/**
 *
 * TagWrapped
 *
 */
import './style.scss';

import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import React from 'react';

interface Props extends Pick<TagProps, 'closable' | 'onClose'> {
	label?: React.ReactNode;
}

export const TagWrapped = (props: Props) => {
	const { label, closable, onClose } = props;
	return (
		<Tag className="ant-tag-wrapper" closable={closable} onClose={onClose}>
			{label}
		</Tag>
	);
};
