import { useFieldFactory } from '../../../../definitions/fields';

export const useCommoditySchema = () => {
	const { erpNumber, products, commodity, priceCtrl, basisCtrl } =
		useFieldFactory();

	const commoditiesFields = [
		erpNumber,
		products,
		commodity,
		priceCtrl,
		basisCtrl,
	];

	return commoditiesFields;
};
