import { call, put, select } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import {
	selectAvailablesListData,
	selectSelectedAvailables,
	selectSelectedRows,
} from '../selectors';
import { actions } from '../slice';

const { rollOrphans } = apiEndpoints;

export function* rollOrphanOffers() {
	const selectedOrphanOffers = yield select(selectSelectedRows);
	const availableBids = yield select(selectAvailablesListData);
	const orphansIDs = selectedOrphanOffers.map((offer) => offer.id);
	let selectedBid = yield select(selectSelectedAvailables);
	selectedBid = availableBids.filter((bid) => bid.label === selectedBid)[0];

	const data = {
		Ids: orphansIDs,
		DeliveryStartDate: selectedBid.deliveryStartDate,
		DeliveryEndDate: selectedBid.deliveryEndDate,
		FuturesMonth: selectedBid.futuresMonth,
		CommodityId: selectedBid.commodityId,
		CropYear: selectedBid.cropYear,
		DeliveryLocationId: selectedBid.deliveryLocationId,
	};

	const requestURL = yield new URL(`${apiRoutes.base}/${rollOrphans}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data,
		false,
	);

	if (!!responseData) {
		const orphanCount = responseData.orphanCount;
		const processedTransactions = responseData.processedTransactions.length;
		const requestedTransactions = responseData.requestedTransactions.length;

		if (processedTransactions === requestedTransactions) {
			yield put(
				globalActions.addNotification({
					type: CustomNotificationTypes.SUCCESS,
					message: responseData.message,
				}),
			);
		} else if (processedTransactions < requestedTransactions) {
			yield put(
				globalActions.addNotification({
					type: CustomNotificationTypes.ERROR,
					message: responseData.message,
				}),
			);
		}

		yield put(actions.orphanOffersRolled(orphanCount));

		if (orphanCount > 0) {
			yield put(actions.requestOrphansAndAvailables());
			yield put(actions.resetSelectedRows());
		}
	} else if (!!responseError.detail) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.ERROR,
				message: responseError.detail,
			}),
		);
		yield put(actions.errorOrphanOffers(responseError));
	}
}
