import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import {
	FormButtonsProps,
	FormSchemaDefinition,
} from 'app/components/GenericForm/types';
import { translations } from 'locales/i18n';

import { useHedgeIntegrationSwitchFormData } from '../../hooks/useHedgeIntegrationSwitchFormData';
import { actions } from '../../slice';
import { HEDGE_EXECUTION_TYPE, HEDGE_INTEGRATION_TYPE } from '../../types';
import { useHedgeIntegrationSchema } from './schemas';

const domain =
	translations.app.containers.Settings.sections.ERPIntegration.views
		.HedgeIntegration;

const className = 'hedge-integration-setup-form';

export const HedgeIntegration = () => {
	const { hedgeIntegration, isLoading, translate } =
		useHedgeIntegrationSwitchFormData();

	const hedgeIntegrationSchema = useHedgeIntegrationSchema();
	const dispatch = useDispatch();

	const setDefaultSchema = hedgeIntegration?.isHedgeAutomatic
		? HEDGE_INTEGRATION_TYPE.automatic
		: HEDGE_INTEGRATION_TYPE.manual;

	const defaultSchema = hedgeIntegrationSchema[setDefaultSchema];
	const [currentSchema, setCurrentSchema] =
		useState<FormSchemaDefinition>(defaultSchema);

	const resolver = yupResolver(currentSchema?.validationSchema);

	const formInstance = useForm({
		defaultValues: {
			...currentSchema?.initialValues,
		},
		mode: 'all',
		resolver,
	});

	const { watch } = formInstance;

	const hedgeIntegrationType = watch('action');

	useEffect(() => {
		setCurrentSchema(hedgeIntegrationSchema[hedgeIntegrationType]);
	}, [hedgeIntegrationType]);

	const getTranslation = (key: string) => translate(domain[key]);

	const handleCancel = () => {
		formInstance.reset({
			...defaultSchema?.initialValues,
		});
	};

	const handleSubmit = (values) => {
		const isHedgeAutomatic =
			values?.action === HEDGE_INTEGRATION_TYPE.automatic;
		const isHedgeInstant =
			values?.executionType === HEDGE_EXECUTION_TYPE.instant;
		let hedgeTime =
			isHedgeAutomatic && !isHedgeInstant ? values?.setTime : null;

		if (hedgeTime && hedgeTime.indexOf(':') === hedgeTime.lastIndexOf(':')) {
			hedgeTime += ':00';
		}

		const data = {
			...hedgeIntegration,
			isHedgeAutomatic,
			isHedgeInstant,
			hedgeTime,
		};

		dispatch(actions.setHedgeIntegrationRequest({ data }));
	};

	const buttonsDefinition: FormButtonsProps[] = [
		{
			children: getTranslation('cancelButton'),
			dataTestId: 'cancel-button',
			disabled: isLoading,
			htmlType: 'button',
			key: 'cancel',
			onClick: handleCancel,
		},
		{
			children: getTranslation('submitButton'),
			dataTestId: 'erp-submit-button',
			disabled: isLoading || !formInstance.formState.isValid,
			htmlType: 'submit',
			key: 'submit',
		},
	];

	if (isLoading) {
		return <Spin data-testid="spinner-loading" key="spinner" />;
	}

	return (
		<GenericForm.Form
			buttonsDefinition={buttonsDefinition}
			className={className}
			data-testid={className}
			formInstance={formInstance}
			key="erpSetupForm"
			onSubmit={handleSubmit}
			validationSchema={currentSchema?.validationSchema}
		>
			{currentSchema?.elements}
		</GenericForm.Form>
	);
};
