interface LocationData {
	deliveryStart: string;
	deliveryEnd: string;
	symbol: string;
	locationName: string;
	basisPrice: number;
	futurePrice: number;
	cropYear: number;
	commodityId: string;
	deliveryMonthCode: string;
	start: string;
	end: string;
	locationId: string;
}

interface MatrixData {
	Beginning: string;
	Ending: string;
	FutCont: string;
	futurePrice: number;
	[key: string]: string | number;
}

/**
 * Takes an array of objects
 * @returns an Array of only unique locations
 */
export const uniqueLocations = (data: LocationData[]): string[] => {
	return [...new Set(data.map((item) => item.locationName))];
};

/**
 * convert "2023-01-01T00:00:00" this format to "01-Jan"
 * @internal exported for automated tests
 * @param string format (2023-01-01T00:00:00)
 * @returns String format (01-Jan)
 */
export const convertDate = (date: string): string => {
	const newDate = new Date(date);
	const month = newDate.toLocaleString('default', { month: 'short' });
	const day = newDate.getDate();
	return `${day}-${month}`;
};

/**
 * Take simple array of object and and sort them by delivery range
 * @internal exported for automated tests
 * @param data [{},{}]
 * @returns arrayOfObjects [{},{}]
 */
export const sortLocationData = (data: LocationData[]) => {
	const dataWithDates = data.map((item) => ({
		...item,
		deliveryStartObj: new Date(item.deliveryStart),
		deliveryEndObj: new Date(item.deliveryEnd),
	}));
	return dataWithDates.sort((a, b) => {
		if (a.deliveryStartObj < b.deliveryStartObj) return -1;
		if (a.deliveryStartObj > b.deliveryStartObj) return 1;
		if (a.deliveryEndObj < b.deliveryEndObj) return -1;
		if (a.deliveryEndObj > b.deliveryEndObj) return 1;
		return 0;
	});
};

/**
 * Take simple array of object and create a matrix to presnt the data as required
 * @param number
 * @param data [{},{}]
 * @param uniqueLocations []
 * @returns arrayOfObjects [{},{}]
 */
export const createCashbidsMatrix = (
	data: LocationData[],
	uniqueLocations: string[],
): MatrixData[] => {
	const locationsWithHeader = [
		'Beginning',
		'Ending',
		'FutCont',
		...uniqueLocations,
	];

	const sortedData = sortLocationData(data);

	const newData: MatrixData[] = [];
	sortedData.forEach((item) => {
		let obj: any = {};
		locationsWithHeader.forEach((key) => {
			if (key === 'Beginning') {
				obj[key] = convertDate(item.deliveryStart);
			} else if (key === 'Ending') {
				obj[key] = convertDate(item.deliveryEnd);
			} else if (key === 'FutCont') {
				obj[key] = item.symbol.slice(-3);
			} else {
				obj[key] =
					item.locationName === key
						? [item.basisPrice, item.futurePrice, item.locationId]
						: '';
			}
		});
		obj['futurePrice'] = item.futurePrice;
		obj['commodityId'] = item.commodityId;
		obj['deliveryMonthCode'] = item.deliveryMonthCode;
		obj['cropYear'] = item.cropYear;
		obj['start'] = item.deliveryStart;
		obj['end'] = item.deliveryEnd;
		newData.push(obj);
	});

	const processedData = groupSimilarData(newData);
	return processedData;
};

/**
 * Groupe the objects according to the similar value
 * @param number
 * @param data [{},{}]
 * @returns arrayOfObjects [{},{}]
 */
const groupSimilarData = (data: MatrixData[]): MatrixData[] => {
	return data.reduce((accumulator: MatrixData[], current: MatrixData) => {
		const existing = accumulator.find(
			(item) =>
				item.Beginning === current.Beginning &&
				item.Ending === current.Ending &&
				item.FutCont === current.FutCont &&
				item.futurePrice === current.futurePrice,
		);

		if (!existing) {
			accumulator.push(current);
		} else {
			let isMerged = true;

			for (const key in current) {
				if (
					key !== 'Beginning' &&
					key !== 'Ending' &&
					key !== 'FutCont' &&
					key !== 'futurePrice'
				) {
					if (
						existing[key] !== current[key] &&
						existing[key] !== '' &&
						current[key] !== ''
					) {
						isMerged = false;
						break;
					} else if (existing[key] === '') {
						existing[key] = current[key];
					}
				}
			}

			if (isMerged) {
				accumulator[accumulator.indexOf(existing)] = existing;
			} else {
				accumulator.push(current);
			}
		}

		return accumulator;
	}, []);
};

/**
 * Get current CST time in "02/21/2023, 10:14:40 CST" format
 * @returns string
 */
export const getCSTTime = () => {
	const now = new Date();
	const formattedTime = now.toLocaleString('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		timeZone: 'America/Chicago',
		timeZoneName: 'short',
	});
	return formattedTime;
};
