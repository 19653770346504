import { FixedColumn } from 'types/FixedColumn';
import { GenericError } from 'types/GenericError';
import { GenericOption } from 'types/GenericOption';
import { IGenericPagination } from 'types/GenericPagination';
import { Offer, OffersFilters } from 'types/Offer';
import { OfferDetails } from 'types/OfferDetails';

import { Modal as BidsheetModals } from '../Settings/sections/Bidsheet/types';

export interface OffersState {
	success: boolean | null;

	currentModal: OffersModals | BidsheetModals | null;
	currentView: OffersViews | null;

	currentContractType: GenericOption;

	pagination: IGenericPagination;

	offersList: {
		data: Offer[];
		loading: boolean;
		total: number;
		error: GenericError | null;
	};

	activeOffer: {
		data: OfferDetails | null;
		loading: boolean;
		error: GenericError | null;
	};

	activeOfferDetails: {
		data: OfferDetails | null;
		loading: boolean;
		error: GenericError | null;
	};

	selectedFilters: OffersFilters;

	selectedColumns: OffersVisibleColumns;

	fixedColumns: OffersFixedColumns;

	selectedRows: any[];
}

export enum OffersViews {
	Details = 'details',
	Table = 'table',
}

export type OffersFixedColumns = OffersColumns<FixedColumn>;

type OffersVisibleColumns = OffersColumns<boolean>;

type OffersColumns<T> = {
	created: T;
	offer: T;
	expiration: T;
	customer: T;
	employee: T;
	commodity: T;
	crop: T;
	deliveryStartDate: T;
	deliveryEndDate: T;
	futuresMonth: T;
	destination: T;
	quantity: T;
	futures: T;
	basis: T;
	cash: T;
	dots: T;
};

export enum OffersModals {
	Create = 'create',
	Edit = 'edit',
	Cancel = 'cancel',
	Book = 'book',
	InformCustomer = 'informCustomer',
	MultipleBook = 'multipleBook',
}

export enum OrderStatus {
	Filled = 'Filled',
	CanceledExpired = 'Canceled/Expired',
}

export enum TransactionType {
	Offer = 'Offer',
	Contract = 'Cash Contract',
}

export interface ActiveEntity {
	data: OfferDetails;
	error: GenericError | null;
	loading: boolean;
}

export type ContainerState = OffersState;
