import { ArrowRightOutlined } from '@ant-design/icons';
import React, { ComponentProps } from 'react';

import { DownloadLink } from 'app/components/DownloadLink';
import { FormCalendar } from 'app/components/FormCalendar';
import { FormCheckbox } from 'app/components/FormCheckbox';
import { FormDragAndDrop } from 'app/components/FormDragAndDrop';
import { FormDropdown } from 'app/components/FormDropdown';
import { FormInput } from 'app/components/FormInput';
import { FormInputNumber } from 'app/components/FormInputNumber';
import { FormInputPrice } from 'app/components/FormInputPrice';
import { FormInputQuantity } from 'app/components/FormInputQuantity';
import { FormMultiFieldRow } from 'app/components/FormMultiFieldRow';
import { FormRadios } from 'app/components/FormRadios';
import { FormSearch } from 'app/components/FormSearch';
import { FormSwitch } from 'app/components/FormSwitch';
import { FormTextArea } from 'app/components/FormTextArea';
import { FormTransfer } from 'app/components/FormTransfer';
import { CropYearsLegacy } from 'app/containers/Transactions/components/CropYearsLegacy';
import { GenericOption } from 'types/GenericOption';

export const generateFormInputNumber = (
	formField: ComponentProps<typeof FormInputNumber>,
) => {
	return <FormInputNumber {...formField} />;
};

export const generateInputFieldPrice = (
	formField: ComponentProps<typeof FormInputPrice>,
) => {
	return <FormInputPrice {...formField} />;
};

export const generateFormInputQuantity = (
	formField: ComponentProps<typeof FormInputQuantity>,
) => {
	return <FormInputQuantity {...formField} />;
};

export const generateInputField = (
	formField: ComponentProps<typeof FormInput>,
) => {
	return <FormInput {...formField} />;
};

export const generateMultiField = (
	formField: Omit<ComponentProps<typeof FormMultiFieldRow>, 'fields'> & {
		// TODO get rid of any type here
		/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
		fields: null | { render: (field: any) => JSX.Element | null }[];
	},
) => {
	const innerElements = formField.fields?.map((innerField) =>
		innerField.render(innerField),
	);

	return (
		<FormMultiFieldRow
			{...formField}
			fields={innerElements || []}
		></FormMultiFieldRow>
	);
};

export const generateDropdown = (
	formField: Omit<ComponentProps<typeof FormDropdown>, 'options'> & {
		options: GenericOption[] | null | undefined;
	},
) => {
	return <FormDropdown {...formField} options={formField.options || []} />;
};

export const generateSwitch = (
	formField: ComponentProps<typeof FormSwitch>,
) => {
	return <FormSwitch {...formField} />;
};

export const generateCalendar = (
	formField: ComponentProps<typeof FormCalendar>,
) => {
	return <FormCalendar {...formField} />;
};

export const generateDragAndDrop = (
	formField: ComponentProps<typeof FormDragAndDrop>,
) => {
	return <FormDragAndDrop {...formField} />;
};

export const generateDownloadLink = (
	formField: ComponentProps<typeof DownloadLink>,
) => {
	return <DownloadLink {...formField} />;
};

export const generateCheckbox = (
	formField: ComponentProps<typeof FormCheckbox>,
) => {
	return <FormCheckbox {...formField} />;
};

export const generateArrowIcon = (
	formField: ComponentProps<typeof ArrowRightOutlined>,
) => {
	return <ArrowRightOutlined {...formField} />;
};

export const generateCropYears = (
	formField: ComponentProps<typeof CropYearsLegacy>,
) => {
	return <CropYearsLegacy {...formField} />;
};

export const generateFormRadios = (
	formField: ComponentProps<typeof FormRadios>,
) => {
	return <FormRadios {...formField} />;
};

export const generateTextArea = (
	formfield: ComponentProps<typeof FormTextArea>,
) => {
	return <FormTextArea {...formfield} />;
};

export const generateTransfer = (
	formField: ComponentProps<typeof FormTransfer>,
) => {
	return <FormTransfer {...formField} />;
};

export const generateFormSearch = (
	formField: ComponentProps<typeof FormSearch>,
) => {
	return <FormSearch {...formField} />;
};
