import React from 'react';

import { GenericTable } from 'app/components/GenericTable';
import { CONSTANTS } from 'utils/constants';
import { currencyFormat } from 'utils/helpers';

interface Data {
	futures: null | boolean;
	passFill: null | boolean;
	futuresPrice: number | string | null;
}

interface Props {
	data: Data;
}

const isOrderFilled = ({ futures, passFill }: Data) =>
	futures !== null || !!!passFill;

export const FuturesPrice = ({ data }: Props) => (
	<>
		{isOrderFilled(data) && (
			<GenericTable.Column>
				<p
					className="text text--large text--centered"
					data-testid="futures-price"
				>
					{currencyFormat(
						data.futuresPrice,
						CONSTANTS.FIXED_DECIMALS,
						CONSTANTS.FIXED_DECIMALS,
					)}
				</p>
			</GenericTable.Column>
		)}
	</>
);
