import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ErrorInLine } from '../../types';
import { Filters, Modal, RoundingRulesSectionState, View } from './types';

// The initial state of the Settings container
export const initialState: RoundingRulesSectionState = {
	currentView: View.List,
	currentModal: null,

	currentRoundingRule: {
		data: null,
		error: null,
		loading: false,
	},
	activeRoundingRule: {
		data: null,
		error: null,
		loading: false,
	},

	list: {
		data: [],
		loading: false,
		total: 0,
		error: null,
	},

	typelist: {
		data: [],
		loading: false,
		total: 0,
		error: null,
	},

	pagination: {
		limit: 50,
		start: 1,
	},

	selectedFilters: {
		name: '',
	},
	errorsInLine: [],
};

const roundingRulesSlice = createSlice({
	name: 'roundingRules',
	initialState,
	reducers: {
		setCurrentModal(state, action: PayloadAction<Modal | null>) {
			state.currentModal = action.payload;
		},

		toggleStatus(state) {
			state.currentRoundingRule = {
				...state.currentRoundingRule,
				loading: true,
				error: null,
			};
		},

		roundingRuleStatusToggled(state, action) {
			state.currentRoundingRule = {
				...state.currentRoundingRule,
				loading: false,
				error: null,
				data: action.payload,
			};
		},

		roundingRuleStatusError(state, action) {
			state.currentRoundingRule = {
				...state.currentRoundingRule,
				error: action.payload,
				loading: false,
			};
		},

		setcurrentRoundingRule(state, action) {
			state.currentRoundingRule = {
				data: action.payload,
				loading: true,
				error: null,
			};
		},

		loadRoundingRulesList(state) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},

		loadRoundingTypesList(state) {
			state.typelist = {
				...state.typelist,
				loading: true,
				error: null,
			};
		},
		deleteRoundingRule(state, action: PayloadAction<object>) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},
		RoundingRuleDeleted(state) {
			state.list = {
				...state.list,
				loading: false,
				error: null,
			};
		},

		deleteRoundingRuleError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		setActiveRoundingRule(state, action) {
			state.activeRoundingRule = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		resetActiveRoundingRule(state) {
			state.activeRoundingRule = {
				data: null,
				loading: false,
				error: null,
			};
		},

		roundingRulesListLoaded(state, action: PayloadAction<any>) {
			state.list = {
				...state.list,
				data: action.payload,
				total: action.payload.length,
				loading: false,
				error: null,
			};
		},

		roundingRulesListError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		roundingTypesListLoaded(state, action: PayloadAction<any>) {
			state.typelist = {
				...state.typelist,
				data: action.payload,
				total: action.payload.length,
				loading: false,
				error: null,
			};
		},

		roundingTypesListError(state, action: PayloadAction<GenericError>) {
			state.typelist = {
				...state.typelist,
				loading: false,
				error: action.payload,
			};
		},

		setPagination(state, action: PayloadAction<any>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},

		setSelectedFilters(state, action: PayloadAction<Filters>) {
			state.selectedFilters = {
				name: action.payload.name || '',
			};
		},

		createRoundingRule(state, action) {},

		roundingRuleCreated(state, action) {
			state.currentRoundingRule = {
				data: null,
				loading: false,
				error: null,
			};
		},

		editRoundingRule(state, action) {
			state.currentRoundingRule = {
				...state.currentRoundingRule,
				error: null,
				loading: true,
			};
		},

		roundingRuleEdited(state, action) {
			state.currentRoundingRule = {
				data: null,
				loading: false,
				error: null,
			};
		},

		roundingRuleError(state, action: PayloadAction<GenericError>) {
			state.currentRoundingRule = {
				...state.currentRoundingRule,
				error: action.payload,
				loading: false,
			};
		},

		setErrorsInLine(state, action: PayloadAction<ErrorInLine[]>) {
			state.errorsInLine = action.payload.map((error) => {
				if (error.Field === 'name') {
					return {
						...error,
						Field: 'roundingRuleName',
					};
				}
				if (error.Field === 'number') {
					return {
						...error,
						Field: 'erpNumber',
					};
				} else return { ...error };
			});

			state.currentRoundingRule = {
				...state.currentRoundingRule,
				loading: false,
			};
		},

		requestedRoundingRuleById(state, action) {
			state.currentRoundingRule = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = roundingRulesSlice;
