import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import moment from 'moment';
import React, { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';

import { ConfirmationModal } from 'app/components/ConfirmationModal';
import { GenericForm } from 'app/components/GenericForm';
import {
	FormButtonsProps,
	FormSchemaDefinition,
} from 'app/components/GenericForm/types';
import {
	selectFeatureFlags,
	selectOrderEntry,
} from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { selectActiveOffer } from 'app/containers/Offers/selectors';
import { actions } from 'app/containers/Offers/slice';
import { SourceContext } from 'app/containers/Transactions';
import { ModalHeader } from 'app/containers/Transactions/components/ModalHeader';
import { useGroupsFormSchema } from 'app/containers/Transactions/hooks/useGroupsFormSchema';
import { FeatureFlag } from 'types/FeatureFlags';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import {
	CONSTANTS,
	CONTRACT_TYPE_VALUES,
	CONTRACT_TYPES,
	DATE_FORMAT,
} from 'utils/constants';
import { customFormat } from 'utils/helpers';
import { getOrderEntriesFormValues } from 'utils/order-entry-helpers';

import { useTranslations } from '../shared/useTranslations';
import { useOffersSchema } from './schemas/Offers';

export const EditModal = memo(function EditModal() {
	const dispatch = useDispatch();

	const orderEntries = useSelector(selectOrderEntry);
	const offerData = useSelector(selectActiveOffer);
	const featureFlags = useSelector(selectFeatureFlags);

	const translations = useTranslations();

	const sourceName = GlobalSagaSource.createEditOfferModal;
	const offerNumber =
		offerData && offerData.number ? ` #${offerData.number}` : '';
	const editOfferTitle = translations.actions.editOfferTitle + offerNumber;
	const { editConfirmationMessage, confirmText, cancelText } =
		translations.actions;

	const offersSchema = useOffersSchema();

	const [currentSchema] = useState<FormSchemaDefinition>(
		offersSchema[offerData?.contractTypeName || ''],
	);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState('');
	const [formValues, setFormValues] = useState({});

	const resolver = yupResolver(currentSchema.validationSchema);

	const transformedGroupData = offerData?.groups?.map((group) => ({
		label: group.name,
		value: group.id,
	}));

	const formInstance = useForm({
		defaultValues: {
			...currentSchema.initialValues,
			groups: transformedGroupData,
		},
		resolver,
	});
	const { reset, setError, clearErrors } = formInstance;

	useGroupsFormSchema(currentSchema, formInstance, offerData?.groups);

	const handleClose = () => {
		reset();
		dispatch(actions.setCurrentModal(null));
		cleanState();
	};

	const cleanState = () => {
		dispatch(globalActions.clearFuturesPrices(sourceName));
		dispatch(globalActions.clearPostedBasisPrice(sourceName));
		dispatch(globalActions.clearFuturesMonth(sourceName));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	const handleSubmit = (values) => {
		const newQuantity = parseFloat(
			customFormat(
				values.remainingBalance,
				false,
				CONSTANTS.FIXED_QUANTITY_DECIMALS,
			),
		);
		if (
			offerData?.isInternal === true &&
			newQuantity >= offerData?.lotFactor &&
			offerData?.contractTypeId !== CONTRACT_TYPE_VALUES.basis.toLowerCase()
		) {
			setConfirmationMessage(translations.confirmation.public);
			setFormValues(values);
			setShowConfirmation(true);
		} else if (
			offerData?.isInternal === false &&
			newQuantity < offerData?.lotFactor &&
			offerData?.contractTypeId !== CONTRACT_TYPE_VALUES.basis.toLowerCase()
		) {
			setError('remainingBalance', {
				type: 'error',
				message: translations.confirmation.editDownValidation,
				shouldFocus: true,
			});
		} else {
			clearErrors('remainingBalance');
			submitForm(values);
		}
	};

	const submitForm = (values) => {
		setShowConfirmation(false);
		let newOfferData: any = {
			...offerData,
			theirContract: values.theirContract,
			freightPrice: values.freight ? parseFloat(values.freight) : 0,
			fees1: values.fees1 ? parseFloat(values.fees1) : 0,
			fees2: values.fees2 ? parseFloat(values.fees2) : 0,
			expiration: values.expirationDateGTC
				? moment(values.expirationDateGTC).format(DATE_FORMAT)
				: null,
			gtc: values.gtcMode,
			customerId: values.customer?.value,
			employeeId: values.employee?.value,
			comments: values.comments,
			customFields: getOrderEntriesFormValues(orderEntries, values),
			remainingBalance: featureFlags[FeatureFlag.enableOfferQuantityEdit]
				? parseFloat(
						customFormat(
							values.remainingBalance,
							false,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					)
				: 0,
			groups: values.groups?.map((group) => group.value) || [],
			regionId: values?.assignedRegion?.value,
		};

		if (values.contract?.label === CONTRACT_TYPES.flatPrice) {
			newOfferData = {
				...newOfferData,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
				price: values.flatPrice ? parseFloat(values.flatPrice) : 0,
				cashSettlement: values.cashSettlement,
			};
		} else if (values.contract?.label === CONTRACT_TYPES.basis) {
			newOfferData = {
				...newOfferData,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				price: values.netBasisPrice ? parseFloat(values.netBasisPrice) : 0,
			};
		} else if (values.contract?.label === CONTRACT_TYPES.hta) {
			newOfferData = {
				...newOfferData,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
				price: values.netFutures ? parseFloat(values.netFutures) : 0,
			};
		}

		batch(() => {
			cleanState();

			dispatch(
				actions.updateOffer({
					data: newOfferData,
					id: offerData?.id,
					successMessage: translations.common.success,
				}),
			);
		});
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			children: translations.buttons.cancelChanges,
			dataTestId: 'cancel-button',
			htmlType: 'button',
			key: 'cancelOfferChanges',
			onClick: handleClose,
		},
		{
			children: translations.buttons.submitChanges,
			dataTestId: 'submit-button',
			htmlType: 'submit',
			key: 'submit',
		},
	];

	const header = (
		<ModalHeader
			title={editOfferTitle}
			content={editConfirmationMessage}
			confirmText={confirmText}
			cancelText={cancelText}
			handleConfirm={handleClose}
		/>
	);

	return (
		<>
			<GenericForm.ModalContainer
				key="customerModal"
				className="create-form__modal"
				title={header}
				onCancel={handleClose}
				closable={false}
				destroyOnClose={true}
				keyboard={false}
			>
				<SourceContext.Provider value={GlobalSagaSource.createEditOfferModal}>
					<GenericForm.Form
						className="order-form"
						key="customerForm"
						formInstance={formInstance}
						validationSchema={currentSchema?.validationSchema}
						onSubmit={
							featureFlags[FeatureFlag.enableOfferQuantityEdit]
								? handleSubmit
								: submitForm
						}
						buttonsDefinition={btnsDefinition}
					>
						{currentSchema?.elements as any}
					</GenericForm.Form>
				</SourceContext.Provider>
			</GenericForm.ModalContainer>

			{featureFlags[FeatureFlag.enableOfferQuantityEdit] &&
				showConfirmation && (
					<ConfirmationModal
						body={confirmationMessage}
						confirmBtn={translations.buttons.yes}
						cancelBtn={translations.buttons.cancel}
						handleCancel={() => setShowConfirmation(false)}
						handleConfirmation={() => submitForm(formValues)}
						showCancelButton={true}
						title={translations.confirmation.title}
					/>
				)}
		</>
	);
});
