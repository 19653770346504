import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { selectDefaultPageData } from 'app/containers/GlobalSaga/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { NetBasis } from 'app/containers/Transactions/components/NetBasis';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { ActionType } from 'types/ActionType';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import { isDeliveryDateCustom, isDeliveryDateWindow } from 'utils/helpers';

import { validatePrice } from '../../shared/helpers';
import { useTranslations } from '../../shared/useTranslations';

export const useBasisSchema = (isAdjustment = false) => {
	const translations = useTranslations();
	const defaultData = useSelector(selectDefaultPageData);

	const { elements, initialValues, validationSchema } =
		useOrderEntriesFormSchema();

	const numberMsg = translations.validations.number;

	const requiredMsg = translations.validations.required;
	const basisFormatError = translations.validations.basisFormatError;

	return {
		initialValues: {
			action: ActionType.BUY,
			commodity: { value: null },
			location: { value: null },
			deliveryLocation: { value: null },
			assignedRegion: { value: null },
			deliveryDate: null,
			deliveryDatesMode: { value: DeliveryDateMode.Window },
			deliveryDateWindow: { value: null },
			cropYear: null,
			futuresMonth: { value: null },
			futuresPrice: null,
			postedBasis: null,
			pushBasis: null,
			netBasis: null,
			freight: null,
			fees1: null,
			fees2: null,
			flatPrice: null,
			netFutures: null,
			netBasisPrice: null,
			quantity: null,
			gtcMode: false,
			expirationDate: null,
			employee: {
				label: defaultData?.employeeName,
				value: defaultData?.employeeId,
			},
			comments: '',
			basis: null,
			futures: null,
			passFill: false,
			doNotHedge: false,
			useServiceFees: false,
			...initialValues,
		},

		validationSchema: Yup.object().shape({
			contract: Yup.object().requiredOption(requiredMsg),
			commodity: Yup.object().requiredOption(requiredMsg),
			location: Yup.object().requiredOption(requiredMsg),
			deliveryLocation: Yup.object().requiredOption(requiredMsg),
			assignedRegion: Yup.object().requiredOption(requiredMsg),
			deliveryDate: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateCustom(mode),
				then: Yup.mixed().required(requiredMsg),
			}),
			deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateWindow(mode),
				then: Yup.object().requiredOption(requiredMsg),
			}),
			freight: Yup.number().typeError(numberMsg).nullable(),
			postedBasis: Yup.number()
				.required(requiredMsg)
				.typeError(numberMsg)
				.test('priceValidation', basisFormatError, (value: string) =>
					validatePrice(value),
				),
			pushBasis: Yup.number().typeError(numberMsg).nullable(),
			fees: Yup.number().typeError(numberMsg),
			netBasisPrice: Yup.number().typeError(numberMsg).required(requiredMsg),
			employee: Yup.object().requiredOption(requiredMsg),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
			...validationSchema,
		}),
		elements: [
			<ContractType
				resetDependenciesOnChange={false}
				disableBuySell={true}
				ShowNTC
			/>,
			<Commodity checkDefaultValues disabled />,
			<Location />,
			<DeliveryLocation />,
			<AssignedRegion />,
			<CropYears disabled />,
			<DeliveryDates checkDefaultValues />,
			<Futures
				checkDefaultValues
				futuresMonth={{ market: true, disableWithinDeliveryMode: true }}
			/>,
			<Basis disabled={{ netBasis: true }} adjustedBasis datesModeDependency />,
			<ExpirationDate />,
			<Freight />,
			<Fees />,
			<NetBasis />,
			<Employee />,
		]
			.concat(elements)
			.concat([<Comments />]),
	};
};
