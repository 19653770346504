import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { NotificationEventTypes } from '../../components/NotificationEventTypes';
import {
	selectTenantNotificationSettings,
	selectTenantNotificationSettingsLoading,
} from '../../selectors';
import { actions } from '../../slice';
import { NotificationEvent } from '../../types';

export const TenantNotificationSettings = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const tenantPreferences = useSelector(selectTenantNotificationSettings);
	const loading = useSelector(selectTenantNotificationSettingsLoading);

	const translationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;
	const handleSubmit = (records: NotificationEvent[]) => {
		dispatch(
			actions.setTenantPreferenceStatus({
				tenantSettings: records,
			}),
		);
	};

	useEffect(() => {
		dispatch(actions.getTenantPreferences());
	}, []);

	return (
		<NotificationEventTypes
			preferences={tenantPreferences}
			handleSubmit={handleSubmit}
			title={translate(translationsScope.EventTypes)}
			loading={loading}
		/>
	);
};
