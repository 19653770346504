import {
	BlockOutlined,
	CheckSquareFilled,
	CloseOutlined,
	EditOutlined,
	StopOutlined,
} from '@ant-design/icons';
import { Card, Col } from 'antd';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'app/containers/Offers/slice';
import { ActiveEntity, OffersModals } from 'app/containers/Offers/types';
import { OfferDetails } from 'types/OfferDetails';

interface Props {
	activeEntity: ActiveEntity;
	span: number;
	noBorder?: boolean;
	dataTestId: string;
	handleClose: () => void;
}
export const OfferActionsSection = memo(function OfferActionsSection(
	props: Props,
) {
	const { activeEntity, span, dataTestId, handleClose } = props;
	const { canBeBooked, canBeCanceled, canBeCompleted, canBeEdited } =
		activeEntity.data.restrictions;
	const dispatch = useDispatch();

	const handleCancelModal = (offerData: OfferDetails) => {
		if (!canBeCanceled) return;
		dispatch(actions.setCurrentModal(OffersModals.Cancel));
		dispatch(actions.setActiveOffer(offerData));
	};

	const handleCompleteModal = (offerData: OfferDetails) => {
		if (!canBeCompleted) return;
		dispatch(actions.setCurrentModal(OffersModals.InformCustomer));
		dispatch(actions.setActiveOffer(offerData));
	};

	const handleEditModal = (offerId: string) => {
		if (!canBeEdited) return;
		dispatch(actions.loadOfferDetails(offerId));
		dispatch(actions.setCurrentModal(OffersModals.Edit));
	};

	const handleBookModal = (offerData: OfferDetails) => {
		if (!canBeBooked) return;
		dispatch(actions.setCurrentModal(OffersModals.Book));
		dispatch(actions.setActiveOffer(offerData));
	};

	return (
		activeEntity && (
			<Col span={span}>
				<Card
					className="contract summary-actions"
					data-testid={dataTestId}
					bordered={false}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className="divider">
						<StopOutlined
							className={classNames('action-icon', {
								'disabled-icon': !canBeCanceled,
							})}
							onClick={() => handleCancelModal(activeEntity.data)}
							data-testid="cancel-button"
						/>
						<EditOutlined
							className={classNames('action-icon', {
								'disabled-icon': !canBeEdited,
							})}
							onClick={() => handleEditModal(activeEntity.data?.id)}
							data-testid="edit-button"
						/>

						<BlockOutlined
							className={classNames('action-icon', {
								'disabled-icon': !canBeBooked,
							})}
							onClick={() => handleBookModal(activeEntity.data)}
							data-testid="book-button"
						/>

						<CheckSquareFilled
							className={classNames('action-icon', {
								'disabled-icon': !canBeCompleted,
							})}
							onClick={() => handleCompleteModal(activeEntity.data)}
							data-testid="complete-button"
						/>
						<CloseOutlined
							className="action-icon close"
							onClick={handleClose}
							data-testid="close-offer-button"
						/>
					</div>
				</Card>
			</Col>
		)
	);
});
