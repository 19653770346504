import './styles.scss';

import React, { memo } from 'react';
import { FieldValues } from 'react-hook-form';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { getNumberArray } from 'utils/helpers';

import { Header } from '../../components/Header';
import { OrderEntryRow } from '../../components/OrderEntryRow';
import { actions } from '../../slice';
import {
	getFormValues,
	ORDER_ENTRIES_MAX,
	ORDER_ENTRY_DOMAIN,
} from '../../utils/helpers';
import { useOrderEntryFormData } from './hooks/useOrderEntryFormData';

export const OrderEntryForm = memo(function OrderEntryForm() {
	const {
		dispatch,
		formInstance,
		hasCustomFields,
		hasMultiSelect,
		initialValues,
		isCreatingOrderEntries,
		orderEntries,
		translate,
		validationSchema,
	} = useOrderEntryFormData();

	const getTranslation = (key: string, values?: any) =>
		translate(ORDER_ENTRY_DOMAIN.Views.OrderEntryForm[key], values);

	const handleCancel = () => {
		formInstance.reset({
			...initialValues,
		});
	};

	const handleSubmit = (values: FieldValues) => {
		const mappedValues = getFormValues(isCreatingOrderEntries, values);

		dispatch(
			actions.setOrderEntryRequest({
				data: { fields: mappedValues, hasCustomFields },
			}),
		);
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			children: getTranslation('cancelButton'),
			dataTestId: 'cancel-button',
			htmlType: 'button',
			key: 'cancel',
			onClick: handleCancel,
		},
		{
			children: getTranslation('submitButton'),
			dataTestId: 'submit-button',
			htmlType: 'submit',
			key: 'submit',
		},
	];

	return (
		<GenericForm.Form
			buttonsDefinition={btnsDefinition}
			className="order-entry-form"
			formInstance={formInstance}
			key="orderEntryForm"
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			<>
				<Header hasMultiSelect={hasMultiSelect} />
				{getNumberArray(ORDER_ENTRIES_MAX).map((index: number) => (
					<OrderEntryRow index={index} orderEntry={orderEntries?.[index]} />
				))}
			</>
		</GenericForm.Form>
	);
});
