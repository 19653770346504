import './style.scss';

import { Col, Row } from 'antd';
import classNames from 'classnames';
import { Form } from 'formik-antd';
import React, { memo } from 'react';

import { ModalSize } from 'types/ModalSize';

interface Props {
	name: string;
	label?: string;
	fields: (JSX.Element | null)[];
	spans?: number[];
	size?: ModalSize;
}

const CONSTANTS = {
	COLUMNS: 12,
	GUTTER: 10,
};

export const FormMultiFieldRow = memo(function FormMultiFieldRow(props: Props) {
	const { name, label, fields, spans, size } = props;
	const { GUTTER, COLUMNS } = CONSTANTS;

	const defaultColSpan = Math.floor(COLUMNS / fields.length);

	return (
		<Form.Item
			data-testid={name}
			label={size === ModalSize.large ? null : label ? label : <div />}
			colon={!!label}
			name={name}
			key={name}
			className={classNames('multi-field-row-form__label', {
				'large-modal': size === ModalSize.large,
			})}
		>
			<Row
				data-testid={`row-${name}`}
				gutter={GUTTER}
				className="multi-field-row-form__row"
			>
				{fields.map((field, index) => {
					const id = `${name}-${index}`;
					const span = spans && spans[index] ? spans[index] : defaultColSpan;

					const columns =
						size === ModalSize.large ? (
							<Col key={id}>{field}</Col>
						) : (
							<Col span={span} key={id}>
								{field}
							</Col>
						);

					return columns;
				})}
			</Row>
		</Form.Item>
	);
});
