import React, { memo } from 'react';
import { FieldValues } from 'react-hook-form';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { actions } from 'app/containers/ReviewAndRelease/slice';
import { translations } from 'locales/i18n';

import { useAcknowledgeAndFillData } from '../hooks/useAcknowledgeAndFillData';
import { getFormValues } from './utils/helpers';

const domain =
	translations.app.containers.ReviewAndRelease.components.Views.List.Modals
		.AcknowledgeAndFill;

export const AcknowledgeAndFill = memo(function AcknowledgeAndFill() {
	const {
		dispatch,
		elements,
		formInstance,
		orderId,
		translate,
		validationSchema,
	} = useAcknowledgeAndFillData();

	const getTranslation = (key: string) => translate(domain[key]);

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const handleSubmit = (values: FieldValues) => {
		const data = getFormValues(orderId, values);

		dispatch(actions.setAcknowledgeAndFillRequest({ data }));
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			children: getTranslation('cancelButton'),
			dataTestId: 'cancel-button',
			htmlType: 'button',
			key: 'cancel',
			onClick: handleClose,
		},
		{
			children: getTranslation('submitButton'),
			dataTestId: 'submit-button',
			htmlType: 'submit',
			key: 'submit-button',
		},
	];

	return (
		<GenericForm.ModalContainer
			closable={false}
			key="acknowledgeAndFillModal"
			title={getTranslation('title')}
		>
			<GenericForm.Form
				buttonsDefinition={btnsDefinition}
				formInstance={formInstance}
				key="acknowledgeAndFillModal"
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{elements}
			</GenericForm.Form>
		</GenericForm.ModalContainer>
	);
});
