import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { transactionItemSchema } from 'types/TransactionItem';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';

const { transactionsRoot } = apiEndpoints;

export function* getTransactionsList() {
	const requestURL = new URL(`${apiRoutes.base}/${transactionsRoot}`);
	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(transactionItemSchema),
	});

	if (!!data) {
		yield* put(actions.transactionsListLoaded(data));
	} else if (!!error?.detail) {
		yield* put(actions.transactionsError(error));
	}
}
