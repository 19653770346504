import {
	ArrowsAltOutlined,
	CheckCircleOutlined,
	ClockCircleOutlined,
	CloseOutlined,
	ColumnHeightOutlined,
	DislikeOutlined,
	DollarCircleOutlined,
	EditOutlined,
	StopOutlined,
	SyncOutlined,
	UndoOutlined,
} from '@ant-design/icons';
import React from 'react';

import { GenericItem } from 'types/GenericItem';
import { mapGenericItemsListToIcon } from 'utils/helpers';

import { LiveLedgerEvents } from '../../../types';
import { FuturesAssigned } from '../components/IconRoundedEvent/Icons/FuturesAssigned';

interface Params {
	eventList: GenericItem[];
	entityName: string;
}

export const getEventsMap = (params: Params) => {
	const { eventList, entityName } = params;
	const eventsMap = mapGenericItemsListToIcon(eventList, entityName);

	if (eventsMap && Object.keys(eventsMap).length > 0) {
		if (eventsMap[LiveLedgerEvents.Create]) {
			eventsMap[LiveLedgerEvents.Create].icon = <CheckCircleOutlined />;
		}
		if (eventsMap[LiveLedgerEvents.Cancel]) {
			eventsMap[LiveLedgerEvents.Cancel].icon = <StopOutlined />;
		}
		if (eventsMap[LiveLedgerEvents.Roll]) {
			eventsMap[LiveLedgerEvents.Roll].icon = <SyncOutlined />;
		}
		if (eventsMap[LiveLedgerEvents.Price]) {
			eventsMap[LiveLedgerEvents.Price].icon = <DollarCircleOutlined />;
		}
		if (eventsMap[LiveLedgerEvents.Edit]) {
			eventsMap[LiveLedgerEvents.Edit].icon = <EditOutlined />;
		}
		if (eventsMap[LiveLedgerEvents.Threshold]) {
			eventsMap[LiveLedgerEvents.Threshold].icon = <ColumnHeightOutlined />;
		}
		if (eventsMap[LiveLedgerEvents.Deny]) {
			eventsMap[LiveLedgerEvents.Deny].icon = (
				<DislikeOutlined className="icon-round-event__icon" />
			);
		}
		if (eventsMap[LiveLedgerEvents.Reject]) {
			eventsMap[LiveLedgerEvents.Reject].icon = (
				<CloseOutlined className="icon-round-event__icon" />
			);
		}
		if (eventsMap[LiveLedgerEvents.BeginningBalance]) {
			eventsMap[LiveLedgerEvents.BeginningBalance].icon = (
				<ClockCircleOutlined />
			);
		}
		if (eventsMap[LiveLedgerEvents.AssignFutures]) {
			eventsMap[LiveLedgerEvents.AssignFutures].icon = <FuturesAssigned />;
		}
		if (eventsMap[LiveLedgerEvents.Undo]) {
			eventsMap[LiveLedgerEvents.Undo].icon = <UndoOutlined />;
		}
		if (eventsMap[LiveLedgerEvents.ApplyNameId]) {
			eventsMap[LiveLedgerEvents.ApplyNameId].icon = <ArrowsAltOutlined />;
		}
	}
	return eventsMap;
};
