import { Input } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';

export const Comments = memo(function Comments() {
	const { t: translate } = useTranslation();
	const translationScope =
		translations.app.containers.Transactions.components.Comments;

	return (
		<GenericForm.FormItem
			name="comments"
			label={translate(translationScope.commentsLabel)}
			data-testid="comments-form-item"
			isInputSlow
			customClassName={['tall-cell']}
		>
			<Input.TextArea
				key="comments-area"
				autoSize={{
					minRows: 4,
					maxRows: 4,
				}}
				rows={4}
			/>
		</GenericForm.FormItem>
	);
});
