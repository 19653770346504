import { GenericError } from 'types/GenericError';

// Isolated Chashbid state
export interface CashBidsAndQuotesState {
	success: boolean | null;
	futureQuoteList: {
		data: any[];
		loading: boolean;
		error: GenericError | null;
		lastUpdateTime: string | null;
	};
	cashBidsList: {
		data: any[];
		loading: boolean;
		error: GenericError | null;
		lastUpdateTime: string | null;
	};
	selectedFilters: {
		products: { value: string; label: string }[];
		cashbidsCommodities: commodities[];
		locations: { value: any; label: string }[];
		cropYears: { value: any; label: string }[];
	};
	activeState: {
		commodity: commodities;
	};
	productList: {
		data: Product[];
		loading: boolean;
		error: GenericError | null;
	};
}

export type CashbidQuote = CashBidsAndQuotesState;

export interface commodities {
	value: any;
	label: string;
}
export interface FuturesQouteTab {
	name: string;
	content: any;
	icon?: any;
	className?: any;
}

export enum FilterKey {
	CashBidsCommodities = 'cashbidsCommodities',
	CropYears = 'cropYears',
	Locations = 'locations',
}
export type SelectedValue = {
	value: string | number;
	label: string | number;
	key: string | number;
};

export interface Product {
	id: string;
	fullDescription: string;
}
