/**
 *
 * TableWrapper
 *
 */
import './style.scss';

import { Button, Popover, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ContractTypePill } from 'app/components/ContractTypePill';
import { UserAvatar } from 'app/components/UserAvatar';
import { translations } from 'locales/i18n';
import { CONSTANTS, CONTRACT_TYPES, EMPTY_COLUMN } from 'utils/constants';
import {
	customFormat,
	dateFormat,
	decimalSignFormat,
	timeFormat,
} from 'utils/helpers';

import {
	selectCropYearBalanceList,
	selectEventList,
	selectLiveLedgerList,
	selectLiveLedgerListLoading,
	selectSelectedCropYear,
} from '../../selectors';
import { actions } from '../../slice';
import {
	CropYearBalance,
	Customer,
	LiveLedgerEvents,
	Transaction,
} from '../../types';
import { IconRoundedEvent } from './components/IconRoundedEvent';
import { getEventsMap } from './utils/getEventsMap';

const { Paragraph } = Typography;

export const TableWrapper = memo(function TableWrapper() {
	const { t: translate } = useTranslation();

	const loading = useSelector(selectLiveLedgerListLoading);
	const data = useSelector(selectLiveLedgerList);
	const cropYearBalanceList: CropYearBalance[] = useSelector(
		selectCropYearBalanceList,
	);
	const selectedCropYear = useSelector(selectSelectedCropYear);
	const eventList = useSelector(selectEventList);

	const scopeIntl = translations.app.containers.LiveLedger.components.Table;

	const frontMap = useMemo(() => {
		let frontMap;
		if (!eventList || eventList.length < 1) {
			frontMap = {};
		} else {
			frontMap = getEventsMap({ eventList, entityName: 'icon-round-event' });
		}
		return frontMap;
	}, [eventList]);

	const Item = (props: {
		className?: string | null;
		children: React.ReactNode;
	}) => (
		<div className="wrapper-column">
			<div className={classNames('inner', props.className)}>
				{props.children}
			</div>
		</div>
	);

	const mark = (item: number, data: Transaction) => {
		const isMarked = selectedCropYear
			? selectedCropYear === data.cropYear &&
				data.cropYear === cropYearBalanceList[item]?.cropYear
			: data.cropYear === cropYearBalanceList[item]?.cropYear;
		return data.isAffectingBalance && isMarked;
	};

	const markRejectedDenied = (data: Transaction) => {
		const event = data.event;
		const isRejectedDenied =
			!!event &&
			(event === LiveLedgerEvents.Reject || event === LiveLedgerEvents.Deny);
		const isSelected = !selectedCropYear || selectedCropYear === data.cropYear;
		return isSelected && isRejectedDenied;
	};

	const grayOut = (item: number, data: Transaction) => {
		const isGray = selectedCropYear
			? (data.cropYear !== selectedCropYear && data.cropYear !== 0) ||
				cropYearBalanceList[item]?.cropYear !== selectedCropYear
			: false;
		return isGray;
	};

	const RenderBalanceOnBeginningEvent = (
		balance: number,
		rejectedBalance: number,
		index: number,
		data: Transaction,
	) => {
		if (rejectedBalance !== 0) {
			return (
				<span className="text text--centered">
					{RenderBalanceValue(data, index, balance)}
					<Popover
						content={
							<div className="popover--content">
								{translate(scopeIntl.rejectedBalance)}
							</div>
						}
						trigger="hover"
					>
						{RenderRejectedBalanceValue(data, index, rejectedBalance)}
					</Popover>
				</span>
			);
		} else {
			return RenderBalanceValue(data, index, balance);
		}
	};
	const RenderBalance = (
		balance: number,
		rejectedBalance: number,
		index: number,
		data: Transaction,
	) => {
		if (rejectedBalance !== 0 && mark(index, data)) {
			return (
				<span className="text text--centered">
					{RenderBalanceValue(data, index, balance)}
					<Popover
						content={
							<div className="popover--content">
								{translate(scopeIntl.rejectedBalance)}
							</div>
						}
						trigger="hover"
					>
						{RenderRejectedBalanceValue(data, index, rejectedBalance)}
					</Popover>
				</span>
			);
		} else {
			return RenderBalanceValue(data, index, balance);
		}
	};
	const RenderBalanceValue = (
		data: Transaction,
		index: number,
		balance: number,
	) => {
		return (
			<p
				className={classNames('text text--centered', {
					'not-selected': grayOut(index, data),
					'text--bold': mark(index, data),
				})}
				data-testid={`contract-balance-${cropYearBalanceList[index]?.cropYear}`}
			>
				{decimalSignFormat(balance)}
			</p>
		);
	};
	const RenderRejectedBalanceValue = (
		data: Transaction,
		index: number,
		balance: number,
	) => {
		return (
			<p
				className={classNames('text text--centered rejected-balance', {
					'not-selected': grayOut(index, data),
					'text--bold': mark(index, data),
				})}
				data-testid={`contract-rejected-balance-${cropYearBalanceList[index]?.cropYear}`}
			>
				{decimalSignFormat(balance)}
			</p>
		);
	};

	const InfoTooltip = ({
		data,
		testId,
	}: {
		data: Customer;
		testId: string;
	}) => {
		const keys = Object.keys(data) as (keyof typeof data)[];
		const labels: string[] = [];
		keys.forEach((key) => {
			const capitalized = (key.charAt(0).toUpperCase() +
				key.slice(1)) as Capitalize<typeof key>;
			labels.push(translate(scopeIntl[`customer${capitalized}`]));
		});
		return (
			<div data-testid={testId}>
				{keys.map((key, index) => {
					if (!labels[index]) return null;
					return (
						<div className="tooltip" key={index}>
							<span className="tooltip-label">{labels[index]}: </span>
							{data[key] || EMPTY_COLUMN}
						</div>
					);
				})}
			</div>
		);
	};

	/**
	 * @param param0
	 * @returns checks is the contractType is valid,
	 * then it evaluates if it could show the data (internalNumber or number) or show dashes
	 */
	const getDisplayedText = ({
		contractType,
		data,
	}: {
		contractType: string | null | undefined;
		data: string | null | undefined;
	}) => {
		let displayedText: string | null = null;

		if (
			contractType != null &&
			Object.values(CONTRACT_TYPES).includes(contractType)
		) {
			displayedText = !!data ? data : EMPTY_COLUMN;
		}

		return displayedText;
	};

	/**
	 * @returns if the row has to be shown as not selected, fot the class not-selected
	 */
	const hasSelectedCropYear = ({ cropYear }: { cropYear: number }) =>
		!!selectedCropYear
			? cropYear !== selectedCropYear && cropYear !== 0
			: false;

	const columns: ColumnsType<Transaction> = [
		{
			title: translate(scopeIntl.event),
			className: 'live-ledger-column extra-small',
			render: (data: Transaction) => (
				<Item className="content-centered">
					{data.event === translate(scopeIntl.beginningBalanceState) ? (
						<div
							className="beginning-balance"
							data-testid="beginning-balance-text"
						>
							{translate(scopeIntl.beginningBalance)}
						</div>
					) : eventList && data.event ? (
						<IconRoundedEvent
							frontMap={frontMap ?? {}}
							data={data}
							externalClass={classNames({
								'not-selected': selectedCropYear
									? data.cropYear !== selectedCropYear
									: false,
							})}
						/>
					) : (
						''
					)}
				</Item>
			),
		},
		{
			title: translate(scopeIntl.contract),
			className: 'live-ledger-column medium',
			render: (data: Transaction) => (
				<Item>
					<p
						className={classNames('text text--bold', {
							'not-selected': hasSelectedCropYear({ cropYear: data.cropYear }),
						})}
						data-testid="contract-number"
					>
						{getDisplayedText({
							contractType: data.contract?.type,
							data: data.contract?.number,
						})}
					</p>
					<p
						className={classNames('text text--bold', {
							'not-selected': hasSelectedCropYear({ cropYear: data.cropYear }),
						})}
					>
						{data.contract?.type && (
							<ContractTypePill
								contractType={data.contract.type}
								isSell={data.isSell}
							/>
						)}
					</p>
				</Item>
			),
		},
		{
			title: translate(scopeIntl.hrvystId),
			className: 'live-ledger-column small',
			render: (data: Transaction) => (
				<Item>
					<p
						className={classNames('text text--centered', {
							'not-selected': hasSelectedCropYear({ cropYear: data.cropYear }),
						})}
						data-testid="hrvyst-id"
					>
						{getDisplayedText({
							contractType: data.contract?.type,
							data: data.contract?.internalNumber,
						})}
					</p>
				</Item>
			),
		},
		{
			title: translate(scopeIntl.created),
			className: 'live-ledger-column medium',
			render: (data: Transaction) => (
				<Item>
					<p
						className={classNames('text text--bold', {
							'not-selected': hasSelectedCropYear({ cropYear: data.cropYear }),
						})}
						data-testid="created-date"
					>
						{dateFormat(data.createdOn)}
					</p>
					<p
						className={classNames('text', {
							'not-selected': hasSelectedCropYear({ cropYear: data.cropYear }),
						})}
						data-testid="created-time"
					>
						{timeFormat(data.createdOn)}
					</p>
				</Item>
			),
		},
		{
			title: translate(scopeIntl.customer),
			className: 'live-ledger-column small',
			render: (data: Transaction) => {
				return (
					<Item className="content-centered">
						{data.customer != null &&
							(data.customer.name?.length ?? 0) >= 1 &&
							(data.contract?.internalNumber?.length ?? 0) >= 1 && (
								<Popover
									content={
										<InfoTooltip
											data={data.customer}
											testId={`customer-tooltip-${data.contract?.internalNumber}`}
										/>
									}
									overlayClassName="customer-tooltip"
									trigger="hover"
								>
									<Button
										type="text"
										className="customer-button"
										data-testid={`customer-button-${data.contract?.internalNumber}`}
										shape="circle"
										icon={
											<UserAvatar
												user={data.customer}
												testId={`customer-icon-${data.contract?.internalNumber}`}
												noTooltip
											/>
										}
										size="large"
									/>
								</Popover>
							)}
					</Item>
				);
			},
		},
		{
			title: translate(scopeIntl.deliveryM),
			className: 'live-ledger-column small',
			render: (data: Transaction) => (
				<Item>
					<p
						className={classNames('text text--centered', {
							'not-selected': hasSelectedCropYear({ cropYear: data.cropYear }),
						})}
						data-testid="delivery-month"
					>
						{data.deliveryMonth}
					</p>
				</Item>
			),
		},
		{
			title: translate(scopeIntl.futuresM),
			className: 'live-ledger-column small',
			render: (data: Transaction) => (
				<Item>
					<p
						className={classNames('text text--centered', {
							'not-selected': hasSelectedCropYear({ cropYear: data.cropYear }),
						})}
						data-testid="futures-month"
					>
						{data.futuresMonth}
					</p>
				</Item>
			),
		},
		{
			title: translate(scopeIntl.destination),
			className: 'live-ledger-column medium',
			render: (data: Transaction) => (
				<Item>
					<Paragraph
						className={classNames('text ellipsis', {
							'not-selected': hasSelectedCropYear({ cropYear: data.cropYear }),
						})}
						data-testid="destination"
						title={data.destination ?? undefined}
						ellipsis
					>
						{data.destination}
					</Paragraph>
				</Item>
			),
		},
		{
			title: translate(scopeIntl.quantity),
			className: 'live-ledger-column medium',
			render: (data: Transaction) => (
				<Item
					className={classNames('with-dot', {
						'rejected-or-declined': markRejectedDenied(data),
					})}
				>
					<Popover
						content={
							<div className="popover--content">
								{
									<div>
										{!!data.contract?.number
											? `# ${data.contract?.number}`
											: EMPTY_COLUMN}
									</div>
								}
								<strong>{translate(scopeIntl.tooltipStatusLabel)}</strong>
								{` ${frontMap && Object.keys(frontMap).length >= 0 ? !!frontMap[data.event] && frontMap[data.event].label : ''}`}
							</div>
						}
						trigger="hover"
						visible={
							data.event === LiveLedgerEvents.Reject ||
							data.event === LiveLedgerEvents.Deny
								? undefined
								: false
						}
						overlayClassName={classNames({
							'live-ledger__no-popoverxx':
								!(data.event === LiveLedgerEvents.Reject) &&
								!(data.event === LiveLedgerEvents.Deny),
						})}
					>
						<p
							className={classNames('text text--centered', {
								'not-selected': selectedCropYear
									? data.cropYear !== selectedCropYear
									: false,
							})}
							data-testid="quantity"
						>
							{data.quantity?.grossRemainingBalance
								? data.isSell
									? `-${customFormat(data.quantity?.grossRemainingBalance, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`
									: `+${customFormat(data.quantity?.grossRemainingBalance, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`
								: ''}
						</p>
					</Popover>
					{data.inDNH && (
						<Popover
							content={
								<span style={{ padding: '0 1em' }}>
									{translate(scopeIntl.doNotHedge)}
								</span>
							}
						>
							<span
								className={classNames({
									'yellow-dot': true,
									'not-selected': selectedCropYear
										? data.cropYear !== selectedCropYear
										: false,
								})}
							/>
						</Popover>
					)}
				</Item>
			),
		},
		{
			title: (
				<div className="crop-year-title" data-testid="crop-year-title">
					{translate(scopeIntl.cropYearsTitle)}
				</div>
			),
			children: [
				{
					title: cropYearBalanceList.length > 0 && (
						<CropYearSubtitle
							selectedCropYear={selectedCropYear}
							cropYearBalance={cropYearBalanceList[0]}
						/>
					),
					className: 'live-ledger-column crop-year-subtitle-column medium',
					render: (data: Transaction) => (
						<Item
							className={classNames({
								'affected-crop-year': mark(0, data),
							})}
						>
							{data.event === translate(scopeIntl.beginningBalanceState)
								? RenderBalanceOnBeginningEvent(
										data.balanceCrop0,
										data.rejectedBalance0,
										0,
										data,
									)
								: RenderBalance(
										data.balanceCrop0,
										data.rejectedBalance0,
										0,
										data,
									)}
						</Item>
					),
				},
				{
					title: cropYearBalanceList.length > 1 && (
						<CropYearSubtitle
							selectedCropYear={selectedCropYear}
							cropYearBalance={cropYearBalanceList[1]}
						/>
					),
					className: 'live-ledger-column crop-year-subtitle-column medium',
					render: (data: Transaction) => (
						<Item
							className={classNames({
								'affected-crop-year': mark(1, data),
							})}
						>
							{data.event === translate(scopeIntl.beginningBalanceState)
								? RenderBalanceOnBeginningEvent(
										data.balanceCrop1,
										data.rejectedBalance1,
										1,
										data,
									)
								: RenderBalance(
										data.balanceCrop1,
										data.rejectedBalance1,
										1,
										data,
									)}
						</Item>
					),
				},
				{
					title: cropYearBalanceList.length > 2 && (
						<CropYearSubtitle
							selectedCropYear={selectedCropYear}
							cropYearBalance={cropYearBalanceList[2]}
						/>
					),
					className: 'live-ledger-column crop-year-subtitle-column medium',
					render: (data: Transaction) => (
						<Item
							className={classNames({
								'affected-crop-year': mark(2, data),
							})}
						>
							{data.event === translate(scopeIntl.beginningBalanceState)
								? RenderBalanceOnBeginningEvent(
										data.balanceCrop2,
										data.rejectedBalance2,
										2,
										data,
									)
								: RenderBalance(
										data.balanceCrop2,
										data.rejectedBalance2,
										2,
										data,
									)}
						</Item>
					),
				},
				{
					title: cropYearBalanceList.length > 3 && (
						<CropYearSubtitle
							selectedCropYear={selectedCropYear}
							cropYearBalance={cropYearBalanceList[3]}
						/>
					),
					className: 'live-ledger-column crop-year-subtitle-column medium',
					render: (data: Transaction) => (
						<Item
							className={classNames({
								'affected-crop-year': mark(3, data),
							})}
						>
							{data.event === translate(scopeIntl.beginningBalanceState)
								? RenderBalanceOnBeginningEvent(
										data.balanceCrop3,
										data.rejectedBalance3,
										3,
										data,
									)
								: RenderBalance(
										data.balanceCrop3,
										data.rejectedBalance3,
										3,
										data,
									)}
						</Item>
					),
				},
			],
		},
	];

	return (
		<Table
			data-testid="live-ledger-table"
			columns={columns}
			loading={loading}
			pagination={false}
			dataSource={data?.filter(
				(d) =>
					selectedCropYear === null ||
					d.cropYear === 0 ||
					d.cropYear === selectedCropYear,
			)}
			scroll={{ x: 'max-content' }}
			rowKey="id"
			sticky
			onRow={onRow}
		/>
	);
});

const onRow = (data: Transaction) => {
	return {
		className: '',
		'data-testid': `row-${data.contract?.internalNumber}`,
	};
};

function CropYearSubtitle({
	selectedCropYear,
	cropYearBalance,
}: {
	selectedCropYear: number | null;
	cropYearBalance: CropYearBalance;
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const popover =
		selectedCropYear === cropYearBalance.cropYear
			? t(
					translations.app.containers.LiveLedger.components.CropYearCards
						.clearFilter,
				)
			: t(
					translations.app.containers.LiveLedger.components.CropYearCards
						.filter,
				);

	const button = (
		<button
			type="button"
			className={classNames('crop-year-subtitle', {
				'affected-crop': selectedCropYear === cropYearBalance.cropYear,
			})}
			disabled={!cropYearBalance.isActive}
			onClick={() =>
				dispatch(
					actions.changeSelectedFilters({
						selectedCropYear:
							selectedCropYear === cropYearBalance.cropYear
								? null
								: cropYearBalance.cropYear,
					}),
				)
			}
			data-testid={`crop-year-${cropYearBalance.cropYear}`}
		>
			{cropYearBalance.cropYear}
		</button>
	);

	if (cropYearBalance.isActive) {
		return (
			<Popover
				content={
					<div
						style={{ padding: '0 1rem' }}
						data-testid="crop-year-filter-tooltip"
					>
						{popover}
					</div>
				}
			>
				{button}
			</Popover>
		);
	} else {
		return button;
	}
}
