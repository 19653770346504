import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { futureMonthDTOSchema } from 'types/FutureMonth';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';

const { offersCatalogFuturesMonths } = apiEndpoints;

export function* getCatalogFuturesMonthsList(
	type: ReturnType<typeof actions.loadCatalogFuturesMonthsList>,
) {
	const { source } = type.payload;
	const requestURL = new URL(`${apiRoutes.base}/${offersCatalogFuturesMonths}`);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(futureMonthDTOSchema),
	});

	// Success actions
	if (!!data) {
		yield* put(
			actions.catalogFuturesMonthsListLoaded({
				source,
				data: data.map((item) => ({ id: item.name, ...item })),
			}),
		);
	} else if (!!error) {
		yield* put(actions.catalogFuturesMonthsListError({ source, error }));
	}
}
