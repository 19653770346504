import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { selectFilters } from 'app/containers/PreHedge/selectors';
import { getSelectedFilter } from 'app/containers/ReviewAndRelease/utils/helpers';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { fileRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { PreHedgeFilters } from '../types';

const { preHedgeExport } = apiEndpoints;

export function* exportPreHedge(
	action: PayloadAction<{ successMsg: string; errorMsg: string }>,
) {
	const { successMsg, errorMsg } = action.payload;
	const selectedFilters: PreHedgeFilters = yield select(selectFilters);
	const requestURL = yield new URL(`${apiRoutes.base}/${preHedgeExport}`);

	const selectedStatus = getSelectedFilter('status', 'value', selectedFilters);
	const selectedFuturesMonth = getSelectedFilter(
		'futuresMonth',
		'value',
		selectedFilters,
	);
	const selectedSymbol = getSelectedFilter('symbol', 'value', selectedFilters);

	const data = {
		...selectedFilters,
		status: selectedStatus,
		futuresMonth: selectedFuturesMonth,
		symbol: selectedSymbol,
	};

	const { responseData, responseError } = yield call(
		fileRequest,
		requestURL,
		httpMethod.Post,
		false,
		data,
		null,
		true,
		true,
	);
	if (!!responseData) {
		yield put(actions.exportedPreHedge(responseData));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMsg + responseData.fileName,
			}),
		);
		yield put(actions.clearExportPreHedge());
	} else if (!!responseError.detail) {
		yield put(actions.exportPreHedgeError(responseError));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.ERROR,
				message: errorMsg,
			}),
		);
	}
}
