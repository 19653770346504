import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { useTranslations as useQtyToPriceTranslations } from 'app/containers/Contracts/components/Modals/shared/useTranslations';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { selectDefaultPageData } from 'app/containers/GlobalSaga/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { Customer } from 'app/containers/Transactions/components/Customer';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { NetBasis } from 'app/containers/Transactions/components/NetBasis';
import { Quantity } from 'app/containers/Transactions/components/Quantity';
import { TheirContract } from 'app/containers/Transactions/components/TheirContract';
import { Transaction } from 'app/containers/Transactions/components/Transaction';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { ActionType } from 'types/ActionType';
import { ContractDetails } from 'types/ContractDetails';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import { CONSTANTS } from 'utils/constants';
import {
	customFormat,
	isDeliveryDateCustom,
	isDeliveryDateWindow,
} from 'utils/helpers';

import {
	validateMinMaxValue,
	validateMinQuantity,
	validatePrice,
} from '../../shared/helpers';
import { useTranslations } from '../../shared/useTranslations';

export const useBasisSchema = (isAdjustment = false) => {
	const translations = useTranslations();
	const defaultData = useSelector(selectDefaultPageData);

	const qtyToPriceTranslations = useQtyToPriceTranslations();

	const orderData = useSelector(selectActiveContract) as ContractDetails;

	const { elements, initialValues, validationSchema } =
		useOrderEntriesFormSchema();

	const quantityErrorMsg =
		translations.validations.createContractMinQuantityValidation;
	const numberMsg = translations.validations.number;

	const requiredMsg = translations.validations.required;
	const maxLength10Msg = translations.validations.maxLength10;
	const basisFormatError = translations.validations.basisFormatError;

	return {
		initialValues: {
			action: ActionType.BUY,
			commodity: { value: null },
			location: { value: null },
			deliveryLocation: { value: null },
			assignedRegion: { value: null },
			deliveryDate: null,
			deliveryDatesMode: { value: DeliveryDateMode.Window },
			deliveryDateWindow: { value: null },
			cropYear: null,
			futuresMonth: { value: null },
			futuresPrice: null,
			postedBasis: null,
			pushBasis: null,
			netBasis: null,
			freight: null,
			fees1: null,
			fees2: null,
			flatPrice: null,
			netFutures: null,
			netBasisPrice: null,
			quantity: customFormat(
				orderData.quantity,
				true,
				CONSTANTS.FIXED_QUANTITY_DECIMALS,
			),
			gtcMode: false,
			expirationDate: null,
			customer: { value: null },
			employee: {
				label: defaultData?.employeeName,
				value: defaultData?.employeeId,
			},
			comments: '',
			basis: null,
			futures: null,
			passFill: false,
			doNotHedge: false,
			useServiceFees: false,
			...initialValues,
		},

		validationSchema: Yup.object().shape({
			theirContract: Yup.string().nullable().max(10, maxLength10Msg),
			transaction: Yup.object().requiredOption(requiredMsg),
			contractNumber: Yup.string().nullable(),
			contract: Yup.object().requiredOption(requiredMsg),
			commodity: Yup.object().requiredOption(requiredMsg),
			location: Yup.object().requiredOption(requiredMsg),
			deliveryLocation: Yup.object().requiredOption(requiredMsg),
			assignedRegion: Yup.object().requiredOption(requiredMsg),
			deliveryDate: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateCustom(mode),
				then: Yup.mixed().required(requiredMsg),
			}),
			deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateWindow(mode),
				then: Yup.object().requiredOption(requiredMsg),
			}),
			freight: Yup.number().typeError(numberMsg).nullable(),
			postedBasis: Yup.number()
				.required(requiredMsg)
				.typeError(numberMsg)
				.test('priceValidation', basisFormatError, (value: string) =>
					validatePrice(value),
				),
			pushBasis: Yup.number().typeError(numberMsg).nullable(),
			fees: Yup.number().typeError(numberMsg),
			netBasisPrice: Yup.number().typeError(numberMsg),
			quantity: Yup.string()
				.typeError(numberMsg)
				.required(requiredMsg)
				.required(translations.validations.required)
				.test(
					'minMaxValidation',
					qtyToPriceTranslations.validations.rangeValue(
						customFormat(
							orderData.remainingBalance,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					),
					(value: string) =>
						validateMinMaxValue(orderData.remainingBalance, value),
				)
				.test('minValidation', quantityErrorMsg, (value: string) =>
					validateMinQuantity(value),
				),
			customer: Yup.object().requiredOption(requiredMsg),
			employee: Yup.object().requiredOption(requiredMsg),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
			...validationSchema,
		}),
		elements: [
			<TheirContract />,
			<Transaction disabled hidden />,
			<ContractNumber />,
			<ContractType
				resetDependenciesOnChange={false}
				disableBuySell={true}
				ShowNTC
			/>,
			<Commodity checkDefaultValues disabled />,
			<Location />,
			<DeliveryLocation />,
			<AssignedRegion />,
			<CropYears disabled />,
			<DeliveryDates checkDefaultValues />,
			<Quantity />,
			<Futures
				checkDefaultValues
				futuresMonth={{
					market: true,
					disableWithinDeliveryMode: true,
				}}
			/>,
			<Basis disabled={{ netBasis: true }} adjustedBasis datesModeDependency />,
			<ExpirationDate />,
			<Freight />,
			<Fees />,
			<NetBasis />,
			<Customer disabled />,
			<Employee />,
		]
			.concat(elements)
			.concat([<Comments />]),
	};
};
