import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { Modal, PublishType, TableTypes } from '../types';

const { bidsheetPublish } = apiEndpoints;

export function* loadBidsheetSummary() {
	const requestURL = yield new URL(`${apiRoutes.base}/${bidsheetPublish}`);
	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.bisheetSummaryLoaded(responseData));
		yield put(actions.setCurrentModal(Modal.Publish));
	} else if (!!responseError.detail) {
		yield put(actions.bisheetSummaryError(responseError));
	}
}

export function* publishBidsheet(action: PayloadAction<PublishType>) {
	const domain =
		translations.app.containers.Settings.sections.Bidsheet.Modals
			.BidsheetPublish;
	const requestURL = yield new URL(`${apiRoutes.base}/${bidsheetPublish}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
	);

	// Success actions
	if (!!responseData) {
		if (action.payload === PublishType.publishAndClose) {
			yield put(actions.setCurrentModal(null));
			yield put(actions.resetBidsheetPublished());
		} else if (action.payload === PublishType.publishAndRoll) {
			yield put(actions.requestNoBidList());
		}
		yield put(actions.loadBidsheetList({ type: TableTypes.Filter }));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				title: { key: domain.succesMessageTitle },
				showDescription: false,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.bidsheetPublishError(responseError));
	}
}
