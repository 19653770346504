import {
	CheckCircleFilled,
	PauseCircleOutlined,
	PlayCircleOutlined,
} from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmDialog } from 'app/components/ConfirmDialog';
import { GenericTable } from 'app/components/GenericTable';
import { NotificationAlertTone } from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';

import { selectNotificationAlerts } from '../../../selectors';
import { actions } from '../../../slice';

const { Paragraph } = Typography;
const TOGGLE_WIDTH = 70;

export const AlertTones = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const notificationAlerts = useSelector(selectNotificationAlerts);
	const translationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;

	const [playingAudio, setPlayingAudio] = useState(null);
	const audioRef: MutableRefObject<HTMLMediaElement> = useRef(new Audio());

	const playAudio = (src) => {
		const currentAudio = audioRef.current;

		if (src === playingAudio) {
			audioRef.current.pause();
			setPlayingAudio(null);
		} else {
			audioRef.current.src = src;
			audioRef.current.play();
			setPlayingAudio(src);
		}

		currentAudio.addEventListener('ended', (e) => setPlayingAudio(null));
	};

	const alertColumns = [
		{
			key: 'name',
			render: (data: NotificationAlertTone) => {
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--label ellipsis"
							title={translate(translationsScope.Sounds[data.id])}
							ellipsis={{ rows: 2 }}
						>
							{translate(translationsScope.Sounds[data.id])}
						</Paragraph>
						<audio src={data.src}></audio>
					</GenericTable.Column>
				);
			},
		},
		{
			width: TOGGLE_WIDTH,
			render: (_, record: NotificationAlertTone) => (
				<GenericTable.Column>
					<Button
						type="link"
						shape="circle"
						icon={
							record.src === playingAudio ? (
								<PauseCircleOutlined />
							) : (
								<PlayCircleOutlined />
							)
						}
						size="large"
						onClick={() => playAudio(record.src)}
					/>
				</GenericTable.Column>
			),
		},
		{
			width: TOGGLE_WIDTH,
			render: (_, record: NotificationAlertTone) => (
				<GenericTable.Column>
					<ConfirmDialog
						placement="bottomRight"
						message={translate(translationsScope.ToggleOnMessage)}
						handleConfirm={() =>
							dispatch(
								actions.setUserNotificationsAlertTone({
									alertSound: record.id,
								}),
							)
						}
						confirmText={translate(translationsScope.ConfirmSwitchText)}
						cancelText={translate(translationsScope.CancelSwitchText)}
						disabled={record.isActive}
						trigger={
							record.isActive ? (
								<div className="circle-filled">
									<CheckCircleFilled />
								</div>
							) : (
								<Button type="link" block={true}>
									{translate(translationsScope.ActivateButtonText)}
								</Button>
							)
						}
						withOverlay={false}
					/>
				</GenericTable.Column>
			),
		},
	];

	return (
		<GenericTable.Table
			pagination={false}
			columns={alertColumns}
			data={notificationAlerts}
		/>
	);
};
