import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '../../slice';
import { Headers } from './Header';
import { TableWrapper } from './Table';

export const List = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(actions.loadCommoditiesList());
		// eslint-disable-next-line
	}, []);
	return (
		<div className="list-container">
			<Headers />
			<TableWrapper />
		</div>
	);
};
