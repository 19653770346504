import './style.scss';

import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { SwitchStateFull } from 'app/components/SwitchStateFull';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';

import { CurrentGroupedLocation } from '../../../fields/types';
import {
	selectCurrentLocationError,
	selectCurrentLocationId,
	selectFilters,
	selectListData,
	selectListLoading,
	selectListTotal,
	selectPaginationLimit,
	selectPaginationStart,
} from '../../../selectors';
import { actions } from '../../../slice';
import { Actions } from './Actions';

const { Paragraph } = Typography;

export const Table = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.Locations.View.List.Table;
	const translationsShared = translations.app.containers.Settings.Shared;

	const customHandleToggle = (
		status: boolean,
		record: CurrentGroupedLocation,
	) => {
		if (!record.destinationLocationNumber && !record.destinationLocationName) {
			return dispatch(
				globalActions.addNotification({
					type: CustomNotificationTypes.ERROR,
					message: translate(
						translationsScope.Headers.deliveryLocationRequired,
					),
				}),
			);
		}
		if (!record.contractLocationNumber && !record.contractLocationName) {
			return dispatch(
				globalActions.addNotification({
					type: CustomNotificationTypes.ERROR,
					message: translate(
						translationsScope.Headers.contractLocationRequired,
					),
				}),
			);
		}
		if (!record.regionName) {
			return dispatch(
				globalActions.addNotification({
					type: CustomNotificationTypes.ERROR,
					message: translate(translationsScope.Headers.regionRequired),
				}),
			);
		}
		dispatch(actions.toggleStatus({ id: record.id, isActive: status }));
	};

	const dispatch = useDispatch();
	const listData = useSelector(selectListData);
	const listTotal = useSelector(selectListTotal);
	const listLoading = useSelector(selectListLoading);
	const start = useSelector(selectPaginationStart);
	const limit = useSelector(selectPaginationLimit);
	const filters = useSelector(selectFilters);
	const renderParagraph = (data: string) => (
		<GenericTable.Column>
			<Paragraph
				className="text text--large ellipsis"
				title={data}
				ellipsis={{ rows: 2 }}
			>
				<p className="text text--large">{data}</p>
			</Paragraph>
		</GenericTable.Column>
	);
	const columns = [
		{
			title: translate(translationsScope.Headers.Status),
			dataIndex: 'isActive',
			key: 'isActive',
			fixed: true,
			width: 70,
			render: (data: boolean, record: CurrentGroupedLocation) => (
				<GenericTable.Column>
					<SwitchStateFull
						initialStatus={data}
						recordId={record.id}
						toggleAction={actions.toggleStatus}
						customHandleConfirm={(status: boolean) =>
							customHandleToggle(status, record)
						}
						activeRecordSelector={selectCurrentLocationId}
						errorSelector={selectCurrentLocationError}
						toogleOnMessage={translate(
							translationsScope.activateSwitch.toogleOnMessage,
						)}
						toggleOffMessage={translate(
							translationsScope.activateSwitch.toggleOffMessage,
						)}
						confirmButtonText={translate(
							translationsScope.activateSwitch.confirmButtonText,
						)}
						cancelButtonText={translate(
							translationsScope.activateSwitch.cancelButtonText,
						)}
						stateControlled={false}
					/>
				</GenericTable.Column>
			),
		},
		{
			title: (
				<div className="table__header">
					{translate(translationsScope.Headers.ContractLocationErp)}
				</div>
			),
			key: 'contractLocationNumber',
			dataIndex: 'contractLocationNumber',
			width: 195,
			render: renderParagraph,
		},
		{
			title: translate(translationsScope.Headers.ContractLocationName),
			key: 'contractLocationName',
			dataIndex: 'contractLocationName',
			width: 110,
			render: renderParagraph,
		},
		{
			title: translate(translationsScope.Headers.DeliveryLocationErp),
			dataIndex: 'destinationLocationNumber',
			key: 'destinationLocationNumber',
			fixed: true,
			width: 185,
			render: renderParagraph,
		},
		{
			title: translate(translationsScope.Headers.DeliveryLocationName),
			key: 'destinationLocationName',
			dataIndex: 'destinationLocationName',
			width: 110,
			fixed: true,
			render: renderParagraph,
		},
		{
			title: translate(translationsShared.RegionHeader),
			key: 'regionName',
			dataIndex: 'regionName',
			width: 100,
			fixed: true,
			render: renderParagraph,
		},
		{
			fixed: 'right' as 'right',
			width: 15,
			render: (data: CurrentGroupedLocation) => (
				<GenericTable.Column>
					<Actions rowData={data} />
				</GenericTable.Column>
			),
		},
	];

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadLocationsList());
	};

	return (
		<GenericTable.Table
			otherClassName="table-container locations"
			columns={columns}
			data={listData}
			loading={listLoading}
			selectedFilters={filters}
			pagination={{
				total: listTotal,
				pageSize: limit,
				current: start,
				onChange: handleOnChange,
			}}
			emptyText={translate(translationsShared.Table.emptyText, {
				query: filters.name,
			})}
		/>
	);
};
