import './style.scss';

import { Form, Select, SelectProps } from 'formik-antd';
import React, { memo } from 'react';

import { GenericOption } from 'types/GenericOption';

interface Props extends SelectProps {
	name: string;
	label?: string;
	placeholder?: string;
	options: GenericOption[];
	onSearch: (string: string) => void;
	onSelect?: (values: GenericOption[]) => void;
	onBlur?: () => void;
}

export const FormSearch = memo(function FormSearch(props: Props) {
	const {
		onSearch,
		onBlur,
		onSelect,
		name,
		label,
		placeholder,
		options,
		labelInValue = true,
	} = props;

	const handleBlur = () => {
		onBlur && onBlur();
	};

	const handleChange = (params: GenericOption[]) => {
		onSelect && onSelect(params);
	};

	return (
		<Form.Item label={label} name={name} className="search-form__label">
			<Select
				{...props}
				name={name}
				placeholder={placeholder}
				options={options}
				showSearch
				showArrow={false}
				labelInValue={labelInValue}
				onSearch={onSearch}
				onChange={handleChange}
				onBlur={handleBlur}
				allowClear={true}
			/>
		</Form.Item>
	);
});
