import { CONTRACT_TYPES } from 'utils/constants';

import { useTranslations } from '../../../shared/useTranslations';
import { useBasisSchema } from './BasisSchema';
import { useFlatPriceSchema } from './FlatPriceSchema';
import { useHTASchema } from './HTASchema';
import { useNTCSchema } from './NTCSchema';

export const useCashContractSchema = (
	translations: ReturnType<typeof useTranslations>,
) => ({
	[CONTRACT_TYPES.flatPrice]: useFlatPriceSchema(translations, true),
	[CONTRACT_TYPES.basis]: useBasisSchema(translations, true),
	[CONTRACT_TYPES.hta]: useHTASchema(translations, true),
	[CONTRACT_TYPES.ntc]: useNTCSchema(translations, true),
});
