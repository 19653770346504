/* --- STATE --- */
import { Permission } from 'types/Authorization';
import { GenericError } from 'types/GenericError';
export interface UserRolesState {
	currentView: View;
	currentModal: Modal | null;

	activeUserRoles: {
		data: any;
		error: GenericError | null;
		loading: boolean;
	};

	list: {
		data: [];
		loading: boolean;
		error: GenericError | null;
	};

	userRolesStatus: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	createUserRole: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	userRoleDetails: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	roleLocations: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	roleLimits: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};
}

export enum View {
	List = 'list',
	Create = 'create',
	Edit = 'edit',
}

export enum Modal {
	Delete = 'Delete',
}

export interface CreateEditRolePayload {
	currentRoleName: any;
	data: {
		name: string;
		claimGrant: Permission[];
	};
	actionType: View;
	successMessage: string;
	rolesLocations: any;
	bushelsLimit: number;
	roleId?: string;
}

export type UserRolesSectionState = UserRolesState;
