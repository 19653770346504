import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { selectUserInfo } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import {
	CustomNotificationTypes,
	NotificationAlertTone,
} from 'app/containers/GlobalSaga/types';
import { selectActiveAlertTone } from 'app/containers/HubSaga/selectors';
import { actions as hubActions } from 'app/containers/HubSaga/slice';
import { UserInfo } from 'types/UserInfo';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { mapAlertTones } from 'utils/notifications';
import { getAlertToneById } from 'utils/notifications';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import {
	NotificationAlertTonePayload,
	NotificationEventPayload,
} from '../types';

export function* getUserPreferences() {
	const { employeeNotificationSettings } = apiEndpoints;
	const userInfo: UserInfo = yield select(selectUserInfo);
	const requestURL = yield new URL(
		`${apiRoutes.base}/${employeeNotificationSettings}/${userInfo.email}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		globalActions.addNotification({
			type: CustomNotificationTypes.SUCCESS,
		});
		yield put(actions.userPreferencesLoaded(responseData));
	}

	if (!!responseError) {
		yield put(actions.userPreferencesError(responseError));
	}
}

export function* setUserPreferenceStatus(
	action: PayloadAction<NotificationEventPayload>,
) {
	const { userNotificationSubscriptions } = apiEndpoints;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${userNotificationSubscriptions}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		action.payload,
	);

	// Success actions
	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.getUserPreferences());
	}

	if (!!responseError) {
		yield put(actions.setTenantPreferenceStatusError(responseError));
	}
}

export function* getUserAlertTones() {
	const activeAlertTone: NotificationAlertTone = yield select(
		selectActiveAlertTone,
	);
	yield put(
		actions.userNotificationAlertTonesLoaded(
			mapAlertTones(activeAlertTone?.id),
		),
	);
}

// Makes a request to the API to set the user's alert tone preference
export function* setUserAlertTone(
	action: PayloadAction<NotificationAlertTonePayload>,
) {
	const userInfo: UserInfo = yield select(selectUserInfo);
	const { notificationAlertTones } = apiEndpoints;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${notificationAlertTones}/${userInfo.email}/select`,
	);

	const { responseData } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		null,
		true,
		action.payload,
	);
	const { alertSound: alertSoundId } = action.payload;

	// Success actions
	if (!!responseData) {
		const selectedAlertTone = getAlertToneById(alertSoundId);

		yield put(
			actions.userNotificationAlertTonesLoaded(mapAlertTones(alertSoundId)),
		);

		if (selectedAlertTone) {
			yield put(hubActions.setActiveAlertTone(selectedAlertTone));
		}
	}
}

export function* getUserAssignedGroups() {
	const userInfo: UserInfo = yield select(selectUserInfo);
	const { employeeGroups } = apiEndpoints;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${employeeGroups}/${userInfo.email}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.userAssignedGroupsLoaded(responseData));
	}

	if (!!responseError) {
		yield put(actions.userAssignedGroupsError(responseError));
	}
}
