import React, { memo } from 'react';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { DrawerWrapper } from './components/DrawerWrapper';
import { summaryDashboardSaga } from './root-saga';
import { reducer, sliceKey } from './slice';

export const SummaryDashboard = memo(function SummaryDashboard() {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: summaryDashboardSaga });

	return <DrawerWrapper />;
});
