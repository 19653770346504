import React, { memo } from 'react';

import { GenericForm } from 'app/components/GenericForm';
import { SwitchStateFull } from 'app/components/SwitchStateFull';
import { translations } from 'locales/i18n';

import { useContractIntegrationSwitchFormData } from '../../hooks/useContractIntegrationSwitchFormData';
import { actions } from '../../slice';
import { SWITCH_FORM_ITEMS } from '../../types';

const domain =
	translations.app.containers.Settings.sections.ERPIntegration.components
		.ContractIntegrationSwitch;

export const ContractIntegrationSwitch = memo(
	function ContractIntegrationSwitch() {
		const { dispatch, erpIntegration, initialValues, translate } =
			useContractIntegrationSwitchFormData();

		const getTranslation = (key: string) => translate(domain[key]);

		const handleConfirm = (active: boolean) => {
			const data = {
				...erpIntegration,
				[SWITCH_FORM_ITEMS.ERPIntegration]: !active,
			};

			dispatch(actions.setERPIntegrationRequest({ data }));
		};

		return (
			<GenericForm.FormItem
				colon={false}
				data-testid={`${SWITCH_FORM_ITEMS.ERPIntegration}-form-item`}
				label={getTranslation('label')}
				name={SWITCH_FORM_ITEMS.ERPIntegration}
			>
				<SwitchStateFull
					cancelButtonText={getTranslation('cancelButtonText')}
					confirmButtonText={getTranslation('confirmButtonText')}
					customHandleConfirm={handleConfirm}
					errorSelector={actions.setERPIntegrationError}
					initialStatus={initialValues[SWITCH_FORM_ITEMS.ERPIntegration]}
					recordId={SWITCH_FORM_ITEMS.ERPIntegration}
					stateControlled={false}
					toggleAction={actions.setERPIntegrationRequest}
					toggleOffMessage={getTranslation('toggleOffMessage')}
					toogleOnMessage={getTranslation('toggleOnMessage')}
				/>
			</GenericForm.FormItem>
		);
	},
);
