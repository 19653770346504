import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

export const useTranslations = () => {
	const { t: translate } = useTranslation();

	const scope = translations.app.containers.PreHedge.components.Modals;
	const sharedScope = translations.app.containers.Transactions.Modals;

	return {
		actions: {
			newPreHedgeTitle: translate(scope.newPreHedgeTitle),
			createConfirmationMessage: translate(
				scope.preHedgeCreateConfirmationMessage,
			),
			confirmation: translate(scope.confirmation),
			confirmText: translate(scope.confirmText),
			cancelText: translate(scope.cancelText),
			editConfirmationMessage: translate(scope.editConfirmationMessage),
			editPreHedgeTitle: translate(scope.editPreHedgeTitle),
		},
		buttons: {
			submitAndNewPreHedge: translate(scope.buttons.submitAndNewPreHedge),
			submitPreHedge: translate(scope.buttons.submitPreHedge),
			cancelChanges: translate(scope.buttons.cancelChanges),
		},
		validations: {
			number: translate(sharedScope.validations.number),
			required: translate(sharedScope.validations.required),
		},
		common: {
			success: translate(translations.app.commons.success.description),
		},
	};
};
