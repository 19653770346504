import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';

interface Props {
	disabled?: boolean;
}

export const OriginCustomer = memo(function OriginCustomer(props: Props) {
	const { disabled = false } = props;

	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Contracts.components.OriginCustomer;

	return (
		<GenericForm.FormItem
			customClassName={['search-form__label']}
			data-testid="customer-form-item"
			label={translate(msg.label)}
			name="origCustomer"
		>
			<Dropdown disabled={disabled} key="contract" options={[]} />
		</GenericForm.FormItem>
	);
});
