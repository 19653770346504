import './style.scss';

import classNames from 'classnames';
import { Checkbox, Form } from 'formik-antd';
import React, { memo } from 'react';

interface Props {
	name: string;
	cropList: any;
	label?: string;
	checkbox?: boolean;
	disable?: boolean;
	isDateRangeSelected?: boolean;
	handleChange?: (event) => void;
	colon?: boolean;
}

export const CropYearsList = memo(function CropYearsList(props: Props) {
	const {
		name,
		label,
		cropList,
		checkbox,
		handleChange,
		disable,
		isDateRangeSelected,
		colon = true,
	} = props;

	return (
		<>
			{!!isDateRangeSelected && (
				<Form.Item
					name={name}
					label={label}
					className={classNames('crop-year-list__form-label', {
						'two-row-label': checkbox,
					})}
					colon={colon}
				>
					{cropList.map((label) => {
						return (
							<div
								key={label}
								className={classNames('crop-year-list__container', {
									'crop-year-list__container--shift': checkbox,
								})}
							>
								{checkbox && (
									<Checkbox
										name={name}
										onChange={handleChange}
										className="crop-year-list__checkbox"
									/>
								)}
								<p
									className={classNames('crop-year-list__container', {
										'crop-year-list-text--disable': disable,
									})}
								>
									{label}
								</p>
							</div>
						);
					})}
				</Form.Item>
			)}
		</>
	);
});
