import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { NotificationGroupPayload, View } from '../types';

const { employeeGroupNotificationSettings, employeeGroupAllNonMembers } =
	apiEndpoints;

export function* loadEmployeeGroups() {
	const { employeeGroupNotificationSettings } = apiEndpoints;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${employeeGroupNotificationSettings}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		null,
		false,
		{
			includeSystemGroups: true,
		},
	);

	if (!!responseData) {
		yield put(actions.employeeGroupsLoaded(responseData));
	}

	if (!!responseError) {
		yield put(actions.employeeGroupsError(responseError));
	}
}

export function* loadEmployeeGroupDetails(action: PayloadAction<string>) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${employeeGroupNotificationSettings}/${action.payload}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.employeeGroupDetailsLoaded(responseData));
		yield put(actions.setCurrentView(View.Edit));
	}

	if (!!responseError) {
		yield put(actions.employeeGroupDetailsError(responseError));
	}
}

export function* loadEmployeeGroupMembers(action: PayloadAction<string>) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${employeeGroupNotificationSettings}/${action.payload}/members`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.employeeGroupMembersLoaded(responseData));
	}

	if (!!responseError) {
		yield put(actions.employeeGroupMembersError(responseError));
	}
}

export function* loadEmployeeGroupNonMembers(
	action: PayloadAction<string | undefined>,
) {
	let requestURL: URL;

	if (action.payload) {
		requestURL = yield new URL(
			`${apiRoutes.base}/${employeeGroupNotificationSettings}/${action.payload}/non-members`,
		);
	} else {
		requestURL = yield new URL(
			`${apiRoutes.base}/${employeeGroupAllNonMembers}`,
		);
	}
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.employeeGroupNonMembersLoaded(responseData));
	}

	if (!!responseError) {
		yield put(actions.employeeGroupNonMembersError(responseError));
	}
}

export function* createEmployeeGroup(
	action: PayloadAction<NotificationGroupPayload>,
) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${employeeGroupNotificationSettings}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		action.payload,
	);

	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.setCurrentView(View.List));
		yield put(globalActions.loadEmployeeNotificationGroupList());
	}

	if (!!responseError) {
		yield put(globalActions.employeeNotificationGroupListError(responseError));
	}
}

export function* deleteEmployeeGroup(action: PayloadAction<string>) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${employeeGroupNotificationSettings}/${action.payload}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Delete,
	);

	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(globalActions.loadEmployeeNotificationGroupList());
	}

	if (!!responseError) {
		yield put(actions.deleteEmployeeGroupError(responseError));
	}
}

export function* updateEmployeeGroup(
	action: PayloadAction<NotificationGroupPayload>,
) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${employeeGroupNotificationSettings}/${action.payload.id}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		action.payload,
	);

	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.setCurrentView(View.List));
		yield put(actions.clearEmployeeGroupState());
		yield put(globalActions.loadEmployeeNotificationGroupList());
	}

	if (!!responseError) {
		yield put(actions.updateEmployeeGroupError(responseError));
	}
}
