import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'app/components/Checkbox';
import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';

interface Props {}

export const CashSettlement = memo(function CashSettlement(props: Props) {
	const { t: translate } = useTranslation();
	const msg =
		translations.app.containers.Transactions.components.CashSettlement;

	return (
		<GenericForm.FormItem
			name="cashSettlement"
			wrapperCol={{ span: 4, offset: 3 }}
			data-testid="cashSettlement-form-item"
		>
			<Checkbox
				key="cashSettlement"
				disabled={false}
				label={translate(msg.label)}
			/>
		</GenericForm.FormItem>
	);
});
