import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslations as useQtyToPriceTranslations } from 'app/containers/Contracts/components/Modals/shared/useTranslations';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { Customer } from 'app/containers/Transactions/components/Customer';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { NetBasis } from 'app/containers/Transactions/components/NetBasis';
import { Quantity } from 'app/containers/Transactions/components/Quantity';
import { TheirContract } from 'app/containers/Transactions/components/TheirContract';
import { Transaction } from 'app/containers/Transactions/components/Transaction';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { useTranslations } from 'app/containers/Transactions/hooks/useTranslations';
import { CONSTANTS, MIN_VALUE } from 'utils/constants';
import {
	customFormat,
	getActionType,
	getDeliveryDatesMode,
	getDeliveryDateWindow,
	getExpirationDate,
	isDeliveryDateCustom,
	isDeliveryDateWindow,
	validatePriceFormat,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { validateMinMaxValue, validateMinQuantity } from '../../shared/helpers';

export const useNTCSchema = () => {
	const translations = useTranslations();
	const qtyToPriceTranslations = useQtyToPriceTranslations();
	let orderData = useSelector(selectActiveContract) as any;

	const { elements, initialValues, validationSchema } =
		useOrderEntriesFormSchema(orderData);

	const quantityErrorMsg =
		translations.validations.createContractMinQuantityValidation;
	const numberMsg = translations.validations.number;
	const maxLength10Msg = translations.validations.maxLength10;
	const requiredMsg = translations.validations.required;

	return {
		initialValues: {
			action: getActionType(orderData.isSell),
			commodity: {
				label: orderData.commodityName,
				value: orderData.commodityId,
			},
			location: {
				label: orderData.locationName,
				value: orderData.locationId,
			},
			deliveryLocation: {
				label: orderData.deliveryLocationName,
				value: orderData.deliveryLocationId,
			},
			assignedRegion: {
				label: orderData?.regionName,
				value: orderData?.regionId,
			},
			deliveryDatesMode: getDeliveryDatesMode(orderData),
			deliveryDateWindow: getDeliveryDateWindow(orderData),
			cropYear: orderData.cropYear,

			theirContract: orderData.theirContract,
			transaction: {
				label: orderData.transactionTypeName,
				value: orderData.transactionTypeId,
			},
			contractNumber: orderData.number,
			contract: {
				label: orderData.contractTypeName,
				value: orderData.contractTypeId,
			},
			futuresMonth: {
				label: orderData.futuresMonth,
				value: orderData.futuresMonth,
			},
			futuresPrice: validatePriceFormat(orderData.futuresPrice),
			qtyPriceBalance: `${customFormat(orderData.remainingBalance, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`,
			qtyPriceAmount: customFormat(
				orderData.quantity,
				true,
				CONSTANTS.FIXED_QUANTITY_DECIMALS,
			),
			efpBushels: MIN_VALUE,
			postedBasis: validatePriceFormat(orderData.postedBasis),
			pushBasis: validatePriceFormat(orderData.pushBasis),
			netBasis: validatePriceFormat(orderData.netBasis),
			freight: validatePriceFormat(orderData.freightPrice),
			fees1: validatePriceFormat(orderData.fees1),
			fees2: validatePriceFormat(orderData.fees2),
			flatPrice: validatePriceFormat(orderData.price),
			netFutures: validatePriceFormat(orderData.price),
			netBasisPrice: validatePriceFormat(orderData.price),
			quantity: customFormat(
				orderData.quantity,
				true,
				CONSTANTS.FIXED_QUANTITY_DECIMALS,
			),
			deliveryDate: [
				moment(orderData.deliveryStartDate),
				moment(orderData.deliveryEndDate),
			],
			customer: {
				label: orderData.customerName,
				value: orderData.customerId,
			},
			employee: {
				label: orderData.employeeName,
				value: orderData.employeeId,
			},
			comments: orderData.comments,
			passFill: orderData.passFill,
			doNotHedge: orderData.doNotHedge,
			expirationDate: getExpirationDate(orderData),
			...initialValues,
		},
		validationSchema: Yup.object().shape({
			theirContract: Yup.string().nullable().max(10, maxLength10Msg),
			contractNumber: Yup.string().nullable(),
			contract: Yup.object().requiredOption(requiredMsg),
			commodity: Yup.object().requiredOption(requiredMsg),
			location: Yup.object().requiredOption(requiredMsg),
			deliveryLocation: Yup.object().requiredOption(requiredMsg),
			assignedRegion: Yup.object().requiredOption(requiredMsg),
			deliveryDate: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateCustom(mode),
				then: Yup.mixed().required(requiredMsg),
			}),
			deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateWindow(mode),
				then: Yup.object().requiredOption(requiredMsg),
			}),
			quantity: Yup.string()
				.typeError(numberMsg)
				.required(requiredMsg)
				.test(
					'minMaxValidation',
					qtyToPriceTranslations.validations.rangeValue(
						customFormat(
							orderData.remainingBalance,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					),
					(value: string) =>
						validateMinMaxValue(orderData.remainingBalance, value),
				)
				.test('minValidation', quantityErrorMsg, (value: string) =>
					validateMinQuantity(value),
				),
			customer: Yup.object().requiredOption(requiredMsg),
			employee: Yup.object().requiredOption(requiredMsg),
			...validationSchema,
		}),
		elements: [
			<TheirContract />,
			<Transaction disabled hidden />,
			<ContractNumber />,
			<ContractType
				resetDependenciesOnChange={false}
				disableBuySell={true}
				ShowNTC
			/>,
			<Commodity checkDefaultValues disabled />,
			<Location />,
			<DeliveryLocation />,
			<AssignedRegion />,
			<CropYears disabled />,
			<DeliveryDates checkDefaultValues useServiceFees />,
			<Quantity />,
			<Futures
				checkDefaultValues
				futuresMonth={{ market: true, disableWithinDeliveryMode: true }}
				futuresPrice={{ market: true, disabled: false }}
			/>,
			<Basis disabled={{ netBasis: true }} adjustedBasis datesModeDependency />,
			<Fees />,
			<NetBasis disabled />,
			<ExpirationDate />,
			<Customer disabled />,
			<Employee />,
		]
			.concat(elements)
			.concat([<Comments />]),
	};
};
