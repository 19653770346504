import React, { useEffect } from 'react';
import { useAbac } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericViewForm } from 'app/components/GenericViewForm';
import {
	allEditNoQuantityLabels,
	allEditNoQuantityPermission,
	allEditQuantityLabels,
	allEditQuantityPermission,
	ContractType,
	functionsPermission,
	roleCreationList,
	roleSectionsList,
	ScreenPermission,
} from 'app/containers/Settings/types';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import { FieldDefinition } from 'types/FieldDefinition';

import { useFieldFactory } from '../../../../definitions/fields';
import { UseFieldTranslations } from '../../../../definitions/fields/translations';
import {
	contractTypeList,
	functionsPermissionList,
	offerTypeList,
	screenPermissionList,
} from '../../../../types';
import {
	selectActiveUserRoles,
	selectCreateUserRoleLoading,
	selectCurrentView,
	selectRoleLimits,
	selectUserRoleDetails,
} from '../../selectors';
import { userRolesActions } from '../../slice';
import { View } from '../../types';

export const CreateEditRole = () => {
	const { t: translate } = useTranslation();
	const fieldsTranslations = UseFieldTranslations();
	const dispatch = useDispatch();
	const fieldList = useFieldFactory();
	const createUserLoading = useSelector(selectCreateUserRoleLoading);
	const activeUserRole = useSelector(selectActiveUserRoles);
	const userRoleDetails = useSelector(selectUserRoleDetails);
	const currentView = useSelector(selectCurrentView);
	const roleLimits = useSelector(selectRoleLimits);

	// I belive this componemt is used to add new permission to user  soo I am adding a validation for NTC here also
	const { userHasPermissions } = useAbac();

	const hasOriginatorPermission = userHasPermissions(
		Permission.CONTRACTSERVICE_ORIGINATOR_BIDS_QUOTES_TENANT_VIEW,
	);
	let screenPermissionsList = screenPermissionList;

	//if user does not have permission to view NTC from admin side then remove NTC from settings.
	if (!hasOriginatorPermission) {
		screenPermissionsList = screenPermissionList.filter((item) => {
			return (
				item !==
				screenPermissionList.find(
					(item) => item === ScreenPermission.ORIGINATOR_SCREEN,
				)
			);
		});
	}

	const hasNTCPermission = userHasPermissions(
		Permission.CONTRACTSERVICE_ALLOW_CONTRACTNTC,
	);

	//if user does not have permission to view The Originator Screen then remove The Originator Screen from sidebar.
	let contractTypes = contractTypeList;
	if (!hasNTCPermission) {
		contractTypes = contractTypeList.filter((item) => {
			return (
				item !== contractTypeList.find((item) => item === ContractType.NTC)
			);
		});
	}

	useEffect(() => {
		return () => {
			dispatch(userRolesActions.resetActiveUserRoles());
			dispatch(userRolesActions.clearEditUserRole());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const domain =
		translations.app.containers.Settings.sections.Roles.Views.CreateRole;

	let userRoleSchema: FieldDefinition[] | null;

	if (currentView === View.Create) {
		userRoleSchema = [
			fieldList.roleName,
			...contractTypes.map((field) => fieldList[field]),
			...offerTypeList.map((field) => fieldList[field]),
			...screenPermissionsList.map((field) => fieldList[field]),
			...functionsPermissionList.map((field) => fieldList[field]),
			{
				...fieldList.bushelsLimit,
				initialValue: 0,
			},
		];
	} else if (currentView === View.Edit) {
		const setInitialValues = (field) => {
			return {
				...fieldList[field],
				initialValue: userRoleDetails.includes(Permission[field]),
			};
		};

		const allEdit = (fieldName, permissionsList) => {
			let allPermissionsCheck = permissionsList.every((permission) =>
				userRoleDetails.includes(permission),
			);

			return {
				...fieldList[fieldName],
				initialValue: allPermissionsCheck,
			};
		};

		userRoleSchema = activeUserRole &&
			userRoleDetails && [
				{ ...fieldList.roleName, initialValue: activeUserRole.name },
				...contractTypes.map((field) => setInitialValues(field)),
				...offerTypeList.map((field) => setInitialValues(field)),
				...screenPermissionList.map((field) => setInitialValues(field)),
				setInitialValues(functionsPermission.CANCEL),
				setInitialValues(functionsPermission.DO_NOT_HEDGE),
				allEdit(
					functionsPermission.EDIT_NO_QUANTITY,
					allEditNoQuantityPermission,
				),
				allEdit(functionsPermission.EDIT_QUANITY, allEditQuantityPermission),
				setInitialValues(functionsPermission.SALES_TRADING),
				{
					...fieldList.bushelsLimit,
					initialValue: roleLimits.limit,
				},
				setInitialValues(functionsPermission.RESEND_TO_ERP),
				setInitialValues(functionsPermission.MOBILEAPP),
			];
	} else {
		userRoleSchema = null;
	}

	const handleSubmit = (values) => {
		let permissionsList: string[] = [];

		Object.keys(values).forEach((key) => {
			if (typeof values[key] === 'boolean' && values[key] === true) {
				permissionsList.push(key);
			}

			if (permissionsList.includes(functionsPermission.EDIT_NO_QUANTITY)) {
				const index = permissionsList.indexOf(
					functionsPermission.EDIT_NO_QUANTITY,
				);
				if (index > -1) {
					permissionsList.splice(index, 1);
				}
				permissionsList = [...permissionsList, ...allEditNoQuantityLabels];
			}

			if (permissionsList.includes(functionsPermission.EDIT_QUANITY)) {
				const index = permissionsList.indexOf(functionsPermission.EDIT_QUANITY);
				if (index > -1) {
					permissionsList.splice(index, 1);
				}
				permissionsList = [...permissionsList, ...allEditQuantityLabels];
			}
		});

		const claimGrantList = permissionsList.map(
			(permission) => Permission[permission],
		);

		const dataTransferObject = {
			name: values.roleName,
			claimGrant: claimGrantList,
		};

		const name = values.roleName;

		dispatch(
			userRolesActions.createUserRole({
				currentRoleName:
					currentView === View.Create ? name : activeUserRole.name,
				data: dataTransferObject,
				actionType: currentView,
				rolesLocations: values.rolesLocations,
				bushelsLimit: values.bushelsLimit,
				roleId: activeUserRole?.id,
				successMessage:
					currentView === View.Create
						? translate(domain.toastSuccessCreateMessage, {
								name,
							})
						: translate(domain.toastSuccessEditMessage, {
								name,
							}),
			}),
		);
	};

	const handleClose = () => {
		dispatch(userRolesActions.setCurrentView(View.List));
	};

	const translateList = (list) => {
		if (list?.lenght === 0) return;
		return list.map((section) => ({
			label: fieldsTranslations.createEditRoleView.sections[section],
			value: section,
		}));
	};

	const tabTitles = {
		tab1: translate(domain.rolePermissionsTabTitle),
		tab2: translate(domain.roleLimitsTabTitle),
	};

	return (
		userRoleSchema && (
			<GenericViewForm
				formDefinition={userRoleSchema}
				sectionsList={translateList(roleSectionsList)}
				secondTabSectionsList={translateList(roleCreationList)}
				handleSubmit={handleSubmit}
				handleClose={handleClose}
				tabTitles={tabTitles}
				footerButtons={
					<FormFooterButtons
						cancelLabel={translate(domain.forgetChanges)}
						submitLabel={translate(domain.saveRole)}
						closeModal={handleClose}
						isLoading={createUserLoading}
					/>
				}
			/>
		)
	);
};
