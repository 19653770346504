import { Divider } from 'antd';
import { FieldArray, useFormikContext } from 'formik';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormDropdown } from 'app/components/FormDropdown';
import { selectNonNtcContractTypeCatalog } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import { CONTRACT_TYPES } from 'utils/constants';

import { CommodityCropSection, CommodityCropSectionYears } from '../../types';

interface Props {
	cropsSection: CommodityCropSectionYears[];
	regionId: string;
	dropdownOptions: GenericOption[];
	defaultPlaceholder: string;
}

export const HedgeAccountContent = memo(function HedgeAccountContent({
	cropsSection,
	regionId = '',
	dropdownOptions,
	defaultPlaceholder = '',
}: Props) {
	const { t: translate } = useTranslation();

	const domain = translations.app.containers.Settings.hedgeAccount;
	const nonNtcContractTypes = useSelector(selectNonNtcContractTypeCatalog);

	const {
		values: { commodity },
	} = useFormikContext<CommodityCropSection>();

	return (
		cropsSection && (
			<div className="steps-content">
				<div className="hedge-account__header">
					<h4>
						{`${translate(domain.commodityTitle)}`}
						<p>{`${commodity}`}</p>
					</h4>
					<h4>{translate(domain.contractTypeTitle)}</h4>
					<h4>{translate(domain.hedgeAccountTitle)}</h4>
				</div>
				<div className="hedge-account__body">
					<FieldArray name="hedgeAccount">
						{() =>
							cropsSection &&
							cropsSection.map((year, index) => (
								<div key={`cropYearSection-${year.year}`}>
									<Divider
										className="hedge-account__divider"
										type="horizontal"
									/>
									<div className="hedge-account__container">
										<label className="hedge-account__year-label">
											<p>{translate(domain.cropYearLabel)}</p>{' '}
											<p>{year.year}</p>
										</label>
										<div className="hedge-account__elements">
											{nonNtcContractTypes &&
												nonNtcContractTypes.map((contract) => {
													const label =
														contract.code === CONTRACT_TYPES.basis
															? `*${translate(domain.basisPricingLabel)}`
															: `*${contract.name}`;
													return (
														<FormDropdown
															key={`${regionId}.${index}.${contract.code}`}
															name={`cropsSection.${regionId}.${index}.${contract.code}`}
															label={label}
															placeholder={defaultPlaceholder}
															options={dropdownOptions}
														/>
													);
												})}
										</div>
									</div>
								</div>
							))
						}
					</FieldArray>
				</div>
			</div>
		)
	);
});
