import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ModalSize } from 'types/ModalSize';
import { PRICE_DECIMALS } from 'utils/constants';
import {
	generateArrowIcon,
	generateCheckbox,
	generateDropdown,
	generateFormInputNumber,
	generateFormInputQuantity,
	generateInputField,
	generateInputFieldPrice,
	generateMultiField,
	generateSwitch,
} from 'utils/GenericFormInputs/generic-form-inputs';
import {
	mapToLabelValue,
	mapToOption,
	productToLabelValue,
} from 'utils/helpers';

import { generateOrphansTable } from '../../sections/Bidsheet/Modals/RollOrphan/fields';
import {
	selectAvailablesListData,
	selectNoBidListData,
} from '../../sections/Bidsheet/selectors';
import { selectProductsList } from '../../sections/Commodities/selectors';
import {
	selectCountriesList,
	selectStatesList,
} from '../../sections/Customers/selectors';
import { selectUserRolesListData } from '../../sections/UserRoles/selectors';
import {
	allPermissionsLabels,
	contractTypeList,
	FORM_HEADER,
	functionsPermissionList,
	offerTypeList,
	RoleSections,
	RoleSettings,
	screenPermissionList,
} from '../../types';
import { UseFieldTranslations } from './translations';

export const useFieldFactory = () => {
	const translations = UseFieldTranslations();
	const requiredMsg = translations.validationSchema.required;
	const validEmail = translations.validationSchema.invalidEmail;
	const noAvailableBids = translations.validationSchema.noAvailableBids;
	const countriesList = useSelector(selectCountriesList).data;
	const statesList = useSelector(selectStatesList).data;
	const rolesList = useSelector(selectUserRolesListData);

	const productList = useSelector(selectProductsList).product;

	const noBidList = useSelector(selectNoBidListData);
	const noBidOptions = mapToOption(noBidList);
	const availableBidList = useSelector(selectAvailablesListData);
	const availableBidOptions = mapToOption(availableBidList);

	const hasAvailableOption = availableBidOptions?.length > 0;

	const getRolePermissionsSection = (sectionName, fieldsList) => {
		if (fieldsList.length === 0) return;
		const fieldsArray = fieldsList.map((field) => {
			return [
				field,
				{
					name: field,
					key: field,
					initialValue: false,
					render: generateSwitch,
					section: sectionName,
					size: 'small',
					validation: Yup.boolean(),
					panelFieldLabel:
						!!translations.createEditRoleView.labels[sectionName] &&
						!!translations.createEditRoleView.labels[sectionName][field]
							? translations.createEditRoleView.labels[sectionName][field]
							: null,
				},
			];
		});

		return Object.fromEntries(fieldsArray);
	};

	return {
		erpNumber: {
			name: 'erpNumber',
			key: 'erpNumber',
			validation: Yup.string().required(requiredMsg),
			render: generateInputField,
			initialValue: '',
			label: translations.erpNumber.label,
			placeholder: translations.erpNumber.placeholder,
			disabled: false,
		},
		name: {
			name: 'name',
			key: 'name',
			label: translations.name.nameLabel,
			render: generateMultiField,
			fields: [
				{
					name: 'firstName',
					key: 'firstName',
					placeholder: translations.name.firstNamePlaceholder,
					initialValue: '',
					validation: Yup.string().required(requiredMsg),
					render: generateInputField,
				},
				{
					name: 'lastName',
					key: 'lastName',
					placeholder: translations.name.lastNamePlaceholder,
					initialValue: '',
					validation: Yup.string().required(requiredMsg),
					render: generateInputField,
				},
			],
		},
		cellphone: {
			name: 'cellphone',
			key: 'cellphone',
			validation: Yup.string().nullable(),
			render: generateFormInputNumber,
			initialValue: null,
			label: translations.cellphone.label,
			placeholder: translations.cellphone.placeholder,
			disabled: false,
			numberType: 'phone',
		},
		address: {
			name: 'address',
			key: 'address',
			label: translations.address.label,
			placeholder: translations.address.placeholder,
			initialValue: '',
			validation: Yup.string().required(requiredMsg),
			render: generateInputField,
		},
		cityAndState: {
			name: 'cityAndState',
			key: 'cityAndState',
			label: '',
			render: generateMultiField,
			fields: [
				{
					name: 'city',
					key: 'city',
					placeholder: translations.cityPlaceholder,
					initialValue: '',
					validation: Yup.string().required(requiredMsg),
					render: generateInputField,
				},
				{
					name: 'state',
					key: 'state',
					placeholder: translations.statePlaceholder,
					initialValue: '',
					validation: Yup.string().required(requiredMsg),
					render: generateDropdown,
					options: mapToLabelValue(statesList),
				},
			],
		},
		zipAndCountry: {
			name: 'zipAndCountry',
			key: 'zipAndCountry',
			label: '',
			render: generateMultiField,
			fields: [
				{
					name: 'zipCode',
					key: 'zipCode',
					placeholder: translations.zipCodePlaceholder,
					initialValue: '',
					validation: Yup.string().required(requiredMsg),
					render: generateFormInputNumber,
				},
				{
					name: 'country',
					key: 'country',
					placeholder: translations.countryPlaceholder,
					initialValue: mapToLabelValue(countriesList),
					validation: Yup.string().required(requiredMsg),
					render: generateDropdown,
					options: mapToLabelValue(countriesList),
				},
			],
		},
		email: {
			name: 'email',
			key: 'email',
			label: translations.email.label,
			placeholder: translations.email.placeholder,
			initialValue: '',
			validation: Yup.string().email(validEmail),
			render: generateInputField,
		},
		role: {
			name: 'role',
			key: 'role',
			label: translations.role.label,
			placeholder: translations.role.placeholder,
			validation: Yup.string().required(requiredMsg),
			options: mapToLabelValue(rolesList),
			render: rolesList.length === 0 ? generateInputField : generateDropdown,
		},
		products: {
			name: 'product',
			key: 'product',
			label: translations.products.label,
			placeholder: translations.products.placeholder,
			validation: Yup.string().nullable().required(requiredMsg),
			render: generateDropdown,
			options: productToLabelValue(productList),
			initialValue: null,
		},
		commodity: {
			name: 'commodity',
			key: 'commodity',
			label: translations.commodity.label,
			placeholder: translations.commodity.placeholder,
			initialValue: null,
			validation: Yup.string().nullable().required(requiredMsg),
			render: generateInputField,
		},
		priceCtrl: {
			name: 'priceCtrl',
			key: 'priceCtrl',
			label: translations.priceCtrl.label,
			placeholder: '0.0000',
			initialValue: '',
			validation: Yup.number().required(requiredMsg).nullable(),
			decimals: PRICE_DECIMALS,
			arrowsHidden: true,
			render: generateInputFieldPrice,
		},
		basisCtrl: {
			name: 'basisCtrl',
			key: 'basisCtrl',
			label: translations.basisCtrl.label,
			placeholder: '0.0000',
			initialValue: '',
			validation: Yup.number().required(requiredMsg).nullable(),
			decimals: PRICE_DECIMALS,
			arrowsHidden: true,
			render: generateInputFieldPrice,
		},
		hedgeFutures: {
			name: 'hedgeFutures',
			key: 'hedgeFutures',
			label: translations.hedgeFuture.label,
			initialValue: '',
			render: generateInputField,
		},
		roleName: {
			name: 'roleName',
			key: 'roleName',
			placeholder:
				translations.createEditRoleView.header.createRole.namePlaceholder,
			initialValue: '',
			render: generateInputField,
			borderless: true,
			section: FORM_HEADER,
			suffix: <EditOutlined />,
			validation: Yup.string()
				.required(requiredMsg)
				.test(
					'oneOfRequired',
					translations.createEditRoleView.labels.rolesNameValidation,
					function (item) {
						return allPermissionsLabels.some(
							// @ts-ignore
							(permission) => this.parent[permission] === true,
						);
					},
				),
		},
		...getRolePermissionsSection(RoleSections.CONTRACT_TYPES, contractTypeList),
		...getRolePermissionsSection(RoleSections.OFFERS_TYPES, offerTypeList),
		...getRolePermissionsSection(RoleSections.SCREENS, screenPermissionList),
		...getRolePermissionsSection(
			RoleSections.FUNCTIONS,
			functionsPermissionList,
		),

		bushelsLimit: {
			name: 'bushelsLimit',
			key: 'bushelsLimit',
			initialValue: null,
			render: generateFormInputQuantity,
			section: RoleSettings.LIMITS,
			validation: Yup.number().nullable(),
			panelFieldLabel: translations.createEditRoleView.labels.bushelsLimitLabel,
			placeholder:
				translations.createEditRoleView.labels.bushelsLimitPlaceholder,
		},
		addAdditionalCropYear: {
			name: 'addCropYear',
			key: 'addAdditionalCropYear',
			render: generateCheckbox,
			label: translations.addAdditionalCropYear.label,
			message: translations.location.origin.instructions,
			span: 3,
			offset: 4,
		},
		rollOrphanBids: {
			name: 'rollOrphanBids',
			key: 'rollOrphanBids',
			label: '',
			render: generateMultiField,
			size: ModalSize.large,
			fields: [
				{
					name: 'noBid',
					key: 'noBid',
					placeholder: '',
					initialValue: noBidOptions?.[0],
					validation: Yup.string().nullable().required(requiredMsg),
					size: ModalSize.large,
					render: generateDropdown,
					options: noBidOptions,
				},
				{
					name: 'arrowIcon',
					key: 'arrowIcon',
					className: 'green-arrow-icon',
					render: generateArrowIcon,
				},
				{
					name: 'yesBid',
					key: 'yesBid',
					placeholder: translations.rollOrphanBids.yesBidPlaceholder,
					initialValue: null,
					validation: Yup.string()
						.nullable()
						.test('hasOptions', noAvailableBids, (value) => hasAvailableOption)
						.required(requiredMsg),
					size: ModalSize.large,
					render: generateDropdown,
					options: availableBidOptions,
				},
			],
		},
		rollOrphanTable: {
			name: 'rollOrphanTable',
			key: 'rollOrphanTable',
			initialValue: false,
			validation: Yup.boolean().test(
				'hasSelectedRecords',
				requiredMsg,
				(value) => value,
			),
			render: generateOrphansTable,
		},
	};
};
