import './styles.scss';

import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { actions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';
import { SessionActions } from 'types/SessionActions';
import { AUTH_URL, RESET_PASSWORD_URL } from 'utils/auth/auth-settings';

import {
	selectAuthUser,
	selectSessionCheck,
	selectUserInfo,
} from '../GlobalSaga/selectors';
import HrvystLogo from './assets/hrvyst-logo.svg';
import RjoLogo from './assets/rjo-logo.svg';

interface locationStateType {
	from: { pathname: string };
}

export const Login = () => {
	const { t: translate } = useTranslation();
	const { state } = useLocation<locationStateType>();
	const authUser = useSelector(selectAuthUser);
	const userInfo = useSelector(selectUserInfo);
	const sessionCheck = useSelector(selectSessionCheck);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			actions.startManageSession({ sessionAction: SessionActions.getSession }),
		);
	}, [dispatch]);

	const fromRoute = state && state.from ? state.from.pathname : '/';
	const excludedRoutes = ['/', '/login'];
	const redirectTo = (
		<Redirect
			to={{
				pathname: excludedRoutes.indexOf(fromRoute) > 0 ? '/' : fromRoute,
			}}
		/>
	);

	const loginForm = (
		<>
			<div className="login">
				<main className="login__hrvyst">
					<img src={HrvystLogo} alt="" className="login__hrvyst-logo" />
					<Button
						data-testid="login-button"
						className="login__hrvyst-login-btn"
						href={AUTH_URL}
					>
						{translate(translations.app.containers.Login.loginButton)}
					</Button>
					<a href={RESET_PASSWORD_URL} className="login__hrvyst-forget-pass">
						{translate(translations.app.containers.Login.forgotPassword)}
					</a>
				</main>
				<footer className="login__footer">
					<div className="login__footer__rjo-name">
						<span className="login__footer__rjo-name--developed-by">
							{' '}
							{translate(translations.app.containers.Login.developedBy)}{' '}
						</span>{' '}
						<img src={RjoLogo} alt="Rjo Logo" />
					</div>
					<div className="login__footer__rjo-about">
						<span className="login__footer--link">
							{' '}
							{translate(translations.app.containers.Login.riskManagment)}
						</span>{' '}
						|
						<span className="login__footer--link">
							{' '}
							{translate(translations.app.containers.Login.service)}{' '}
						</span>{' '}
						|
						<span className="login__footer--link">
							{translate(translations.app.containers.Login.artTrading)}
						</span>
					</div>
					<div className="login__footer--link">
						<button>
							<a href="https://www.rjobrien.com">
								{' '}
								{translate(translations.app.containers.Login.RJOSiteLink)}{' '}
							</a>
						</button>
					</div>
					<div className="login__footer__copyright">
						© {new Date().getFullYear()}{' '}
						{translate(translations.app.containers.Login.RJOBrien)}
					</div>
				</footer>
			</div>
		</>
	);

	return !authUser && !userInfo && sessionCheck ? loginForm : redirectTo;
};
