import { PayloadAction } from '@reduxjs/toolkit';

import { FileResponse } from 'types/FileResponse';
import { GenericError } from 'types/GenericError';
import { GenericItem } from 'types/GenericItem';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { GetPersistedData } from 'utils/helpers';

import { PERSISTED_FILTER } from './constants';
import { ContainerState, ExportExcelPayload, Transaction } from './types';

// The initial state of the LiveLedger container
export const initialState: ContainerState = {
	totalOrders: 0,

	events: null,

	liveLedgerList: {
		data: [],
		loading: false,
		error: null,
	},

	selectedFilters: {
		selectedCommodity: GetPersistedData(PERSISTED_FILTER.COMMODITY, ''),
		selectedRegion: GetPersistedData(PERSISTED_FILTER.REGION, ''),
		selectedCropYear: null,
		selectedDate: null,
	},

	liveLedgerTemplate: {
		fileName: null,
		url: null,
		loading: false,
		error: null,
	},

	cropYearBalanceList: [],
};

const liveLedgerSlice = createSlice({
	name: 'liveLedger',
	initialState,
	reducers: {
		changeSelectedFilters(
			state,
			action: PayloadAction<Partial<ContainerState['selectedFilters']>>,
		) {
			state.selectedFilters = {
				...state.selectedFilters,
				...action.payload,
			};
		},
		cropYearBalanceListLoaded(
			state,
			action: PayloadAction<ContainerState['cropYearBalanceList']>,
		) {
			state.cropYearBalanceList = action.payload;
		},

		loadLiveLedgerList(state) {
			state.liveLedgerList = {
				data: [],
				loading: true,
				error: null,
			};
		},

		liveLedgerListLoaded(state, action: PayloadAction<Transaction[]>) {
			state.liveLedgerList = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		liveLedgerListError(state, action: PayloadAction<GenericError>) {
			state.liveLedgerList = {
				data: [],
				error: action.payload,
				loading: false,
			};
		},

		setTotalLiveLedgerList(state, action: PayloadAction<number>) {
			state.totalOrders = action.payload;
		},

		setLiveLedgerEvents(state, action: PayloadAction<GenericItem[] | null>) {
			state.events = action.payload;
		},

		exportLiveLedger(state, action: PayloadAction<ExportExcelPayload>) {
			state.liveLedgerTemplate = {
				...state.liveLedgerTemplate,
				loading: true,
			};
		},
		liveLedgerExported(state, action: PayloadAction<FileResponse>) {
			state.liveLedgerTemplate = {
				url: action.payload.url,
				fileName: action.payload.fileName,
				error: null,
				loading: false,
			};
		},
		liveLedgerExportFailed(state, action: PayloadAction<GenericError>) {
			state.liveLedgerTemplate = {
				...state.liveLedgerTemplate,
				error: action.payload,
				loading: false,
			};
		},
		clearLiveLedgerTemplate(state) {
			state.liveLedgerTemplate = {
				url: null,
				error: null,
				loading: false,
				fileName: null,
			};
		},
		clearSelectedDate(state) {
			state.selectedFilters = {
				...state.selectedFilters,
				selectedDate: null,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = liveLedgerSlice;
