export enum HedgeSummaryTabs {
	futuresErp = 'futuresErp',
	tradeBook = 'tradeBook',
}

export interface HedgeSummaryTab {
	name: string;
	content: JSX.Element;
	className?: string;
}
