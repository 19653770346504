import React, { memo } from 'react';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';

const firstName = 'firstName';
const lastName = 'lastName';
const name = 'name';

export const Name = memo(function Name() {
	const translations = UseFieldTranslations();

	return (
		<GenericForm.FormItem
			data-testid={`${name}-form-item`}
			label={translations[name].nameLabel}
			name={name}
		>
			<GenericForm.FormItem
				data-testid={`${firstName}-form-item`}
				name={firstName}
			>
				<Input
					name={firstName}
					placeholder={translations[name].firstNamePlaceholder}
				/>
			</GenericForm.FormItem>
			<GenericForm.FormItem
				data-testid={`${lastName}-form-item`}
				name={lastName}
			>
				<Input
					name={lastName}
					placeholder={translations[name].lastNamePlaceholder}
				/>
			</GenericForm.FormItem>
		</GenericForm.FormItem>
	);
});
