import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { actions } from 'app/containers/GlobalSaga/slice';
import { regionSchema } from 'types/Regions';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

const { regions, regionsForEmployeeActivePermissions } = apiEndpoints;

export function* getEnabledRegionsSaga(
	action: ReturnType<typeof actions.getEnabledRegionsRequest>,
) {
	const { isEmployeeActiveRegion } = action.payload;
	let requestURL = new URL(`${apiRoutes.base}/${regions}?enabledOnly=${true}`);

	if (isEmployeeActiveRegion) {
		requestURL = new URL(
			`${apiRoutes.base}/${regionsForEmployeeActivePermissions}`,
		);
	}

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(regionSchema),
	});

	if (!!data) {
		yield* put(actions.getEnabledRegionsSuccess(data));
	} else if (!!error?.detail) {
		yield* put(actions.getEnabledRegionsError(error));
	}
}
