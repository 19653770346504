import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { useForm } from 'react-hook-form';

import { useToggleSchema } from '../../../schemas/useToggleSchema';

export const useToggleFormData = () => {
	const { elements, initialValues, validationSchema } = useToggleSchema();

	const resolver = yupResolver(validationSchema);
	const formInstance = useForm({
		resolver,
		mode: 'all',
		defaultValues: {
			...initialValues,
		},
	});

	return {
		elements,
		formInstance,
		validationSchema,
	};
};
