import { Input } from 'antd';
import React, { memo } from 'react';

import { GenericForm } from 'app/components/GenericForm';
import { OrderEntry } from 'types/OrderEntry';
import { preventWheelChange, preventWheelEvent } from 'utils/util';

import { getOrderEntryId } from '../utils/helpers';

interface Props {
	orderEntry: OrderEntry;
}

export const Int = memo(function Int({ orderEntry }: Props) {
	const orderEntryId = getOrderEntryId(orderEntry);

	return (
		<>
			<GenericForm.FormItem
				data-testid={`${orderEntryId}-form-item`}
				label={orderEntry?.label}
				name={orderEntryId}
			>
				<Input
					maxLength={25}
					key={orderEntryId}
					name={orderEntryId}
					type="number"
					onWheel={preventWheelChange}
					onFocus={preventWheelEvent}
				/>
			</GenericForm.FormItem>
		</>
	);
});
