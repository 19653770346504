import './styles.scss';

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';

const fieldName = 'theirContract';
const domain =
	translations.app.containers.Transactions.components.TheirContract;

interface Props {
	disabled?: boolean;
}

export const TheirContract = memo(function TheirContract(props: Props) {
	const { disabled } = props;
	const { t: translate } = useTranslation();

	return (
		<GenericForm.FormItem
			data-testid={`${fieldName}-form-item`}
			label={translate(domain.label)}
			name={fieldName}
		>
			<Input
				key={fieldName}
				maxLength={10}
				name={fieldName}
				placeholder={translate(domain.placeholder)}
				disabled={disabled}
			/>
		</GenericForm.FormItem>
	);
});
