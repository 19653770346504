import { Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
	selectListLoading,
	selectOrphanedBalance,
} from 'app/containers/PreHedge/selectors';
import { translations } from 'locales/i18n';

export const OrphanedBalanceTable = () => {
	const { t: translate } = useTranslation();
	const scopeTranslations =
		translations.app.containers.preHedge.components.Views.List.Menu;
	const orphanedBalance = useSelector(selectOrphanedBalance);
	const listLoading = useSelector(selectListLoading);
	const { buy, sell } = orphanedBalance;
	return (
		<div className="preHedge-menu__orphaned-table">
			<div className="preHedge-menu__header-section">
				<div>{translate(scopeTranslations.orphanedTableTitle)}</div>
			</div>
			<div className="preHedge-menu__orphaned-cell">
				<div className="preHedge-menu__orphaned-buy">
					<div>{translate(scopeTranslations.buy)}</div>
					<div>{listLoading ? <Spin key="spinner" /> : buy}</div>
				</div>
				<div className="preHedge-menu__orphaned-sell">
					<div>{translate(scopeTranslations.sell)}</div>
					<div>{listLoading ? <Spin key="spinner" /> : sell}</div>
				</div>
			</div>
		</div>
	);
};
