import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { missingBids } = apiEndpoints;

export function* noBidsList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${missingBids}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.setNoBidList(responseData.list));
	} else if (!!responseError.detail) {
		yield put(actions.setNoBidListError(responseError));
	}
}
