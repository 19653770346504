import 'moment-timezone';

import moment, { Moment } from 'moment';
import numbro from 'numbro';

import { Product } from 'app/containers/CashbidsAndQuotes/types';
import { ActionType } from 'types/ActionType';
import { Contract } from 'types/Contract';
import { ContractDetails } from 'types/ContractDetails';
import { DeliveryDate } from 'types/DeliveryDate';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import { FrontMap } from 'types/FrontMap';
import { GenericItem } from 'types/GenericItem';
import { GenericOption, GenericOptionInput } from 'types/GenericOption';
import { OffersFilters } from 'types/Offer';
import { DeliveryDateValue, ServiceFeeData } from 'types/ServiceFee';
import { FuturesMonthCode, PRE_HEDGE_ORDER_TYPES } from 'utils/constants';

import {
	CONSTANTS,
	DATE_FORMAT,
	DATE_MMDDYY,
	DATE_MMDDYYYY,
	MONTHS_CODES,
	TIME_FORMAT,
} from './constants';
import { DateRangeIntervals } from './types/validators';

/**
 * Takes a single array index to map in label - value shape
 * @param number
 * @param minimumFractionDigits minimum number of decimals to be displayed
 * @param maximumFractionDigits maximum number of decimals to be displayed
 * @returns
 */
export const currencyFormat = (
	number: number | string | null,
	minimumFractionDigits = CONSTANTS.MIN_FRACTION_DIGIT,
	maximumFractionDigits = CONSTANTS.MAX_FRACTION_DIGIT,
) => {
	return Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits,
		maximumFractionDigits,
	}).format(
		number == null
			? 0
			: typeof number === 'string'
				? parseFloat(number)
				: number,
	);
};

/**
 * Takes a single array index to map in label - value shape by given property
 * @param option object to map in label value shape
 * @param property object property to map in a label value object
 * @returns label value object
 */
export function optionToLabelValue<
	Property extends string,
	Option extends Record<Property, string>,
>(option: Option, property: Property): { label: string; value: string } {
	return {
		label: option[property],
		value: option[property],
	};
}

/**
 * Takes a single array index to map in label - value shape
 *
 * TODO: this returns false if the array is falsy, but it would be better to return null
 * unfortunately, some code expects these values to not be null (ie tries to access properties, which result in undefined for false but error for null)
 * once the repo is more strictly typed in general, we should change this
 * @param array
 * @param property array property to map in a label value object
 * @returns
 */
export function mapPropertyToLabelValue<
	Property extends string,
	Option extends Record<Property, string>,
>(array: Option[] | null | undefined, property: Property) {
	return !!array && array.map((option) => optionToLabelValue(option, property));
}

export const mapToLabelValueObject = (
	option: GenericOptionInput,
): GenericOption => ({
	label: option.name,
	value: option.id,
	type: option.typeId,
	highlight: option.highlight,
	regionIds: option.regionIds,
});

export const mapToLabelValue = (
	inputArr: GenericOptionInput[] | null | undefined,
): GenericOption[] => {
	return inputArr
		? inputArr.map((option) => mapToLabelValueObject(option))
		: [];
};

/**
 *
 * @param array same as mapToLabelValue, but array contains flat string values instead of array of objects
 */
export const flatmapToLabelValue = (array: string[]) => {
	const mappedArray =
		!!array &&
		array.map((option) => {
			return {
				name: `${option}`,
				id: option,
			};
		});

	return mappedArray;
};

export const transFormToLabelValue = (array: string[]) => {
	const mappedArray =
		!!array &&
		array.map((option) => {
			return {
				name: option,
				id: option,
			};
		});

	return mappedArray;
};

export const productToLabelValue = (array: Product[]) => {
	const mappedArray =
		!!array &&
		array.map((option) => {
			return {
				label: `${option.fullDescription}`,
				value: option.id,
			};
		});

	return mappedArray;
};

export const yearsToLabelValue = (array: (string | number)[]) => {
	const mappedArray =
		!!array &&
		array.map((option) => {
			return {
				label: option.toString(),
				value: option === 'None' ? null : option,
			};
		});

	return mappedArray;
};

export const formatPriceHelper = (
	price: string | number | null | undefined,
) => {
	if (typeof price === 'number') {
		return price.toFixed(CONSTANTS.FIXED_DECIMALS);
	}

	return price;
};

export const createRangeCalendarOption = (
	startDate: moment.MomentInput,
	endDate: moment.MomentInput,
	name: string | null = '',
	labels = { startLabel: '', endLabel: '' },
) => {
	startDate = moment(startDate);
	endDate = moment(endDate);

	const startDateFormated = moment(startDate).format('MM/DD/YYYY');
	const endDateFormated = moment(endDate).format('MM/DD/YYYY');

	return {
		value: `${startDateFormated} - ${endDateFormated}`,
		label: `${(name ?? '').toUpperCase()} -
      ${labels.startLabel} ${startDateFormated} -
      ${labels.endLabel} ${endDateFormated} `,
		startDate,
		endDate,
	};
};

export function bidsheetDataFromDeliveryDate(
	currentDeliveryDate: [moment.MomentInput, moment.MomentInput],
	deliveryDatesState: DeliveryDate[],
) {
	const currentStart = currentDeliveryDate[0];
	const currentEnd = currentDeliveryDate[1];
	const data = deliveryDatesState?.find(
		(item) =>
			moment(item.start).isSame(currentStart) &&
			moment(item.end).isSame(currentEnd),
	);
	return {
		futuresMonth: data?.futureMonth,
		postedBasis: data?.basis,
	};
}

export const decimalFormat = (number: number | string) => {
	const formater =
		!number || Number.isNaN(number)
			? '----'
			: numbro(number).format({
					thousandSeparated: true,
					mantissa: 0,
				});
	return formater;
};

export const decimalSignFormat = (number: number) => {
	return numbro(number).format({
		thousandSeparated: true,
		mantissa: 2,
		forceSign: true,
	});
};

export const customFormat = (
	number: number | string | undefined,
	thousandSeparated?: boolean,
	mantissa?: number,
) =>
	numbro(number).format({
		thousandSeparated,
		mantissa,
	});

export const basisFormat = (number: number) => {
	const formater =
		(!number && number !== 0) || Number.isNaN(number)
			? '----'
			: Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 4,
					maximumFractionDigits: 4,
				})
					.format(number)
					.replace(/^(\D+)/, '$1 ');
	return formater;
};

export const validatePriceFormat = (
	value: number | string | null | undefined,
) => {
	if (value != null) {
		return (typeof value === 'string' ? parseFloat(value) : value).toFixed(
			CONSTANTS.FIXED_DECIMALS,
		);
	}
	return value;
};

//#region date and time formats
export const dateTimeFormat = (date: moment.MomentInput) => {
	return moment
		.utc(date, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS')
		.tz('America/Chicago')
		.format(`${DATE_MMDDYY} ${TIME_FORMAT}`);
};

/**
 * @returns date in format MM/DD/YYYY
 * @param date
 */
export const dateFormat = (date: moment.MomentInput) => {
	return moment(date).format(DATE_MMDDYYYY);
};

export const dateFormatDelivery = (date: number | string | Date) => {
	var userTimezoneOffset = new Date(date).getTimezoneOffset() * 60000;
	var newDate = new Date(new Date(date).getTime() + userTimezoneOffset);
	return moment(newDate).format(DATE_MMDDYYYY);
};
/**
 * @returns date in format MM/DD/YYYY
 * @param date
 */
export const dateShortFormat = (date: moment.MomentInput) => {
	return moment(date).format(DATE_MMDDYY);
};
/**
 * return with date format MM/DD/YY
 * @param date
 */
export const dateFormatShortYear = (date: moment.MomentInput) => {
	const formater = !!date ? moment(date).format(DATE_MMDDYY) : '----';
	return formater;
};

/**
 * return time in format HH:mm:ss
 * @param time
 */
export const timeFormat = (time: moment.MomentInput) => {
	return moment(time).format(TIME_FORMAT);
};

/**
 * @returns FMY - startDate - endDate
 * @param futureMonth in format MMMYY
 * @param startDate in format MM/DD/YYYY
 * @param endDate in format MM/DD/YYYY
 */
export const deliveryWindowFormat = (
	futureMonth: moment.MomentInput,
	startDate: moment.MomentInput,
	endDate: moment.MomentInput,
	printFM = true,
) => {
	const label = printFM
		? `${futureMonth} - (${dateFormat(startDate)} - ${dateFormat(endDate)})`
		: `${dateFormat(startDate)} - ${dateFormat(endDate)}`;
	return label;
};

/**
 * @returns format date YYYY-MM-DD
 * @param date
 */
export const dateFormatYearMonthDay = (date: moment.MomentInput) =>
	moment(date).format(DATE_FORMAT);

/**
 * @returns format date {day: DD, month: MMMM, year: YYYY}
 * @param date
 */
export const dateFormatSplited = (date: moment.MomentInput) => {
	return {
		day: moment(date).format('DD'),
		month: moment(date).format('MMMM'),
		year: moment(date).format('YYYY'),
	};
};

/**
 * @returns boolean
 * @param startDate
 * @param endDate
 */
export const validateDateRange = (
	startDate: moment.Moment | null | undefined,
	endDate: moment.Moment | null | undefined,
	datesRange: DateRangeIntervals,
) => {
	if (startDate && endDate) {
		if (
			endDate.diff(startDate, 'months', true) < datesRange.min ||
			endDate.diff(startDate, 'months', true) > datesRange.max
		) {
			return true;
		} else {
			return false;
		}
	}
	return false;
};

/**
 * @param date
 * @returns date casted to moment
 */
export const dateMoment = (date: moment.MomentInput) => moment(date);
//#endregion date and time formats

/** */
export function hasSetFilters(filters: unknown): boolean {
	return (
		filters != null &&
		Object.values(filters).some(
			(filter) =>
				filter != null &&
				(filter === true ||
					((typeof filter === 'string' || Array.isArray(filter)) &&
						filter.length > 0)),
		)
	);
}

/**
 * @returns value with a comma separated each 3 digits
 * @param value number
 */
export const addComma = (value: number | string): string => {
	if (value?.toString() === '.') return value.toString(); //if user have entered something starting with a decimal point, we return it.  this is for quantities less than one bushel
	const decimalParts = value?.toString().split('.');
	const addCommaUsingInbuiltConstructor = new Intl.NumberFormat('en-US')
		.format(parseInt(decimalParts[0]))
		.toString();
	decimalParts[0] = addCommaUsingInbuiltConstructor;
	return decimalParts?.join('.');
};

/**
 * @returns value without commas
 * @param value string
 */
export const removeComma = (value: string) => value.replace(/,/g, '');

export const mapToLabelValueFormatted = (
	array: { name: string; id: string; code: string }[],
) => {
	const mappedArray =
		!!array &&
		array.map((option) => {
			return {
				label: `${option.name}`,
				value: option.id,
				code: `${option.code}`,
			};
		});

	return mappedArray;
};

/**
 * @returns an object representing a map of id: {label, icon, class}
 * @param listOfGenericItems GenericItem[]
 * @param entityName string
 */

export const mapGenericItemsListToIcon = (
	listOfGenericItems: GenericItem[],
	entityName: string,
) => {
	if (!listOfGenericItems || listOfGenericItems.length === 0) return;

	const labelsMap = listOfGenericItems.reduce<FrontMap>(
		(acumulator, element) => {
			return {
				...acumulator,
				[element.id]: {
					label: element.name,
					icon: null,
					class: `${entityName}--${element.id}`,
				},
			};
		},
		{},
	);

	return labelsMap;
};

/**
 * @returns an object representing a map of {label: {id, id, id,id, id,}}
 * @param listOfObjects {label, id, id, id, id, id,}[]
 */

export function mapLabelToIds<T extends { label: string }>(list: T[]) {
	if (!list || list.length === 0) return {};

	const labelsMap = list.reduce((acumulator, element) => {
		return {
			...acumulator,
			[element.label]: element,
		};
	}, {});

	return labelsMap;
}

/**
 * @returns an object representing element size: { width,  height }
 * @param element HTMLElement
 * @param excludeMargin boolean
 */
export const getElementSize = (
	element: HTMLElement | null | undefined,
	excludeMargin: boolean = false,
) => {
	if (!element) {
		return {
			width: 0,
			height: 0,
		};
	}

	const styles = getComputedStyle(element);
	const domRect = element?.getBoundingClientRect();

	if (excludeMargin) {
		return {
			width: domRect.width,
			height: domRect.height,
		};
	}

	return {
		width:
			domRect.width +
			parseFloat(styles.marginLeft) +
			parseFloat(styles.marginRight),
		height:
			domRect.height +
			parseFloat(styles.marginTop) +
			parseFloat(styles.marginBottom),
	};
};

export const mapToOption = (array: { label: string }[]) => {
	let options;
	if (array?.length > 0) {
		options = array.map((option) => ({
			label: option.label,
			value: option.label,
		}));
	} else options = null;
	return options;
};

/**
 * @param number The number to be formatted
 * @param integers The max amount of integer digits
 * @param decimals Fixed amount of decimals digits
 * @returns The formated number with maxIntegers ammount digits
 */
export const parseMaxIntegers = (
	number: string | null | undefined,
	integers: number,
	decimals?: number,
) => {
	if (!number) return false;

	let parsedValue = number;
	let isValid = hasMaxIntegers(number, integers);
	if (!isValid) {
		const value = number.toString().replace('.', '');
		parsedValue = `${value?.slice(0, integers)}.${value?.slice(integers)}`;
	}

	let result = decimals
		? parseFloat(parsedValue).toFixed(decimals)
		: parseFloat(parsedValue);

	return result;
};

/**
 *
 * @param number The number to be checked
 * @param integers The MAX integers digits
 * @returns Whether has a valid amount of integer digits or nor
 */
export const hasMaxIntegers = (number: number | string, integers: number) => {
	const pointIndex = number
		?.toString()
		.replace(/[^0-9.]+/g, '')
		.indexOf('.');
	return pointIndex > -1 && pointIndex <= integers;
};

/**
 * type that both Commodity and CommodityDnhItem are assignable to
 */
type CommonCommodity = {
	id: string;
	regionName?: string | null;
	cropYears:
		| {
				cropYear: number;
				isActive?: boolean;
				isDnhActive?: boolean;
		  }[]
		| null;
};

export function getCropYearOptions(
	commoditiesList: CommonCommodity[],
	commodity: { value: string } | null | undefined,
): {
	label: string;
	value: number;
	disabled: boolean;
	isDnhActive?: boolean;
}[] {
	const currentYear = moment().year();
	const defaultCropYears = [
		{ label: currentYear.toString(), value: currentYear, disabled: true },
		{
			label: (currentYear + 1).toString(),
			value: currentYear + 1,
			disabled: true,
		},
		{
			label: (currentYear + 2).toString(),
			value: currentYear + 2,
			disabled: true,
		},
		{
			label: (currentYear + 3).toString(),
			value: currentYear + 3,
			disabled: true,
		},
	];

	if (!commodity) return defaultCropYears;

	const commodityData = commoditiesList?.find(
		(singleCommodity) => singleCommodity.id === commodity.value,
	);

	const newOptions = commodityData?.cropYears?.map((cropYearData) => ({
		disabled: !(cropYearData.isActive ?? true),
		isDnhActive: cropYearData.isDnhActive,
		label: cropYearData.cropYear.toString(),
		value: cropYearData.cropYear,
	}));

	return newOptions || defaultCropYears;
}

export function getCropYearOptionsWithRegion(
	commoditiesList: CommonCommodity[],
	commodity: { value: string } | null | undefined,
	region: { label: string } | null | undefined,
): {
	label: string;
	value: number;
	disabled: boolean;
	isDnhActive?: boolean;
}[] {
	const currentYear = moment().year();
	const defaultCropYears = [
		{ label: currentYear.toString(), value: currentYear, disabled: true },
		{
			label: (currentYear + 1).toString(),
			value: currentYear + 1,
			disabled: true,
		},
		{
			label: (currentYear + 2).toString(),
			value: currentYear + 2,
			disabled: true,
		},
		{
			label: (currentYear + 3).toString(),
			value: currentYear + 3,
			disabled: true,
		},
	];

	if (!commodity || !region) return defaultCropYears;

	const commodityData = commoditiesList?.find(
		(singleCommodity) =>
			singleCommodity.id === commodity.value &&
			singleCommodity.regionName === region.label,
	);

	const newOptions = commodityData?.cropYears?.map((cropYearData) => ({
		disabled: !(cropYearData.isActive ?? true),
		isDnhActive: cropYearData.isDnhActive,
		label: cropYearData.cropYear.toString(),
		value: cropYearData.cropYear,
	}));

	return newOptions || defaultCropYears;
}

/**
 *
 * @param number Number to be checked
 * @param decimals Fixed decimals
 * @returns Whether has valid quarter cents, ex: X.XX00, X.XX25, X.XX50, X.XX75
 */
export const validateQuarterCents = (
	number: string | number,
	decimals = CONSTANTS.FIXED_DECIMALS,
) => {
	if (decimals < 2) {
		throw new Error('Decimals parameter must be greater or equal to 2');
	}
	const floatNumber = (
		typeof number === 'string' ? parseFloat(number) : (number ?? 0)
	).toFixed(decimals);
	const lastDigits = floatNumber.toString().slice(-2);
	return Number(lastDigits) % 25 === 0;
};

/**
 * Checks if a value falls within an exclusive range and returns true if it does, otherwise false.
 * @param value The value to be checked.
 * @param up The upper bound of the range (exclusive).
 * @param down The lower bound of the range (exclusive).
 * @returns True if the value is within the range, otherwise false.
 */
export const handlePriceControl = (
	value: number,
	up: number,
	down: number,
): boolean => {
	if (value < down || value > up) return false;
	return true;
};

/**
 * @param string future month code
 * @return equivalent month for future month code
 */
export const futuresMonthsCodes = (monthCode: FuturesMonthCode) =>
	MONTHS_CODES[monthCode];

/**
 * @param Array delivery dates
 * @return delivery date object or null
 */
export const getDeliveryPeriod = (
	deliveryDate: [moment.MomentInput, moment.MomentInput] | null | undefined,
) =>
	deliveryDate
		? {
				start: moment(deliveryDate[0]).format(DATE_FORMAT),
				end: moment(deliveryDate[1]).format(DATE_FORMAT),
			}
		: null;

export const mapDynamicForm = (values: Record<string, unknown>) =>
	Object.keys(values).reduce<Record<string, unknown>[]>(
		(mappedForm: Record<string, unknown>[], key: string) => {
			// parse key into guid and propertyName
			const [propertyName, ...guid] = key.split('-');
			const id = guid.join('-');

			// check mappedForm to see if there is an object with id === guid in key
			const ref = mappedForm.find((obj) => obj.id === id);

			// If there is, then add to that object the relevant data
			// else push a new object with relevant data into the mappedForm
			if (ref) {
				ref[propertyName] = values[key];
			} else {
				mappedForm.push({
					id,
					[propertyName]: values[key],
				});
			}

			return mappedForm;
		},
		[],
	);

export const getActionType = (isSell: boolean | null | undefined) =>
	!!isSell ? ActionType.SELL : ActionType.BUY;

export const getDeliveryDatesMode = (orderData: ContractDetails | null) =>
	orderData?.isDeliveryDatesCustom
		? { value: DeliveryDateMode.Custom }
		: { value: DeliveryDateMode.Window };

export const getDeliveryDateWindow = (
	orderData: Pick<
		ContractDetails,
		'deliveryStartDate' | 'deliveryEndDate'
	> | null,
) => {
	const { value } = createRangeCalendarOption(
		orderData?.deliveryStartDate,
		orderData?.deliveryEndDate,
	);

	return {
		value,
	};
};

export const getEfpQuantity = (
	quantityToPriceValue: number,
	isMinimumEFP: boolean,
	lotFactor: number,
) => {
	let efpQuantity;

	if (quantityToPriceValue <= lotFactor) {
		efpQuantity = lotFactor;
	} else if (quantityToPriceValue % lotFactor === 0) {
		efpQuantity = quantityToPriceValue;
	} else if (quantityToPriceValue % lotFactor !== 0 && isMinimumEFP) {
		efpQuantity = Math.floor(quantityToPriceValue / lotFactor) * lotFactor;
	} else if (quantityToPriceValue % lotFactor !== 0 && !isMinimumEFP) {
		efpQuantity = Math.ceil(quantityToPriceValue / lotFactor) * lotFactor;
	}
	return efpQuantity;
};
export const getExpirationDate = (orderData: ContractDetails | null) =>
	orderData?.expirationDate ? moment(orderData?.expirationDate) : null;

export const getFormattedExpirationDate = (
	orderData: ContractDetails | null,
) =>
	orderData?.expirationDate
		? moment(orderData.expirationDate).format(DATE_FORMAT)
		: null;

export const isActionSell = (action: ActionType) => action === ActionType.SELL;

export const isMarketOrder = (orderType: PRE_HEDGE_ORDER_TYPES) =>
	orderType === PRE_HEDGE_ORDER_TYPES.marketOrder;

export const isDeliveryDateCustom = (
	mode: { value: DeliveryDateMode } | null,
) => mode?.value === DeliveryDateMode.Custom;

export const isDeliveryDateWindow = (
	mode: { value: DeliveryDateMode } | null,
) => mode?.value === DeliveryDateMode.Window;

/**
 * update named data at localStorage
 * @param string LocalStorage Key name
 * @param object localStorage value
 * @param object field/s to update
 * @returns
 */

export const UpdatePersistedData = (
	localStorageKey: string,
	sourceObj: object,
	fieldToUpdate: object = {},
) => {
	const updatedData = {
		...sourceObj,
		...fieldToUpdate,
	};

	window.localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
};

export const SetPersistedData = (localStorageKey: string, value: unknown) => {
	window.localStorage.setItem(localStorageKey, JSON.stringify(value));
};

/**
 * Get the persisted DATA from localstorage and load the grid
 * @param string LocalStorage Key name
 * @returns persisted DATA from localstorage
 */

export const GetPersistedData = <T>(
	localStorageKey: string,
	defaultValue: T,
) => {
	const persistedData = window.localStorage.getItem(localStorageKey);
	return persistedData == null
		? defaultValue
		: (JSON.parse(persistedData) as T);
};

/**
 * Calculates the service fee based on the provided input values and the given service fees data.
 * @param {ServiceFeeData} serviceFeesData - An array of service fee data objects.
 * @param {String} contractValue - The selected contract value.
 * @param {String} commodityValue - The selected commodity value.
 * @param {Number} cropYearValue - The selected crop year value.
 * @param {String} transactionValue - The selected transaction value.
 * @param {DeliveryDateValue} deliveryDateValue - The selected delivery date value.
 * @returns {Number} The calculated service fee value or 0 if it's 0 or not found.
 */
export const calculateServiceFee = (
	serviceFeesData: ServiceFeeData[],
	contractValue: string,
	commodityValue: string,
	cropYearValue: number,
	transactionValue: string,
	deliveryDateValue: DeliveryDateValue[],
): number => {
	// Loop through the service fees data and find the matching item based on the provided values
	const selectedItem = serviceFeesData.find(
		(item: ServiceFeeData) =>
			item['commodityId'] === commodityValue &&
			item['contractTypeId'] === contractValue.toLowerCase() &&
			item['cropYear'] === cropYearValue &&
			item['transactionTypeId'] === transactionValue.toLowerCase(),
	);

	if (selectedItem) {
		const targetDeliveryDate = deliveryDateValue[0].format('MMM YY');
		let serviceFeeMonth = selectedItem.serviceFeeMonthList.filter(
			(item: { deliveryMonth: string }) =>
				item.deliveryMonth === targetDeliveryDate,
		);
		if (serviceFeeMonth.length === 0) {
			serviceFeeMonth = selectedItem.serviceFeeMonthList.slice(0, 1);
		}
		return serviceFeeMonth?.[0]?.writingFee ?? 0;
	}

	return 0;
};

/**
 * Counts the number of non-empty filters based on the provided filter object and default filter objects array.
 * @param {OffersFilters} filters - An object representing the filters to be counted.
 * @param {Array<{ value: string, label: string }>} [defaultFilterObject=[]] {optional} - An optional array of default filter objects to be excluded from the count.
 * @param {Boolean} hasDefaultDate {optional} - An optional boolean of default data to be excluded from the count.
 * @returns {number} The number of non-empty filters.
 */

type FilterValue = string | Array<{ value: string; label: string }>;

export const countFilledFilters = (
	filters: OffersFilters,
	defaultFilterObject: Array<{ value: string; label: string }> = [],
	hasDefaultDate: boolean = false,
): number =>
	Object.values(filters).reduce((count, value, index) => {
		if (
			value !== null &&
			value !== undefined &&
			(typeof value !== 'string' || value.trim() !== '') &&
			(hasDefaultDate
				? index !== Object.keys(filters).indexOf('startDate') ||
					value !== dateFormatYearMonthDay(new Date())
				: true) &&
			(typeof value !== 'number' || value !== 0) &&
			(typeof value !== 'object' ||
				(value.length > 0 &&
					!isdefaultFilterObject(value, defaultFilterObject))) &&
			(typeof value !== 'boolean' || value !== false) &&
			(Array.isArray(value)
				? !value.some((item) => item.value === 'all')
				: true)
		) {
			return count + 1;
		}
		return count;
	}, 0);

/**
 * Helper function for countFilledFilters to determine if a value is a default filter object.
 * @param {FilterValue} value - The value to check.
 * @param {Array<{ value: string, label: string }>} defaultFilterObject - An array of default filter objects.
 * @returns {boolean} Whether the value is a default filter object.
 */
const isdefaultFilterObject = (
	value: FilterValue,
	defaultFilterObject: Array<{ value: string; label: string }>,
): boolean =>
	defaultFilterObject.some((obj) => {
		if (Array.isArray(value) && value.length === 1) {
			const objValue = obj.value || '';
			const objLabel = obj.label || '';
			const valValue = value[0].value || '';
			const valLabel = value[0].label || '';
			return objValue === valValue && objLabel === valLabel;
		}
		return false;
	});

export function filterSelectedOption<
	Key extends string,
	Item extends Record<Key, string>,
	Result,
>(
	data: Item[] | null,
	selectedItem: string,
	key: Key,
	mapperFunction: (data: Item[] | Record<Key, string>[]) => Result[],
) {
	return mapperFunction(
		data?.filter((item) => item[key] === selectedItem) || [
			{ [key]: '' } as Record<Key, string>,
		],
	)[0];
}

export const getSelectedFilter = <T extends GenericOption, Key extends keyof T>(
	filter: string,
	key: Key,
	selectedFilters: Record<string, T[]>,
) =>
	!!selectedFilters[filter] && selectedFilters[filter].length > 0
		? selectedFilters[filter]
				.filter((element: T) => element[key] !== 'all')
				.map((element: T) => element[key])
		: [];

export const getNumberArray = (length: number = 0) =>
	Array.from({ length }, (_, index: number) => index);

/**
 * Calculates the end date that is 364 days from the start date.
 *
 * @param startDate - The start date from which the end date will be calculated.
 * @returns A new date that is exactly 364 days after the start date. (This also handles leap years correctly)
 */
export const calculateEndDate = (startDate: string | Date | Moment): Moment => {
	return moment(startDate).add(1, 'year').subtract(1, 'days');
};

/**
 * Function to calculate the total sum of grossRemainingBalance from an array of contracts.
 *
 * @param contracts - An array of contract objects, where each contract contains a quantity object.
 * @returns The total sum of all grossRemainingBalance values from each contract in the contracts array.
 */
export const getTotalGrossRemainingBalance = (
	contracts: Contract[],
): number => {
	return contracts.reduce((total, order) => {
		return total + (order.quantity?.grossRemainingBalance || 0);
	}, 0);
};
