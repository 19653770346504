import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectHasCustomFields,
	selectOrderEntry,
} from 'app/containers/GlobalSaga/selectors';

import { useOrderEntrySchema } from '../../../schemas/useOrderEntrySchema';
import { isMultiSelectTypeInFormValues } from '../../../utils/helpers';

export const useOrderEntryFormData = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const hasCustomFields = useSelector(selectHasCustomFields);
	const orderEntries = useSelector(selectOrderEntry);

	const [hasMultiSelect, setHasMultiSelect] = useState(false);

	const { initialValues, validationSchema } = useOrderEntrySchema();
	const formInstance = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		defaultValues: {
			...initialValues,
		},
	});

	const { watch } = formInstance;
	const values = watch();

	useEffect(() => {
		setHasMultiSelect(isMultiSelectTypeInFormValues(values));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]);

	return {
		dispatch,
		formInstance,
		hasCustomFields,
		hasMultiSelect,
		initialValues,
		isCreatingOrderEntries: orderEntries?.length === 0,
		orderEntries,
		translate,
		validationSchema,
	};
};
