import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { orderEntry } = apiEndpoints;

export function* setOrderEntrySaga(action: PayloadAction<any>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${orderEntry}`);

	const orderEntryData = action?.payload?.data;

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		orderEntryData,
		true,
	);

	if (!!responseData) {
		yield put(actions.setOrderEntrySuccess(responseData));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(globalActions.getOrderEntryRequest({ isActive: false }));
	} else if (!!responseError?.detail) {
		yield put(actions.setOrderEntryError(responseError));
	}
}
