import { z } from 'zod';

import { GenericError } from 'types/GenericError';
import { GenericItem } from 'types/GenericItem';
import { genericItemSchema } from 'utils/validators';

const _cropYearBalanceSchema = z.object({
	beginningBalance: z.number(),
	cropYear: z.number(),
	isActive: z.boolean(),
	rejectedBalance: z.number(),
	totalBalance: z.number(),
});
export interface CropYearBalance
	extends z.infer<typeof _cropYearBalanceSchema> {}
const cropYearBalanceSchema: z.Schema<CropYearBalance> = _cropYearBalanceSchema;

const _contractSchema = z.object({
	internalNumber: z.string().nullable(),
	type: z.string(),
	number: z.string().nullable(),
});
interface Contract extends z.infer<typeof _contractSchema> {}
const contractSchema: z.Schema<Contract> = _contractSchema;

const _customerSchema = z.object({
	name: z.string().nullable(),
	number: z.string().nullable(),
	phone: z.string().nullable(),
	email: z.string().nullable(),
	address: z.string().nullable(),
});
export interface Customer extends z.infer<typeof _customerSchema> {}
const customerSchema: z.Schema<Customer> = _customerSchema;

const _quantitySchema = z.object({
	grossRemainingBalance: z.number(),
	quantityOnContract: z.number(),
	remainingBalance: z.number(),
	unit: z.string(),
});
interface Quantity extends z.infer<typeof _quantitySchema> {}
const quantitySchema: z.Schema<Quantity> = _quantitySchema;

const _transactionSchema = z.object({
	balanceCrop0: z.number(),
	balanceCrop1: z.number(),
	balanceCrop2: z.number(),
	balanceCrop3: z.number(),
	contract: contractSchema.nullable(),
	contractStatus: z.string().nullable(),
	createdOn: z.string(),
	cropYear: z.number(),
	customer: customerSchema.nullable(),
	customerNumber: z.string().nullable(),
	deliveryMonth: z.string().nullable(),
	description: z.string().nullable(),
	destination: z.string().nullable(),
	employee: z.string().nullable(),
	event: z.string(),
	futuresMonth: z.string().nullable(),
	id: z.number(),
	inDNH: z.boolean(),
	isAffectingBalance: z.boolean(),
	isDNHBegin: z.boolean(),
	isDNHEnd: z.boolean(),
	isReverseTransaction: z.boolean(),
	isSell: z.boolean(),
	orphanedBuyBalance: z.number(),
	orphanedSellBalance: z.number(),
	quantity: quantitySchema.nullable(),
	rejectedBalance0: z.number(),
	rejectedBalance1: z.number(),
	rejectedBalance2: z.number(),
	rejectedBalance3: z.number(),
	transactionTypeName: z.string().nullable(),
});
export interface Transaction extends z.infer<typeof _transactionSchema> {}
const transactionSchema: z.Schema<Transaction> = _transactionSchema;

export const liveLedgerResponseSchema = z.object({
	cropYearBalance: z.array(cropYearBalanceSchema),
	events: z.array(genericItemSchema),
	total: z.number(),
	transactions: z.array(transactionSchema),
});
export type LiveLedgerResponse = z.infer<typeof liveLedgerResponseSchema>;

export interface LiveLedgerState {
	totalOrders: number;

	events: GenericItem[] | null;

	selectedFilters: {
		selectedCommodity: string;
		selectedRegion: string;
		selectedCropYear: number | null;
		selectedDate: string | null;
	};

	liveLedgerList: {
		data: Transaction[];
		loading: boolean;
		error: GenericError | null;
	};

	liveLedgerTemplate: {
		url: string | null;
		fileName: string | null;
		loading: boolean;
		error: GenericError | null;
	};

	cropYearBalanceList: CropYearBalance[];
}

export type ContainerState = LiveLedgerState;

export enum LiveLedgerEvents {
	Reject = 'Reject',
	Deny = 'Deny',
	Create = 'Create',
	Cancel = 'Cancel',
	Roll = 'Roll',
	Price = 'Price',
	Edit = 'Edit',
	Threshold = 'Threshold',
	BeginningBalance = 'BeginningBalance',
	AssignFutures = 'AssignFutures',
	Undo = 'Undo',
	ApplyNameId = 'ApplyNameId',
}

export interface ExportExcelPayload {
	successMsg: string;
	errorMsg: string;
}
