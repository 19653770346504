import { CONTRACT_TYPES } from 'utils/constants';

import { useBasisSchema } from './BasisSchema';
import { useFlatPriceSchema } from './FlatPriceSchema';
import { useHTASchema } from './HTASchema';
import { useNTCSchema } from './NTCSchema';

export const useConvertSchema = () => ({
	[CONTRACT_TYPES.flatPrice]: useFlatPriceSchema(),
	[CONTRACT_TYPES.basis]: useBasisSchema(),
	[CONTRACT_TYPES.hta]: useHTASchema(),
	[CONTRACT_TYPES.ntc]: useNTCSchema(),
});
