import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { LocationListPayload } from '../fields/types';
import {
	selectFilters,
	selectPaginationLimit,
	selectPaginationStart,
} from '../selectors';
import { actions } from '../slice';
import { Filters } from '../types';

const { locationsFilter, locationsSearch, locationsRoot } = apiEndpoints;

export function* getLocationsList() {
	const filters: Filters = yield select(selectFilters);
	const limit = yield select(selectPaginationLimit);
	const start = yield select(selectPaginationStart);
	const requestURL = yield new URL(`${apiRoutes.base}/${locationsFilter}`);
	const data = {
		limit,
		start,
		sortColumnName: null,
		sortOrder: 1,
		...filters,
	};

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.locationsListLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.locationsListError(responseError));
	}
}

export function* getSearchLocationsListForContractLocation(
	action: PayloadAction<LocationListPayload>,
) {
	const { name = '', number = '' } = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${locationsSearch}`);
	requestURL.searchParams.append('name', name);
	requestURL.searchParams.append('number', number);
	requestURL.searchParams.append('isDestination', false);
	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(
			actions.searchLocationsListLoadedForContractLocation(responseData),
		);
	} else if (!!responseError.detail) {
		yield put(
			actions.searchLocationsListErrorForContractLocation(responseError),
		);
	}
}

export function* getSearchLocationsListForDestinationLocation(
	action: PayloadAction<LocationListPayload>,
) {
	const { name = '', number = '' } = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${locationsSearch}`);
	requestURL.searchParams.append('name', name);
	requestURL.searchParams.append('number', number);
	requestURL.searchParams.append('isDestination', true);
	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(
			actions.searchLocationsListLoadedForDestinationLocation(responseData),
		);
	} else if (!!responseError.detail) {
		yield put(
			actions.searchLocationsListErrorForDestinationLocation(responseError),
		);
	}
}

export function* getLocationById(action: PayloadAction<{ id: string }>) {
	const { id } = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${locationsRoot}/${id}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.requestedLocationById(responseData));
	} else if (responseError?.status === 2) {
		yield put(actions.setErrorsInLine(responseError.data));
	}
}
