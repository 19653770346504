import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { ntcAdmin } = apiEndpoints;

export function* setNtcAdminSaga(action: PayloadAction<any>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${ntcAdmin}`);

	const ntcAdminData = action?.payload?.data;

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		ntcAdminData,
		true,
	);

	if (!!responseData) {
		yield put(actions.setNtcAdminSuccess(responseData));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(globalActions.loadContractTypeCatalog());
		yield put(globalActions.loadContractTypeList(ContractTypeSource.CONTRACT));
		yield put(globalActions.getNtcAdminRequest());
	} else if (!!responseError?.detail) {
		yield put(actions.setNtcAdminError(responseError));
	}
}
