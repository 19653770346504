import './style.scss';

import {
	ArrowsAltOutlined,
	CheckCircleOutlined,
	ColumnHeightOutlined,
	DollarCircleOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
	MoreOutlined,
	RotateRightOutlined,
	StopOutlined,
	SyncOutlined,
	UndoOutlined,
} from '@ant-design/icons';
import { Button, Popover, Typography } from 'antd';
import classNames from 'classnames';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ContractTypePill } from 'app/components/ContractTypePill';
import { FilterEmpty } from 'app/components/FilterEmpty';
import { GenericIcon } from 'app/components/GenericIcon';
import { GenericTable } from 'app/components/GenericTable';
import { InlineDot } from 'app/components/InlineDot';
import { UserAvatar } from 'app/components/UserAvatar';
import {
	ContractsFixedColumns,
	ERP_STATUS,
	ERP_STATUS_CODE,
} from 'app/containers/Contracts/types';
import { PopoverContent } from 'app/containers/Transactions/components/PopoverContent';
import { TransactionEvents } from 'app/containers/Transactions/types';
import { translations } from 'locales/i18n';
import { FixedColumn } from 'types/FixedColumn';
import { CONSTANTS } from 'utils/constants';
import {
	currencyFormat,
	customFormat,
	dateFormat,
	dateShortFormat,
	hasSetFilters,
	timeFormat,
} from 'utils/helpers';

import {
	selectContractList,
	selectContractListLoading,
	selectFixedColumns,
	selectPagination,
	selectSelectedColumns,
	selectSelectedFilters,
	selectSelectedRows,
	selectTotalContracts,
} from '../../../../selectors';
import { actions } from '../../../../slice';
import { ActionsMenu } from './ActionsMenu';
import { NestedTable } from './NestedTable';

const { Paragraph } = Typography;

interface TableWrapperProps {
	handleExpandedRow?: Function;
}

export const TableWrapper = memo(function TableWrapper({
	handleExpandedRow,
}: TableWrapperProps) {
	const [expandedRows, setExpandedRows] = useState<string[]>([]);
	const selectedColumns = useSelector(selectSelectedColumns);
	const fixedColumns = useSelector(selectFixedColumns);

	const dispatch = useDispatch();

	const translationsScope =
		translations.app.containers.OrderContainer.components.OrdersTable;

	const loading = useSelector(selectContractListLoading);
	const selectedFilters = useSelector(selectSelectedFilters);
	const data = useSelector(selectContractList);
	const pagination = useSelector(selectPagination);
	const total = useSelector(selectTotalContracts);

	const [activePopover, setActivePopover] = useState<string | null>(null);

	const handleVisibleChange = (
		popoverId: string | null,
		newVisible: boolean,
	) => {
		setActivePopover(newVisible ? popoverId : null);
	};
	const handleViewContract = (contractId) => {
		dispatch(
			actions.loadContractSummary({
				id: contractId,
			}),
		);
	};

	const { t: translate } = useTranslation();

	const mounted = useRef();
	useEffect(() => {
		if (!mounted.current && loading) {
			setExpandedRows([]);
		}
	}, [loading]);

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadContractList());
	};

	// For multiple select
	const selectedRowsData = useSelector(selectSelectedRows);
	const selectedRowKeys = selectedRowsData.map(({ id }) => id);

	const onChangeSelection = (_selectedIDs, selectedRowsData) => {
		dispatch(actions.setSelectedRows(selectedRowsData));
	};

	const Item = (props) => (
		<div className="wrapper-column">
			<div className={`order__column ${props.className}`}>{props.children}</div>
		</div>
	);

	const getBasisElements = (data) => [
		{
			label: translate(translationsScope.postedBasis),
			value: data.basis.posted,
		},
		{
			label: translate(translationsScope.pushBasis),
			value: data.basis.push,
		},
		{
			label: translate(translationsScope.netBasis),
			value: data.basis.net,
		},
	];

	const getPriceElements = (data) => [
		{
			label: translate(translationsScope.futures),
			value: data.price.futures,
		},
		{
			label: translate(translationsScope.netBasis),
			value: data.basis.net,
		},
		{
			label: translate(translationsScope.freight),
			value: data.price.freight,
		},
		{
			label: translate(translationsScope.serviceFee),
			value: data.price.fees[0],
		},
		{
			label: translate(translationsScope.serviceFee),
			value: data.price.fees[1],
		},
	];

	const columns: {
		name: keyof ContractsFixedColumns;
		title: string;
		className: string;
		fixed: FixedColumn;
		render: (text, data, index) => JSX.Element;
	}[] = [
		{
			name: 'created',
			title: translate(translationsScope.created),
			className: 'column-table sm',
			fixed: fixedColumns['created'],
			render: (data) => (
				<Item>
					<Popover
						content={
							<div className="popover--content">
								<strong>{translate(translationsScope.status)}: </strong>
								{data.status}
							</div>
						}
						trigger="hover"
					>
						<div>
							<p
								className="text text--bold text--large"
								data-testid="created-date"
							>
								{dateFormat(data.creationDate ?? data.createdOn ?? null)}
							</p>
							<p className="text text--label" data-testid="created-time">
								{timeFormat(data.creationDate ?? data.createdOn ?? null)}
								{typeof data?.comesFromAnOffer === 'boolean' && (
									<InlineDot comesFromAnOffer={data.comesFromAnOffer} />
								)}
							</p>
						</div>
					</Popover>
				</Item>
			),
		},
		{
			name: 'contractNumber',
			title: translate(translationsScope.contractNumber),
			className: 'column-table md',
			fixed: fixedColumns['contractNumber'],
			render: (data) => {
				return (
					<Item>
						<div className="text text--bold flex-row">
							<div>
								{data.contract.erpStatus === ERP_STATUS.Timeout ||
								data.contract.erpStatus === ERP_STATUS.Failed ? (
									<GenericIcon
										hasTooltip
										icon={<ExclamationCircleOutlined />}
										iconType="danger"
										tooltipText={
											!!data.contract.erpMessage
												? data.contract.erpMessage
												: ERP_STATUS_CODE[data.contract.erpStatus]
										}
									/>
								) : data.contract.erpStatus === ERP_STATUS.Processing ? (
									<>
										<span
											className="text text--large text--bold"
											data-testid="status"
										>
											{ERP_STATUS_CODE[1]}
										</span>
									</>
								) : (
									<>
										<span
											className="text text--large text--bold"
											data-testid="contract-number"
										>
											{!!data.contract.number && `${data.contract.number}`}
										</span>
									</>
								)}
							</div>
							{!!data.transactionType && (
								<div>
									<ContractTypePill
										contractType={data.contract.type}
										isSell={data.isSell}
										style={{ marginLeft: 4 }}
										data-testid="contract-type"
									/>
								</div>
							)}
						</div>
					</Item>
				);
			},
		},
		{
			name: 'hrvystId',
			title: translate(translationsScope.hrvystId),
			className: 'column-table md md--content-limit',
			fixed: fixedColumns['hrvystId'],
			render: (data) => {
				return (
					<Item>
						<div className="text text--bold flex-row">
							<div>
								<span
									className="text text--large text--bold"
									data-testid="hrvyst-id"
								>
									{!!data.contract.internalNumber
										? `${data.contract.internalNumber}`
										: '---'}
								</span>
								<span data-testid="hrvyst-parent-id">
									{data.parent && data.parent.number && `${data.parent.number}`}
								</span>
							</div>
						</div>
					</Item>
				);
			},
		},
		{
			name: 'event',
			title: translate(translationsScope.event),
			className: 'column-table sm',
			fixed: fixedColumns['event'],
			render: (data) => {
				let statusClassName;
				let icon;

				const testId = `status-icon-${data.id}`;

				if (data.event === TransactionEvents.Create) {
					statusClassName = 'order__column--open';
					icon = <CheckCircleOutlined />;
				} else if (data.event === TransactionEvents.Price) {
					statusClassName = 'order__column--price';
					icon = <DollarCircleOutlined />;
				} else if (data.event === TransactionEvents.Cancel) {
					statusClassName = 'order__column--cancel';
					icon = <StopOutlined />;
				} else if (data.event === TransactionEvents.Roll) {
					statusClassName = 'order__column--roll';
					icon = <SyncOutlined />;
				} else if (data.event === TransactionEvents.Threshold) {
					statusClassName = 'order__column--threshold';
					icon = <ColumnHeightOutlined />;
				} else if (data.event === TransactionEvents.Edit) {
					statusClassName = 'order__column--edit';
					icon = <EditOutlined />;
				} else if (data.event === TransactionEvents.ApplyNameId) {
					statusClassName = 'order__column--applynameid';
					icon = <ArrowsAltOutlined />;
				} else if (data.event === TransactionEvents.Convert) {
					statusClassName = 'order__column--applynameid';
					icon = <RotateRightOutlined />;
				} else if (data.event === TransactionEvents.Undo) {
					statusClassName = 'order__column--cancel';
					icon = <UndoOutlined />;
				}
				return (
					<div
						className={classNames('order__column', statusClassName)}
						data-testid={testId}
					>
						{icon}
					</div>
				);
			},
		},
		{
			name: 'customer',
			title: translate(translationsScope.customer),
			className: 'column-table md md--content-limit',
			fixed: fixedColumns['customer'],
			render: (data) => (
				<Item>
					<p
						className="text text--centered text--small line-height"
						data-testid="customer-number"
					>
						{data?.customer?.number}
					</p>
					<Paragraph
						className="text text--centered text--small line-height"
						title={data?.customer?.name}
						ellipsis={{ rows: 2 }}
						data-testid="customer-name"
					>
						{data?.customer?.name}
					</Paragraph>
				</Item>
			),
		},
		{
			name: 'employee',
			title: translate(translationsScope.employee),
			className: 'column-table sm',
			fixed: fixedColumns['employee'],
			render: (data) => {
				return (
					<Item>
						<UserAvatar user={data.employee} testId="employee-initials" />
					</Item>
				);
			},
		},
		{
			name: 'commodity',
			title: translate(translationsScope.commodity),
			className: 'column-table sm sm--content-limit',
			fixed: fixedColumns['commodity'],
			render: (data) => (
				<Item>
					<Paragraph
						className="text text--large line-height"
						title={data.commodity}
						ellipsis={{ rows: 2 }}
						data-testid="commodity"
					>
						{data.commodity}
					</Paragraph>
				</Item>
			),
		},
		{
			name: 'crop',
			title: translate(translationsScope.crop),
			className: 'column-table sm',
			fixed: fixedColumns['crop'],
			render: (data) => (
				<Item>
					<p
						className="text text--bold text--large text--centered"
						data-testid="crop-year"
					>
						{data.cropYear}
					</p>
				</Item>
			),
		},
		{
			name: 'deliveryStartDate',
			title: translate(translationsScope.deliveryStartDate),
			className: 'column-table offers-column sm',
			fixed: fixedColumns['deliveryStartDate'],
			render: (data) => (
				<Item>
					<p
						className="text text--large text--centered"
						data-testid="delivery-start-date"
					>
						{dateShortFormat(data.delivery.begin)}
					</p>
				</Item>
			),
		},
		{
			name: 'deliveryEndDate',
			title: translate(translationsScope.deliveryEndDate),
			className: 'column-table offers-column sm',
			fixed: fixedColumns['deliveryEndDate'],
			render: (data) => (
				<Item>
					<p
						className="text text--large text--centered"
						data-testid="delivery-end-date"
					>
						{dateShortFormat(data.delivery.end)}
					</p>
				</Item>
			),
		},
		{
			name: 'futuresMonth',
			title: translate(translationsScope.futuresMonth),
			className: 'column-table xs',
			fixed: fixedColumns['futuresMonth'],
			render: (data) => (
				<Item>
					<p
						className="text text--large text--centered"
						data-testid="futures-month"
					>
						{data.price.futuresMonth}
					</p>
				</Item>
			),
		},
		{
			name: 'destination',
			title: translate(translationsScope.destination),
			className: 'column-table xs xs--content-limit header--nowrap',
			fixed: fixedColumns['destination'],
			render: (data) => (
				<Item>
					<Paragraph
						className="text text--large line-height"
						title={data.delivery.destination}
						ellipsis={{ rows: 2 }}
						data-testid="destination"
					>
						{data.delivery.destination}
					</Paragraph>
				</Item>
			),
		},
		{
			name: 'pushBasis',
			title: translate(translationsScope.pushBasis),
			className: 'column-table xs',
			fixed: fixedColumns['pushBasis'],
			render: (data) => (
				<Item>
					<p
						className="text text--large text--centered"
						data-testid="push-basis"
					>
						{currencyFormat(
							data.basis.push,
							CONSTANTS.FIXED_DECIMALS,
							CONSTANTS.FIXED_DECIMALS,
						)}
					</p>
				</Item>
			),
		},
		{
			name: 'balance',
			title: translate(translationsScope.balance),
			className: 'column-table sm',
			fixed: fixedColumns['balance'],
			render: (data) => (
				<Item>
					<p className="text text--large text--bold" data-testid="balance">
						{customFormat(
							data.quantity.grossRemainingBalance,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						)}
					</p>

					<p className="text" data-testid="remaining-balance">
						<span>
							{translate(translationsScope.unpriced) + ' '}
							{data.remainingBalance || data.quantity.remainingBalance
								? customFormat(
										data.remainingBalance || data.quantity.remainingBalance,
										true,
										CONSTANTS.FIXED_QUANTITY_DECIMALS,
									)
								: '---'}
						</span>
					</p>
				</Item>
			),
		},
		{
			name: 'quantity',
			title: translate(translationsScope.quantity),
			className: 'column-table sm',
			fixed: fixedColumns['quantity'],
			render: (data) => (
				<Item>
					<p className="text text--large text--bold" data-testid="quantity">
						{customFormat(
							data.quantity.quantityOnContract,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						)}
					</p>
				</Item>
			),
		},
		{
			name: 'futures',
			title: translate(translationsScope.futures),
			className: 'column-table xs',
			fixed: fixedColumns['futures'],
			render: (data) => (
				<Item>
					<p className="text text--large text--centered" data-testid="futures">
						{!!!data.passFill || data.price.futures !== null // The order isn't filled yet
							? currencyFormat(
									data.price.futures,
									CONSTANTS.FIXED_DECIMALS,
									CONSTANTS.FIXED_DECIMALS,
								)
							: ''}
					</p>
				</Item>
			),
		},
		{
			name: 'basis',
			title: translate(translationsScope.basis),
			className: 'column-table xs',
			fixed: fixedColumns['basis'],
			render: (data) => (
				<Item>
					<Popover
						content={<PopoverContent elements={getBasisElements(data)} />}
						trigger="hover"
					>
						<p className="text text--large text--centered" data-testid="basis">
							{currencyFormat(
								data.basis.net,
								CONSTANTS.FIXED_DECIMALS,
								CONSTANTS.FIXED_DECIMALS,
							)}
						</p>
					</Popover>
				</Item>
			),
		},
		{
			name: 'cashPrice',
			title: translate(translationsScope.cashPrice),
			className: 'column-table xs',
			fixed: fixedColumns['cashPrice'],
			render: (data) => (
				<Item>
					<Popover
						content={<PopoverContent elements={getPriceElements(data)} />}
						trigger="hover"
					>
						<p
							className="text text--large text--centered"
							data-testid="cash-price"
						>
							{!!!data.passFill || data.price.price !== null // The order isn't filled yet
								? currencyFormat(
										data.price.price,
										CONSTANTS.FIXED_DECIMALS,
										CONSTANTS.FIXED_DECIMALS,
									)
								: ''}
						</p>
					</Popover>
				</Item>
			),
		},
	];

	const tableLocale = {
		emptyText: loading ? (
			false
		) : hasSetFilters(selectedFilters) ? (
			<FilterEmpty />
		) : (
			false
		),
	};

	const onRow = (record) => {
		return {
			className: `border-left--${record.status.toLowerCase()}`,
			'data-testid': `row-${record.contract.internalNumber}`,
		};
	};

	columns.sort((a, b) => {
		const first = a.fixed === 'left' ? 0 : a.fixed === false ? 1 : 2;
		const second = b.fixed === 'left' ? 0 : b.fixed === false ? 1 : 2;

		if (first === second) {
			return 0;
		} else if (first < second) {
			return -1;
		} else {
			return 1;
		}
	});

	const expandedColumns = [
		...columns,
		{
			name: 'dots',
			title: '',
			className: 'column-table',
			fixed: fixedColumns['dots'],
			render: (_, data, index) => {
				const dataId = `${data.id}|${data.parent.id}|${data.creationDate ?? data.createdOn}|${index}`;
				return (
					<div className="wrapper-column">
						<div className="order__column last-column">
							<Popover
								visible={activePopover === dataId}
								onVisibleChange={(newVisible) =>
									handleVisibleChange(dataId, newVisible)
								}
								content={
									<ActionsMenu
										setActivePopover={setActivePopover}
										data={data}
										handleViewOrder={() => handleViewContract(data.parent.id)}
									/>
								}
								trigger="click"
								data-testid="more-actions-trigger"
							>
								<Button type="text" icon={<MoreOutlined />} />
							</Popover>
						</div>
					</div>
				);
			},
		},
	];
	const expandedRowRender = () => {
		return <NestedTable columns={expandedColumns} />;
	};

	const onTableRowExpand = (expanded, record) => {
		const keys: string[] = [];
		if (expanded) {
			keys.push(record.id);
			handleExpandedRow && handleExpandedRow(record.id);
		}
		setExpandedRows(keys);
	};

	const rootColumns = [
		...columns,
		{
			name: 'dots',
			title: '',
			className: 'column-table',
			fixed: fixedColumns['dots'],
			render: (_, data, index) => {
				const dataId = `${data.id}|${data.creationDate ?? data.createdOn}|${index}`;
				return (
					<Item className="last-column">
						<Popover
							visible={activePopover === dataId}
							onVisibleChange={(newVisible) =>
								handleVisibleChange(dataId, newVisible)
							}
							content={
								<ActionsMenu
									setActivePopover={setActivePopover}
									data={data}
									handleViewOrder={() => handleViewContract(data.id)}
								/>
							}
							trigger="click"
							data-testid="more-actions-trigger"
						>
							<Button type="text" icon={<MoreOutlined />} />
						</Popover>
					</Item>
				);
			},
		},
	]
		.filter((col) => selectedColumns[col.name])
		.map((col) => {
			return {
				...col,
				fixed: fixedColumns[col.name],
			};
		});

	return (
		<GenericTable.VirtualTable
			className="table-wrapper"
			columns={rootColumns}
			loading={loading}
			pagination={{
				total: total,
				pageSize: pagination.limit,
				defaultPageSize: pagination.limit,
				current: pagination.start,
				onChange: handleOnChange,
				pageSizeOptions: ['20', '50', '100'],
			}}
			data={data}
			rowKey={(record) => record.id}
			locale={tableLocale}
			onRow={onRow}
			onExpand={onTableRowExpand}
			expandable={{
				expandedRowRender,
				rowExpandable: (record) => record.childs > 0,
			}}
			expandedRowKeys={expandedRows}
			rowSelection={{
				onChange: onChangeSelection,
				preserveSelectedRowKeys: false,
				selectedRowKeys,
			}}
		/>
	);
});
