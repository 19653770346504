import { useTranslation } from 'react-i18next';

import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';

export const UseDefaultMessagesTranslations = () => {
	const { t: translate } = useTranslation();
	const domain = translations.app.components.ToastMessage;

	return {
		[CustomNotificationTypes.ERROR]: {
			title: translate(domain.error.title),
			message: translate(domain.error.message),
		},

		[CustomNotificationTypes.SUCCESS]: {
			title: translate(domain.success.title),
			message: translate(domain.success.message),
		},

		[CustomNotificationTypes.INFO]: {
			title: translate(domain.info.title),
			message: translate(domain.info.message),
		},

		[CustomNotificationTypes.WARNING]: {
			title: translate(domain.warning.title),
			message: translate(domain.warning.message),
		},
	};
};

/* invalidEmail: , */
