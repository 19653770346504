import React, { memo, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { selectCommoditiesList } from 'app/containers/GlobalSaga/selectors';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { translations } from 'locales/i18n';
import { getCropYearOptions, mapToLabelValue } from 'utils/helpers';

import { SourceContext } from '../..';
import { useDefaultValues } from '../../hooks/useDefaultValues';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';
interface Props {
	source?: CalculatedFieldsSource;
	resetFields?: Array<string>;
	reloadFuturesMonth?: boolean;
	disabled?: boolean;
	hidden?: boolean;
	checkDefaultValues?: boolean;
}

export const Commodity = memo(function Commodity(props: Props) {
	const {
		reloadFuturesMonth = true,
		disabled = false,
		resetFields = [],
		hidden = false,
		source = CalculatedFieldsSource.Transactions,
		checkDefaultValues = false,
	} = props;

	const sourceName = useContext(SourceContext);

	const defaultValues = useDefaultValues();

	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.Commodity;

	const commoditiesList = useSelector(selectCommoditiesList);
	const commoditiesOptions = mapToLabelValue(commoditiesList);

	const { getValues, reset, setValue } = useFormContext();

	const { clearStore, getFuturesMonths } = useHandleBusinessRules(sourceName);

	useEffect(() => {
		loadFuturesMonths();
		if (checkDefaultValues) {
			checkDefaultValue();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const checkDefaultValue = () => {
		const { commodity } = defaultValues;
		const isValid = commoditiesOptions.find(
			(option) => option.value === commodity?.value,
		);

		if (!isValid && commodity.value) {
			setValue('commodity', { value: null });
		}
	};

	const loadFuturesMonths = () => {
		if (reloadFuturesMonth) {
			getFuturesMonths(source);
		}
	};
	const { initialValues } = useOrderEntriesFormSchema();

	const handleChange = (currentValue, values) => {
		const { commodity } = getValues();
		if (commodity) {
			loadFuturesMonths();
			clearStore();
			const cropYearOptions = getCropYearOptions(commoditiesList, commodity);
			const firstEnabledCrop = cropYearOptions?.find((crop) => !crop.disabled);

			let aditionalFieldsToReset = resetFields.reduce((previous, current) => {
				return { ...previous, [current]: values[current] };
			}, {});

			reset({
				theirContract: values.theirContract,
				preHedgeOrderType: values.preHedgeOrderType,
				deliveryDatesMode: values.deliveryDatesMode,
				contractNumber: values.contractNumber,
				transaction: values.transaction,
				contract: values.contract,
				action: values.action,
				commodity: values.commodity,
				cropYear: firstEnabledCrop?.value || null,
				employee: values.employee,
				...aditionalFieldsToReset,
				...initialValues,
			});
		}
	};
	return (
		<GenericForm.FormItem
			onChange={handleChange}
			name="commodity"
			label={translate(msg.commodityLabel)}
			data-testid="commodity-form-item"
			customClassName={hidden ? ['hidden'] : []}
		>
			<Dropdown
				data-testid="commodity-input-item"
				disabled={disabled}
				placeholder={translate(msg.commodityPlaceholder)}
				options={commoditiesOptions}
			></Dropdown>
		</GenericForm.FormItem>
	);
});
