import { Button } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { selectTableDataLoading } from '../../../../selectors';

interface FormButtonProps {
	onClear: () => void;
}
export const FormButtons = memo(function FormButtons({
	onClear,
}: FormButtonProps) {
	const { t: translate } = useTranslation();
	const tableDataLoading = useSelector(selectTableDataLoading);
	return (
		<div
			className="form-buttons filters-generator__buttons"
			data-testid="submit-wrapper"
		>
			<Button type="primary" htmlType="submit" disabled={tableDataLoading}>
				{translate(
					translations.app.containers.GenericReport.submitFilterButton,
				)}
			</Button>
			<Button data-testid="clear-button" onClick={onClear}>
				{translate(translations.app.containers.GenericReport.clearFilterButton)}
			</Button>
		</div>
	);
});
