/**
 *
 * Edit Service Fee
 *
 */
import { EditFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { serviceFeesActions } from '../../../../slice';
import { View } from '../../../../types';

interface Props {
	rowData: any;
}

export const Edit = ({ rowData }: Props) => {
	const dispatch = useDispatch();

	const handleEditServiceFee = (rowData) => {
		dispatch(serviceFeesActions.setCurrentView(View.Edit));
		dispatch(serviceFeesActions.loadEditServiceFee(rowData));
	};

	return (
		<div className="actions">
			<Button
				data-testid="edit-button"
				onClick={() => handleEditServiceFee(rowData)}
				type="text"
			>
				<EditFilled />
			</Button>
		</div>
	);
};
