import React from 'react';
import { useSelector } from 'react-redux';

import { FeatureFlag } from 'types/FeatureFlags';

import { selectFeatureFlags } from '../GlobalSaga/selectors';

export function Markets() {
	const featureFlags = useSelector(selectFeatureFlags);
	if (!featureFlags[FeatureFlag.enableMarketsTab]) return null;
	return (
		<iframe
			className="w-full h-full"
			title="Markets"
			src="https://mdemo2.cqg.com/hrvyst/desktop/main?partnerToken=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJqZ2lsbWFuIiwiZXhwIjoxNzYyODkyOTI3fQ.QPA1fFyPrbZJHtXBaf4EHNJvLWoPbLSir6OuEjFSiJjK88raYjKx1tKtYtTVBPF_ts7Q977IIkthiEgBya12lMNPDI-7lSehZedIM3nUeRUAIgqPTAEf43EB8iInQeLDiGh-WjDUAKOTVSCfOQhaV7VL6uItePZg3_pwomN6chXRlIOH88Gb8P_75cd5m74QYM6rcYrdi0vNwvzAOUb9Ej4d80l1yYK5rFOO5ij6Gg5_UxSSJyqAWW8i5y-fCsEUGQbLSsIqFGK75BVK6eWRhkehaSdvuobi04-nrjsXVw-UQ_NO4At11L4OXv8tV6g6ksj_XtxEKEQE_aPXm071Tg"
		/>
	);
}
