import './styles.scss';

import { EyeFilled } from '@ant-design/icons';
import { Card } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { GenericTable } from 'app/components/GenericTable';
import { translations } from 'locales/i18n';
import { dateTimeFormat } from 'utils/helpers';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { notificationsHistorySaga } from './saga';
import {
	selectNotificationsHistory,
	selectNotificationsHistoryLoading,
	selectNotificationsHistoryTotal,
	selectPagination,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';

export const NotificationsHistory = memo(function NotificationsHistory() {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: notificationsHistorySaga });

	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(actions.loadNotificationsHistory({}));
	}, []);

	const data = useSelector(selectNotificationsHistory);
	const loading = useSelector(selectNotificationsHistoryLoading);
	const total = useSelector(selectNotificationsHistoryTotal);
	const pagination = useSelector(selectPagination);
	const translationsScope = translations.app.containers.NotificationsHistory;
	const columns = [
		{
			title: translate(translationsScope.subject),
			key: 'subject',
			dataIndex: 'subject',
			render: (data) => <Paragraph>{data}</Paragraph>,
		},
		{
			title: translate(translationsScope.body),
			key: 'body',
			dataIndex: 'body',
			render: (data) => <Paragraph>{data}</Paragraph>,
		},
		{
			title: translate(translationsScope.aggregateType),
			key: 'aggregateType',
			dataIndex: 'aggregateType',
			render: (data) => <Paragraph>{data}</Paragraph>,
		},
		{
			title: translate(translationsScope.dateDelivered),
			key: 'dateDelivered',
			dataIndex: 'dateDelivered',
			render: (data) => (
				<Paragraph>
					{data
						? dateTimeFormat(data)
						: translate(translationsScope.notDelivered)}
				</Paragraph>
			),
		},
		{
			title: translate(translationsScope.dateDismissed),
			key: 'dateDismissed',
			dataIndex: 'dateDismissed',
			render: (data) => (
				<Paragraph>
					{data
						? dateTimeFormat(data)
						: translate(translationsScope.notDismissed)}
				</Paragraph>
			),
		},
		{
			title: translate(translationsScope.dateSeen),
			key: 'dateSeen',
			dataIndex: 'dateSeen',
			render: (data) => (
				<Paragraph>
					{data ? dateTimeFormat(data) : translate(translationsScope.notSeen)}
				</Paragraph>
			),
		},
		{
			title: translate(translationsScope.viewDetails),
			className: 'view-details--column',
			width: 70,
			fixed: 'right' as 'right',
			dataIndex: 'aggregateId',
			render: (_, record) =>
				record.path && (
					<Link to={record.path}>
						<EyeFilled className="view-icon" />
					</Link>
				),
		},
	];

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.loadNotificationsHistory({
				limit: pageSize,
				start: page,
			}),
		);
	};

	return (
		<Card bordered={true} className="notifications-history-wrapper">
			<GenericTable.VirtualTable
				className="table-wrapper notifications-history-table"
				columns={columns}
				loading={loading}
				pagination={{
					total: total,
					pageSize: pagination.limit,
					defaultPageSize: pagination.limit,
					current: pagination.start,
					onChange: handleOnChange,
					pageSizeOptions: ['20', '50', '100'],
				}}
				data={data}
				rowKey={(record) => record.id}
			></GenericTable.VirtualTable>
		</Card>
	);
});
