import { takeLatest } from 'redux-saga/effects';

import { addBid } from './Sagas/add-bid-saga';
import {
	loadBidsheetSummary,
	publishBidsheet,
} from './Sagas/bidsheet-publish-saga';
import { getBidsheetList } from './Sagas/bidsheet-saga';
import { deleteBidRow } from './Sagas/delete-bid-row-saga';
import { exportBids } from './Sagas/export-saga';
import {
	getAllNotificationGroups,
	notifyBidSent,
} from './Sagas/notification-groups-saga';
import { noBidsList } from './Sagas/offers-no-bids-saga';
import { orphansAndAvailableList } from './Sagas/orphans-available-bids-saga';
import { rollOrphanOffers } from './Sagas/roll-orphan-saga';
import { totalOrphans } from './Sagas/total-orphans-saga';
import { updateBid } from './Sagas/update-bid-saga';
import { uploadBidsheet } from './Sagas/uploadBidsheet-saga';
import { actions } from './slice';

export function* bidsheetSaga() {
	yield takeLatest(actions.uploadBidsheet.type, uploadBidsheet);
	yield takeLatest(actions.requestNoBidList.type, noBidsList);
	yield takeLatest(
		actions.requestOrphansAndAvailables.type,
		orphansAndAvailableList,
	);
	yield takeLatest(actions.loadBidsheetList.type, getBidsheetList);
	yield takeLatest(actions.loadBidsheetSummary.type, loadBidsheetSummary);
	yield takeLatest(actions.publishBidsheet.type, publishBidsheet);
	yield takeLatest(actions.rollOrphanOffers.type, rollOrphanOffers);
	yield takeLatest(actions.requestTotalOrphans.type, totalOrphans);
	yield takeLatest(actions.deleteBidRow.type, deleteBidRow);
	yield takeLatest(actions.addBidRow, addBid);
	yield takeLatest(actions.updateBid, updateBid);
	yield takeLatest(actions.exportBids.type, exportBids);

	// Notification Groups
	yield takeLatest(
		actions.getAllNotificationGroups.type,
		getAllNotificationGroups,
	);
	yield takeLatest(actions.notifyBidSent.type, notifyBidSent);
}
