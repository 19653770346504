import { CONTRACT_TYPES } from 'utils/constants';

import { useTranslations } from '../../../shared/useTranslations';
import { useBasisSchema } from './BasisSchema';
import { useFlatPriceSchema } from './FlatPriceSchema';
import { useHTASchema } from './HTASchema';
import { useNTCSchema } from './NTCSchema';

export const useEditDefaultSchema = (
	translations: ReturnType<typeof useTranslations>,
) => ({
	[CONTRACT_TYPES.flatPrice]: useFlatPriceSchema(translations),
	[CONTRACT_TYPES.basis]: useBasisSchema(translations),
	[CONTRACT_TYPES.hta]: useHTASchema(translations),
	[CONTRACT_TYPES.ntc]: useNTCSchema(translations),
});
