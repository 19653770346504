import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { serviceFeeDataSchema } from 'types/ServiceFee';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';
import { genericItemSchema } from 'utils/validators';

import { actions } from '../slice';
const { serviceFeeRoot, serviceFeeMonths, serviceFeeTypes } = apiEndpoints;

export function* getServiceFeesList() {
	let requestURL = new URL(
		`${apiRoutes.base}/${serviceFeeRoot}/${serviceFeeMonths}`,
	);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(serviceFeeDataSchema),
	});

	if (!!data) {
		yield* put(actions.ServiceFeeListLoaded(data));
	} else if (!!error?.detail) {
		yield* put(actions.ServiceFeeListError(error));
	}
}

export function* getServiceFeeTypes() {
	let requestURL = new URL(
		`${apiRoutes.base}/${serviceFeeRoot}/${serviceFeeTypes}`,
	);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(genericItemSchema),
	});

	if (!!data) {
		yield* put(actions.ServiceFeeTypesListLoaded(data));
	} else if (!!error?.detail) {
		yield* put(actions.ServiceFeeListError(error));
	}
}
