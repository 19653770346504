import React from 'react';

import { Filters } from './Filters';
import { Table } from './Table';

export const List = () => {
	return (
		<>
			<Filters />
			<Table />
		</>
	);
};
