/**
 *
 * GenericIcon
 *
 */
import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import React, { memo, ReactNode } from 'react';

interface GenericIconProps extends PopoverProps {
	icon: ReactNode;
	iconType?: 'success' | 'info' | 'warning' | 'danger';
	hasTooltip: boolean;
	tooltipText?: string;
}

export const GenericIcon: React.FC<GenericIconProps> = memo(
	function GenericIcon({
		icon,
		hasTooltip,
		tooltipText,
		placement = 'top',
		trigger = 'hover',
		iconType = 'info',
	}: GenericIconProps) {
		const iconContainer = (
			<span className={`generic-icon ${iconType}`}>{icon}</span>
		);

		return (
			<div data-testid={'generic-icon'}>
				{hasTooltip ? (
					<Popover
						placement={placement}
						content={
							<div className="popover-content">
								{iconContainer}
								<span>{tooltipText}</span>
							</div>
						}
						trigger={trigger}
					>
						{iconContainer}
					</Popover>
				) : (
					iconContainer
				)}
			</div>
		);
	},
);
