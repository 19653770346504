import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { translations } from 'locales/i18n';

import { selectCurrentCustomerLoading } from '../../selectors';
import { actions } from '../../slice';
import { Customer } from '../../types';
import { useCreateCustomerSchema } from './schemas';

const domain =
	translations.app.containers.Settings.sections.Customers.Modals.CreateEdit;

export const CreateCustomer = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const isLoading = useSelector(selectCurrentCustomerLoading);

	const schema = useCreateCustomerSchema();
	const resolver = yupResolver(schema?.validationSchema);
	const formInstance = useForm({
		defaultValues: {
			...schema?.initialValues,
		},
		mode: 'all',
		resolver,
	});

	const handleSubmit = (values) => {
		const fullNameCreated = `${values.firstName} ${values.lastName}`;

		const data: Customer = {
			firstName: values.firstName,
			lastName: values.lastName,
			number: values.erpNumber,
			street: values.address,
			city: values.city,
			state: values.state?.value,
			zipCode: values.zipCode?.toString(),
			country: values.country?.value,
			phoneNumber: values.cellphone?.toString(),
			email: values.email,
			isInSync: true,
			isMobileAppEnable: values.isMobileAppEnable,
			mobileAppLimit: values.mobileAppLimit,
			restrictions: {
				canBeEdited: true,
			},
			isEmailNotificationEnabled: values.isEmailNotificationEnabled,
			isSmsNotificationEnabled: values.isSmsNotificationEnabled,
		};

		dispatch(
			actions.createCustomer({
				data: data,
				successMessage: translate(domain.toastSuccessCreateMessage, {
					fullNameCreated,
				}),
			}),
		);
	};

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'customer-form__create-customer',
			htmlType: 'button',
			children: translate(domain.cancelCustomerModalButton),
			onClick: handleClose,
			disabled: isLoading,
			key: 'cancel',
		},
		{
			htmlType: 'submit',
			children: translate(domain.addCustomerModalButton),
			disabled: isLoading || !formInstance.formState.isValid,
			key: 'create-customer',
			dataTestId: 'create-customer-modal-btn',
		},
	];

	return (
		<GenericForm.ModalContainer
			className="create-customer-modal"
			key="createCustomerModal"
			onCancel={handleClose}
			title={translate(domain.createCustomerModalTitle)}
		>
			<GenericForm.Form
				buttonsDefinition={btnsDefinition}
				className="create-customer-form"
				formInstance={formInstance}
				key="createCustomerForm"
				onSubmit={handleSubmit}
				validationSchema={schema?.validationSchema}
			>
				{schema?.elements}
			</GenericForm.Form>
		</GenericForm.ModalContainer>
	);
};
