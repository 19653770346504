import { takeLatest } from 'redux-saga/effects';

import {
	downloadFilledHedgemapRequest,
	downloadHedgeMapTemplate,
	getHedgeMappingList,
	submitHedgeMapToServer,
	uploadHedgeMapTemplateUpdated,
} from '../HedgeMapping/Sagas/hedgemap-saga';
import { hedgeMappingActions } from './slice';

export function* hedgeMappingSaga() {
	yield takeLatest(
		hedgeMappingActions.loadHedgeMapList.type,
		getHedgeMappingList,
	);
	yield takeLatest(
		hedgeMappingActions.loadHedgeMapTemplate.type,
		downloadHedgeMapTemplate,
	);

	yield takeLatest(
		hedgeMappingActions.uploadHedgeMapTemplateUpdated.type,
		uploadHedgeMapTemplateUpdated,
	);
	yield takeLatest(
		hedgeMappingActions.submitHedgeMapToServer.type,
		submitHedgeMapToServer,
	);
	yield takeLatest(
		hedgeMappingActions.downloadFilledHedgemapRequest.type,
		downloadFilledHedgemapRequest,
	);
}
