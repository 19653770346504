import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { userRolesActions } from '../slice';

const { rolesRoot } = apiEndpoints;

export function* deleteUserRole(action: PayloadAction<any>) {
	const name = action.payload;
	const roleName = encodeURIComponent(name);
	const requestURL = yield new URL(
		`${apiRoutes.identity}/${rolesRoot}?name=${roleName}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Delete,
	);

	if (!!responseData) {
		yield put(userRolesActions.UserRoleDeleted());
		yield put(userRolesActions.loadUserRolesList());
	} else if (!!responseError.detail) {
		yield put(userRolesActions.deleteUserRoleError(responseError));
	}
}
