import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the SummaryDashboard container
export const initialState: ContainerState = {
	longShortSummary: {
		selectedFilters: {
			commodity: [],
			cropYear: [],
			dateTime: null,
		},
	},

	longShortReport: {
		data: [],
		loading: false,
		error: null,
	},

	longShortSummaryRecap: {
		selectedFilters: {
			dateTime: null,
		},
	},

	longShortReportRecap: {
		data: [],
		loading: false,
		error: null,
	},
};

const summaryDashboardSlice = createSlice({
	name: 'summaryDashboard',
	initialState,
	reducers: {
		setSelectedFilters(state, action: PayloadAction<any>) {
			state.longShortSummary.selectedFilters = {
				...state.longShortSummary.selectedFilters,
				...action.payload,
			};
		},

		loadLongShortReport(state) {
			state.longShortReport = {
				data: [],
				loading: true,
				error: null,
			};
		},

		longShortReportError(state, action: PayloadAction<GenericError>) {
			state.longShortReport = {
				...state.longShortReport,
				loading: false,
				error: action.payload,
			};
		},

		longShortReportLoaded(state, action: PayloadAction<any>) {
			state.longShortReport = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		resetLongShortReportError(state) {
			state.longShortReport = {
				...state.longShortReport,
				error: null,
			};
		},

		setSelectedFiltersRecap(state, action: PayloadAction<any>) {
			state.longShortSummaryRecap.selectedFilters = {
				...state.longShortSummaryRecap.selectedFilters,
				...action.payload,
			};
		},

		loadLongShortReportRecap(state) {
			state.longShortReportRecap = {
				data: [],
				loading: true,
				error: null,
			};
		},

		longShortReportErrorRecap(state, action: PayloadAction<GenericError>) {
			state.longShortReportRecap = {
				...state.longShortReportRecap,
				loading: false,
				error: action.payload,
			};
		},

		longShortReportLoadedRecap(state, action: PayloadAction<any>) {
			state.longShortReportRecap = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		resetLongShortReportErrorRecap(state) {
			state.longShortReportRecap = {
				...state.longShortReportRecap,
				error: null,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = summaryDashboardSlice;
