import './style.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { actions as hubActions } from 'app/containers/HubSaga/slice';
import { EventType } from 'types/EventType';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { ApplyNameIdModal } from './components/Modals/ApplyNameID';
import { BulkConvertModal } from './components/Modals/BulkConvertModal';
import { BulkEditModal } from './components/Modals/BulkEditModal';
import { CancelModal } from './components/Modals/CancelModal';
import { ConvertModal } from './components/Modals/ConvertModal';
import { EditModal } from './components/Modals/EditModal';
import { PriceModal } from './components/Modals/PriceModal';
import { PriceViaEFPModal } from './components/Modals/PriceViaEFPModal';
import { PriceViaOfferModal } from './components/Modals/PriceViaOfferModal';
import { RollModal } from './components/Modals/RollModal';
import UndoModal from './components/Modals/UndoModal';
import { Details } from './components/Views/Details';
import { List } from './components/Views/List';
import { contractsSaga } from './root-saga';
import {
	selectActiveContract,
	selectApplyNameId,
	selectCurrentEventType,
	selectCurrentModal,
	selectCurrentView,
	selectSelectedRows,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';
import { ContractsModals, ContractsViews } from './types';

export function Contracts() {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: contractsSaga });

	const currentModal = useSelector(selectCurrentModal);
	const currentView = useSelector(selectCurrentView);
	const activeContractData = useSelector(selectActiveContract);
	const applyNameData = useSelector(selectApplyNameId).data;
	const currentEventType = useSelector(selectCurrentEventType);
	const selectedRowsData = useSelector(selectSelectedRows);

	const dispatch = useDispatch();

	const { id: contractId } = useParams<{ id: string }>();

	useEffect(() => {
		if (contractId) {
			dispatch(actions.loadContractSummary({ id: contractId }));
		} else {
			dispatch(actions.setCurrentView(ContractsViews.Table));
		}
	}, [contractId]);

	useEffect(() => {
		// Enable auto-refresh for contracts
		dispatch(hubActions.subscribeToContractUpdates());
		return () => {
			dispatch(hubActions.unsubscribeFromContractUpdates());

			dispatch(actions.resetContractError());
			dispatch(actions.setCurrentView(ContractsViews.Table));
			dispatch(
				actions.setSelectedFilters({
					contractOrCustomer: '',
					number: '',
				}),
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{currentView === ContractsViews.Details ? <Details /> : <List />}
			{currentModal === ContractsModals.Edit && activeContractData && (
				<EditModal />
			)}
			{currentModal === ContractsModals.Convert && activeContractData && (
				<ConvertModal />
			)}
			{currentModal === ContractsModals.BulkConvert &&
				selectedRowsData &&
				activeContractData && <BulkConvertModal />}
			{currentModal === ContractsModals.BulkEdit &&
				selectedRowsData &&
				activeContractData && <BulkEditModal />}
			{currentModal === ContractsModals.Cancel && <CancelModal />}
			{currentModal === ContractsModals.Undo && <UndoModal />}
			{currentModal === ContractsModals.PriceRoll &&
				(currentEventType === EventType.rollingBasis ||
					currentEventType === EventType.rollingHTA) && (
					<RollModal eventType={currentEventType} />
				)}
			{currentModal === ContractsModals.PriceRoll &&
				(currentEventType === EventType.pricingBasis ||
					currentEventType === EventType.pricingHTA) && (
					<PriceModal eventType={currentEventType} />
				)}
			{currentModal === ContractsModals.PriceRoll &&
				currentEventType === EventType.pricingViaEfpBasis && (
					<PriceViaEFPModal eventType={currentEventType} />
				)}
			{currentModal === ContractsModals.PriceRoll &&
				(currentEventType === EventType.pricingViaOfferBasis ||
					currentEventType === EventType.pricingViaOfferHTA) && (
					<PriceViaOfferModal eventType={currentEventType} />
				)}
			{currentModal === ContractsModals.ApplyNameId && applyNameData && (
				<ApplyNameIdModal />
			)}
		</>
	);
}
