import { z } from 'zod';

const _roundingRuleSchema = z.object({
	id: z.string().uuid(),
	contractTypeId: z.string().uuid(),
	decimalPlace: z.number(),
	isSell: z.boolean(),
	from: z.number(),
	to: z.number(),
	roundingTypeId: z.string().uuid().nullable(),
	isActive: z.boolean(),
});
export interface RoundingRule extends z.infer<typeof _roundingRuleSchema> {}
export const roundingRuleSchema: z.Schema<RoundingRule> = _roundingRuleSchema;
