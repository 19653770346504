import './styles.scss';

import { ClockCircleOutlined } from '@ant-design/icons';
import { Spin, Switch, Tag, TimePicker } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericTable } from 'app/components/GenericTable';
import { translations } from 'locales/i18n';
import { FieldDefinition } from 'types/FieldDefinition';
import { formSchemaGenerator } from 'utils/GenericFormGenerators/generator';

import { useNotificationEventTypeSchema } from '../../schemas/useNotificationEventTypeSchema';
import {
	NotificationEvent,
	NotificationEventStatus,
	NotificationEventType,
	NotificationSettingsScope,
} from '../../types';

const TOGGLE_WIDTH = 90;

interface Props {
	preferences: NotificationEvent[];
	handleSubmit: (records: NotificationEvent[]) => void;
	title?: string;
	loading?: boolean;
}

export const NotificationEventTypes = ({
	preferences,
	handleSubmit,
	title,
	loading,
}: Props) => {
	const { t: translate } = useTranslation();
	const featureTranslationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;
	const commonTranslationsScope = translations.app.commons;
	const fieldDefinitions: FieldDefinition[] = useNotificationEventTypeSchema({
		preferences,
	});
	const { initialValues } = formSchemaGenerator(fieldDefinitions);
	const hideSwitchButton = useCallback(
		(key, eventType) => {
			return (
				key === NotificationEventStatus.IsDailySummaryEnabled &&
				eventType !== NotificationEventType.OfferExpiring
			);
		},
		[preferences],
	);

	const createSwitchColumn = (title: string, key: NotificationEventStatus) => {
		return {
			title,
			dataIndex: key,
			key: key,
			width: TOGGLE_WIDTH,
			render: (_, record) => (
				<GenericTable.Column>
					<Field name={`${record.eventType}.${key}`}>
						{({ field, form }) =>
							!hideSwitchButton(key, record.eventType) && (
								<Switch
									checked={field.value}
									disabled={field.value === undefined}
									onChange={(checked) => {
										form.setFieldValue(`${record.eventType}.${key}`, checked);
									}}
								/>
							)
						}
					</Field>
				</GenericTable.Column>
			),
		};
	};
	const timerColumn = {
		dataIndex: NotificationEventStatus.ScheduleTime,
		key: NotificationEventStatus.ScheduleTime,
		width: 200,
		render: (_, record) =>
			record.scope === NotificationSettingsScope.tenant &&
			record.eventType === NotificationEventType.BidsSent && (
				<GenericTable.Column>
					<Field
						name={`${record.eventType}.${NotificationEventStatus.ScheduleTime}`}
					>
						{({ field, form }) => (
							<TimePicker
								format="hh:mm A"
								size="small"
								value={field.value}
								showNow={false}
								onChange={(value) => {
									form.setFieldValue(
										`${record.eventType}.${NotificationEventStatus.ScheduleTime}`,
										value,
									);
								}}
								suffixIcon={
									<Tag icon={<ClockCircleOutlined />}>
										{translate(commonTranslationsScope.CSTTimezone)}
									</Tag>
								}
							/>
						)}
					</Field>
				</GenericTable.Column>
			),
	};
	const inAppToggleColumn = createSwitchColumn(
		translate(featureTranslationsScope.Internal),
		NotificationEventStatus.IsInAppEnabled,
	);
	const emailToggleColumn = createSwitchColumn(
		translate(featureTranslationsScope.Email),
		NotificationEventStatus.IsEmailEnabled,
	);
	const smsToggleColumn = createSwitchColumn(
		translate(featureTranslationsScope.SMS),
		NotificationEventStatus.IsSmsEnabled,
	);
	const dailySummaryToggleColumn = createSwitchColumn(
		translate(featureTranslationsScope.DailySummary),
		NotificationEventStatus.IsDailySummaryEnabled,
	);

	const columns = [
		{
			title,
			key: 'eventType',
			render: (data) => {
				return (
					<GenericTable.Column>
						{translate(commonTranslationsScope[data.eventType])}
					</GenericTable.Column>
				);
			},
		},
		timerColumn,
		inAppToggleColumn,
		emailToggleColumn,
		smsToggleColumn,
		dailySummaryToggleColumn,
	];

	if (loading) {
		return <Spin data-testid="spinner-loading" key="spinner" />;
	}

	return preferences.length ? (
		<Formik
			initialValues={initialValues}
			onSubmit={(values) => handleSubmit(Object.values(values))}
		>
			{({ resetForm }) => {
				return (
					<Form>
						<GenericTable.Table
							otherClassName="events-table-container"
							rowKey="id"
							pagination={false}
							columns={columns}
							data={preferences}
						/>
						<FormFooterButtons
							cancelLabel={translate(commonTranslationsScope.cancelButton)}
							submitLabel={translate(commonTranslationsScope.submitButton)}
							isLoading={loading ?? false}
							closeModal={resetForm}
						/>
					</Form>
				);
			}}
		</Formik>
	) : (
		<></>
	);
};
