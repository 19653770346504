import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions as globalActions } from '../../../../GlobalSaga/slice';
import { serviceFeesActions } from '../slice';

const { serviceFeeRoot } = apiEndpoints;

export function* deleteServiceFee(action: PayloadAction<any>) {
	const id = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${serviceFeeRoot}?id=${id}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Delete,
	);

	if (!!responseData) {
		yield put(serviceFeesActions.ServiceFeeDeleted());
		yield put(serviceFeesActions.loadServiceFeesList());
		yield put(globalActions.loadServiceFeeList());
	} else if (!!responseError.detail) {
		yield put(serviceFeesActions.deleteServiceFeeError(responseError));
	}
}
