import { Trade } from 'types/TradeBook';

import { ErpStatus } from './types';

export const sumProperty = (arr: Trade[], property: string) => {
	return arr?.reduce((sum, record) => sum + record[property], 0);
};

export const shouldApplyGrayClass = ({ erpStatus }) => {
	if (erpStatus === ErpStatus.Fail || erpStatus === ErpStatus.Pending) {
		return 'table-colum--gray';
	} else {
		return '';
	}
};
