import { useAbac } from 'react-abac';
import { useSelector } from 'react-redux';

import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { Permission } from 'types/Authorization';

import { menuItems, sectionsPermissions } from '../menuItems';

export const useMenuItems = () => {
	const { userHasPermissions } = useAbac();
	const flags = useSelector(selectFeatureFlags);

	const isSectionAllowed = (sectionName: string) =>
		userHasPermissions(sectionsPermissions[sectionName]) ||
		userHasPermissions(Permission.CONTRACTSERVICE_SETTINGS_ADMIN);

	for (let i = 0; i < menuItems.length; i++) {
		if (
			!userHasPermissions(menuItems[i]?.permission) &&
			menuItems[i]?.permission
		) {
			// remove the ith item from the array
			menuItems.splice(i, 1);
			i--;
		}
	}

	// Map through the menuItems and update the component based on individual permissions
	const updatedMenuItems = menuItems.map((item) => {
		if (item.legacyComponent && item.featureFlag && !flags[item.featureFlag]) {
			return {
				...item,
				component: item.legacyComponent, // Use the legacy component
			};
		}
		return item;
	});

	// Filter out the menu items that are not allowed based on permissions and feature flags
	const allowedMenuItems = updatedMenuItems.filter((item) => {
		// For items with legacyComponent, no need to check featureFlag, always include them
		if (item.legacyComponent) {
			return isSectionAllowed(item.name);
		}

		// For other items, check featureFlag
		return (
			isSectionAllowed(item.name) &&
			(item.featureFlag ? flags[item.featureFlag] : true)
		);
	});
	const defaultMenuItem = allowedMenuItems[0]?.name;

	return {
		allowedMenuItems,
		defaultMenuItem,
	};
};
