import { Avatar, Tooltip } from 'antd';
import React from 'react';

import { AppSmallIcon } from '../CustomIcon';

interface Props {
	user:
		| { firstName: string | null; lastName: string | null }
		| { name: string | null }
		| 'internal'
		| null
		| undefined;
	noTooltip?: boolean;
	testId?: string;
}

export function UserAvatar({ user, noTooltip, testId }: Props) {
	if (user == null) return null;
	if (user === 'internal') {
		return noTooltip ? (
			<AppSmallIcon data-testid={testId} />
		) : (
			<Tooltip title="Updated by Hrvyst">
				<AppSmallIcon data-testid={testId} />
			</Tooltip>
		);
	}

	let initials = '';
	let tooltipContent = '';
	if ('firstName' in user && 'lastName' in user) {
		initials = `${user.firstName?.charAt(0) ?? ''}${user.lastName?.charAt(0) ?? ''}`;
		tooltipContent = `${user.firstName} ${user.lastName}`;
	} else {
		initials =
			user.name
				?.split(' ')
				.map((substring) => substring.charAt(0) ?? '')
				.join('')
				.substring(0, 2) ?? '';
		tooltipContent = user.name ?? '';
	}

	const avatar = (
		<Avatar style={{ color: 'black' }} data-testid={testId}>
			{initials}
		</Avatar>
	);
	if (noTooltip) {
		return avatar;
	} else {
		return <Tooltip title={tooltipContent}>{avatar}</Tooltip>;
	}
}
