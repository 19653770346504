import { useDispatch, useSelector } from 'react-redux';

import {
	selectCustomerGroupDetails,
	selectCustomerGroupDetailsLoading,
	selectCustomerGroupMembers,
	selectCustomerGroupMembersLoading,
	selectCustomerGroupNonMembers,
	selectCustomerGroupNonMembersLoading,
} from '../selectors';
import { actions } from '../slice';
import { NotificationGroupEditHookValue } from '../types';

export const useCustomerGroupEdit = (): NotificationGroupEditHookValue => {
	const dispatch = useDispatch();
	const groupDetails = useSelector(selectCustomerGroupDetails);
	const groupDetailsLoading = useSelector(selectCustomerGroupDetailsLoading);
	const memberList = useSelector(selectCustomerGroupMembers);
	const memberListLoading = useSelector(selectCustomerGroupMembersLoading);
	const nonMemberList = useSelector(selectCustomerGroupNonMembers);
	const nonMemberListLoading = useSelector(
		selectCustomerGroupNonMembersLoading,
	);

	const onClose = () => dispatch(actions.clearCustomerGroupState());

	const onSubmit = ({ groupName, groupMembers }) => {
		dispatch(
			actions.updateCustomerGroup({
				id: groupDetails?.id,
				name: groupName,
				members: groupMembers,
			}),
		);
	};

	return {
		onClose,
		groupDetails,
		nonMemberList,
		memberList,
		onSubmit,
		loading: groupDetailsLoading || memberListLoading || nonMemberListLoading,
	};
};
