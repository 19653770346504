import './styles.scss';

import { Card } from 'antd';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { GenericReport } from 'app/containers/GenericReport';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';

export const Reports = memo(function Reports() {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			globalActions.setPageTitle(
				translate(translations.app.components.AppLayout.reports),
			),
		);
	}, []);

	return (
		<>
			<div className="reports">
				<Card>
					<div className="generic-report table-wrapper">
						<GenericReport mode="form" />
					</div>
				</Card>
			</div>
		</>
	);
});
