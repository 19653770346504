import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import React, { memo, useEffect, useState } from 'react';
import { useAbac } from 'react-abac';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import {
	FormButtonsProps,
	FormSchemaDefinition,
} from 'app/components/GenericForm/types';
import {
	selectActiveContractError,
	selectActiveContractLoading,
	setCashbidsState,
} from 'app/containers/Contracts/selectors';
import { actions } from 'app/containers/Contracts/slice';
import { TransactionTypesContracts } from 'app/containers/Contracts/types';
import { selectOrderEntry } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { selectContractStateFromPreHedge } from 'app/containers/PreHedge/selectors';
import { actions as preHedgeActions } from 'app/containers/PreHedge/slice';
import { SourceContext } from 'app/containers/Transactions';
import { ModalHeader } from 'app/containers/Transactions/components/ModalHeader';
import { useDefaultValues } from 'app/containers/Transactions/hooks/useDefaultValues';
import { Permission } from 'types/Authorization';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import {
	CONSTANTS,
	CONTRACT_TYPE_VALUES,
	CONTRACT_TYPES,
	CONTRACT_TYPES_GUIDS,
	DATE_FORMAT,
} from 'utils/constants';
import {
	customFormat,
	getFormattedExpirationDate,
	isActionSell,
	isDeliveryDateCustom,
} from 'utils/helpers';
import { usePriceControl } from 'utils/hooks/usePriceControl';
import { getOrderEntriesFormValues } from 'utils/order-entry-helpers';
import { isEmptyObject } from 'utils/validators';

import { useTranslations } from '../shared/useTranslations';
import { useContractSchemas } from './schemas';

export const MIN_QUANTITY = 0.01;
export const CreateModal = memo(function CreateModal() {
	const translations = useTranslations();
	const dispatch = useDispatch();

	const { userHasPermissions } = useAbac();

	const [closeAfterSubmit, setCloseAfterSubmit] = useState(true);

	const orderEntries = useSelector(selectOrderEntry);
	const isLoading = useSelector(selectActiveContractLoading);
	const cashbidsState = useSelector(setCashbidsState);
	const contractStateFromPreHedge = useSelector(
		selectContractStateFromPreHedge,
	);
	const activeContractError = useSelector(selectActiveContractError);

	const defaultInitialValues = useDefaultValues(ContractTypeSource.CONTRACT);

	const { transaction, contract } = defaultInitialValues;

	const contractSchemas = useContractSchemas();
	const defaultSchema = contractSchemas[transaction.label];
	const [currentSchema, setCurrentSchema] = useState<FormSchemaDefinition>(
		defaultSchema[contract?.label] || defaultSchema['NTC'],
	);
	const [currentContract, setCurrentContract] = useState<string>('HTA');
	const traditionalContracts = ['HTA', 'Basis', 'Flat Price'];

	const resolver = yupResolver(currentSchema.validationSchema);

	const getInitialValues = (cashbidsState, contractStateFromPreHedge) => {
		if (
			isEmptyObject(cashbidsState) &&
			isEmptyObject(contractStateFromPreHedge)
		) {
			return defaultInitialValues;
		} else if (isEmptyObject(cashbidsState)) {
			return contractStateFromPreHedge;
		} else {
			return cashbidsState;
		}
	};

	const formInstance = useForm({
		defaultValues: {
			...currentSchema.initialValues,
			...getInitialValues(cashbidsState, contractStateFromPreHedge),
		},
		mode: 'all',
		resolver,
	});

	const {
		watch,
		getValues,
		setValue,
		formState: { errors },
		setError,
		clearErrors,
	} = formInstance;

	const validatePriceControl = usePriceControl(
		watch('futuresPrice'),
		setError,
		clearErrors,
	);

	const currentTransactionType = watch('transaction')?.value;
	const currentContractType = watch('contract')?.value;

	// defining an empty function to be handle scope chaining
	let updateformState = () => {};

	const resetSchemaDependencyArr = !isEmptyObject(contractStateFromPreHedge)
		? [currentContractType]
		: [currentTransactionType, currentContractType];

	useEffect(() => {
		if (activeContractError && !isEmptyObject(contractStateFromPreHedge)) {
			const quantity = getValues('quantity');
			const currentMax = getValues('max');
			if (quantity && currentMax)
				setValue(
					'max',
					(
						parseFloat(currentMax) +
						parseFloat(
							customFormat(quantity, false, CONSTANTS.FIXED_QUANTITY_DECIMALS),
						)
					).toFixed(2),
				);
		}
	}, [activeContractError]);

	useEffect(() => {
		let newSchema;

		const { transaction, contract, contractNumber, theirContract } =
			formInstance.watch();
		const { isDirty } = formInstance.formState;

		const getSchema = (cashbidsState, contractStateFromPreHedge) => {
			const state = isEmptyObject(contractStateFromPreHedge)
				? cashbidsState
				: contractStateFromPreHedge;
			const contract = state?.contract;
			const contractType = contract?.label;
			const schema = contractSchemas[transaction.label][contractType];
			return schema;
		};
		if (
			!isEmptyObject(cashbidsState) ||
			!isEmptyObject(contractStateFromPreHedge)
		)
			setCurrentSchema(getSchema(cashbidsState, contractStateFromPreHedge));

		if (!transaction?.label || !contract?.label || !isDirty) return;

		setCurrentContract(contract.label);

		/*
		 * Bugfix
		 * Task No 2982
		 * DETAILS: (Found a bug, when NTC is selected first and then the adjustment is selected, it loads a blank screen)
		 *
		 * Apporach Explain: When Transaction type is other than Cash Contract I render defalut schema which is HTA
		 */
		const retainDependencies = {
			transaction,
			contractNumber,
			theirContract,
			contract,
		};

		if (transaction?.label === TransactionTypesContracts.CashContract) {
			newSchema =
				contractSchemas[transaction.label][contract.label] ||
				contractSchemas[transaction.label][CONTRACT_TYPES.ntc];
		} else if (transaction?.label === TransactionTypesContracts.BushelsOnly) {
			newSchema = contractSchemas[transaction.label][CONTRACT_TYPES.ntc];
		} else {
			newSchema =
				contractSchemas[transaction.label][contract.label] ||
				contractSchemas[transaction.label][CONTRACT_TYPES.hta];
		}
		formInstance.reset({
			...retainDependencies,
			...newSchema?.initialValues,
		});
		cleanState();
		setCurrentSchema(newSchema);

		updateformState = () => {
			cleanState();
			const { contract, ...rest } = retainDependencies;
			formInstance.reset({
				...rest,
				...newSchema?.initialValues,
			});
		};

		return () => {
			cleanState();
		};
	}, resetSchemaDependencyArr);

	// update form state when contract type changes, so that it persists the values given by user without resetting other dependencies
	useEffect(() => {
		updateformState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTransactionType]);

	/**
	 * The option for manually selecting the notification groups is disabled for now.
	 * We use a system group for Basis Push notifications.
	 * If we need to enable the feature for manual group selection, we can use the following code.
	 */
	// useGroupsFormSchema(currentSchema, formInstance, []);

	const sourceName = GlobalSagaSource.contractModal;
	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		cleanState();
	};

	const cleanState = () => {
		dispatch(globalActions.clearCachedFuturesPrice());
		dispatch(preHedgeActions.clearContractStateFromPreHedge());
		dispatch(preHedgeActions.clearActivePreHedge());
		dispatch(actions.clearCashbidsState());
		dispatch(globalActions.clearFuturesPrices(sourceName));
		dispatch(globalActions.clearPostedBasisPrice(sourceName));
		dispatch(globalActions.clearFuturesMonth(sourceName));
		dispatch(globalActions.clearDeliveryDates(sourceName));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'order-form__new-order',
			type: 'default',
			htmlType: 'submit',
			children: translations.buttons.submitAndNewOrder,
			onClick: () => {
				setCloseAfterSubmit(false);
			},
			disabled: isLoading,
			debounceReenable: 1000,
			key: 'submit',
			dataTestId: 'create-modal-btn',
		},
		{
			htmlType: 'submit',
			children: translations.buttons.submitOrder,
			disabled: isLoading,
			debounceReenable: 1000,
			key: 'new-order',
			dataTestId: 'create-new-modal-btn',
		},
	];

	const header = (
		<ModalHeader
			title={translations.actions.newOrderTitle}
			content={
				translations.actions.createConfirmationMessage +
				' ' +
				translations.actions.confirmation
			}
			confirmText={translations.actions.confirmText}
			cancelText={translations.actions.cancelText}
			handleConfirm={handleClose}
		/>
	);

	const getExtendedContractTypeId = (contract) => {
		if (!CONTRACT_TYPES_GUIDS[contract?.value?.toUpperCase()]) {
			return contract?.value;
		} else {
			return undefined;
		}
	};
	const handleSubmit = (values) => {
		const label =
			CONTRACT_TYPES_GUIDS[values.contract?.value?.toUpperCase()] || 'NTC';

		if (label === CONTRACT_TYPES.hta || label === CONTRACT_TYPES.ntc) {
			validatePriceControl();
			if (!isEmptyObject(errors)) return;
		}

		let data: any = {
			theirContract: values.theirContract,
			transactionTypeId: values.transaction.value,
			number: values.contractNumber,
			contractTypeId: traditionalContracts.includes(currentContract)
				? values.contract.value || values.contract.type
				: CONTRACT_TYPE_VALUES.ntc,
			extendedContractTypeId: getExtendedContractTypeId(values.contract),
			isSell: isActionSell(values.action),
			commodityId: values.commodity.value,
			locationId: values.location.value,
			deliveryLocationId:
				values.deliveryLocation.defaultDestinationLocationId ||
				values.deliveryLocation.value,
			cropYear: values.cropYear,
			isDeliveryDatesCustom: isDeliveryDateCustom(values.deliveryDatesMode),
			deliveryStartDate: values.deliveryDate?.[0]?.format(DATE_FORMAT),
			deliveryEndDate: values.deliveryDate?.[1]?.format(DATE_FORMAT),
			freightPrice: values.freight ? parseFloat(values.freight) : 0,
			fees1: values.fees1 ? parseFloat(values.fees1) : 0,
			fees2: values.fees2 ? parseFloat(values.fees2) : 0,
			quantity: parseFloat(
				customFormat(values.quantity, false, CONSTANTS.FIXED_QUANTITY_DECIMALS),
			),
			customerId: values.customer.value,
			employeeId: values.employee.value,
			comments: values.comments,
			futuresMonth: values.futuresMonth?.value,
			customFields: getOrderEntriesFormValues(orderEntries, values),
			groups: values.groups?.map((group) => group.value),
			regionId: values?.assignedRegion?.value,
		};

		if (label === CONTRACT_TYPES.flatPrice) {
			data = {
				...data,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				price: values.flatPrice ? parseFloat(values.flatPrice) : 0,
				passFill: values.passFill,
				doNotHedge: values.doNotHedge,
			};
		} else if (label === CONTRACT_TYPES.basis) {
			data = {
				...data,
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				price: values.netBasisPrice ? parseFloat(values.netBasisPrice) : 0,
				expirationDate: getFormattedExpirationDate(values),
			};
		} else if (label === CONTRACT_TYPES.hta) {
			data = {
				...data,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
				price: values.netFutures ? parseFloat(values.netFutures) : 0,
				passFill: values.passFill,
				doNotHedge: values.doNotHedge,
				expirationDate: getFormattedExpirationDate(values),
			};
		} else if (label === CONTRACT_TYPES.ntc) {
			data = {
				...data,
				expirationDate: getFormattedExpirationDate(values),
				postedBasis: values.postedBasis ? parseFloat(values.postedBasis) : 0,
				pushBasis: values.pushBasis ? parseFloat(values.pushBasis) : 0,
				netBasis: values.netBasis ? parseFloat(values.netBasis) : 0,
				futuresPrice: values.futuresPrice ? parseFloat(values.futuresPrice) : 0,
			};
		}

		if (!isEmptyObject(contractStateFromPreHedge)) {
			data = {
				...data,
				prehedgeId: contractStateFromPreHedge?.prehedgeId,
			};
			const currentMax = getValues('max');
			if (currentMax) setValue('max', (currentMax - data.quantity).toFixed(2));
		}

		batch(() => {
			if (closeAfterSubmit) {
				cleanState();
			}
			dispatch(
				actions.createContract({
					data,
					closeAfterSubmit: closeAfterSubmit,
					successMessage: translations.common.success,
					loadReviewAndRelease: userHasPermissions(
						Permission.CONTRACTSERVICE_REVIEWANDRELEASE_VIEW,
					),
				}),
			);
			setCloseAfterSubmit(true);
		});
	};
	return (
		<GenericForm.ModalContainer
			key="createContractModal"
			title={header}
			closable={false}
		>
			<SourceContext.Provider value={sourceName}>
				<GenericForm.Form
					className="order-form"
					key="createContractForm"
					formInstance={formInstance}
					validationSchema={currentSchema?.validationSchema}
					onSubmit={handleSubmit}
					buttonsDefinition={btnsDefinition}
				>
					{currentSchema?.elements}
				</GenericForm.Form>
			</SourceContext.Provider>
		</GenericForm.ModalContainer>
	);
});
