import { TransactionTypesContracts } from 'app/containers/Contracts/types';

import { useTranslations } from '../../shared/useTranslations';
import { useAdjustmentsSchema } from './Adjustments';
import { useBushelsOnlySchema } from './BushelsOnly';
import { useCashContractSchema } from './CashContract';
import { useSalesTradingSchema } from './SalesTrading';

export const useContractSchemas = () => {
	const translations = useTranslations();
	return {
		[TransactionTypesContracts.Adjustment]: useAdjustmentsSchema(translations),
		[TransactionTypesContracts.CashContract]:
			useCashContractSchema(translations),
		[TransactionTypesContracts.SalesTrading]:
			useSalesTradingSchema(translations),
		[TransactionTypesContracts.BushelsOnly]: useBushelsOnlySchema(translations),
	};
};
