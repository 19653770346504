import { useDispatch, useSelector } from 'react-redux';

import { selectActiveEmployeeGroup } from '../selectors';
import { actions } from '../slice';
import { NotificationGroupDeleteHookValue } from '../types';

export const useEmployeeGroupDelete = (): NotificationGroupDeleteHookValue => {
	const dispatch = useDispatch();
	const activeGroup = useSelector(selectActiveEmployeeGroup);

	const onClose = () => dispatch(actions.clearEmployeeGroupState());

	const onSubmit = () => {
		activeGroup && dispatch(actions.deleteEmployeeGroup(activeGroup?.id));
		dispatch(actions.setCurrentModal(null));
		onClose();
	};

	return {
		onClose,
		activeGroup,
		onSubmit,
	};
};
