import { GenericError } from 'types/GenericError';
import { GenericOption } from 'types/GenericOption';
import { Trade, TradeBook } from 'types/TradeBook';

export interface TradeBookFilters {
	commodityId: { value: string; label: string }[];
	futuresMonth: { value: string; label: string }[];
	accountNo: { value: string; label: string }[];
	orderNo?: string | null;
}

/* --- STATE --- */
export interface TradeBookState {
	selectedBuyRows: Trade[];

	selectedSellRows: Trade[];

	selectedFilters: TradeBookFilters;

	tradeBookList: {
		data: TradeBook;
		loading: boolean;
		total: number;
		erpErrorMessage: string | null;
		error: GenericError | null;
	};
	integrateToErp: {
		loading: boolean;
		error: GenericError | null;
	};
	futureMonths: {
		data: GenericOption[];
		loading: boolean;
		error: GenericError | null;
	};
}

export interface IntegrateToErpDataType {
	orderFillId: string;
	allocatedQuantity: number;
}

export enum ErpStatus {
	Fail = 'Fail',
	Success = 'Success',
	Pending = 'Pending',
}
