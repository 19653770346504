import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useERPSetupFormSchema } from '../schemas/useERPSetupFormSchema';
import {
	selectERPIntegration,
	selectERPIntegrationLoading,
	selectERPTypesLoading,
} from '../selectors';

export const useERPSetupFormData = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const { elements, initialValues, validationSchema } = useERPSetupFormSchema();
	const resolver = yupResolver(validationSchema);
	const formInstance = useForm({
		defaultValues: {
			...initialValues,
		},
		mode: 'all',
		resolver,
	});
	const erpIntegration = useSelector(selectERPIntegration);
	const isLoading = useSelector(selectERPIntegrationLoading);
	const isLoadingERPTypes = useSelector(selectERPTypesLoading);

	return {
		dispatch,
		elements,
		erpIntegration,
		formInstance,
		initialValues,
		isLoading,
		isLoadingERPTypes,
		translate,
		validationSchema,
	};
};
