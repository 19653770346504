import React, { useMemo } from 'react';
import { useAbac } from 'react-abac';
import { useSelector } from 'react-redux';

import { compareNumbers } from 'app/containers/Contracts/utils/helper';
import { selectDefaultPageData } from 'app/containers/GlobalSaga/selectors';
import {
	selectActivePreHedge,
	selectContractStateFromPreHedge,
} from 'app/containers/PreHedge/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { Customer } from 'app/containers/Transactions/components/Customer';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { FlatPrice } from 'app/containers/Transactions/components/FlatPrice';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { PassFill } from 'app/containers/Transactions/components/PassFill';
import { Quantity } from 'app/containers/Transactions/components/Quantity';
import { TheirContract } from 'app/containers/Transactions/components/TheirContract';
import { Transaction } from 'app/containers/Transactions/components/Transaction';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { ActionType } from 'types/ActionType';
import { Permission } from 'types/Authorization';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import { CONSTANTS } from 'utils/constants';
import { isDeliveryDateCustom, isDeliveryDateWindow } from 'utils/helpers';
import { isEmptyObject } from 'utils/validators';
import Yup from 'yupCustom';

import { validateMinQuantity, validatePrice } from '../../../shared/helpers';
import { useTranslations } from '../../../shared/useTranslations';

export const useFlatPriceSchema = (
	translations: ReturnType<typeof useTranslations>,
	isAdjustment = false,
) => {
	const defaultData = useSelector(selectDefaultPageData);
	const contractStateFromPreHedge = useSelector(
		selectContractStateFromPreHedge,
	);
	const preHedgeData = useSelector(selectActivePreHedge);

	const shouldDisabled = !isEmptyObject(contractStateFromPreHedge);

	const { elements, initialValues, validationSchema } =
		useOrderEntriesFormSchema();

	const { userHasPermissions } = useAbac();

	const futuresDisabled = !userHasPermissions(
		Permission.CONTRACTSERVICE_CONTRACT_ENABLE_FUTURES,
	);

	const quantityErrorMsg =
		translations.validations.createContractMinQuantityValidation;
	const numberMsg = translations.validations.number;
	const requiredMsg = translations.validations.required;
	const maxLength10Msg = translations.validations.maxLength10;
	const basisFormatError = translations.validations.basisFormatError;

	const validation = useMemo(
		() =>
			Yup.object().shape({
				theirContract: Yup.string().nullable().max(10, maxLength10Msg),
				transaction: Yup.object().requiredOption(requiredMsg),
				contractNumber: Yup.string().nullable(),
				contract: Yup.object().requiredOption(requiredMsg),
				commodity: Yup.object().requiredOption(requiredMsg),
				location: Yup.object().requiredOption(requiredMsg),
				deliveryLocation: Yup.object().requiredOption(requiredMsg),
				assignedRegion: Yup.object().requiredOption(requiredMsg),
				// when empty, cropYear fails with a NaN type error, so we use requiredMessage for typeError
				cropYear: Yup.number().typeError(requiredMsg).required(requiredMsg),
				deliveryDate: Yup.mixed().when('deliveryDatesMode', {
					is: (mode) => isDeliveryDateCustom(mode),
					then: Yup.mixed().required(requiredMsg),
				}),
				deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
					is: (mode) => isDeliveryDateWindow(mode),
					then: Yup.object().requiredOption(requiredMsg),
				}),
				freight: Yup.number().typeError(numberMsg).nullable(),
				fees: Yup.number().typeError(numberMsg),
				flatPrice: Yup.number().typeError(numberMsg).required(requiredMsg),
				quantity: Yup.string()
					.typeError(numberMsg)
					.required(requiredMsg)
					.test('minValidation', quantityErrorMsg, (value: string) =>
						validateMinQuantity(value),
					)
					.test(
						'maxValidation',
						'Quantity must be lower or equal to available Max balance',
						(value) => {
							return compareNumbers(
								value,
								preHedgeData?.max,
								CONSTANTS.FIXED_QUANTITY_DECIMALS,
							);
						},
					),
				customer: Yup.object().requiredOption(requiredMsg),
				employee: Yup.object().requiredOption(requiredMsg),
				pushBasis: Yup.number().typeError(numberMsg).nullable(),
				postedBasis: Yup.number()
					.typeError(requiredMsg)
					.required(requiredMsg)
					.test('priceValidation', basisFormatError, (value: string) =>
						validatePrice(value),
					),
				futuresMonth: Yup.object().requiredOption(requiredMsg),
				futuresPrice: Yup.number().typeError(requiredMsg).required(requiredMsg),
				...validationSchema,
			}),
		[
			validationSchema,
			quantityErrorMsg,
			numberMsg,
			requiredMsg,
			maxLength10Msg,
			basisFormatError,
			preHedgeData?.max,
		],
	);

	return useMemo(
		() => ({
			initialValues: {
				action: ActionType.BUY,
				commodity: { value: null },
				location: { value: null },
				deliveryLocation: { value: null },
				assignedRegion: {
					value: preHedgeData?.regionId,
					label: preHedgeData?.regionName,
				},
				deliveryDate: null,
				deliveryDatesMode: { value: DeliveryDateMode.Window },
				deliveryDateWindow: { value: null },
				cropYear: null,
				futuresMonth: { value: null },
				futuresPrice: null,
				postedBasis: null,
				pushBasis: null,
				netBasis: null,
				freight: null,
				fees1: null,
				fees2: null,
				flatPrice: null,
				netFutures: null,
				netBasisPrice: null,
				quantity: null,
				gtcMode: false,
				expirationDate: '',
				customer: { value: null },
				employee: {
					label: defaultData?.employeeName,
					value: defaultData?.employeeId,
				},
				comments: '',
				basis: null,
				futures: null,
				passFill: false,
				doNotHedge: false,
				useRoundingRules: false,
				useServiceFees: false,
				max: preHedgeData?.max,
				...initialValues,
			},
			validationSchema: validation,
			elements: (
				<>
					<TheirContract />
					<Transaction />
					<ContractNumber />
					<ContractType
						disableBuySell={shouldDisabled}
						disabled={shouldDisabled}
						ShowNTC={!isAdjustment}
					/>
					<Commodity disabled={shouldDisabled} checkDefaultValues />
					<Location />
					<DeliveryLocation />
					<AssignedRegion />
					<CropYears
						checkDefaultValues={!preHedgeData}
						disabled={shouldDisabled}
					/>
					<DeliveryDates
						checkDefaultValues
						useServiceFees
						resetFuturePriceDependencies
						resetFutureDependencies={!shouldDisabled}
					/>
					<Quantity showMax={shouldDisabled} maxQuantity={preHedgeData?.max} />
					<PassFill hedgeable />
					<Futures
						checkDefaultValues
						futuresMonth={{
							market: true,
							disableWithinDeliveryMode: true,
							disabled: shouldDisabled,
						}}
						futuresPrice={{
							market: preHedgeData ? false : true,
							disabled: futuresDisabled || shouldDisabled,
						}}
						useRoundingRules
					/>
					<Basis disabled={{ netBasis: true }} datesModeDependency />
					<Freight />
					<Fees />
					<FlatPrice />
					<Customer isAdjustment={isAdjustment} />
					<Employee />
					{elements}
					<Comments />
				</>
			),
		}),
		[
			isAdjustment,
			defaultData,
			preHedgeData,
			shouldDisabled,
			elements,
			initialValues,
			futuresDisabled,
			validation,
		],
	);
};
