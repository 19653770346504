import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericModalForm } from 'app/components/GenericModalForm';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';
import { translations } from 'locales/i18n';
import { FieldDefinition } from 'types/FieldDefinition';

import { useFieldFactory } from '../../../../definitions/fields';
import { Tag50Controller } from '../../components/Tag50Controller';
import {
	selectActiveEmployeeError,
	selectActiveEmployeeLoading,
} from '../../selectors';
import { actions } from '../../slice';
import { useEmployeeHook } from './useEmployeeHook';

export const CreateEmployee = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(actions.resetActiveEmployee());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const activeEmployeeLoading = useSelector(selectActiveEmployeeLoading);
	const fieldList = useFieldFactory();
	const genericTranslations = UseFieldTranslations();

	const domain =
		translations.app.containers.Settings.sections.Employees.Modals.Create;

	const createEmployeeSchema: FieldDefinition[] = [
		fieldList.erpNumber,
		fieldList.name,
		fieldList.role,
		fieldList.cellphone,
		{
			...fieldList.email,
			validation: fieldList.email.validation.required(
				genericTranslations.validationSchema.required,
			),
		},
		{
			name: 'Tag50Account',
			render: () => <Tag50Controller />,
			initialValue: '',
			validation: Yup.string().when('tag50toggle', {
				is: true,
				then: Yup.string().required(
					genericTranslations.validationSchema.required,
				),
			}),
		},
	];

	const hookParams = {
		useErpNumberErrorMessage: {
			field: fieldList.erpNumber.name,
			errorMessageERP: translate(domain.erpNumberRepeatedMessage),
			errorSelector: selectActiveEmployeeError,
			defaultErrorMessage: translate(domain.toastErrorCreateMessage),
		},
	};

	const handleSubmit = (values) => {
		const fullNameCreated = `${values.firstName} ${values.lastName}`;

		dispatch(
			actions.createEmployee({
				data: {
					firstName: values.firstName,
					lastName: values.lastName,
					number: values.erpNumber,
					phoneNumber: values.cellphone,
					email: values.email,
					role: values.role.value,
					tag50: values.tag50toggle,
					tag50Account: values.tag50toggle ? values.Tag50Account : null,
				},
				successMessage: translate(domain.toastSuccessCreateMessage, {
					fullNameCreated,
				}),
			}),
		);
	};

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	return (
		<>
			<GenericModalForm
				title={translate(domain.createEmployeeModalTitle)}
				formDefinition={createEmployeeSchema}
				handleSubmit={handleSubmit}
				handleClose={handleClose}
				footerButtons={
					<FormFooterButtons
						isLoading={activeEmployeeLoading}
						cancelLabel={translate(domain.cancelEmployeeModalButton)}
						submitLabel={translate(domain.addEmployeeModalButton)}
						closeModal={handleClose}
					/>
				}
				hookSchema={useEmployeeHook}
				hookParams={hookParams}
			/>
		</>
	);
};
