import { PayloadAction } from '@reduxjs/toolkit';

import { FileResponse } from 'types/FileResponse';
import { FutureMonth } from 'types/FutureMonth';
import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
	FIXED_COLUMNS_FOR_REVIEW_AND_RELEASE,
	SELECTED_COLUMNS_FOR_REVIEW_AND_RELEASE,
} from 'utils/constants';
import {
	dateFormatYearMonthDay,
	GetPersistedData,
	mapToLabelValue,
} from 'utils/helpers';

import {
	ContainerState,
	ExportExcelPayload,
	Modal,
	ReviewAndReleaseAction,
	ReviewAndReleaseFilters,
	ReviewAndReleaseStatusUpdate,
	ReviewAndReleaseViews,
} from './types';

// The initial state of the ReviewAndRelease container
export const initialState: ContainerState = {
	acknowledgeAndFill: {
		data: null,
		error: null,
		loading: false,
	},

	acknowledgeAndFillUpdate: {
		data: null,
		error: null,
		loading: false,
	},

	exportReviewAndRelease: {
		error: null,
		fileName: '',
		loading: false,
		url: null,
	},

	activeOrderDetails: {
		data: null,
		error: null,
		loading: false,
	},

	currentModal: null,

	currentView: ReviewAndReleaseViews.table,

	externalFill: {
		error: null,
		data: [],
		loading: false,
	},

	list: {
		allLoaded: false,
		data: [],
		error: null,
		loading: false,
		total: 0,
	},

	pagination: {
		limit: 50,
		start: 1,
	},

	selectedFilters: {
		accountName: null,
		customerId: [],
		futuresMonth: [],
		number: null,
		startDate: null,
		endDate: null,
		status: [],
		sortColumnName: null,
		sortOrder: 0,
		symbol: [],
	},

	selectedRows: [],

	futureMonths: {
		data: [],
		error: null,
		loading: false,
	},

	selectedColumns: GetPersistedData(SELECTED_COLUMNS_FOR_REVIEW_AND_RELEASE, {
		created: true,
		updateDate: true,
		status: true,
		account: true,
		orderNumber: true,
		sellBuy: true,
		source: true,
		symbol: true,
		futuresMonth: true,
		type: true,
		gtc: true,
		price: true,
		avgFillPrice: true,
		quantity: true,
		workingQty: true,
		customer: true,
		hrvystId: true,
		dots: true,
	}),

	fixedColumns: GetPersistedData(FIXED_COLUMNS_FOR_REVIEW_AND_RELEASE, {
		created: 'left',
		updateDate: false,
		status: false,
		account: false,
		orderNumber: false,
		sellBuy: false,
		source: false,
		symbol: false,
		futuresMonth: false,
		type: false,
		gtc: false,
		price: false,
		avgFillPrice: false,
		quantity: false,
		workingQty: false,
		customer: false,
		hrvystId: false,
		dots: 'right',
	}),
};

const reviewAndReleaseSlice = createSlice({
	name: 'reviewAndRelease',
	initialState,
	reducers: {
		/**
		 *
		 * @param state
		 * @param action if payload = null, clears all filters to initialValues
		 */
		setSelectedFilters(
			state,
			action: PayloadAction<ReviewAndReleaseFilters | null>,
		) {
			if (!!action.payload) {
				state.selectedFilters = {
					...state.selectedFilters,
					...action.payload,
				};
			} else {
				state.selectedFilters = {
					status: [],
					startDate: dateFormatYearMonthDay(new Date()),
				};
			}
		},
		loadReviewAndReleaseList(state, action: PayloadAction<undefined>) {
			const reset = action.payload;

			if (reset) {
				state.pagination.start = 1;
				state.list.allLoaded = false;
				state.list.data = [];
			}

			state.list = {
				...state.list,
				data: state.list.data,
				loading: true,
				error: null,
			};
		},
		reviewAndReleaseListLoaded(state, action: PayloadAction<any>) {
			const { list, total } = action.payload;

			state.list = {
				...state.list,
				data: list,
				loading: false,
				error: null,
				total,
			};
			state.list.allLoaded = state.list.data.length === state.list.total;
		},
		reviewAndReleaseListError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
			state.list.allLoaded = state.list.data.length === state.list.total;
		},
		reviewAndReleaseMenuAction(
			state,
			action: PayloadAction<ReviewAndReleaseAction>,
		) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},
		reviewAndReleaseMenuActionSuccess(state, action) {
			state.list = {
				...state.list,
				loading: false,
			};
		},
		reviewAndReleaseMenuActionError(state, action) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},
		setPagination(state, action: PayloadAction<any>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},
		setSelectedRows(state, action: PayloadAction<any[]>) {
			state.selectedRows = action.payload;
		},
		loadOrderDetail(state, action: PayloadAction<{ id: string }>) {
			state.activeOrderDetails = {
				data: null,
				loading: true,
				error: null,
			};
		},
		orderDetailLoaded(state, action: PayloadAction<any>) {
			state.activeOrderDetails = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		orderDetailError(state, action: PayloadAction<any>) {
			state.activeOrderDetails = {
				...state.activeOrderDetails,
				loading: false,
				error: action.payload,
			};
		},
		setCurrentView(state, action: PayloadAction<ReviewAndReleaseViews>) {
			state.currentView = action.payload;
		},
		setStartPagination(state, action: PayloadAction<any>) {
			state.pagination = {
				...state.pagination,
				start: action.payload.start || state.pagination.start,
			};
		},
		setActiveOrderDetails(state, action: PayloadAction<any>) {
			state.activeOrderDetails.data = action.payload;
		},

		setCurrentModal(state, action: PayloadAction<Modal | null>) {
			state.currentModal = action.payload;
		},

		createExternalFill(state, action: PayloadAction<any>) {
			state.externalFill = {
				...state.externalFill,
				data: action.payload,
				loading: true,
				error: null,
			};
		},

		createdExternalFill(state, action: PayloadAction<any>) {
			state.externalFill = {
				...state.externalFill,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		externalFillError(state, action: PayloadAction<GenericError>) {
			state.externalFill = {
				...state.externalFill,
				loading: false,
				error: action.payload,
			};
		},

		getExternalFill(state, action: PayloadAction<any>) {
			state.externalFill = {
				...state.externalFill,
				loading: true,
			};
		},

		cancelExternalFill(state, action: PayloadAction<any>) {
			state.externalFill = {
				...state.externalFill,
				loading: true,
			};
		},
		cancelExternalFillError(state, action: PayloadAction<any>) {
			state.externalFill = {
				...state.externalFill,
				error: action.payload,
				loading: false,
			};
		},

		editExternalFill(state, action: PayloadAction<any>) {
			state.externalFill = {
				...state.externalFill,
				loading: true,
			};
		},
		editExternalFillLoaded(state, action: PayloadAction<any>) {
			state.externalFill = {
				...state.externalFill,
				data: action.payload,
				loading: false,
			};
		},
		editExternalFillError(state, action: PayloadAction<any>) {
			state.externalFill = {
				...state.externalFill,
				error: action.payload,
				loading: false,
			};
		},

		getAcknowledgeAndFillError(state, action) {
			state.acknowledgeAndFill = {
				...state.acknowledgeAndFill,
				error: action?.payload,
				loading: false,
			};
		},

		getAcknowledgeAndFillRequest(state, action) {
			state.acknowledgeAndFill = {
				...state.acknowledgeAndFill,
				data: null,
				error: null,
				loading: true,
			};
		},

		getAcknowledgeAndFillSuccess(state, action) {
			state.acknowledgeAndFill = {
				...state.acknowledgeAndFill,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		setAcknowledgeAndFillError(state, action) {
			state.acknowledgeAndFillUpdate = {
				...state.acknowledgeAndFillUpdate,
				error: action?.payload,
				loading: false,
			};
		},

		setAcknowledgeAndFillRequest(state, action) {
			state.acknowledgeAndFillUpdate = {
				...state.acknowledgeAndFillUpdate,
				data: null,
				error: null,
				loading: true,
			};
		},

		setAcknowledgeAndFillSuccess(state, action) {
			state.acknowledgeAndFillUpdate = {
				...state.acknowledgeAndFillUpdate,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		exportReviewAndRelease(state, action: PayloadAction<ExportExcelPayload>) {
			state.exportReviewAndRelease = {
				...state.exportReviewAndRelease,
				loading: true,
			};
		},
		exportedReviewAndRelease(state, action: PayloadAction<FileResponse>) {
			state.exportReviewAndRelease = {
				url: action.payload.url,
				fileName: action.payload.fileName,
				error: null,
				loading: false,
			};
		},
		exportReviewAndReleaseError(state, action: PayloadAction<any>) {
			state.exportReviewAndRelease = {
				...state.exportReviewAndRelease,
				error: action.payload,
				loading: false,
			};
		},

		setFixedColumns(state, action: PayloadAction<any>) {
			state.fixedColumns = {
				...state.fixedColumns,
				...action.payload,
			};
		},

		setSelectedColumns(state, action: PayloadAction<any>) {
			state.selectedColumns = {
				...state.selectedColumns,
				...action.payload,
			};
		},
		clearExportReviewAndRelease(state) {
			state.exportReviewAndRelease = {
				url: null,
				fileName: null,
				loading: false,
				error: null,
			};
		},

		getFuturesMonthsError(state, action) {
			state.futureMonths = {
				...state.futureMonths,
				error: action?.payload,
				loading: false,
			};
		},

		getFuturesMonthsRequest(state) {
			state.futureMonths = {
				...state.futureMonths,
				data: [],
				error: null,
				loading: true,
			};
		},

		getFuturesMonthsSuccess(state, action: PayloadAction<FutureMonth[]>) {
			state.futureMonths = {
				...state.futureMonths,
				data: mapToLabelValue(
					action?.payload.map((item) => ({
						...item,
						highlight: item.isExpired,
					})),
				),
				error: null,
				loading: false,
			};
		},

		reviewAndReleaseUpdated(
			state,
			action: PayloadAction<ReviewAndReleaseStatusUpdate>,
		) {
			state.list.data = state.list.data.map((item) => {
				if (item.id === action.payload.id) {
					const updatedItem = { ...item };

					for (const key in action.payload) {
						if (action.payload[key]) {
							updatedItem[key] = action.payload[key];
						}
					}
					return updatedItem;
				}
				return item;
			});
		},
	},
});

export const { actions, reducer, name: sliceKey } = reviewAndReleaseSlice;
