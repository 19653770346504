import './styles.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { TabsWrapper } from 'app/components/TabsWrapper';
import { translations } from 'locales/i18n';

import { useTabItems } from './hooks/useTabItems';
import { notificationSettingsSaga } from './saga';
import { reducer, sliceKey } from './slice';
import { NotificationSettingTabs } from './types';

export const NotificationSettings = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: notificationSettingsSaga });

	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;

	const tabs = useTabItems();

	const tabsTranslations = {
		[NotificationSettingTabs.personal]: translate(
			translationsScope.PersonalSettings,
		),
		[NotificationSettingTabs.employeeGroup]: translate(
			translationsScope.EmployeeGroupsSettings,
		),
		[NotificationSettingTabs.customerGroup]: translate(
			translationsScope.CustomerGroupsSettings,
		),
		[NotificationSettingTabs.tenant]: translate(
			translationsScope.TenantSettings,
		),
	};
	return (
		<TabsWrapper
			classname="notification-settings__tabs"
			tabs={tabs}
			translations={tabsTranslations}
			type="card"
			defaultActiveKey={NotificationSettingTabs.personal}
			destroyInactiveTabPane={true}
		/>
	);
};
