import { Button } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'app/containers/TradeBook/slice';
import { translations } from 'locales/i18n';
import { Trade } from 'types/TradeBook';

import { sumProperty } from '../../helper';
import { selectIntegrateToErpLoading } from '../../selectors';
import { IntegrateToErpDataType } from '../../types';

interface Props {
	buyDataSource: Trade[];
	sellDataSource: Trade[];
	setSellSelectionCount: Dispatch<SetStateAction<number>>;
	setBuySelectionCount: Dispatch<SetStateAction<number>>;
}

export const ButtonsDefination = ({
	setBuySelectionCount,
	setSellSelectionCount,
	buyDataSource,
	sellDataSource,
}: Props) => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const IsIntegrateToErpLoading = useSelector(selectIntegrateToErpLoading);
	const translationsScope = translations.app.containers.TradeBook;

	const totalBuyAllocation = sumProperty(buyDataSource, 'allocation');

	const totalSellAllocation = sumProperty(sellDataSource, 'allocation');

	const canBeIntegrated =
		totalBuyAllocation === totalSellAllocation && totalBuyAllocation !== 0;

	const handleCancel = () => {
		setBuySelectionCount(0);
		setSellSelectionCount(0);
		dispatch(actions.setSelectedBuyRowsAsEmpty());
		dispatch(actions.setSelectedSellRowsAsEmpty());
	};

	const handleSubmit = () => {
		const combinedData: IntegrateToErpDataType[] = [];

		buyDataSource.forEach((buyItem) => {
			if (buyItem.allocation > 0) {
				combinedData.push({
					orderFillId: buyItem.id,
					allocatedQuantity: buyItem.allocation,
				});
			}
		});

		sellDataSource.forEach((sellItem) => {
			if (sellItem.allocation > 0) {
				combinedData.push({
					orderFillId: sellItem.id,
					allocatedQuantity: sellItem.allocation,
				});
			}
		});

		dispatch(actions.integrateToErpRequest(combinedData));
	};
	return (
		<div className="tradebook-container__btns">
			<Button data-testid="cancel-button" type="ghost" onClick={handleCancel}>
				{translate(translationsScope.cancel)}
			</Button>
			<Button
				disabled={!canBeIntegrated || IsIntegrateToErpLoading}
				data-testid="submit-match-button"
				type="primary"
				onClick={handleSubmit}
			>
				{translate(translationsScope.submitMatch)}
			</Button>
		</div>
	);
};
