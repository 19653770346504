import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
	ERPIntegrationSectionState,
	hedgeIntegrationPayload,
	hedgeIntegrationResponse,
} from './types';

export const initialState: ERPIntegrationSectionState = {
	erpIntegration: {
		data: null,
		error: null,
		loading: false,
	},

	erpIntegrationUpdate: {
		data: null,
		error: null,
		loading: false,
	},

	erpTypes: {
		data: [],
		error: null,
		loading: false,
	},

	hedgeIntegration: {
		data: null,
		error: null,
		loading: false,
	},

	hedgeIntegrationUpdate: {
		data: null,
		error: null,
		loading: false,
	},
};

const erpIntegrationSlice = createSlice({
	name: 'erpIntegration',
	initialState,
	reducers: {
		getERPIntegrationError(state, action) {
			state.erpIntegration = {
				...state.erpIntegration,
				error: action?.payload,
				loading: false,
			};
		},

		getERPIntegrationRequest(state) {
			state.erpIntegration = {
				...state.erpIntegration,
				data: null,
				error: null,
				loading: true,
			};
		},

		getERPIntegrationSuccess(state, action) {
			state.erpIntegration = {
				...state.erpIntegration,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		getHedgeIntegrationError(state, action: PayloadAction<GenericError>) {
			state.hedgeIntegration = {
				...state.hedgeIntegration,
				error: action?.payload,
				loading: false,
			};
		},

		getHedgeIntegrationRequest(state) {
			state.hedgeIntegration = {
				...state.hedgeIntegration,
				data: null,
				error: null,
				loading: true,
			};
		},

		getHedgeIntegrationSuccess(
			state,
			action: PayloadAction<hedgeIntegrationResponse>,
		) {
			state.hedgeIntegration = {
				...state.hedgeIntegration,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		setHedgeIntegrationError(state, action: PayloadAction<GenericError>) {
			state.hedgeIntegrationUpdate = {
				...state.hedgeIntegrationUpdate,
				error: action?.payload,
				loading: false,
			};
		},

		setHedgeIntegrationRequest(
			state,
			action: PayloadAction<hedgeIntegrationPayload>,
		) {
			state.hedgeIntegrationUpdate = {
				...state.hedgeIntegrationUpdate,
				data: null,
				error: null,
				loading: true,
			};
		},

		setHedgeIntegrationSuccess(
			state,
			action: PayloadAction<hedgeIntegrationResponse>,
		) {
			state.hedgeIntegrationUpdate = {
				...state.hedgeIntegrationUpdate,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		setERPIntegrationError(state, action) {
			state.erpIntegrationUpdate = {
				...state.erpIntegrationUpdate,
				error: action?.payload,
				loading: false,
			};
		},

		setERPIntegrationRequest(state, action) {
			state.erpIntegrationUpdate = {
				...state.erpIntegrationUpdate,
				data: null,
				error: null,
				loading: true,
			};
		},

		setERPIntegrationSuccess(state, action) {
			state.erpIntegrationUpdate = {
				...state.erpIntegrationUpdate,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		getERPTypesError(state, action) {
			state.erpTypes = {
				...state.erpTypes,
				error: action?.payload,
				loading: false,
			};
		},

		getERPTypesRequest(state) {
			state.erpTypes = {
				...state.erpTypes,
				data: [],
				error: null,
				loading: true,
			};
		},

		getERPTypesSuccess(state, action) {
			state.erpTypes = {
				...state.erpTypes,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = erpIntegrationSlice;
