import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectAcknowledgeAnFill } from 'app/containers/ReviewAndRelease/selectors';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { HedgeAccount } from 'app/containers/Transactions/components/HedgeAccount';
import { Quantity } from 'app/containers/Transactions/components/Quantity';
import { translations } from 'locales/i18n';
import { CONSTANTS } from 'utils/constants';
import { customFormat, getActionType } from 'utils/helpers';
import Yup from 'yupCustom';

const domain =
	translations.app.containers.ReviewAndRelease.components.Views.List.Modals
		.AcknowledgeAndFill;

export const useAcknowledgeAndFillSchema = () => {
	const { t: translate } = useTranslation();
	const order = useSelector(selectAcknowledgeAnFill);
	const requiredValueMsg = translate(domain.requiredValue);

	return {
		initialValues: {
			contractNumber: order?.orderNumber,
			commodity: {
				label: order?.commodityName,
				value: order?.commodityId,
			},
			cropYear: order?.cropYear,
			action: getActionType(order?.isSell),
			quantity: customFormat(
				order?.quantity,
				true,
				CONSTANTS.FIXED_QUANTITY_DECIMALS,
			),
			futuresMonth: {
				label: order?.futuresMonth,
				value: order?.futuresMonth,
			},
			futuresPrice: null,
			hedgeAccount: {
				label: order?.hedgeAccount,
				value: order?.hedgeAccountId,
			},
			comments: order?.comments,
		},
		validationSchema: Yup.object().shape({
			commodity: Yup.object().requiredOption(requiredValueMsg),
			cropYear: Yup.string().required(requiredValueMsg),
			action: Yup.string().required(requiredValueMsg),
			quantity: Yup.string().required(requiredValueMsg),
			futuresMonth: Yup.object().requiredOption(requiredValueMsg),
			futuresPrice: Yup.number()
				.typeError(requiredValueMsg)
				.required(requiredValueMsg),
			hedgeAccount: Yup.object().requiredOption(requiredValueMsg),
		}),
		elements: [
			<ContractNumber disabled={false} />,
			<Commodity disabled />,
			<CropYears disabled />,
			<ContractType
				disabled
				resetDependenciesOnChange={false}
				showContractType={false}
			/>,
			<Quantity disabled />,
			<Futures
				futuresMonth={{ disabled: false, market: false }}
				futuresPrice={{ market: false }}
			/>,
			<HedgeAccount />,
			<Comments />,
		],
	};
};
