import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.regions || initialState;

export const selectAllRegions = createSelector(
	[selectDomain],
	(state) => state.regions.data,
);

export const selectAllRegionsLoading = createSelector(
	[selectDomain],
	(state) => state.regions.loading,
);
