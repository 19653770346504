import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { selectActiveOffer } from 'app/containers/Offers/selectors';
import { translations } from 'locales/i18n';
import { getActionType } from 'utils/helpers';

import { SourceContext } from '../..';
import { useLocationsByAction } from '../../hooks/useLocationsByAction';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';

interface Props {
	disabled?: boolean;
	hidden?: boolean;
}

export const Location = (props: Props) => {
	const { disabled = false, hidden = true } = props;
	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.Location;

	const sourceName = useContext(SourceContext);

	const { watch } = useFormContext();

	const activeContract = useSelector(selectActiveContract);
	const activeOffer = useSelector(selectActiveOffer);
	// if action is in the form data, use that, otherwise fallback to action of the active contract/offer
	// (some forms, eg Price/PriceViaOffer/PriceViaEFP, don't have action in the form data)
	const action =
		watch('action') ??
		(activeContract && getActionType(activeContract.isSell)) ??
		(activeOffer && getActionType(activeOffer.isSell));
	const { locations } = useLocationsByAction(action);

	const { getPostedBasis } = useHandleBusinessRules(sourceName);
	const handleChange = () => {
		getPostedBasis(sourceName);
	};

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="location"
			onChange={handleChange}
			data-testid="location-form-item"
			customClassName={hidden ? ['hidden'] : []}
		>
			<Dropdown
				disabled={disabled}
				key="location"
				placeholder={translate(msg.placeholder)}
				options={locations}
			/>
		</GenericForm.FormItem>
	);
};
