import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions as globalActions } from '../../../../GlobalSaga/slice';
import { serviceFeesActions } from '../slice';
import { View } from '../types';
const { serviceFeeRoot } = apiEndpoints;

export function* getServiceFeesList() {
	let requestURL = yield new URL(`${apiRoutes.base}/${serviceFeeRoot}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(serviceFeesActions.ServiceFeesListLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(serviceFeesActions.ServiceFeesListError(responseError));
	}
}

export function* createServiceFee(action: PayloadAction<any>) {
	let requestURL = yield new URL(`${apiRoutes.base}/${serviceFeeRoot}`);
	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		action.payload,
	);
	if (!!responseData) {
		yield put(serviceFeesActions.serviceFeeMonths(responseData));
		yield put(globalActions.loadServiceFeeList());
	} else if (!!responseError) {
		yield put(serviceFeesActions.setServiceFeeStatusError(responseError));
	}
}

const { serviceFeeDeactivate, serviceFeeActivate } = apiEndpoints;

export function* setServiceFeeStatus(action: PayloadAction<any>) {
	const { id, isActive } = action.payload;
	let requestURL;
	if (isActive) {
		requestURL = yield new URL(
			`${apiRoutes.base}/${serviceFeeDeactivate}?id=${id}`,
		);
	} else {
		requestURL = yield new URL(
			`${apiRoutes.base}/${serviceFeeActivate}?id=${id}`,
		);
	}

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
	);

	// Success actions
	if (!!responseData) {
		yield put(serviceFeesActions.loadServiceFeesList());
		yield put(serviceFeesActions.setServiceFeeStatusSuccess(responseData));
	} else if (!!responseError) {
		yield put(serviceFeesActions.loadServiceFeesList());
		yield put(serviceFeesActions.setServiceFeeStatusError(responseError));
	}
}

export function* setServiceFeeMonths(action: PayloadAction<any>) {
	let requestURL = yield new URL(`${apiRoutes.base}/${serviceFeeRoot}/update`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		action.payload,
	);

	if (!!responseData) {
		yield put(serviceFeesActions.serviceFeeMonths(responseData));
		yield put(serviceFeesActions.setCurrentView(View.List));
		yield put(serviceFeesActions.setServiceFeeStatusSuccess(responseData));
		yield put(globalActions.loadServiceFeeList());
	} else if (!!responseError.detail) {
		yield put(serviceFeesActions.setServiceFeeStatusError(responseError));
	}
}

export function* loadEditServiceFee(action: PayloadAction<any>) {
	const id = action.payload.id;
	let requestURL = yield new URL(`${apiRoutes.base}/${serviceFeeRoot}/${id}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(serviceFeesActions.serviceFeeMonths(responseData));
	} else if (!!responseError.detail) {
		yield put(serviceFeesActions.ServiceFeesListError(responseError));
	}
}
