import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.brokerMapping || initialState;

export const selectBrokerMapping = createSelector(
	[selectDomain],
	(brokerMappingState) => brokerMappingState.mappings,
);

export const selectCommodityId = createSelector(
	[selectDomain],
	(brokerMappingState) => brokerMappingState.commodityId,
);

export const selectRegionId = createSelector(
	[selectDomain],
	(brokerMappingState) => brokerMappingState.regionId,
);

export const displayMapping = createSelector(
	[selectDomain],
	(brokerMappingState) => brokerMappingState.displayMapping,
);

export const selectUpdateMappingLoading = createSelector(
	[selectDomain],
	(brokerMappingState) => brokerMappingState.updateMappings.loading,
);

export const selectCreateMappingLoading = createSelector(
	[selectDomain],
	(brokerMappingState) => brokerMappingState.createMappings.loading,
);
