import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
	BrokerMappingRequest,
	BrokerMappingSectionState,
	CreateBrokerMapRequest,
	UpdateBrokerMappingRequest,
} from './types';

export const initialState: BrokerMappingSectionState = {
	mappings: {
		data: null,
		loading: false,
		error: null,
	},
	updateMappings: {
		data: null,
		loading: false,
		error: null,
	},
	createMappings: {
		data: null,
		loading: false,
		error: null,
	},
	displayMapping: false,
	commodityId: '',
	regionId: '',
};

const brokerMappingSlice = createSlice({
	name: 'brokerMapping',
	initialState,
	reducers: {
		clearMappings(state) {
			state.mappings = {
				data: [],
				loading: false,
				error: null,
			};
		},
		loadMappings(state, action: PayloadAction<BrokerMappingRequest>) {
			state.mappings = {
				...state.mappings,
				loading: true,
				error: null,
			};
		},

		mappingsLoaded(state, action: PayloadAction<any>) {
			state.mappings = {
				...state.mappings,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		mappingsError(state, action: PayloadAction<GenericError>) {
			state.mappings = {
				...state.mappings,
				loading: false,
				error: action.payload,
			};
		},

		updateMappings(state, action: PayloadAction<UpdateBrokerMappingRequest[]>) {
			state.updateMappings = {
				...state.updateMappings,
				loading: true,
				error: null,
			};
		},

		createMappings(state, action: PayloadAction<CreateBrokerMapRequest[]>) {
			state.createMappings = {
				...state.createMappings,
				loading: true,
				error: null,
			};
		},

		mappingsUpdated(
			state,
			action: PayloadAction<UpdateBrokerMappingRequest[]>,
		) {
			state.updateMappings = {
				...state.updateMappings,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		mappingsCreated(state, action: PayloadAction<CreateBrokerMapRequest[]>) {
			state.createMappings = {
				...state.createMappings,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		mappingsUpdateError(state, action: PayloadAction<GenericError>) {
			state.updateMappings = {
				...state.updateMappings,
				loading: false,
				error: action.payload,
			};
		},

		mappingsCreateError(state, action: PayloadAction<GenericError>) {
			state.createMappings = {
				...state.createMappings,
				loading: false,
				error: action.payload,
			};
		},

		setCommodityId(state, action: PayloadAction<string>) {
			state.commodityId = action.payload;
		},

		setRegionId(state, action: PayloadAction<string>) {
			state.regionId = action.payload;
		},

		setShowDisplay(state, action: PayloadAction<boolean>) {
			state.displayMapping = action.payload;
		},
	},
});

export const {
	actions: brokerMappingActions,
	reducer: brokerMappingReducer,
	name: sliceKey,
} = brokerMappingSlice;
