import './style.scss';

import { MoreOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popover, Typography } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ContractTypePill } from 'app/components/ContractTypePill';
import { FilterEmpty } from 'app/components/FilterEmpty';
import { GenericTable } from 'app/components/GenericTable';
import { UserAvatar } from 'app/components/UserAvatar';
import { actions } from 'app/containers/Offers/slice';
import { OffersFixedColumns } from 'app/containers/Offers/types';
import { PopoverContent } from 'app/containers/Transactions/components/PopoverContent';
import { translations } from 'locales/i18n';
import { FixedColumn } from 'types/FixedColumn';
import { CONSTANTS } from 'utils/constants';
import {
	currencyFormat,
	customFormat,
	dateFormat,
	dateShortFormat,
	hasSetFilters,
	timeFormat,
} from 'utils/helpers';

import {
	selectFixedColumns,
	selectOffersList,
	selectOffersListLoading,
	selectPagination,
	selectSelectedColumns,
	selectSelectedFilters,
	selectSelectedRows,
	selectTotalOffers,
} from '../../../../selectors';
import { ActionsMenu } from './ActionsMenu';
import { getIsOrderStatusFilled } from './utils/helpers';

const { Paragraph } = Typography;

export const TableWrapper = memo(function TableWrapper() {
	const selectedColumns = useSelector(selectSelectedColumns);
	const fixedColumns = useSelector(selectFixedColumns);

	const dispatch = useDispatch();

	const translationsScope = translations.app.containers.Offers.TableWrapper;

	const data = useSelector(selectOffersList);
	const loading = useSelector(selectOffersListLoading);
	const selectedFilters = useSelector(selectSelectedFilters);
	const total = useSelector(selectTotalOffers);
	const pagination = useSelector(selectPagination);

	const selectedRowsData = useSelector(selectSelectedRows);

	const { t: translate } = useTranslation();

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadOffersList(false));
	};

	const getBasisElements = (data) => [
		{
			label: translate(translationsScope.postedBasis),
			value: data.basis.posted,
		},
		{
			label: translate(translationsScope.pushBasis),
			value: data.basis.push,
		},
		{
			label: translate(translationsScope.netBasis),
			value: data.basis.net,
		},
	];

	const getPriceElements = (data) => [
		{
			label: translate(translationsScope.futures),
			value: data.price.futures,
		},
		{
			label: translate(translationsScope.netBasis),
			value: data.basis.net,
		},
		{
			label: translate(translationsScope.freight),
			value: data.price.freight,
		},
		{
			label: translate(translationsScope.serviceFee),
			value: data.price.fees[0],
		},
		{
			label: translate(translationsScope.serviceFee),
			value: data.price.fees[1],
		},
	];

	const Item = (props) => (
		<div className="wrapper-column" data-testid={props['data-testid']}>
			<div className={`offer__column ${props.className}`}>{props.children}</div>
		</div>
	);

	const getIsOrphan = ({ isOrphan, hasRejection }) => {
		if (isOrphan) {
			return 'table-colum--yellow';
		} else if (hasRejection) {
			return 'table-colum--red';
		} else {
			return '';
		}
	};

	const isOrderStatusFilled = getIsOrderStatusFilled(selectedFilters);
	const selectedDate = isOrderStatusFilled ? 'updateDate' : 'creationDate';

	const columns: {
		name: keyof OffersFixedColumns;
		title: string;
		className: string;
		fixed: FixedColumn;
		render: (any) => JSX.Element;
	}[] = [
		{
			name: 'created',
			title: translate(
				isOrderStatusFilled
					? translationsScope.updated
					: translationsScope.created,
			),
			className: 'column-table offers-column sm',
			fixed: fixedColumns['created'],
			render: (data) => (
				<Item>
					<Popover
						content={
							<div className="popover--content">
								<strong>{translate(translationsScope.status)}: </strong>
								{data.status}
							</div>
						}
						trigger="hover"
					>
						<div>
							<p className="text text--bold text--large">
								{dateFormat(data[selectedDate])}
							</p>
							<p className="text text--label">
								{timeFormat(data[selectedDate])}
							</p>
						</div>
					</Popover>
				</Item>
			),
		},
		{
			name: 'offer',
			title: translate(translationsScope.offer),
			className: 'column-table offers-column md',
			fixed: fixedColumns['offer'],
			render: (data) => (
				<Item>
					<div className="text text--bold flex-row">
						<div>
							<span className="text text--large text--bold">
								{data?.contract?.number ? `${data.contract.number}` : ''}
							</span>
							<span>
								{data.parent && data.parent.number
									? `${data.parent.number}`
									: null}
							</span>
						</div>

						<div>
							<ContractTypePill
								contractType={data.contract.type}
								isSell={data.isSell}
								style={{ marginLeft: 4 }}
							/>
						</div>
					</div>
				</Item>
			),
		},
		{
			name: 'expiration',
			title: translate(translationsScope.expiration),
			className: 'column-table offers-column sm',
			fixed: fixedColumns['expiration'],
			render: (data) => {
				return (
					<Item
						data-testid={`${data.expiration ? 'expiration' : 'gtc'}-${data.id}`}
					>
						<p className="text text--centered text--large">
							{data.expiration
								? dateShortFormat(data.expiration)
								: translate(translationsScope.gtc)}
						</p>
					</Item>
				);
			},
		},
		{
			name: 'customer',
			title: translate(translationsScope.customer),
			className: 'column-table column-table md md--content-limit',
			fixed: fixedColumns['customer'],
			render: (data) => (
				<Item>
					<p className="text text--centered text--small line-height">
						{data?.customer?.number}
					</p>
					<Paragraph
						className="text text--centered text--small line-height"
						title={data?.customer?.name}
						ellipsis={{ rows: 2 }}
					>
						{data?.customer?.name}
					</Paragraph>
				</Item>
			),
		},
		{
			name: 'employee',
			title: translate(translationsScope.employee),
			className: 'column-table offers-column xs xs--content-limit',
			fixed: fixedColumns['employee'],
			render: (data) => {
				return (
					<Item>
						<UserAvatar user={data.employee} />
					</Item>
				);
			},
		},
		{
			name: 'commodity',
			title: translate(translationsScope.commodity),
			className: 'column-table offers-column xs xs--content-limit',
			fixed: fixedColumns['commodity'],
			render: (data) => (
				<Item>
					<Paragraph
						className="text text--large line-height"
						title={data.commodity}
						ellipsis={{ rows: 2 }}
					>
						{data.commodity}
					</Paragraph>
				</Item>
			),
		},
		{
			name: 'crop',
			title: translate(translationsScope.crop),
			className: 'column-table offers-column xs xs--content-limit',
			fixed: fixedColumns['crop'],
			render: (data) => (
				<Item>
					<p className="text text--bold text--large text--centered">
						{data.cropYear}
					</p>
				</Item>
			),
		},
		{
			name: 'deliveryStartDate',
			title: translate(translationsScope.deliveryStartDate),
			className: 'column-table offers-column sm',
			fixed: fixedColumns['deliveryStartDate'],
			render: (data) => (
				<Item>
					<p className="text text--large text--centered">
						{dateShortFormat(data.delivery.begin)}
					</p>
				</Item>
			),
		},
		{
			name: 'deliveryEndDate',
			title: translate(translationsScope.deliveryEndDate),
			className: 'column-table offers-column sm',
			fixed: fixedColumns['deliveryEndDate'],
			render: (data) => (
				<Item>
					<p className="text text--large text--centered">
						{dateShortFormat(data.delivery.end)}
					</p>
				</Item>
			),
		},
		{
			name: 'futuresMonth',
			title: translate(translationsScope.futuresMonth),
			className: 'column-table offers-column xs xs--content-limit',
			fixed: fixedColumns['futuresMonth'],
			render: (data) => (
				<Item>
					<p className="text text--large text--centered">
						{data.price.futuresMonth}
					</p>
				</Item>
			),
		},
		{
			name: 'destination',
			title: translate(translationsScope.destination),
			className: 'column-table offers-column sm sm--content-limit',
			fixed: fixedColumns['destination'],
			render: (data) => (
				<Item>
					<Paragraph
						className="text text--large line-height"
						title={data.delivery.destination}
						ellipsis={{ rows: 2 }}
					>
						{data.delivery.destination}
					</Paragraph>
				</Item>
			),
		},
		{
			name: 'quantity',
			title: translate(translationsScope.quantity),
			className: 'column-table column-table offers-column sm',
			fixed: fixedColumns['quantity'],
			render: (data) => (
				<Item>
					<p className="text text--large text--bold text--centered">
						{customFormat(
							data.quantity.quantity,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						)}
					</p>

					<p className="text text--centered">
						<span>
							{`${translate(translationsScope.quantityUnfilled)} ${
								data?.quantity?.unfilled
									? customFormat(
											data.quantity.unfilled,
											true,
											CONSTANTS.FIXED_QUANTITY_DECIMALS,
										)
									: '---'
							}`}
						</span>
					</p>
				</Item>
			),
		},
		{
			name: 'futures',
			title: translate(translationsScope.futures),
			className: 'column-table offers-column xs',
			fixed: fixedColumns['futures'],
			render: (data) => (
				<Item>
					<p className="text text--large text--centered">
						{currencyFormat(
							data.price.futures,
							CONSTANTS.FIXED_DECIMALS,
							CONSTANTS.FIXED_DECIMALS,
						)}
					</p>
				</Item>
			),
		},
		{
			name: 'basis',
			title: translate(translationsScope.basis),
			className: 'column-table offers-column xs',
			fixed: fixedColumns['basis'],
			render: (data) => (
				<Item>
					<Popover
						content={<PopoverContent elements={getBasisElements(data)} />}
						trigger="hover"
					>
						<p className="text text--large text--centered">
							{currencyFormat(
								data.basis.net,
								CONSTANTS.FIXED_DECIMALS,
								CONSTANTS.FIXED_DECIMALS,
							)}
						</p>
					</Popover>
				</Item>
			),
		},
		{
			name: 'cash',
			title: translate(translationsScope.cash),
			className: 'column-table offers-column xs',
			fixed: fixedColumns['cash'],
			render: (data) => (
				<Item>
					<Popover
						content={<PopoverContent elements={getPriceElements(data)} />}
						trigger="hover"
					>
						<p className="text text--large text--centered">
							{currencyFormat(
								data.price.price,
								CONSTANTS.FIXED_DECIMALS,
								CONSTANTS.FIXED_DECIMALS,
							)}
						</p>
					</Popover>
				</Item>
			),
		},
		{
			name: 'dots',
			title: '',
			className: 'column-table offers-column',
			fixed: fixedColumns['dots'],
			render: (data) => (
				<Item className="last-column">
					<Popover
						content={<ActionsMenu data={data} />}
						trigger="click"
						data-testid="more-actions-trigger"
					>
						<Button type="text" icon={<MoreOutlined />} />
					</Popover>
				</Item>
			),
		},
	];

	const selectedRowKeys = selectedRowsData.map(({ id }) => id);

	const onChangeSelection = (_selectedIDs, selectedRowsData) => {
		dispatch(actions.setSelectedRows(selectedRowsData));
	};

	const selectionColumnHeader =
		selectedFilters?.statusView?.[0].value === 'all' ? (
			<Checkbox disabled={true}></Checkbox>
		) : undefined;

	columns.sort((a, b) => {
		const first = a.fixed === 'left' ? 0 : a.fixed === false ? 1 : 2;
		const second = b.fixed === 'left' ? 0 : b.fixed === false ? 1 : 2;

		if (first === second) {
			return 0;
		} else if (first < second) {
			return -1;
		} else {
			return 1;
		}
	});

	let newColumns = columns
		.filter((col) => selectedColumns[col.name])
		.map((col) => {
			return {
				...col,
				fixed: fixedColumns[col.name],
			};
		});

	return (
		<GenericTable.VirtualTable
			className="table-wrapper"
			columns={newColumns}
			loading={loading}
			pagination={{
				total: total,
				pageSize: pagination.limit,
				defaultPageSize: pagination.limit,
				current: pagination.start,
				onChange: handleOnChange,
				pageSizeOptions: ['20', '50', '100'],
			}}
			data={data}
			rowKey={(record) => record.id}
			locale={{
				emptyText: loading ? (
					false
				) : (
					<FilterEmpty
						description={
							hasSetFilters(selectedFilters)
								? undefined // default message is for filters applied and not found records
								: translate(translationsScope.noOffers)
						}
					/>
				),
			}}
			onRow={(record) => {
				return {
					className: `border-left-offer--${record?.status?.trim().split(' ').join('-').toLowerCase()}`,
					'data-testid': `offer-row-${record.id}`,
				};
			}}
			rowClassName={getIsOrphan}
			rowSelection={{
				onChange: onChangeSelection,
				columnTitle: selectionColumnHeader,
				preserveSelectedRowKeys: false,
				fixed: true,
				columnWidth: 30,
				selectedRowKeys,
			}}
		/>
	);
});
