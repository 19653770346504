import { GenericError } from 'types/GenericError';
import { Region } from 'types/Regions';

export enum REGIONS_FORM_ITEMS {
	ID = 'id',
	REGION_NAME_LABEL = 'regionNameLabel',
	ERP_ID_LABEL = 'erpIdLabel',
	STATUS = 'isActive',
	REGIONS_DEFAULT = 'regions',
	TYPE = 'type',
}

export interface RegionsState {
	regionUpdate: {
		error: GenericError | null;
		loading: boolean;
	};
	regions: {
		data: Region[];
		error: GenericError | null;
		loading: boolean;
	};
}
