import './style.scss';

import {
	CheckCircleOutlined,
	CloseCircleFilled,
	CloseCircleOutlined,
	ExclamationCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { Select as AntSelect, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import classNames from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';

const { Option } = AntSelect;

interface Props
	extends Omit<SelectProps<GenericOption>, 'onChange' | 'labelInValue'> {
	name: string;
	label?: string;
	placeholder?: string;
	onSearch: (string: string) => void;
	options: GenericOption[];
	clearOptions: () => void;
	disabled?: boolean;
	loading?: boolean;
	required?: boolean;
}

enum StatusType {
	success = 'success',
	warning = 'warning',
	error = 'error',
	validating = 'validating',
	none = '',
}

export const SearchWithFeedback = memo(function SearchWithFeedback(
	props: Props,
) {
	const [currentSearchKey, setCurrentSearchKey] = useState('');

	const { t: translate } = useTranslation();

	const { name, disabled, loading, clearOptions, ...otherProps } = props;

	const { watch, setValue, setError, clearErrors, formState } =
		useFormContext();

	const value = watch(name);
	const error = formState.errors[name];

	const handleSearch = (searchText: string) => {
		setCurrentSearchKey(searchText);

		if (searchText) props.onSearch(searchText);
		else {
			clearOptions();
			setValue(name, { value: null }, { shouldValidate: true });
		}
	};

	const handleBlur = () => {
		if (currentSearchKey) setCurrentSearchKey('');
		clearOptions();
	};

	let status = StatusType.none;
	const warningMessage = translate(
		translations.app.components.SearchWithFeedback.unavailable,
	);

	if (loading) {
		status = StatusType.validating;
	} else if (value?.value && !currentSearchKey) {
		status = StatusType.success;
	} else if (currentSearchKey && props.options.length === 0) {
		status = StatusType.warning;
	} else if (!currentSearchKey && error) {
		if (error?.message === warningMessage) status = StatusType.warning;
		else status = StatusType.error;
	}

	useEffect(() => {
		if (status === StatusType.warning) {
			setError(name, { type: 'warning', message: warningMessage });
		} else if (error?.message === warningMessage) {
			clearErrors(name);
		}
	}, [name, status, warningMessage, error?.message, setError, clearErrors]);

	const selectOptions =
		currentSearchKey &&
		!!props.options &&
		props.options.map((item) => (
			<Option value={item.value} key={item.value}>
				{item.label}
			</Option>
		));

	return (
		<Select
			{...otherProps}
			className={classNames({
				[`${status}`]: !!status,
			})}
			labelInValue
			showSearch
			placeholder={props.placeholder}
			defaultActiveFirstOption={false}
			filterOption={false}
			onSearch={handleSearch}
			onBlur={handleBlur}
			disabled={disabled}
			notFoundContent={null}
			allowClear={true}
			showArrow={!!status}
			clearIcon={<CloseCircleFilled />}
			suffixIcon={
				status === 'success' ? (
					<CheckCircleOutlined />
				) : status === 'warning' ? (
					<ExclamationCircleOutlined />
				) : status === 'error' ? (
					<CloseCircleOutlined />
				) : status === 'validating' ? (
					<LoadingOutlined />
				) : undefined
			}
		>
			{selectOptions}
		</Select>
	);
});
