import React from 'react';

export function preventWheelEvent(e: React.FocusEvent<HTMLInputElement>) {
	e.target.addEventListener(
		'wheel',
		function (e) {
			e.preventDefault();
		},
		{ passive: false },
	);
}

export function preventWheelChange(e: React.WheelEvent<HTMLInputElement>) {
	e.preventDefault();
	e.currentTarget.blur();
}
