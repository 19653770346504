import { GenericError } from 'types/GenericError';
import { OrderEntryData } from 'types/OrderEntry';

export enum ORDER_ENTRY_FORM_ITEMS {
	ID = 'id',
	LABEL = 'label',
	MULTI_SELECT_LENGTH = 'multiSelectLength',
	MULTI_SELECT_INPUT = 'multiSelectInput',
	ORDER_ENTRY_DEFAULT = 'orderEntry',
	STATUS = 'isActive',
	TYPE = 'type',
}

export interface OrderEntryState {
	orderEntryUpdate: {
		data: OrderEntryData | null;
		error: GenericError | null;
		loading: boolean;
	};
}

export type OrderEntrySectionState = OrderEntryState;

export enum TOGGLE_FORM_ITEMS {
	HAS_CUSTOM_FIELDS = 'hasCustomFields',
}
