import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { AppliedUnappliedBushel } from 'app/containers/Contracts/components/Business/AppliedUnappliedBushel';
import { OriginBasis } from 'app/containers/Contracts/components/Business/OriginBasis';
import { OriginDeliveryDate } from 'app/containers/Contracts/components/Business/OriginDeliveryDate';
import { OriginDeliveryWindow } from 'app/containers/Contracts/components/Business/OriginDeliveryWindow';
import { QuantityToRoll } from 'app/containers/Contracts/components/Business/QuantityToRoll';
import { Spread } from 'app/containers/Contracts/components/Business/Spread';
import { useTranslations } from 'app/containers/Contracts/components/Modals/shared/useTranslations';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { Transaction } from 'app/containers/Transactions/components/Transaction';
import { CONSTANTS } from 'utils/constants';
import {
	customFormat,
	dateFormatYearMonthDay,
	deliveryWindowFormat,
	getDeliveryDatesMode,
	getDeliveryDateWindow,
	getExpirationDate,
	getFormattedExpirationDate,
	isDeliveryDateCustom,
	isDeliveryDateWindow,
	validatePriceFormat,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { validateMinMaxValue } from '../../../shared/helpers';

export const useBasisSchema = () => {
	const translations = useTranslations();

	let orderData = useSelector(selectActiveContract) as any;
	if (!!!orderData) return null;

	const requiredMsg = translations.validations.required;

	return {
		initialValues: {
			commodity: {
				label: orderData.commodityName,
				value: orderData.commodityId,
			},
			location: {
				label: orderData.locationName,
				value: orderData.locationId,
			},
			deliveryLocation: {
				label: orderData.deliveryLocationName,
				value: orderData.deliveryLocationId,
			},
			contract: {
				label: orderData.contractTypeName,
				value: orderData.contractTypeId,
			},
			transaction: {
				label: orderData.transactionTypeName,
				value: orderData.transactionTypeId,
			},
			rolling: null,
			deliveryWindow: deliveryWindowFormat(
				orderData.futuresMonth,
				orderData.deliveryStartDate,
				orderData.deliveryEndDate,
			),
			deliveryDate: [
				moment(orderData.deliveryStartDate),
				moment(orderData.deliveryEndDate),
			],
			origBasis: orderData.netBasis,
			deliveryDatesMode: getDeliveryDatesMode(orderData),
			deliveryDateWindow: getDeliveryDateWindow(orderData),
			deliveryDateRolling: orderData.futuresMonth,
			futuresPriceInput: validatePriceFormat(orderData.futuresPrice),
			cropYear: orderData.cropYear,
			futuresMonth: { value: null },
			futuresPrice: null,
			qtyRollBalance: `${customFormat(orderData.remainingBalance, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`,
			qtyRollAmount: null,
			spread: null,
			postedBasis: validatePriceFormat(orderData.postedBasis),
			pushBasis: validatePriceFormat(orderData.pushBasis),
			netBasis: validatePriceFormat(orderData.netBasis),
			freight: validatePriceFormat(orderData.freightPrice),
			fees1: validatePriceFormat(orderData.fees1),
			fees2: validatePriceFormat(orderData.fees2),
			employee: {
				label: orderData.employeeName,
				value: orderData.employeeId,
			},
			comments: orderData.comments,
			expirationDate: getExpirationDate(orderData),
			useServiceFees: false,
			isRoll: false,
			isUnappliedLoad: false,
		},
		validationSchema: Yup.object().shape({
			deliveryDatesMode: Yup.object().requiredOption(),
			deliveryDate: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateCustom(mode),
				then: Yup.mixed().required(requiredMsg),
			}),
			deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateWindow(mode),
				then: Yup.object().requiredOption(requiredMsg),
			}),
			deliveryDateRolling: Yup.string()
				.nullable()
				.required(translations.validations.required),
			qtyRollAmount: Yup.string()
				.required(translations.validations.required)
				.test(
					'minMaxValidation',
					translations.validations.rangeValue(
						customFormat(
							orderData.remainingBalance,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					),
					(value: string) =>
						validateMinMaxValue(orderData.remainingBalance, value),
				)
				.nullable(),
			employee: Yup.object().requiredOption(requiredMsg),
			spread: Yup.number()
				.typeError(translations.validations.number)
				.required(translations.validations.required),
			freight: Yup.number().typeError(translations.validations.number),
			fees1: Yup.number().typeError(translations.validations.number),
			fees2: Yup.number().typeError(translations.validations.number),
			postedBasis: Yup.number()
				.typeError(translations.validations.required)
				.required(translations.validations.required),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
			expirationDate: Yup.string().nullable(),
		}),
		elements: [
			<Commodity hidden />, // hidden fields work as inner references for business rules
			<DeliveryLocation hidden />,
			<Location />,
			<Transaction hidden />,
			<ContractType hidden />,
			<OriginDeliveryWindow disabled />,
			<OriginDeliveryDate disabled />,
			<OriginBasis disabled />,
			<CropYears />,
			<DeliveryDates
				resetDependencies
				spreadDependency
				useServiceFees
				isRoll
			/>,
			<Futures
				futuresMonth={{
					market: true,
					loadSpread: true,
					filterMonths: true,
					filterByMonth: orderData.futuresMonth,
				}}
			/>,
			<QuantityToRoll disabled={{ qtyRollBalance: true }} />,
			<AppliedUnappliedBushel />,
			<Basis disabled={{ netBasis: true }} />,
			<Spread canUpdateBasis spreadBasisDependency showRetryBtn />,
			<ExpirationDate />,
			<Freight />,
			<Fees />,
			<Employee />,
			<Comments />,
		],
		getSubmitValues: (values) => {
			let submitValues = {
				futuresMonth: values.futuresMonth?.value,
				deliveryStartDate: dateFormatYearMonthDay(values.deliveryDate[0]),
				deliveryEndDate: dateFormatYearMonthDay(values.deliveryDate[1]),
				quantity: parseFloat(
					customFormat(
						values.qtyRollAmount,
						false,
						CONSTANTS.FIXED_QUANTITY_DECIMALS,
					),
				),
				freightPrice: values.freight,
				fees1: values.fees1,
				fees2: values.fees2,
				employeeId: values.employee.value,
				comments: values.comments,
				isDeliveryDatesCustom: isDeliveryDateCustom(values.deliveryDatesMode),
				cropYear: values.cropYear,
				postedBasis: parseFloat(values.postedBasis),
				pushBasis: parseFloat(values.pushBasis),
				netBasis: parseFloat(values.netBasis),
				expirationDate: getFormattedExpirationDate(values),
				isAppliedLoad: !values?.isUnappliedLoad ?? true,
			};

			if (isNaN(values.freight)) delete submitValues.freightPrice;
			if (isNaN(values.fees1)) delete submitValues.fees1;
			if (isNaN(values.fees2)) delete submitValues.fees2;

			return submitValues;
		},
	};
};
