import { Button, Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ClearFilters } from 'app/components/ClearFilter';
import { FixedColumns } from 'app/components/ColumnPreference/FixedColumns';
import { VisibleColumns } from 'app/components/ColumnPreference/VisibleColumns';
import { ExcelExport } from 'app/components/ExcelExport';
import {
	selectExportTemplate,
	selectFilters,
	selectFixedColumns,
	selectSelectedColumns,
} from 'app/containers/ReviewAndRelease/selectors';
import { actions } from 'app/containers/ReviewAndRelease/slice';
import { Modal } from 'app/containers/ReviewAndRelease/types';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';
import { GenericOption } from 'types/GenericOption';
import {
	FILTERS_FOR_REVIEW_AND_RELEASE,
	FIXED_COLUMNS_FOR_REVIEW_AND_RELEASE,
	SELECTED_COLUMNS_FOR_REVIEW_AND_RELEASE,
} from 'utils/constants';
import { countFilledFilters } from 'utils/helpers';
import { initialFilters } from 'utils/initial-filters';

import { BulkActions } from './BulkActions';
import { Filters } from './Filters';

export const Menu = memo(function Menu() {
	const url = useSelector(selectExportTemplate)?.url;
	const [activeFilterCount, setActiveFilterCount] = useState(0);
	const selectedFilters = useSelector(selectFilters);
	const mapOptions = (values: string[]) =>
		values.map<GenericOption>((value) => ({
			label: translate(
				translations.app.containers.ReviewAndRelease.components.Views.List
					.Table[value],
			),
			value: value,
		}));
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const featureFlags = useFlags();

	const scopeTranslations =
		translations.app.containers.ReviewAndRelease.components.Views.List
			.ActionsMenu;
	const handleExternalFill = () => {
		dispatch(actions.setCurrentModal(Modal.ExternalFill));
	};
	const handleOnClickExport = () => {
		const errorMsg = translate(scopeTranslations.failedExport);
		const successMsg = translate(scopeTranslations.successExport);
		dispatch(actions.exportReviewAndRelease({ errorMsg, successMsg }));
	};

	const selectedColumns = useSelector(selectSelectedColumns);
	const mutableColumns = Object.keys(selectedColumns).filter(
		(value) => !['created', 'dots'].includes(value),
	);
	const fixedColumns = useSelector(selectFixedColumns);
	const movableColumns = Object.keys(fixedColumns).filter(
		(value) => !['created', 'dots'].includes(value),
	);

	useEffect(() => {
		setActiveFilterCount(countFilledFilters(selectedFilters, [], true));
	}, [selectedFilters]);

	const newFiltersLayout = featureFlags[FeatureFlag.enableNewFilterLayout];

	const clearFilters = (
		<ClearFilters
			activeFilterCount={activeFilterCount}
			localStorageKey={FILTERS_FOR_REVIEW_AND_RELEASE}
			loadGrid={actions.loadReviewAndReleaseList()}
			seFilters={actions.setSelectedFilters(
				initialFilters.reviewAndReleaseFilters,
			)}
			grid={'reviewAndReleaseFilters'}
		/>
	);

	const filters = <Filters />;

	const userColumnPreference = (
		<>
			<FixedColumns
				persistedDataKey={FIXED_COLUMNS_FOR_REVIEW_AND_RELEASE}
				fixedColumns={fixedColumns}
				setFixedColumns={actions.setFixedColumns}
				columns={movableColumns}
				mapOptions={mapOptions}
			/>
			<VisibleColumns
				persistedDataKey={SELECTED_COLUMNS_FOR_REVIEW_AND_RELEASE}
				selectedColumns={selectedColumns}
				setSelectedColumns={actions.setSelectedColumns}
				columns={mutableColumns}
				mapOptions={mapOptions}
			/>
		</>
	);

	const bulkActions = <BulkActions />;

	const externalFillButton = (
		<Button
			disabled={false}
			onClick={handleExternalFill}
			type="primary"
			data-testid="button-external-fill"
		>
			{translate(scopeTranslations.externalFill)}
		</Button>
	);

	const excelExport = (
		<ExcelExport
			fileName={translate(scopeTranslations.exportFileName)}
			handleFileRequest={handleOnClickExport}
			url={url}
		/>
	);

	if (newFiltersLayout) {
		return (
			<Row>
				<Col xs={12} md={12} lg={12} xl={9}>
					{clearFilters}
					{filters}
				</Col>
				<Col xs={12} md={12} lg={12} xl={3} className="padding">
					<Row justify="end">{userColumnPreference}</Row>
					<Row justify="end">{bulkActions}</Row>
					<Row justify="end">
						{externalFillButton}
						{excelExport}
					</Row>
				</Col>
			</Row>
		);
	} else {
		return (
			<div>
				{clearFilters}
				{filters}
				<Row justify="end">{userColumnPreference}</Row>
				<Row justify="end">
					{bulkActions}
					{externalFillButton}
					{excelExport}
				</Row>
			</div>
		);
	}
});
