import { FieldValues } from 'react-hook-form';

import { translations } from 'locales/i18n';
import { mapDynamicForm } from 'utils/helpers';

export const NTC_ADMIN_DOMAIN =
	translations.app.containers.Settings.sections.NtcAdmin;

export const NTC_ADMIN_ENTRIES_MAX = 1;
const MIN_GUID_PORTION = 5;

export const isInputValid = (input: string, status: boolean) =>
	!!input || !!!status;

export const getFormValues = (isCreatingNTC: boolean, values: FieldValues) =>
	mapDynamicForm(values).map((formItem: FieldValues) => {
		const breakId = formItem?.id.split('-');

		const idToPass =
			breakId.length < MIN_GUID_PORTION ? undefined : formItem?.id;

		return {
			id: idToPass, //TODO:might need to update the logic, id: isCreatingNTC ? undefined : formItem?.id,
			isActive: formItem?.isActive,
			name: formItem?.ntcNameLabel,
			erpNumber: formItem?.erpIdLabel,
		};
	});
