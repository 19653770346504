import { Button, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { translations } from 'locales/i18n';

import { actions } from '../../../slice';
import { Modal } from '../../../types';

export const Filters = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const translationsRoot =
		translations.app.containers.Settings.sections.RoundingRules.View.List
			.Filters;

	const handleCreateRoundingRule = () => {
		dispatch(actions.setCurrentModal(Modal.Create));
	};

	// TODO Enable this when onSearch implemented
	// const handleOnSearch = value => {
	//   dispatch(
	//     actions.setPagination({
	//       start: 1,
	//     }),
	//   );
	//   dispatch(
	//     actions.setSelectedFilters({
	//       name: value,
	//     }),
	//   );
	//   dispatch(actions.loadRoundingRulesList());
	// };

	return (
		<div className="filters-container">
			<Row className="grid-menu" gutter={16}>
				<Col span={9}>
					<Row gutter={16}>
						<Col xs={3} md={4} xxl={3} className="grid-menu-label">
							{translate(translationsRoot.labelRoundingRules)}
						</Col>
					</Row>
				</Col>
				<Col span={3}>
					<Row justify="end">
						<Button
							type="primary"
							size="middle"
							onClick={handleCreateRoundingRule}
							className="orders-button"
							data-testid="button-create-rounding-rule"
						>
							{translate(translationsRoot.createButton)}
						</Button>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
