import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.bypassReview || initialState;

export const selectTag50SettingsData = createSelector(
	[selectDomain],
	(bypassReviewState) => bypassReviewState.tag50Settings.data,
);

export const selectUpdateTag50SettingsLoading = createSelector(
	[selectDomain],
	(bypassReviewState) => bypassReviewState.updateTag50Settings.loading,
);

export const selectTag50EmployeesListData = createSelector(
	[selectDomain],
	(bypassReviewState) => bypassReviewState.tag50EmployeesList.data,
);
