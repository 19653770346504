import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { IGenericPagination } from 'types/GenericPagination';
import { UserInternalNotification } from 'types/UserNotification';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { generateNotificationLink } from 'utils/notifications';

import { NotificationsHistoryState } from './types';

export const initialState: NotificationsHistoryState = {
	pagination: {
		start: 1,
		limit: 20,
	},
	notificationsList: {
		data: [],
		total: 0,
		loading: false,
		error: null,
	},
};

const notificationsHistorySlice = createSlice({
	name: 'notificationsHistory',
	initialState,
	reducers: {
		loadNotificationsHistory(
			state,
			action: PayloadAction<Partial<IGenericPagination>>,
		) {
			const { start, limit } = action.payload;

			if (start && limit) {
				state.pagination = {
					start,
					limit,
				};
			}

			state.notificationsList = {
				...state.notificationsList,
				loading: true,
			};
		},

		notificationsHistoryLoaded(
			state,
			action: PayloadAction<{
				data: UserInternalNotification[];
				total: number;
			}>,
		) {
			const { data, total } = action.payload;
			state.notificationsList = {
				...state.notificationsList,
				data: data.map((item) => ({
					...item,
					path: generateNotificationLink(item),
				})),
				total,
				loading: false,
			};
		},

		notificationsHistoryError(state, action: PayloadAction<GenericError>) {
			state.notificationsList = {
				...state.notificationsList,
				error: action.payload,
				loading: false,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = notificationsHistorySlice;
