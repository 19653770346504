import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
	selectActiveContract,
	setCashbidsState,
} from 'app/containers/Contracts/selectors';
import {
	selectDestinationsList,
	selectGroupedLocationList,
	selectLocationsList,
} from 'app/containers/GlobalSaga/selectors';
import { selectActiveOffer } from 'app/containers/Offers/selectors';
import { selectActivePreHedge } from 'app/containers/PreHedge/selectors';
import { ActionType } from 'types/ActionType';
import { GroupedLocation } from 'types/GroupedLocation';
import { Location, LocationWithRegionIds } from 'types/Location';
import { mapToLabelValue } from 'utils/helpers';
import { isEmptyObject } from 'utils/validators';

export const useLocationsByAction = (action: ActionType) => {
	const allLocations = useSelector(selectLocationsList)?.data;
	const allDestinations = useSelector(selectDestinationsList)?.data;
	const allGroupedLocations = useSelector(selectGroupedLocationList)?.data;
	const currentContract = useSelector(selectActiveContract);
	const currentOffer = useSelector(selectActiveOffer);
	const cashbidsState = useSelector(setCashbidsState);
	const currentPrehedge = useSelector(selectActivePreHedge);

	const [availableLocations, setAvailableLocations] = useState<Location[]>([]);
	const [availableDestinations, setAvailableDestinations] = useState<
		Location[]
	>([]);
	const [availableGroupedLocations, setAvailableGroupedLocations] = useState<
		GroupedLocation[]
	>([]);
	// to filter out groupedLocations so that users can only see deliverylocation which has region same as contract's region
	let groupedLocations = useMemo(() => {
		const regionId =
			currentContract?.regionId ||
			currentOffer?.regionId ||
			currentPrehedge?.regionId;
		return regionId
			? allGroupedLocations.filter((location) => location.regionId === regionId)
			: allGroupedLocations;
	}, [allGroupedLocations, currentContract, currentOffer]);

	// If user is creating contract from the originator screen,
	// then filter the destination location to only show those destinations -
	//  that match the delivery location that was selected from the originator screen.
	if (cashbidsState && !isEmptyObject(cashbidsState)) {
		groupedLocations = groupedLocations.filter(
			(e) =>
				e.destinationLocationId === cashbidsState?.deliveryLocation?.filterId,
		);
	}
	useEffect(() => {
		const filterKey = action === ActionType.BUY ? 'forBuy' : 'forSell';

		const filteredLocations = allLocations?.filter(
			(location) => location[filterKey],
		);
		const filteredDestinations = allDestinations?.filter(
			(location) => location[filterKey],
		);
		const filteredGroupedLocations = groupedLocations?.filter(
			(location) => location[filterKey],
		);
		//TODO: Change allGroupedlocation with updatedgroupedlocation
		const labeledDestinations: LocationWithRegionIds[] =
			filteredGroupedLocations
				.reduce((result: LocationWithRegionIds[], groupedLocation) => {
					const existingLocation = result.find(
						(loc) => loc.name === groupedLocation.name,
					);
					const filteredDestination = filteredDestinations.find(
						(destination) =>
							destination.id === groupedLocation.destinationLocationId,
					);
					const { id, name, regionId } = groupedLocation;

					if (existingLocation) {
						// If the location with the same name already exists, merge regionIds
						existingLocation?.regionIds?.push(groupedLocation.regionId);
					} else {
						// If the location with the same name doesn't exist, create a new entry
						result.push({
							id: id,
							name: name,
							forBuy: !!filteredDestination?.forBuy,
							forSell: !!filteredDestination?.forSell,
							regionIds: [regionId],
						});
					}

					return result;
				}, [])
				.filter((location) => location.forBuy || location.forSell);

		setAvailableLocations(filteredLocations || []);
		setAvailableDestinations(labeledDestinations || []);
		setAvailableGroupedLocations(allGroupedLocations || []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, allGroupedLocations]);

	return {
		locations: mapToLabelValue(availableLocations),
		destinations: mapToLabelValue(availableDestinations),
		groupedLocations: availableGroupedLocations,
	};
};
