import { Popover, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import {
	selectOrphansAndAvailablesLoading,
	selectOrphansListData,
	selectRollOrphanOffersLoading,
	selectSelectedRows,
} from 'app/containers/Settings/sections/Bidsheet/selectors';
import { actions } from 'app/containers/Settings/sections/Bidsheet/slice';
import { PopoverContent } from 'app/containers/Transactions/components/PopoverContent';
import { translations } from 'locales/i18n';
import { CONSTANTS } from 'utils/constants';
import { currencyFormat, customFormat } from 'utils/helpers';

import { selectSelectedNoBid } from '../../../../selectors';
import { Orphan } from '../../../../types';

const { Paragraph } = Typography;
interface Props {
	name: string;
	id: string;
}
export const OrphansTable = (props: Props) => {
	const { name, id } = props;
	const dispatch = useDispatch();
	const loadingList: boolean = useSelector(selectOrphansAndAvailablesLoading);
	const rollingOffers: boolean = useSelector(selectRollOrphanOffersLoading);
	const listData: Orphan[] = useSelector(selectOrphansListData);
	const selectedRowsData: any[] = useSelector(selectSelectedRows);
	const hasSelectedRows: boolean = selectedRowsData.length > 0;
	const orphanTableLoading = loadingList || rollingOffers;

	const selectedNoBid = useSelector(selectSelectedNoBid);

	useEffect(() => {
		if (!!selectedNoBid) {
			dispatch(actions.resetSelectedRows());
			dispatch(actions.requestOrphansAndAvailables());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedNoBid]);

	const { t: translate } = useTranslation();
	const translationsScope = translations.app.containers.Offers.TableWrapper;

	const getBasisElements = (data) => [
		{
			label: translate(translationsScope.postedBasis),
			value: data.basis.posted,
		},
		{
			label: translate(translationsScope.pushBasis),
			value: data.basis.push,
		},
		{
			label: translate(translationsScope.netBasis),
			value: data.basis.net,
		},
	];

	const getPriceElements = (data) => [
		{
			label: translate(translationsScope.futures),
			value: data.price.futures,
		},
		{
			label: translate(translationsScope.netBasis),
			value: data.basis.net,
		},
		{
			label: translate(translationsScope.freight),
			value: data.price.freight,
		},
		{
			label: translate(translationsScope.serviceFee),
			value: data.price.fees[0],
		},
		{
			label: translate(translationsScope.serviceFee),
			value: data.price.fees[1],
		},
	];

	const columns = [
		{
			title: translate(translationsScope.customer),
			className: 'offers-column large',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--medium ellipsis"
						title={data.customer.name}
						ellipsis={{ rows: 1 }}
					>
						{`${data.customer.firstName} ${data.customer.lastName}`}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.commodity),
			className: 'offers-column medium',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--medium ellipsis"
						title={data.commodity}
						ellipsis={{ rows: 2 }}
					>
						{data.commodity}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.crop),
			className: 'offers-column small',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--medium text--centered">{data.cropYear}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.contractType),
			className: 'offers-column small',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--medium text--centered">
						{data.contractType}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.destination),
			className: 'offers-column large',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--medium ellipsis"
						title={data.location}
						ellipsis={{ rows: 1 }}
					>
						{data.location}
					</Paragraph>
				</GenericTable.Column>
			),
		},

		{
			title: translate(translationsScope.quantity),
			className: 'offers-column medium',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--medium text--centered">
						{customFormat(
							data.quantity,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						)}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.basis),
			className: 'offers-column small',
			render: (data) => (
				<GenericTable.Column>
					<Popover
						content={<PopoverContent elements={getBasisElements(data)} />}
						trigger="hover"
					>
						<p className="text text--medium text--centered">
							{currencyFormat(data.basis.net)}
						</p>
					</Popover>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.futures),
			className: 'offers-column small',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--medium text--centered">
						{currencyFormat(data.price.futures)}
					</p>
				</GenericTable.Column>
			),
		},

		{
			title: translate(translationsScope.cash),
			className: 'offers-column small',
			render: (data) => (
				<GenericTable.Column>
					<Popover
						content={<PopoverContent elements={getPriceElements(data)} />}
						trigger="hover"
					>
						<p className="text text--medium text--centered">
							{currencyFormat(data.price.cash)}
						</p>
					</Popover>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.futuresMonth),
			className: 'offers-column small',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--medium text--centered">{data.futureMonth}</p>
				</GenericTable.Column>
			),
		},
	];

	const selectedRowKeys = selectedRowsData.map(({ id }) => id);

	const onChangeSelection = (_selectedIDs, selectedRowsData) => {
		dispatch(actions.setSelectedRows(selectedRowsData));
	};

	return (
		<GenericTable.FormTableWrapper
			name={name}
			id={id}
			hasSelectedRows={hasSelectedRows}
		>
			<GenericTable.Table
				className="orphans-table"
				otherClassName="table-container"
				columns={columns}
				data={listData}
				loading={orphanTableLoading}
				pagination={false}
				rowSelection={{
					onChange: onChangeSelection,
					columnTitle: listData.length < 0 && undefined,
					preserveSelectedRowKeys: false,
					fixed: true,
					selectedRowKeys,
				}}
			/>
		</GenericTable.FormTableWrapper>
	);
};
