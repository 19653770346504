import { HubConnection } from '@microsoft/signalr';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import { ActionType, PushNotification } from 'app/containers/HubSaga/types';
import { actions } from 'app/containers/Offers/slice';
import { OfferInfoDto } from 'types/Offer';
import { throttledDispatcher } from 'utils/store-util';

import { hubSubscriptionChannels, THROTTLE_TIME } from '../constants';

export function offersHandler(
	connection: HubConnection,
	dispatch: Dispatch<AnyAction>,
) {
	const throttledUpdate = throttledDispatcher<OfferInfoDto>(
		dispatch,
		actions.updateOffersList,
		THROTTLE_TIME,
	);
	const throttledLoad = throttledDispatcher<void>(
		dispatch,
		() => actions.loadOffersList(true),
		THROTTLE_TIME,
	);

	connection.on(
		hubSubscriptionChannels.offerUpdates,
		(pushNotification: PushNotification<OfferInfoDto>) => {
			if (pushNotification.action === ActionType.Update) {
				throttledUpdate(pushNotification.data);
			} else {
				// Refresh the whole table
				throttledLoad();
			}
		},
	);
}
