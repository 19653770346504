import { generateInputField } from 'utils/GenericFormInputs/generic-form-inputs';

import { CurrentGroupedLocation, LocationField } from './types';
import { useLocationFieldFactory } from './useLocationFieldFactory';

export const useManualEditSchema = (
	currentLocation: CurrentGroupedLocation | null = null,
): LocationField[] => {
	const locationGeneralFields = useLocationFieldFactory(currentLocation);

	let fields: LocationField[] = [
		{
			...locationGeneralFields.contractLocationName,
			name: 'contractLocationNameManual',
			initialValue: currentLocation?.contractLocationName,
			render: generateInputField,
		},
		{
			...locationGeneralFields.contractLocationNumber,
			name: 'contractLocationNumberManual',
			initialValue: currentLocation?.contractLocationNumber,
			render: generateInputField,
		},
		{
			...locationGeneralFields.destinationLocationName,
			name: 'destinationLocationNameManual',
			initialValue: currentLocation?.destinationLocationName,
			render: generateInputField,
		},
		{
			...locationGeneralFields.destinationLocationNumber,
			name: 'destinationLocationNumberManual',
			initialValue: currentLocation?.destinationLocationNumber,
			render: generateInputField,
		},
	];

	fields = [
		...fields,
		locationGeneralFields.regionId,
		{ ...locationGeneralFields.manualEditMode, initialValue: true },
	];

	return fields;
};
