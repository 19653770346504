import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

export const useTranslations = () => {
	const { t: translate } = useTranslation();

	const scope = translations.app.containers.Contracts.components.Modals;

	const sharedScope = translations.app.containers.Transactions.Modals;
	return {
		validations: {
			number: translate(sharedScope.validations.number),
			maxLength10: translate(sharedScope.validations.maxlength10),
			required: translate(sharedScope.validations.required),
			createContractMinQuantityValidation: translate(
				scope.validations.createContractMinQuantityValidation,
			),
			futuresPriceFormatError: translate(
				scope.validations.futuresPriceFormatError,
			),
			basisFormatError: translate(scope.validations.basisFormatError),
			futuresPriceFieldsNeeded: translate(
				scope.validations.futuresPriceFieldsNeeded,
			),
			nonNegativeMsg: translate(scope.validations.nonNegativeValues),
			futuresPriceQuarterMsg: translate(
				translations.app.containers.Transactions.components.Futures
					.futuresPriceQuarterMsg,
			),
		},
		common: {
			success: translate(translations.app.commons.success.description),
		},
	};
};
