import React from 'react';

import { BellNotifications } from 'app/components/BellNotifications';
import { ProfileMenu } from 'app/components/ProfileMenu';
import { SendCustomNotification } from 'app/components/SendCustomNotification';
import { FeatureFlag } from 'types/FeatureFlags';

enum TopBarItemType {
	divider = 'divider',
	component = 'component',
}

interface TopBarItem {
	type: TopBarItemType;
	component?: React.ReactNode;
	featureFlag?: FeatureFlag;
}

export const topBarItems: TopBarItem[] = [
	{
		type: TopBarItemType.component,
		component: <BellNotifications />,
		featureFlag: FeatureFlag.enableNotifications,
	},
	{
		type: TopBarItemType.component,
		component: <SendCustomNotification />,
		featureFlag: FeatureFlag.enableNotifications,
	},
	{
		type: TopBarItemType.divider,
	},
	{
		type: TopBarItemType.component,
		component: <ProfileMenu />,
	},
];
