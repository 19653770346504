import { PayloadAction } from '@reduxjs/toolkit';

import {
	NotificationAlertTone,
	NotificationGroupDto,
} from 'app/containers/GlobalSaga/types';
import { GenericError } from 'types/GenericError';
import { GenericItem } from 'types/GenericItem';
import { GroupMemberDto } from 'types/Notifications';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { makeNotificationGroupMemberFromDto } from 'utils/notifications';

import {
	Modal,
	NotificationAlertTonePayload,
	NotificationEvent,
	NotificationEventPayload,
	NotificationEventStatus,
	NotificationGroupPayload,
	NotificationSettingsScope,
	NotificationSettingsSectionState,
	UserNotificationSubscriptions,
	View,
} from './types';

// The initial state of the Settings container
export const initialState: NotificationSettingsSectionState = {
	currentModal: null,
	currentView: View.List,

	tenantPreferences: {
		data: [],
		loading: false,
		error: null,
	},
	alertTones: {
		data: [],
		loading: false,
		error: null,
	},
	userPreferences: {
		data: [],
		loading: false,
		error: null,
	},
	assignedGroups: {
		data: [],
		loading: false,
		error: null,
	},
	// Employee Groups
	employeeGroups: {
		data: [],
		loading: false,
		error: null,
	},
	activeEmployeeGroup: {
		data: null,
		loading: false,
		error: null,
	},
	editEmployeeGroupDetails: {
		data: null,
		loading: false,
		error: null,
	},
	employeeGroupMembers: {
		data: [],
		loading: false,
		error: null,
	},
	employeeGroupNonMembers: {
		data: [],
		loading: false,
		error: null,
	},
	// Customer Groups
	activeCustomerGroup: {
		data: null,
		loading: false,
		error: null,
	},
	editCustomerGroupDetails: {
		data: null,
		loading: false,
		error: null,
	},
	customerGroupMembers: {
		data: [],
		loading: false,
		error: null,
	},
	customerGroupNonMembers: {
		data: [],
		loading: false,
		error: null,
	},
};

const notificationSettingsSlice = createSlice({
	name: 'notificationSettings',
	initialState,
	reducers: {
		setCurrentModal(state, action: PayloadAction<Modal | null>) {
			state.currentModal = action.payload;
		},
		setCurrentView(state, action: PayloadAction<View>) {
			state.currentView = action.payload;
		},

		// Administrator notification settings
		getTenantPreferences(state) {
			state.tenantPreferences = {
				...state.tenantPreferences,
				loading: true,
				error: null,
			};
		},
		tenantPreferencesLoaded(state, action: PayloadAction<NotificationEvent[]>) {
			state.tenantPreferences = {
				...state.tenantPreferences,
				data: action.payload.map((item) => ({
					...item,
					scope: NotificationSettingsScope.tenant,
				})),
				loading: false,
				error: null,
			};
		},
		tenantPreferencesError(state, action: PayloadAction<GenericError>) {
			state.tenantPreferences = {
				...state.tenantPreferences,
				loading: false,
				error: action.payload,
			};
		},

		setTenantPreferenceStatus(
			state,
			action: PayloadAction<NotificationEventPayload>,
		) {
			state.tenantPreferences = {
				...state.tenantPreferences,
				loading: true,
				error: null,
			};
		},

		setTenantPreferenceStatusError(state, action: PayloadAction<GenericError>) {
			state.tenantPreferences = {
				...state.tenantPreferences,
				loading: false,
				error: action.payload,
			};
		},

		// User notification settings
		getUserPreferences(state) {
			state.userPreferences = {
				...state.userPreferences,
				loading: true,
				error: null,
			};
		},
		userPreferencesLoaded(
			state,
			action: PayloadAction<UserNotificationSubscriptions>,
		) {
			const data: NotificationEvent[] = action.payload.userSubscriptions.map(
				(item) => {
					const tenantPreference = action.payload.tenantSettings.find(
						(event) => event.eventType === item.eventType,
					);
					if (!tenantPreference) return item;

					return {
						...item,
						scope: NotificationSettingsScope.personal,
						[NotificationEventStatus.IsInAppEnabled]:
							tenantPreference[NotificationEventStatus.IsInAppEnabled] === false
								? undefined
								: item[NotificationEventStatus.IsInAppEnabled],
						[NotificationEventStatus.IsEmailEnabled]:
							tenantPreference[NotificationEventStatus.IsEmailEnabled] === false
								? undefined
								: item[NotificationEventStatus.IsEmailEnabled],
						[NotificationEventStatus.IsSmsEnabled]:
							tenantPreference[NotificationEventStatus.IsSmsEnabled] === false
								? undefined
								: item[NotificationEventStatus.IsSmsEnabled],
						[NotificationEventStatus.IsDailySummaryEnabled]:
							tenantPreference[
								NotificationEventStatus.IsDailySummaryEnabled
							] === false
								? undefined
								: item[NotificationEventStatus.IsDailySummaryEnabled],
					};
				},
			);
			state.userPreferences = {
				...state.userPreferences,
				data,
				loading: false,
				error: null,
			};
		},
		setUserPreferenceStatus(
			state,
			action: PayloadAction<NotificationEventPayload>,
		) {
			state.userPreferences = {
				...state.userPreferences,
				loading: true,
				error: null,
			};
		},
		userPreferencesError(state, action: PayloadAction<GenericError>) {
			state.userPreferences = {
				...state.userPreferences,
				loading: false,
				error: action.payload,
			};
		},

		getUserNotificationsAlertTones(state) {
			state.alertTones = {
				...state.alertTones,
				loading: true,
			};
		},
		userNotificationAlertTonesLoaded(
			state,
			action: PayloadAction<NotificationAlertTone[]>,
		) {
			state.alertTones = {
				...state.alertTones,
				data: action.payload,
				loading: false,
			};
		},
		setUserNotificationsAlertTone(
			state,
			action: PayloadAction<NotificationAlertTonePayload>,
		) {
			state.userPreferences = {
				...state.userPreferences,
				loading: true,
				error: null,
			};
		},

		getUserAssignedGroups(state) {
			state.assignedGroups = {
				...state.assignedGroups,
				loading: true,
				error: null,
			};
		},
		userAssignedGroupsLoaded(state, action: PayloadAction<GenericItem[]>) {
			state.assignedGroups = {
				...state.assignedGroups,
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		userAssignedGroupsError(state, action: PayloadAction<GenericError>) {
			state.assignedGroups = {
				...state.assignedGroups,
				loading: false,
				error: action.payload,
			};
		},

		// Employee Groups Settings
		loadEmployeeGroups(state) {
			state.employeeGroups = {
				...state.employeeGroups,
				loading: true,
				error: null,
			};
		},
		employeeGroupsLoaded(state, action: PayloadAction<NotificationGroupDto[]>) {
			state.employeeGroups = {
				...state.employeeGroups,
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		employeeGroupsError(state, action: PayloadAction<GenericError>) {
			state.employeeGroups = {
				...state.employeeGroups,
				error: action.payload,
				loading: false,
			};
		},
		loadEmployeeGroupMembers(state, action: PayloadAction<string>) {
			state.employeeGroupMembers = {
				...state.employeeGroupMembers,
				loading: true,
				error: null,
			};
		},
		employeeGroupMembersLoaded(state, action: PayloadAction<GroupMemberDto[]>) {
			state.employeeGroupMembers = {
				...state.employeeGroupMembers,
				data: action.payload.map((item) =>
					makeNotificationGroupMemberFromDto(item),
				),
				loading: false,
				error: null,
			};
		},
		employeeGroupMembersError(state, action: PayloadAction<GenericError>) {
			state.employeeGroupMembers = {
				...state.employeeGroupMembers,
				error: action.payload,
				loading: false,
			};
		},

		loadEmployeeGroupNonMembers(
			state,
			action: PayloadAction<string | undefined>,
		) {
			state.employeeGroupNonMembers = {
				...state.employeeGroupNonMembers,
				loading: true,
				error: null,
			};
		},
		employeeGroupNonMembersLoaded(
			state,
			action: PayloadAction<GroupMemberDto[]>,
		) {
			state.employeeGroupNonMembers = {
				...state.employeeGroupNonMembers,
				data: action.payload.map((item) =>
					makeNotificationGroupMemberFromDto(item),
				),
				loading: false,
				error: null,
			};
		},
		employeeGroupNonMembersError(state, action: PayloadAction<GenericError>) {
			state.employeeGroupNonMembers = {
				...state.employeeGroupNonMembers,
				error: action.payload,
				loading: false,
			};
		},

		createEmployeeGroup(
			state,
			action: PayloadAction<NotificationGroupPayload>,
		) {
			state.activeEmployeeGroup = {
				...state.activeEmployeeGroup,
				loading: true,
				error: null,
			};
		},
		updateEmployeeGroup(
			state,
			action: PayloadAction<NotificationGroupPayload>,
		) {
			state.editEmployeeGroupDetails = {
				...state.editEmployeeGroupDetails,
				loading: true,
				error: null,
			};
		},
		updateEmployeeGroupError(state, action: PayloadAction<GenericError>) {
			state.editEmployeeGroupDetails = {
				...state.editEmployeeGroupDetails,
				error: action.payload,
				loading: false,
			};
		},

		deleteEmployeeGroup(state, action: PayloadAction<string>) {
			state.activeEmployeeGroup = {
				...state.activeEmployeeGroup,
				loading: true,
				error: null,
			};
		},
		deleteEmployeeGroupError(state, action: PayloadAction<GenericError>) {
			state.activeEmployeeGroup = {
				...state.activeEmployeeGroup,
				error: action.payload,
				loading: false,
			};
		},

		loadEmployeeGroupDetails(state, action: PayloadAction<string>) {
			state.editEmployeeGroupDetails = {
				...state.editEmployeeGroupDetails,
				loading: true,
				error: null,
			};
		},

		employeeGroupDetailsLoaded(
			state,
			action: PayloadAction<NotificationGroupDto>,
		) {
			state.editEmployeeGroupDetails = {
				...state.editEmployeeGroupDetails,
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		employeeGroupDetailsError(state, action: PayloadAction<GenericError>) {
			state.editEmployeeGroupDetails = {
				...state.editEmployeeGroupDetails,
				error: action.payload,
				loading: false,
			};
		},

		setActiveEmployeeGroup(state, action: PayloadAction<NotificationGroupDto>) {
			state.activeEmployeeGroup = {
				...state.activeEmployeeGroup,
				data: action.payload,
			};
		},

		clearEmployeeGroupState(state, action: PayloadAction<void>) {
			state.activeEmployeeGroup = {
				data: null,
				loading: false,
				error: null,
			};
			state.editEmployeeGroupDetails = {
				data: null,
				loading: false,
				error: null,
			};
			state.employeeGroupMembers = {
				data: [],
				loading: false,
				error: null,
			};
			state.employeeGroupNonMembers = {
				data: [],
				loading: false,
				error: null,
			};
		},

		// Customer Groups Settings
		loadCustomerGroupMembers(state, action: PayloadAction<string>) {
			state.customerGroupMembers = {
				...state.customerGroupMembers,
				loading: true,
				error: null,
			};
		},
		customerGroupMembersLoaded(state, action: PayloadAction<GroupMemberDto[]>) {
			state.customerGroupMembers = {
				...state.customerGroupMembers,
				data: action.payload.map((item) =>
					makeNotificationGroupMemberFromDto(item),
				),
				loading: false,
				error: null,
			};
		},
		customerGroupMembersError(state, action: PayloadAction<GenericError>) {
			state.customerGroupMembers = {
				...state.customerGroupMembers,
				error: action.payload,
				loading: false,
			};
		},

		loadCustomerGroupNonMembers(
			state,
			action: PayloadAction<string | undefined>,
		) {
			state.customerGroupNonMembers = {
				...state.customerGroupNonMembers,
				loading: true,
				error: null,
			};
		},
		customerGroupNonMembersLoaded(
			state,
			action: PayloadAction<GroupMemberDto[]>,
		) {
			state.customerGroupNonMembers = {
				...state.customerGroupNonMembers,
				data: action.payload.map((item) =>
					makeNotificationGroupMemberFromDto(item),
				),
				loading: false,
				error: null,
			};
		},
		customerGroupNonMembersError(state, action: PayloadAction<GenericError>) {
			state.customerGroupNonMembers = {
				...state.customerGroupNonMembers,
				error: action.payload,
				loading: false,
			};
		},

		createCustomerGroup(
			state,
			action: PayloadAction<NotificationGroupPayload>,
		) {
			state.activeCustomerGroup = {
				...state.activeCustomerGroup,
				loading: true,
				error: null,
			};
		},
		updateCustomerGroup(
			state,
			action: PayloadAction<NotificationGroupPayload>,
		) {
			state.editCustomerGroupDetails = {
				...state.editCustomerGroupDetails,
				loading: true,
				error: null,
			};
		},
		updateCustomerGroupError(state, action: PayloadAction<GenericError>) {
			state.editCustomerGroupDetails = {
				...state.editCustomerGroupDetails,
				error: action.payload,
				loading: false,
			};
		},

		deleteCustomerGroup(state, action: PayloadAction<string>) {
			state.activeCustomerGroup = {
				...state.activeCustomerGroup,
				loading: true,
				error: null,
			};
		},
		deleteCustomerGroupError(state, action: PayloadAction<GenericError>) {
			state.activeCustomerGroup = {
				...state.activeCustomerGroup,
				error: action.payload,
				loading: false,
			};
		},

		loadCustomerGroupDetails(state, action: PayloadAction<string>) {
			state.editCustomerGroupDetails = {
				...state.editCustomerGroupDetails,
				loading: true,
				error: null,
			};
		},

		customerGroupDetailsLoaded(
			state,
			action: PayloadAction<NotificationGroupDto>,
		) {
			state.editCustomerGroupDetails = {
				...state.editCustomerGroupDetails,
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		customerGroupDetailsError(state, action: PayloadAction<GenericError>) {
			state.editCustomerGroupDetails = {
				...state.editCustomerGroupDetails,
				error: action.payload,
				loading: false,
			};
		},

		setActiveCustomerGroup(state, action: PayloadAction<NotificationGroupDto>) {
			state.activeCustomerGroup = {
				...state.activeCustomerGroup,
				data: action.payload,
			};
		},

		clearCustomerGroupState(state, action: PayloadAction<void>) {
			state.activeCustomerGroup = {
				data: null,
				loading: false,
				error: null,
			};
			state.editCustomerGroupDetails = {
				data: null,
				loading: false,
				error: null,
			};
			state.customerGroupMembers = {
				data: [],
				loading: false,
				error: null,
			};
			state.customerGroupNonMembers = {
				data: [],
				loading: false,
				error: null,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = notificationSettingsSlice;
