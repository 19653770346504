import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { selectEmployeeNotificationGroups } from 'app/containers/GlobalSaga/selectors';
import { actions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';

export const Groups = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const msg = translations.app.containers.Transactions.components.Groups;

	const groupList = useSelector(selectEmployeeNotificationGroups);

	useEffect(() => {
		dispatch(actions.loadEmployeeNotificationGroupList());
	}, []);

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="groups"
			data-testid="groups-form-item"
		>
			<Dropdown
				key="groups"
				placeholder={translate(msg.placeholder)}
				options={mapToLabelValue(groupList)}
				mode="multiple"
			/>
		</GenericForm.FormItem>
	);
};
