import './style.scss';

import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { selectActiveOffer } from '../../../selectors';
import { actions } from '../../../slice';

export const BookModal = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const translationsScope =
		translations.app.containers.Offers.components.BookOffer;

	const activeOffer: any = useSelector(selectActiveOffer);

	const handleConfirmation = () => {
		activeOffer && dispatch(actions.bookOffer(activeOffer));
		dispatch(actions.setCurrentModal(null));
	};

	const handleCancel = () => {
		dispatch(actions.setCurrentModal(null));
	};

	return (
		<Modal
			maskClosable={false}
			className="orders-book-modal"
			title={translate(translationsScope.bookModalTitle)}
			visible={true}
			onOk={handleConfirmation}
			onCancel={handleCancel}
			footer={[
				<Button
					key="back"
					onClick={handleCancel}
					className="return-button"
					data-testId="return-button"
				>
					{translate(translationsScope.cancel)}
				</Button>,
				<Button
					key="submit"
					onClick={handleConfirmation}
					className="cancel-button"
					data-testId="confirm-button"
					type="primary"
				>
					{translate(translationsScope.confirm)}
				</Button>,
			]}
		>
			{activeOffer && translate(translationsScope.bookMessage)}
		</Modal>
	);
};
