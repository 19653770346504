/**
 *
 * ContractTypePill
 *
 */
import './style.scss';

import classNames from 'classnames';
import React, { CSSProperties, memo } from 'react';

import { IsSellRoundPill } from '../IsSellRoundPill';

interface Props {
	contractType: string | null;
	isSell: boolean;
	style?: CSSProperties;
	className?: string;
}

export const ContractTypePill = memo(function ContractTypePill({
	contractType,
	isSell,
	style,
	className,
}: Props) {
	return (
		<div
			className={classNames('contract-type-pill__wrapper', className)}
			style={style}
		>
			<span
				className={classNames(
					'contract-type-pill',
					{
						'contract-type-pill--hta': contractType === 'HTA',
					},
					{
						'contract-type-pill--flat':
							contractType === 'Flat Price' || contractType === 'FlatPrice',
					},
					{
						'contract-type-pill--basis': contractType === 'Basis',
					},
					{
						'contract-type-pill--ntc':
							contractType === 'NTC' || contractType === 'NTC 1',
					},
				)}
				data-testid={`contract-type-${contractType?.toLocaleLowerCase()}`}
			>
				{contractType}
			</span>
			<IsSellRoundPill isSell={isSell} />
		</div>
	);
});
