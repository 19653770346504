import { createSlice } from 'utils/@reduxjs/toolkit';

import { OrderEntrySectionState } from './types';

/**
 * @internal exported for automated tests
 */
export const initialState: OrderEntrySectionState = {
	orderEntryUpdate: {
		data: null,
		error: null,
		loading: false,
	},
};

const OrderEntrySlice = createSlice({
	name: 'orderEntry',
	initialState,
	reducers: {
		setOrderEntryError(state, action) {
			state.orderEntryUpdate = {
				...state.orderEntryUpdate,
				error: action?.payload,
				loading: false,
			};
		},

		setOrderEntryRequest(state, action) {
			state.orderEntryUpdate = {
				...state.orderEntryUpdate,
				data: null,
				error: null,
				loading: true,
			};
		},

		setOrderEntrySuccess(state, action) {
			state.orderEntryUpdate = {
				...state.orderEntryUpdate,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = OrderEntrySlice;
