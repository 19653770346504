import React from 'react';
import { useAbac } from 'react-abac';

import { Permission } from 'types/Authorization';

import { NotificationSettingTab, NotificationSettingTabs } from '../types';
import { CustomerGroupNotificationSettings } from '../View/CustomerGroupNotificationSettings';
import { EmployeeGroupNotificationSettings } from '../View/EmployeeGroupNotificationSettings';
import { PersonalNotificationSettings } from '../View/PersonalNotificationSettings';
import { TenantNotificationSettings } from '../View/TenantNotificationSettings';

export const useTabItems = () => {
	const { userHasPermissions } = useAbac();

	const tabs: NotificationSettingTab[] = [
		{
			name: NotificationSettingTabs.personal,
			content: <PersonalNotificationSettings />,
		},
		{
			name: NotificationSettingTabs.employeeGroup,
			content: <EmployeeGroupNotificationSettings />,
			permission: Permission.CONTRACTSERVICE_SETTINGS_ADMIN,
		},
		{
			name: NotificationSettingTabs.customerGroup,
			content: <CustomerGroupNotificationSettings />,
			permission: Permission.CONTRACTSERVICE_SETTINGS_ADMIN,
		},
		{
			name: NotificationSettingTabs.tenant,
			content: <TenantNotificationSettings />,
			permission: Permission.CONTRACTSERVICE_SETTINGS_ADMIN,
		},
	];

	return tabs.filter(
		(item) => !item.permission || userHasPermissions(item.permission),
	);
};
