import { PayloadAction } from '@reduxjs/toolkit';

import { FutureMonth } from 'types/FutureMonth';
import { GenericError } from 'types/GenericError';
import { Trade, TradeBook } from 'types/TradeBook';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { mapToLabelValue } from 'utils/helpers';

import {
	IntegrateToErpDataType,
	TradeBookFilters,
	TradeBookState,
} from './types';

export const initialState: TradeBookState = {
	selectedFilters: {
		commodityId: [],
		orderNo: null,
		futuresMonth: [],
		accountNo: [],
	},

	tradeBookList: {
		data: { buys: [], sells: [] },
		loading: false,
		total: 0,
		erpErrorMessage: null,
		error: null,
	},
	integrateToErp: {
		loading: false,
		error: null,
	},

	selectedBuyRows: [],

	selectedSellRows: [],

	futureMonths: {
		data: [],
		error: null,
		loading: false,
	},
};

const contractsSlice = createSlice({
	name: 'tradeBook',
	initialState,
	reducers: {
		setSelectedFilters(state, action: PayloadAction<TradeBookFilters>) {
			state.selectedFilters = {
				...state.selectedFilters,
				...action.payload,
			};
		},

		loadTradeBookList(state) {
			state.tradeBookList = {
				...state.tradeBookList,
				data: { buys: [], sells: [] },
				loading: true,
				error: null,
			};
		},
		tradeBookListLoaded(
			state,
			action: PayloadAction<{
				data: TradeBook;
				total: number;
				erpErrorMessage: string | null;
			}>,
		) {
			const { data, total, erpErrorMessage } = action.payload;
			state.tradeBookList = {
				...state.tradeBookList,
				data,
				total,
				erpErrorMessage,
				loading: false,
				error: null,
			};
		},

		tradeBookListError(state, action: PayloadAction<GenericError>) {
			state.tradeBookList = {
				...state.tradeBookList,
				error: action.payload,
				loading: false,
			};
		},

		integrateToErpRequest(
			state,
			action: PayloadAction<IntegrateToErpDataType[]>,
		) {
			state.integrateToErp = {
				...state.integrateToErp,
				loading: true,
				error: null,
			};
		},
		integrateToErpSuccess(state) {
			state.integrateToErp = {
				...state.integrateToErp,
				loading: false,
				error: null,
			};
		},

		integrateToErpError(state, action: PayloadAction<GenericError>) {
			state.integrateToErp = {
				...state.integrateToErp,
				error: action.payload,
				loading: false,
			};
		},

		setSelectedBuyRows(state, action: PayloadAction<Trade[]>) {
			state.selectedBuyRows = action.payload;
		},

		setSelectedSellRows(state, action: PayloadAction<Trade[]>) {
			state.selectedSellRows = action.payload;
		},

		setSelectedBuyRowsAsEmpty(state) {
			state.selectedBuyRows = [];
		},

		setSelectedSellRowsAsEmpty(state) {
			state.selectedSellRows = [];
		},
		getFuturesMonthsError(state, action: PayloadAction<GenericError>) {
			state.futureMonths = {
				...state.futureMonths,
				error: action.payload,
				loading: false,
			};
		},

		getFuturesMonthsRequest(state) {
			state.futureMonths = {
				...state.futureMonths,
				data: [],
				error: null,
				loading: true,
			};
		},

		getFuturesMonthsSuccess(state, action: PayloadAction<FutureMonth[]>) {
			state.futureMonths = {
				...state.futureMonths,
				data: mapToLabelValue(
					action?.payload.map((item) => ({
						...item,
						highlight: item.isExpired,
					})),
				),
				error: null,
				loading: false,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = contractsSlice;
