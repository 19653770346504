import './styles.scss';

import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { RegionSaga } from './saga';
import { selectAllRegionsLoading } from './selectors';
import { actions, reducer, sliceKey } from './slice';
import { REGIONS_DOMAIN } from './utils/helpers';
import RegionsForm from './Views/RegionsForm';

export const Regions = () => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: RegionSaga });
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const isLoading = useSelector(selectAllRegionsLoading);

	useEffect(() => {
		dispatch(actions.getAllRegionsRequest());
	}, []);

	if (isLoading) {
		return <Spin data-testid="spinner-loading" key="spinner" />;
	}

	return (
		<div className="regions" data-testid="regions-form">
			<p className="header">{translate(REGIONS_DOMAIN.header)}</p>
			<RegionsForm />
		</div>
	);
};
