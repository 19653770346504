import { takeLatest } from 'redux-saga/effects';

import { createEditRoleLimits } from './Sagas/create-edit-limits';
import { createEditRoleLocation } from './Sagas/create-edit-location-saga';
import {
	createEditUserRole,
	getUserRoleDetails,
} from './Sagas/create-edit-rol-saga';
import { deleteUserRole } from './Sagas/delete-user-roles-saga';
import { getUserRolesList } from './Sagas/user-roles-sagas';
import { userRolesActions } from './slice';

export function* userRolesSagas() {
	yield takeLatest(userRolesActions.loadUserRolesList.type, getUserRolesList);
	yield takeLatest(userRolesActions.deleteUserRole.type, deleteUserRole);
	yield takeLatest(userRolesActions.createUserRole.type, createEditUserRole);
	yield takeLatest(userRolesActions.loadEditUserRole.type, getUserRoleDetails);
	yield takeLatest(
		userRolesActions.createRoleLocation.type,
		createEditRoleLocation,
	);
	yield takeLatest(
		userRolesActions.createEditRoleLimits.type,
		createEditRoleLimits,
	);
}
