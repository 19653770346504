import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { Modal } from '../types';

const { reviewAndReleaseAcknowledgeAndFill } = apiEndpoints;

export function* getAcknowledgeAndFillSaga(
	action: PayloadAction<{ id: string }>,
) {
	const { id } = action.payload;

	const requestURL = yield new URL(
		`${apiRoutes.base}/${reviewAndReleaseAcknowledgeAndFill}/${id}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	if (!!responseData) {
		const data = {
			...responseData,
			id,
		};
		yield put(actions.getAcknowledgeAndFillSuccess(data));
		yield put(actions.setCurrentModal(Modal.AcknowledgeAndFill));
	} else if (!!responseError.detail) {
		yield put(actions.getAcknowledgeAndFillError(responseError));
	}
}

export function* setAcknowledgeAndFillSaga(action: PayloadAction<any>) {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${reviewAndReleaseAcknowledgeAndFill}`,
	);

	const orderData = action?.payload?.data;

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		orderData,
	);

	if (!!responseData) {
		yield put(actions.setAcknowledgeAndFillSuccess(responseData));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.loadReviewAndReleaseList());
		yield put(actions.setCurrentModal(null));
	} else if (!!responseError?.detail) {
		yield put(actions.setAcknowledgeAndFillError(responseError));
	}
}
