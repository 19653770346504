import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectEmployeeNotificationGroupsLoading } from 'app/containers/GlobalSaga/selectors';

import { selectEmployeeGroups } from '../selectors';
import { actions } from '../slice';
import { Modal, NotificationGroupListHookValue, View } from '../types';

export const useEmployeeGroupList = (): NotificationGroupListHookValue => {
	const dispatch = useDispatch();
	const groupList = useSelector(selectEmployeeGroups);
	const listLoading = useSelector(selectEmployeeNotificationGroupsLoading);

	const handleCreateGroup = () => {
		dispatch(actions.setCurrentView(View.Create));
		dispatch(actions.loadEmployeeGroupNonMembers());
	};
	const handleEditGroup = ({ id }) => {
		dispatch(actions.loadEmployeeGroupDetails(id));
		dispatch(actions.loadEmployeeGroupMembers(id));
		dispatch(actions.loadEmployeeGroupNonMembers(id));
	};
	const handleDeleteGroup = (rowData) => {
		dispatch(actions.setActiveEmployeeGroup(rowData));
		dispatch(actions.setCurrentModal(Modal.Delete));
	};

	useEffect(() => {
		dispatch(actions.loadEmployeeGroups());
	}, []);

	return {
		handleCreateGroup,
		handleEditGroup,
		handleDeleteGroup,
		groupList,
		listLoading,
	};
};
