import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as hubActions } from 'app/containers/HubSaga/slice';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { CancelModal } from './components/Modals/CancelModal';
import { EditModal } from './components/Modals/EditModal';
import SelectContractTypeModal from './components/Modals/SelectContractTypeModal';
import { Details } from './components/Views/Details';
import { List } from './components/Views/List';
import { preHedgeSaga } from './root-saga';
import {
	selectActivePreHedge,
	selectCurrentModal,
	selectCurrentView,
} from './selectors';
import { actions, reducer, sliceKey } from './slice';
import { Modal, PreHedgeViews } from './types';

export const PreHedge = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: preHedgeSaga });

	const dispatch = useDispatch();

	const currentModal = useSelector(selectCurrentModal);
	const currentView = useSelector(selectCurrentView);
	const preHedgeData = useSelector(selectActivePreHedge);

	useEffect(() => {
		return () => {
			dispatch(actions.setCurrentModal(null));
		};
	}, []);

	useEffect(() => {
		// Enable auto-refresh for prehedge tab
		dispatch(hubActions.subscribeToPreHedgeUpdates());

		return () => {
			dispatch(hubActions.unsubscribeFromPreHedgeUpdates());
		};
	}, []);

	return (
		<>
			{currentView === PreHedgeViews.details ? <Details /> : <List />}
			{currentModal === Modal.Edit && preHedgeData && <EditModal />}
			{currentModal === Modal.Cancel && <CancelModal />}
			{currentModal === Modal.ContractType && <SelectContractTypeModal />}
		</>
	);
};
