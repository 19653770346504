import './style.scss';

import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { FocusEventHandler, memo } from 'react';

interface Props {
	format?: string;
	placeholder?: string;
	disabled?: boolean;
	customDisabledDate?: (date: Moment) => boolean;
	value?: Moment | null;
	onChange?: (value: Moment | null) => void;
	onBlur?: FocusEventHandler<HTMLInputElement>;
}

export const Calendar = memo(function Calendar(props: Props) {
	const { customDisabledDate = beforeEndOfDay, ...otherProps } = props;

	return (
		<DatePicker
			{...otherProps}
			disabledDate={customDisabledDate}
			className="calendar__input"
		/>
	);
});

function beforeEndOfDay(date: Moment | null) {
	return Boolean(date && date < moment().endOf('day'));
}
