import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { fileRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { bidsheetUpload } = apiEndpoints;

export function* uploadBidsheet(action: PayloadAction<any>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${bidsheetUpload}`);
	const { data, config } = action.payload;

	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Post,
		true,
		data,
		config,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.bidsheetUploaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.clearBidsheetUpload());
		yield put(actions.bidsheetTemplateError(responseError));
	}
}
