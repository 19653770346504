import { formSchemaGenerator } from 'utils/GenericFormGenerators/generator';

import { WizardSchema, WizardTab } from './types';

export const wizardSchemaGenerator = (schema: WizardTab[]): WizardSchema => {
	let initialValues = {};
	const tabsElements: JSX.Element[][] = [];
	const tabsValidationSchemas: object[] = [];

	schema.forEach((singleTab) => {
		const tabSchema = formSchemaGenerator(singleTab.formDefinitions);

		initialValues = {
			...initialValues,
			...tabSchema.initialValues,
		};

		tabsElements.push(tabSchema.elements);
		tabsValidationSchemas.push(tabSchema.validationSchema);
	});

	return {
		initialValues,
		tabsElements,
		tabsValidationSchemas,
	};
};
