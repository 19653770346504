import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.settings || initialState;

export const selectActiveSection = createSelector(
	[selectDomain],
	(settingsState) => settingsState.activeSection,
);

export const selectLocationPermissionSelection = createSelector(
	[selectDomain],
	(settingsState) => settingsState.isLocationPermissionStateUpdated,
);
