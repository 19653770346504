/**
 *
 * BulkActions
 *
 */
import './style.scss';

import { BlockOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectOffersListLoading,
	selectSelectedRows,
} from 'app/containers/Offers/selectors';
import { actions } from 'app/containers/Offers/slice';
import { OffersModals } from 'app/containers/Offers/types';
import { translations } from 'locales/i18n';

interface Props {}

export const BulkActions = memo(function BulkActions(props: Props) {
	const scopeTranslations =
		translations.app.containers.Contracts.Offers.ListMenu;
	const { t: translate } = useTranslation();

	const dispatch = useDispatch();

	const selectedRowsData = useSelector(selectSelectedRows);

	const isLoading = useSelector(selectOffersListLoading);

	const validateAction = (restrictionName) =>
		selectedRowsData.length &&
		selectedRowsData.every(({ restrictions }) => restrictions[restrictionName]);

	const canBeBooked = validateAction('canBeBooked');

	const handleBook = () => {
		dispatch(actions.setCurrentModal(OffersModals.MultipleBook));
	};

	return (
		<div className="bulk-actions">
			{selectedRowsData.length !== 0 && (
				<span className="selection-count">
					{`${selectedRowsData.length}
          ${translate(scopeTranslations.selected)}`}
				</span>
			)}
			<Button
				disabled={!canBeBooked || isLoading}
				onClick={handleBook}
				type="primary"
				ghost
				data-testid="button-book"
			>
				<BlockOutlined />
				{translate(scopeTranslations.bookOffers)}
			</Button>
		</div>
	);
});
