import moment from 'moment';
import { useSelector } from 'react-redux';

import { TransactionTypesContracts } from 'app/containers/Contracts/types';
import {
	selectContractTypeList,
	selectDefaultLocationList,
	selectDefaultPageData,
	selectTransactionsList,
} from 'app/containers/GlobalSaga/selectors';
import { TransactionType } from 'app/containers/Offers/types';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import { CONTRACT_TYPES } from 'utils/constants';
import {
	createRangeCalendarOption,
	getActionType,
	mapToLabelValue,
} from 'utils/helpers';

export const useDefaultValues = (
	source = ContractTypeSource.CONTRACT as ContractTypeSource,
) => {
	const defaultValues = useSelector(selectDefaultPageData);

	const groupedLocation = useSelector(selectDefaultLocationList);
	const transactionsList = mapToLabelValue(useSelector(selectTransactionsList));
	const defaultTransaction =
		transactionsList?.find((transaction) => {
			let id = defaultValues?.transactionTypeId ?? null;
			if (source === ContractTypeSource.OFFER)
				return transaction.label === TransactionType.Offer;
			if (source === ContractTypeSource.CONTRACT) {
				if (id) {
					return transaction.value === id;
				} else {
					return transaction.label === TransactionTypesContracts.CashContract;
				}
			}
			return null;
		}) || transactionsList?.[0];
	const contractTypeList = mapToLabelValue(
		useSelector(selectContractTypeList(source)),
	);
	const defaultContract =
		contractTypeList.find((contract) => {
			let id = defaultValues?.contractTypeId ?? null;
			if (id) {
				return contract.value === id;
			} else {
				return contract.label === CONTRACT_TYPES.flatPrice;
			}
		}) || contractTypeList?.[0];

	const { value } = createRangeCalendarOption(
		defaultValues?.deliveryPeriod?.start,
		defaultValues?.deliveryPeriod?.end,
	);
	const selectedWindow = {
		value,
	};

	const defaultInitialValues = {
		transaction: defaultTransaction,
		contract: defaultContract,
		action: getActionType(defaultValues?.isSell),
		location: { value: defaultValues?.locationId || null },
		deliveryLocation: {
			value: groupedLocation?.id || null,
			defaultDestinationLocationId: defaultValues?.deliveryLocationId,
			regionIds: groupedLocation?.regionId,
		},
		commodity: { value: defaultValues?.commodityId || null },
		cropYear: defaultValues?.cropYear || null,
		deliveryDate: [
			moment(defaultValues?.deliveryPeriod?.start),
			moment(defaultValues?.deliveryPeriod?.end),
		],
		futuresMonth: { value: defaultValues?.futuresMonth || null },
		deliveryDateWindow: (defaultValues?.deliveryPeriod && selectedWindow) || {
			value: null,
		},
		deliveryDatesMode: {
			value: defaultValues?.isDeliveryDateCustom
				? DeliveryDateMode.Custom
				: DeliveryDateMode.Window,
		},
	};

	return defaultInitialValues;
};
