import { Button, Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

export const PopoverContent = ({
	createContractHandler,
	createOfferHandler,
	row,
	keyValue,
}) => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.CashbidsAndQuotes.Cashbids;

	return (
		<div className="create-new-entry__actions-container">
			<Button
				onClick={() =>
					createContractHandler && createContractHandler(keyValue, row)
				}
				size="small"
				type="text"
				className="create-offer__button"
			>
				{translate(translationsScope.createContract)}
			</Button>
			<Divider type="horizontal" className="actions__divider--margin" />
			<Button
				onClick={() =>
					createOfferHandler && createOfferHandler(keyValue, row, true)
				}
				size="small"
				type="text"
				className="create-offer__button"
			>
				{translate(translationsScope.createOffer)}
			</Button>
		</div>
	);
};
