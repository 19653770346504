import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DeleteServiceFee } from './Modals/DeleteServiceFee';
//import { DeleteServiceFee } from './Modals/DeleteServiceFee';
import { selectCurrentView } from './selectors';
import { serviceFeesActions } from './slice';
import { View } from './types';
import { CreateEditServiceFee } from './View/CreateEditServiceFee';
import { List } from './View/List';

export const ServiceFees = () => {
	const dispatch = useDispatch();
	const currentView = useSelector(selectCurrentView);

	useEffect(() => {
		dispatch(serviceFeesActions.setCurrentView(View.List));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{currentView === View.Create || currentView === View.Edit ? (
				<CreateEditServiceFee />
			) : (
				<List />
			)}
			{currentView === View.Delete && <DeleteServiceFee />}
		</>
	);
};
