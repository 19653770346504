/**
 *
 * InlineDot
 *
 */
import './style.scss';

import classNames from 'classnames';
import React, { memo } from 'react';

interface Props {
	comesFromAnOffer: boolean;
}

export const InlineDot = memo(function InlineDot(props: Props) {
	const { comesFromAnOffer } = props;

	return (
		<span
			className={classNames('inline-dot', {
				'inline-dot--offer': comesFromAnOffer,
				'inline-dot--user': !comesFromAnOffer,
			})}
			data-testid="inline-dot"
		/>
	);
});
