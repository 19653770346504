import React, { memo } from 'react';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { GenericOption } from 'types/GenericOption';
import { OrderEntry } from 'types/OrderEntry';
import { yearsToLabelValue } from 'utils/helpers';

import { getOrderEntryId } from '../utils/helpers';

interface Props {
	orderEntry: OrderEntry;
}

export const Mul = memo(function Mul({ orderEntry }: Props) {
	const orderEntryId = getOrderEntryId(orderEntry);
	let updatedOrderEntryItems;

	orderEntry?.items &&
		(updatedOrderEntryItems = ['None'].concat(orderEntry.items));
	updatedOrderEntryItems = [...new Set(updatedOrderEntryItems)];

	return (
		<GenericForm.FormItem
			data-testid={`${orderEntryId}-form-item`}
			label={orderEntry?.label}
			name={orderEntryId}
		>
			<Dropdown
				options={yearsToLabelValue(updatedOrderEntryItems) as GenericOption[]}
			/>
		</GenericForm.FormItem>
	);
});
