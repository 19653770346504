export enum Permission {
	CONTRACTSERVICE_ALLOW_CONTRACTNTC = 'ContractService.Allow.ContractNTC',
	CONTRACTSERVICE_BIDSHEET_UPLOAD = 'ContractService.Bidsheet.Upload',
	CONTRACTSERVICE_CASHSUMMARY_VIEW = 'ContractService.CashSummary.View',
	CONTRACTSERVICE_CONTRACT_ACTIVATEDNH = 'ContractService.Contract.ActivateDNH',
	CONTRACTSERVICE_CONTRACT_CANCEL = 'ContractService.Contract.Cancel',
	CONTRACTSERVICE_CONTRACT_OMS_ONLY = 'ContractService.Contract.OMSOnly',
	CONTRACTSERVICE_CONTRACT_ENABLE_FUTURES = 'ContractService.Contract.EnableFutures',
	CONTRACTSERVICE_CONTRACT_RESENDTOERP = 'ContractService.Contract.ResendToErp',
	CONTRACTSERVICE_USE_SALES_TRADING = 'ContractService.Contract.UseSalesTrading',
	CONTRACTSERVICE_CONTRACT_USECASHSETTLEMENT = 'ContractService.Contract.UseCashSettlement',
	CONTRACTSERVICE_CONTRACTBASIS_CREATE = 'ContractService.ContractBasis.Create',
	CONTRACTSERVICE_CONTRACTBASIS_EDITNONQUANTITY = 'ContractService.ContractBasis.EditNonQuantity',
	CONTRACTSERVICE_CONTRACTBASIS_EDITQUANTITY = 'ContractService.ContractBasis.EditQuantity',
	CONTRACTSERVICE_CONTRACTFLATPRICE_CREATE = 'ContractService.ContractFlatPrice.Create',
	CONTRACTSERVICE_CONTRACTFLATPRICE_EDITNONQUANTITY = 'ContractService.ContractFlatPrice.EditNonQuantity',
	CONTRACTSERVICE_CONTRACTFLATPRICE_EDITQUANTITY = 'ContractService.ContractFlatPrice.EditQuantity',
	CONTRACTSERVICE_CONTRACTHTA_CREATE = 'ContractService.ContractHTA.Create',
	CONTRACTSERVICE_CONTRACTHTA_EDITNONQUANTITY = 'ContractService.ContractHTA.EditNonQuantity',
	CONTRACTSERVICE_CONTRACTHTA_EDITQUANTITY = 'ContractService.ContractHTA.EditQuantity',
	CONTRACTSERVICE_CONTRACTNTC_CREATE = 'ContractService.ContractNTC.Create',
	CONTRACTSERVICE_CONTRACTNTC_EDITNONQUANTITY = 'ContractService.ContractNTC.EditNonQuantity',
	CONTRACTSERVICE_CONTRACTNTC_EDITQUANTITY = 'ContractService.ContractNTC.EditQuantity',
	CONTRACTSERVICE_LIVELEDGER_VIEW = 'ContractService.LiveLedger.View',
	CONTRACTSERVICE_ORIGINATOR_BIDS_QUOTES_VIEW = 'ContractService.originatorBidsQuotes.View',
	CONTRACTSERVICE_ORIGINATOR_BIDS_QUOTES_TENANT_VIEW = 'ContractService.originatorBidsQuotes.Tenant.View',
	CONTRACTSERVICE_OFFERBASIS_CREATE = 'ContractService.OfferBasis.Create',
	CONTRACTSERVICE_OFFERBASIS_EDIT = 'ContractService.OfferBasis.Edit',
	CONTRACTSERVICE_OFFERHTA_CREATE = 'ContractService.OfferHTA.Create',
	CONTRACTSERVICE_OFFERHTA_EDIT = 'ContractService.OfferHTA.Edit',
	CONTRACTSERVICE_OFFERFLATPRICE_CREATE = 'ContractService.OfferFlatPrice.Create',
	CONTRACTSERVICE_OFFERFLATPRICE_EDIT = 'ContractService.OfferFlatPrice.Edit',
	CONTRACTSERVICE_REVIEWANDRELEASE_VIEW = 'ContractService.ReviewAndRelease.View',
	IDENTITYSERVICE_SECURE_ADMIN = 'IdentityService.Secure.Admin',
	CONTRACTSERVICE_SETTINGS_ADMIN = 'ContractService.Settings.Admin',
	CONTRACTSERVICE_TRANSACTIONTYPE_USEADJUSTMENTS = 'ContractService.TransactionType.UseAdjustments',
	CONTRACTSERVICE_CUSTOMER_MOBILEAPPENABLE = 'ContractService.Customer.MobileAppEnable',
	GENERALAPP_USER = 'GeneralApp.User',
	CONTRACTSERVICE_FUTURESERP_VIEW = 'ContractService.FuturesERP.View',
	CONTRACTSERVICE_TRADEBOOK_VIEW = 'ContractService.TradeBook.View',
	CONTRACTSERVICE_FUTUREFIRST_VIEW = 'ContractService.FutureFirst.View',
}
