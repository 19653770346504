import React from 'react';
import { useSelector } from 'react-redux';

import Yup from 'yupCustom';

import { ContractIntegrationSwitch } from '../components/ContractIntegrationSwitch';
import { selectERPIntegration } from '../selectors';
import { SWITCH_FORM_ITEMS } from '../types';

export const useContractIntegrationSwitchFormSchema = () => {
	const erpIntegration = useSelector(selectERPIntegration);

	return {
		initialValues: {
			[SWITCH_FORM_ITEMS.ERPIntegration]:
				erpIntegration?.[SWITCH_FORM_ITEMS.ERPIntegration],
		},
		validationSchema: Yup.object().shape({
			[SWITCH_FORM_ITEMS.ERPIntegration]: Yup.boolean(),
		}),
		elements: [<ContractIntegrationSwitch />],
	};
};
