import { Input } from 'antd';
import React, { memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';
import { preventWheelChange, preventWheelEvent } from 'utils/util';

import { SourceContext } from '../..';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';

interface Props {
	disabled?: boolean;
}

export const NetFutures = memo(function NetFutures(props: Props) {
	const { disabled = true } = props;
	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.NetFutures;

	const sourceName = useContext(SourceContext);
	const { manageTotalPriceCall } = useHandleBusinessRules(sourceName);

	useEffect(() => {
		manageTotalPriceCall();
	}, []);

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="netFutures"
			data-testid="netFutures-form-item"
		>
			<Input
				key="netFutures"
				placeholder={translate(msg.placeholder)}
				type="number"
				onWheel={preventWheelChange}
				onFocus={preventWheelEvent}
				disabled={disabled}
			/>
		</GenericForm.FormItem>
	);
});
