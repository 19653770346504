import './style.scss';

import { Col, Divider, Row } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import React, { ComponentProps, memo } from 'react';
import * as Yup from 'yup';

import { FORM_HEADER } from 'app/containers/Settings/types';
import { FieldDefinition } from 'types/FieldDefinition';
import { GenericOption } from 'types/GenericOption';
import {
	FormSchema,
	formSchemaGenerator,
} from 'utils/GenericFormGenerators/generator';

import { MultiColumnGrid } from './components/MultiColumnGrid';

interface Props {
	footerButtons?: React.ReactNode;
	formDefinition: FieldDefinition[];
	handleSubmit: ComponentProps<typeof Formik>['onSubmit'];
	header?: JSX.Element;
	secondTabSectionsList?: GenericOption[];
}

export const GenereicFormPlane = memo(function GenereicFormPlane({
	footerButtons,
	formDefinition,
	handleSubmit,
	header,
	secondTabSectionsList = [],
}: Props) {
	const { initialValues, validationSchema, elements }: FormSchema =
		formSchemaGenerator(formDefinition);

	const formatedValidationSchema = Yup.object().shape(validationSchema);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={formatedValidationSchema}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{() => {
				return (
					<>
						<Form
							labelCol={{ span: 3 }}
							wrapperCol={{ span: 9 }}
							className="generic-view-accordion__form"
						>
							<div className="generic-view-accordion__header">
								<Row>
									<Col flex={30}>
										{header}
										{elements.map(
											(element) =>
												element.props.section === FORM_HEADER && element,
										)}
									</Col>
									<Col flex={68} />
								</Row>
								<Divider
									key="divider"
									className="generic-view-accordion__header--divider"
								/>
							</div>
							{!!secondTabSectionsList.length && (
								<div className="generic-view-accordion__body">
									<MultiColumnGrid
										sectionsList={secondTabSectionsList}
										elements={elements}
									/>
								</div>
							)}
							<div className="generic-view-accordion__footerButtons">
								<div className="generic-view-accordion__footerButtons--actions">
									{footerButtons}
								</div>
							</div>
						</Form>
					</>
				);
			}}
		</Formik>
	);
});
