/**
 *
 * ContractActionsSection
 *
 */
import './style.scss';

import {
	CloseOutlined,
	DollarCircleOutlined,
	EditOutlined,
	RotateRightOutlined,
	StopOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import { Card, Col } from 'antd';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'app/containers/Contracts/slice';
import { ContractsModals } from 'app/containers/Contracts/types';
import { EventType } from 'types/EventType';

interface Props {
	activeEntity: any;
	span: number;
	noBorder?: boolean;
	dataTestId: string;
	handleClose: () => void;
}
export const ContractActionsSection = memo(function ContractActionsSection(
	props: Props,
) {
	const { activeEntity, span, dataTestId, handleClose } = props;

	const dispatch = useDispatch();

	const handleCancelModal = (contractData) => {
		dispatch(actions.setCurrentModal(ContractsModals.Cancel));
		dispatch(actions.setActiveOrder(contractData));
	};

	const handleEditModal = (contractId) => {
		dispatch(actions.loadContractDetails(contractId));
		dispatch(actions.setCurrentModal(ContractsModals.Edit));
	};

	const handleRollModal = (data) => {
		switch (data.contractTypeName.toLowerCase()) {
			case 'hta':
				dispatch(actions.setCurrentEventType(EventType.rollingHTA));
				break;
			case 'basis':
				dispatch(actions.setCurrentEventType(EventType.rollingBasis));
				break;
		}

		dispatch(actions.loadContractDetails(data.id));
		dispatch(actions.setCurrentModal(ContractsModals.PriceRoll));
	};

	const handlePriceModal = (data) => {
		switch (data.contractTypeName.toLowerCase()) {
			case 'hta':
				dispatch(actions.setCurrentEventType(EventType.pricingHTA));
				break;
			case 'basis':
				dispatch(actions.setCurrentEventType(EventType.pricingBasis));
				break;
		}
		dispatch(actions.loadContractDetails(data.id));
		dispatch(actions.setCurrentModal(ContractsModals.PriceRoll));
	};

	const handleConvertModal = (contractId) => {
		dispatch(actions.loadContractDetails(contractId));
		dispatch(actions.setCurrentModal(ContractsModals.Convert));
	};

	return (
		activeEntity && (
			<Col span={span}>
				<Card
					className="contract summary-actions"
					data-testid={dataTestId}
					bordered={false}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className="divider">
						<SyncOutlined
							className={classNames('action-icon', {
								'disabled-icon': !activeEntity.data.restrictions.canBeRolled,
							})}
							onClick={
								activeEntity.data.restrictions.canBeRolled
									? () => handleRollModal(activeEntity.data)
									: undefined
							}
							data-testid="roll-button"
						/>
						<DollarCircleOutlined
							className={classNames('action-icon', {
								'disabled-icon': !activeEntity.data.restrictions.canBePriced,
							})}
							onClick={
								activeEntity.data.restrictions.canBePriced
									? () => handlePriceModal(activeEntity.data)
									: undefined
							}
							data-testid="price-button"
						/>
						<EditOutlined
							className={classNames('action-icon', {
								'disabled-icon': !activeEntity.data.restrictions.canBeEdited,
							})}
							onClick={
								activeEntity.data.restrictions.canBeEdited
									? () => handleEditModal(activeEntity.data?.id)
									: undefined
							}
							data-testid="edit-button"
						/>

						<RotateRightOutlined
							className={classNames('action-icon', {
								'disabled-icon': !activeEntity.data.restrictions.canBeConverted,
							})}
							onClick={
								activeEntity.data.restrictions.canBeConverted
									? () => handleConvertModal(activeEntity.data?.id)
									: undefined
							}
							data-testid="convert-button"
						/>

						<StopOutlined
							className={classNames('action-icon', {
								'disabled-icon': !activeEntity.data.restrictions.canBeCanceled,
							})}
							onClick={
								activeEntity.data.restrictions.canBeCanceled
									? () => handleCancelModal(activeEntity.data)
									: undefined
							}
							data-testid="cancel-button"
						/>

						<CloseOutlined
							className="action-icon close"
							onClick={handleClose}
							data-testid="close-contract-button"
						/>
					</div>
				</Card>
			</Col>
		)
	);
});
