import { CONTRACT_TYPES } from 'utils/constants';

import { useBasisSchema } from '../CashContract/BasisSchema';
import { useHTASchema } from '../CashContract/HTASchema';

// Implement own schemas changes when needed
export const useAdjustmentsSchema = () => ({
	[CONTRACT_TYPES.basis]: useBasisSchema(),
	[CONTRACT_TYPES.hta]: useHTASchema(),
});
