import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { Input } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { translations } from 'locales/i18n';
import { ActionType } from 'types/ActionType';
import { ROUNDING_VALUES } from 'utils/constants';
import { isActionSell, mapToLabelValue } from 'utils/helpers';
import { preventWheelChange, preventWheelEvent } from 'utils/util';
import Yup from 'yupCustom';

import { selectTypeData } from '../../selectors';
import { actions } from '../../slice';

export const CreateRoundingRule = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const min = 0;
	const max = 0.01;

	const roundingOptions = mapToLabelValue(useSelector(selectTypeData));
	const [roundingTypeDisabled, setRoundingTypeDisabled] = useState(() => false);

	const domain =
		translations.app.containers.Settings.sections.RoundingRules.Modals.Create;

	const validationSchema = Yup.object().shape({
		contract: Yup.object().requiredOption(
			translate(domain.RoundingRuleRequiredText),
		),
		decimalPlace: Yup.object().requiredOption(
			translate(domain.RoundingRuleRequiredText),
		),
		to: Yup.number()
			.typeError(translate(domain.RoundingRuleRequiredText))
			.moreThan(min, translate(domain.RoundingRuleValidation))
			.lessThan(max, translate(domain.RoundingRuleValidation)),
		from: Yup.number()
			.typeError(translate(domain.RoundingRuleRequiredText))
			.moreThan(min, translate(domain.RoundingRuleValidation))
			.lessThan(max, translate(domain.RoundingRuleValidation)),
		roundingType: Yup.object().when('decimalPlace', {
			is: (decimalPlace) => decimalPlace.value === '0.0000',
			then: Yup.object().required(translate(domain.RoundingRuleRequiredText)),
		}),
	});

	roundingOptions.unshift({
		label: '',
		value: '',
	});

	const handleSubmit = (values) => {
		const data = {
			contractTypeId: values.contract.value,
			isSell: isActionSell(values.action),
			To: values.to,
			From: values.from,
			decimalPlace: values.decimalPlace?.value,
			roundingType:
				values.roundingType === undefined
					? undefined
					: values.roundingType.value,
		};

		dispatch(
			actions.createRoundingRule({
				data: data,
				successMessage: translate(domain.toastSuccessCreateMessage),
			}),
		);
	};

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};
	const resolver = yupResolver(validationSchema);
	const formInstance = useForm({
		resolver,
		mode: 'all',
		defaultValues: {
			action: ActionType.BUY,
			from: null,
			to: null,
			decimalPlace: { value: null },
			value: { value: null },
		},
	});

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'order-form__new-order',
			htmlType: 'button',
			children: 'Cancel',
			onClick: handleClose,
			key: 'cancel',
		},
		{
			htmlType: 'submit',
			children: translate(domain.addRoundingRuleModalButton),
			key: 'submit',
		},
	];

	const handleDecimalChange = () => {
		const { getValues, setValue } = formInstance;
		const currentValue = getValues('decimalPlace');
		if (currentValue.value === ROUNDING_VALUES.one) {
			setRoundingTypeDisabled(false);
		} else {
			setValue('roundingType', undefined);
			setRoundingTypeDisabled(true);
		}
	};

	return (
		<GenericForm.ModalContainer
			key="roundingRuleModal"
			className="create-form__modal"
			title={translate(domain.addRoundingRuleModalButton)}
			onCancel={handleClose}
			closable={false}
			destroyOnClose={true}
			keyboard={false}
		>
			<GenericForm.Form
				key="roundingRuleForm"
				formInstance={formInstance}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				buttonsDefinition={btnsDefinition}
			>
				<ContractType />

				<GenericForm.FormItem
					label={translate(domain.RoundingRuleRangeLabel)}
					name="tofrom"
					data-testid="fees-form-item"
				>
					<GenericForm.FormItem name="from">
						<Input
							data-testid="fees1-input-item"
							placeholder={translate(domain.RoundingRuleFromLabel)}
							key="from"
							type="number"
							onWheel={preventWheelChange}
							onFocus={preventWheelEvent}
							step={0.0001}
						></Input>
					</GenericForm.FormItem>

					<GenericForm.FormItem name="to">
						<Input
							data-testid="from-input-item"
							placeholder={translate(domain.RoundingRuleToLabel)}
							key="to"
							type="number"
							onFocus={preventWheelEvent}
							step={0.0001}
						></Input>
					</GenericForm.FormItem>
				</GenericForm.FormItem>
				<GenericForm.FormItem
					label={translate(domain.RoundingRuleDecimalLabel)}
					name="decimalPlace"
					data-testid="decimal-row"
					onChange={handleDecimalChange}
				>
					<Dropdown
						key="decimalPlace"
						options={[
							{
								label: ROUNDING_VALUES.one,
								value: ROUNDING_VALUES.one,
							},
							{
								label: ROUNDING_VALUES.two,
								value: ROUNDING_VALUES.two,
							},
							{
								label: ROUNDING_VALUES.three,
								value: ROUNDING_VALUES.three,
							},
							{
								label: ROUNDING_VALUES.four,
								value: ROUNDING_VALUES.four,
							},
						]}
					/>
				</GenericForm.FormItem>
				<GenericForm.FormItem
					label={translate(domain.RoundingRuleRoundingLabel)}
					name="roundingType"
					data-testid="rounding-row"
				>
					<Dropdown
						key="roundingType"
						disabled={roundingTypeDisabled}
						options={roundingOptions}
					/>
				</GenericForm.FormItem>
			</GenericForm.Form>
		</GenericForm.ModalContainer>
		//from to decimal place rounding
	);
};
