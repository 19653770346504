import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { commoditiesFilter } = apiEndpoints;

export function* getCommoditiesList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${commoditiesFilter}`);
	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.commoditiesListLoaded(responseData));
	} else if (!!responseError) {
		yield put(actions.commoditiesListError(responseError));
	}
}
