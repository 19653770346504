import React, { memo } from 'react';

import { NumberInput } from 'app/components/NumberInput';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';
import { NumberType } from 'types/NumberType';

const cellphone = 'cellphone';

export const Cellphone = memo(function Cellphone() {
	const translations = UseFieldTranslations();

	return (
		<NumberInput
			label={translations[cellphone].label}
			maxLength={14}
			name={cellphone}
			numberType={NumberType.phone}
			placeholder={translations[cellphone].placeholder}
		/>
	);
});
