import { Spin } from 'antd';
import React from 'react';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { translations } from 'locales/i18n';

import { useERPSetupFormData } from '../../hooks/useERPSetupFormData';
import { actions } from '../../slice';
import { SETUP_FORM_ITEMS, SWITCH_FORM_ITEMS } from '../../types';

const domain =
	translations.app.containers.Settings.sections.ERPIntegration.views.SetupForm;

const className = 'erp-setup-form';

export const SetupForm = () => {
	const {
		dispatch,
		elements,
		erpIntegration,
		initialValues,
		isLoading,
		isLoadingERPTypes,
		formInstance,
		translate,
		validationSchema,
	} = useERPSetupFormData();

	const getTranslation = (key: string) => translate(domain[key]);

	const handleCancel = () => {
		formInstance.reset({
			...initialValues,
		});
	};

	const handleSubmit = (values) => {
		const data = {
			...values,
			[SWITCH_FORM_ITEMS.ERPIntegration]:
				erpIntegration?.[SWITCH_FORM_ITEMS.ERPIntegration],
			[SETUP_FORM_ITEMS.AccountingSystem]:
				values[SETUP_FORM_ITEMS.AccountingSystem]?.value,
		};

		dispatch(
			actions.setERPIntegrationRequest({ data, isUpdatingERPSetup: true }),
		);
	};

	const buttonsDefinition: FormButtonsProps[] = [
		{
			children: getTranslation('cancelButton'),
			dataTestId: 'cancel-button',
			disabled: isLoading,
			htmlType: 'button',
			key: 'cancel',
			onClick: handleCancel,
		},
		{
			children: getTranslation('submitButton'),
			dataTestId: 'erp-submit-button',
			disabled: isLoading || !formInstance.formState.isValid,
			htmlType: 'submit',
			key: 'submit',
		},
	];

	if (isLoadingERPTypes) {
		return <Spin data-testid="spinner-loading" key="spinner" />;
	}

	return (
		<GenericForm.Form
			buttonsDefinition={buttonsDefinition}
			className={className}
			data-testid={className}
			formInstance={formInstance}
			key="erpSetupForm"
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			{elements}
		</GenericForm.Form>
	);
};
