import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { CONTRACT_TYPES } from 'utils/constants';

import { initialState } from './slice';
import { CalculatedFieldsSource } from './types';

const selectDomain = (state: RootState) => state?.globalSaga || initialState;

export const selectAuthUser = createSelector(
	[selectDomain],
	(globalState) => globalState.user,
);

export const selectUserInfo = createSelector(
	[selectDomain],
	(globalState) => globalState.userInfo,
);

export const selectCurrentModal = createSelector(
	[selectDomain],
	(globalState) => globalState.currentModal,
);

export const selectCommoditiesList = createSelector(
	[selectDomain],
	(globalState) => globalState.commodities.data,
);

export const selectCropsList = createSelector(
	[selectDomain],
	(globalState) => globalState.crops.data,
);

export const selectTransactionsList = createSelector(
	[selectDomain],
	(globalState) => globalState.transactions.data,
);
export const selectTransactionsListLoading = createSelector(
	[selectDomain],
	(globalState) => globalState.transactions.loading,
);

export const selectContractTypeList = (source: ContractTypeSource) =>
	createSelector(
		[selectDomain],
		(globalState) => globalState.contractType[source]?.data,
	);

export const selectContractTypeCatalog = createSelector(
	[selectDomain],
	(globalState) => globalState.contractTypeCatalog.data,
);
export const selectNonNtcContractTypeCatalog = createSelector(
	selectContractTypeCatalog,
	(contractTypes) =>
		contractTypes.filter(
			(contractType) => contractType.code !== CONTRACT_TYPES.ntc,
		),
);

export const selectEventList = createSelector(
	[selectDomain],
	(globalState) => globalState.event.data,
);

export const selectCustomersList = createSelector(
	[selectDomain],
	(globalState) => globalState.customers,
);

export const selectLocationsList = createSelector(
	[selectDomain],
	(globalState) => globalState.locations,
);

export const selectRoundingRulesList = createSelector(
	[selectDomain],
	(globalState) => globalState.roundingRules,
);

export const selectDestinationsList = createSelector(
	[selectDomain],
	(globalState) => globalState.destinations,
);

export const selectGroupedLocationList = createSelector(
	[selectDomain],
	(globalState) => globalState.groupedLocations,
);

export const selectDefaultLocationList = createSelector(
	[selectDomain],
	(globalState) => {
		const matchingLocations = globalState.groupedLocations?.data?.filter(
			(location) =>
				location.contractLocationId ===
					globalState?.defaultPage?.data?.locationId &&
				location.destinationLocationId ===
					globalState?.defaultPage?.data?.deliveryLocationId,
		);

		if (!matchingLocations || matchingLocations.length === 0) {
			return null;
		}

		// Always return regionId as an array
		const regionIds = matchingLocations.map((location) => location.regionId);

		return {
			...matchingLocations[0],
			regionId: regionIds,
		};
	},
);

export const selectEmployeesList = createSelector(
	[selectDomain],
	(globalState) => globalState.employees,
);

export const selectFuturesPrice = createSelector(
	[selectDomain],
	(globalState) => globalState.futuresPrice,
);

export const selectCachedFuturesPrice = createSelector(
	[selectDomain],
	(globalState) => globalState.cachedFuturesPrice.data,
);

export const selectDeliveryDates = createSelector(
	[selectDomain],
	(globalState) => globalState.deliveryDates,
);

export const selectFuturesMonth = createSelector(
	[selectDomain],
	(globalState) => globalState.futuresMonth,
);

export const selectPostedBasisPrice = createSelector(
	[selectDomain],
	(globalState) => globalState.postedBasisPrice,
);

export const selectCatalogFuturesMonth = createSelector(
	[selectDomain],
	(globalState) => globalState.catalogFuturesMonths,
);

export const selectAppNotifications = createSelector(
	[selectDomain],
	(globalState) => globalState.appNotifications,
);

export const selectReviewAndReleaseStatus = createSelector(
	[selectDomain],
	(globalState) => globalState.reviewAndReleaseStatus,
);

export const selectPreHedgeStatus = createSelector(
	[selectDomain],
	(globalState) => globalState.preHedgeStatus,
);

export const selectSessionCheck = createSelector(
	[selectDomain],
	(globalState) => globalState.localStorage.sessionCheck,
);

export const selectSessionError = createSelector(
	[selectDomain],
	(globalState) => globalState.localStorage.error,
);

export const selectFuturesMonthOptions = (source: CalculatedFieldsSource) =>
	createSelector(
		[selectDomain],
		(globalState) => globalState.futuresMonthOptions[source]?.data,
	);

export const selectHedgeAccountList = createSelector(
	[selectDomain],
	(globalState) => globalState.hedgeAccounts.data,
);

export const selectDefaultPageData = createSelector(
	[selectDomain],
	(state) => state.defaultPage.data,
);

export const selectDefaultPageLoading = createSelector(
	[selectDomain],
	(state) => state.defaultPage.loading,
);

export const selectServiceFeeList = createSelector(
	[selectDomain],
	(state) => state.serviceFees,
);

export const selectServiceFeeTypesList = createSelector(
	[selectDomain],
	(state) => state.serviceFeeTypes.data,
);

export const selectHasCustomFields = createSelector(
	[selectDomain],
	(state) => state.orderEntry.data?.hasCustomFields,
);

export const selectOrderEntry = createSelector(
	[selectDomain],
	(state) => state.orderEntry.data?.fields,
);

export const selectOrderEntryLoading = createSelector(
	[selectDomain],
	(state) => state.orderEntry.loading,
);

export const selectNtcAdmin = createSelector(
	[selectDomain],
	(state) => state.ntcAdmin.data,
);

export const selectNtcAdminLoading = createSelector(
	[selectDomain],
	(state) => state.ntcAdmin.loading,
);

export const selectOrderStatus = createSelector(
	[selectDomain],
	(state) => state.orderStatus.data,
);

export const selectEnabledRegions = createSelector(
	[selectDomain],
	(state) => state.regions.data,
);

// Notifications
export const selectEmployeeNotificationGroups = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.employeeNotificationGroups?.data,
);
export const selectEmployeeNotificationGroupsLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.employeeNotificationGroups?.loading,
);
export const selectCustomerNotificationGroups = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.customerNotificationGroups?.data,
);
export const selectCustomerNotificationGroupsLoading = createSelector(
	[selectDomain],
	(notificationSettingsState) =>
		notificationSettingsState.customerNotificationGroups?.loading,
);

// Launch Darkly
export const selectFeatureFlags = createSelector(
	[selectDomain],
	(state) => state.featureFlags,
);
