import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';

interface Props {
	disabled?: {
		balance?: boolean;
		splitAmount?: boolean;
	};
	customPlaceholder?: string;
}

export const QuantityToSplit = memo(function QuantityToSplit({
	disabled,
	customPlaceholder,
}: Props) {
	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Contracts.components.QuantityToSplit;

	return (
		<>
			<GenericForm.FormItem
				customClassName={['input-quantity__label']}
				data-testid="quantity-form-item"
				label={translate(msg.balance.label)}
				name="balance"
			>
				<Input
					className="input-quantity__input"
					data-testid="balance-input-item"
					disabled={disabled?.balance}
					name="balance"
					suffix={<>{translate(msg.balance.label)}</>}
					type="text"
				/>
			</GenericForm.FormItem>
			<GenericForm.FormItem
				isNumericFieldSlow
				customClassName={['input-quantity__label']}
				data-testid="quantityToSplit-form-item"
				label={translate(msg.quantityToSplitLabel)}
				name="quantityToSplit"
			>
				<Input
					className="input-quantity__input"
					data-testid="quantityToSplit-input-item"
					disabled={disabled?.splitAmount}
					placeholder={customPlaceholder}
					name="quantityToSplit"
				/>
			</GenericForm.FormItem>
		</>
	);
});
