import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { InputPassword } from 'app/components/InputPassword';
import { translations } from 'locales/i18n';

import { SETUP_FORM_ITEMS } from '../../types';

const domain =
	translations.app.containers.Settings.sections.ERPIntegration.components
		.Password;

export const Password = memo(function Password() {
	const { t: translate } = useTranslation();

	return (
		<GenericForm.FormItem
			data-testid={`${SETUP_FORM_ITEMS.Password}-form-item`}
			label={translate(domain.label)}
			name={SETUP_FORM_ITEMS.Password}
		>
			<InputPassword name={SETUP_FORM_ITEMS.Password} />
		</GenericForm.FormItem>
	);
});
