import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '../slice';

export const useLocationEdit = ({ values = { manualEditMode: false } }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.enableManualEdit(values.manualEditMode));
	}, [values.manualEditMode]);
};
