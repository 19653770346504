/**
 *
 * FilterInput
 *
 */
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';

const { Search } = Input;

interface Props {
	placeholder?: string;
	onSearch: (value: string) => void;
	currentOption?: string;
	className?: string;
}

export const FilterInput = (props: Props) => {
	const { onSearch, placeholder, currentOption, className } = props;
	const [value, setValue] = useState(currentOption);

	useEffect(() => {
		setValue(currentOption);
	}, [currentOption]);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	return (
		<Search
			size={'small'}
			className={`details-filter__input ${className}`}
			placeholder={placeholder}
			onSearch={onSearch}
			allowClear={true}
			data-testid="filter-input"
			enterButton={<SearchOutlined data-testid="details-filter-search" />}
			defaultValue={value}
			value={value}
			onChange={handleChange}
		/>
	);
};
