import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';
import { contractTypeSchema } from '../types';

const { contractTypesRoot } = apiEndpoints;

export function* getContractTypeCatalog() {
	const requestURL = new URL(`${apiRoutes.base}/${contractTypesRoot}`);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(contractTypeSchema),
	});

	if (!!data) {
		yield* put(actions.contractTypeCatalogLoaded(data));
	} else if (!!error?.detail) {
		yield* put(actions.contractTypeCatalogError(error));
	}
}
