import './styles.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

export const Header = () => {
	const { t: translate } = useTranslation();

	const REGIONS_DOMAIN = translations.app.containers.Settings.sections.Regions;

	return (
		<div className="regions-header">
			<label className="label--status" data-testid="statusLabel">
				{translate(REGIONS_DOMAIN.statusLabel)}
			</label>
			<label className="label" data-testid="regionNamePlaceholder">
				{translate(REGIONS_DOMAIN.regionNamePlaceholder)}
			</label>
			<label className="label" data-testid="erpIdPlaceholder">
				{translate(REGIONS_DOMAIN.erpIdPlaceholder)}
			</label>
		</div>
	);
};
