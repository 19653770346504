import { GenericError } from 'types/GenericError';
import { IGenericPagination } from 'types/GenericPagination';

type Restrictions = {
	canBeEdited: boolean;
};

export type Customer = {
	firstName: string;
	lastName: string;
	number: string;
	street: string;
	city: string;
	state: string;
	zipCode: string;
	country: string;
	phoneNumber: string;
	email: string;
	isInSync: boolean;
	mobileAppLimit?: number | null;
	isMobileAppEnable?: boolean;
	restrictions: Restrictions;
	isEmailNotificationEnabled: boolean;
	isSmsNotificationEnabled: boolean;
	id?: string;
	isActive?: boolean;
	workPhoneNumber?: string;
};

/* --- STATE --- */
export interface CustomersState {
	currentView: View;
	currentModal: Modal | null;

	activeCustomer: {
		data: Customer | null;
		error: GenericError | null;
		loading: boolean;
	};

	countriesList: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	statesList: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	customerStatus: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	list: {
		data: Customer[];
		loading: boolean;
		total: number;
		allLoaded: boolean;
		error: GenericError | null;
	};

	selectedFilters: Filters;

	pagination: IGenericPagination;
}

export enum View {
	List = 'list',
}
export enum Modal {
	Create = 'Create',
	Edit = 'Edit',
}

export enum FiltersEnum {
	Query = 'query',
}

export interface Filters {
	[FiltersEnum.Query]?: string;
}

export interface TooglePayload {
	id: string;
	isActive: boolean;
}

export type CustomerSectionState = CustomersState;
