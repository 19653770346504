/**
 *
 * History
 *
 */
import './style.scss';

import classNames from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { selectActiveOrderDetails } from 'app/containers/ReviewAndRelease/selectors';
import { translations } from 'locales/i18n';
import { CONSTANTS } from 'utils/constants';
import { customFormat, dateFormat, timeFormat } from 'utils/helpers';

import { FuturesPrice } from '../FuturesPrice';
import { getColumnValue } from '../utils/helpers';

interface OffersHistory {
	clientNumber: string | null;
	createdOn: string | null;
	cropYear: number | null;
	event: string | null;
	futuresMonth: string | null;
	futuresPrice: number | null;
	lots: number | null;
	marketId: any;
	marketInformation: any;
	marketPrice: number;
	quantity: number;
	state: string;
	workingLots: number;
}

export const History = memo(function History() {
	const translationsScope =
		translations.app.containers.OrderContainer.components.OrdersTable;

	const activeOrderDetails = useSelector(selectActiveOrderDetails);
	const loading = activeOrderDetails.loading;
	const transactionHistory: OffersHistory[] = activeOrderDetails.data?.history;

	const { t: translate } = useTranslation();

	const columns = [
		{
			title: translate(translationsScope.created),
			key: 'created',
			className: 'md',
			fixed: true,
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--bold text--large">
						{dateFormat(data.createdOn)}
					</p>
					<p className="text text--label">{timeFormat(data.createdOn)}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.contractNumber),
			key: 'contract',
			fixed: true,
			className: 'md',
			render: (data) => (
				<GenericTable.Column>
					<div className="text text--bold flex-column flex-row--space-around">
						<span className="text text--large text--bold">
							{getColumnValue(data?.clientNumber)}
						</span>
					</div>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.event),
			key: 'event',
			className: 'xs',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large text--centered">{data.event}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.futuresMonth),
			key: 'futuresMonth',
			className: 'xs',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large text--centered">{data.futuresMonth}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.crop),
			className: 'xs',
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--large text--centered">{data.cropYear}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.futures),
			key: 'futures',
			className: 'xs',
			fixed: 'right' as 'right',
			render: (data) => <FuturesPrice data={data} />,
		},
		{
			title: translate(translationsScope.quantity),
			className: 'xs',
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--large text--centered">
						{customFormat(
							data?.quantity,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						)}
					</p>
				</GenericTable.Column>
			),
		},
	];

	return (
		<>
			{transactionHistory?.length && (
				<>
					<div className="table-title" data-testid="history-table-title">
						<p className="text text--large">{`History`}</p>
					</div>
					<GenericTable.Table<any>
						otherClassName="history-container"
						columns={columns}
						loading={loading}
						data={transactionHistory}
						rowKey={(record) => record.id}
						pagination={false}
						onRow={(record) => ({
							className: classNames(
								`border-left--${record?.state?.trim().split(' ').join('-').toLowerCase()}`,
							),
						})}
					/>
				</>
			)}
		</>
	);
});
