import React, { memo, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { RadioButtons } from 'app/components/RadiosButtons';
import { selectContractTypeList } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';
import { ActionType } from 'types/ActionType';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { CONTRACT_TYPE_VALUES } from 'utils/constants';
import { mapToLabelValue } from 'utils/helpers';

import { SourceContext } from '../..';

interface Props {
	disabled?: boolean;
	resetDependenciesOnChange?: boolean;
	handleOnChange?: () => {};
	hidden?: boolean;
	showContractType?: boolean;
	ShowNTC?: boolean;
	RemoveAllExceptNTC?: boolean;
	disableBuySell?: boolean;
}
export const ContractType = memo(function ContractType(props: Props) {
	const {
		disabled,
		handleOnChange = handleChange,
		resetDependenciesOnChange = true,
		hidden = false,
		showContractType = true,
		ShowNTC = false,
		RemoveAllExceptNTC = false,
		disableBuySell = false,
	} = props;
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const msg = translations.app.containers.Transactions.components.ContractType;

	const sourceName = useContext(SourceContext);

	let sagaSource = ContractTypeSource.CONTRACT;
	if (sourceName === GlobalSagaSource.createEditOfferModal) {
		sagaSource = ContractTypeSource.OFFER;
	}

	let contractTypes = mapToLabelValue(
		useSelector(selectContractTypeList(sagaSource)),
	);

	/**
	 * Since We will view NTC as a contract type to a limited view i.e in create new contract so to ensure less changes in code we will customise the view of show NTC
	 * in createModal under contrat
	 *
	 * We will mark showNTC=true in the schema of create modal only
	 */
	if (!ShowNTC) {
		// remove contractType.label = 'NTC' from the list so that user cannot sellect ntc while converting a contract
		if (contractTypes !== null && Array.isArray(contractTypes)) {
			contractTypes = contractTypes.filter(
				(contractType) => contractType.type !== CONTRACT_TYPE_VALUES.ntc,
			);
		}
	}

	/**
	 * Since We will view NTC as a contract type to a limited view i.e in create new contract so to ensure less changes in code we will customise the view of show NTC
	 * in createModal under contrat
	 * We will mark RemoveAllExceptNTC=true in the schema of create modal only
	 * This will remove all the contract types except NTC
	 */
	if (RemoveAllExceptNTC) {
		// remove contractType.label !== 'NTC' from the list so that user cannot sellect ntc while converting a contract
		if (contractTypes !== null && Array.isArray(contractTypes)) {
			contractTypes = contractTypes.filter(
				(contractType) => contractType.type === CONTRACT_TYPE_VALUES.ntc,
			);
		}
	}

	const { setValue } = useFormContext();

	const clearStore = () => {
		dispatch(globalActions.clearDeliveryDates(sourceName));
		dispatch(globalActions.clearFuturesMonth(sourceName));
		dispatch(globalActions.clearFuturesPrices(sourceName));
		dispatch(globalActions.clearPostedBasisPrice(sourceName));
	};

	function handleChange() {
		if (resetDependenciesOnChange) {
			clearStore();
			setValue('location', { value: null });
			setValue('deliveryLocation', { value: null });
			setValue('deliveryDateWindow', { value: null });
		}
	}

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="contract-group"
			data-testid="contract-group-form-item"
			customClassName={hidden ? ['hidden'] : []}
		>
			{showContractType && (
				<GenericForm.FormItem name="contract">
					<Dropdown
						key="contract"
						placeholder={translate(msg.placeholder)}
						options={contractTypes}
						disabled={disabled}
					/>
				</GenericForm.FormItem>
			)}
			<GenericForm.FormItem name="action" onChange={handleOnChange}>
				<RadioButtons
					disabled={disableBuySell || disabled}
					name="action"
					key="action"
					disabledMode={disableBuySell ? 'multi' : 'single'}
					options={[
						{ label: translate(msg.action.buy), value: ActionType.BUY },
						{ label: translate(msg.action.sell), value: ActionType.SELL },
					]}
				/>
			</GenericForm.FormItem>
		</GenericForm.FormItem>
	);
});
