/**
 *
 * Filters in List Menu
 *
 */
import './style.scss';

import { Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Collapsible } from 'app/components/Collapsible';
import { FilterAutocomplete } from 'app/components/FilterAutocomplete';
import { FilterDate } from 'app/components/FilterDate';
import { FilterInput } from 'app/components/FilterInput';
import { FilterMultiple } from 'app/components/FilterMultiple';
import { FilterSingle } from 'app/components/FilterSingle';
import { FilterSwitch } from 'app/components/FilterSwitch';
import { OrderDirectionOptions } from 'app/containers/Contracts/types';
import { globalSaga } from 'app/containers/GlobalSaga/saga';
import {
	selectCatalogFuturesMonth,
	selectCommoditiesList,
	selectCustomersList,
	selectDestinationsList,
	selectEmployeesList,
} from 'app/containers/GlobalSaga/selectors';
import {
	actions as globalActions,
	reducer as globalReducer,
	sliceKey as globalSliceKey,
} from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';
import { GenericOption } from 'types/GenericOption';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { allDefaultOption, FILTERS_FOR_OFFERS } from 'utils/constants';
import {
	dateMoment,
	GetPersistedData,
	mapToLabelValue,
	mapToLabelValueObject,
	UpdatePersistedData,
} from 'utils/helpers';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { selectSelectedFilters } from '../../../../../selectors';
import { actions } from '../../../../../slice';

const { offerFilters: sourceName } = GlobalSagaSource;
export const Filters = () => {
	useInjectReducer({ key: globalSliceKey, reducer: globalReducer });
	useInjectSaga({ key: globalSliceKey, saga: globalSaga });
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const featureFlags = useFlags();

	const commoditiesList = useSelector(selectCommoditiesList);
	const employees = useSelector(selectEmployeesList);
	const { data: employeesList = [], loading: employeesLoading } = (employees &&
		employees[sourceName]) || { data: [], loading: false };
	const { data: destinationList = [] } = useSelector(
		selectDestinationsList,
	) || { data: [], loading: false };

	const allOrderDirectionOptions = [
		{
			...allDefaultOption,
			name: translate(
				translations.app.containers.Contracts.ListMenu.filters.buyOrSell.all,
			),
		},
		{
			name: OrderDirectionOptions.Buy,
			id: OrderDirectionOptions.Buy,
		},
		{
			name: OrderDirectionOptions.Sell,
			id: OrderDirectionOptions.Sell,
		},
	];
	const orderDirectionOptions = mapToLabelValue(allOrderDirectionOptions);

	const customers = useSelector(selectCustomersList);
	const { data: customersList = [], loading: customersLoading } = customers[
		sourceName
	] || { data: [], loading: false };

	const catalogFuturesMonths = useSelector(selectCatalogFuturesMonth);
	const { data: catFMlist = [], loading: catFMloading } = catalogFuturesMonths[
		sourceName
	] || { data: [], loading: false };

	const selectedFilters = useSelector(selectSelectedFilters);

	// Get the persisted filter DATA from localstorage and load the contract grid
	const persistedFilters = GetPersistedData<null | typeof selectedFilters>(
		FILTERS_FOR_OFFERS,
		null,
	);

	useEffect(() => {
		dispatch(actions.setSelectedFilters(persistedFilters));
		dispatch(actions.loadOffersList(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const transllScope =
		translations.app.containers.Offers.components.Views.List.ListMenu.Filters;
	//#region Helpers

	//TODO: Mau - Check if there is another place to load these options
	const [futuresMonthsOptions, setFuturesMonthsOptions] = useState<
		GenericOption[]
	>([]);
	useEffect(() => {
		dispatch(
			globalActions.loadCatalogFuturesMonthsList({ source: sourceName }),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!catFMloading && catFMlist.length > 0) {
			setFuturesMonthsOptions([
				mapToLabelValueObject(allFuturesMonthsOption),
				...catFMlist,
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [catalogFuturesMonths]);

	//FIXME: Mau - we need to replace these hardcoded options when BE delivers an endpoint
	const statusOptions: GenericOption[] = mapToLabelValue([
		{
			id: 'Working/Rejected',
			name: 'Working / Rejected',
		},
		{
			id: 'Canceled/Expired',
			name: 'Canceled / Expired',
		},
		{
			id: 'Filled',
			name: 'Filled',
		},
		{
			id: 'Completed',
			name: 'Completed',
		},
		{
			id: 'Pending',
			name: 'Pending',
		},
	]);

	//#endregion

	//#region Filters

	//#region - Commodities Filter

	const allCommoditiesOption = {
		...allDefaultOption,
		name: translate(
			translations.app.containers.Contracts.ListMenu.filters.commodities.all,
		),
	};

	const destinationOptions = mapToLabelValue([
		{
			...allDefaultOption,
			name: translate(
				translations.app.containers.Contracts.ListMenu.filters.destinations.all,
			),
		},
		...destinationList,
	]);

	const commoditiesOptions = mapToLabelValue([
		allCommoditiesOption,
		...commoditiesList,
	]);

	const handleChangeCommodities = (selectedValues) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			commodities: selectedValues,
		});

		dispatch(
			actions.setSelectedFilters({
				commodities: selectedValues,
			}),
		);
		dispatch(actions.loadOffersList(true));
	};

	//#endregion Filters

	//#region - Customer Filter

	const customerOptions = mapToLabelValue(customersList);

	const handleChangeCustomer = (selectedValue) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			customerId: selectedValue,
		});

		dispatch(
			actions.setSelectedFilters({
				customerId: selectedValue,
			}),
		);
		if (!selectedValue.length)
			dispatch(globalActions.clearCustomersList({ source: sourceName }));

		dispatch(actions.loadOffersList(true));
	};

	const handleSearchCustomer = (searchValue) => {
		if (searchValue.length) {
			dispatch(
				globalActions.loadCustomersList({
					search: searchValue,
					source: sourceName,
				}),
			);
		} else if (!searchValue.length) {
			dispatch(globalActions.clearCustomersList({ source: sourceName }));
		} else if (selectedFilters.customerId) {
			UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
				commodities: null,
			});
			dispatch(
				actions.setSelectedFilters({
					customerId: null,
				}),
			);
			dispatch(globalActions.clearCustomersList({ source: sourceName }));
			dispatch(actions.loadOffersList(true));
		}
	};

	//#endregion

	//#region - Status Filter
	const handleChangeStatus = (selectedStatus) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			statusView:
				!!selectedStatus && !!selectedStatus.length
					? selectedStatus
					: [statusOptions[0]],
		});
		dispatch(
			actions.setSelectedFilters({
				statusView:
					!!selectedStatus && !!selectedStatus.length
						? selectedStatus
						: [statusOptions[0]],
			}),
		);
		dispatch(actions.loadOffersList(true));
	};

	//#endregion

	//#region - Date Filter
	const handleDateChange = (selectedDate) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			date: !!selectedDate ? selectedDate : null,
		});
		dispatch(
			actions.setSelectedFilters({
				date: !!selectedDate ? selectedDate : null,
			}),
		);
		dispatch(actions.loadOffersList(true));
	};

	//#endregion

	//#region - Expiration Date Filter
	const handleExpirationDateChange = (selectedDate) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			expirationDate: !!selectedDate ? selectedDate : null,
		});
		dispatch(
			actions.setSelectedFilters({
				expirationDate: !!selectedDate ? selectedDate : null,
			}),
		);
		dispatch(actions.loadOffersList(true));
	};

	//#endregion

	//#region - Employees Filter

	const employeeOptions = mapToLabelValue(employeesList);

	const handleChangeEmployee = (selectedValue) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			employeeId: selectedValue,
		});
		dispatch(
			actions.setSelectedFilters({
				employeeId: selectedValue,
			}),
		);
		if (!selectedValue.length)
			dispatch(globalActions.clearEmployeesList({ source: sourceName }));

		dispatch(actions.loadOffersList(true));
	};

	const handleSearchEmployee = (searchValue) => {
		if (searchValue.length) {
			dispatch(
				globalActions.loadEmployeesList({
					search: searchValue,
					source: sourceName,
				}),
			);
		} else if (!searchValue.length) {
			dispatch(globalActions.clearEmployeesList({ source: sourceName }));
		} else if (selectedFilters.employeeId) {
			UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
				employeeId: null,
			});
			dispatch(
				actions.setSelectedFilters({
					employeeId: null,
				}),
			);
			dispatch(globalActions.clearEmployeesList({ source: sourceName }));
			dispatch(actions.loadOffersList(true));
		}
	};

	//#endregion Filters

	//#region - GTC Filter

	const handleGTCChange = (checked) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			gtc: checked,
		});
		dispatch(
			actions.setSelectedFilters({
				gtc: checked,
			}),
		);
		dispatch(actions.loadOffersList(true));
	};

	//#endregion

	//#region - Futures Month Filter

	const allFuturesMonthsOption = {
		...allDefaultOption,
		name: translate(transllScope.futuresMonth.all),
	};
	const handleChangeDestination = (selectedValues) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			destinations: selectedValues,
		});

		dispatch(
			actions.setSelectedFilters({
				destinations: selectedValues,
			}),
		);

		dispatch(actions.loadOffersList(true));
	};

	const handleChangeNumber = (value: string) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			numberOrCustomer: value,
		});

		dispatch(
			actions.setSelectedFilters({
				numberOrCustomer: value,
			}),
		);

		dispatch(actions.loadOffersList(true));
	};

	const handleChangeFuturesMonth = (selectedFutureMonth) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			futuresMonth: selectedFutureMonth,
		});
		dispatch(
			actions.setSelectedFilters({
				futuresMonth: selectedFutureMonth,
			}),
		);
		dispatch(actions.loadOffersList(true));
	};

	//#endregion

	const handleChangeOrderDirection = (selectedValue) => {
		UpdatePersistedData(FILTERS_FOR_OFFERS, selectedFilters, {
			orderDirection: selectedValue,
		});
		dispatch(
			actions.setSelectedFilters({
				orderDirection: selectedValue,
			}),
		);

		dispatch(actions.loadOffersList(true));
	};
	//#endregion

	const newFiltersLayout = featureFlags[FeatureFlag.enableNewFilterLayout];

	return (
		<>
			<section className="grid-main-filters">
				<div className="grid-menu-label">
					{translate(translations.app.containers.Contracts.ListMenu.label)}
				</div>
				<Row gutter={newFiltersLayout ? [8, 8] : [16, { sm: 16, md: 0 }]}>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3, xl: 3 }
							: { sm: 4, md: 3 })}
					>
						<FilterMultiple
							options={commoditiesOptions}
							handleChange={handleChangeCommodities}
							currentOptions={
								selectedFilters.commodities || [commoditiesOptions[0]]
							}
							placeholder={translate(
								translations.app.containers.Contracts.ListMenu.filters
									.commodities.all, //TODO: Mau - refactor to a generic message
							)}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3, xl: 3 }
							: { sm: 4, md: 3 })}
					>
						<FilterSingle
							options={statusOptions}
							handleChange={handleChangeStatus}
							currentOption={[
								selectedFilters.statusView?.[0] || statusOptions[0],
							]}
							placeholder={translate(transllScope.status.placeHolder)}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3, xl: 3 }
							: { sm: 4, md: 3 })}
					>
						<FilterAutocomplete
							maxTagTextLength={30}
							options={customerOptions}
							handleChange={handleChangeCustomer}
							handleSearch={handleSearchCustomer}
							loading={customersLoading}
							currentOption={selectedFilters?.customerId || []}
							placeholder={translate(
								translations.app.containers.Contracts.ListMenu.filters.customer
									.all, //TODO: Mau - refactor to a generic message
							)}
							notFoundContent={
								!customersLoading && !customerOptions.length
									? translate(
											translations.app.containers.Contracts.ListMenu.filters
												.notfound, //TODO: Mau - refactor to a generic message
										)
									: null
							}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3, xl: 3 }
							: { sm: 4, md: 3 })}
					>
						<FilterMultiple
							currentOptions={
								selectedFilters.destinations || [destinationOptions[0]]
							}
							handleChange={handleChangeDestination}
							options={destinationOptions}
							placeholder={translate(
								translations.app.containers.Contracts.ListMenu.filters
									.destinations.placeholder,
							)}
						/>
					</Col>
				</Row>
			</section>
			<Collapsible
				collapsibleKey={'offers-filter-more'}
				inlineTitle={true}
				content={
					<Row gutter={newFiltersLayout ? [8, 8] : [16, { sm: 16, md: 0 }]}>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3, xl: 3 }
								: { sm: 4, md: 3 })}
						>
							<FilterDate
								currentDate={
									!!selectedFilters.date
										? dateMoment(selectedFilters.date)
										: null
								}
								name="Date"
								handleChange={handleDateChange}
								defaultValue={
									!!selectedFilters.date
										? dateMoment(selectedFilters.date)
										: undefined
								}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3, xl: 3 }
								: { sm: 4, md: 3 })}
						>
							<FilterAutocomplete
								maxTagTextLength={30}
								options={employeeOptions}
								handleChange={handleChangeEmployee}
								handleSearch={handleSearchEmployee}
								loading={employeesLoading}
								currentOption={selectedFilters?.employeeId || []}
								placeholder={translate(
									translations.app.containers.Contracts.ListMenu.filters
										.employees.searchLabel,
								)}
								notFoundContent={
									!employeesLoading && !employeeOptions.length
										? translate(
												translations.app.containers.Contracts.ListMenu.filters
													.notfound,
											)
										: null
								}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3, xl: 3 }
								: { sm: 4, md: 3 })}
						>
							<FilterSingle
								options={orderDirectionOptions}
								handleChange={handleChangeOrderDirection}
								currentOption={[
									selectedFilters.orderDirection?.[0] ||
										orderDirectionOptions[0],
								]}
								placeholder={translate(transllScope.buyOrSell.placeHolder)}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3, xl: 3 }
								: { sm: 4, md: 2 })}
						>
							<FilterMultiple
								options={futuresMonthsOptions}
								handleChange={handleChangeFuturesMonth}
								currentOptions={
									selectedFilters.futuresMonth || [futuresMonthsOptions[0]]
								}
								placeholder={translate(transllScope.futuresMonth.placeHolder)}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3, xl: 3 }
								: { sm: 4, md: 3 })}
						>
							<FilterInput
								onSearch={handleChangeNumber}
								placeholder={translate(transllScope.number)}
								currentOption={selectedFilters?.numberOrCustomer || ''}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3, xl: 3 }
								: { sm: 4, md: 3 })}
						>
							<FilterDate
								currentDate={
									!!selectedFilters.expirationDate
										? dateMoment(selectedFilters.expirationDate)
										: null
								}
								name="ExpirationDate"
								placeholder="Select Expiration Date"
								handleChange={handleExpirationDateChange}
								defaultValue={
									!!selectedFilters.expirationDate
										? dateMoment(selectedFilters.expirationDate)
										: undefined
								}
							/>
						</Col>
						<Col
							{...(newFiltersLayout
								? { xs: 12, md: 6, lg: 3, xl: 3 }
								: { sm: 4, md: 1 })}
						>
							<FilterSwitch
								handleChange={handleGTCChange}
								checkedElement={translate(transllScope.gtc.checked)}
								uncheckedElement={translate(transllScope.gtc.unchecked)}
								checked={selectedFilters.gtc}
							/>
						</Col>
					</Row>
				}
			/>
		</>
	);
};
