import './styles.scss';

import { Alert, Button, Col, Row, Spin, Tooltip } from 'antd';
import React, { ComponentProps } from 'react';
import { useAbac } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmDialog } from 'app/components/ConfirmDialog';
import { ExcelExport } from 'app/components/ExcelExport';
import { selectUserInfo } from 'app/containers/GlobalSaga/selectors';
import {
	selectExportTemplate,
	selectHasAnythingChanged,
} from 'app/containers/Settings/sections/Bidsheet/selectors';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';

import { selectSummaryLoading } from '../../../selectors';
import { actions } from '../../../slice';
import { Modal } from '../../../types';
import { Filters } from '../Filters';

export const Header = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const summaryLoading = useSelector(selectSummaryLoading);
	const hasAnythingChanged = useSelector(selectHasAnythingChanged);
	const userInfo = useSelector(selectUserInfo);
	const url = useSelector(selectExportTemplate)?.url;
	const translationsRoot =
		translations.app.containers.Settings.sections.Bidsheet.View.List.Header;

	const { userHasPermissions } = useAbac();
	const hasBidsheetUploadPermission = userHasPermissions(
		Permission.CONTRACTSERVICE_BIDSHEET_UPLOAD,
	);
	const noPermissionTooltipProps: ComponentProps<typeof Tooltip> = {
		title: translate(translations.app.commons.noPermission),
	};
	// hide the tooltip if user has permission
	if (hasBidsheetUploadPermission) {
		noPermissionTooltipProps.visible = false;
	}

	const handleNewBidsheet = () => {
		dispatch(actions.clearBidsheetUpload());
		dispatch(actions.setCurrentModal(Modal.Upload));
	};

	const handleOnClickPublish = () => {
		dispatch(actions.loadBidsheetSummary());
	};

	const handleOnClickExport = () => {
		const errorMsg = translate(translationsRoot.failedExport);
		const successMsg = translate(translationsRoot.successExport);
		dispatch(actions.exportBids({ errorMsg, successMsg }));
	};

	const handleNotifyNow = () => {
		const successMessage = translate(translationsRoot.notifyNowSuccess);
		dispatch(actions.notifyBidSent(successMessage));
	};

	const handleAdd = () => {
		const newRow = {
			createdOn: new Date(),
			employee: {
				lastName: userInfo?.lastName,
				firstName: userInfo?.firstName,
			},
			cropYear: 2021,
			commodity: '',
			location: '',
			deliveryStart: new Date(),
			deliveryEnd: new Date(),
			futureMonth: '',
			oldFutureMonth: '',
			basis: 0,
			oldBasis: 0,
			change: 0,
			id: crypto.randomUUID(),
			newRow: true,
		};
		dispatch(actions.bidsheetListAddBid(newRow));
	};

	return (
		<div className="bidsheet-container">
			<div className="bidsheet-container__title">
				<h2>{translate(translationsRoot.bidsheetTitle)}</h2>
			</div>
			{hasAnythingChanged && (
				<Alert
					showIcon
					style={{
						marginBottom: '1rem',
						fontWeight: 'bolder',
					}}
					type="info"
					message={translate(translationsRoot.unpublishedBidsAlert)}
				/>
			)}
			<Row gutter={[16, 16]} className="bidsheet-container__actions">
				<Col xs={12} xxl={8} className="bidsheet-container-filters">
					<Filters />
				</Col>
				<Col xs={12} xxl={4} className="bidsheet-container-buttons">
					<ConfirmDialog
						message={translate(translationsRoot.notifyNowConfirmation)}
						handleConfirm={handleNotifyNow}
						confirmText={translate(
							translations.app.components.ConfirmationModal.confirm,
						)}
						cancelText={translate(
							translations.app.components.ConfirmationModal.cancel,
						)}
						trigger={
							<Button
								type="primary"
								size="middle"
								className="orders-button"
								data-testid="button-notify-now-row"
							>
								{translate(translationsRoot.notifyNow)}
							</Button>
						}
					/>
					<Tooltip {...noPermissionTooltipProps}>
						<div>
							<Button
								type="primary"
								size="middle"
								onClick={() => handleAdd()}
								className="orders-button"
								data-testid="button-create-bid-row"
								disabled={!hasBidsheetUploadPermission}
							>
								{translate(translationsRoot.addBid)}
							</Button>
						</div>
					</Tooltip>
					<Tooltip {...noPermissionTooltipProps}>
						<div>
							<Button
								type="primary"
								size="middle"
								onClick={handleNewBidsheet}
								className="orders-button"
								data-testid="new-bidsheet-button"
								ghost
								disabled={!hasBidsheetUploadPermission}
							>
								{translate(translationsRoot.createBidsheetButton)}
							</Button>
						</div>
					</Tooltip>
					<Tooltip {...noPermissionTooltipProps}>
						<div>
							<Button
								type="primary"
								size="middle"
								className="orders-button"
								onClick={handleOnClickPublish}
								data-testid="button-bidsheet-publish"
								disabled={!hasBidsheetUploadPermission}
							>
								{summaryLoading && (
									<Spin size="small" data-testid="button-bidsheet-spinner" />
								)}
								{translate(translationsRoot.publishBidsheet)}
							</Button>
						</div>
					</Tooltip>
					<ExcelExport
						fileName={translate(translationsRoot.exportFileName)}
						handleFileRequest={handleOnClickExport}
						label={translate(translationsRoot.exportBidsheet)}
						url={url}
					/>
				</Col>
			</Row>
		</div>
	);
};
