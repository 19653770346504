import { CloseOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { translations } from 'locales/i18n';
import { FieldDefinition } from 'types/FieldDefinition';
import {
	FormSchema,
	formSchemaGenerator,
} from 'utils/GenericFormGenerators/generator';

import { actions } from '../../slice';
import { View } from '../../types';

interface Props {
	fieldSchema: FieldDefinition[];
	handleSubmit: (values) => void;
	onClose: () => void;
}

export const GroupSettingsForm = ({
	fieldSchema,
	handleSubmit,
	onClose,
}: Props) => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;
	const handleClose = () => {
		dispatch(actions.setCurrentView(View.List));
		onClose();
	};

	const { initialValues, validationSchema, elements }: FormSchema =
		formSchemaGenerator(fieldSchema);
	const formattedValidationSchema = Yup.object().shape(validationSchema);
	const [nameField, transferField] = elements;

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={formattedValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors }) => (
					<Form labelCol={{ span: 3 }} wrapperCol={{ span: 9 }}>
						<Row className="generic-view-accordion__header">
							<Col flex={30}>{nameField}</Col>
							<Col flex={68} />
							<Col flex={2}>
								<CloseOutlined className="close__icon" onClick={handleClose} />
							</Col>
						</Row>
						<Divider
							key="divider"
							className="generic-view-accordion__header--divider"
						/>
						{transferField}
						{transferField.key && errors[transferField.key] && (
							<Paragraph type="danger" className="text text--right">
								{errors[transferField.key]}
							</Paragraph>
						)}

						<FormFooterButtons
							cancelLabel={translate(translationsScope.CancelButton)}
							submitLabel={translate(translationsScope.SaveGroup)}
							closeModal={handleClose}
							// the parent component (CreateNotificationGroup/EditNotificationGroup) hides this whole component when loading/submitting
							isLoading={false}
						/>
					</Form>
				)}
			</Formik>
		</>
	);
};
