import React from 'react';
import { useSelector } from 'react-redux';

import { AppliedUnappliedBushel } from 'app/containers/Contracts/components/Business/AppliedUnappliedBushel';
import { OriginCustomer } from 'app/containers/Contracts/components/Business/OriginCustomer';
import { OriginDeliveryWindow } from 'app/containers/Contracts/components/Business/OriginDeliveryWindow';
import { QuantityToSplit } from 'app/containers/Contracts/components/Business/QuantityToSplit';
import { selectApplyNameId } from 'app/containers/Contracts/selectors';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { Customer } from 'app/containers/Transactions/components/Customer';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { FlatPrice } from 'app/containers/Transactions/components/FlatPrice';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { CONSTANTS } from 'utils/constants';
import {
	customFormat,
	deliveryWindowFormat,
	validatePriceFormat,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { validateMinQuantity } from '../../../shared/helpers';
import { useTranslations } from '../../../shared/useTranslations';

export const useDefaultSchema = () => {
	const translations = useTranslations();

	const quantityErrorMsg =
		translations.validations.createContractMinQuantityValidation;
	const numberMsg = translations.validations.number;

	const requiredMsg = translations.validations.required;

	let orderData = useSelector(selectApplyNameId).data;
	if (!orderData) return;

	const quantityToSplitPlaceHolder: string = `${customFormat(orderData.quantityToApply, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`;

	return {
		initialValues: {
			contractNumber: orderData.number,
			origCustomer: {
				label: orderData.customerName,
				value: orderData.customerId,
			},
			deliveryWindow: deliveryWindowFormat(
				orderData.futuresMonth,
				orderData.deliveryStartDate,
				orderData.deliveryEndDate,
				false,
			),
			balance: `${customFormat(orderData.quantityToApply, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`,
			customer: {
				label: orderData.customerName,
				value: orderData.customerId,
			},
			futuresMonth: {
				label: orderData.futuresMonth,
				value: orderData.futuresMonth,
			},
			futuresPrice: validatePriceFormat(orderData.futuresPrice),
			postedBasis: validatePriceFormat(orderData.postedBasis),
			pushBasis: validatePriceFormat(orderData.pushBasis),
			netBasis: validatePriceFormat(orderData.netBasis),
			freight: validatePriceFormat(orderData.freightPrice),
			fees1: validatePriceFormat(orderData.fees1),
			fees2: validatePriceFormat(orderData.fees2),
			flatPrice: validatePriceFormat(orderData.price),
			employee: {
				label: orderData.employeeName,
				value: orderData.employeeId,
			},
			comments: orderData.comments,
			isUnappliedLoad: false,
		},
		validationSchema: Yup.object().shape({
			freight: Yup.number().typeError(numberMsg).nullable(),
			fees1: Yup.number().typeError(numberMsg),
			fees2: Yup.number().typeError(numberMsg),
			quantityToSplit: Yup.string()
				.typeError(numberMsg)
				.required(requiredMsg)
				.test('minValidation', quantityErrorMsg, (value: string) =>
					validateMinQuantity(value),
				)
				.test(
					'maxValidation',
					'Quantity to Split must be lower or equal to available balance',
					(value) => {
						if (value) {
							const quantity = parseFloat(
								customFormat(
									orderData.quantityToApply,
									false,
									CONSTANTS.FIXED_QUANTITY_DECIMALS,
								),
							);

							const split = parseFloat(
								customFormat(value, false, CONSTANTS.FIXED_QUANTITY_DECIMALS),
							);

							return quantity - split >= 0;
						} else {
							return false;
						}
					},
				),
			customer: Yup.object().requiredOption(requiredMsg),
			employee: Yup.object().requiredOption(requiredMsg),
		}),
		elements: [
			<ContractNumber disabled />,
			<OriginCustomer disabled />,
			<OriginDeliveryWindow deliveryDateLabel disabled />,
			<QuantityToSplit
				customPlaceholder={quantityToSplitPlaceHolder}
				disabled={{ balance: true }}
			/>,
			<AppliedUnappliedBushel />,
			<Customer />,
			<Futures
				futuresMonth={{ market: false, disabled: true }}
				futuresPrice={{ market: false, disabled: true }}
			/>,
			<Basis
				disabled={{ postedBasis: true, netBasis: true, pushBasis: true }}
				adjustedBasis
				datesModeDependency
			/>,
			<Freight />,
			<Fees />,
			<FlatPrice callTotalPriceOnMount disabled />,
			<Employee />,
			<Comments />,
		],
	};
};
