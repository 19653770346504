import { InputNumber, Popover } from 'antd';
import React, {
	Dispatch,
	memo,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FilterEmpty } from 'app/components/FilterEmpty';
import { GenericTable } from 'app/components/GenericTable';
import { shouldApplyGrayClass } from 'app/containers/TradeBook/helper';
import {
	selectSelectedBuyRows,
	selectSelectedFilters,
	selectTradeBookList,
	selectTradeBookListLoading,
} from 'app/containers/TradeBook/selectors';
import { actions } from 'app/containers/TradeBook/slice';
import { ErpStatus } from 'app/containers/TradeBook/types';
import { translations } from 'locales/i18n';
import { FixedColumn } from 'types/FixedColumn';
import { Trade } from 'types/TradeBook';
import {
	customFormat,
	dateFormat,
	hasSetFilters,
	timeFormat,
} from 'utils/helpers';

import useAllocationChange from '../../customHooks/useAllocationChange';
import { useSelectDeselect } from '../../customHooks/useSelectDeselect';
import { TableFooter } from '../TableFooter';

interface ColumnProps {
	name: string;
	title: string;
	className: string;
	fixed: FixedColumn;
	key?: string;
	dataIndex?: string;
	render: (data: Trade) => JSX.Element;
}

interface Props {
	buyDataSource: Trade[];
	setBuyDataSource: Dispatch<SetStateAction<Trade[]>>;
	initialBuyDataSource: Trade[];
	setInitialBuyDataSource: Dispatch<SetStateAction<Trade[]>>;
	sellSelectionCount: number;
	buySelectionCount: number;
	setBuySelectionCount: Dispatch<SetStateAction<number>>;
}

export const BuyTableWrapper = memo(function BuyTableWrapper(props: Props) {
	const {
		sellSelectionCount,
		buySelectionCount,
		setBuySelectionCount,
		buyDataSource,
		setBuyDataSource,
		initialBuyDataSource,
		setInitialBuyDataSource,
	} = props;
	const dispatch = useDispatch();
	const [selectedRowKeys, setselectedRowKeys] = useState<string[]>([]);
	const [editingKey, setEditingKey] = useState<string>('');

	const isEditing = (record) => record.id === editingKey;

	const shouldDisableCheckBoxes =
		buySelectionCount < 2 && sellSelectionCount >= 2 && buySelectionCount > 0;

	const translationsScope = translations.app.containers.TradeBook;

	const loading = useSelector(selectTradeBookListLoading);
	const selectedFilters = useSelector(selectSelectedFilters);
	const data = useSelector(selectTradeBookList);
	const selectedRowsData = useSelector(selectSelectedBuyRows);

	const { handleSelectDeselect } = useSelectDeselect(
		buyDataSource,
		initialBuyDataSource,
		setBuyDataSource,
	);
	const { handleSelectDeselect: persistAllocation } = useSelectDeselect(
		data?.buys,
		initialBuyDataSource,
		setBuyDataSource,
		true,
	);

	useEffect(() => {
		if (!loading) {
			const updatedBuyData = data.buys?.map((item) => ({
				...item,
				unmatched: item.filledQuantity - item.matchedQuantity,
				allocation: 0,
			}));
			const filteredBuyData = updatedBuyData?.filter(
				(item) => item.unmatched !== 0 || item.erpStatus !== ErpStatus.Success,
			);
			setBuyDataSource(filteredBuyData);
			setInitialBuyDataSource(filteredBuyData);
		}
		if (selectedRowsData.length > 0) {
			setBuySelectionCount(selectedRowsData.length);
			persistAllocation(selectedRowsData);
		}
	}, [loading]);

	const { t: translate } = useTranslation();

	const { handleAllocationChange } = useAllocationChange({
		dataSource: buyDataSource,
		setDataSource: setBuyDataSource,
		setEditingKey: setEditingKey,
	});

	useEffect(() => {
		setselectedRowKeys(selectedRowsData.map(({ id }) => id));
	}, [selectedRowsData]);

	const onChangeSelection = (_selectedIDs, selectedRowsData) => {
		setBuySelectionCount(selectedRowsData.length);
		handleSelectDeselect(selectedRowsData);

		dispatch(actions.setSelectedBuyRows(selectedRowsData));
	};

	const Item = (props) => (
		<div className="wrapper-column">
			<div className={`order__column ${props.className}`}>{props.children}</div>
		</div>
	);

	const columns: ColumnProps[] = [
		{
			name: 'createdOn',
			title: translate(translationsScope.createdOn),
			className: 'column-table sm',
			fixed: false,
			render: (data) => {
				return (
					<Item>
						<Popover
							content={
								<div className="popover--content">
									<strong>
										{data?.createdOn ? timeFormat(data.createdOn) : '---'}{' '}
									</strong>
								</div>
							}
							trigger="hover"
						>
							<div>
								<p className="text text--bold">
									{data?.createdOn ? dateFormat(data.createdOn) : '---'}
								</p>
							</div>
						</Popover>
					</Item>
				);
			},
		},
		{
			name: 'order',
			title: translate(translationsScope.order),
			className: 'column-table sm',
			fixed: false,
			render: (data) => {
				return (
					<GenericTable.Column>
						<div className="text text--bold flex-row">
							{<p className="text text--bold">{data?.marketId}</p>}
						</div>
					</GenericTable.Column>
				);
			},
		},
		{
			name: 'hrvystId',
			title: translate(translationsScope.hrvystId),
			className: 'column-table sm',
			fixed: false,
			render: (data) => (
				<Item>
					<div className="text text--bold flex-row">
						<div>
							<span className="text text--bold">
								{!!data?.internalCode ? `${data?.internalCode}` : '---'}
							</span>
						</div>
					</div>
				</Item>
			),
		},
		{
			name: 'futures',
			title: translate(translationsScope.futures),
			fixed: false,
			className: 'column-table sm',
			render: (data) => (
				<GenericTable.Column>
					<Item>
						<div className="text text--bold flex-row">
							<div>
								<span className="text text--bold">
									{!!data?.futuresMonth ? `${data?.futuresMonth}` : '---'}
								</span>
							</div>
						</div>
					</Item>
				</GenericTable.Column>
			),
		},
		{
			name: 'fillPrice',
			title: translate(translationsScope.fillPrice),
			fixed: false,
			className: 'column-table sm',
			render: (data) => (
				<GenericTable.Column>
					<Item>
						<div className="text text--bold flex-row">
							<div>
								<span className="text text--bold">
									{!!data?.price
										? `${customFormat(data?.price, true, 4)}`
										: '---'}
								</span>
							</div>
						</div>
					</Item>
				</GenericTable.Column>
			),
		},
		{
			name: 'orderQty',
			title: translate(translationsScope.orderQty),
			fixed: false,
			className: 'column-table sm',
			render: (data) => (
				<GenericTable.Column>
					<Item>
						<div className="text text--bold flex-row">
							<div>
								<span className="text text--bold">
									{!!data?.orderQuantity ? `${data?.orderQuantity}` : '---'}
								</span>
							</div>
						</div>
					</Item>
				</GenericTable.Column>
			),
		},
		{
			name: 'filledLots',
			title: translate(translationsScope.filledLots),
			fixed: false,
			className: 'column-table sm',
			render: (data) => (
				<GenericTable.Column>
					<Item>
						<div className="text text--bold flex-row">
							<div>
								<span className="text text--bold">
									{!!data?.filledQuantity ? `${data?.filledQuantity}` : '---'}
								</span>
							</div>
						</div>
					</Item>
				</GenericTable.Column>
			),
		},
		{
			name: 'unMatched',
			title: translate(translationsScope.unMatched),
			fixed: false,
			className: 'column-table sm',
			render: (data) => {
				return (
					<GenericTable.Column>
						<div className="text text--bold flex-row">
							<div>
								<span className="text text--bold">{data.unmatched}</span>
							</div>
						</div>
					</GenericTable.Column>
				);
			},
		},
		{
			name: 'allocation',
			title: translate(translationsScope.allocation),
			className: 'column-table sm',
			fixed: false,
			render: (data) => (
				<GenericTable.Column>
					<Item>
						<div className="text text--bold flex-row">
							<div>
								{isEditing(data) ? (
									<InputNumber
										onBlur={(e) =>
											handleAllocationChange(
												data,
												e.target.value,
												selectedRowsData,
											)
										}
										onPressEnter={(e) => {
											const target = e.target as HTMLInputElement;
											handleAllocationChange(
												data,
												target.value,
												selectedRowsData,
											);
										}}
										value={data.allocation}
										max={data.filledQuantity}
									/>
								) : (
									<Popover
										content={
											<div className="popover--content">
												<strong>
													{translate(translationsScope.allocationHelperText)}
												</strong>
											</div>
										}
										trigger="hover"
									>
										<span
											onClick={() => setEditingKey(data.id)}
											className="text text--bold allocation"
										>
											{data?.allocation}
										</span>
									</Popover>
								)}
							</div>
						</div>
					</Item>
				</GenericTable.Column>
			),
		},
	];

	const tableLocale = {
		emptyText:
			!loading && hasSetFilters(selectedFilters) ? (
				<FilterEmpty />
			) : (
				<FilterEmpty
					description={translate(translationsScope.emptyDescription)}
				/>
			),
	};

	return (
		<GenericTable.VirtualTable<any>
			className="table-wrapper"
			columns={columns}
			loading={loading}
			data={buyDataSource ?? []}
			rowKey={(record) => record.id}
			locale={tableLocale}
			pagination={false}
			rowClassName={shouldApplyGrayClass}
			rowSelection={{
				onChange: onChangeSelection,
				preserveSelectedRowKeys: false,
				selectedRowKeys,
				getCheckboxProps: (trade) => ({
					disabled:
						trade.erpStatus === ErpStatus.Fail ||
						trade.erpStatus === ErpStatus.Pending ||
						shouldDisableCheckBoxes,
				}),
			}}
			title={() => translate(translationsScope.buy)}
			footer={(data: readonly Trade[]) => (
				<TableFooter data={data as Trade[]} />
			)}
		/>
	);
});
