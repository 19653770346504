import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { View } from '../types';

const { identity } = apiRoutes;
const { userClaimsByEmail, userClaims } = apiEndpoints;

export function* loadEmployeesPermissions(action: PayloadAction<any>) {
	const email = action.payload;
	const requestURL = yield new URL(`${identity}/${userClaimsByEmail}/${email}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		null,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.employeesPermissionsLoaded(responseData.claimGrants));
		yield put(actions.setEmployeeUserID(responseData.userId));
	} else if (!!responseError.detail) {
		yield put(actions.employeeError(responseError));
	}
}

export function* updateEmployeePermissions(action: PayloadAction<any>) {
	const { data, successMessage } = action.payload;

	const { userId } = data;
	const requestURL = yield new URL(
		`${identity}/${userClaims}?userId=${userId}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data as any,
	);

	if (!!responseData) {
		yield put(actions.employeesPermissionsUpdated());
		yield put(actions.setCurrentView(View.List));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.updateEmployeesPermissionsError(responseError));
	}
}

export function* updateEmployeePermissionsLocations(
	action: PayloadAction<any>,
) {
	const { data, successMessage } = action.payload;

	const { userId } = data;
	const requestURL = yield new URL(
		`${identity}/${userClaims}?userId=${userId}`,
	);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data as any,
	);

	if (!!responseData) {
		yield put(actions.employeesPermissionsUpdated());
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.updateEmployeesPermissionsError(responseError));
	}
}
