import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

export const useSummaryTranslations = () => {
	const { t: translate } = useTranslation();
	const domain = translations.app.containers.Transactions.components.Details;
	return {
		hrvyst: {
			id: translate(domain.hrvystID),
		},
		contract: {
			contractNumber: translate(domain.contractNumber),
			parentContract: translate(domain.parentContract),
			contractType: translate(domain.contractType),
			salePill: translate(
				translations.app.components.IsSellRoundPill.sellAbbreviated,
			),
			purchasePill: translate(
				translations.app.components.IsSellRoundPill.purchaseAbbreviated,
			),
			contractCommodity: translate(domain.contractCommodity),
			contractCrop: translate(domain.contractCrop),
			contractFutureM: translate(domain.contractFutureM),
			contractQuantity: translate(domain.contractQuantity),
			contractUnpriced: translate(domain.contractUnpriced),
			originatedOfferId: translate(domain.originatedOfferId),
			theirContract: translate(domain.theirContract),
		},
		offer: {
			offerNumber: translate(domain.offerNumber),
			offerCommodiy: translate(domain.offerCommodiy),
			offerExpiration: translate(domain.offerExpiration),
			offerGtc: translate(domain.offerGtc),
			offerCrop: translate(domain.offerCrop),
			offerFuturesMonth: translate(domain.offerFuturesMonth),
			offerQuantity: translate(domain.offerQuantity),
			offerUnfilledQuantity: translate(domain.offerUnfilledQuantity),
			theirContract: translate(domain.theirContract),
		},
		reviewAndRelease: {
			orderNumber: translate(domain.orderNumber),
			accountNumber: translate(domain.accountNumber),
			orderSource: translate(domain.orderSource),
			orderSymbol: translate(domain.orderSymbol),
			orderType: translate(domain.orderType),
			orderMonthYear: translate(domain.orderMonthYear),
			orderGTC: translate(domain.orderGTC),
			orderIsGTC: translate(domain.orderIsGTC),
			orderIsNotGTC: translate(domain.orderIsNotGTC),
			orderPrice: translate(domain.orderPrice),
			orderAvgFillPrice: translate(domain.orderAvgFillPrice),
			orderQuantity: translate(domain.orderQuantity),
			orderWorkingQuantity: translate(domain.orderWorkingQuantity),
			orderFilledQuantity: translate(domain.orderFilledQuantity),
			orderRejectedReason: translate(domain.orderRejectedReason),
			theirContract: translate(domain.theirContract),
		},
		preHedge: {
			orderGTC: translate(domain.orderGTC),
			orderIsGTC: translate(domain.orderIsGTC),
			orderIsNotGTC: translate(domain.orderIsNotGTC),
			quantityOnHedge: translate(domain.quantityOnHedge),
			orphanedBalance: translate(domain.orphanedBalance),
		},
		employee: {
			createdOn: translate(domain.createdOn),
			createdBy: translate(domain.createdBy),
			updatedOn: translate(domain.updatedOn),
			updatedBy: translate(domain.updatedBy),
		},
		customer: {
			customerName: translate(domain.customerName),
			cellPhone: translate(domain.cellPhone),
			email: translate(domain.email),
			adjustment: translate(domain.adjustmentCustomer),
		},
		deliveryInfo: {
			deliveryStartDate: translate(domain.headersDeliveryStartDate),
			deliveryEndDate: translate(domain.headersDeliveryEndDate),
			expirationDate: translate(domain.headersExpirationDate),
			destination: translate(domain.destination),
			location: translate(domain.location),
			region: translate(domain.region),
		},
		prices: {
			futures: translate(domain.futures),
			freight: translate(domain.freight),
			serviceFee: translate(domain.serviceFee),
		},
		basis: {
			postedB: translate(domain.postedB),
			pushB: translate(domain.pushB),
			netB: translate(domain.netB),
			adjustedB: translate(domain.adjustedB),
		},
		cashPrice: translate(domain.cashPrice),
		unpriced: translate(domain.unpriced),
		quantity: {
			quantity: translate(domain.quantity),
		},
		customerTitle: translate(domain.customerTitle),
		employeeTitle: translate(domain.employeeTitle),
		deliveryInfoTitle: translate(domain.deliveryInfoTitle),
		customFields: {
			isFieldFalse: translate(domain.isFieldFalse),
			isFieldTrue: translate(domain.isFieldTrue),
		},
	};
};
