import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';

const { cropsRoot } = apiEndpoints;

export function* getCropsList() {
	const requestURL = new URL(`${apiRoutes.base}/${cropsRoot}`);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(z.number().int()),
	});

	if (!!data) {
		yield* put(actions.cropsListLoaded(data));
	} else if (!!error?.detail) {
		yield* put(actions.cropsError(error));
	}
}
