import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { groupedLocationSchema } from 'types/GroupedLocation';
import { locationSchema } from 'types/Location';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';

const { locationsRoot, groupedLocations } = apiEndpoints;

const locationsResponseSchema = z.object({
	total: z.number().int(),
	locations: z.array(locationSchema),
});

export function* getLocationsList() {
	const requestURL = new URL(`${apiRoutes.base}/${locationsRoot}`);
	requestURL.searchParams.set('IsLocation', 'true');

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: locationsResponseSchema,
	});

	if (!!data) {
		yield* put(actions.locationsListLoaded(data.locations));
	} else if (!!error?.detail) {
		yield* put(actions.locationsListError(error));
	}
}

export function* getGroupedLocationsList() {
	const requestURL = new URL(`${apiRoutes.base}/${groupedLocations}`);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(groupedLocationSchema),
	});

	if (!!data) {
		yield* put(actions.groupedLocationsListLoaded(data));
	} else if (!!error?.detail) {
		yield* put(actions.groupedLocationsListError(error));
	}
}
