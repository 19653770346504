import { useSelector } from 'react-redux';

import { useFieldFactory } from '../../../../definitions/fields';
import { selectActiveCommodity } from '../../selectors';

export const useEditCommoditySchema = (
	hasAdditionalCropYear: boolean,
	setHasAdditionalCropYear: (value: boolean) => void,
) => {
	const activeCommodity = useSelector(selectActiveCommodity);

	const {
		erpNumber,
		hedgeFutures,
		commodity,
		priceCtrl,
		basisCtrl,
		addAdditionalCropYear,
	} = useFieldFactory();

	const {
		name,
		priceControl,
		basisControl,
		hedgeFutures: hedgeFuturesValue,
		number,
		numberOfCropYears,
	} = activeCommodity || {};

	const erpNumberInput = {
		...erpNumber,
		disabled: true,
		initialValue: number,
	};

	const commodityName = {
		...commodity,
		disabled: true,
		initialValue: name,
	};

	const priceCtrlInput = {
		...priceCtrl,
		initialValue: priceControl,
	};

	const basisCtrlInput = {
		...basisCtrl,
		initialValue: basisControl,
	};

	const hedgeFuturesInput = {
		...hedgeFutures,
		disabled: true,
		initialValue: hedgeFuturesValue,
	};

	const additionalCropYear = {
		...addAdditionalCropYear,
		initialValue: hasAdditionalCropYear,
		handleChange: () => {
			setHasAdditionalCropYear(!hasAdditionalCropYear);
		},
	};

	const editCommoditySchema = [
		erpNumberInput,
		hedgeFuturesInput,
		commodityName,
		priceCtrlInput,
		basisCtrlInput,
		additionalCropYear,
	];

	/**
	 * If the commodity has 4 crop years, remove the additional crop year checbox field field from the ui
	 */
	if (numberOfCropYears === 4) {
		const index = editCommoditySchema.indexOf(additionalCropYear);
		if (index > -1) {
			// only splice array when item is found
			editCommoditySchema.splice(index, 1); // 2nd parameter means remove one item only
		}
	}

	return editCommoditySchema;
};
