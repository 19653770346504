import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';
import { preventWheelChange, preventWheelEvent } from 'utils/util';

import { SETUP_FORM_ITEMS } from '../../types';

const domain =
	translations.app.containers.Settings.sections.ERPIntegration.components
		.CommentsLength;

export const CommentsLength = memo(function CommentsLength() {
	const { t: translate } = useTranslation();

	return (
		<GenericForm.FormItem
			data-testid={`${SETUP_FORM_ITEMS.CommentsLength}-form-item`}
			label={translate(domain.label)}
			name={SETUP_FORM_ITEMS.CommentsLength}
		>
			<Input
				name={SETUP_FORM_ITEMS.CommentsLength}
				type="number"
				onWheel={preventWheelChange}
				onFocus={preventWheelEvent}
			/>
		</GenericForm.FormItem>
	);
});
