import './style.scss';

import {
	ArrowsAltOutlined,
	CheckCircleOutlined,
	CheckSquareOutlined,
	ColumnHeightOutlined,
	DollarCircleOutlined,
	EditOutlined,
	HistoryOutlined,
	RotateRightOutlined,
	StopOutlined,
	SyncOutlined,
	UndoOutlined,
} from '@ant-design/icons';
import { Popover, Typography } from 'antd';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ContractTypePill } from 'app/components/ContractTypePill';
import {
	BookAndOfferIcon,
	CreatedFromFilledOfferIcon,
	FilledOfferIcon,
	PartiallyFilledOfferIcon,
} from 'app/components/CustomIcon';
import { GenericTable } from 'app/components/GenericTable';
import { UserAvatar } from 'app/components/UserAvatar';
import { TransactionEvents } from 'app/containers/Transactions/types';
import { translations } from 'locales/i18n';
import { CONSTANTS } from 'utils/constants';
import {
	currencyFormat,
	customFormat,
	dateFormat,
	timeFormat,
} from 'utils/helpers';

const { Paragraph } = Typography;

interface Props {
	activeTransactionDetails: any;
	transactionType: string;
}

export const History = memo(function History({
	activeTransactionDetails,
	transactionType,
}: Props) {
	const translationsScope =
		translations.app.containers.OrderContainer.components.OrdersTable;

	const loading = activeTransactionDetails.loading;
	const transactionHistory = activeTransactionDetails.data?.history;
	const { t: translate } = useTranslation();

	const columns = [
		{
			title: translate(translationsScope.created),
			key: 'created',
			className: 'md',
			fixed: true,
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--bold text--large">
						{dateFormat(data.createdOn)}
					</p>
					<p className="text text--label">{timeFormat(data.createdOn)}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.contractNumber),
			key: 'contract',
			fixed: true,
			className: 'md',
			render: (data) => (
				<GenericTable.Column>
					<div className="text text--bold flex-column flex-row--space-around">
						<span className="text text--large text--bold">
							{data?.contract?.number || '---'}
						</span>

						<div>
							<ContractTypePill
								contractType={data.contract.type}
								isSell={data.isSell}
							/>
						</div>
					</div>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.hrvystId),
			key: 'contract',
			className: 'md',
			render: (data) => (
				<GenericTable.Column>
					<div className="text text--bold flex-row flex-row--space-between">
						<div>
							<span className="text text--large text--bold">
								{data?.offerNumber || data?.contract?.internalNumber || '---'}
							</span>
							<span>{data?.parentNumber || ''}</span>
						</div>
					</div>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.event),
			key: 'event',
			className: 'xs',
			render: (data) => {
				let statusClassName;
				let icon;

				const testId = `status-icon-${data.id}`;

				const popoverMessage = data.isEditReject
					? translate(translationsScope.editRejected)
					: data.event?.description;

				if (data.isEditReject) {
					statusClassName = 'order__column--cancel';
					icon = <UndoOutlined />;
				} else if (data.event?.name === TransactionEvents.Create) {
					statusClassName = 'contract__column--open';
					icon = <CheckCircleOutlined />;
				} else if (data.event?.name === TransactionEvents.Price) {
					statusClassName = 'contract__column--price';
					icon = <DollarCircleOutlined />;
				} else if (data.event?.name === TransactionEvents.Cancel) {
					statusClassName = 'contract__column--cancel';
					icon = <StopOutlined />;
				} else if (
					data.event?.name === TransactionEvents.Roll &&
					transactionType === 'Contract'
				) {
					statusClassName = 'contract__column--roll';
					icon = <SyncOutlined />;
				} else if (
					data.event?.name === TransactionEvents.Roll &&
					transactionType === 'Offer'
				) {
					statusClassName = 'contract__column--roll';
					icon = <HistoryOutlined />;
				} else if (data.event?.name === TransactionEvents.Threshold) {
					statusClassName = 'contract__column--threshold';
					icon = <ColumnHeightOutlined />;
				} else if (data.event?.name === TransactionEvents.Book) {
					statusClassName = 'contract__column--book';
					icon = <BookAndOfferIcon />;
				} else if (data.event?.name === TransactionEvents.Edit) {
					statusClassName = 'contract__column--edit';
					icon = <EditOutlined />;
				} else if (data.event?.name === TransactionEvents.RollBack) {
					statusClassName = 'contract__column--rollback';
					icon = <EditOutlined />;
				} else if (data.event?.name === TransactionEvents.Convert) {
					statusClassName = 'contract__column--convert';
					icon = <RotateRightOutlined />;
				} else if (
					data.event?.name === TransactionEvents.CreatedFromFilledOffer
				) {
					icon = <CreatedFromFilledOfferIcon />;
				} else if (data.event?.name === TransactionEvents.Filled) {
					icon = <FilledOfferIcon />;
				} else if (data.event?.name === TransactionEvents.PartiallyFilled) {
					icon = <PartiallyFilledOfferIcon />;
				} else if (data.event?.name === TransactionEvents.ApplyNameId) {
					statusClassName = 'contract__column--applynameid';
					icon = <ArrowsAltOutlined />;
				} else if (data.event?.name === TransactionEvents.Completed) {
					statusClassName = 'contract__column--completed';
					icon = <CheckSquareOutlined />;
				} else if (data.event?.name === TransactionEvents.Undo) {
					statusClassName = 'order__column--cancel';
					icon = <UndoOutlined />;
				}

				return (
					<GenericTable.Column>
						<div
							data-testid={testId}
							className={classNames('contract__column', statusClassName)}
						>
							<Popover
								placement="top"
								content={
									<div className="popover-content">{popoverMessage}</div>
								}
								trigger="hover"
							>
								{icon}
							</Popover>
						</div>
					</GenericTable.Column>
				);
			},
		},
		...(transactionType === 'Offer'
			? [
					{
						title: translate(translationsScope.expiration),
						key: 'expiration',
						className: 'sm',
						render: (data) => (
							<GenericTable.Column>
								<p className="text text--large text--centered">
									{data.gtc
										? translate(translationsScope.gtc)
										: dateFormat(data.expiration)}
								</p>
							</GenericTable.Column>
						),
					},
				]
			: []),
		{
			title: translate(translationsScope.customer),
			key: 'customer',
			className: 'column-table md md--content-limit',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--centered text--large line-height">
						{data?.customerNumber}
					</p>
					<Paragraph
						className="text text--centered text--large line-height"
						title={data?.customerName}
						ellipsis={{ rows: 2 }}
					>
						{data?.customerName}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.employee),
			key: 'employee',
			className: 'xs',
			render: (data) => {
				return (
					<GenericTable.Column>
						<UserAvatar user={data.isEditReject ? 'internal' : data.employee} />
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.commodity),
			key: 'commodity',
			className: 'xs xs--content-limit',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data.commodityName}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data.commodityName}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.crop),
			key: 'crop',
			className: 'xs',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--bold text--large text--centered">
						{data.cropYear}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.futuresMonth),
			key: 'futuresMonth',
			className: 'xs',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large text--centered">{data.futuresMonth}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.destination),
			key: 'destination',
			className: 'xs xs--content-limit header--nowrap',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data.delivery.destination}
						ellipsis={{ rows: 2 }}
					>
						{data.delivery.destination}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.deliveryStart),
			key: 'deliveryStart',
			className: 'sm',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">{dateFormat(data.delivery.begin)}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.deliveryEnd),
			key: 'deliveryEnd',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">{dateFormat(data.delivery.end)}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.freightPrice),
			key: 'freightPrice',
			className: 'sm',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">
						{customFormat(
							data.freightPrice ?? 0,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						)}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.fees1),
			key: 'freightPrice',
			className: 'sm',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">
						{data.fees1
							? customFormat(
									data.fees1,
									true,
									CONSTANTS.FIXED_QUANTITY_DECIMALS,
								)
							: '---'}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.fees2),
			key: 'freightPrice',
			className: 'sm',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">
						{data.fees2
							? customFormat(
									data.fees2,
									true,
									CONSTANTS.FIXED_QUANTITY_DECIMALS,
								)
							: '---'}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.comments),
			key: 'comments',
			className: 'sm sm--content-limit',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data.comments || '---'}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data.comments || '---'}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		...(transactionType === 'Contract'
			? [
					{
						title: translate(translationsScope.balance),
						key: 'quantity',
						className: 'sm',
						render: (data) => (
							<GenericTable.Column>
								<p className="text text--large text--bold">
									{customFormat(
										data.quantity.grossRemainingBalance,
										true,
										CONSTANTS.FIXED_QUANTITY_DECIMALS,
									)}
								</p>

								<p className="text">
									<span>
										{`${translate(translationsScope.unpriced)} `}
										{data.quantity.remainingBalance
											? customFormat(
													data.quantity.remainingBalance,
													true,
													CONSTANTS.FIXED_QUANTITY_DECIMALS,
												)
											: '---'}
									</span>
								</p>
							</GenericTable.Column>
						),
					},
					{
						title: translate(translationsScope.quantity),
						key: 'quantity',
						className: 'sm',
						fixed: 'right' as 'right',
						render: (data) => (
							<GenericTable.Column>
								<p className="text text--large text--bold">
									{customFormat(
										data.quantity.quantityOnContract,
										true,
										CONSTANTS.FIXED_QUANTITY_DECIMALS,
									)}
								</p>
							</GenericTable.Column>
						),
					},
				]
			: [
					{
						title: translate(translationsScope.quantity),
						key: 'quantity',
						className: 'sm',
						fixed: 'right' as 'right',
						render: (data) => (
							<GenericTable.Column>
								<p className="text text--large text--bold">
									{customFormat(
										data.quantity.quantity,
										true,
										CONSTANTS.FIXED_QUANTITY_DECIMALS,
									)}
								</p>

								<p className="text">
									<span>
										{`${translate(translationsScope.unpriced)} `}
										{data.quantity.unfilled
											? customFormat(
													data.quantity.unfilled,
													true,
													CONSTANTS.FIXED_QUANTITY_DECIMALS,
												)
											: '---'}
									</span>
								</p>
							</GenericTable.Column>
						),
					},
				]),
		{
			title: translate(translationsScope.futures),
			key: 'futures',
			className: 'sm',
			fixed: 'right' as 'right',
			render: (data) =>
				(!!!data.passFill || data.futures !== null) && ( // The order isn't filled yet
					<GenericTable.Column>
						<p className="text text--large text--centered">
							{currencyFormat(
								data.futures,
								CONSTANTS.FIXED_DECIMALS,
								CONSTANTS.FIXED_DECIMALS,
							)}
						</p>
					</GenericTable.Column>
				),
		},
		{
			title: translate(translationsScope.basis),
			key: 'basis',
			className: 'sm',
			fixed: 'right' as 'right',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large text--centered">
						{currencyFormat(
							data.basis.net,
							CONSTANTS.FIXED_DECIMALS,
							CONSTANTS.FIXED_DECIMALS,
						)}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.cashPrice),
			key: 'cashPrice',
			className: 'sm',
			fixed: 'right' as 'right',
			render: (data) =>
				(!!!data.passFill || data.price !== null) && ( // The order isn't filled yet
					<GenericTable.Column>
						<p className="text text--large text--centered">
							{currencyFormat(
								data.price,
								CONSTANTS.FIXED_DECIMALS,
								CONSTANTS.FIXED_DECIMALS,
							)}
						</p>
					</GenericTable.Column>
				),
		},
	];

	const isViaOffer = ({ offerNumber }) => {
		if (offerNumber) {
			return 'table-colum--yellow';
		}
		return '';
	};
	return (
		<>
			<div>
				<p className="text text--large text--bold">{`${transactionType} History`}</p>
			</div>
			<GenericTable.Table<any>
				otherClassName="table-wrapper"
				columns={transactionHistory ? columns : []}
				loading={loading}
				data={transactionHistory || []}
				rowKey={(record) => record.id}
				onRow={(record) => {
					return {
						className:
							transactionType === 'Contract'
								? `border-left--${record.status.toLowerCase()} `
								: `border-left-offer--${record?.status?.trim().split(' ').join('-').toLowerCase()}`,
					};
				}}
				pagination={false}
				rowClassName={isViaOffer}
			/>
		</>
	);
});
