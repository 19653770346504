export const ROUTES = {
	HOME: '/',
	TRANSACTIONS: '/transactions',
	REPORTS: '/reports',
	SETTINGS: '/settings',
	LIVELEDGER: '/live-ledger',
	LOGIN: '/login',
	AUTH_CALLBACK: '/auth-callback',
	DEFAULT: '/not-found',
	CASHBIDS_QUOTES: '/cash-bids-quotes',
	HEDGE_SUMMARY: '/hedge-summary',
	NOTIFICATIONS_HISTORY: '/notifications-history',
	MARKETS: '/markets',
};
