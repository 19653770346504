import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmationModal } from 'app/components/ConfirmationModal';
import { selectSelectedRows } from 'app/containers/Offers/selectors';
import { actions } from 'app/containers/Offers/slice';

import { useTranslations } from '../shared/useTranslations';

export const BookMultipleModal = () => {
	const dispatch = useDispatch();

	const translations = useTranslations();
	const selectedRowsData = useSelector(selectSelectedRows);

	const handleCancel = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const handleConfirmation = () => {
		dispatch(actions.bookMultipleOffers(selectedRowsData));
		dispatch(actions.setCurrentModal(null));
	};

	return (
		<ConfirmationModal
			body={translations.bookModal.body}
			handleCancel={handleCancel}
			handleConfirmation={handleConfirmation}
			title={translations.bookModal.title}
		/>
	);
};
