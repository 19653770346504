import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';

import { SETUP_FORM_ITEMS } from '../../types';

const domain =
	translations.app.containers.Settings.sections.ERPIntegration.components
		.Username;

export const Username = memo(function Username() {
	const { t: translate } = useTranslation();

	const getTranslation = (key: string) => translate(domain[key]);

	return (
		<GenericForm.FormItem
			data-testid={`${SETUP_FORM_ITEMS.Username}-form-item`}
			label={getTranslation('label')}
			name={SETUP_FORM_ITEMS.Username}
		>
			<Input
				name={SETUP_FORM_ITEMS.Username}
				placeholder={getTranslation('placeholder')}
			/>
		</GenericForm.FormItem>
	);
});
