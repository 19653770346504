import './style.scss';

import { CloseOutlined } from '@ant-design/icons';
import { Card, Col } from 'antd';
import React, { memo } from 'react';

interface Props {
	activeEntity: any;
	span: number;
	noBorder?: boolean;
	dataTestId: string;
	handleClose: () => void;
}
export const PreHedgeActionsSection = memo(function PreHedgeActionsSection(
	props: Props,
) {
	const { activeEntity, span, dataTestId, handleClose } = props;

	return (
		activeEntity && (
			<Col span={span}>
				<Card
					className="contract summary-actions"
					data-testid={dataTestId}
					bordered={false}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className="divider">
						<CloseOutlined
							className="action-icon close"
							onClick={handleClose}
							data-testid="close-order-button"
						/>
					</div>
				</Card>
			</Col>
		)
	);
});
