import React from 'react';
import { useSelector } from 'react-redux';

import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';
import { mapToLabelValue } from 'utils/helpers';
import Yup from 'yupCustom';

import { Address } from '../../../components/Address';
import { Cellphone } from '../../../components/Cellphone';
import { Email } from '../../../components/Email';
import { ErpNumber } from '../../../components/ErpNumber';
import { Name } from '../../../components/Name';
import { Notify } from '../../../components/Notify';
import { selectCountriesList } from '../../../selectors';

export const useCreateCustomerSchema = () => {
	const countriesList = useSelector(selectCountriesList).data;
	const translations = UseFieldTranslations();
	const requiredMsg = translations.validationSchema.required;
	const validEmail = translations.validationSchema.invalidEmail;

	return {
		initialValues: {
			address: '',
			cellphone: '',
			city: '',
			country: mapToLabelValue(countriesList)?.[0],
			email: '',
			erpNumber: '',
			firstName: '',
			lastName: '',
			state: undefined,
			zipCode: '',
			isMobileAppEnable: false,
			mobileAppLimit: null,
			isEmailNotificationEnabled: false,
			isSmsNotificationEnabled: false,
		},
		validationSchema: Yup.object().shape({
			address: Yup.string().required(requiredMsg),
			city: Yup.string().required(requiredMsg),
			cellphone: Yup.string().nullable(),
			country: Yup.object().required(requiredMsg),
			email: Yup.string().email(validEmail),
			erpNumber: Yup.string().required(requiredMsg),
			firstName: Yup.string().required(requiredMsg),
			lastName: Yup.string().required(requiredMsg),
			state: Yup.object().required(requiredMsg),
			zipCode: Yup.string().required(requiredMsg),
		}),
		elements: [
			<ErpNumber />,
			<Name />,
			<Cellphone />,
			<Address />,
			<Email />,
			<Notify />,
		],
	};
};
