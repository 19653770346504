import React from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

import { LongShortSummary } from '../components/LongShortSummary';

export const UseFieldTranslations = () => {
	const { t: translate } = useTranslation();
	return {
		longAndShort: translate(
			translations.app.containers.SummaryDashboard.tab.longAndShort,
		),
		cashSummary: translate(
			translations.app.containers.SummaryDashboard.tab.cashSummary,
		),
		basisSummary: translate(
			translations.app.containers.SummaryDashboard.tab.basisSummary,
		),
		lsRecap: translate(
			translations.app.containers.SummaryDashboard.tab.lsRecap,
		),
		managementDashboard: translate(
			translations.app.containers.SummaryDashboard.tab.managementDashboard,
		),
	};
};

export const summaryDashboardTabs = [
	{
		name: 'longAndShort',
		content: <LongShortSummary />,
	},
];
