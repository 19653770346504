import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';
import { genericItemSchema } from 'utils/validators';

import { actions } from '../slice';

const { hedgeAccount } = apiEndpoints;

const hedgeAccountsResponseSchema = z.object({
	total: z.number().int(),
	hedgeAccounts: z.array(genericItemSchema),
});

export function* getHedgeAccountList() {
	const requestURL = new URL(`${apiRoutes.base}/${hedgeAccount}`);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: hedgeAccountsResponseSchema,
	});

	if (!!data) {
		yield* put(actions.hedgeAccountListLoaded(data.hedgeAccounts));
	} else if (!!error?.detail) {
		yield* put(actions.hedgeAccountError(error));
	}
}
