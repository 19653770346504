import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { translations } from 'locales/i18n';

import {
	generateDownloadLink,
	generateDragAndDrop,
} from '../../../../../../../utils/GenericFormInputs/generic-form-inputs';
import {
	selectCommodityInfo,
	selectDownloadTemplateUrl,
	selectHedgeMapTemplateHedgeUpdate,
	selectRegionInfo,
} from '../../selectors';
import { hedgeMappingActions } from '../../slice';

export const useHedgeMapping = () => {
	const SUPPORTED_FORMATS = [
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	];
	const template = useSelector(selectHedgeMapTemplateHedgeUpdate);
	const excelData = useSelector(selectDownloadTemplateUrl);
	const { t: translate } = useTranslation();
	const [preUploadErrors, setPreUploadErrors] = useState<string[]>([]);
	const [processedFile, setProcessedFile] = useState<string>('');
	const dispatch = useDispatch();
	const commodityId = useSelector(selectCommodityInfo);
	const regionId = useSelector(selectRegionInfo);
	const domain =
		translations.app.containers.Settings.sections.HedgeMapping.UploadHedgeMap;

	const clearUploadHedge = () => {
		dispatch(hedgeMappingActions.clearHedgeMapUpdateTemplate());
		setProcessedFile('');
	};
	const beforeUpload = (file, setProgress) => {
		clearUploadHedge();
		setProcessedFile('');

		let errors: any = [];
		setProgress(20);
		const isXlsx =
			file.type ===
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		if (!isXlsx) {
			errors.push(translate(domain.fileFormatValidation));
			setProgress(0);
		}
		const isLessThan25M = file.size / 1024 / 1024 < 25;
		if (!isLessThan25M) {
			errors.push(translate(domain.fileSizeValidation));
			setProgress(0);
		}
		setPreUploadErrors(errors);
		return isXlsx && isLessThan25M;
	};

	const onUpload = (options, setProgress) => {
		clearUploadHedge();
		const { file, onProgress } = options;
		const fmData = new FormData();

		fmData.append('File', file);
		fmData.append('CommodityId', commodityId);
		if (regionId) fmData.append('regionId', regionId);

		const config = {
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				setProgress(percent);
				onProgress({ percent: (event.loaded / event.total) * 100 });

				if (percent === 100) {
					setTimeout(() => setProgress(0), 1000);
				}
			},
		};

		setProcessedFile(file.name);
		dispatch(
			hedgeMappingActions.uploadHedgeMapTemplateUpdated({
				data: fmData,
				config,
			}),
		);
	};

	const downLoadLinkHedging = {
		name: 'downloadLink',
		key: 'downloadLink',
		render: generateDownloadLink,
		show: true,
		url: excelData,
		fileName: `hedgemapping.xlsx`,
		disabled: template.uploadResponse.status === 200,
		isUploaded: template.uploadResponse.status === 200,
		isLoading: template.loading,
		uploadCompleteText: translate(domain.uploadCompleteText),
		uploadingText: translate(domain.uploadText),
		downloadText: translate(domain.downloadFile),
		onDeleteIcon: clearUploadHedge,
		hide:
			(template.uploadResponse.data &&
				template.uploadResponse.data.data.hasErrors) ||
			!!preUploadErrors.length,
	};

	const useHedgeMappingComponent = {
		name: 'hedgeMappingHedgeUpdate',
		key: 'hedgeMappingHedgeUpdate',
		// errors: false,
		initialValue: null,
		render: generateDragAndDrop,
		dragText: translate(domain.dragText),
		downloadText: translate(domain.downloadFile),
		uploadText: translate(domain.uploadText),
		uploadCompleteText: translate(domain.uploadCompleteText),
		errorList: template.uploadResponse.data
			? template.uploadResponse.data.data.errors
			: preUploadErrors,
		fileName: processedFile,
		showFileName: false,
		beforeUpload,
		onUpload,
		template,
		validation: Yup.mixed()
			.required(translate(domain.requiredFile))
			.test('fileSize', ' ', (value) => value && value.size / 1024 / 1024 < 25)
			.test(
				'fileFormat',
				' ',
				(value) => value && SUPPORTED_FORMATS.includes(value.type),
			)
			.test('wrongValues', ' ', (value) => {
				if (value && template.uploadResponse.data) {
					if (
						template.uploadResponse.data &&
						template.uploadResponse.data.data.hasErrors
					) {
						return false;
					} else {
						return true;
					}
				} else return true;
			}),
	};

	return [useHedgeMappingComponent, downLoadLinkHedging];
};
