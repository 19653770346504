import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CreateCommodity } from '../Commodities/Modals/CreateCommodity';
import { EditCommodity } from './Modals/EditCommodity';
import {
	selectActiveCommodity,
	selectActiveCommodityLoading,
	selectCurrentModal,
} from './selectors';
import { actions } from './slice';
import { Modal } from './types';
import { List } from './View/List';
export const Commodities = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.loadProducts());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const currentModal = useSelector(selectCurrentModal);
	const selectedCommodityData = useSelector(selectActiveCommodity);
	const editCommodityLoading = useSelector(selectActiveCommodityLoading);
	return (
		<>
			<List />
			{currentModal === Modal.Create && <CreateCommodity />}
			{currentModal === Modal.Edit &&
				!editCommodityLoading &&
				selectedCommodityData && <EditCommodity />}
		</>
	);
};
