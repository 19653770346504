import './style.scss';

import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';
import { GenericOptionData } from 'types/GenericOption';
import { mapToLabelValue } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';

import { selectActiveEmployee } from '../../selectors';
import { actions } from '../../slice';
import { Modal } from '../../types';

export const ViewLocation = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const regionsOptions = useEnabledRegions(
		mapToLabelValue,
	) as GenericOptionData[];
	const activeEmployee = useSelector(selectActiveEmployee);

	const domain =
		translations.app.containers.Settings.sections.Employees.Modals.Location;

	const handleNavigateToViewLocationModal = (event: {
		preventDefault: () => void;
	}) => {
		event.preventDefault();
		dispatch(actions.setCurrentModal(Modal.Location));
		dispatch(
			actions.getEmployeeLocations({
				id: activeEmployee?.id,
				regionId: regionsOptions.length && regionsOptions[0].value,
			}),
		);
	};

	return (
		<>
			<Button
				className="view_location"
				key="view_location"
				onClick={handleNavigateToViewLocationModal}
			>
				{translate(domain.viewLocation)}
			</Button>
		</>
	);
};
