import './styles.scss';

import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { SwitchStateFull } from 'app/components/SwitchStateFull';
import { translations } from 'locales/i18n';
import { CONSTANTS } from 'utils/constants';
import { customFormat } from 'utils/helpers';

import {
	selectCustomerStatusError,
	selectFilters,
	selectListData,
	selectListLoading,
	selectListTotal,
	selectPaginationLimit,
	selectPaginationStart,
} from '../../../selectors';
import { actions } from '../../../slice';
import { FiltersEnum } from '../../../types';
import { Actions } from './Actions';

const { Paragraph } = Typography;

export const Table = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.Customers;
	const translationsShared = translations.app.containers.Settings.Shared;
	const dispatch = useDispatch();
	const listData = useSelector(selectListData);
	const listTotal = useSelector(selectListTotal);
	const listLoading = useSelector(selectListLoading);
	const start = useSelector(selectPaginationStart);
	const limit = useSelector(selectPaginationLimit);
	const filters = useSelector(selectFilters);
	const columns = [
		{
			title: translate(translationsScope.View.List.Table.Headers.Status),
			dataIndex: 'isActive',
			key: 'isActive',
			fixed: true,
			width: 70,
			render: (data, record) => (
				<GenericTable.Column>
					<SwitchStateFull
						initialStatus={data}
						recordId={record.id}
						toggleAction={actions.setCustomerStatus}
						errorSelector={selectCustomerStatusError}
						toogleOnMessage={translate(
							translationsScope.View.List.Table.activateSwitch.toogleOnMessage,
						)}
						toggleOffMessage={translate(
							translationsScope.View.List.Table.activateSwitch.toggleOffMessage,
						)}
						confirmButtonText={translate(
							translationsScope.View.List.Table.activateSwitch
								.confirmButtonText,
						)}
						cancelButtonText={translate(
							translationsScope.View.List.Table.activateSwitch.cancelButtonText,
						)}
						stateControlled={false}
					/>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Erp),
			dataIndex: 'number',
			key: 'number',
			fixed: true,
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Name),
			key: 'name',
			render: (data) => {
				const name = `${data.lastName} ${data.firstName}`;
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis"
							title={name}
							ellipsis={{ rows: 2 }}
						>
							<p className="text text--large">{name}</p>
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.CellPhone),
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Address),
			key: 'address',
			render: (data) => {
				const address = `${data.street}, ${data.city}. ${data.state} ${data.zipCode} ${data.country}`;
				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis"
							title={address}
							ellipsis={{ rows: 2 }}
						>
							<p className="text text--large">{address}</p>
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Email),
			dataIndex: 'email',
			key: 'email',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Notifications),
			children: [
				{
					title: translate(translationsScope.View.List.Table.Headers.Email),
					dataIndex: 'isEmailNotificationEnabled',
					width: 100,
					className: 'notification--column',
					render: (data) => (
						<GenericTable.Column>
							{data ? (
								<CheckCircleFilled className="notification--icon" />
							) : (
								<CloseCircleOutlined className="notification--icon" />
							)}
						</GenericTable.Column>
					),
				},
				{
					title: translate(translationsScope.View.List.Table.Headers.SMS),
					dataIndex: 'isSmsNotificationEnabled',
					className: 'notification--column',
					render: (data) => (
						<GenericTable.Column>
							{data ? (
								<CheckCircleFilled className="notification--icon" />
							) : (
								<CloseCircleOutlined className="notification--icon" />
							)}
						</GenericTable.Column>
					),
				},
			],
		},
		{
			title: translate(
				translationsScope.View.List.Table.Headers.MobileLimitHeader,
			),
			dataIndex: 'mobileAppLimit',
			className: 'mobile-limit--column',
			key: 'mobileAppLimit',
			width: 90,
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">
							{data
								? customFormat(data, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)
								: '---'}
						</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			fixed: 'right' as 'right',
			width: 20,
			render: (data) => (
				<GenericTable.Column>
					<Actions rowData={data} />
				</GenericTable.Column>
			),
		},
	];

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadCustomersList());
	};

	return (
		<GenericTable.Table
			otherClassName="table-container"
			columns={columns}
			data={listData}
			loading={listLoading}
			selectedFilters={filters}
			pagination={{
				total: listTotal,
				pageSize: limit,
				current: start,
				onChange: handleOnChange,
			}}
			emptyText={translate(translationsShared.Table.emptyText, {
				query: filters[FiltersEnum.Query],
			})}
		/>
	);
};
