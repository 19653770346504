import './style.scss';

import { DatePicker, Form } from 'formik-antd';
import moment, { Moment } from 'moment';
import React, { memo } from 'react';

interface Props {
	name: string;
	label?: string;
	format?: string;
	onSelect?: (value: null | Moment | [Moment | null, Moment | null]) => void;
	isRange?: boolean;
	defaultValue?: null | Moment | [Moment | null, Moment | null];
	disabledDate?: (date: Moment) => boolean;
	placeholder?: string;
	disabled?: boolean;
	defaultPickerValue?: [Moment, Moment];
	onCalendarChange?: (any: null | [Moment | null, Moment | null]) => boolean;
	customDisabledDate?: (date: Moment) => boolean;
}

export const FormCalendar = memo(
	<ValueType extends Moment | [Moment, Moment]>(props: Props) => {
		const {
			name,
			label,
			format,
			isRange,
			defaultValue,
			disabledDate,
			placeholder,
			disabled,
			defaultPickerValue,
			onCalendarChange,
			customDisabledDate,
		} = props;

		const handleChange = (selectedValue: null | ValueType) => {
			if (props.onSelect) {
				props.onSelect(selectedValue);
			}
		};

		const disableDate = (current: Moment) => {
			return current && current < moment().endOf('day');
		};

		return (
			<Form.Item
				label={label}
				key={name}
				name={name}
				className="calendar__label"
			>
				{isRange ? (
					<DatePicker.RangePicker
						name={name}
						format={format}
						onChange={
							handleChange as
								| undefined
								| ((value: [Moment | null, Moment | null] | null) => void)
						}
						className="calendar__input"
						disabledDate={customDisabledDate ? customDisabledDate : disableDate}
						disabled={disabled}
						defaultValue={
							defaultValue as [Moment | null, Moment | null] | null | undefined
						}
						defaultPickerValue={defaultPickerValue}
						onCalendarChange={onCalendarChange}
					/>
				) : (
					<DatePicker
						disabledDate={disabledDate}
						defaultValue={defaultValue as Moment | undefined}
						name={name}
						format={format}
						onChange={
							handleChange as undefined | ((value: Moment | null) => void)
						}
						className="calendar__input"
						placeholder={placeholder}
						disabled={disabled}
					/>
				)}
			</Form.Item>
		);
	},
);
