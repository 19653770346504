import { z } from 'zod';

export enum FIELD_TYPES_VALUES {
	BLN = 'BLN',
	DAT = 'DAT',
	INT = 'INT',
	MUL = 'MUL',
	TXT = 'TXT',
	TXT2 = 'TX2',
	UND = 'UND',
}

export interface CustomField {
	id: string;
	type: FIELD_TYPES_VALUES;
	value: string | null;
}

const _orderEntrySchema = z.object({
	// technically, .nullable() would be more accurate than .nullish()
	// the never fully omits the value, just uses null
	// but a lot of existing utils work with undefined
	defaultValue: z.string().nullish(),
	erpField: z.string().nullish(),
	id: z.string().uuid().nullish(),
	isActive: z.boolean(),
	items: z.array(z.string()).nullish(),
	label: z.string().nullish(),
	type: z.nativeEnum(FIELD_TYPES_VALUES).nullish(),
});
export interface OrderEntry extends z.infer<typeof _orderEntrySchema> {}
const orderEntrySchema: z.Schema<OrderEntry> = _orderEntrySchema;

const _orderEntryDataSchema = z.object({
	hasCustomFields: z.boolean(),
	fields: z.array(orderEntrySchema).nullable(),
});
export interface OrderEntryData extends z.infer<typeof _orderEntryDataSchema> {}
export const orderEntryDataSchema: z.Schema<OrderEntryData> =
	_orderEntryDataSchema;
