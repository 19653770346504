import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectHedgeMapTemplate } from 'app/containers/Settings/sections/Commodities/selectors';
import { actions } from 'app/containers/Settings/sections/Commodities/slice';
import { DATE_FORMAT } from 'utils/constants';
import { calculateEndDate } from 'utils/helpers';

export const useHedgeMapping = ({
	values,
	setFieldValue,
	errors,
	setFieldTouched,
}) => {
	const removedFile = useSelector(selectHedgeMapTemplate);
	const { commodity, product, cropYearCalendar, addCropYear, addCropYear0 } =
		values;

	const dispatch = useDispatch();
	const getMonths = (startDate, endDate) => {
		let months = 1;
		let currentMonth = startDate.getMonth();

		while (startDate < endDate) {
			const newDate = startDate.setDate(startDate.getDate() + 1);
			startDate = new Date(newDate);

			if (startDate.getMonth() !== currentMonth) {
				currentMonth = startDate.getMonth();
				months = months + 1;
			}
		}
		return months;
	};

	useEffect(() => {
		setFieldTouched('hedgeMapping', false);

		if (values.hedgeMapping && errors && !errors.hedgeMapping) {
			setFieldValue('hedgeMapping', '');
		}
	}, [removedFile, setFieldValue, setFieldTouched]);

	useEffect(() => {
		if (commodity && product && cropYearCalendar) {
			const startDate = moment(cropYearCalendar);
			const endDate = moment(calculateEndDate(cropYearCalendar));
			const months = getMonths(startDate.toDate(), endDate.toDate());

			if (!isNaN(months)) {
				dispatch(
					actions.loadHedgeMapTemplate({
						name: commodity,
						productId: product.value,
						startDate: startDate.format(DATE_FORMAT),
						endDate: endDate.format(DATE_FORMAT),
						months: months,
						hasFourthCrop: addCropYear,
						numberOfCropYears: addCropYear ? 4 : 3,
					}),
				);
			}
		}
	}, [commodity, product, cropYearCalendar, addCropYear, addCropYear0]);
};
