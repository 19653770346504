import './style.scss';

import { Checkbox, Select } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { memo, useState } from 'react';

import { GenericOption } from 'types/GenericOption';

import { TagWrapped } from '../TagWrapped';

interface Props {
	options: GenericOption[];
	currentOption: GenericOption[];
	handleChange: (selectedValues: GenericOption[]) => void;
	placeholder?: string;
}

export const FilterSingle = memo(function FilterSingle(props: Props) {
	const [open, setOpen] = useState(false);
	const { Option } = Select;
	const {
		options,
		placeholder,
		handleChange: propHandleChange,
		currentOption,
	} = props;

	const handleChange = (selectedValues: GenericOption[]) => {
		if (selectedValues.length > 1) selectedValues.shift();
		setOpen(false);
		if (
			!(
				currentOption?.some((element) => element.value === 'all') &&
				selectedValues[0].value === 'all'
			)
		) {
			propHandleChange(selectedValues);
		}
	};

	const handleBlur = () => {
		setOpen(false);
	};
	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<Select
			data-testid="filter-single"
			className="filter-select-single"
			open={open}
			onClick={handleClick}
			mode="multiple"
			tagRender={TagWrapped}
			labelInValue
			showArrow={true}
			showSearch={false}
			size="small"
			onChange={handleChange}
			onBlur={handleBlur}
			placeholder={placeholder}
			allowClear={false}
			optionLabelProp="label"
			defaultActiveFirstOption={false}
			menuItemSelectedIcon={null}
			maxTagTextLength={8}
			value={
				currentOption?.some((element) => element.value === 'all')
					? []
					: currentOption
			}
		>
			{options.map((option, index) => (
				<Option
					key={option.value}
					value={option.value}
					label={option.label}
					data-testid={`filter-single-${option.label}`}
				>
					<Checkbox
						checked={
							currentOption?.some(
								(element) => element.value === option.value,
							) ||
							(!currentOption?.length && index === 0)
						}
					></Checkbox>
					<Text mark={option?.highlight ?? false} className="select-options">
						{option.label}
					</Text>
				</Option>
			))}
		</Select>
	);
});
