import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { GenericItem } from 'types/GenericItem';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { CreateEditRolePayload, Modal, UserRolesState, View } from './types';

export const initialState: UserRolesState = {
	currentView: View.List,
	currentModal: null,

	activeUserRoles: {
		data: null,
		error: null,
		loading: false,
	},

	list: {
		data: [],
		loading: false,
		error: null,
	},

	userRolesStatus: {
		data: [],
		loading: false,
		error: null,
	},

	createUserRole: {
		data: [],
		loading: false,
		error: null,
	},

	userRoleDetails: {
		data: [],
		loading: false,
		error: null,
	},

	roleLocations: {
		data: [],
		loading: false,
		error: null,
	},

	roleLimits: {
		data: [],
		loading: false,
		error: null,
	},
};

const userRolesSlice = createSlice({
	name: 'userRoles',
	initialState,
	reducers: {
		setCurrentModal(state, action: PayloadAction<Modal | null>) {
			state.currentModal = action.payload;
		},

		setCurrentView(state, action: PayloadAction<View>) {
			state.currentView = action.payload;
		},

		setActiveUserRoles(state, action) {
			state.activeUserRoles = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		resetActiveUserRoles(state) {
			state.activeUserRoles = {
				data: null,
				loading: false,
				error: null,
			};
		},

		loadUserRolesList(state: UserRolesState) {
			state.list = {
				...state.list,
				data: [],
				loading: true,
				error: null,
			};
		},

		userRolesListLoaded(state: UserRolesState, action: PayloadAction<any>) {
			state.list = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		userRolesListError(
			state: UserRolesState,
			action: PayloadAction<GenericError>,
		) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		deleteUserRole(state, action: PayloadAction<object>) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},

		UserRoleDeleted(state) {
			state.list = {
				...state.list,
				loading: false,
				error: null,
			};
		},

		deleteUserRoleError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		createUserRole(state, action: PayloadAction<CreateEditRolePayload>) {
			state.createUserRole = {
				...state.createUserRole,
				loading: true,
				error: null,
			};
		},

		userRoleCreated(state) {
			state.createUserRole = {
				...state.createUserRole,
				loading: false,
				error: null,
			};
		},

		userRoleError(state, action: PayloadAction<GenericError>) {
			state.createUserRole = {
				...state.createUserRole,
				loading: false,
				error: action.payload,
			};
		},

		loadEditUserRole(state, action: PayloadAction<GenericItem>) {
			state.userRoleDetails = {
				...state.userRoleDetails,
				loading: true,
			};
		},

		//Get total orders already loaded
		editUserRoleLoaded(state, action: PayloadAction<any>) {
			state.userRoleDetails = {
				...state.userRoleDetails,
				data: action.payload,
				loading: false,
			};
		},

		clearEditUserRole(state) {
			state.userRoleDetails = {
				...state.userRoleDetails,
				data: [],
			};
		},

		editUserRoleError(state, action: PayloadAction<GenericError>) {
			state.userRoleDetails = {
				...state.userRoleDetails,
				loading: false,
				error: action.payload,
			};
		},

		createRoleLocation(state, action: PayloadAction<any>) {
			state.roleLocations = {
				...state.roleLocations,
				data: action.payload,
				loading: true,
				error: null,
			};
		},

		roleLocationCreated(state, action) {
			state.roleLocations = {
				...state.roleLocations,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		roleLocationError(state, action: PayloadAction<GenericError>) {
			state.roleLocations = {
				...state.roleLocations,
				loading: false,
				error: action.payload,
			};
		},

		createEditRoleLimits(state, action: PayloadAction<any>) {
			state.roleLimits = {
				...state.roleLimits,
				data: action.payload,
				loading: true,
				error: null,
			};
		},

		createdEditedRoleLimits(state, action) {
			state.roleLimits = {
				...state.roleLimits,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		roleLimitsError(state, action: PayloadAction<GenericError>) {
			state.roleLimits = {
				...state.roleLimits,
				loading: false,
				error: action.payload,
			};
		},
	},
});

export const {
	actions: userRolesActions,
	reducer: userRolesReducer,
	name: userRolesSliceKey,
} = userRolesSlice;
