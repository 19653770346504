import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
	selectCommoditiesList,
	selectFuturesMonthOptions,
	selectHedgeAccountList,
} from 'app/containers/GlobalSaga/selectors';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';
import { ActionType } from 'types/ActionType';
import { PRICE_DECIMALS } from 'utils/constants';
import {
	generateCropYears,
	generateDropdown,
	generateFormRadios,
	generateInputField,
	generateInputFieldPrice,
	generateTextArea,
} from 'utils/GenericFormInputs/generic-form-inputs';
import { mapPropertyToLabelValue, mapToLabelValue } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';
import Yup from 'yupCustom';

import { hasHedgeableQuantityError } from '../utils/helpers';

export const useExternalFillSchema = () => {
	const { t: translate } = useTranslation();
	const domain =
		translations.app.containers.ReviewAndRelease.components.Views.List.Menu
			.Modals;

	const requiredValue = translate(domain.requiredValue);

	const commodities = useSelector(selectCommoditiesList);
	const commoditiesOptions = mapToLabelValue(commodities);
	const futuresMonthList = mapPropertyToLabelValue(
		useSelector(selectFuturesMonthOptions(CalculatedFieldsSource.Transactions)),
		'name',
	);
	const hedgeAccountList = mapToLabelValue(useSelector(selectHedgeAccountList));
	const regionsList = useEnabledRegions(mapToLabelValue);

	const fields = {
		contract: {
			name: 'contract',
			key: 'contract',
			render: generateInputField,
			initialValue: '',
			label: translate(domain.contractFormTitle),
			placeholder: translate(domain.contractFormPlaceholder),
			disabled: false,
		},
		commodity: {
			name: 'commodity',
			key: 'commodity',
			render: generateDropdown,
			validation: Yup.string().required(requiredValue),
			initialValue: '',
			label: translate(domain.commodityFormTitle),
			placeholder: translate(domain.commodityFormPlaceholder),
			options: commoditiesOptions,
		},
		cropYears: {
			name: 'cropYear',
			key: 'cropYear',
			validation: Yup.number().required(requiredValue).nullable(),
			render: generateCropYears,
			initialValue: null,
			required: true,
			label: translate(domain.cropYearFormTitle),
		},
		buyOrSell: {
			name: 'buyOrSale',
			key: 'buyOrSale',
			label: translate(domain.buySellFormTitle),
			render: generateFormRadios,
			validation: Yup.string().required(requiredValue),
			span: 3,
			offset: 0,
			initialValue: ActionType.BUY,
			options: [
				{ label: translate(domain.buyLabel), value: ActionType.BUY },
				{ label: translate(domain.sellLabel), value: ActionType.SELL },
			],
		},
		quantity: {
			name: 'quantity',
			key: 'quantity',
			label: translate(domain.quantityFormTitle),
			validation: Yup.string()
				.required(requiredValue)
				.test({
					name: 'hedgeableQuantity',
					message: translate(domain.quantityHedgeable),
					test: function (value: string) {
						const commodity = this.parent?.commodity;

						return hasHedgeableQuantityError(commodities, commodity, value);
					},
				}),
			placeholder: translate(domain.quantityFormPlaceholder),
			decimals: PRICE_DECIMALS,
			render: generateInputField,
		},
		futuresMonth: {
			name: 'futuresMonth',
			key: 'futuresMonth',
			label: translate(domain.futuresMonthFormTitle),
			placeholder: translate(domain.futuresMonthFormPlaceholder),
			validation: Yup.string().required(requiredValue),
			render: generateDropdown,
			options: futuresMonthList,
		},
		price: {
			name: 'price',
			key: 'price',
			label: translate(domain.priceFormTitle),
			placeholder: translate(domain.priceFormPlaceholder),
			validation: Yup.string().required(requiredValue),
			arrowsHidden: true,
			decimals: PRICE_DECIMALS,
			render: generateInputFieldPrice,
		},
		futuresAccount: {
			name: 'futuresAccount',
			key: 'futuresAccount',
			label: translate(domain.futuresAcctFormTitle),
			placeholder: translate(domain.futuresAcctFormPlaceholder),
			validation: Yup.string().required(requiredValue),
			render: generateDropdown,
			options: hedgeAccountList,
		},
		comments: {
			name: 'comments',
			key: 'comments',
			initialValue: '',
			label: translate(domain.commentsFormTitle),
			placeholder: translate(domain.commentsFormTitle),
			autoSize: {
				minRows: 4,
				maxRows: 4,
			},
			render: generateTextArea,
		},
		regionId: {
			name: 'regionId',
			key: 'regionId',
			label: translate(domain.regionFormTitle),
			placeholder: translate(domain.regionFormPlaceholder),
			validation: Yup.string().required(requiredValue),
			render: generateDropdown,
			options: regionsList,
		},
	};

	return fields;
};
