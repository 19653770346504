import axios, {
	AxiosError,
	AxiosRequestConfig,
	AxiosResponse,
	isAxiosError,
	Method,
} from 'axios';
import { CANCEL } from 'redux-saga';
import { call, put, select } from 'typed-redux-saga';
import { z } from 'zod';

import { selectAuthUser } from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { FileResponse } from 'types/FileResponse';
import { GenericError } from 'types/GenericError';
import { GenericResponse } from 'types/GenericResponse';

import logout from './auth/logout';
import { genericErrorSchema } from './validators';

const CANCEL_OPERATION = 'Operation canceled.';
/**
 * Enum for HTTP Methods
 */
export enum httpMethod {
	Get = 'GET',
	Put = 'PUT',
	Post = 'POST',
	Delete = 'DELETE',
}

/**
 * Header Accept enum for HTTP requests
 */
const headerAccept = {
	Json: 'application/json',
};

const RESPONSE_STATUS_CODE = {
	UNAUTHORIZED: 401,
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: AxiosResponse) {
	const parsedResponse = response.data;
	// IE doesnt support startsWith
	if (
		response &&
		response.headers &&
		response.headers['content-type'] &&
		response.headers['content-type'].indexOf('image/') === 0
	) {
		return parsedResponse;
	}
	parsedResponse.status = response.status;
	return parsedResponse;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
async function request(
	url: string,
	options?: AxiosRequestConfig,
): Promise<{} | { err: AxiosError }> {
	const cancelTokenSource = axios.CancelToken.source();
	if (!options) {
		options = { cancelToken: cancelTokenSource.token };
	} else {
		options = Object.assign(options, {
			cancelToken: cancelTokenSource.token,
		});
	}
	const promise = axios(url, options).then(parseJSON);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(promise as any)[CANCEL] = () => {
		// cancel XHR request, called by redux-saga when a saga gets cancelled
		cancelTokenSource.cancel(CANCEL_OPERATION);
	};

	return promise;
}

/**
 * Returns a valid header object for a request
 * @param token Authentication token for request
 * @param accept Header accept for request
 */
function getHeaders(token: string | null = null, accept = headerAccept.Json) {
	let headers = {
		Accept: accept,
		'Content-Type': 'application/json',
		Authorization: '',
	};
	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}
	return headers;
}

/**
 * Get a valid HTTP options object for a new request
 * @param method HTTP method for request
 * @param data Request body
 * @param token Authentication token
 * @param accept Header accept value for request
 */
function getOptions(
	method = httpMethod.Get,
	data: unknown,
	token: string | null = null,
	params: object | null = null,
	accept = headerAccept.Json,
): AxiosRequestConfig {
	const options: AxiosRequestConfig = {
		method: method as Method,
		data: JSON.stringify(data),
		headers: getHeaders(token, accept),
		params,
	};
	if (!data) {
		delete options.data;
	}
	return options;
}

const withSchemaSymbol = Symbol('withSchema');
const noSchemaSymbol = Symbol('noSchema');
export function* requestWithSchema<ResponseType, Method extends httpMethod>(
	url: URL,
	method: Method,
	options: Method extends httpMethod.Get | httpMethod.Delete
		? {
				body?: null;
				showNotification?: boolean;
				params?: null | object;
				schema: z.ZodSchema<ResponseType>;
			}
		: {
				body: Record<string, unknown>;
				showNotification?: boolean;
				params?: null | object;
				schema: z.ZodSchema<ResponseType>;
			},
) {
	const { body, showNotification, params, schema } = options;
	const response = yield* call(
		genericRequest,
		url,
		method,
		body,
		showNotification,
		params,
		withSchemaSymbol,
	);
	const error = response.responseError
		? genericErrorSchema.parse(response.responseError)
		: null;
	let data: ResponseType | null = response.responseData;
	let parseResult = schema.safeParse(response.responseData);
	if (data != null) {
		if (parseResult.success) {
			// do nothing; later on, we should set `data = parseResult.data`
			// parseResult.data drops any unrecognized properties
			// until we have better type coverage throughout the project, that's risky
			// because something might be relying on that now-missing property without us knowing
		} else {
			if (process.env.NODE_ENV === 'development') {
				yield* put(
					globalActions.addNotification({
						type: CustomNotificationTypes.ERROR,
						title: `${parseResult.error.name}`,
						message: [
							parseResult.error.issues[0].message,
							url.pathname,
							parseResult.error.issues[0].path.join('.'),
						].join('\n'),
						duration: 0,
					}),
				);
				console.error(
					[
						'responseData failed schema parsing; falling back to unparsed data',
						`error: ${parseResult.error.issues[0].message}`,
						`data path: ${parseResult.error.issues[0].path.join('.')}`,
						`api path: ${url.pathname}`,
					].join('\n'),
					{
						url,
						method,
						body,
						params,
						response,
						schema,
						error: parseResult.error,
					},
				);
			}
		}
	}
	return { data, error };
}

export function* genericRequest(
	url: URL,
	method: httpMethod,
	data: unknown = null,
	showNotification = true,
	params: object | null = null,
	calledWithSchema = noSchemaSymbol,
) {
	if (
		process.env.NODE_ENV === 'development' &&
		calledWithSchema !== withSchemaSymbol
	) {
		console.log(
			`DEPRECATED: switch to requestWithSchema for type-safe requests (${method} ${url.pathname})\nIf you're working on this part of the code, consider updating the sagas`,
		);
	}
	const authUser = yield* select(selectAuthUser);

	if (!authUser) {
		console.trace('User not authenticated');

		return {
			responseData: null,
			responseError: {},
		};
	}
	const options = getOptions(method, data, authUser.accessToken, params);

	try {
		const response: GenericResponse = (yield* call(
			request,
			url.href,
			options,
		)) as GenericResponse;
		if (response.type === 4) {
			const responseError: GenericError = { detail: response.message };

			if (showNotification) {
				yield* put(
					globalActions.addNotification({
						type: CustomNotificationTypes.ERROR,
						message: response.message || undefined,
					}),
				);
			}

			return {
				responseData: null,
				responseError,
			};
		} else {
			return {
				responseData: response.data || true,
				responseError: null,
			};
		}
	} catch (error) {
		//refers to Warning code
		if (
			isAxiosError(error) &&
			error.response?.status === RESPONSE_STATUS_CODE.UNAUTHORIZED
		) {
			logout();
		}
		if (isAxiosError(error) && error.response?.data?.Type === 2) {
			const responseError: GenericError = {
				detail: error.response.data.Message,
				data: error.response.data.Data,
				status: error.response?.data.Type,
			};
			return {
				responseData: null,
				responseError,
			};
		} else {
			// refers to Error
			const responseError: GenericError = {
				detail: isAxiosError(error)
					? (error.response?.data?.Message ?? error.message)
					: error instanceof Error
						? error.message
						: undefined,
			};

			if (showNotification) {
				yield* put(
					globalActions.addNotification({
						type: CustomNotificationTypes.ERROR,
						title: responseError.title,
						message: responseError.detail ?? undefined,
					}),
				);
			}
			return {
				responseData: null,
				responseError,
			};
		}
	}
}

// TODO the types here (esp the inferred return type) a mess; we should improve this once the repo is strictly typed
export function* fileRequest(
	url: URL,
	method: httpMethod,
	showNotification = true,
	data?: unknown,
	config?: AxiosRequestConfig | null,
	deserializeHeaders?: boolean,
	forceBlob?: boolean,
) {
	let response:
		| FileResponse
		| { responseData: null; responseError: GenericError }
		| AxiosResponse
		| string
		| undefined;
	const authUser = yield* select(selectAuthUser);
	if (!authUser) {
		console.trace('User not authenticated');

		return {
			responseData: null,
			responseError: {},
		};
	}
	try {
		if (method === httpMethod.Post) {
			let configAxios = {
				...config,
				headers: {
					Authorization: `Bearer ${authUser.accessToken}`,
				},
			};
			if (forceBlob === true) {
				configAxios.responseType = 'blob';
			}
			response = yield* call(() =>
				axios
					.post(url.href, data, configAxios)
					.then((response) => {
						if (forceBlob === true) {
							if (deserializeHeaders) {
								const fileName = deserializeFileNameFromHeader(response);
								return {
									url: window.URL.createObjectURL(new Blob([response.data])),
									fileName: fileName ?? null,
								};
							} else {
								return window.URL.createObjectURL(new Blob([response.data]));
							}
						} else {
							return response;
						}
					})
					.catch((error) => {
						const responseError: GenericError = {
							detail: error.response
								? error.response.data.Message
								: error.message,
						};
						return {
							responseData: null,
							responseError,
						};
					}),
			);
		} else if (method === httpMethod.Get) {
			response = yield* call(() =>
				axios({
					url: url.href,
					method: 'GET',
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${authUser.accessToken}`,
					},
				})
					.then((response) => {
						if (deserializeHeaders) {
							const fileName = deserializeFileNameFromHeader(response);
							return {
								url: window.URL.createObjectURL(new Blob([response.data])),
								fileName: fileName ?? null,
							};
						} else {
							return window.URL.createObjectURL(new Blob([response.data]));
						}
					})
					.catch((error) => {
						const responseError: GenericError = { detail: error.message };
						return {
							responseData: null,
							responseError,
						};
					}),
			);
		}

		if (showNotification) {
			if (isErrorResponse(response)) {
				yield* put(
					globalActions.addNotification({
						type: CustomNotificationTypes.ERROR,
						message: response.responseError.detail || undefined,
					}),
				);
			}
		}

		return {
			responseData: response,
			responseError: null,
		};
	} catch (error) {
		const responseError: GenericError = {
			title: error instanceof Error ? error.name : 'Unknown Error',
			detail: error instanceof Error ? error.message : null,
		};

		if (showNotification) {
			yield* put(
				globalActions.addNotification({
					type: CustomNotificationTypes.ERROR,
					title: responseError.title,
					message: responseError.detail ?? undefined,
				}),
			);
		}

		return {
			responseData: null,
			responseError,
		};
	}
}

function isErrorResponse(
	response: unknown,
): response is { responseError: GenericError } {
	return (
		response != null &&
		typeof response === 'object' &&
		'responseError' in response &&
		typeof response.responseError === 'object'
	);
}

const genericErrorWithDetailSchema = z.object({
	detail: z.string().min(1),
});
export function isGenericErrorWithDetail(
	error: unknown,
): error is GenericError & z.infer<typeof genericErrorWithDetailSchema> {
	return genericErrorWithDetailSchema.safeParse(error).success;
}

function deserializeFileNameFromHeader(response: AxiosResponse) {
	let fileName = '';
	const dispositionHeader = response.headers?.['content-disposition'];
	const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
	const matches = filenameRegex.exec(dispositionHeader);

	if (matches != null && matches[1]) {
		fileName = matches[1].replace(/['"]/g, '');
	}
	return fileName;
}
