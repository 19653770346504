import { GenericError } from 'types/GenericError';
import { IGenericPagination } from 'types/GenericPagination';

import { ErrorInLine } from '../../types';

/* --- STATE --- */
export interface RoundingRuleState {
	currentView: View;
	currentModal: Modal | null;

	currentRoundingRule: {
		data: any;
		error: GenericError | null;
		loading: boolean;
	};

	list: {
		data: [];
		loading: boolean;
		total: number;
		error: GenericError | null;
	};

	typelist: {
		data: [];
		loading: boolean;
		total: number;
		error: GenericError | null;
	};

	activeRoundingRule: {
		data: any;
		error: GenericError | null;
		loading: boolean;
	};

	selectedFilters: Filters;

	pagination: IGenericPagination;

	errorsInLine: ErrorInLine[];
}

export enum View {
	List = 'list',
}
export enum Modal {
	Create = 'Create',
	Edit = 'Edit',
	Delete = 'Delete',
}

enum FiltersEnum {
	Name = 'name',
}

export interface Filters {
	[FiltersEnum.Name]?: string;
}

export type RoundingRulesSectionState = RoundingRuleState;
