import { customFormat } from 'utils/helpers';

export const compareNumbers = (
	value: number | string | undefined,
	preHedgeMaxQty: number | string | undefined,
	decimals: number,
): boolean => {
	if (value && typeof preHedgeMaxQty === 'number') {
		const quantity = parseFloat(customFormat(preHedgeMaxQty, false, decimals));
		const max = parseFloat(customFormat(value, false, decimals));

		return quantity - max >= 0;
	}
	return true;
};
