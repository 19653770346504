import './styles.scss';

import { Button } from 'antd';
import classNames from 'classnames';
import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

interface Props {
	url: string | null;
	handleFileRequest?: React.MouseEventHandler<HTMLElement>;
	fileName?: string | null;
	label?: string | null;
}

export const ExcelExport = memo(function ExcelExport({
	url,
	handleFileRequest,
	fileName,
	label,
}: Props) {
	const { t: translate } = useTranslation();
	const anchorRef = useRef<HTMLAnchorElement>(null);

	const hasFileRequestHandler = handleFileRequest != null;
	useEffect(() => {
		if (hasFileRequestHandler && url) {
			anchorRef?.current?.click();
		}
	}, [url, hasFileRequestHandler]);

	return (
		<div className="excel-export-container">
			{handleFileRequest && (
				<Button type="primary" onClick={handleFileRequest}>
					{label ||
						translate(translations.app.components.ExcelExport.exportButtonText)}
				</Button>
			)}
			<a
				ref={anchorRef}
				className={classNames('ant-btn', { hidden: handleFileRequest })}
				href={url ?? ''}
				data-testid="excel-export-button"
				download={
					fileName ??
					translate(translations.app.components.ExcelExport.reportFileName)
				}
			>
				{label ||
					translate(translations.app.components.ExcelExport.exportButtonText)}
			</a>
		</div>
	);
});
