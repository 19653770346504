import React, { memo, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { TransactionTypesContracts } from 'app/containers/Contracts/types';
import { selectContractStateFromPreHedge } from 'app/containers/PreHedge/selectors';
import { translations } from 'locales/i18n';
import { isEmptyObject } from 'utils/validators';

import { SourceContext } from '../..';
import { useTransactionsBySource } from '../../hooks/useTransactionsBySource';

interface Props {
	disabled?: boolean;
	hidden?: boolean;
}
export const Transaction = memo(function Transaction(props: Props) {
	const { disabled = false, hidden = false } = props;
	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.Transaction;
	const sourceName = useContext(SourceContext);

	const contractStateFromPreHedge = useSelector(
		selectContractStateFromPreHedge,
	);

	const shouldRemovedBushelsOnly = !isEmptyObject(contractStateFromPreHedge);

	const transactionsList = useTransactionsBySource(sourceName);

	const withoutBushelsOnly = transactionsList.filter(
		(item) => item.label !== TransactionTypesContracts.BushelsOnly,
	);

	const { setValue } = useFormContext();

	const handleChange = () => {
		setValue('contractNumber', null);
	};

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="transaction"
			onChange={handleChange}
			data-testid="transaction-form-item"
			customClassName={hidden ? ['hidden'] : []}
		>
			<Dropdown
				disabled={disabled}
				key="transaction"
				placeholder={translate(msg.placeholder)}
				options={
					shouldRemovedBushelsOnly ? withoutBushelsOnly : transactionsList
				}
			/>
		</GenericForm.FormItem>
	);
});
