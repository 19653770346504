/**
 *
 * Cashbids And Quotes
 *
 */

import './style.scss';

import { Card } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Split from 'react-split';

import { contractsSaga } from 'app/containers/Contracts/root-saga';
import {
	reducer as contractsReducer,
	sliceKey as contractsKey,
} from 'app/containers/Contracts/slice';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { Cashbids } from './components/Cashbids';
import { FuturesQoutes } from './components/FuturesQoutes';
import { cashbitdsAndQuotesSaga } from './root-saga';
import {
	selectCashbidsLastUpdatedTime,
	selectFuturesQoutesLastUpdatedTime,
} from './selectors';
import { reducer, sliceKey } from './slice';

export function CashbidsAndQuotes() {
	const dispatch = useDispatch();
	// initialize reducers and saga for this container
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: cashbitdsAndQuotesSaga });
	useInjectReducer({ key: contractsKey, reducer: contractsReducer });
	useInjectSaga({ key: contractsKey, saga: contractsSaga });
	// state handling for maximize and minimize
	const [isCashBidsMaximize, setIsCashBidsMaximize] = useState(false);
	const [isFuturesQuotesMaximize, setIsFuturesQuotesMaximize] = useState(false);

	const CashbidsLastUpdatedTime = useSelector(selectCashbidsLastUpdatedTime);
	const FuturesQoutesLastUpdatedTime = useSelector(
		selectFuturesQoutesLastUpdatedTime,
	);

	// translation
	const { t: translate } = useTranslation();
	const CashbidsAndQuotesScope = translations.app.containers.CashbidsAndQuotes;
	const getTranslation = (key: string) =>
		translate(CashbidsAndQuotesScope[key]);

	// state handling for commodities list
	useEffect(() => {
		dispatch(globalActions.loadCommoditiesList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const RenderSections = () => {
		return (
			<>
				{!isCashBidsMaximize && isFuturesQuotesMaximize ? null : (
					<div
						className={classNames('cashbidsAndQuotes__cashbids', {
							'cashbidsAndQuotes__cashbids--isMaximize': isCashBidsMaximize,
						})}
					>
						<div className="cashbidsAndQuotes__heading">
							<p>{getTranslation('cashBidsHeading')}</p>
							<h2>
								{CashbidsLastUpdatedTime &&
									`Last Update: ${CashbidsLastUpdatedTime}`}
							</h2>
						</div>
						<Cashbids
							isCashBidsMaximize={isCashBidsMaximize}
							setIsCashBidsMaximize={setIsCashBidsMaximize}
						/>
					</div>
				)}
				{isCashBidsMaximize && !isFuturesQuotesMaximize ? null : (
					<div
						className={classNames('cashbidsAndQuotes__futures-quotes', {
							'cashbidsAndQuotes__futuresqoutes--isMaximize':
								isFuturesQuotesMaximize,
						})}
					>
						<div className="cashbidsAndQuotes__heading">
							<p>{getTranslation('futuresQoutesHeading')}</p>
							<h2>
								{FuturesQoutesLastUpdatedTime &&
									`Last Update: ${FuturesQoutesLastUpdatedTime}`}
							</h2>
						</div>
						<FuturesQoutes
							isFuturesQuotesMaximize={isFuturesQuotesMaximize}
							setIsFuturesQuotesMaximize={setIsFuturesQuotesMaximize}
						/>
					</div>
				)}
			</>
		);
	};

	return (
		<>
			<Card role="card" bordered={true} className="cashbidsAndQuotes">
				{isCashBidsMaximize || isFuturesQuotesMaximize ? (
					RenderSections()
				) : (
					<Split direction="vertical" className="cashbidsAndQuotes__split">
						{RenderSections()}
					</Split>
				)}
			</Card>
		</>
	);
}
