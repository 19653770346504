import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState, ReportOption } from './types';

// The initial state of the GenericReport container
export const initialState: ContainerState = {
	reportsList: {
		data: [],
		loading: false,
		error: null,
	},

	currentReport: null,

	tableSchema: {
		data: {
			bodies: [],
			filters: [],
			hasDynamicHeaderLabel: true,
			hasPagination: true,
			sections: [],
		},
		loading: false,
		error: null,
	},

	filtersData: {},

	dynamicFiltersData: {},

	tableData: {
		data: [],
		total: 0,
		loading: false,
		error: null,
		loaded: false,
	},

	tableDataReportTemplate: {
		url: null,
		loading: false,
		error: null,
	},

	pagination: {
		limit: 50,
		start: 1,
	},

	selectedFilters: {},
};

const genericReportSlice = createSlice({
	name: 'genericReport',
	initialState,
	reducers: {
		loadReportsList(state) {
			state.reportsList = {
				data: [],
				loading: true,
				error: null,
			};
		},

		reportsListLoaded(state, action: PayloadAction<ReportOption[]>) {
			state.reportsList = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		setCurrentReport(state, action: PayloadAction<ReportOption | null>) {
			state.currentReport = action.payload;

			state.tableData = {
				data: [],
				total: 0,
				loading: false,
				error: null,
				loaded: false,
			};
		},

		loadTableSchema(state) {
			state.tableSchema = {
				...initialState.tableSchema,
				loading: true,
			};
		},

		tableSchemaLoaded(state, action: PayloadAction<any>) {
			state.tableSchema = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		tableSchemaError(state, action: PayloadAction<GenericError>) {
			state.tableSchema = {
				...initialState.tableSchema,
				error: action.payload,
				loading: false,
			};
		},

		loadFilterData(state, action: PayloadAction<object>) {},

		filterDataLoaded(state, action: PayloadAction<object>) {
			state.filtersData = {
				...state.filtersData,
				...action.payload,
			};
		},

		setSelectedFilters(state, action: PayloadAction<object>) {
			state.selectedFilters = action.payload;
		},

		loadTableData(state) {
			state.tableData = {
				...state.tableData,
				loading: true,
				error: null,
			};
		},

		loadTableDataReport(state) {
			state.tableDataReportTemplate = {
				...state.tableDataReportTemplate,
				loading: true,
				error: null,
			};
		},

		tableDataLoaded(state, action: PayloadAction<any>) {
			const data = action.payload;

			state.tableData = {
				...state.tableData,
				data: data.list,
				total: data.total,
				loading: false,
				error: null,
				loaded: true,
			};
		},

		tableDataLoadedReport(state, action: PayloadAction<any>) {
			const { url } = action.payload;
			state.tableDataReportTemplate = {
				url: url,
				loading: false,
				error: null,
			};
		},

		resetTableData(state) {
			state.tableData = { ...initialState.tableData };
		},

		tableDataError(state, action: PayloadAction<GenericError>) {
			state.tableData = {
				...state.tableData,
				error: action.payload,
				loading: false,
			};
		},

		tableDataErrorReport(state, action: PayloadAction<GenericError>) {
			state.tableDataReportTemplate = {
				...state.tableDataReportTemplate,
				error: action.payload,
				loading: false,
			};
		},

		loadDynamicFilter(state, action: PayloadAction<any>) {
			const sourceListName = action.payload.source;

			state.dynamicFiltersData[sourceListName] = {
				data: [],
				loading: true,
				error: null,
			};
		},

		dynamicFiltersLoaded(state, action: PayloadAction<any>) {
			const sourceListName = action.payload.source;

			state.dynamicFiltersData[sourceListName] = {
				data: action.payload.data,
				loading: false,
				error: null,
			};
		},

		dynamicFiltersError(state, action: PayloadAction<any>) {
			const sourceListName = action.payload.source;

			state.dynamicFiltersData[sourceListName] = {
				data: null,
				loading: false,
				error: action.payload.error,
			};
		},

		setPagination(state, action: PayloadAction<any>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},

		setStartPagination(state, action: PayloadAction<any>) {
			state.pagination = {
				...state.pagination,
				start: action.payload.start || state.pagination.start,
			};
		},
	},
});

export const {
	actions: genericReportActions,
	reducer: genericReportReducer,
	name: genericReportSliceKey,
} = genericReportSlice;
