import { Menu as AntDMenu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from 'app/routes';
import { translations } from 'locales/i18n';

import { MenuItem } from '../../types';

interface Props {
	menuItems: MenuItem[];
	defaultSelected: string;
}

export function Menu({ menuItems, defaultSelected }: Props) {
	const { t: translate } = useTranslation();

	return (
		<AntDMenu
			className="settings__menu"
			data-testid="settings-menu"
			selectedKeys={[defaultSelected]}
		>
			{menuItems.map((item) => (
				<AntDMenu.Item
					key={item?.name}
					icon={item?.icon}
					data-testid={`menu-item-${item?.name}`}
				>
					<Link to={`${ROUTES.SETTINGS}/${item.name}`}>
						{translate(
							translations.app.containers.Settings.components.menu[item?.name],
						)}
					</Link>
				</AntDMenu.Item>
			))}
		</AntDMenu>
	);
}
