import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectHasCustomFields,
	selectOrderEntryLoading,
} from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { orderEntrySaga } from '../saga';
import { reducer, sliceKey } from '../slice';

export const useOrderEntryData = () => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: orderEntrySaga });

	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const hasCustomFields = useSelector(selectHasCustomFields);
	const isLoading = useSelector(selectOrderEntryLoading);

	useEffect(() => {
		dispatch(globalActions.getOrderEntryRequest({ isActive: false }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		hasCustomFields,
		isLoading,
		translate,
	};
};
