import './style.scss';

import { SyncOutlined } from '@ant-design/icons';
import React, { memo, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import {
	selectActiveContract,
	selectSpreadValue,
} from 'app/containers/Contracts/selectors';
import { SourceContext } from 'app/containers/Transactions';
import { useFormatPrice } from 'app/containers/Transactions/components/hooks/useFormatPrice';
import { useHandleBusinessRules } from 'app/containers/Transactions/components/hooks/useHandleBusinessRules';
import { translations } from 'locales/i18n';
import { formatPriceHelper } from 'utils/helpers';
import { preventWheelChange, preventWheelEvent } from 'utils/util';

interface Props {
	disabled?: boolean;
	canUpdateBasis?: boolean;
	spreadBasisDependency?: boolean;
	showRetryBtn?: boolean;
}

export const Spread = memo(function Spread(props: Props) {
	const { t: translate } = useTranslation();
	const { disabled, spreadBasisDependency, showRetryBtn = false } = props;
	const spreadValue = useSelector(selectSpreadValue);
	const orderData = useSelector(selectActiveContract) as any;

	const msg = translations.app.containers.Transactions.components.Spread;
	const sourceName = useContext(SourceContext);
	const { getSpreadValue, setNetBasis } = useHandleBusinessRules(sourceName);
	const { setValue, getValues } = useFormContext();
	const { formatPrice }: any = useFormatPrice();

	const useEffectSpread = (effect: React.EffectCallback) =>
		useEffect(effect, [spreadValue]);

	useEffectSpread(() => {
		const { postedBasis } = orderData;
		const { futuresPriceInput } = getValues();

		if (!!spreadValue) {
			if (spreadBasisDependency) {
				setValue(
					'postedBasis',
					formatPriceHelper(parseFloat(postedBasis) + parseFloat(spreadValue)),
					{
						shouldValidate: true,
					},
				);
				setNetBasis();
			} else {
				setValue(
					'futuresPrice',
					formatPriceHelper(
						parseFloat(futuresPriceInput) + parseFloat(spreadValue),
					),
					{
						shouldValidate: true,
					},
				);
			}
			setValue('spread', formatPriceHelper(spreadValue), {
				shouldValidate: true,
			});
		}
	});

	/**
	 * @deprecated
	 */
	const handleOnChange = () => {
		// This is no longer needed due buisness formulas by now
		// manageSetPushBasisSpread(canUpdateBasis);

		const { futuresPriceInput, spread } = getValues();
		const { postedBasis } = orderData;
		if (!!spread) {
			if (spreadBasisDependency) {
				setValue(
					'postedBasis',
					formatPriceHelper(parseFloat(postedBasis) + parseFloat(spread)),
					{
						shouldValidate: true,
					},
				);
				setNetBasis();
			} else {
				setValue(
					'futuresPrice',
					formatPriceHelper(parseFloat(futuresPriceInput) + parseFloat(spread)),
					{
						shouldValidate: true,
					},
				);
			}
		}
	};

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="spread"
			data-testid="spread-form-item"
			onChange={handleOnChange}
			customClassName={['spread__label']}
		>
			<Input
				className="spread__input"
				key="spread"
				data-testid="spread-input-item"
				placeholder={translate(msg.placeholder)}
				type="number"
				onWheel={preventWheelChange}
				onFocus={preventWheelEvent}
				disabled={disabled}
				name="spread"
				onBlur={() => formatPrice('spread')}
				suffix={
					<div
						onClick={() => getSpreadValue(translate(msg.missingFM))}
						data-testid="spread-icon"
					>
						{showRetryBtn && <SyncOutlined />}
					</div>
				}
			/>
		</GenericForm.FormItem>
	);
});
