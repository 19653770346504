/**
 *
 * FormButton
 *
 */
import { Button, Popconfirm } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import classNames from 'classnames';
import React, { memo, useEffect, useState } from 'react';

import { FormButtonsProps } from '../types';

export const FormButton = memo(function FormButton(props: FormButtonsProps) {
	const {
		htmlType,
		children,
		popconfirmProps,
		dataTestId,
		debounceReenable,
		...buttonProps
	} = props;

	let defaultProps: ButtonProps = { type: 'primary' };

	if (htmlType === 'reset' || htmlType === 'button') {
		defaultProps.ghost = true;
	}

	// keep buttons disabled for some extra time
	// this is to compensate for bad form performance which causes the disabled->enabled style transition to stutter, making the button look disabled but actually be enabled]
	// users sometimes try to click again and accidentally submit duplicates
	const [disabled, setDisabled] = useState(props.disabled);
	useEffect(() => {
		if (!debounceReenable) return;
		if (disabled === props.disabled) return;
		if (disabled) {
			const timeout = setTimeout(
				() => setDisabled(props.disabled),
				debounceReenable,
			);
			return () => clearTimeout(timeout);
		} else {
			setDisabled(props.disabled);
		}
	}, [disabled, props.disabled, debounceReenable]);

	const button = (
		<Button
			{...defaultProps}
			{...buttonProps}
			data-testid={dataTestId}
			htmlType={htmlType}
			disabled={props.disabled || (debounceReenable ? disabled : undefined)}
		>
			{children}
		</Button>
	);

	if (popconfirmProps && htmlType !== 'submit') {
		return (
			<Popconfirm
				{...popconfirmProps}
				className={classNames(
					'generic-form-confirmation',
					props.popconfirmProps?.className,
				)}
			>
				{button}
			</Popconfirm>
		);
	} else {
		return button;
	}
});
