import React, { memo } from 'react';

import { Checkbox } from 'app/components/Checkbox';
import { GenericForm } from 'app/components/GenericForm';
import { OrderEntry } from 'types/OrderEntry';

import { getOrderEntryId } from '../utils/helpers';

interface Props {
	orderEntry: OrderEntry;
}

export const Bln = memo(function Bln({ orderEntry }: Props) {
	const orderEntryId = getOrderEntryId(orderEntry);

	return (
		<GenericForm.FormItem
			data-testid={`${orderEntryId}-form-item`}
			name={orderEntryId}
			wrapperCol={{ offset: 3, span: 4 }}
		>
			<Checkbox key={orderEntryId} label={orderEntry?.label ?? undefined} />
		</GenericForm.FormItem>
	);
});
