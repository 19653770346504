import { Col, Row } from 'antd';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FilterDate } from 'app/components/FilterDate';
import { FilterInput } from 'app/components/FilterInput';
import { FilterMultiple } from 'app/components/FilterMultiple';
import { FilterSingle } from 'app/components/FilterSingle';
import {
	selectFuturesMonthsList,
	selectSelectedFilters,
} from 'app/containers/FuturesErp/selectors';
import {
	ErpStatusFilterMap,
	FuturesErpFilters,
} from 'app/containers/FuturesErp/types';
import {
	selectCommoditiesList,
	selectFeatureFlags,
} from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';
import { FILTERS_FOR_FUTURESERP } from 'utils/constants';
import {
	dateMoment,
	GetPersistedData,
	mapToLabelValue,
	mapToLabelValueObject,
	UpdatePersistedData,
} from 'utils/helpers';

import { actions } from '../../../../../slice';

const allDefaultOption = {
	id: 'all',
	name: '',
};

export const Filters = memo(function Filters() {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const commoditiesList = useSelector(selectCommoditiesList);
	const selectedFilters = useSelector(selectSelectedFilters);
	const futuresMonthsList = useSelector(selectFuturesMonthsList);
	const featureFlags = useSelector(selectFeatureFlags);

	const erpTranslationScope = translations.app.containers.FuturesErp;
	const translationsScope =
		translations.app.containers.ReviewAndRelease.components.Views.List.Menu
			.Filters;

	const persistedFilters = GetPersistedData<null | typeof selectedFilters>(
		FILTERS_FOR_FUTURESERP,
		null,
	);

	// Apply persisted filters
	useEffect(() => {
		dispatch(actions.setSelectedFilters(persistedFilters));
		dispatch(actions.getFuturesMonthsRequest());
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);
		dispatch(actions.loadFuturesErpList());
	}, []);

	const translationsDomain =
		translations.app.containers.Contracts.ListMenu.filters;

	const allCommoditiesOption = {
		...allDefaultOption,
		name: translate(translationsDomain.commodities.all),
	};

	const allFuturesMonthsOption = {
		...allDefaultOption,
		name: translate(translationsScope.futuresMonthAll),
	};
	const commoditiesOptions = mapToLabelValue([
		allCommoditiesOption,
		...commoditiesList,
	]);
	const futuresMonthsOptions = [
		mapToLabelValueObject(allFuturesMonthsOption),
		...futuresMonthsList,
	];

	const statusOptions = [
		{
			label: translate(translationsDomain.status.all),
			value: [],
		},
		...Object.keys(ErpStatusFilterMap)
			.filter((key) => isNaN(Number(key)))
			.map((key) => ({
				label: translate(erpTranslationScope[key]),
				value: ErpStatusFilterMap[key],
			})),
	];

	const handleChangeField = (obj: FuturesErpFilters) => {
		UpdatePersistedData(FILTERS_FOR_FUTURESERP, selectedFilters, obj);
		dispatch(actions.setSelectedFilters(obj));
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);
		dispatch(actions.loadFuturesErpList());
	};

	const FilterTranslationsScope =
		translations.app.containers.FuturesErpContainer.components.Filters;

	const handleChangeStartDate = (selectedDate) => {
		handleChangeField({ startDate: selectedDate || null });
	};

	const handleChangeEndDate = (selectedDate) => {
		handleChangeField({ endDate: selectedDate || null });
	};

	const handleChangeAccountName = (value) => {
		handleChangeField({ accountNo: value || null });
	};
	const handleChangeNumber = (value) => {
		handleChangeField({ orderNo: value || null });
	};

	const getFilteredFuturesErpList = () => {
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);

		dispatch(actions.loadFuturesErpList());
	};

	const handleChangeCommodities = (selectedValues) => {
		UpdatePersistedData(FILTERS_FOR_FUTURESERP, selectedFilters, {
			commodityId: selectedValues,
		});

		dispatch(
			actions.setSelectedFilters({
				commodityId: selectedValues,
			}),
		);

		getFilteredFuturesErpList();
	};

	const handleChangeStatus = (selectedValue) => {
		UpdatePersistedData(FILTERS_FOR_FUTURESERP, selectedFilters, {
			erpStatus: selectedValue,
		});
		dispatch(
			actions.setSelectedFilters({
				erpStatus: selectedValue,
			}),
		);

		getFilteredFuturesErpList();
	};

	const handleChangeFuturesMonth = (value) => {
		UpdatePersistedData(FILTERS_FOR_FUTURESERP, selectedFilters, {
			futuresMonth: value || null,
		});

		dispatch(
			actions.setSelectedFilters({
				futuresMonth: !!value ? value : null,
			}),
		);

		getFilteredFuturesErpList();
	};

	const newFiltersLayout = featureFlags[FeatureFlag.enableNewFilterLayout];
	return (
		<>
			<section className="grid-main-filters">
				<div className="grid-menu-label">
					{translate(translations.app.containers.Contracts.ListMenu.label)}
				</div>
				<Row gutter={newFiltersLayout ? [8, 8] : [16, { sm: 16, md: 0 }]}>
					<Col {...(newFiltersLayout ? { xs: 12, md: 3, xl: 2 } : { span: 1 })}>
						<FilterDate
							name="startDate"
							handleChange={handleChangeStartDate}
							defaultValue={dateMoment(
								!!selectedFilters.startDate
									? selectedFilters.startDate
									: new Date(),
							)}
							placeholder={translate(translationsScope.startDate)}
							currentDate={
								!!selectedFilters.startDate
									? dateMoment(selectedFilters.startDate)
									: null
							}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 3, lg: 3, xl: 2 }
							: { span: 1 })}
					>
						<FilterDate
							name="endDate"
							currentDate={
								!!selectedFilters.endDate
									? dateMoment(selectedFilters.endDate)
									: null
							}
							handleChange={handleChangeEndDate}
							placeholder={translate(translationsScope.endDate)}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3, xl: 2 }
							: { span: 1.5 })}
					>
						<FilterInput
							onSearch={handleChangeAccountName}
							placeholder={translate(translationsScope.accountName)}
							currentOption={selectedFilters.accountNo || ''}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3, xl: 2 }
							: { span: 2 })}
					>
						<FilterInput
							onSearch={handleChangeNumber}
							placeholder={translate(FilterTranslationsScope.order)}
							currentOption={selectedFilters.orderNo || ''}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3 }
							: { sm: 4, md: 2 })}
					>
						<FilterMultiple
							options={futuresMonthsOptions}
							handleChange={handleChangeFuturesMonth}
							currentOptions={selectedFilters.futuresMonth || []}
							placeholder={translate(
								translationsDomain.futuresMonth.placeHolder,
							)}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3 }
							: { sm: 4, md: 2 })}
					>
						<FilterMultiple
							currentOptions={selectedFilters.commodityId || []}
							handleChange={handleChangeCommodities}
							options={commoditiesOptions}
							placeholder={translate(translationsDomain.commodities.all)}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3 }
							: { sm: 4, md: 1 })}
					>
						<FilterSingle
							currentOption={selectedFilters.erpStatus || []}
							handleChange={handleChangeStatus}
							options={statusOptions}
							placeholder={translate(translationsDomain.status.label)}
						/>
					</Col>
				</Row>
			</section>
		</>
	);
});
