import './styles.scss';

import { Spin } from 'antd';
import React from 'react';

import { useNtcAdminData } from './hooks/useNtcAdminData';
import { NTC_ADMIN_DOMAIN } from './utils/helpers';
import NtcAdminForm from './Views/NtcAdminForm';

export const NtcAdmin = () => {
	const { isLoading, translate } = useNtcAdminData();

	const getTranslation = (key: string, values?: any) =>
		translate(NTC_ADMIN_DOMAIN[key], values);

	if (isLoading) {
		return <Spin data-testid="spinner-loading" key="spinner" />;
	}

	return (
		<div className="ntc-admin" data-testid="ntc-admin-form">
			<p className="header">{getTranslation('header')}</p>
			<p className="header">{getTranslation('subHeader')}</p>
			<NtcAdminForm />
		</div>
	);
};
