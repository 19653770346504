import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { GenericOption } from 'types/GenericOption';
import { IGenericPagination } from 'types/GenericPagination';
import { Offer, OfferInfoDto, OffersFilters } from 'types/Offer';
import { OfferDetails } from 'types/OfferDetails';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
	FIXED_COLUMNS_FOR_OFFERS,
	SELECTED_COLUMNS_FOR_OFFERS,
} from 'utils/constants';
import { GetPersistedData } from 'utils/helpers';

import { Modal as BidsheetModals } from '../Settings/sections/Bidsheet/types';
import { ContainerState, OffersModals, OffersViews } from './types';

// The initial state of the Offers container
export const initialState: ContainerState = {
	success: null,

	currentModal: null,
	currentView: OffersViews.Table,

	currentContractType: {
		label: '',
		value: '',
	},

	pagination: {
		limit: 20,
		start: 1,
	},

	offersList: {
		data: [],
		loading: false,
		total: 0,
		error: null,
	},

	selectedFilters: {
		commodities: [],
		customerId: null,
		date: null,
		futuresMonth: [],
		gtc: false,
		statusView: [
			{
				value: 'Working/Rejected',
				label: 'Working / Rejected', //FIXME: update when the endpoint is available
			},
		],
		numberOrCustomer: null,
		employeeId: [],
		orderDirection: [],
		destinations: [],
		expirationDate: null,
	},
	selectedRows: [],

	activeOffer: { data: null, loading: false, error: null },

	activeOfferDetails: { data: null, loading: false, error: null },

	selectedColumns: GetPersistedData(SELECTED_COLUMNS_FOR_OFFERS, {
		created: true,
		offer: true,
		expiration: true,
		customer: true,
		employee: true,
		commodity: true,
		crop: true,
		deliveryStartDate: true,
		deliveryEndDate: true,
		futuresMonth: true,
		destination: true,
		quantity: true,
		futures: true,
		basis: true,
		cash: true,
		dots: true,
	}),

	fixedColumns: GetPersistedData(FIXED_COLUMNS_FOR_OFFERS, {
		created: 'left',
		offer: false,
		expiration: false,
		customer: false,
		employee: false,
		commodity: false,
		crop: false,
		deliveryStartDate: false,
		deliveryEndDate: false,
		futuresMonth: false,
		destination: false,
		quantity: false,
		futures: false,
		basis: false,
		cash: false,
		dots: 'right',
	}),
};

const offersSlice = createSlice({
	name: 'offers',
	initialState,
	reducers: {
		resetSuccess(state) {
			state.success = null;
		},
		// Current Modal: Create, edit or cancel Offer
		setCurrentModal(
			state,
			action: PayloadAction<OffersModals | BidsheetModals | null>,
		) {
			state.currentModal = action.payload;

			if (state.currentModal === null) state.activeOffer.error = null;
		},

		// set Current action: View Details, Lsit Offers
		setCurrentView(state, action: PayloadAction<OffersViews | null>) {
			state.currentView = action.payload;
		},

		// Current type of contract in creation and edition (Flat price, HTA or Basis)
		setCurrentContractType(state, action: PayloadAction<GenericOption>) {
			state.currentContractType = action.payload;
		},

		setFixedColumns(state, action: PayloadAction<any>) {
			state.fixedColumns = {
				...state.fixedColumns,
				...action.payload,
			};
		},

		setSelectedColumns(state, action: PayloadAction<any>) {
			state.selectedColumns = {
				...state.selectedColumns,
				...action.payload,
			};
		},

		// ----- Offers ------
		loadOffersList(state, action: PayloadAction<boolean>) {
			const reset = action.payload;

			if (reset) {
				state.pagination.start = 1;
				state.selectedRows = [];
			}

			state.offersList = {
				...state.offersList,
				data: [],
				loading: true,
				error: null,
			};
		},

		offersListLoaded(
			state,
			action: PayloadAction<{
				data: Offer[];
				total: number;
			}>,
		) {
			const { data: offersList, total } = action.payload;

			state.offersList = {
				...state.offersList,
				data: offersList,
				total,
				loading: false,
				error: null,
			};
		},

		offerListError(state, action: PayloadAction<GenericError>) {
			state.offersList = {
				...state.offersList,
				error: action.payload,
				loading: false,
			};
		},

		updateOffersList: (state, action: PayloadAction<OfferInfoDto>) => {
			const { payload } = action;
			const offerIndex = state.offersList.data.findIndex(
				(offer) => offer.id === payload.id,
			);

			if (offerIndex !== -1) {
				// If the offer already exists, update its properties
				state.offersList.data[offerIndex].customer.name = payload.customerName;
				state.offersList.data[offerIndex].customer.number =
					payload.customerNumber || '';

				state.offersList.data[offerIndex].delivery.destination =
					payload.deliveryLocationName;
				state.offersList.data[offerIndex].delivery.begin =
					payload.deliveryStartDate as string;
				state.offersList.data[offerIndex].delivery.end =
					payload.deliveryEndDate as string;

				state.offersList.data[offerIndex].commodity = payload.commodityName;
				state.offersList.data[offerIndex].contract.type =
					payload.contractTypeName;
				state.offersList.data[offerIndex].creationDate = payload.createdOn;
				state.offersList.data[offerIndex].status = payload.status;
				state.offersList.data[offerIndex].contract.number = payload.number;

				state.offersList.data[offerIndex].isSell = payload.isSell;
				state.offersList.data[offerIndex].cropYear = payload.cropYear;

				state.offersList.data[offerIndex].price.price = payload.price;
				state.offersList.data[offerIndex].price.futuresMonth =
					payload.futuresMonth;
				state.offersList.data[offerIndex].price.futures =
					payload.futuresPrice || 0;
				state.offersList.data[offerIndex].price.freight = payload.freightPrice;

				state.offersList.data[offerIndex].quantity.quantity = payload.quantity;
				state.offersList.data[offerIndex].basis.push = payload.pushBasis;
				state.offersList.data[offerIndex].basis.net = payload.netBasis || 0;
				state.offersList.data[offerIndex].basis.posted =
					payload.postedBasis || 0;
				state.offersList.data[offerIndex].gtc = payload.gtc;
				state.offersList.data[offerIndex].expiration =
					payload.expiration?.toString() || null;
			} else {
				// TODO: If the offer doesn't exist
			}
		},

		setPagination(state, action: PayloadAction<IGenericPagination>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},

		loadOfferSummary(state, action: PayloadAction<any>) {
			state.activeOfferDetails = {
				data: null,
				loading: true,
				error: null,
			};
		},

		offerSummaryError(state, action: PayloadAction<GenericError>) {
			state.activeOfferDetails = {
				...state.activeOfferDetails,
				error: action.payload,
				loading: false,
			};
		},

		offerSummaryLoaded(state, action: PayloadAction<any>) {
			state.activeOfferDetails = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		/**
		 *
		 * @param state
		 * @param action if payload = null, clears all filters to initialValues
		 */
		setSelectedFilters(state, action: PayloadAction<OffersFilters | null>) {
			if (!!action.payload) {
				state.selectedFilters = {
					...state.selectedFilters,
					...action.payload,
				};
			} else {
				state.selectedFilters = {
					commodities: [],
					customerId: null,
					date: null,
					futuresMonth: [],
					gtc: false,
					statusView: null,
					numberOrCustomer: null,
					employeeId: [],
					orderDirection: [],
					destinations: [],
					expirationDate: null,
				};
			}
		},

		setActiveOffer(state, action: PayloadAction<any>) {
			state.activeOffer.data = action.payload;
		},

		setActiveOfferDetails(state, action: PayloadAction<any>) {
			state.activeOfferDetails.data = action.payload;
		},

		cancelOffer(state, action: PayloadAction<object>) {
			state.offersList = {
				...state.offersList,
				loading: true,
				error: null,
			};
		},
		offerCanceled(state) {
			state.offersList = {
				...state.offersList,
				loading: false,
				error: null,
			};
			state.success = true;
		},
		cancelOfferError(state, action: PayloadAction<GenericError>) {
			state.offersList = {
				...state.offersList,
				loading: false,
				error: action.payload,
			};
		},

		// book offer
		bookOffer(state, action: PayloadAction<object>) {
			state.offersList = {
				...state.offersList,
				loading: true,
				error: null,
			};
		},
		offerBooked(state) {
			state.offersList = {
				...state.offersList,
				loading: false,
				error: null,
			};
			state.success = true;
		},
		bookOfferError(state, action: PayloadAction<GenericError>) {
			state.offersList = {
				...state.offersList,
				loading: false,
				error: action.payload,
			};
		},

		resetOfferError(state) {
			state.offersList = {
				...state.offersList,
				loading: false,
				error: null,
			};
		},

		// Single offer details
		loadOfferDetails(state, action: PayloadAction<string>) {
			state.activeOffer = {
				data: null,
				loading: true,
				error: null,
			};
		},

		offerDetailsLoaded(state, action: PayloadAction<OfferDetails>) {
			state.activeOffer = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		// Create offer
		createOffer(state, action: PayloadAction<any>) {
			state.activeOffer = {
				data: null,
				loading: true,
				error: null,
			};

			state.success = null;
		},
		offerCreated(state) {
			state.activeOffer = {
				data: null,
				loading: false,
				error: null,
			};

			state.success = true;
		},

		// Update offer
		updateOffer(state, action: PayloadAction<any>) {
			state.activeOffer = {
				...state.activeOffer,
				loading: true,
				error: null,
			};

			state.success = false;
		},

		offerUpdated(state) {
			state.activeOffer = {
				data: null,
				loading: false,
				error: null,
			};

			state.success = true;
		},

		activeOfferError(state, action: PayloadAction<GenericError>) {
			state.activeOffer = {
				...state.activeOffer,
				error: action.payload,
				loading: false,
			};
		},

		completeOffer(state, action: PayloadAction<any>) {
			state.activeOffer = {
				...state.activeOffer,
				loading: true,
			};
		},

		setSelectedRows(state, action: PayloadAction<any>) {
			state.selectedRows = action.payload;
		},
		bookMultipleOffers(state, action: PayloadAction<object>) {
			state.offersList = {
				...state.offersList,
				loading: true,
				error: null,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = offersSlice;
