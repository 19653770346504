import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { commoditiesRoot } = apiEndpoints;

export function* editCommodity(action: PayloadAction<any>) {
	const { data, successMessage } = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${commoditiesRoot}?id=${data.id}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	// Success actions
	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);

		yield put(actions.setCurrentModal(null));
		yield put(actions.loadCommoditiesList());
		yield put(actions.commodityEdited(responseData));
	} else if (!!responseError) {
		yield put(actions.commodityError(responseError));
	}
}

export function* getCommodityById(action: PayloadAction<any>) {
	const { id } = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${commoditiesRoot}/${id}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.requestedCommodityById(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.commodityError(responseError));
	}
}
