import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { employeeDeactivate, employeeActivate } = apiEndpoints;

export function* setEmployeeStatus(action: PayloadAction<any>) {
	const { id, isActive } = action.payload;
	let requestURL;
	if (isActive) {
		requestURL = yield new URL(
			`${apiRoutes.base}/${employeeDeactivate}?id=${id}`,
		);
	} else {
		requestURL = yield new URL(
			`${apiRoutes.base}/${employeeActivate}?id=${id}`,
		);
	}

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.loadEmployeesList());
		yield put(actions.setEmployeeStatusSuccess(responseData));
	} else if (!!responseError) {
		yield put(actions.loadEmployeesList());
		yield put(actions.setEmployeeStatusError(responseError));
	}
}
