import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.bidsheet || initialState;

export const selectCurrentModal = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.currentModal,
);

export const selectBidsheetUpload = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.bidsheetTemplate,
);

export const selectSummaryData = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.bidsheetSummary.data,
);
export const selectSummaryLoading = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.bidsheetSummary.loading,
);

export const selectPublishLoading = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.bidsheetPublish.loading,
);

export const selectNoBidListData = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.noBidList.data,
);

export const selectNoBidMappedData = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.noBidList.mappedData,
);

export const selectSelectedNoBid = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.noBidList.selectedNoBid,
);

export const selectNoBidListLoading = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.noBidList.loading,
);

export const selectOrphansListData = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.orphansAndAvailables.orphansList,
);

export const selectSelectedRows = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.orphansAndAvailables.selectedRows,
);

export const selectAvailablesListData = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.orphansAndAvailables.availablesList,
);

export const selectSelectedAvailables = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.orphansAndAvailables.selectedAvailableBid,
);

export const selectOrphansAndAvailablesLoading = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.orphansAndAvailables.loading,
);

export const selectSelectedFilters = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.selectedFilters,
);

export const selectPagination = createSelector(
	[selectDomain],
	(employeesState) => employeesState.pagination,
);

export const selectListData = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.list.data,
);

export const selectListTotal = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.list.total,
);

export const selectHasAnythingChanged = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.list.hasAnythingChanged,
);

export const selectListLoading = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.list.loading,
);

export const selectBidsheetListType = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.list.type,
);

export const selectRollOrphanOffersLoading = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.rollOrphanOffers.loading,
);

export const selectTotalOrphans = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.orphansAndAvailables.totalOrphans,
);

export const selectRollOrphanOffersLoaded = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.rollOrphanOffers.loaded,
);

export const selectOrphanOffersCount = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.rollOrphanOffers.orphanCount,
);

export const selectValidationErrors = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.validationErrors.errors,
);

export const selectExportTemplate = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.bidsheetExport,
);

// Notification Groups
export const selectAllNotificationGroups = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.notificationGroups?.data,
);
export const selectAllNotificationGroupsLoading = createSelector(
	[selectDomain],
	(bidsheetState) => bidsheetState.notificationGroups?.loading,
);
