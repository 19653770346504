import { PayloadAction } from '@reduxjs/toolkit';
import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';
import { genericItemSchema } from 'utils/validators';

import { actions } from '../slice';
import { GenericLoadListPayload } from '../types';

const { customersRoot } = apiEndpoints;

const customersResponseSchema = z.object({
	total: z.number(),
	customers: z.array(genericItemSchema),
});

export function* getCustomersList(
	action: PayloadAction<GenericLoadListPayload>,
) {
	const source = action.payload.source;

	let requestURL = new URL(`${apiRoutes.base}/${customersRoot}`);
	if (action.payload.search) {
		requestURL.searchParams.append('name', action.payload.search);
	}

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: customersResponseSchema,
	});

	if (!!data) {
		const payload = {
			source,
			data: data.customers,
		};
		yield* put(actions.customersListLoaded(payload));
	} else if (!!error?.detail) {
		const payload = {
			source,
			error,
		};
		yield* put(actions.customersListError(payload));
	}
}
