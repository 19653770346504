import { useDispatch, useSelector } from 'react-redux';

import {
	selectEmployeeGroupDetails,
	selectEmployeeGroupDetailsLoading,
	selectEmployeeGroupMembers,
	selectEmployeeGroupMembersLoading,
	selectEmployeeGroupNonMembers,
	selectEmployeeGroupNonMembersLoading,
} from '../selectors';
import { actions } from '../slice';
import { NotificationGroupEditHookValue } from '../types';

export const useEmployeeGroupEdit = (): NotificationGroupEditHookValue => {
	const dispatch = useDispatch();
	const groupDetails = useSelector(selectEmployeeGroupDetails);
	const groupDetailsLoading = useSelector(selectEmployeeGroupDetailsLoading);
	const memberList = useSelector(selectEmployeeGroupMembers);
	const memberListLoading = useSelector(selectEmployeeGroupMembersLoading);
	const nonMemberList = useSelector(selectEmployeeGroupNonMembers);
	const nonMemberListLoading = useSelector(
		selectEmployeeGroupNonMembersLoading,
	);

	const onClose = () => dispatch(actions.clearEmployeeGroupState());

	const onSubmit = ({ groupName, groupMembers }) => {
		dispatch(
			actions.updateEmployeeGroup({
				id: groupDetails?.id,
				name: groupName,
				members: groupMembers,
			}),
		);
	};

	return {
		onClose,
		groupDetails,
		nonMemberList,
		memberList,
		onSubmit,
		loading: groupDetailsLoading || memberListLoading || nonMemberListLoading,
	};
};
