import { ContractType } from 'app/containers/GlobalSaga/types';

import {
	Commodity,
	CommodityBrokerMappingPayload,
	CommodityCropSection,
	CommodityCropSectionYears,
} from './types';

export const getBrokerMapping = (
	cropsSection: CommodityCropSection | CommodityCropSectionYears[],
	nonNtcContractTypes: ContractType[],
	commodityData?: Commodity,
) => {
	const brokerMappingValue: CommodityBrokerMappingPayload[] = [];

	for (const regionKey in cropsSection) {
		const regionCropYearsMap = cropsSection[regionKey];

		regionCropYearsMap.forEach((cropYearMap) => {
			const cropYear = cropYearMap.year;

			for (const contractTypeKey in cropYearMap) {
				const contractTypeFound = nonNtcContractTypes.find(
					(contractType) => contractType.code === contractTypeKey,
				);

				if (contractTypeFound) {
					const contractType: CommodityBrokerMappingPayload = {
						contractTypeId: contractTypeFound?.id || '',
						hedgeAccountId: cropYearMap[contractTypeKey].value,
						cropYear: cropYear,
						regionId: regionKey,
					};

					if (commodityData) {
						contractType.commodityId = commodityData.id;
					}
					brokerMappingValue.push(contractType);
				}
			}
		});
	}

	return brokerMappingValue;
};
