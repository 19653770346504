import { Button, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { translations } from 'locales/i18n';

import { actions } from '../../../slice';
import { Modal } from '../../../types';

export const Headers = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();

	const handleCreateCommodity = () => {
		dispatch(actions.setCurrentModal(Modal.Create));
	};

	const translationsRoot =
		translations.app.containers.Settings.sections.Commodities.View.List.Header;

	return (
		<div className="filters-container">
			<Row className="grid-menu" gutter={16}>
				<Col span={9}>
					<Row gutter={16}>
						<Col xs={3} md={4} xxl={3} className="grid-menu-label">
							{translate(translationsRoot.labelCommodities)}
						</Col>
					</Row>
				</Col>
				<Col span={3}>
					<Row justify="end">
						<Button
							type="primary"
							size="middle"
							onClick={handleCreateCommodity}
							data-testid="create-button"
							className="orders-button"
						>
							{translate(translationsRoot.createCommoditiesButton)}
						</Button>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
