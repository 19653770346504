import {
	FILTERS_FOR_CONTRACTS,
	FILTERS_FOR_OFFERS,
	FILTERS_FOR_REVIEW_AND_RELEASE,
	SESSION_NAME,
} from 'utils/constants';

export default function logout() {
	window.localStorage.removeItem(SESSION_NAME);
	window.localStorage.removeItem(FILTERS_FOR_CONTRACTS);
	window.localStorage.removeItem(FILTERS_FOR_OFFERS);
	window.localStorage.removeItem(FILTERS_FOR_REVIEW_AND_RELEASE);
	window.location.replace('/login');
}
