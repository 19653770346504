/**
 *
 * Delete User Role
 *
 */
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import { userRolesActions } from '../../../../slice';
import { Modal } from '../../../../types';

interface Props {
	rowData: any;
}

export const Delete = (props: Props) => {
	const dispatch = useDispatch();
	const { rowData } = props;

	const handleDeleteUserRole = (rowData) => {
		dispatch(userRolesActions.setActiveUserRoles(rowData));
		dispatch(userRolesActions.setCurrentModal(Modal.Delete));
	};

	return (
		<div className="actions">
			<Button
				type="text"
				data-testid="delete-button"
				onClick={() => handleDeleteUserRole(rowData)}
			>
				<DeleteOutlined />
			</Button>
		</div>
	);
};
