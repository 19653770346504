import './style.scss';

import { InputNumberProps } from 'antd/lib/input-number';
import classNames from 'classnames';
import { useField } from 'formik';
import { Form, InputNumber } from 'formik-antd';
import React, { memo } from 'react';

import { PRICE_KEYS } from 'utils/constants';
import { parseMaxIntegers } from 'utils/helpers';

interface Props extends InputNumberProps {
	name: string;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	arrowsHidden?: boolean;
	maxLength?: number;
	decimals?: number;
	integers?: number;
}
export const FormInputPrice = memo(function FormInputPrice(props: Props) {
	const {
		name,
		label,
		arrowsHidden,
		placeholder,
		disabled,
		decimals,
		integers,
	} = props;

	const [, meta, helpers] = useField(name);

	const handlePressDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const keyCode = event.keyCode;
		const notNumber = isNaN(parseInt(event.key));
		const restrictedKeys = !PRICE_KEYS.find((element) => element === keyCode);

		if (notNumber && restrictedKeys) {
			return event.preventDefault();
		}
	};

	const handleBlur = () => {
		const value =
			integers && meta.value
				? parseMaxIntegers(meta.value, integers)
				: meta.value;
		const parsedValue = parseFloat(value).toFixed(decimals);

		if (!!meta.value) {
			helpers.setValue(parsedValue);
		}
	};

	return (
		<Form.Item
			className="basic-input-form__label"
			label={label}
			name={name}
			key={name}
		>
			<InputNumber
				{...props}
				className={classNames('basic-input-form__input', {
					'arrows-hidden': arrowsHidden,
				})}
				name={name}
				data-testid="form-input-price"
				placeholder={placeholder}
				disabled={disabled}
				onKeyDown={(e) => handlePressDown(e)}
				onBlur={handleBlur}
				precision={decimals}
			/>
		</Form.Item>
	);
});
