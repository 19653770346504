import { GenericItem } from './GenericItem';

export enum AggregateType {
	Unspecified = 'Unspecified',
	Offer = 'Offer',
	Contract = 'Contract',
	Bidsheet = 'Bidsheet',
	ReviewAndRelease = 'ReviewAndRelease',
}

export interface UserInternalNotification extends GenericItem {
	subject: string;
	body: string;
	dateDelivered: Date;
	aggregateType: AggregateType;
	aggregateId: string;
	dateSeen: Date | null;
	dateDismissed: Date | null;
	path?: string;
}

export interface NotificationGroupMember extends GenericItem {
	email: string;
}
