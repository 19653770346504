import { GenericError } from 'types/GenericError';
import { UserInternalNotification } from 'types/UserNotification';

import { NotificationAlertTone } from '../GlobalSaga/types';

export interface PushNotification<T> {
	action: ActionType;
	data: T;
}

export enum ActionType {
	Insert = 0,
	Update = 1,
	Delete = 2,
}

/* --- STATE --- */
export interface HubSagaState {
	userNotifications: {
		data: UserInternalNotification[];
		loading: boolean;
		error: GenericError | null;
	};
	userAlertTone: NotificationAlertTone | null;
}

export type ContainerState = HubSagaState;
