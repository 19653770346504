import { PayloadAction } from '@reduxjs/toolkit';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { shallowEqual } from 'react-redux';

import { Commodity } from 'types/Commodity';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { FutureMonthDTO } from 'types/FutureMonth';
import { GenericError } from 'types/GenericError';
import { GenericItem } from 'types/GenericItem';
import { GroupedLocation } from 'types/GroupedLocation';
import { Location } from 'types/Location';
import { OrderEntryData } from 'types/OrderEntry';
import { Region } from 'types/Regions';
import { RoundingRule } from 'types/RoundingRule';
import { ServiceFeeData } from 'types/ServiceFee';
import { SessionActions } from 'types/SessionActions';
import { TransactionItem } from 'types/TransactionItem';
import { UserAccount } from 'types/UserAccount';
import { UserInfo } from 'types/UserInfo';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { flatmapToLabelValue, mapToLabelValue } from 'utils/helpers';

import {
	CalculatedFieldsSource,
	ContainerState,
	ContractType,
	CustomNotification,
	GenericLoadListErrorPayload,
	GenericLoadListPayload,
	GenericLoadListSuccessPayload,
	GlobalModals,
	LoadCachedFuturesPriceSuccessPayload,
	LoadDeliveryDatesPayload,
	LoadDeliveryDatesSuccessPayload,
	LoadFuturesMonthOptionsSuccessPayload,
	LoadFuturesMonthPayload,
	LoadFuturesMonthSuccessPayload,
	LoadFuturesMontOptionsPayload,
	LoadFuturesPricePayload,
	LoadFuturesPriceSuccessPayload,
	LoadPostedBasisPricePayload,
	LoadPostedBasisPriceSuccessPayload,
	NotificationGroupDto,
	NotificationMessagePayload,
	ReviewAndReleaseTransactionsStatus,
	UserDefaultValues,
} from './types';

// The initial state of the GlobalSaga container
export const initialState: ContainerState = {
	user: null,
	userInfo: null,
	currentModal: null,
	commodities: {
		data: [],
		loading: false,
		error: null,
	},
	crops: {
		data: [],
		loading: false,
		error: null,
	},
	transactions: {
		data: [],
		loading: false,
		error: null,
	},
	contractType: {
		contract: {
			data: [],
			loading: false,
			error: null,
		},
		offer: {
			data: [],
			loading: false,
			error: null,
		},
	},
	contractTypeCatalog: {
		data: [],
		loading: false,
		error: null,
	},
	event: {
		data: [],
		loading: false,
		error: null,
	},
	customers: {},
	locations: {
		data: [],
		loading: false,
		error: null,
	},
	roundingRules: {
		data: [],
		loading: false,
		error: null,
	},
	serviceFees: {
		data: [],
		loading: false,
		error: null,
	},
	serviceFeeTypes: {
		data: [],
		loading: false,
		error: null,
	},
	destinations: {
		data: [],
		loading: false,
		error: null,
	},
	groupedLocations: {
		data: [],
		loading: false,
		error: null,
	},
	defaultPage: {
		data: null,
		error: null,
		loading: false,
	},
	employees: {},

	futuresPrice: {},

	cachedFuturesPrice: {
		data: {
			futuresPrice: 0,
			priceControl: 0,
		},
		error: null,
	},

	deliveryDates: {},
	futuresMonth: {},
	postedBasisPrice: {},
	catalogFuturesMonths: {},
	futuresMonthOptions: {
		Transactions: {
			data: [],
			error: null,
			loading: false,
		},
		Settings: {
			data: [],
			error: null,
			loading: false,
		},
	},

	appNotifications: [],

	reviewAndReleaseStatus: {
		data: [],
		error: null,
		loading: false,
	},
	localStorage: {
		error: null,
		loading: false,
		sessionCheck: false,
		fromLocalStorage: false,
		fromService: false,
	},
	hedgeAccounts: {
		data: [],
		loading: false,
		error: null,
	},

	orderEntry: {
		data: null,
		error: null,
		loading: false,
	},
	ntcAdmin: {
		data: null,
		error: null,
		loading: false,
	},

	orderStatus: {
		data: {
			totalReadyTransactions: 0,
			anyTransactionRejected: false,
		},
		error: null,
		loading: false,
	},

	topbarTitle: '',

	employeeNotificationGroups: {
		data: [],
		loading: false,
		error: null,
	},
	customerNotificationGroups: {
		data: [],
		loading: false,
		error: null,
	},

	regions: {
		data: [],
		error: null,
		loading: false,
	},

	featureFlags: {},

	preHedgeStatus: {
		data: [],
		error: null,
		loading: false,
	},
};

const globalSagaSlice = createSlice({
	name: 'globalSaga',
	initialState,
	reducers: {
		getCommoditiesCropYearsExpired() {},

		loadInitData() {},

		setUserAccount(state, action: PayloadAction<UserAccount | null>) {
			state.user = action.payload;
		},
		setUserInfo(state, action: PayloadAction<UserInfo | null>) {
			state.userInfo = action.payload;
		},
		setCurrentModal(state, action: PayloadAction<GlobalModals | null>) {
			state.currentModal = action.payload;
		},
		// #region Catalogs
		// COMMODITIES
		loadCommoditiesList(state) {
			state.commodities = {
				data: [],
				loading: true,
				error: null,
			};
		},
		commoditiesLoaded(state, action: PayloadAction<Commodity[]>) {
			state.commodities = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		commoditiesError(state, action: PayloadAction<GenericError>) {
			state.commodities = {
				...state.commodities,
				loading: false,
				error: action.payload,
			};
		},

		//  CROP
		loadCropsList(state) {
			state.crops = {
				data: [],
				loading: true,
				error: null,
			};
		},
		cropsListLoaded(state, action: PayloadAction<number[]>) {
			state.crops = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		cropsError(state, action: PayloadAction<GenericError>) {
			state.crops = {
				...state.crops,
				error: action.payload,
				loading: false,
			};
		},

		// Contract Type list
		// contractTypeList is the different ContractType shown in create contract modal
		loadContractTypeList(state, action: PayloadAction<ContractTypeSource>) {
			state.contractType[action.payload] = {
				data: [],
				loading: true,
				error: null,
			};
		},
		contractTypeListLoaded(
			state,
			action: PayloadAction<{
				data: ContractType[];
				source: ContractTypeSource;
			}>,
		) {
			state.contractType[action.payload.source] = {
				data: action.payload.data,
				loading: false,
				error: null,
			};
		},
		contractTypeError(
			state,
			action: PayloadAction<{
				error: GenericError;
				source: ContractTypeSource;
			}>,
		) {
			state.contractType[action.payload.source] = {
				...state.contractType[action.payload.source],
				error: action.payload.error,
				loading: false,
			};
		},

		// Contract Type Catalog
		// contractTypeCatalog is the drop down list named "contract type" which we can see in  contract list page
		loadContractTypeCatalog(state) {
			state.contractTypeCatalog = {
				data: [],
				loading: true,
				error: null,
			};
		},
		contractTypeCatalogLoaded(state, action: PayloadAction<ContractType[]>) {
			state.contractTypeCatalog = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		contractTypeCatalogError(state, action: PayloadAction<GenericError>) {
			state.contractTypeCatalog = {
				...state.contractTypeCatalog,
				error: action.payload,
				loading: false,
			};
		},

		//Event
		loadEventList(state) {
			state.event = {
				data: [],
				loading: true,
				error: null,
			};
		},
		eventLoaded(state, action: PayloadAction<GenericItem[]>) {
			state.event = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		eventError(state, action: PayloadAction<GenericError>) {
			state.event = {
				...state.event,
				loading: false,
				error: action.payload,
			};
		},
		// Transactions list
		loadTransactionsList(state) {
			state.transactions = {
				data: [],
				loading: true,
				error: null,
			};
		},
		transactionsListLoaded(state, action: PayloadAction<TransactionItem[]>) {
			state.transactions = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		transactionsError(state, action: PayloadAction<GenericError>) {
			state.transactions = {
				data: [],
				error: action.payload,
				loading: false,
			};
		},

		// Customers list
		loadCustomersList(state, action: PayloadAction<GenericLoadListPayload>) {
			const sourceListName = action.payload.source;

			state.customers[sourceListName] = {
				data: [],
				loading: true,
				error: null,
			};
		},
		customersListLoaded(
			state,
			action: PayloadAction<GenericLoadListSuccessPayload>,
		) {
			const sourceListName = action.payload.source;

			state.customers[sourceListName] = {
				data: action.payload.data,
				loading: false,
				error: null,
			};
		},
		customersListError(
			state,
			action: PayloadAction<GenericLoadListErrorPayload>,
		) {
			const sourceListName = action.payload.source;

			state.customers[sourceListName] = {
				data: null,
				loading: false,
				error: action.payload.error,
			};
		},

		clearCustomersList(state, action: PayloadAction<{ source: string }>) {
			state.customers[action.payload.source] = {
				data: [],
				loading: false,
				error: null,
			};
		},

		// Locations list
		loadLocationsList(state) {
			state.locations = {
				...state.locations,
				loading: true,
			};
		},
		locationsListLoaded(state, action: PayloadAction<Location[]>) {
			state.locations = {
				...state.locations,
				data: action.payload,
				loading: false,
			};
		},
		locationsListError(state, action: PayloadAction<GenericError>) {
			state.locations = {
				...state.locations,
				loading: false,
				error: action.payload,
			};
		},

		// Locations list
		loadGroupedLocationsList(state) {
			state.groupedLocations = {
				...state.groupedLocations,
				loading: true,
			};
		},
		groupedLocationsListLoaded(
			state,
			action: PayloadAction<GroupedLocation[]>,
		) {
			state.groupedLocations = {
				...state.groupedLocations,
				data: action.payload,
				loading: false,
			};
		},
		groupedLocationsListError(state, action: PayloadAction<GenericError>) {
			state.groupedLocations = {
				...state.groupedLocations,
				loading: false,
				error: action.payload,
			};
		},

		// RoundingRulesListg
		loadRoundingRulesList(state) {
			state.roundingRules = {
				...state.roundingRules,
				loading: true,
			};
		},
		roundingRulesListLoaded(state, action: PayloadAction<RoundingRule[]>) {
			state.roundingRules = {
				...state.roundingRules,
				data: action.payload,
				loading: false,
			};
		},
		roundingRulesListError(state, action: PayloadAction<GenericError>) {
			state.roundingRules = {
				...state.roundingRules,
				loading: false,
				error: action.payload,
			};
		},

		// ServiceFeeList
		loadServiceFeeList(state) {
			state.serviceFees = {
				...state.serviceFees,
				loading: true,
			};
		},
		ServiceFeeListLoaded(state, action: PayloadAction<ServiceFeeData[]>) {
			state.serviceFees = {
				...state.serviceFees,
				data: action.payload,
				loading: false,
			};
		},
		ServiceFeeListError(state, action: PayloadAction<GenericError>) {
			state.serviceFees = {
				...state.serviceFees,
				loading: false,
				error: action.payload,
			};
		},

		// ServiceFeeTypes
		loadServiceFeeTypes(state) {
			state.serviceFeeTypes = {
				...state.serviceFeeTypes,
				loading: true,
			};
		},
		ServiceFeeTypesListLoaded(state, action: PayloadAction<GenericItem[]>) {
			state.serviceFeeTypes = {
				...state.serviceFeeTypes,
				data: action.payload,
				loading: false,
			};
		},
		ServiceFeeTypesListError(state, action: PayloadAction<GenericError>) {
			state.serviceFeeTypes = {
				...state.serviceFeeTypes,
				loading: false,
				error: action.payload,
			};
		},

		// Destinations list
		loadDestinationsList(state) {
			state.destinations = {
				...state.destinations,
				loading: true,
			};
		},
		destinationsListLoaded(state, action: PayloadAction<Location[]>) {
			state.destinations = {
				...state.destinations,
				data: action.payload,
				loading: false,
			};
		},
		destinationsListError(state, action: PayloadAction<GenericError>) {
			state.destinations = {
				...state.destinations,
				error: action.payload,
				loading: false,
			};
		},
		// Employees list
		loadEmployeesList(state, action: PayloadAction<GenericLoadListPayload>) {
			const sourceListName = action.payload.source;

			state.employees[sourceListName] = {
				data: [],
				loading: true,
				error: null,
			};
		},
		employeesListLoaded(
			state,
			action: PayloadAction<GenericLoadListSuccessPayload>,
		) {
			const sourceListName = action.payload.source;

			state.employees[sourceListName] = {
				data: action.payload.data,
				loading: false,
				error: null,
			};
		},
		employeesListError(
			state,
			action: PayloadAction<GenericLoadListErrorPayload>,
		) {
			const sourceListName = action.payload.source;

			state.employees[sourceListName] = {
				data: null,
				loading: false,
				error: action.payload.error,
			};
		},
		clearEmployeesList(state, action: PayloadAction<{ source: string }>) {
			state.employees[action.payload.source] = {
				data: [],
				loading: false,
				error: null,
			};
		},

		// Delivery Dates
		loadDeliveryDates(state, action: PayloadAction<LoadDeliveryDatesPayload>) {
			const sourceListName = action.payload.source;

			state.deliveryDates[sourceListName] = {
				data: null,
				loading: true,
				error: null,
			};
		},
		deliveryDatesLoaded(
			state,
			action: PayloadAction<LoadDeliveryDatesSuccessPayload>,
		) {
			const sourceListName = action.payload.source;

			state.deliveryDates[sourceListName] = {
				data: action.payload.data,
				loading: false,
				error: null,
			};
		},
		deliveryDatesError(
			state,
			action: PayloadAction<GenericLoadListErrorPayload>,
		) {
			const sourceListName = action.payload.source;

			state.deliveryDates[sourceListName] = {
				data: null,
				loading: false,
				error: action.payload.error,
			};
		},

		clearDeliveryDates(
			state,
			action: PayloadAction<string | CalculatedFieldsSource>,
		) {
			const sourceListName = action.payload;

			state.deliveryDates[sourceListName] = {
				data: null,
				loading: false,
				error: null,
			};
		},

		// Futures Month
		loadFuturesMonth(state, action: PayloadAction<LoadFuturesMonthPayload>) {
			const sourceListName = action.payload.source;

			state.futuresMonth[sourceListName] = {
				data: null,
				loading: true,
				error: null,
			};
		},
		futuresMonthLoaded(
			state,
			action: PayloadAction<LoadFuturesMonthSuccessPayload>,
		) {
			const sourceListName = action.payload.source;

			state.futuresMonth[sourceListName] = {
				data: action.payload.data,
				loading: false,
				error: null,
			};
		},
		futuresMonthError(
			state,
			action: PayloadAction<GenericLoadListErrorPayload>,
		) {
			const sourceListName = action.payload.source;

			state.futuresMonth[sourceListName] = {
				data: null,
				loading: false,
				error: action.payload.error,
			};
		},

		clearFuturesMonth(state, action: PayloadAction<string>) {
			const sourceListName = action.payload;

			state.futuresMonth[sourceListName] = {
				data: null,
				loading: false,
				error: null,
			};
		},

		// Posted Basis Price
		loadPostedBasisPrice(
			state,
			action: PayloadAction<LoadPostedBasisPricePayload>,
		) {
			const sourceListName = action.payload.source;

			state.postedBasisPrice[sourceListName] = {
				data: null,
				loading: true,
				error: null,
			};
		},
		postedBasisPriceLoaded(
			state,
			action: PayloadAction<LoadPostedBasisPriceSuccessPayload>,
		) {
			const sourceListName = action.payload.source;

			state.postedBasisPrice[sourceListName] = {
				data: action.payload.data,
				loading: false,
				error: null,
			};
		},
		postedBasisPriceError(
			state,
			action: PayloadAction<GenericLoadListErrorPayload>,
		) {
			const sourceListName = action.payload.source;

			state.postedBasisPrice[sourceListName] = {
				data: null,
				loading: false,
				error: action.payload.error,
			};
		},

		clearPostedBasisPrice(state, action: PayloadAction<string>) {
			const sourceListName = action.payload;

			state.postedBasisPrice[sourceListName] = {
				data: null,
				loading: false,
				error: null,
			};
		},

		// Futures Price
		loadFuturesPrice(state, action: PayloadAction<LoadFuturesPricePayload>) {
			const sourceListName = action.payload.source;

			state.futuresPrice[sourceListName] = {
				data: null,
				loading: true,
				error: null,
			};
		},
		futuresPriceLoaded(
			state,
			action: PayloadAction<LoadFuturesPriceSuccessPayload>,
		) {
			const sourceListName = action.payload.source;

			state.futuresPrice[sourceListName] = {
				data: action.payload.data,
				loading: false,
				error: null,
			};
		},
		futuresPriceError(
			state,
			action: PayloadAction<GenericLoadListErrorPayload>,
		) {
			const sourceListName = action.payload.source;

			state.futuresPrice[sourceListName] = {
				data: null,
				loading: false,
				error: action.payload.error,
			};
		},

		clearFuturesPrices(state, action: PayloadAction<string>) {
			const sourceListName = action.payload;

			state.futuresPrice[sourceListName] = {
				data: null,
				loading: false,
				error: null,
			};
		},

		// Cached Futures Price
		cachedFuturesPriceLoaded(
			state,
			action: PayloadAction<LoadCachedFuturesPriceSuccessPayload>,
		) {
			state.cachedFuturesPrice = {
				data: action.payload,
				error: null,
			};
		},
		cachedFuturesPriceError(
			state,
			action: PayloadAction<GenericLoadListErrorPayload>,
		) {
			state.cachedFuturesPrice = {
				data: {
					futuresPrice: 0,
					priceControl: 0,
				},
				error: action.payload.error,
			};
		},

		clearCachedFuturesPrice(state) {
			state.cachedFuturesPrice = {
				data: {
					futuresPrice: 0,
					priceControl: 0,
				},
				error: null,
			};
		},

		loadCatalogFuturesMonthsList(
			state,
			action: PayloadAction<GenericLoadListPayload>,
		) {
			const sourceListName = action.payload.source;

			state.catalogFuturesMonths[sourceListName] = {
				data: [],
				loading: true,
				error: null,
			};
		},
		catalogFuturesMonthsListLoaded(
			state,
			action: PayloadAction<LoadFuturesMonthOptionsSuccessPayload>,
		) {
			const sourceListName = action.payload.source;

			state.catalogFuturesMonths[sourceListName] = {
				data: mapToLabelValue(
					action?.payload.data.map((item) => ({
						...item,
						highlight: item.isExpired,
					})),
				),
				loading: false,
				error: null,
			};
		},
		catalogFuturesMonthsListError(
			state,
			action: PayloadAction<GenericLoadListErrorPayload>,
		) {
			const sourceListName = action.payload.source;

			state.catalogFuturesMonths[sourceListName] = {
				...state.catalogFuturesMonths[sourceListName],
				loading: false,
				error: action.payload.error,
			};
		},

		addNotification(state, action: PayloadAction<CustomNotification>) {
			const notifications = state.appNotifications;
			const newNotification = {
				...action.payload,
				id: crypto.randomUUID(),
			};

			const existingNotification = notifications.find(
				(element) =>
					element.type === newNotification.type &&
					shallowEqual(element.title, newNotification.title) &&
					shallowEqual(element.message, newNotification.message),
			);

			if (existingNotification === undefined) {
				state.appNotifications.push(newNotification);
			}
		},

		removeNotification(state, action: PayloadAction<string>) {
			const id = action.payload;
			const notifications: CustomNotification[] = state.appNotifications;

			const index = notifications.findIndex((element) => element.id === id);

			if (index > -1) {
				notifications.splice(index, 1);
			}
		},

		loadReviewAndReleaseStates(state) {
			state.reviewAndReleaseStatus = {
				...state.reviewAndReleaseStatus,
				loading: true,
			};
		},

		reviewAndReleaseStatesLoaded(state, action: PayloadAction<string[]>) {
			state.reviewAndReleaseStatus = {
				...state.reviewAndReleaseStatus,
				data: flatmapToLabelValue(action.payload),
				loading: false,
				error: null,
			};
		},
		reviewAndReleaseStatesError(state, action: PayloadAction<GenericError>) {
			state.reviewAndReleaseStatus = {
				...state.reviewAndReleaseStatus,
				error: action.payload,
				loading: false,
			};
		},

		loadPreHedgeStates(state) {
			state.preHedgeStatus = {
				...state.preHedgeStatus,
				loading: true,
			};
		},

		preHedgeStatesLoaded(state, action: PayloadAction<string[]>) {
			state.preHedgeStatus = {
				...state.preHedgeStatus,
				data: flatmapToLabelValue(action.payload),
				loading: false,
				error: null,
			};
		},

		preHedgeStatesError(state, action: PayloadAction<GenericError>) {
			state.preHedgeStatus = {
				...state.preHedgeStatus,
				error: action.payload,
				loading: false,
			};
		},

		startManageSession(
			state,
			action: PayloadAction<{
				sessionAction: SessionActions;
				accessToken?: string | null;
			}>,
		) {
			const sessionCheck =
				action.payload.sessionAction === SessionActions.getSession;

			state.localStorage = {
				...state.localStorage,
				loading: true,
				sessionCheck: sessionCheck,
			};
		},
		sessionLoadedFromLocalStorage(state) {
			state.localStorage = {
				...state.localStorage,
				loading: false,
				fromLocalStorage: true,
			};
		},
		sessionFromService(state) {
			state.localStorage = {
				...state.localStorage,
				loading: false,
				fromService: true,
			};
		},
		manageSessionError(state, action: PayloadAction<GenericError>) {
			const error = action.payload;
			state.localStorage = {
				...state.localStorage,
				error: error,
				loading: false,
			};
		},
		loadFuturesMonthOptions(
			state,
			action: PayloadAction<LoadFuturesMontOptionsPayload>,
		) {
			const source = action.payload.source;
			state.futuresMonthOptions[source] = {
				...state.futuresMonthOptions[source],
				loading: true,
			};
		},
		futuresMonthOptionsLoaded(
			state,
			action: PayloadAction<{ source: string; data: FutureMonthDTO[] }>,
		) {
			const source = action.payload.source;
			state.futuresMonthOptions[source] = {
				...state.futuresMonthOptions[source],
				loading: false,
				data: action.payload.data,
			};
		},
		futuresMonthOptionsError(
			state,
			action: PayloadAction<{ source: string; error: GenericError }>,
		) {
			const source = action.payload.source;
			state.futuresMonthOptions[source] = {
				...state.futuresMonthOptions[source],
				loading: false,
				error: action.payload.error,
			};
		},
		clearFuturesMonthOptions(state, action: PayloadAction<string>) {
			const source = action.payload;
			state.futuresMonthOptions[source] = {
				data: [],
				loading: false,
				error: null,
			};
		},

		loadHedgeAccounts(state) {
			state.hedgeAccounts = {
				...state.hedgeAccounts,
				data: [],
				loading: true,
				error: null,
			};
		},

		hedgeAccountListLoaded(
			state,
			action: PayloadAction<GenericItem[] | undefined>,
		) {
			state.hedgeAccounts = {
				...state.hedgeAccounts,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		hedgeAccountError(state, action: PayloadAction<GenericError>) {
			state.hedgeAccounts = {
				...state.hedgeAccounts,
				loading: false,
				error: action.payload,
			};
		},

		loadDefaultPage(state) {
			state.defaultPage = {
				...state.defaultPage,
				data: null,
				loading: true,
				error: null,
			};
		},

		defaultPageLoaded(state, action: PayloadAction<UserDefaultValues>) {
			state.defaultPage = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		defaultPageError(state, action: PayloadAction<GenericError>) {
			state.defaultPage = {
				...state.defaultPage,
				loading: false,
				error: action.payload,
			};
		},

		getOrderEntryError(state, action: PayloadAction<GenericError>) {
			state.orderEntry = {
				...state.orderEntry,
				error: action?.payload,
				loading: false,
			};
		},

		getOrderEntryRequest(state, action: PayloadAction<{ isActive: boolean }>) {
			state.orderEntry = {
				...state.orderEntry,
				data: null,
				error: null,
				loading: true,
			};
		},

		getOrderEntrySuccess(state, action: PayloadAction<OrderEntryData>) {
			state.orderEntry = {
				...state.orderEntry,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		getNtcAdminError(state, action: PayloadAction<GenericError>) {
			state.ntcAdmin = {
				...state.ntcAdmin,
				error: action?.payload,
				loading: false,
			};
		},

		getNtcAdminRequest(state) {
			state.ntcAdmin = {
				...state.ntcAdmin,
				data: null,
				error: null,
				loading: true,
			};
		},

		getNtcAdminSuccess(state, action: PayloadAction<ContractType[]>) {
			state.ntcAdmin = {
				...state.ntcAdmin,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		getReviewAndReleaseOrderStatus(state) {
			state.orderStatus = {
				...state.orderStatus,
				loading: true,
			};
		},
		getReviewAndReleaseOrderStatusSuccess(
			state,
			action: PayloadAction<ReviewAndReleaseTransactionsStatus>,
		) {
			state.orderStatus = {
				...state.orderStatus,
				loading: false,
				data: action.payload,
			};
		},
		getReviewAndReleaseOrderStatusError(
			state,
			action: PayloadAction<GenericError>,
		) {
			state.orderStatus = {
				...state.orderStatus,
				loading: false,
				error: action.payload,
			};
		},

		setPageTitle(state, action: PayloadAction<string>) {
			state.topbarTitle = action.payload;
		},

		// Notification Group settings
		loadEmployeeNotificationGroupList(state) {
			state.employeeNotificationGroups = {
				...state.employeeNotificationGroups,
				loading: true,
				error: null,
			};
		},
		employeeNotificationGroupListLoaded(
			state,
			action: PayloadAction<NotificationGroupDto[]>,
		) {
			state.employeeNotificationGroups = {
				...state.employeeNotificationGroups,
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		employeeNotificationGroupListError(
			state,
			action: PayloadAction<GenericError>,
		) {
			state.employeeNotificationGroups = {
				...state.employeeNotificationGroups,
				error: action.payload,
				loading: false,
			};
		},

		loadCustomerNotificationGroupList(state) {
			state.customerNotificationGroups = {
				...state.customerNotificationGroups,
				loading: true,
				error: null,
			};
		},

		customerNotificationGroupListLoaded(
			state,
			action: PayloadAction<NotificationGroupDto[]>,
		) {
			state.customerNotificationGroups = {
				...state.customerNotificationGroups,
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		customerNotificationGroupListError(
			state,
			action: PayloadAction<GenericError>,
		) {
			state.customerNotificationGroups = {
				...state.customerNotificationGroups,
				error: action.payload,
				loading: false,
			};
		},
		// Notification messages
		sendCustomNotification(
			state,
			action: PayloadAction<NotificationMessagePayload>,
		) {},

		getEnabledRegionsError(state, action: PayloadAction<GenericError>) {
			state.regions = {
				...state.regions,
				error: action?.payload,
				loading: false,
			};
		},

		getEnabledRegionsRequest(
			state,
			action: PayloadAction<{ isEmployeeActiveRegion: boolean }>,
		) {
			state.regions = {
				...state.regions,
				data: [],
				error: null,
				loading: true,
			};
		},

		getEnabledRegionsSuccess(state, action: PayloadAction<Region[]>) {
			state.regions = {
				...state.regions,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		featureFlagsLoaded(state, action: PayloadAction<LDFlagSet>) {
			state.featureFlags = action.payload;
		},
	},
});

export const { actions, reducer, name: sliceKey } = globalSagaSlice;
