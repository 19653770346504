import { EditFilled, EyeFilled, StopOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { NoteAddIcon } from 'app/components/CustomIcon';
import { actions } from 'app/containers/PreHedge/slice';
import { Modal } from 'app/containers/PreHedge/types';
import { translations } from 'locales/i18n';
import { PreHedge } from 'types/PreHedge';

interface Props {
	rowData: PreHedge;
}

const domain =
	translations.app.containers.ReviewAndRelease.components.Views.List
		.ActionsMenu;

export const ActionsMenu = memo(function ActionsMenu({ rowData }: Props) {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();

	const getTranslation = (key: string) => translate(domain[key]);

	const handleCancelOrder = (rowData) => {
		dispatch(actions.setCurrentModal(Modal.Cancel));
		dispatch(actions.setActivePreHedge(rowData));
	};

	const handleEditOrder = (rowData) => {
		dispatch(actions.loadPreHedgeDetail(rowData.id));
		dispatch(actions.setCurrentModal(Modal.Edit));
	};

	const handleCreatePreHedge = (rowData) => {
		dispatch(actions.loadPreHedgeDetail(rowData.id));
		dispatch(actions.setCurrentModal(Modal.ContractType));
	};

	const handleViewDetail = (rowData) => {
		dispatch(
			actions.loadPreHedgeSummary({
				id: rowData.id,
			}),
		);
	};

	return (
		<div className="actions" data-testid="table-actions">
			<Button
				data-testid="view-details-action"
				onClick={() => handleViewDetail(rowData)}
			>
				<EyeFilled />
				{getTranslation('viewDetails')}
			</Button>
			{rowData.restrictions.canBeCancelled && (
				<Button
					data-testid="cancel-action"
					onClick={() => handleCancelOrder(rowData)}
				>
					<StopOutlined />
					{getTranslation('cancel')}
				</Button>
			)}
			{rowData.restrictions.canBeEdited && (
				<Button
					data-testid="edit-action"
					onClick={() => handleEditOrder(rowData)}
				>
					<EditFilled />
					{getTranslation('edit')}
				</Button>
			)}
			{rowData.restrictions.canCreateContract && (
				<Button
					data-testid="create-action"
					onClick={() => handleCreatePreHedge(rowData)}
				>
					<NoteAddIcon />
					{getTranslation('create')}
				</Button>
			)}
		</div>
	);
});
