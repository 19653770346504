import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { fileRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { bidsheetExport } = apiEndpoints;

export function* exportBids(action: PayloadAction<any>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${bidsheetExport}`);
	const { successMsg, errorMsg } = action.payload;
	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Get,
		false,
		null,
		null,
		true,
	);

	if (!!responseData) {
		yield put(actions.exportedBids(responseData));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMsg + responseData.fileName,
			}),
		);
		yield put(actions.clearExportBids());
	} else if (!!responseError.detail) {
		yield put(actions.exportBidsError(responseError));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.ERROR,
				message: errorMsg,
			}),
		);
	}
}
