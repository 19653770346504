import { useAbac } from 'react-abac';
import { useSelector } from 'react-redux';

import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { Permission } from 'types/Authorization';
import { FeatureFlag } from 'types/FeatureFlags';

export const useFeatureFlags = () => {
	const { userHasPermissions } = useAbac();
	const featureFlags = useSelector(selectFeatureFlags);

	//system Flags
	const showFutureFirstTab = userHasPermissions(
		Permission.CONTRACTSERVICE_FUTUREFIRST_VIEW,
	);

	const isShowFutureFirstPermissionEnabled =
		featureFlags &&
		featureFlags[FeatureFlag.enablePreHedge] &&
		showFutureFirstTab;

	return {
		isShowFutureFirstPermissionEnabled,
	};
};
