import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.employees || initialState;

export const selectCurrentView = createSelector(
	[selectDomain],
	(employeesState) => employeesState.currentView,
);

export const selectCurrentModal = createSelector(
	[selectDomain],
	(employeesState) => employeesState.currentModal,
);

export const selectActiveEmployee = createSelector(
	[selectDomain],
	(employeesState) => employeesState.activeEmployee.data,
);

export const selectActiveEmployeeUserID = createSelector(
	[selectDomain],
	(employeesState) => employeesState.activeEmployee.userID,
);

export const selectActiveEmployeeError = createSelector(
	[selectDomain],
	(employeesState) => employeesState.activeEmployee.error,
);

export const selectListData = createSelector(
	[selectDomain],
	(employeesState) => employeesState.list.data,
);

export const selectListLoading = createSelector(
	[selectDomain],
	(employeesState) => employeesState.list.loading,
);

export const selectListTotal = createSelector(
	[selectDomain],
	(employeesState) => employeesState.list.total,
);
export const selectPaginationLimit = createSelector(
	[selectDomain],
	(employeesState) => employeesState.pagination.limit,
);

export const selectPaginationStart = createSelector(
	[selectDomain],
	(employeesState) => employeesState.pagination.start,
);
export const selectFilters = createSelector(
	[selectDomain],
	(employeesState) => employeesState.selectedFilters,
);

export const selectEmployeeStatusError = createSelector(
	[selectDomain],
	(employeesState) => employeesState.employeeStatus.error,
);
export const selectEmployeeStatusLoading = createSelector(
	[selectDomain],
	(employeesState) => employeesState.employeeStatus.loading,
);
export const selectEmployeeStatusData = createSelector(
	[selectDomain],
	(employeesState) => employeesState.employeeStatus.data,
);

export const selectEmployeePermissionsData = createSelector(
	[selectDomain],
	(employeesState) => employeesState.permissions.data,
);

export const selectEmployeePermissionsLoading = createSelector(
	[selectDomain],
	(employeesState) => employeesState.permissions.loading,
);

export const selectActiveEmployeeLoading = createSelector(
	[selectDomain],
	(employeesState) => employeesState.activeEmployee.loading,
);

export const selectActiveEditEmployee = createSelector(
	[selectDomain],
	(employeesState) => employeesState.activeEmployee.data,
);

export const selectErrorsInline = createSelector(
	[selectDomain],
	(employeesState) => employeesState.errorsInLine,
);

export const selectEmployeeLocations = createSelector(
	[selectDomain],
	(employeesState) => employeesState.employeeRoles.locations.data,
);

export const selectEmployeeLimit = createSelector(
	[selectDomain],
	(employeesState) => employeesState.employeeRoles.limits.data,
);
