import './styles.scss';

import { DeleteOutlined } from '@ant-design/icons';
import { Badge, Button, Popover, Spin, Tabs } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectUserNotifications } from 'app/containers/HubSaga/selectors';
import { actions } from 'app/containers/HubSaga/slice';
import { ROUTES } from 'app/routes';
import { translations } from 'locales/i18n';

import { BellIcon } from '../CustomIcon';
import { NotificationTab } from './components/NotificationTab';

export const BellNotifications = memo(function BellNotifications() {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const userNotifications = useSelector(selectUserNotifications);
	const [notifications, setNotifications] = useState(userNotifications.data);
	const translationsScope = translations.app.components.BellNotifications;

	useEffect(() => {
		const newNotifications = userNotifications.data.filter(
			(item) => !item.dateSeen,
		);
		setNotifications(newNotifications);
	}, [userNotifications]);

	const dismissNotification = (id: string) => {
		dispatch(actions.dismissNotificationItem(id));
	};
	const dismissAllNotifications = () => {
		dispatch(actions.dismissAllNotifications());
	};
	const handleVisibleChange = (visible: boolean) => {
		if (!visible) {
			dispatch(actions.markNotificationsAsSeen());
		}
		setVisible(visible);
	};
	const markAsSeen = () => {
		setVisible(false);
		dispatch(actions.markNotificationsAsSeen());
	};

	const popupTrigger = (
		<Badge className="notification-trigger" count={notifications.length}>
			<BellIcon />
		</Badge>
	);
	const dismissAllButton = userNotifications.data.length ? (
		<DeleteOutlined
			onClick={dismissAllNotifications}
			className="notification-dismiss-all"
		/>
	) : (
		''
	);
	const popupContent = (
		<Spin spinning={userNotifications.loading}>
			<Tabs className="notification-tabs" tabBarExtraContent={dismissAllButton}>
				<Tabs.TabPane
					tab={translate(translationsScope.notificationsTabLabel)}
					key="Notifications"
					animated={true}
				>
					<NotificationTab
						data={userNotifications.data}
						onDismissNotification={dismissNotification}
						onItemClick={markAsSeen}
					/>
				</Tabs.TabPane>
			</Tabs>

			<div className="notification-view-all">
				<Button block onClick={markAsSeen}>
					<Link to={ROUTES.NOTIFICATIONS_HISTORY}>
						{translate(translationsScope.viewAll)}
					</Link>
				</Button>
			</div>
		</Spin>
	);

	return (
		<Popover
			placement="bottomRight"
			content={popupContent}
			trigger="click"
			arrowPointAtCenter
			overlayClassName="notification-popover"
			visible={visible}
			onVisibleChange={handleVisibleChange}
		>
			{popupTrigger}
		</Popover>
	);
});
