import React, { memo } from 'react';

import { GenericForm } from 'app/components/GenericForm';
import { SwitchStateFull } from 'app/components/SwitchStateFull';
import { translations } from 'locales/i18n';

import { useHedgeIntegrationSwitchFormData } from '../../hooks/useHedgeIntegrationSwitchFormData';
import { actions } from '../../slice';
import { SWITCH_FORM_ITEMS } from '../../types';

const domain =
	translations.app.containers.Settings.sections.ERPIntegration.components
		.HedgeIntegrationSwitch;

export const HedgeIntegrationSwitch = memo(function HedgeIntegrationSwitch() {
	const { dispatch, hedgeIntegration, initialValues, translate } =
		useHedgeIntegrationSwitchFormData();

	const getTranslation = (key: string) => translate(domain[key]);

	const handleConfirm = (active: boolean) => {
		const data = {
			...hedgeIntegration,
			[SWITCH_FORM_ITEMS.HedgeIntegration]: !active,
		};
		dispatch(actions.setHedgeIntegrationRequest({ data }));
	};

	return (
		<GenericForm.FormItem
			colon={false}
			data-testid={`${SWITCH_FORM_ITEMS.HedgeIntegration}-form-item`}
			label={getTranslation('label')}
			name={SWITCH_FORM_ITEMS.HedgeIntegration}
		>
			<SwitchStateFull
				cancelButtonText={getTranslation('cancelButtonText')}
				confirmButtonText={getTranslation('confirmButtonText')}
				customHandleConfirm={handleConfirm}
				errorSelector={actions.setERPIntegrationError}
				initialStatus={
					initialValues[SWITCH_FORM_ITEMS.HedgeIntegration] as boolean
				}
				recordId={SWITCH_FORM_ITEMS.HedgeIntegration}
				stateControlled={false}
				toggleAction={actions.setERPIntegrationRequest}
				toggleOffMessage={getTranslation('toggleOffMessage')}
				toogleOnMessage={getTranslation('toggleOnMessage')}
			/>
		</GenericForm.FormItem>
	);
});
