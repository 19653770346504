import { takeLatest } from 'redux-saga/effects';

import {
	bookMultipleOffers,
	bookOffer,
	cancelOffer,
	completeOffer,
	createOffer,
	getOfferDetails,
	getOffersList,
	getOfferSummary,
	updateOffer,
} from './sagas/offers-saga';
import { actions } from './slice';

export function* offersSaga() {
	yield takeLatest(actions.loadOffersList.type, getOffersList);

	yield takeLatest(actions.loadOfferSummary.type, getOfferSummary);

	yield takeLatest(actions.cancelOffer.type, cancelOffer);

	yield takeLatest(actions.createOffer.type, createOffer);

	yield takeLatest(actions.loadOfferDetails.type, getOfferDetails);

	yield takeLatest(actions.updateOffer.type, updateOffer);

	yield takeLatest(actions.bookOffer.type, bookOffer);

	yield takeLatest(actions.completeOffer.type, completeOffer);

	yield takeLatest(actions.bookMultipleOffers.type, bookMultipleOffers);
}
