import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectNtcAdmin } from 'app/containers/GlobalSaga/selectors';

import { useNtcAdminSchema } from '../../../schemas/useNtcAdminSchema';

export const useNtcAdminFormData = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const NTCs = useSelector(selectNtcAdmin);

	const { initialValues, validationSchema } = useNtcAdminSchema();
	const formInstance = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		defaultValues: {
			...initialValues,
		},
	});

	return {
		dispatch,
		formInstance,
		initialValues,
		isCreatingNTCs: NTCs?.length === 0,
		NTCs,
		translate,
		validationSchema,
	};
};
