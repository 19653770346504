import { PayloadAction } from '@reduxjs/toolkit';

import { FileResponse } from 'types/FileResponse';
import { FutureMonth } from 'types/FutureMonth';
import { GenericError } from 'types/GenericError';
import { CreatePreHedgeData } from 'types/PreHedge';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { dateFormatYearMonthDay, mapToLabelValue } from 'utils/helpers';

import {
	ContainerState,
	ContractDetails,
	CreatePreHedgePayload,
	ExportExcelPayload,
	Modal,
	PaginationPayload,
	PreHedgeFilters,
	PreHedgeFixedColumns,
	PreHedgeListLoadedPayload,
	PreHedgeStatusUpdate,
	PreHedgeViews,
	UpdatePreHedgePayload,
} from './types';

export const initialState: ContainerState = {
	success: null,

	exportPreHedge: {
		error: null,
		fileName: '',
		loading: false,
		url: null,
	},

	activePreHedgeDetails: {
		data: null,
		error: null,
		loading: false,
	},

	activePreHedge: {
		data: null,
		loading: false,
		error: null,
	},

	currentModal: null,

	currentView: PreHedgeViews.table,

	list: {
		totalOrphanedBalance: { buy: null, sell: null },
		allLoaded: false,
		data: [],
		error: null,
		loading: false,
		total: 0,
	},

	pagination: {
		limit: 50,
		start: 1,
	},

	orderStatus: {
		data: false,
		error: null,
		loading: false,
		isStreaming: false,
	},

	selectedFilters: {
		accountName: null,
		futuresMonth: [],
		startDate: null,
		number: null,
		endDate: null,
		status: [],
		symbol: [],
	},

	selectedRows: [],

	futureMonths: {
		data: [],
		error: null,
		loading: false,
	},

	fixedColumns: {
		created: 'left',
		updateDate: false,
		status: false,
		account: false,
		orderNumber: false,
		sellBuy: false,
		source: false,
		symbol: false,
		futuresMonth: false,
		type: false,
		gtc: false,
		price: false,
		avgFillPrice: false,
		quantity: false,
		workingQty: false,
		dots: 'right',
	},
	ContractDetails: {},
};

const preHedgeSlice = createSlice({
	name: 'preHedge',
	initialState,
	reducers: {
		resetSuccess(state) {
			state.success = null;
		},
		setContractStateFromPreHedge(
			state,
			action: PayloadAction<ContractDetails>,
		) {
			state.ContractDetails = {
				...state.ContractDetails,
				...action.payload,
			};
		},

		clearContractStateFromPreHedge(state) {
			state.ContractDetails = {};
		},
		/**
		 *
		 * @param state
		 * @param action if payload = null, clears all filters to initialValues
		 */
		setSelectedFilters(state, action: PayloadAction<PreHedgeFilters | null>) {
			if (!!action.payload) {
				state.selectedFilters = {
					...state.selectedFilters,
					...action.payload,
				};
			} else {
				state.selectedFilters = {
					status: [],
					startDate: dateFormatYearMonthDay(new Date()),
				};
			}
		},

		setActivePreHedge(state, action: PayloadAction<CreatePreHedgeData>) {
			state.activePreHedge.data = action.payload;
		},
		clearActivePreHedge(state) {
			state.activePreHedge = {
				data: null,
				loading: true,
				error: null,
			};
		},

		loadPreHedgeList(state, action: PayloadAction<boolean>) {
			const reset = action.payload;

			if (reset) {
				state.pagination.start = 1;
				state.list.allLoaded = false;
				state.list.data = [];
			}

			state.list = {
				...state.list,
				data: state.list.data,
				loading: true,
				error: null,
			};
		},
		preHedgeListLoaded(
			state,
			action: PayloadAction<PreHedgeListLoadedPayload>,
		) {
			const { list, total, totalOrphanedBalance } = action.payload;

			state.list = {
				...state.list,
				data: list,
				totalOrphanedBalance,
				loading: false,
				error: null,
				total,
			};
			state.list.allLoaded = state.list.data.length === state.list.total;
		},
		preHedgeListError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
			state.list.allLoaded = state.list.data.length === state.list.total;
		},
		setPagination(state, action: PayloadAction<PaginationPayload>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},

		loadPreHedgeSummary(state, action: PayloadAction<{ id: string }>) {
			state.activePreHedgeDetails = {
				data: null,
				loading: true,
				error: null,
			};
		},

		preHedgeSummaryError(state, action: PayloadAction<GenericError>) {
			state.activePreHedgeDetails = {
				...state.activePreHedgeDetails,
				error: action.payload,
				loading: false,
			};
		},

		preHedgeSummaryLoaded(state, action: PayloadAction<CreatePreHedgeData>) {
			state.activePreHedgeDetails = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		setSelectedRows(state, action: PayloadAction<CreatePreHedgeData[]>) {
			state.selectedRows = action.payload;
		},
		loadPreHedgeDetail(state, action: PayloadAction<{ id: string }>) {
			state.activePreHedge = {
				data: null,
				loading: true,
				error: null,
			};
		},
		preHedgeDetailLoaded(state, action: PayloadAction<CreatePreHedgeData>) {
			state.activePreHedge = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},
		preHedgeDetailError(state, action: PayloadAction<GenericError>) {
			state.activePreHedge = {
				...state.activePreHedge,
				loading: false,
				error: action.payload,
			};
		},
		setCurrentView(state, action: PayloadAction<PreHedgeViews>) {
			state.currentView = action.payload;
		},

		// Create Pre-Hedge
		createPreHedge(state, action: PayloadAction<CreatePreHedgePayload>) {
			state.activePreHedge = {
				data: null,
				loading: true,
				error: null,
			};

			state.success = null;
		},
		preHedgeCreated(state) {
			state.activePreHedge = {
				data: null,
				loading: false,
				error: null,
			};

			state.success = true;
		},

		cancelPreHedge(state, action: PayloadAction<string>) {
			state.list = {
				...state.list,
				loading: true,
				error: null,
			};
		},
		preHedgeCanceled(state) {
			state.list = {
				...state.list,
				loading: false,
				error: null,
			};
			state.success = true;
		},
		cancelPreHedgeError(state, action: PayloadAction<GenericError>) {
			state.list = {
				...state.list,
				loading: false,
				error: action.payload,
			};
		},

		updatePreHedge(state, action: PayloadAction<UpdatePreHedgePayload>) {
			state.activePreHedge = {
				...state.activePreHedge,
				loading: true,
				error: null,
			};

			state.success = false;
		},

		preHedgeUpdated(state) {
			state.activePreHedge = {
				data: null,
				loading: false,
				error: null,
			};

			state.success = true;
		},

		preHedgeRefresh(state, action: PayloadAction<PreHedgeStatusUpdate>) {
			state.list.data = state.list.data.map((item) => {
				if (item.id === action.payload.id) {
					return {
						...item,
						...action.payload,
					};
				}
				return item;
			});
		},

		activePreHedgeError(state, action: PayloadAction<GenericError>) {
			state.activePreHedge = {
				...state.activePreHedge,
				error: action.payload,
				loading: false,
			};
		},

		setStartPagination(
			state,
			action: PayloadAction<{
				limit?: number;
				start?: number;
			}>,
		) {
			state.pagination = {
				...state.pagination,
				start: action.payload.start || state.pagination.start,
			};
		},
		setActivePreHedgeDetails(state, action: PayloadAction<null>) {
			state.activePreHedgeDetails.data = action.payload;
		},

		setCurrentModal(state, action: PayloadAction<Modal | null>) {
			state.currentModal = action.payload;

			if (state.currentModal === null) state.activePreHedge.error = null;
		},

		exportPreHedge(state, action: PayloadAction<ExportExcelPayload>) {
			state.exportPreHedge = {
				...state.exportPreHedge,
				loading: true,
			};
		},
		exportedPreHedge(state, action: PayloadAction<FileResponse>) {
			state.exportPreHedge = {
				url: action.payload.url,
				fileName: action.payload.fileName,
				error: null,
				loading: false,
			};
		},
		exportPreHedgeError(state, action: PayloadAction<GenericError>) {
			state.exportPreHedge = {
				...state.exportPreHedge,
				error: action.payload,
				loading: false,
			};
		},

		setFixedColumns(state, action: PayloadAction<PreHedgeFixedColumns>) {
			state.fixedColumns = {
				...state.fixedColumns,
				...action.payload,
			};
		},
		clearExportPreHedge(state) {
			state.exportPreHedge = {
				url: null,
				fileName: null,
				loading: false,
				error: null,
			};
		},

		getFuturesMonthsError(state, action: PayloadAction<GenericError>) {
			state.futureMonths = {
				...state.futureMonths,
				error: action?.payload,
				loading: false,
			};
		},

		getFuturesMonthsRequest(state) {
			state.futureMonths = {
				...state.futureMonths,
				data: [],
				error: null,
				loading: true,
			};
		},

		getFuturesMonthsSuccess(state, action: PayloadAction<FutureMonth[]>) {
			state.futureMonths = {
				...state.futureMonths,
				data: mapToLabelValue(
					action?.payload.map((item) => ({
						...item,
						highlight: item.isExpired,
					})),
				),
				error: null,
				loading: false,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = preHedgeSlice;
