import './style.scss';

import {
	CheckCircleOutlined,
	CloseCircleFilled,
	CloseCircleOutlined,
	ExclamationCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { Select as AntSelect } from 'antd';
import classNames from 'classnames';
import { useField } from 'formik';
import { Form, Select } from 'formik-antd';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';

const { Option } = AntSelect;

interface Props {
	name: string;
	label?: string;
	placeholder?: string;
	onSearch: (string) => void;
	options: any;
	onSelect?: (string) => void;
	disabled?: boolean;
	loading?: boolean;
	required?: boolean;
	fieldsNeedToBeChanged: string;
	filterSelectedItem: (selectedValue: string) => {
		label?: string;
		value?: string;
		type?: string;
	};
	isEditMode?: boolean;
}

enum StatusType {
	success = 'success',
	warning = 'warning',
	error = 'error',
	validating = 'validating',
	none = '',
}

export const FormSearchWithInputForLocation = memo(
	function FormSearchWithInputForLocation(props: Props) {
		const [currentSearchKey, setCurrentSearchKey] = useState('');
		const [status, setStatus] = useState<StatusType>(StatusType.none);
		const [newOption, setNewOption] = useState('');

		const staticNewOption = 'newOption';

		const domain = translations.app.containers.Settings.sections.Locations;

		const { t: translate } = useTranslation();

		const {
			name,
			disabled,
			loading,
			required,
			fieldsNeedToBeChanged,
			filterSelectedItem,
			onSearch,
			isEditMode,
		} = props;

		const [, { value, touched, error }, { setError, setTouched, setValue }] =
			useField(name);
		const [, , helpers] = useField(fieldsNeedToBeChanged);

		const handleBlur = () => {
			if (currentSearchKey) setCurrentSearchKey('');
		};
		const handleChange = (itemSelected: GenericOption) => {
			if (currentSearchKey) setCurrentSearchKey('');
			if (!itemSelected) {
				setValue('');
				helpers.setValue('');
				return;
			}

			if (itemSelected.value === staticNewOption) {
				props.onSelect && props.onSelect(newOption);
				setValue({ value: newOption });
			} else {
				props.onSelect && props.onSelect(itemSelected);
				setValue(itemSelected || currentSearchKey);
			}
			const filteredData = filterSelectedItem(itemSelected?.label);

			if (filteredData !== undefined) helpers.setValue(filteredData);
		};

		const useEffectValidateState = (effect: React.EffectCallback) => {
			useEffect(effect, [value, currentSearchKey, loading, touched, error]);
		};
		useEffectValidateState(() => {
			let status = StatusType.none;
			const warningMessage = translate(
				translations.app.components.SearchWithFeedback.unavailable,
			);

			if (loading) {
				status = StatusType.validating;
			} else if (value && !currentSearchKey) {
				status = StatusType.success;
			} else if (currentSearchKey && props.options.length === 0) {
				status = StatusType.warning;
				setError(warningMessage);
			} else if (!currentSearchKey && touched && error) {
				if (error === warningMessage) status = StatusType.warning;
				else status = StatusType.error;
			}
			setStatus(status);
		});

		useEffect(() => {
			if (value === undefined) {
				setValue('');
				setTouched(true);
			}
		}, [setTouched, setValue, value]);

		const selectOptions =
			currentSearchKey &&
			!!props.options &&
			props.options.map((item) => (
				<Option value={item.value} key={item.value}>
					{item.label}
				</Option>
			));

		const handleSearch = (searchText) => {
			setCurrentSearchKey(searchText);
			searchText && onSearch && onSearch(searchText);
			if (searchText.length > 0) {
				setNewOption(searchText);
			}
		};

		return (
			<Form.Item
				label={props.label}
				name={props.name}
				className={classNames('search-form__label', {
					[`${status}`]: !!status,
				})}
				hasFeedback
				validateStatus={status}
				required={required}
			>
				<Select
					labelInValue
					name={props.name}
					showSearch
					placeholder={props.placeholder}
					defaultActiveFirstOption={true}
					onSearch={handleSearch}
					autoClearSearchValue={false}
					onBlur={handleBlur}
					onChange={handleChange}
					disabled={disabled}
					notFoundContent={null}
					allowClear={true}
					showArrow={!!status}
					clearIcon={<CloseCircleFilled />}
					suffixIcon={
						status === 'success' ? (
							<CheckCircleOutlined />
						) : status === 'warning' ? (
							<ExclamationCircleOutlined />
						) : status === 'error' ? (
							<CloseCircleOutlined />
						) : status === 'validating' ? (
							<LoadingOutlined />
						) : undefined
					}
					filterOption={false}
				>
					{selectOptions}

					{!isEditMode &&
						newOption &&
						props.options.filter((option) => option === newOption).length ===
							0 && (
							<Option key={newOption} value={staticNewOption}>
								{translate(domain.createNewOption)} {newOption}
							</Option>
						)}
				</Select>
			</Form.Item>
		);
	},
);
