import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';
import { ERP_NUMBER_REPEATED } from 'utils/constants';

import { selectActiveCustomerError } from '../../../selectors';

export const useEffectErpErrorMessage = (
	erpNumber: string,
	isDirty: boolean,
	setError: Function,
) => {
	const translations = UseFieldTranslations();
	const dispatch = useDispatch();
	const error = useSelector(selectActiveCustomerError);
	const errorDetail = error?.detail;

	useEffect(() => {
		if (isDirty && ERP_NUMBER_REPEATED === errorDetail) {
			setError(erpNumber, {
				type: 'error',
				message: translations[erpNumber].erpNumberRepeatedMessage,
			});
		} else if (isDirty && error) {
			dispatch(
				globalActions.addNotification({
					type: CustomNotificationTypes.ERROR,
					message: translations[erpNumber].defaultErrorMessage,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorDetail]);
};
