import { takeLatest } from 'redux-saga/effects';

import { createEmployee } from './sagas/create-employee-saga';
import { editEmployee } from './sagas/edit-employee-saga';
import {
	loadEmployeesPermissions,
	updateEmployeePermissions,
	updateEmployeePermissionsLocations,
} from './sagas/employee-permissions-saga';
import { getEmployeesList } from './sagas/employees-sagas';
import {
	getLimitByEmployee,
	updateLimitByEmployee,
} from './sagas/limit-by-employee';
import {
	getLocationsByEmployee,
	updateLocationsByEmployee,
} from './sagas/locations-by-employee';
import { getEmployeeById } from './sagas/select-employee-saga';
import { setEmployeeStatus } from './sagas/setEmployeeStatus-saga';
import { actions } from './slice';

export function* employeesSaga() {
	yield takeLatest(actions.loadEmployeesList.type, getEmployeesList);
	yield takeLatest(actions.createEmployee.type, createEmployee);
	yield takeLatest(actions.editEmployee.type, editEmployee);
	yield takeLatest(actions.setEmployeeStatus.type, setEmployeeStatus);
	yield takeLatest(
		actions.loadEmployeesPermissions.type,
		loadEmployeesPermissions,
	);
	yield takeLatest(
		actions.updateEmployeePermissions.type,
		updateEmployeePermissions,
	);
	yield takeLatest(
		actions.updateEmployeePermissionsLocations.type,
		updateEmployeePermissionsLocations,
	);
	yield takeLatest(actions.requestActiveEmployee.type, getEmployeeById);
	yield takeLatest(
		actions.updateEmployeeLocations.type,
		updateLocationsByEmployee,
	);
	yield takeLatest(actions.updateEmployeeLimit.type, updateLimitByEmployee);
	yield takeLatest(actions.getEmployeeLimit.type, getLimitByEmployee);
	yield takeLatest(actions.getEmployeeLocations.type, getLocationsByEmployee);
}
