import './style.scss';

import { Divider, Modal } from 'antd';
import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import React, { memo } from 'react';
import * as Yup from 'yup';

import { FieldDefinition } from 'types/FieldDefinition';
import { ModalSize } from 'types/ModalSize';
import {
	FormSchema,
	formSchemaGenerator,
} from 'utils/GenericFormGenerators/generator';

interface Props<ValuesType, HookParams> {
	title?: string;
	footerButtons?: React.ReactNode;
	formDefinition: FieldDefinition[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO full form type safety
	handleSubmit: (values: any) => void;
	handleClose: () => void;
	hookSchema?: (
		formikProps: FormikProps<ValuesType>,
		hookParams: HookParams,
	) => void;
	hookParams?: HookParams;
	size?: ModalSize;
	scrollable?: boolean;
	labelSpan?: number;
	wrapperColSpan?: number;
	enableReinitialize?: boolean;
}

const DEFAULT_LABEL_SPAN = 3;
const DEFAULT_WRAPPER_COL_SPAN = 9;

const GenericModalFormBase = function GenericModalForm<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- fine in extends
	ValuesType extends Record<string, any>,
	HookParams = undefined,
>(props: Props<ValuesType, HookParams>) {
	const {
		formDefinition,
		title,
		footerButtons,
		handleSubmit,
		handleClose,
		hookSchema,
		hookParams,
		size,
		scrollable = false,
		labelSpan = DEFAULT_LABEL_SPAN,
		wrapperColSpan = DEFAULT_WRAPPER_COL_SPAN,
		enableReinitialize = false,
	} = props;
	const { initialValues, validationSchema, elements }: FormSchema =
		formSchemaGenerator(formDefinition);

	const formatedValidationSchema = Yup.object().shape(validationSchema);

	const labelCol = size === ModalSize.large ? { span: 0 } : { span: labelSpan };
	const wrapperCol =
		size === ModalSize.large ? { span: 12 } : { span: wrapperColSpan };

	return (
		<Formik
			initialValues={initialValues as ValuesType}
			validationSchema={formatedValidationSchema}
			onSubmit={handleSubmit}
			enableReinitialize={enableReinitialize}
		>
			{(formikProps) => {
				!!hookSchema &&
					hookSchema(
						formikProps as FormikProps<ValuesType>,
						hookParams as HookParams,
					);
				return (
					<Modal
						maskClosable={false}
						className={classNames('generic-modal-form', {
							'large-modal': size === ModalSize.large,
							scrollable: scrollable,
						})}
						title={title}
						visible={true}
						footer={null}
						onCancel={handleClose}
					>
						<Divider key="divider" className="form-buttons__divider" />
						<Form labelCol={labelCol} wrapperCol={wrapperCol}>
							{elements}
							{footerButtons}
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
};

export const GenericModalForm = memo(
	GenericModalFormBase,
) as typeof GenericModalFormBase;
