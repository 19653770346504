import { CONTRACT_TYPES } from 'utils/constants';

import { useNTCSchema } from './NTCSchema';

/**
 * @description Bulk Edit is only for open NTC
 * @returns
 */
export const useBulkEditSchema = () => ({
	[CONTRACT_TYPES.ntc]: useNTCSchema(),
});
