import { useFormikContext } from 'formik';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FormInput } from 'app/components/FormInput';
import { FormSwitch } from 'app/components/FormSwitch';
import { useErrorsInLine } from 'app/containers/Settings/definitions/hooks/useErrorsInLine';
import { translations } from 'locales/i18n';

import { selectErrorsInline } from '../../selectors';

interface Props {
	isGlobalTag50?: boolean;
}

export const Tag50Controller = memo(function Tag50Controller(props: Props) {
	const { isGlobalTag50 } = props;
	const errorsInLine = useSelector(selectErrorsInline);

	const { t: translate } = useTranslation();
	const scope =
		translations.app.containers.Settings.sections.Employees.components
			.Tag50Controller;

	const formikProps = useFormikContext();
	const { values }: any = formikProps;

	useErrorsInLine(formikProps, { errors: errorsInLine });

	return (
		<>
			<FormSwitch
				name="tag50toggle"
				label={translate(scope.tag50Label)}
				disabled={isGlobalTag50}
			/>

			{!!values.tag50toggle && (
				<FormInput
					data-testid="form-input-tag50Account"
					key="Tag50Account"
					name="Tag50Account"
					label={`* ${translate(scope.tag50AccountLabel)}`}
					placeholder={translate(scope.tag50AccountPlaceholder)}
				/>
			)}
		</>
	);
});
