import './styles.scss';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React, { useEffect } from 'react';
import { useAbac } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	actions as activateDNHActions,
	reducer as activateDNHReducer,
	sliceKey as activateDNHKey,
} from 'app/containers/ActivateDNHModal/slice';
import { contractsSaga } from 'app/containers/Contracts/root-saga';
import {
	actions as contractsActions,
	reducer as contractsReducer,
	sliceKey as contractsKey,
} from 'app/containers/Contracts/slice';
import { globalSaga } from 'app/containers/GlobalSaga/saga';
import {
	selectTransactionsListLoading,
	selectUserInfo,
} from 'app/containers/GlobalSaga/selectors';
import {
	actions as globalActions,
	reducer as globalReducer,
	sliceKey as globalSliceKey,
} from 'app/containers/GlobalSaga/slice';
import { CreateModal as CreateOfferModal } from 'app/containers/Offers/components/Modals/CreateModal';
import { offersSaga } from 'app/containers/Offers/root-saga';
import {
	actions as offersActions,
	reducer as offersReducer,
	sliceKey as offersKey,
} from 'app/containers/Offers/slice';
import {
	actions as preHedgeActions,
	reducer as preHedgeReducer,
	sliceKey as preHedgeKey,
} from 'app/containers/PreHedge/slice';
import { bypassReviewSaga } from 'app/containers/Settings/sections/BypassReview/sagas';
import { actions as bypassReviewAction } from 'app/containers/Settings/sections/BypassReview/slice';
import {
	reducer as bypassReviewReducer,
	sliceKey as bypassReviewSliceKey,
} from 'app/containers/Settings/sections/BypassReview/slice';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { useFeatureFlags } from 'utils/hooks/useFeatureFlags';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { ActivateDNHModal } from '../ActivateDNHModal';
import { activateDNHModalSaga } from '../ActivateDNHModal/root-saga';
import { selectIsModalOpen } from '../ActivateDNHModal/selectors';
import { CreateModal as CreateContractModal } from '../Contracts/components/Modals/CreateModal';
import { selectCurrentModal as selectCurrentContractModal } from '../Contracts/selectors';
import { ContractsModals } from '../Contracts/types';
import { selectCurrentModal as selectCurrentOfferModal } from '../Offers/selectors';
import { OffersModals } from '../Offers/types';
import { CreateModal as CreatePreHedgeModal } from '../PreHedge/components/Modals/CreateModal';
import { preHedgeSaga } from '../PreHedge/root-saga';
import { selectCurrentModal as selectCurrentPreHedgeModal } from '../PreHedge/selectors';
import { Modal as PreHedgeModals } from '../PreHedge/types';
import { selectTag50EmployeesListData } from '../Settings/sections/BypassReview/selectors';
import { SummaryDashboard } from '../SummaryDashboard';

export const TopBarMenu = () => {
	useInjectReducer({ key: globalSliceKey, reducer: globalReducer });
	useInjectSaga({ key: globalSliceKey, saga: globalSaga });

	useInjectReducer({ key: offersKey, reducer: offersReducer });
	useInjectSaga({ key: offersKey, saga: offersSaga });

	useInjectReducer({ key: contractsKey, reducer: contractsReducer });
	useInjectSaga({ key: contractsKey, saga: contractsSaga });

	useInjectReducer({ key: preHedgeKey, reducer: preHedgeReducer });
	useInjectSaga({ key: preHedgeKey, saga: preHedgeSaga });

	useInjectReducer({ key: activateDNHKey, reducer: activateDNHReducer });
	useInjectSaga({ key: activateDNHKey, saga: activateDNHModalSaga });

	useInjectReducer({ key: bypassReviewSliceKey, reducer: bypassReviewReducer });
	useInjectSaga({ key: bypassReviewSliceKey, saga: bypassReviewSaga });

	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const { userHasPermissions } = useAbac();
	const currentContractModal = useSelector(selectCurrentContractModal);
	const currentOfferModal = useSelector(selectCurrentOfferModal);
	const currentPreHedgeModal = useSelector(selectCurrentPreHedgeModal);
	const isActivateDNHModalOpen = useSelector(selectIsModalOpen);
	const isTransactionsListLoading = useSelector(selectTransactionsListLoading);
	const scopeIntl = translations.app.containers.TopBarMenu;

	const { isShowFutureFirstPermissionEnabled } = useFeatureFlags();
	const tag50employees = useSelector(selectTag50EmployeesListData);

	const userInfo = useSelector(selectUserInfo);
	const isTag50Enabled = tag50employees.some(
		(employee) => employee.email === userInfo?.email,
	);
	/* FIXME: Here goes the new contract modal. Review new GenericForm ModalContainer functionality */
	/*
	 * FIXME: RJO001-684 / Mau : Review if this useEffect is still needed
	 * this actions are needed for transaction options in create modals, if they are not loaded, the modal will be displayed empty
	 */
	useEffect(() => {
		dispatch(globalActions.loadContractTypeList(ContractTypeSource.CONTRACT));
		dispatch(globalActions.loadContractTypeList(ContractTypeSource.OFFER));
		if (userInfo?.id) {
			dispatch(bypassReviewAction.loadTag50EmployeesList());
		}
	}, [dispatch, userInfo]);

	const handleActivateDNH = () => {
		dispatch(activateDNHActions.setIsModalOpen(true));
	};

	const handleCreateContract = () => {
		dispatch(contractsActions.setCurrentModal(ContractsModals.Create));
	};

	const handleCreateOffer = () => {
		dispatch(offersActions.setCurrentModal(OffersModals.Create));
	};

	const handleCreatePreHedge = () => {
		dispatch(preHedgeActions.setCurrentModal(PreHedgeModals.Create));
	};

	const isAllowedToCreateEntry = (permissions: string[]) =>
		permissions.some((permission: string) => userHasPermissions(permission));

	const allowedToCreateContract =
		!userHasPermissions(Permission.CONTRACTSERVICE_CONTRACT_OMS_ONLY) &&
		isAllowedToCreateEntry([
			Permission.CONTRACTSERVICE_CONTRACTBASIS_CREATE,
			Permission.CONTRACTSERVICE_CONTRACTFLATPRICE_CREATE,
			Permission.CONTRACTSERVICE_CONTRACTHTA_CREATE,
			Permission.CONTRACTSERVICE_CONTRACTNTC_CREATE,
			Permission.CONTRACTSERVICE_FUTUREFIRST_VIEW,
		]);

	const allowedToCreateOffer = isAllowedToCreateEntry([
		Permission.CONTRACTSERVICE_OFFERBASIS_CREATE,
		Permission.CONTRACTSERVICE_OFFERFLATPRICE_CREATE,
		Permission.CONTRACTSERVICE_OFFERHTA_CREATE,
	]);

	const allowedToActivateDNH = userHasPermissions(
		Permission.CONTRACTSERVICE_CONTRACT_ACTIVATEDNH,
	);

	const menu = (
		<Menu>
			{allowedToCreateContract && (
				<Menu.Item key="create-contract-button">
					<Button
						onClick={handleCreateContract}
						type="primary"
						loading={isTransactionsListLoading}
					>
						{translate(scopeIntl.createContract)}
					</Button>
				</Menu.Item>
			)}
			{allowedToCreateOffer && (
				<Menu.Item key="create-offer-button">
					<Button
						onClick={handleCreateOffer}
						type="primary"
						loading={isTransactionsListLoading}
					>
						{translate(scopeIntl.createOffer)}
					</Button>
				</Menu.Item>
			)}
			{isShowFutureFirstPermissionEnabled && isTag50Enabled && (
				<Menu.Item key="create-preHedge-button">
					<Button onClick={handleCreatePreHedge} type="primary">
						{translate(scopeIntl.createPreHedge)}
					</Button>
				</Menu.Item>
			)}

			{allowedToActivateDNH && (
				<Menu.Item key="activate-dnh-button">
					<Button onClick={handleActivateDNH} type="primary">
						{translate(scopeIntl.activateDNH)}
					</Button>
				</Menu.Item>
			)}
			<Menu.Item key="summary-dashboard-button">
				<SummaryDashboard />
			</Menu.Item>
		</Menu>
	);

	return (
		<>
			<Menu className="topbar-menu-container narrow-screen">
				<Menu.Item key="create-contract-button">
					<Button
						className="topbar-menu-container__button"
						data-testid="create-contract-button"
						onClick={handleCreateContract}
						type="primary"
						loading={isTransactionsListLoading}
					>
						{translate(scopeIntl.createContract)}
					</Button>
				</Menu.Item>
				<Menu.Item key="create-offer-button">
					<Button
						className="topbar-menu-container__button"
						data-testid="create-offer-button"
						onClick={handleCreateOffer}
						type="primary"
						loading={isTransactionsListLoading}
					>
						{translate(scopeIntl.createOffer)}
					</Button>
				</Menu.Item>
				{isShowFutureFirstPermissionEnabled && isTag50Enabled && (
					<Menu.Item key="create-preHedge-button">
						<Button
							className="topbar-menu-container__button"
							data-testid="create-preHedge-button"
							onClick={handleCreatePreHedge}
							type="primary"
						>
							{translate(scopeIntl.createPreHedge)}
						</Button>
					</Menu.Item>
				)}
				<Menu.Item key="activate-dnh-button" disabled={!allowedToActivateDNH}>
					<Button
						className="topbar-menu-container__button"
						data-testid="activate-dnh-button"
						onClick={handleActivateDNH}
						type="primary"
					>
						{translate(scopeIntl.activateDNH)}
					</Button>
				</Menu.Item>
				<Menu.Item key="summary-dashboard-button">
					<SummaryDashboard />
				</Menu.Item>
			</Menu>
			<Dropdown overlay={menu} className="wide-screen--actions-hub">
				<Button
					className="topbar-menu-container__button"
					type="primary"
					data-testid="create-new-entry-button"
				>
					{translate(scopeIntl.actionsHub)} <DownOutlined />
				</Button>
			</Dropdown>
			{currentContractModal === ContractsModals.Create && (
				<CreateContractModal />
			)}
			{currentOfferModal === OffersModals.Create && <CreateOfferModal />}
			{currentPreHedgeModal === PreHedgeModals.Create && (
				<CreatePreHedgeModal />
			)}
			{isActivateDNHModalOpen && <ActivateDNHModal />}
		</>
	);
};
