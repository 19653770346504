import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';

export const throttledDispatcher = <T>(
	dispatch: Dispatch<AnyAction>,
	actionCreator: (payload: T) => AnyAction,
	wait: number,
) => {
	return throttle((payload: T) => {
		dispatch(actionCreator(payload));
	}, wait);
};
