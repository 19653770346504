import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';

import { useEffectErpErrorMessage } from './hooks/useEffectErpErrorMessage';

const erpNumber = 'erpNumber';

interface Props {
	disabled?: boolean;
}

export const ErpNumber = memo(function ErpNumber({ disabled }: Props) {
	const translations = UseFieldTranslations();
	const {
		formState: { isDirty },
		setError,
	} = useFormContext();

	useEffectErpErrorMessage(erpNumber, isDirty, setError);

	return (
		<GenericForm.FormItem
			data-testid={`${erpNumber}-form-item`}
			label={translations[erpNumber].label}
			name={erpNumber}
		>
			<Input
				disabled={disabled}
				name={erpNumber}
				placeholder={translations[erpNumber].placeholder}
			/>
		</GenericForm.FormItem>
	);
});
