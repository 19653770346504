import './style.scss';

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React, { memo } from 'react';

import { GenericOption } from 'types/GenericOption';

interface Props extends Omit<SelectProps<GenericOption>, 'labelInValue'> {
	options: GenericOption[];
}

export const Dropdown = memo(function Dropdown(props: Props) {
	const { showSearch = true, options, ...otherProps } = props;

	return (
		<Select
			{...otherProps}
			className="dropdown-form__select"
			showSearch={showSearch}
			options={options}
			filterOption={(input, option) => {
				const label = option?.label;
				return (
					typeof label === 'string' &&
					label.toLowerCase().includes(input.toLowerCase())
				);
			}}
			labelInValue
		></Select>
	);
});
