/**
 *
 * BulkActions
 *
 */
import './style.scss';

import { DislikeFilled, LikeFilled, RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectListLoading,
	selectSelectedRows,
} from 'app/containers/ReviewAndRelease/selectors';
import { actions } from 'app/containers/ReviewAndRelease/slice';
import { MenuActionType } from 'app/containers/ReviewAndRelease/types';
import { translations } from 'locales/i18n';

const domain =
	translations.app.containers.ReviewAndRelease.components.Views.List
		.ActionsMenu;

export const BulkActions = memo(function BulkActions() {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const selectedRowsData = useSelector(selectSelectedRows);
	const isLoading = useSelector(selectListLoading);

	const getTranslation = (key: string) => translate(domain[key]);

	const validateAction = (restrictionName: string) =>
		selectedRowsData.length &&
		selectedRowsData.every(({ restrictions }) => restrictions[restrictionName]);

	const canBeApproved = validateAction('canBeApproved');
	const canBeDenied = validateAction('canBeDenied');
	const canBeResent = validateAction('canBeResent');

	const handleApprove = () => {
		dispatch(
			actions.reviewAndReleaseMenuAction({
				data: selectedRowsData,
				type: MenuActionType.approve,
			}),
		);
	};

	const handleDeny = () => {
		dispatch(
			actions.reviewAndReleaseMenuAction({
				data: selectedRowsData,
				type: MenuActionType.deny,
			}),
		);
	};

	// TODO Implement this method when resend order US is defined
	const handleResend = () => {
		dispatch(
			actions.reviewAndReleaseMenuAction({
				data: selectedRowsData,
				type: MenuActionType.resent,
			}),
		);
	};

	return (
		<>
			{selectedRowsData.length !== 0 && (
				<span className="selection-count">
					{`${selectedRowsData.length} 
          ${getTranslation('selected')}`}
				</span>
			)}
			<Button
				data-testid="button-approve"
				disabled={!canBeApproved || isLoading}
				ghost
				onClick={handleApprove}
				type="primary"
			>
				<LikeFilled />
				{getTranslation('approve')}
			</Button>

			<Button
				data-testid="button-deny"
				disabled={!canBeDenied || isLoading}
				ghost
				onClick={handleDeny}
				type="primary"
			>
				{getTranslation('deny')}
				<DislikeFilled />
			</Button>

			<Button
				data-testid="button-resend"
				disabled={!canBeResent || isLoading}
				ghost
				onClick={handleResend}
				type="primary"
			>
				<RedoOutlined />
				{getTranslation('resend')}
			</Button>
		</>
	);
});
