import React, { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Checkbox } from 'app/components/Checkbox';
import { GenericForm } from 'app/components/GenericForm';
import { selectDNHList } from 'app/containers/ActivateDNHModal/selectors';
import { translations } from 'locales/i18n';
import { getCropYearOptionsWithRegion } from 'utils/helpers';

const domain = translations.app.containers.ActivateDNHModal;

export const DoNotHedge = memo(function DoNotHedge() {
	const { t: translate } = useTranslation();
	const { setValue, watch } = useFormContext();
	const commoditiesList = useSelector(selectDNHList);
	const commodity = watch('commodity');
	const region = watch('region');
	const cropYear = watch('cropYear');

	useEffect(() => {
		const options = getCropYearOptionsWithRegion(
			commoditiesList,
			commodity,
			region,
		);
		const currentCropYear = options.find((option) => option.value === cropYear);

		setValue('doNotHedge', currentCropYear?.isDnhActive);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cropYear, region]);

	return (
		<GenericForm.FormItem
			data-testid="doNotHedge-form-item"
			name="doNotHedge"
			wrapperCol={{ offset: 3, span: 4 }}
		>
			<Checkbox key="doNotHedge" label={translate(domain.checkbox)} />
		</GenericForm.FormItem>
	);
});
