import { Button, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { translations } from 'locales/i18n';

import { userRolesActions } from '../../../slice';
import { View } from '../../../types';

export const Headers = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const translationsScope =
		translations.app.containers.Settings.sections.UserRoles;
	const handleCreateUserRole = () => {
		dispatch(userRolesActions.setCurrentView(View.Create));
	};

	return (
		<div className="filters-container">
			<Row className="grid-menu" gutter={16} justify="end">
				<Col>
					<Button
						type="primary"
						size="middle"
						onClick={handleCreateUserRole}
						className="orders-button"
					>
						{translate(
							translationsScope.View.List.Table.Headers.CreateUserRoles,
						)}
					</Button>
				</Col>
			</Row>
		</div>
	);
};
