import { useEffect } from 'react';

import { ErrorInLine } from '../../types';

export const useErrorsInLine = (
	formikProps,
	hookParams: { errors: ErrorInLine[] },
) => {
	const { setFieldError } = formikProps;
	const { errors = [] } = hookParams;

	useEffect(() => {
		errors.forEach((error) => {
			setFieldError(error.Field, error.Message);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors]);
};
