/**
 *
 * Delete User Role
 *
 */
import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { actions as globalActions } from '../../../../../GlobalSaga/slice';
import { selectActiveServiceFees } from '../../selectors';
import { serviceFeesActions } from '../../slice';
import { View } from '../../types';

export const DeleteServiceFee = () => {
	const dispatch = useDispatch();
	const activeServiceFee: any = useSelector(selectActiveServiceFees);
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.ServiceFees;

	const handleCancel = () => {
		dispatch(serviceFeesActions.resetActiveServiceFees());
		dispatch(serviceFeesActions.setCurrentView(View.List));
	};

	const handleConfirmation = () => {
		dispatch(serviceFeesActions.deleteServiceFee(activeServiceFee?.id));
		dispatch(serviceFeesActions.setCurrentView(View.List));
		dispatch(globalActions.loadServiceFeeList());
	};

	return (
		<Modal
			className="orders-cancel-modal"
			footer={[
				<Button
					className="return-button"
					data-testid="return-button"
					key="back"
					onClick={handleCancel}
				>
					{translate(translationsScope.Modals.Delete.CancelButton)}
				</Button>,
				<Button
					className="cancel-button"
					data-testid="confirm-button"
					key="submit"
					onClick={handleConfirmation}
					type="primary"
				>
					{translate(translationsScope.Modals.Delete.DeleteButton)}
				</Button>,
			]}
			maskClosable={false}
			onCancel={handleCancel}
			onOk={handleConfirmation}
			title={translate(translationsScope.Modals.Delete.Title)}
			visible
		>
			{translate(translationsScope.Modals.Delete.BodyMessage)}
		</Modal>
	);
};
