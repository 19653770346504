import { z } from 'zod';

const _locationSchema = z.object({
	id: z.string().uuid(),
	name: z.string(),
	forBuy: z.boolean(),
	forSell: z.boolean(),
});
export interface Location extends z.infer<typeof _locationSchema> {}
export const locationSchema: z.Schema<Location> = _locationSchema;

export interface LocationWithRegionIds extends Location {
	regionIds: string[];
}
