import { call, put } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { userRolesActions } from '../slice';

const { contractSetting } = apiEndpoints;

export function* createEditRoleLimits(action) {
	const requestURL = yield new URL(`${apiRoutes.base}/${contractSetting}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		action.payload,
	);

	if (!!responseData) {
		yield put(userRolesActions.createdEditedRoleLimits(responseData));
	} else if (!!responseError.detail) {
		yield put(userRolesActions.roleLocationError(responseError));
	}
}
