import { Dispatch, SetStateAction } from 'react';

import { Trade } from 'types/TradeBook';

type DataSource = Trade[];

interface UseSelectDeselect {
	handleSelectDeselect: (selectedRowsData: Trade[]) => void;
}

export const useSelectDeselect = (
	dataSource: DataSource,
	initialDataSource: DataSource,
	setDataSource: Dispatch<SetStateAction<DataSource>>,
	shouldPersist: boolean = false,
): UseSelectDeselect => {
	const handleSelectDeselect = (selectedRowsData) => {
		let newDataSource;
		if (shouldPersist) {
			newDataSource = dataSource.map((item) => ({
				...item,
				unmatched: item.filledQuantity - item.matchedQuantity,
				allocation: 0,
			}));
		} else {
			newDataSource = dataSource;
		}

		// Make a copy of the current dataSource
		const updatedDataSource = [...newDataSource];

		selectedRowsData.forEach((item) => {
			// Determine the new allocation value
			const newAllocation =
				item.unmatched > 0 ? item.unmatched : item.filledQuantity;

			// Check if the user has changed the allocation
			const isAllocationChangedByUser =
				item.isUserModified ||
				(item.allocation !== newAllocation && item.allocation !== 0);

			// Create the new data object with the updated allocation and user modification flag
			const newData = {
				...item,
				allocation: isAllocationChangedByUser ? item.allocation : newAllocation,
				isUserModified: isAllocationChangedByUser,
			};

			// Find the index of the item in the copied dataSource
			const index = updatedDataSource.findIndex(
				(record) => record.id === item.id,
			);

			if (index !== -1) {
				// Update the item in the copied dataSource
				updatedDataSource[index] = { ...updatedDataSource[index], ...newData };
			}
		});

		// Iterate through the initialDataSource to check for deselected rows
		initialDataSource.forEach((initialRow) => {
			const isSelected = selectedRowsData.some(
				(selectedRow) => selectedRow.id === initialRow.id,
			);

			if (!isSelected) {
				// If the row is not selected, reset it to the initial state
				const index = updatedDataSource.findIndex(
					(record) => record.id === initialRow.id,
				);

				if (index !== -1) {
					updatedDataSource[index] = { ...initialRow };
				}
			}
		});

		// Now, `updatedDataSource` contains the updated dataSource with deselected rows reset to their initial state.
		setDataSource(updatedDataSource);
	};

	return {
		handleSelectDeselect,
	};
};
