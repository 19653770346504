/**
 *
 * TableBody
 *
 */
import { Empty, Typography } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ExcelExport } from 'app/components/ExcelExport';
import { GenericTable } from 'app/components/GenericTable';
import { translations } from 'locales/i18n';
import { CONSTANTS } from 'utils/constants';
import {
	currencyFormat,
	customFormat,
	dateFormat,
	timeFormat,
} from 'utils/helpers';

import {
	selectBodiesSchema,
	selectCurrentReport,
	selectHasDynamicHeaderLabel,
	selectHasPagination,
	selectPagination,
	selectSectionsSchema,
	selectTableData,
	selectTableDataLoading,
	selectTableDataReportUrl,
	selectTableDataTotal,
} from '../../selectors';
import { genericReportActions } from '../../slice';
import { getCellSchemaStyles, getColumnTitle } from '../../utils/helpers';

const { Paragraph } = Typography;

export const TableBody = memo(function TableBody() {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const bodiesSchema = useSelector(selectBodiesSchema);
	const currentReport = useSelector(selectCurrentReport);
	const excelData = useSelector(selectTableDataReportUrl);
	const hasDynamicHeaderLabel = useSelector(selectHasDynamicHeaderLabel);
	const hasPagination = useSelector(selectHasPagination);
	const pagination = useSelector(selectPagination);
	const sections = useSelector(selectSectionsSchema);
	const tableData = useSelector(selectTableData);
	const tableDataLoading = useSelector(selectTableDataLoading);
	const totalList = useSelector(selectTableDataTotal);

	let headers = [];
	let rowsData: any[] = [];

	const tableHeader = sections?.find((item) => item.type === 'header');

	tableData?.forEach((singleTableBody) => {
		headers = singleTableBody.headers?.[0];

		const mappedData = singleTableBody.list.map((row) => {
			return {
				...row,
				schemaId: singleTableBody.id,
			};
		});

		rowsData = [...rowsData, ...mappedData];
	});

	const getFormatedData = (data, format) => {
		switch (format) {
			case 'bool':
				return data
					? translate(
							translations.app.containers.GenericReport.components.TableBody
								.booleanFormatTrueText,
						)
					: translate(
							translations.app.containers.GenericReport.components.TableBody
								.booleanFormatFalseText,
						);

			case 'decimal':
				return customFormat(data, true, CONSTANTS.FIXED_QUANTITY_DECIMALS);

			case 'currency':
				return (
					data !== null &&
					data !== undefined &&
					typeof data === 'number' &&
					currencyFormat(
						data,
						CONSTANTS.FIXED_DECIMALS,
						CONSTANTS.FIXED_DECIMALS,
					)
				);

			case 'datetime':
				return (
					data &&
					typeof data === 'string' && (
						<div>
							<label className="text text--bold">{dateFormat(data)}</label>
							<label className="text text--label">{timeFormat(data)}</label>
						</div>
					)
				);

			case 'date':
				return data && moment(data).isValid() ? (
					<label className="text text--label">{dateFormat(data)}</label>
				) : (
					data
				);

			case 'longtext':
				return (
					<Paragraph
						className="text ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						{data}
					</Paragraph>
				);

			default:
				return data;
		}
	};

	const columns = tableHeader?.columns.map((singleColumn, index) => {
		const renderCell = (data, childColumn?) => {
			const tableSchema = bodiesSchema.find((schema) => {
				return schema.id === data.schemaId;
			});
			const cellSchema = tableSchema.columns.find((columnSchema) => {
				if (childColumn) {
					return columnSchema.id === childColumn.id;
				} else {
					return columnSchema.id === singleColumn.id;
				}
			});

			if (!cellSchema) {
				return null;
			}

			return (
				<div
					className={classNames('wrapper-column', {
						borderless:
							(data['HrvstEdgeIsSubTotal'] || data['HrvstEdgeIsTotal']) &&
							data[cellSchema.property],
					})}
					style={getCellSchemaStyles(cellSchema.css)}
					data-testid={`cell-${index}`}
				>
					<div className="order__column">
						{data[cellSchema.property] !== null &&
							getFormatedData(data[cellSchema.property], cellSchema.format)}
					</div>
				</div>
			);
		};

		const children = singleColumn.children?.map((childColumn) => {
			return {
				title: (
					<div className={`${childColumn.cssClass}`}>{childColumn.label}</div>
				),
				render: (data) => renderCell(data, childColumn),
			};
		});

		return {
			title: getColumnTitle(hasDynamicHeaderLabel, headers, singleColumn.label),
			fixed: singleColumn.fixed,
			children,
			render: (data) => renderCell(data),
		};
	});

	const onPaginationChange = (page, pageSize) => {
		dispatch(
			genericReportActions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(genericReportActions.loadTableData());
	};

	return rowsData.length ? (
		currentReport && (
			<>
				<ExcelExport url={excelData} />
				<GenericTable.Table
					columns={columns}
					data={rowsData}
					loading={tableDataLoading}
					pagination={
						hasPagination && {
							total: totalList,
							pageSize: pagination.limit,
							current: pagination.start,
							onChange: onPaginationChange,
						}
					}
					otherClassName={`table-container ${currentReport.key.toLowerCase()}`}
					onRow={(record) => {
						return {
							className: classNames({
								subtotal: record.HrvstEdgeIsSubTotal,
								total: record.HrvstEdgeIsTotal,
							}),
						};
					}}
				/>
			</>
		)
	) : (
		<Empty />
	);
});
