import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { erpIntegrationSaga } from '../saga';
import {
	selectERPIntegration,
	selectERPIntegrationLoading,
	selectERPTypesLoading,
	selectHedgeIntegration,
	selectHedgeIntegrationLoading,
} from '../selectors';
import { actions, reducer, sliceKey } from '../slice';
import { SWITCH_FORM_ITEMS } from '../types';

export const useERPIntegrationData = () => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: erpIntegrationSaga });

	const dispatch = useDispatch();
	const erpIntegration = useSelector(selectERPIntegration);
	const hedgeIntegration = useSelector(selectHedgeIntegration);
	const isERPIntegrationActive =
		erpIntegration?.[SWITCH_FORM_ITEMS.ERPIntegration];
	const isHedgeIntegrationActive =
		hedgeIntegration?.[SWITCH_FORM_ITEMS.HedgeIntegration];
	const isLoadingERPIntegration = useSelector(selectERPIntegrationLoading);
	const isLoadingHedgeIntegration = useSelector(selectHedgeIntegrationLoading);
	const isLoadingERPTypes = useSelector(selectERPTypesLoading);

	useEffect(() => {
		dispatch(actions.getERPIntegrationRequest());
		dispatch(actions.getHedgeIntegrationRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isERPIntegrationActive) {
			dispatch(actions.getERPTypesRequest());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isERPIntegrationActive]);

	return {
		isERPIntegrationActive,
		isHedgeIntegrationActive,
		isLoadingHedgeIntegration,
		isLoading: isLoadingERPIntegration || isLoadingERPTypes,
	};
};
