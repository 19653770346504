import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectOrderEntry } from 'app/containers/GlobalSaga/selectors';
import { OrderEntry } from 'types/OrderEntry';
import { getNumberArray } from 'utils/helpers';

import { ORDER_ENTRY_FORM_ITEMS } from '../../../types';
import {
	getFieldTypes,
	getMultiSelectInputsIdList,
	getMultiSelectOptions,
} from '../utils/helpers';

export const useOrderEntryRowData = (
	index: number,
	orderEntry: OrderEntry | undefined,
) => {
	const { t: translate } = useTranslation();
	const { clearErrors, watch } = useFormContext();
	const orderEntries = useSelector(selectOrderEntry);
	const isCreatingOrderEntries = orderEntries?.length === 0;
	const isActive = isCreatingOrderEntries || !!orderEntry?.isActive;

	const [disabledItems, setDisabledItems] = useState(!isActive);
	const [fieldType, setFieldType] = useState(orderEntry?.type);
	const [multiSelectLength, setMultiSelectLength] = useState(
		orderEntry?.items?.length,
	);
	const [multiSelectInputs, setMultiSelectInputs] = useState(
		getNumberArray(multiSelectLength),
	);

	const values = watch();
	const orderEntryId =
		orderEntry?.id || `${ORDER_ENTRY_FORM_ITEMS.ORDER_ENTRY_DEFAULT}-${index}`;

	const labelId = `${ORDER_ENTRY_FORM_ITEMS.LABEL}-${orderEntryId}`;
	const statusId = `${ORDER_ENTRY_FORM_ITEMS.STATUS}-${orderEntryId}`;
	const typeId = `${ORDER_ENTRY_FORM_ITEMS.TYPE}-${orderEntryId}`;
	const multiSelectLengthId = `${ORDER_ENTRY_FORM_ITEMS.MULTI_SELECT_LENGTH}-${orderEntryId}`;
	const multiSelectInputsIdList = getMultiSelectInputsIdList(orderEntryId);
	const currentMultiSelectLenth = values[multiSelectLengthId];
	const currentStatus = values[statusId];

	useEffect(() => {
		if (!!!currentStatus) {
			clearErrors([labelId, multiSelectLengthId, typeId]);
			clearErrors(multiSelectInputsIdList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStatus]);

	useEffect(() => {
		setMultiSelectLength(currentMultiSelectLenth?.value);
	}, [currentMultiSelectLenth]);

	useEffect(() => {
		setMultiSelectInputs(getNumberArray(multiSelectLength));
	}, [multiSelectLength]);

	return {
		disabledItems,
		fieldType,
		fieldTypes: getFieldTypes(translate),
		isActive,
		multiSelectInputs,
		multiSelectOptions: getMultiSelectOptions(),
		orderEntryId,
		setDisabledItems,
		setFieldType,
		setMultiSelectLength,
		translate,
	};
};
