import { takeLatest } from 'redux-saga/effects';

import { deleteServiceFee } from './sagas/delete-service-fee-saga';
import {
	createServiceFee,
	getServiceFeesList,
	loadEditServiceFee,
	setServiceFeeMonths,
	setServiceFeeStatus,
} from './sagas/service-fee-sagas';
import { serviceFeesActions } from './slice';

export function* serviceFeesSagas() {
	yield takeLatest(
		serviceFeesActions.loadServiceFeesList.type,
		getServiceFeesList,
	);
	yield takeLatest(serviceFeesActions.deleteServiceFee.type, deleteServiceFee);

	yield takeLatest(
		serviceFeesActions.setServiceFeeStatus.type,
		setServiceFeeStatus,
	);
	yield takeLatest(serviceFeesActions.createServiceFee.type, createServiceFee);
	yield takeLatest(
		serviceFeesActions.submiServiceFeeMonths.type,
		setServiceFeeMonths,
	);

	yield takeLatest(
		serviceFeesActions.loadEditServiceFee.type,
		loadEditServiceFee,
	);
}
