import './style.scss';

import { SwapRightOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	format?: string;
	getDisabledDateRange?: () => { min: Moment; max: Moment } | null;
	startDatePlaceholder?: string;
	endDatePlaceholder?: string;
	disabled?: boolean;
}

export const RangeDatePicker = memo(function RangeDatePicker(props: Props) {
	const {
		name,
		format,
		getDisabledDateRange,
		startDatePlaceholder,
		endDatePlaceholder,
		disabled = false,
	} = props;

	const { watch, setValue } = useFormContext();
	const meta = watch(name);

	const [startDateValue, setStartDateValue] = useState<Moment | null>();
	const [endDateValue, setEndDateValue] = useState<Moment | null>();

	const [pickerStartDate, setPickerStartDate] = useState<Moment | null>();
	const [pickerEndDate, setPickerEndDate] = useState<Moment | null>();

	const [startPickerKey, setStartPickerKey] = useState(0);
	const [endPickerKey, setEndPickerKey] = useState(0);

	const [startOpen, setStartOpen] = useState(false);
	const [endOpen, setEndOpen] = useState(false);

	useEffect(() => {
		if (!!meta) {
			setStartDateValue(meta?.[0]);
			setEndDateValue(meta?.[1]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!!getDisabledDateRange) {
			const disabledDates = getDisabledDateRange && getDisabledDateRange();
			if (!!disabledDates) {
				setPickerStartDate(
					disabledDates.min > moment() ? disabledDates.min : moment(),
				);
			}
			if (!!meta) {
				setPickerStartDate(meta?.[0]);
				setPickerEndDate(meta?.[1]);
			} else if (startDateValue && endDateValue) {
				setStartPickerKey(startPickerKey + 1);
				setStartDateValue(null);
				setEndDateValue(null);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getDisabledDateRange]);

	const onStartChange = (value: Moment | null) => {
		const endDate = endDateValue;
		setStartDateValue(value);

		if (!!!endDateValue || (!!endDate && value != null && value > endDate)) {
			setPickerEndDate(value);
			setEndPickerKey(endPickerKey + 1);
			setEndOpen(true);
			setEndDateValue(null);
			setValue(name, null);
			setStartDateValue(value);
		} else {
			setValue(name, [value, endDateValue], { shouldValidate: true });
		}
	};

	const onEndChange = (value: Moment | null) => {
		setEndDateValue(value);
		if (value && !!startDateValue) {
			setValue(name, [startDateValue, value], { shouldValidate: true });
		} else {
			setStartPickerKey(startPickerKey + 1);
			setStartDateValue(null);
			setValue(name, null);
		}

		if (value && !!!startDateValue) {
			setStartOpen(true);
		}
	};

	const toggleEndOpen = () => {
		if (!startDateValue) {
			setStartOpen(true);
		} else {
			setEndOpen(!endOpen);
		}
	};

	const toggleStartOpen = () => {
		setStartOpen(!startOpen);
	};

	return (
		<div
			className={classNames('range-date-picker', {
				'range-date-picker--error': meta?.error && meta?.touched,
				'range-date-picker--disabled': disabled,
			})}
		>
			<DatePicker
				allowClear={false}
				bordered={false}
				className="range-date-picker__start"
				defaultPickerValue={pickerStartDate ?? undefined}
				disabled={disabled || !getDisabledDateRange}
				format={format}
				open={startOpen}
				onOpenChange={toggleStartOpen}
				onChange={onStartChange}
				placeholder={startDatePlaceholder}
				showToday={false}
				suffixIcon={<SwapRightOutlined />}
				value={startDateValue}
				key={`startKey-${startPickerKey}`}
			/>

			<DatePicker
				bordered={false}
				className="range-date-picker__end"
				defaultPickerValue={pickerEndDate ?? undefined}
				disabled={disabled || !getDisabledDateRange}
				format={format}
				onChange={onEndChange}
				onOpenChange={toggleEndOpen}
				open={endOpen}
				placeholder={endDatePlaceholder}
				showToday={false}
				key={`endKey-${endPickerKey}`}
				value={endDateValue}
			/>
		</div>
	);
});
