import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useFormikToState = (
	{ values, setFieldValue, setFieldTouched },
	{ noBids, availableBids },
) => {
	const dispatch = useDispatch();
	const noBidsFormik = values[noBids.fieldName];
	const availableBidsFormik = values[availableBids.fieldName];

	useEffect(() => {
		if (!!noBidsFormik && !!noBidsFormik.value) {
			setFieldValue(availableBids.fieldName, null);
			setFieldTouched(availableBids.fieldName, false, false);
			dispatch(noBids.action(noBidsFormik.value));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [noBidsFormik]);

	useEffect(() => {
		if (!!availableBidsFormik && !!availableBidsFormik.value)
			dispatch(availableBids.action(availableBidsFormik.value));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableBidsFormik]);
};
