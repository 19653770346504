import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { actions as hubActions } from 'app/containers/HubSaga/slice';
import { EditModal } from 'app/containers/Offers/components/Modals/EditModal';
import { selectActiveOffer } from 'app/containers/Offers/selectors';
import { bidsheetSaga } from 'app/containers/Settings/sections/Bidsheet/sagas';
import {
	reducer as BidsheetReducer,
	sliceKey as BidsheetSliceKey,
} from 'app/containers/Settings/sections/Bidsheet/slice';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { RollOrphan } from '../Settings/sections/Bidsheet/Modals/RollOrphan';
import { Modal as BidsheetModals } from '../Settings/sections/Bidsheet/types';
import { BookModal } from './components/Modals/BookModal';
import { BookMultipleModal } from './components/Modals/BookMultipleModal';
import { CancelModal } from './components/Modals/CancelModal';
import { InformCustomerModal } from './components/Modals/InformCustomerModal';
import { Details } from './components/Views/Details';
import { List } from './components/Views/List';
import { offersSaga } from './root-saga';
import { selectCurrentModal, selectCurrentView } from './selectors';
import { actions, reducer, sliceKey } from './slice';
import { OffersModals, OffersViews } from './types';

export const Offers = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: offersSaga });
	useInjectReducer({ key: BidsheetSliceKey, reducer: BidsheetReducer });
	useInjectSaga({ key: BidsheetSliceKey, saga: bidsheetSaga });

	const currentView = useSelector(selectCurrentView);
	const currentModal = useSelector(selectCurrentModal);
	const offerData = useSelector(selectActiveOffer);

	const dispatch = useDispatch();

	const { id: offerId } = useParams<{ id: string }>();

	useEffect(() => {
		if (offerId) {
			dispatch(actions.loadOfferSummary(offerId));
		} else {
			dispatch(actions.setCurrentView(OffersViews.Table));
		}
	}, [offerId]);

	useEffect(() => {
		// Enable auto-refresh for offers
		dispatch(hubActions.subscribeToOfferUpdates());

		return () => {
			dispatch(hubActions.unsubscribeFromOfferUpdates());
		};
	}, []);

	const handleRollOrphanClose = () => {
		dispatch(actions.setCurrentModal(null));
		dispatch(actions.loadOffersList(true));
	};

	return (
		<>
			{currentView === OffersViews.Details ? <Details /> : <List />}
			{currentModal === OffersModals.Edit && offerData && <EditModal />}
			{currentModal === OffersModals.Book && <BookModal />}
			{currentModal === OffersModals.Cancel && <CancelModal />}
			{currentModal === OffersModals.InformCustomer && <InformCustomerModal />}
			{currentModal === BidsheetModals.RollOrphan && (
				<RollOrphan handleClose={handleRollOrphanClose} />
			)}
			{currentModal === OffersModals.MultipleBook && <BookMultipleModal />}
		</>
	);
};
