import { call, put, takeLatest } from 'redux-saga/effects';

import { actions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';
import { PayloadAction } from 'utils/test-utils';

import { defaultPageActions } from './slice';

const { defaultPageSettings } = apiEndpoints;

/**
 * @internal exported for automated tests
 */
export function* updateDefaultPageData(action: PayloadAction<any>) {
	const { data, successMessage } = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${defaultPageSettings}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	// Success actions
	if (!!responseData) {
		yield put(
			actions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);
		yield put(defaultPageActions.updateDefaultPageSuccess(responseData));
		yield put(actions.loadDefaultPage());
	} else if (!!responseError.detail) {
		yield put(defaultPageActions.updateDefaultPageError(responseError));
	}
}

export function* defaultPageSaga() {
	yield takeLatest(
		defaultPageActions.updateDefaultPage.type,
		updateDefaultPageData,
	);
}
