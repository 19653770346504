import './styles.scss';

import React from 'react';

import { GenericForm } from 'app/components/GenericForm';

import { useHedgeIntegrationSwitchFormData } from '../../hooks/useHedgeIntegrationSwitchFormData';

const className = 'erp-integration-switch-form';

export const HedgeIntegrateSwitchForm = () => {
	const { elements, formInstance, validationSchema } =
		useHedgeIntegrationSwitchFormData();

	return (
		<GenericForm.Form
			className={className}
			data-testid={className}
			formInstance={formInstance}
			key="hedgeIntegrationSwitchForm"
			validationSchema={validationSchema}
		>
			{elements}
		</GenericForm.Form>
	);
};
