import React from 'react';
import { useTranslation } from 'react-i18next';

import Yup from 'yupCustom';

import { domain } from '..';
import { Commodity } from '../components/Commodity';
import { CropYears } from '../components/CropYears';
import { DoNotHedge } from '../components/DoNotHedge';
import { Region } from '../components/Region';

export const useActivateDNHSchema = () => {
	const { t: translate } = useTranslation();
	const requiredMsg = translate(domain.requiredMessage);

	return {
		initialValues: {
			commodity: { value: null },
			region: { value: null },
			cropYear: null,
			doNotHedge: false,
		},
		validationSchema: Yup.object().shape({
			commodity: Yup.object().requiredOption(requiredMsg),
			region: Yup.object().requiredOption(requiredMsg),
			cropYear: Yup.number(),
			doNotHedge: Yup.boolean(),
		}),
		elements: [<Commodity />, <Region />, <CropYears />, <DoNotHedge />],
	};
};
