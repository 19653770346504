import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectTransactionsList } from 'app/containers/GlobalSaga/selectors';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { TransactionItem } from 'types/TransactionItem';
import { mapToLabelValue } from 'utils/helpers';

enum TRANSANCTIONS_SOURCE {
	Contracts = 'Contract',
	Offers = 'Offer',
}
export const useTransactionsBySource = (source: GlobalSagaSource) => {
	const allTransactions = useSelector(selectTransactionsList);

	let transactionSource;

	if (source === GlobalSagaSource.contractModal) {
		transactionSource = TRANSANCTIONS_SOURCE.Contracts;
	} else {
		transactionSource = TRANSANCTIONS_SOURCE.Offers;
	}

	const [transactions, setTransactions] = useState<TransactionItem[]>([]);

	useEffect(() => {
		const filteredLocations = allTransactions?.filter(
			(transaction) => transaction.type === transactionSource,
		);

		setTransactions(filteredLocations || []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [source, allTransactions]);

	return mapToLabelValue(transactions);
};
