import { History } from 'history';
import React from 'react';
import { useAbac } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
	AnalyticsIcon,
	HedgeSummaryIcon,
	LiveLedgerIcon,
	MarketsIcon,
	NoteAddIcon,
	OriginatorIcon,
	SettingsIcon,
} from 'app/components/CustomIcon';
import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { TransactionsTabs } from 'app/containers/Transactions/types';
import { ROUTES } from 'app/routes';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import { FeatureFlag } from 'types/FeatureFlags';

interface SideBarItems {
	label: string;
	route: string;
	icon?: React.ReactNode;
	className?: string;
	handleClick: (history: History<unknown>) => void;
	testId: string;
}

export const useBuildSideBarItems = () => {
	const { userHasPermissions } = useAbac();
	const featureFlags = useSelector(selectFeatureFlags);
	const { t: translate } = useTranslation();

	const sideBarItems: Array<SideBarItems> = [
		{
			label: translate(
				translations.app.components.AppLayout.contractManagement,
			),
			route: ROUTES.TRANSACTIONS,
			icon: <NoteAddIcon />,
			handleClick: (history) => {
				history.push(`${ROUTES.TRANSACTIONS}/${TransactionsTabs.contracts}`);
			},
			testId: 'top-menu-item-contract-management',
		},
		{
			label: translate(translations.app.components.AppLayout.reports),
			route: ROUTES.REPORTS,
			icon: <AnalyticsIcon />,
			handleClick: (history) => {
				history.push(ROUTES.REPORTS);
			},
			testId: 'top-menu-item-reports',
		},
	];

	if (userHasPermissions(Permission.CONTRACTSERVICE_LIVELEDGER_VIEW)) {
		sideBarItems.push({
			label: translate(translations.app.components.AppLayout.liveLedger),
			route: ROUTES.LIVELEDGER,
			icon: <LiveLedgerIcon />,
			handleClick: (history) => {
				history.push(ROUTES.LIVELEDGER);
			},
			testId: 'top-menu-item-live-ledger',
		});
	}

	if (
		userHasPermissions(Permission.CONTRACTSERVICE_ORIGINATOR_BIDS_QUOTES_VIEW)
	) {
		sideBarItems.push({
			label: translate(translations.app.components.AppLayout.cashBidsAndQuotes),
			route: ROUTES.CASHBIDS_QUOTES,
			icon: <OriginatorIcon />,
			handleClick: (history) => {
				history.push(ROUTES.CASHBIDS_QUOTES);
			},
			testId: 'top-menu-item-cash-bids-quotes',
		});
	}
	if (
		(userHasPermissions(Permission.CONTRACTSERVICE_FUTURESERP_VIEW) ||
			userHasPermissions(Permission.CONTRACTSERVICE_TRADEBOOK_VIEW)) &&
		featureFlags[FeatureFlag.enableHedgeIntegration]
	) {
		sideBarItems.push({
			label: translate(translations.app.components.AppLayout.hedgeSummary),
			route: ROUTES.HEDGE_SUMMARY,
			icon: <HedgeSummaryIcon />,
			handleClick: (history) => {
				history.push(ROUTES.HEDGE_SUMMARY);
			},
			testId: 'top-menu-item-hedge-summary',
		});
	}

	if (featureFlags[FeatureFlag.enableMarketsTab]) {
		sideBarItems.push({
			label: translate(translations.app.components.AppLayout.markets),
			route: ROUTES.MARKETS,
			icon: <MarketsIcon />,
			handleClick: (history) => history.push(ROUTES.MARKETS),
			testId: 'top-menu-item-markets',
		});
	}

	sideBarItems.push({
		label: translate(translations.app.components.AppLayout.settings),
		route: ROUTES.SETTINGS,
		icon: <SettingsIcon />,
		className: 'settings-menu-item',
		handleClick: (history) => {
			history.push(ROUTES.SETTINGS);
		},
		testId: 'top-menu-item-settings',
	});

	return sideBarItems;
};
