import './style.scss';

import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { RadioButtons } from 'app/components/RadiosButtons';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { translations } from 'locales/i18n';
import { CONSTANTS, MAX_VALUE, MIN_VALUE } from 'utils/constants';
import { customFormat, decimalFormat } from 'utils/helpers';

interface Props {
	disabled?: boolean;
	priceViaEFP?: boolean;
}

export const EFPBushels = memo(function EFPBushels(props: Props) {
	const { t: translate } = useTranslation();

	const { disabled, priceViaEFP = false } = props;
	const msg = translations.app.containers.Contracts.components.EFPBushels;

	const hints = [
		`*${translate(msg.EFPBushelsMaxMultipleOption)}`,
		`*${translate(msg.EFPBushelsMaxSingleOption)}`,
	];

	const activeContractData = useSelector(selectActiveContract);

	const { watch } = useFormContext();
	const values = watch();

	const quantityToPriceValue = values?.qtyPriceAmount
		? parseFloat(
				customFormat(
					values.qtyPriceAmount,
					false,
					CONSTANTS.FIXED_QUANTITY_DECIMALS,
				),
			)
		: 0;
	const lotFactor = activeContractData?.lotFactor;

	let minLabel = translate(msg.EFPBushelsMin);
	let maxLabel = translate(msg.EFPBushelsMax);
	let maxDisabled = false;
	// if quantity has value, calculate actual values for min/max and use that as label
	if (quantityToPriceValue != null && quantityToPriceValue > 0 && lotFactor) {
		if (quantityToPriceValue <= lotFactor) {
			minLabel = decimalFormat(lotFactor.toString());
			maxDisabled = true;
		} else if (quantityToPriceValue % lotFactor === 0) {
			minLabel = decimalFormat(quantityToPriceValue.toString());
			maxDisabled = true;
		} else if (quantityToPriceValue % lotFactor !== 0) {
			minLabel = decimalFormat(
				(Math.floor(quantityToPriceValue / lotFactor) * lotFactor).toString(),
			);

			maxLabel = decimalFormat(
				(Math.ceil(quantityToPriceValue / lotFactor) * lotFactor).toString(),
			);
			maxDisabled = false;
		}
	}

	const options = [
		{ label: minLabel, value: MIN_VALUE },
		{ label: maxLabel, value: MAX_VALUE, disabled: maxDisabled },
	];

	const message =
		hints &&
		(maxDisabled ? (
			<div className="efp-bushels__hint">{hints[1]}</div>
		) : (
			<div className="efp-bushels__hint">{hints[0]}</div>
		));

	if (!watch('priceViaEFPCheckBox') && priceViaEFP) return null;
	return (
		<GenericForm.FormItem
			label={translate(msg.EFPBushelsLabel)}
			name="efpBushels"
			data-testid="efpBushels-form-item"
		>
			<RadioButtons
				key="efpBushels"
				options={options}
				disabled={disabled}
				message={message}
				disabledMode="multi"
				optionType="default"
				customClass="efp-bushels__radios"
			/>
		</GenericForm.FormItem>
	);
});
