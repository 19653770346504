import React from 'react';
import { useSelector } from 'react-redux';

import { AppliedUnappliedBushel } from 'app/containers/Contracts/components/Business/AppliedUnappliedBushel';
import { QuantityToPrice } from 'app/containers/Contracts/components/Business/QuantityToPrice';
import { useTranslations } from 'app/containers/Contracts/components/Modals/shared/useTranslations';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractNumber } from 'app/containers/Transactions/components/ContractNumber';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { FlatPrice } from 'app/containers/Transactions/components/FlatPrice';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { PassFill } from 'app/containers/Transactions/components/PassFill';
import { CONSTANTS } from 'utils/constants';
import {
	customFormat,
	dateFormatYearMonthDay,
	dateMoment,
	getDeliveryDatesMode,
	getDeliveryDateWindow,
	getExpirationDate,
	getFormattedExpirationDate,
	isDeliveryDateCustom,
	isDeliveryDateWindow,
	validatePriceFormat,
	validateQuarterCents,
} from 'utils/helpers';
import Yup from 'yupCustom';

import {
	validateMinMaxValue,
	validatePositiveValue,
	validatePrice,
} from '../../../shared/helpers';

export const useBasisSchema = () => {
	const translations = useTranslations();

	let orderData = useSelector(selectActiveContract) as any;
	if (!!!orderData) return null;

	const requiredMsg = translations.validations.required;

	return {
		initialValues: {
			contractNumber: orderData.number,
			contract: {
				label: orderData.contractTypeName,
				value: orderData.contractTypeId,
			},
			commodity: {
				label: orderData.commodityName,
				value: orderData.commodityId,
			},
			location: {
				label: orderData.locationName,
				value: orderData.locationId,
			},
			deliveryLocation: {
				label: orderData.deliveryLocationName,
				value: orderData.deliveryLocationId,
			},
			assignedRegion: {
				label: orderData?.regionName,
				value: orderData?.regionId,
			},
			cropYear: orderData.cropYear,
			deliveryDate: [
				dateMoment(orderData.deliveryStartDate),
				dateMoment(orderData.deliveryEndDate),
			],
			deliveryDatesMode: getDeliveryDatesMode(orderData),
			deliveryDateWindow: getDeliveryDateWindow(orderData),
			futuresMonth: {
				label: orderData.futuresMonth,
				value: orderData.futuresMonth,
			},
			futuresPrice: null,
			futures: null,
			qtyPriceBalance: `${customFormat(orderData.remainingBalance, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`,
			qtyPriceAmount: null,
			passFill: false,
			postedBasis: validatePriceFormat(orderData.postedBasis),
			pushBasis: validatePriceFormat(orderData.pushBasis),
			netBasis: validatePriceFormat(orderData.netBasis),
			freight: validatePriceFormat(orderData.freightPrice),
			fees1: validatePriceFormat(orderData.fees1),
			fees2: validatePriceFormat(orderData.fees2),
			flatPrice: orderData.flatPrice,
			employee: {
				label: orderData.employeeName,
				value: orderData.employeeId,
			},
			comments: orderData.comments,
			expirationDate: getExpirationDate(orderData),
			isUnappliedLoad: false,
		},
		validationSchema: Yup.object().shape({
			location: Yup.object().requiredOption(requiredMsg),
			deliveryLocation: Yup.object().requiredOption(requiredMsg),
			assignedRegion: Yup.object().requiredOption(requiredMsg),
			deliveryDate: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateCustom(mode),
				then: Yup.mixed().required(requiredMsg),
			}),
			deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateWindow(mode),
				then: Yup.object().requiredOption(requiredMsg),
			}),
			flatPrice: Yup.number()
				.typeError(translations.validations.number)
				.required(translations.validations.required),
			qtyPriceAmount: Yup.string()
				.required(translations.validations.required)
				.test(
					'minMaxValidation',
					translations.validations.rangeValue(
						customFormat(
							orderData.remainingBalance,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					),
					(value: string) =>
						validateMinMaxValue(orderData.remainingBalance, value),
				)
				.nullable(),
			futuresPrice: Yup.number()
				.typeError(translations.validations.required)
				.required(translations.validations.required)
				.test(
					'priceValidation',
					translations.validations.futuresPriceFormatError,
					(value: string) => validatePrice(value),
				)
				.test(
					'nonNegativeValues',
					translations.validations.nonNegativeMsg,
					(value: string) => validatePositiveValue(value),
				)
				.test(
					'quarterCentsValidation',
					translations.validations.futuresPriceQuarterMsg,
					(val) => validateQuarterCents(val),
				),
			employee: Yup.object().requiredOption(requiredMsg),
			freight: Yup.number().typeError(translations.validations.number),
			fees1: Yup.number().typeError(translations.validations.number),
			fees2: Yup.number().typeError(translations.validations.number),
			expirationDate: Yup.string().nullable(),
		}),
		elements: [
			<Commodity hidden />, // hidden fields work as inner references for business rules
			<ContractType hidden />,
			<ContractNumber disabled />,
			<Location />,
			<DeliveryLocation />,
			<AssignedRegion />,
			<CropYears disabled />,
			<DeliveryDates
				modeSelectorDisabled
				filterByFutureMonth={orderData.futuresMonth}
			/>,
			<QuantityToPrice disabled={{ qtyPriceBalance: true }} />,
			<AppliedUnappliedBushel />,
			<Futures
				usePriceFromCache
				useRoundingRules
				futuresMonth={{ market: true, disabled: true }}
				futuresPrice={{ market: true }}
			/>,
			<PassFill hedgeable />,
			<Basis
				disabled={{ netBasis: true, pushBasis: true, postedBasis: true }}
			/>,
			<ExpirationDate />,
			<Freight />,
			<Fees />,
			<FlatPrice disabled />,
			<Employee />,
			<Comments />,
		],
		getSubmitValues: (values) => {
			let submitValues = {
				locationId: values.location?.value,
				deliveryLocationId: values.deliveryLocation?.value,
				deliveryStartDate: dateFormatYearMonthDay(values.deliveryDate?.[0]),
				deliveryEndDate: dateFormatYearMonthDay(values.deliveryDate?.[1]),
				futuresMonth: values.futuresMonth?.value,
				futuresPrice: parseFloat(values.futuresPrice) || 0,
				quantity: parseFloat(
					customFormat(
						values.qtyPriceAmount,
						false,
						CONSTANTS.FIXED_QUANTITY_DECIMALS,
					),
				),
				employeeId: values.employee?.value,
				comments: values.comments,
				isDeliveryDatesCustom: isDeliveryDateCustom(values.deliveryDatesMode),
				passTheFill: values.passFill,
				cropYear: values.cropYear,
				postedBasis: parseFloat(values.postedBasis),
				pushBasis: parseFloat(values.pushBasis),
				expirationDate: getFormattedExpirationDate(values),
				isAppliedLoad: !values?.isUnappliedLoad ?? true,
				regionId: values?.assignedRegion?.value,
			} as {};

			if (!isNaN(values.freight)) {
				submitValues = {
					...submitValues,
					freightPrice: parseFloat(values.freight),
				};
			}

			if (!isNaN(values.fees1)) {
				submitValues = {
					...submitValues,
					fees1: parseFloat(values.fees1),
				};
			}

			if (!isNaN(values.fees2)) {
				submitValues = {
					...submitValues,
					fees2: parseFloat(values.fees2),
				};
			}

			return submitValues;
		},
	};
};
