import './style.scss';

import React from 'react';
import { useAbac } from 'react-abac';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { TabsWrapper } from 'app/components/TabsWrapper';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';

import { FuturesErp } from '../FuturesErp';
import { TradeBook } from '../TradeBook';
import { HedgeSummaryTab, HedgeSummaryTabs as HedgeSummaryEnum } from './types';

export function HedgeSummary() {
	const { t: translate } = useTranslation();
	const { userHasPermissions } = useAbac();

	const translationsScope = translations.app.containers.HedgeSummary;

	const HedgeSummaryTabs: HedgeSummaryTab[] = [
		{
			name: HedgeSummaryEnum.futuresErp,
			content: <FuturesErp />,
		},
	];

	if (userHasPermissions(Permission.CONTRACTSERVICE_TRADEBOOK_VIEW)) {
		HedgeSummaryTabs.push({
			name: HedgeSummaryEnum.tradeBook,
			content: <TradeBook />,
		});
	}

	const tabsTranslations = {
		futuresErp: translate(translationsScope.futureErpTab),
		tradeBook: translate(translationsScope.tradeBookTab),
	};

	return (
		<>
			<Helmet>
				<title>{translate(translationsScope.pageTitle)}</title>
				<meta
					name="description"
					content={translate(translationsScope.pageTitle)}
				/>
			</Helmet>
			<TabsWrapper
				classname={'Hedge-summary__tabs'}
				tabs={HedgeSummaryTabs}
				translations={tabsTranslations}
				type="card"
			/>
		</>
	);
}
