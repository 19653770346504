import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.serviceFees || initialState;

export const selectCurrentView = createSelector(
	[selectDomain],
	(ServiceFeesState) => ServiceFeesState.currentView,
);

export const selectActiveServiceFees = createSelector(
	[selectDomain],
	(ServiceFeesState) => ServiceFeesState.activeServiceFees.data,
);

export const selectServiceFeesListData = createSelector(
	[selectDomain],
	(ServiceFeesState) => ServiceFeesState.list.data,
);

export const selectListLoading = createSelector(
	[selectDomain],
	(ServiceFeesState) => ServiceFeesState.list.loading,
);

export const selectServiceFeesStatusError = createSelector(
	[selectDomain],
	(ServiceFeesState) => ServiceFeesState.serviceFeesStatus.error,
);

export const selectServiceFeeMonths = createSelector(
	[selectDomain],
	(ServiceFeesState) => ServiceFeesState.serviceFeeMonths,
);
