import React from 'react';
import * as Yup from 'yup';

import { GenericOption } from 'types/GenericOption';
import {
	generateCheckbox,
	generateDropdown,
} from 'utils/GenericFormInputs/generic-form-inputs';
import { mapToLabelValue } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';

import { FormSearchWithInputForLocation } from '../components/FormSearchWithInputForLocation';
import { useLocationFieldTranslations } from './translations';
import { CurrentGroupedLocation, LocationField } from './types';

type LocationFieldKey =
	| 'destinationLocationName'
	| 'destinationLocationNumber'
	| 'contractLocationName'
	| 'contractLocationNumber'
	| 'manualEditMode'
	| 'regionId';

const generateSearchDropDownForLocation = (formField) => {
	return (
		<FormSearchWithInputForLocation
			{...formField}
			options={formField.options || []}
		/>
	);
};

export const useLocationFieldFactory = (
	currentLocation: CurrentGroupedLocation | null = null,
): Record<LocationFieldKey, LocationField> => {
	const translations = useLocationFieldTranslations();
	const requiredMsg = translations.validationSchema.required;
	const regionOptions = useEnabledRegions(mapToLabelValue);
	const initialRegionValue: GenericOption | string =
		currentLocation?.regionId && currentLocation?.regionName
			? ({
					label: currentLocation.regionName,
					value: currentLocation.regionId,
				} as GenericOption)
			: '';

	return {
		contractLocationName: {
			name: 'contractLocationName',
			label: translations.location.contractName.label,
			placeholder: translations.location.contractName.placeholder,
			validation: Yup.string().required(requiredMsg),
			initialValue: currentLocation?.contractLocationName,
			render: generateSearchDropDownForLocation,
		},
		contractLocationNumber: {
			name: 'contractLocationNumber',
			label: translations.location.contractNumber.label,
			placeholder: translations.location.contractNumber.placeholder,
			validation: Yup.string().required(requiredMsg),
			initialValue: currentLocation?.contractLocationNumber,
			render: generateSearchDropDownForLocation,
		},
		destinationLocationName: {
			name: 'destinationLocationName',
			label: translations.location.destinationName.label,
			placeholder: translations.location.destinationName.placeholder,
			validation: Yup.string().required(requiredMsg),
			initialValue: currentLocation?.destinationLocationName,
			render: generateSearchDropDownForLocation,
		},
		destinationLocationNumber: {
			name: 'destinationLocationNumber',
			label: translations.location.destinationNumber.label,
			placeholder: translations.location.destinationNumber.placeholder,
			validation: Yup.string().required(requiredMsg),
			initialValue: currentLocation?.destinationLocationNumber,
			render: generateSearchDropDownForLocation,
		},
		manualEditMode: {
			name: 'manualEditMode',
			render: generateCheckbox,
			placeholder: translations.location.manualEditMode,
			label: translations.location.manualEditMode,
			initialValue: false,
			span: 3,
			offset: 3,
		},
		regionId: {
			name: 'regionId',
			key: 'regionId',
			label: translations.location.region.label,
			placeholder: translations.location.region.placeholder,
			initialValue: initialRegionValue,
			options: regionOptions,
			validation: Yup.string().required(requiredMsg),
			render: generateDropdown,
		},
	};
};
