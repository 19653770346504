import { Commodity } from 'types/Commodity';
import { GenericOption } from 'types/GenericOption';
import { CONSTANTS } from 'utils/constants';
import { customFormat, removeComma } from 'utils/helpers';

const DEFAULT_HEDGEABLE_QUANTITY = 5000;
const MIN_QUANTITY = 0.01;

export const hasHedgeableQuantityError = (
	commodities: Commodity[],
	commodityOption: GenericOption,
	value: string,
) => {
	const selectedCommodity = commodities?.find(
		(commodity: Commodity) => commodity?.id === commodityOption?.value,
	);

	const hedgeableQuantity =
		selectedCommodity?.lotFactor || DEFAULT_HEDGEABLE_QUANTITY;

	return value && parseFloat(removeComma(value)) % hedgeableQuantity
		? false
		: true;
};

export const validateMinQuantity = (value: string | undefined) => {
	if (value) {
		try {
			const flatNumber = parseFloat(
				customFormat(value, false, CONSTANTS.FIXED_QUANTITY_DECIMALS),
			);
			return flatNumber >= MIN_QUANTITY;
		} catch (error) {
			console.error(error);
			return false;
		}
	} else {
		return true;
	}
};
