import './style.scss';

import { SyncOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import moment from 'moment';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import {
	selectCachedFuturesPrice,
	selectFuturesMonth,
	selectFuturesMonthOptions,
	selectFuturesPrice,
} from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { useFormatPrice } from 'app/containers/Transactions/components/hooks/useFormatPrice';
import { translations } from 'locales/i18n';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import { CONSTANTS, FuturesMonthCode } from 'utils/constants';
import {
	formatPriceHelper,
	futuresMonthsCodes,
	handlePriceControl,
	mapPropertyToLabelValue,
	mapToLabelValue,
} from 'utils/helpers';
import { preventWheelChange, preventWheelEvent } from 'utils/util';

import { SourceContext } from '../..';
import { useDefaultValues } from '../../hooks/useDefaultValues';
import { useHandleBusinessRules } from '../hooks/useHandleBusinessRules';

enum FuturesTypes {
	FUTURES_MONTH = 'futuresMonth',
	FUTURES_PRICE = 'futuresPrice',
}
interface FuturesProperties {
	disabled?: boolean;
	market?: boolean;
	//When f/m calculated from bidsheet
	getFromBidsheet?: boolean;
	onChange?: () => {};
	wrapperCol?: {
		span?: string | number;
		offset?: string | number;
	};
}
interface FuturesMonthProps extends FuturesProperties {
	loadSpread?: boolean;
	filterMonths?: boolean;
	filterByMonth?: string;
	disableWithinDeliveryMode?: boolean;
	getFuturesMonthOnMount?: boolean;
	source?: CalculatedFieldsSource;
}

interface Props {
	futuresMonth?: FuturesMonthProps;
	futuresPrice?: FuturesProperties;
	useRoundingRules?: boolean;
	checkDefaultValues?: boolean;
	usePriceFromCache?: boolean;
}

export const Futures = memo(function Futures(props: Props) {
	const {
		futuresMonth = {},
		futuresPrice,
		useRoundingRules,
		checkDefaultValues = false,
		usePriceFromCache = false,
	} = props;
	const sourceName = useContext(SourceContext);

	const dispatch = useDispatch();

	const { t: translate } = useTranslation();
	const msg = translations.app.containers.Transactions.components.Futures;
	const { formatPrice }: any = useFormatPrice();
	const defaultValues = useDefaultValues();
	const [persistsFuturesPrice, setPersistsFuturesPrice] =
		useState<boolean>(false);

	const { setValue, setError, getValues, watch, clearErrors } =
		useFormContext();

	const { deliveryDatesMode, futuresPrice: futuresPriceValue } = watch();

	let disableFM = false;
	if (futuresMonth?.disableWithinDeliveryMode) {
		if (deliveryDatesMode?.value === DeliveryDateMode.Window) {
			disableFM = true;
		}
	}

	let label = translate(msg.label);
	if (!!!futuresPrice) {
		label = translate(msg.futuresMonth.label);
	}
	const {
		manageTotalPriceCall,
		getSpreadValue,
		getRoundingRules,
		getFuturesMonths,
	} = useHandleBusinessRules(sourceName);

	// Populates f/m generic options
	let futuresMonths = mapPropertyToLabelValue(
		useSelector(
			selectFuturesMonthOptions(
				futuresMonth.source || CalculatedFieldsSource.Transactions,
			),
		),
		'name',
	);

	if (futuresMonth.filterMonths && futuresMonths) {
		const currentMonth = parseInt(moment().format('M'));
		const currentYear = parseInt(moment().format('YY'));

		futuresMonths = futuresMonths?.filter((futures) => {
			const futureCode = futures.value[0];
			const futuresYear = futures.value.substring(1, 3);
			if (
				parseInt(futuresYear) < currentYear ||
				(futuresMonthsCodes(futureCode as FuturesMonthCode) < currentMonth &&
					parseInt(futuresYear) === currentYear) ||
				futures.value === futuresMonth.filterByMonth
			) {
				return false;
			}

			return true;
		});
	}

	//Calculates f/m value from bidsheet
	const futuresMonthBidsheet = useSelector(selectFuturesMonth);
	const futuresMonthBidsheetValue =
		futuresMonthBidsheet?.[sourceName]?.data ?? null;

	const futuresMonthBidsheetOption = futuresMonthBidsheetValue
		? mapToLabelValue([
				{ id: futuresMonthBidsheetValue, name: futuresMonthBidsheetValue },
			])
		: [];

	const futuresPriceFromState = useSelector(selectFuturesPrice);

	const cachedFuturesPrice = useSelector(selectCachedFuturesPrice);

	const up =
		cachedFuturesPrice?.futuresPrice + cachedFuturesPrice?.priceControl;
	const down =
		cachedFuturesPrice?.futuresPrice - cachedFuturesPrice?.priceControl;

	let calculatedFuturesPrice =
		futuresPriceFromState?.[sourceName]?.data ?? null;

	const futuresPriceMarketFailure =
		futuresPriceFromState?.[sourceName]?.error ?? null;

	const useEffectSetFuturesPrice = (effect: React.EffectCallback) => {
		useEffect(effect, [calculatedFuturesPrice]);
	};

	useEffectSetFuturesPrice(() => {
		//call the value here in case the futures price needs to change.
		if (calculatedFuturesPrice) {
			if (useRoundingRules)
				calculatedFuturesPrice = getRoundingRules(calculatedFuturesPrice);
			if (!usePriceFromCache) {
				setValue('futuresPrice', formatPriceHelper(calculatedFuturesPrice), {
					shouldValidate: true,
				});
			}

			if (usePriceFromCache && futuresPriceValue === null) {
				setValue('futuresPrice', formatPriceHelper(calculatedFuturesPrice), {
					shouldValidate: true,
				});
			}
			if (
				usePriceFromCache &&
				futuresPriceValue !== null &&
				futuresPriceValue !== cachedFuturesPrice?.futuresPrice &&
				!persistsFuturesPrice
			) {
				setValue('futuresPrice', formatPriceHelper(calculatedFuturesPrice), {
					shouldValidate: true,
				});
			}

			manageTotalPriceCall();
		}
	});

	const useEffectSetInlineFuturesPriceError = (
		effect: React.EffectCallback,
	) => {
		useEffect(effect, [futuresPriceMarketFailure]);
	};

	useEffectSetInlineFuturesPriceError(() => {
		if (futuresPriceMarketFailure) {
			let errorMsg;

			if (futuresPrice?.disabled) {
				errorMsg = msg.futuresPrice.marketWarning;
			} else {
				errorMsg = msg.futuresPrice.marketError;
			}
			setError('futuresPrice', {
				type: 'error',
				message: translate(errorMsg),
			});
		}
	});

	useEffect(() => {
		if (futuresMonth.getFuturesMonthOnMount) {
			getFuturesMonths(
				futuresMonth.source || CalculatedFieldsSource.Transactions,
			);
		}
		if (checkDefaultValues) {
			checkDefaultValue();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const checkDefaultValue = () => {
		const { futuresMonth } = defaultValues;
		const { commodity } = getValues();

		if (futuresMonth?.value && !commodity?.value) {
			setValue('futuresMonth', { value: null });
		}
	};
	const handleFuturesMonthChange = () => {
		setValue('futuresPrice', null);
		dispatch(globalActions.clearCachedFuturesPrice());
		if (futuresMonth.loadSpread) {
			getSpreadValue();
		}
	};

	const handlePriceChange = () => {
		manageTotalPriceCall();
	};

	const requiredMsg = translate(
		translations.app.containers.Transactions.Modals.validations.required,
	);
	const requiredFieldsMsg = translate(msg.futuresPriceFieldsNeeded);

	const getFuturesPrice = () => {
		const { commodity, futuresMonth } = getValues();
		if (!usePriceFromCache) {
			setValue('futuresPrice', null);
		}

		if (commodity?.value && futuresMonth?.value) {
			dispatch(
				globalActions.loadFuturesPrice({
					source: sourceName,
					params: {
						commodityId: commodity.value,
						futuresMonth: futuresMonth.value,
					},
				}),
			);
		} else {
			setError('commodity', { type: 'error', message: requiredMsg });
			setError('futuresMonth', { type: 'error', message: requiredMsg });
			setError('futuresPrice', { type: 'error', message: requiredFieldsMsg });
		}
	};

	const priceControlWrapper = () => {
		if (!usePriceFromCache) return;
		if (usePriceFromCache && !cachedFuturesPrice?.futuresPrice) {
			getFuturesPrice();
		}
		const isPriceUnderControl = handlePriceControl(futuresPriceValue, up, down);
		if (!isPriceUnderControl) {
			if (up === 0 && down === 0) return;
			setError('futuresPrice', {
				type: 'error',
				message: translate(msg.priceControlMsg, {
					range: `${down.toFixed(CONSTANTS.FIXED_DECIMALS)} - ${up.toFixed(CONSTANTS.FIXED_DECIMALS)}`,
				}),
				shouldFocus: true,
			});
		} else {
			clearErrors('futuresPrice');
		}
	};

	useEffect(() => {
		if (!usePriceFromCache) return;
		if (up !== 0 && down !== 0) {
			priceControlWrapper();
		}
	}, [
		cachedFuturesPrice,
		futuresPriceValue,
		watch('customer'),
		watch('deliveryDate'),
		watch('quantity'),
		watch('deliveryLocation'),
		watch('assignedRegion'),
		watch('quantityToPrice'),
		watch('qtyPriceBalance'),
		watch('qtyPriceAmount'),
	]);

	return (
		<>
			<GenericForm.FormItem
				name="futures-group"
				label={label}
				data-testid="futures-group-form-item"
			>
				{futuresMonth && (
					<GenericForm.FormItem
						name={FuturesTypes.FUTURES_MONTH}
						onChange={
							futuresMonth.market ? handleFuturesMonthChange : undefined
						}
						data-testid="futures-month-input"
						wrapperCol={{
							span: futuresMonth?.wrapperCol?.span,
							offset: futuresMonth?.wrapperCol?.offset,
						}}
					>
						<Dropdown
							key="futuresMonth"
							placeholder={translate(msg.futuresMonth.placeholder)}
							options={
								futuresMonth?.getFromBidsheet
									? futuresMonthBidsheetOption
									: futuresMonths || []
							}
							disabled={
								!(futuresMonths && futuresMonths.length) ||
								futuresMonth.disabled ||
								disableFM
							}
						/>
					</GenericForm.FormItem>
				)}
				{futuresPrice && (
					<GenericForm.FormItem
						name="futuresPrice"
						wrapperCol={{
							span: futuresPrice?.wrapperCol?.span,
							offset: futuresPrice?.wrapperCol?.offset,
						}}
						onChange={handlePriceChange}
						customClassName={['futures__label']}
						excludeControllerOnBlur
					>
						<Input
							className="futures__input"
							data-testid="futures-price-input"
							disabled={futuresPrice.disabled}
							placeholder={translate(msg.futuresPrice.placeholder)}
							step={0.0001}
							key="futuresPrice"
							suffix={
								<div
									onClick={() => {
										getFuturesPrice();
										setPersistsFuturesPrice(false);
									}}
									data-testid="futures-price-icon"
								>
									{futuresPrice.market && <SyncOutlined />}
								</div>
							}
							onWheelCapture={preventWheelChange}
							onWheel={preventWheelChange}
							onFocus={preventWheelEvent}
							type="number"
							onBlur={() => {
								formatPrice('futuresPrice');
								priceControlWrapper();
								setPersistsFuturesPrice(true);
							}}
						/>
					</GenericForm.FormItem>
				)}
			</GenericForm.FormItem>
		</>
	);
});
