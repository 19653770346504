import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';
import { ActivateDNHModalState } from './types';

const selectDomain = (state: RootState) =>
	state.activateDNHModal || initialState;

export const selectDNHList = createSelector(
	[selectDomain],
	(activateDNHModalState: ActivateDNHModalState) =>
		activateDNHModalState.dnhList.data,
);

export const selectDNHListLoading = createSelector(
	[selectDomain],
	(activateDNHModalState: ActivateDNHModalState) =>
		activateDNHModalState.dnhList.loading,
);

export const selectIsModalOpen = createSelector(
	[selectDomain],
	(activateDNHModalState: ActivateDNHModalState) =>
		activateDNHModalState.isModalOpen,
);
