import './style.scss';

import { Button } from 'antd';
import { Form } from 'formik-antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

interface Props {
	handlePrev: () => void;
	activeTabIndex: number;
	isLastStepActive: boolean;
	isAddingAdditionalCropYear?: boolean;
	disabled?: boolean;
	isEdit?: boolean;
}

export const WizardButtons = memo(function WizardButtons({
	handlePrev,
	isLastStepActive,
	activeTabIndex,
	disabled = false,
	isAddingAdditionalCropYear = false,
	isEdit = false,
}: Props) {
	const { t: translate } = useTranslation();
	const translationsScope = translations.app.components.wizardButtons;
	const buttonSubmitText = translate(translationsScope.wizardSubmit);
	const buttonNextText = translate(translationsScope.wizardNext);
	const buttonPrevText = translate(translationsScope.wizardPrev);

	const getButtonText = () => {
		if (isLastStepActive && isAddingAdditionalCropYear) {
			return buttonSubmitText;
		}
		if (isLastStepActive && !isAddingAdditionalCropYear) {
			return buttonSubmitText;
		}
		if (!isLastStepActive && isAddingAdditionalCropYear) {
			return buttonNextText;
		}
		if (!isLastStepActive && !isAddingAdditionalCropYear && isEdit) {
			return buttonSubmitText;
		}
		return buttonNextText;
	};

	return (
		<Form name="genericWizardForm">
			<div className="wizard-buttons__container">
				<Button
					className="wizard-buttons__prev"
					data-testid="wizard-button-prev"
					key="wizard-buttons-next"
					onClick={handlePrev}
					disabled={activeTabIndex === 0}
				>
					{buttonPrevText}
				</Button>
				<Button
					className="wizard-buttons__next"
					data-testid="wizard-button-submit"
					type="primary"
					key="form-buttons-cancel"
					htmlType="submit"
					disabled={disabled}
				>
					{getButtonText()}
				</Button>
			</div>
		</Form>
	);
});
