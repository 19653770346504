import { Tabs } from 'antd';
import React, { memo } from 'react';

const { TabPane } = Tabs;

interface Props {
	headers: string[];
	activeIndex: number;
	disable: boolean;
}

export const TabGenerator = memo(function TabGenerator(props: Props) {
	const { headers, activeIndex, disable } = props;

	const handleChange = () => {
		if (disable) return;
	};

	return (
		<Tabs
			defaultActiveKey="0"
			activeKey={activeIndex.toString()}
			onChange={handleChange}
			data-testid="wizard-tab-generator"
		>
			{headers &&
				headers.map((title, index) => <TabPane tab={title} key={index} />)}
		</Tabs>
	);
});
