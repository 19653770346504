import React from 'react';
import { useSelector } from 'react-redux';

import Yup from 'yupCustom';

import { HedgeExecutionType } from '../../../components/HedgeExecutionType';
import { HedgeIntegrationType } from '../../../components/HedgeIntegrationType';
import { selectHedgeIntegration } from '../../../selectors';
import { HEDGE_EXECUTION_TYPE, HEDGE_INTEGRATION_TYPE } from '../../../types';
export const useAutomaticSchema = () => {
	const hedgeIntegration = useSelector(selectHedgeIntegration);
	return {
		initialValues: {
			action: HEDGE_INTEGRATION_TYPE.automatic,
			executionType: hedgeIntegration?.isHedgeInstant
				? HEDGE_EXECUTION_TYPE.instant
				: HEDGE_EXECUTION_TYPE.setTime,
			setTime: hedgeIntegration?.hedgeTime,
		},

		validationSchema: Yup.object().shape({
			setTime: Yup.string().when('executionType', {
				is: HEDGE_EXECUTION_TYPE.setTime,
				then: Yup.string().required('value required').min(1, 'value required'),
			}),
		}),
		elements: [<HedgeIntegrationType />, <HedgeExecutionType />],
	};
};
