import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericModalForm } from 'app/components/GenericModalForm';
import { useFieldFactory } from 'app/containers/Settings/definitions/fields';
import { useFormikToState } from 'app/containers/Settings/definitions/hooks/useFormikToState';
import { translations } from 'locales/i18n';
import { FieldDefinition } from 'types/FieldDefinition';
import { ModalSize } from 'types/ModalSize';

import {
	selectOrphanOffersCount,
	selectRollOrphanOffersLoaded,
	selectRollOrphanOffersLoading,
} from '../../selectors';
import { actions } from '../../slice';

interface Props {
	handleClose: () => void;
}

export const RollOrphan = ({ handleClose }: Props) => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const domain =
		translations.app.containers.Settings.sections.Bidsheet.RollOrphan;
	const fieldList = useFieldFactory();
	const rollOrphansLoading = useSelector(selectRollOrphanOffersLoading);
	const rollOrphanLoaded = useSelector(selectRollOrphanOffersLoaded);
	const orphansCount = useSelector(selectOrphanOffersCount);

	useEffect(() => {
		return () => {
			dispatch(actions.resetOrphansModalState());
			dispatch(actions.resetBidsheetPublished());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (rollOrphanLoaded && orphansCount === 0) {
			handleClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rollOrphanLoaded, orphansCount]);

	const createOrphansRollSchema: FieldDefinition[] = [
		fieldList.rollOrphanBids,
		fieldList.rollOrphanTable,
	];

	const handleSubmit = (values) => {
		// TODO: Submit Action
	};
	const handleConfirmation = () => {
		dispatch(actions.rollOrphanOffers());
	};

	return (
		<GenericModalForm
			title={translate(domain.modalTitle)}
			formDefinition={createOrphansRollSchema}
			size={ModalSize.large}
			handleSubmit={handleSubmit}
			handleClose={handleClose}
			footerButtons={
				<FormFooterButtons
					isLoading={rollOrphansLoading}
					cancelLabel={translate(domain.cancelRoll)}
					submitLabel={translate(domain.rollSelected)}
					closeModal={handleClose}
					hasConfirmDialog={true}
					confirmDialogMessage={translate(domain.confirmationMessage)}
					handleDialogConfirm={handleConfirmation}
					confirmButtonText={translate(domain.cofirmationOk)}
					cancelButtonText={translate(domain.cofirmationCancel)}
				/>
			}
			hookSchema={useFormikToState}
			hookParams={{
				noBids: {
					fieldName: fieldList.rollOrphanBids.fields[0].name,
					action: actions.setSelectedNoBid,
				},
				availableBids: {
					fieldName: fieldList.rollOrphanBids.fields[2].name,
					action: actions.setSelectedAvailableBid,
				},
			}}
		/>
	);
};
