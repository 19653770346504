import './style.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { sumProperty } from 'app/containers/TradeBook/helper';
import { translations } from 'locales/i18n';
import { Trade } from 'types/TradeBook';

interface props {
	data: Trade[];
}
export const TableFooter = ({ data }: props) => {
	const translationsScope =
		translations.app.containers.TradeBook.components.TableFooter;
	const { t: translate } = useTranslation();

	const totalFilledLots = sumProperty(data, 'filledQuantity');

	const totalUnmatchedLots = sumProperty(data, 'unmatched');

	const totalLotsAllocated = sumProperty(data, 'allocation');

	return (
		<div className="table-footer">
			<span>
				{translate(translationsScope.totalFilledLots)} {totalFilledLots}{' '}
			</span>
			<span>
				{translate(translationsScope.totalUnmatchedLots)} {totalUnmatchedLots}{' '}
			</span>
			<span>
				{translate(translationsScope.totalLotsAllocated)} {totalLotsAllocated}{' '}
			</span>
		</div>
	);
};
