import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.preHedge || initialState;

export const selectActivePreHedgeDetails = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.activePreHedgeDetails,
);

export const selectActivePreHedge = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.activePreHedge.data,
);

export const selectActivePreHedgeLoading = createSelector(
	[selectDomain],
	(offersState) => offersState.activePreHedge.loading,
);

export const selectCurrentModal = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.currentModal,
);

export const selectCurrentView = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.currentView,
);

export const selectFilters = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.selectedFilters,
);

export const selectFixedColumns = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.fixedColumns,
);

export const selectListAllLoaded = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.list.allLoaded,
);

export const selectListData = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.list.data,
);

export const selectOrphanedBalance = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.list.totalOrphanedBalance,
);

export const selectListLoading = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.list.loading,
);

export const selectListTotal = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.list.total,
);

export const selectPagination = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.pagination,
);

export const selectSelectedRows = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.selectedRows,
);

export const selectExportTemplate = createSelector(
	[selectDomain],
	(reviewAndReleaseState) => reviewAndReleaseState.exportPreHedge,
);

export const selectFuturesMonthsList = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.futureMonths.data,
);

export const selectContractStateFromPreHedge = createSelector(
	[selectDomain],
	(preHedgeState) => preHedgeState.ContractDetails,
);
