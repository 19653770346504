import { call, put, select } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import {
	selectFilters,
	selectPaginationLimit,
	selectPaginationStart,
} from '../selectors';
import { actions } from '../slice';
import { Filters } from '../types';
const { roundingRulesRoot, roundingTypesRoot } = apiEndpoints;

export function* getRoundingRulesList() {
	const filters: Filters = yield select(selectFilters);
	const limit = yield select(selectPaginationLimit);
	const start = yield select(selectPaginationStart);
	const requestURL = yield new URL(`${apiRoutes.base}/${roundingRulesRoot}`);
	const data = {
		limit,
		start,
		sortColumnName: null,
		sortOrder: 1,
		...filters,
	};

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		data,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.roundingRulesListLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.roundingRulesListError(responseError));
	}
}

export function* getRoundingTypesList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${roundingTypesRoot}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.roundingTypesListLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.roundingTypesListError(responseError));
	}
}
