import { Action } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';
import {
	generateDownloadLink,
	generateDragAndDrop,
} from 'utils/GenericFormInputs/generic-form-inputs';

import { selectHedgeMapTemplate } from '../../selectors';
import { actions } from '../../slice';
import { FileUploadPayload } from '../../types';

const XLSX_FORMAT =
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

interface Props {
	uploadAction: (payload: FileUploadPayload) => Action;
}

export const useHedgeMapping = ({ uploadAction }: Props) => {
	const translations = UseFieldTranslations();
	const dispatch = useDispatch();

	// re-using template for both upload and edit because  it is storing the same data structure for two different actions and it is not possible to use edit and create simultaneously
	const template = useSelector(selectHedgeMapTemplate);

	const [preUploadErrors, setPreUploadErrors] = useState<string[]>([]);
	const [processedFile, setProcessedFile] = useState<string>('');
	const supportedFormats = [XLSX_FORMAT];

	const isLessThan25M = (size) => size && size / 1024 / 1024 < 25;

	const clearUpload = () => {
		dispatch(actions.clearHedgeMapTemplate());
	};

	const handleBeforeUpload = (file, setProgress) => {
		let errors: string[] = [];

		clearUpload();
		setProcessedFile('');
		setProgress(20);

		if (file.type !== XLSX_FORMAT) {
			errors.push(translations.hedgeMapping.fileFormatValidation);
			setProgress(0);
		}

		if (!isLessThan25M(file.size)) {
			errors.push(translations.hedgeMapping.fileSizeValidation);
			setProgress(0);
		}

		setPreUploadErrors(errors);
		return file.type === XLSX_FORMAT && isLessThan25M(file.size);
	};

	const handleUpload = (options, setProgress) => {
		const fmData = new FormData();
		const { file, onProgress } = options;

		clearUpload();
		fmData.append('File', file);

		if (template?.data) {
			//adding validation if template is null this means that the user is trying to upload a file in edit section and we will get all the data from the "commodity" state
			const {
				productId,
				name,
				months,
				startDate,
				endDate,
				hasFourthCrop,
				numberOfCropYears,
				commodityId,
			} = template.data;
			fmData.append('ProductId', productId);
			fmData.append('Name', name);
			fmData.append('Months', months);
			fmData.append('StartDate', startDate);
			fmData.append('EndDate', endDate);
			fmData.append('HasFourthCrop', hasFourthCrop);
			fmData.append('NumberOfCropYears', numberOfCropYears);
			if (commodityId) fmData.append('CommodityId', commodityId);
		}

		const config = {
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100);
				setProgress(percent);
				onProgress({ percent: (event.loaded / event.total) * 100 });

				if (percent === 100) {
					setTimeout(() => setProgress(0), 1000);
				}
			},
		};
		setProcessedFile(file.name);
		dispatch(
			uploadAction({
				data: fmData,
				config,
			}),
		);
	};

	return [
		{
			name: 'hedgeMapping',
			key: 'hedgeMapping',
			initialValue: null,
			render: generateDragAndDrop,
			dragText: translations.hedgeMapping.dragText,
			downloadText: translations.hedgeMapping.downloadText,
			uploadText: translations.hedgeMapping.uploadText,
			uploadCompleteText: translations.hedgeMapping.uploadCompleteText,
			errorList: template.uploadResponse.data
				? template.uploadResponse.data.data.errors
				: preUploadErrors,
			fileName: processedFile,
			beforeUpload: handleBeforeUpload,
			onUpload: handleUpload,
			template,
			validation: Yup.mixed()
				.required(translations.validationSchema.requiredFile)
				.test('fileSize', ' ', (value) => value && isLessThan25M(value.size))
				.test(
					'fileFormat',
					' ',
					(value) => value && supportedFormats.includes(value.type),
				)
				.test('wrongValues', ' ', (value) => {
					if (value && template.uploadResponse.data) {
						return !(
							template.uploadResponse.data &&
							template.uploadResponse.data.data.hasErrors
						);
					} else {
						return false;
					}
				}),
		},
		{
			name: 'downloadLink',
			key: 'downloadLink',
			render: generateDownloadLink,
			show: template.data && template.data.url,
			url: template.data && template.data.url,
			fileName:
				template.data && template.data.name
					? `${template.data.name}.xlsx`
					: null,
			disabled: template.uploadResponse.status === 200,
			isUploaded: template.uploadResponse.status === 200,
			isLoading: template.loading,
			uploadCompleteText: translations.hedgeMapping.uploadCompleteText,
			uploadingText: translations.hedgeMapping.uploadText,
			downloadText: translations.hedgeMapping.downloadText,
			onDeleteIcon: clearUpload,
			hide:
				(template.uploadResponse.data &&
					template.uploadResponse.data.data.hasErrors) ||
				!!preUploadErrors.length,
		},
	];
};
