import { Row, Spin } from 'antd';
import React, { memo } from 'react';

import { FieldDefinition } from 'types/FieldDefinition';

import { useNotificationGroupSchema } from '../../schemas/useNotificationGroupSchema';
import { NotificationGroupCreateHookValue } from '../../types';
import { GroupSettingsForm } from '../GroupSettingsForm';

interface Props {
	useGroupsHook: () => NotificationGroupCreateHookValue;
	nonMembersTitle: string;
}

export const CreateNotificationGroup = memo(function CreateNotificationGroup({
	useGroupsHook,
	nonMembersTitle,
}: Props) {
	const { nonMemberList = [], onSubmit, onClose, loading } = useGroupsHook();
	const notificationGroupSchema = useNotificationGroupSchema({
		nonMemberList,
		memberList: [],
		nonMembersTitle,
	}) as FieldDefinition[];

	if (loading) {
		return (
			<Row justify="space-around">
				<Spin />
			</Row>
		);
	} else {
		return (
			<GroupSettingsForm
				fieldSchema={notificationGroupSchema}
				handleSubmit={onSubmit}
				onClose={onClose}
			/>
		);
	}
});
