import { takeLatest } from 'redux-saga/effects';

import { createRegionSaga, getAllRegionsSaga } from './sagas/regions-saga';
import { actions } from './slice';

export function* RegionSaga() {
	yield takeLatest(actions.createRegionRequest.type, createRegionSaga);

	yield takeLatest(actions.getAllRegionsRequest.type, getAllRegionsSaga);
}
