import { PayloadAction } from '@reduxjs/toolkit';

import { Contract, ContractInfoDto } from 'types/Contract';
import { ContractDetails } from 'types/ContractDetails';
import { EventType } from 'types/EventType';
import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
	FIXED_COLUMNS_FOR_CONTRACTS,
	SELECTED_COLUMNS_FOR_CONTRACTS,
} from 'utils/constants';
import { GetPersistedData, mapToLabelValue } from 'utils/helpers';

import {
	GenericLoadListErrorPayload,
	GenericLoadListPayload,
	LoadFuturesMonthOptionsSuccessPayload,
} from '../GlobalSaga/types';
import { ContainerState, ContractsModals, ContractsViews } from './types';

// The initial state of the OrdersContainer container
export const initialState: ContainerState = {
	success: null,
	currentModal: null,
	currentView: ContractsViews.Table,

	initialValues: {
		contractType: {
			label: '',
			value: '',
		},
		transactionType: {
			label: '',
			value: '',
		},
		contractNumber: '',
	},

	selectedFilters: {
		commodities: [],
		customerId: [],
		contractTypeId: [],
		eventId: [],
		contractOrCustomer: '',
		number: '',
		statusView: [],
		futuresMonths: [],
		employeeId: [],
		orderDirection: [],
		transactionTypeId: [],
		destinations: [],
	},

	selectedColumns: GetPersistedData(SELECTED_COLUMNS_FOR_CONTRACTS, {
		created: true,
		contractNumber: true,
		hrvystId: true,
		event: true,
		customer: true,
		employee: true,
		commodity: true,
		crop: true,
		deliveryStartDate: true,
		deliveryEndDate: true,
		futuresMonth: true,
		destination: true,
		pushBasis: true,
		balance: true,
		quantity: true,
		futures: true,
		basis: true,
		cashPrice: true,
		dots: true,
	}),

	fixedColumns: GetPersistedData(FIXED_COLUMNS_FOR_CONTRACTS, {
		created: 'left',
		contractNumber: false,
		hrvystId: false,
		event: false,
		customer: false,
		employee: false,
		commodity: false,
		crop: false,
		deliveryStartDate: false,
		deliveryEndDate: false,
		futuresMonth: false,
		destination: false,
		pushBasis: false,
		balance: false,
		quantity: false,
		futures: false,
		basis: false,
		cashPrice: false,
		dots: 'right',
	}),

	pagination: {
		limit: 50,
		start: 1,
	},

	contractList: {
		data: [],
		loading: false,
		total: 0,
		allLoaded: false,
		error: null,
	},

	activeContract: {
		data: null,
		loading: false,
		error: null,
	},

	activeContractDetails: {
		data: null,
		loading: false,
		error: null,
	},

	contractsChildren: {
		data: null,
		loading: false,
		error: null,
	},

	contractPriceRoll: {
		data: null,
		loading: false,
		error: null,
		response: null,
	},

	currentEventType: EventType.pricingBasis,

	spreadValue: {
		data: null,
		response: null,
		loading: false,
		error: null,
	},

	contractApplyNameId: {
		data: null,
		loading: false,
		error: null,
	},

	resendToERP: {
		data: null,
		loading: false,
		error: null,
	},
	selectedRows: [],
	cashbidsState: {},
	contractsFuturesMonths: {},
};

const contractsSlice = createSlice({
	name: 'contracts',
	initialState,
	reducers: {
		resetSuccess(state) {
			state.success = null;
		},

		setCashbidsState(state, action: PayloadAction<any>) {
			state.cashbidsState = {
				...state.cashbidsState,
				...action.payload,
			};
		},

		clearCashbidsState(state) {
			state.cashbidsState = {};
		},

		setSelectedFilters(state, action: PayloadAction<any>) {
			state.selectedFilters = {
				...state.selectedFilters,
				...action.payload,
			};
		},

		setFixedColumns(state, action: PayloadAction<any>) {
			state.fixedColumns = {
				...state.fixedColumns,
				...action.payload,
			};
		},

		setSelectedColumns(state, action: PayloadAction<any>) {
			state.selectedColumns = {
				...state.selectedColumns,
				...action.payload,
			};
		},

		updateContractList: (state, action: PayloadAction<ContractInfoDto>) => {
			const { payload } = action;
			const contractIndex = state.contractList.data.findIndex(
				(contract) => contract.id === payload.id,
			);

			if (contractIndex !== -1) {
				// If the contract already exists, update its properties
				state.contractList.data[contractIndex].customer.name =
					payload.customerName;

				state.contractList.data[contractIndex].delivery.destination =
					payload.deliveryLocationName;
				state.contractList.data[contractIndex].delivery.begin =
					payload.deliveryStartDate as string;
				state.contractList.data[contractIndex].delivery.end =
					payload.deliveryEndDate as string;

				state.contractList.data[contractIndex].commodity =
					payload.commodityName;
				state.contractList.data[contractIndex].contract.internalNumber =
					payload.internalNumber;
				state.contractList.data[contractIndex].creationDate = payload.createdOn;
				state.contractList.data[contractIndex].status = payload.status;
				state.contractList.data[contractIndex].contract.number = payload.number;

				state.contractList.data[contractIndex].isSell = payload.isSell;
				state.contractList.data[contractIndex].cropYear = payload.cropYear;

				state.contractList.data[contractIndex].price.price = payload.price;
				state.contractList.data[contractIndex].price.futuresMonth =
					payload.futuresMonth;
				state.contractList.data[contractIndex].price.futures =
					payload.futuresPrice || 0;
				state.contractList.data[contractIndex].price.freight =
					payload.freightPrice;

				state.contractList.data[contractIndex].quantity.quantityOnContract =
					payload.quantityOnContract;
				state.contractList.data[contractIndex].basis.push =
					payload.pushBasis || null;
				state.contractList.data[contractIndex].basis.net =
					payload.netBasis || null;
				state.contractList.data[contractIndex].basis.posted =
					payload.postedBasis || null;

				state.contractList.data[contractIndex].event = payload.event;
				state.contractList.data[contractIndex].remainingBalance =
					payload.remainingBalance;
				state.contractList.data[contractIndex].comesFromAnOffer =
					payload.comesFromAnOffer;
			} else {
				// If the contract doesn't exist
				// TODO: Somehow we have to check if this item should be in the filtered list
			}
		},

		loadContractList(state) {
			state.contractList = {
				...state.contractList,
				data: [],
				loading: true,
				error: null,
			};
		},
		contractListLoaded(
			state,
			action: PayloadAction<{
				data: Contract[];
				total: number;
				allLoaded: boolean;
			}>,
		) {
			const { data: contractList, total, allLoaded } = action.payload;

			state.contractList = {
				...state.contractList,
				data: contractList,
				total,
				allLoaded,
				loading: false,
				error: null,
			};
		},
		setPagination(state, action: PayloadAction<any>) {
			state.pagination = {
				limit: action.payload.limit || state.pagination.limit,
				start: action.payload.start || state.pagination.start,
			};
		},

		// Select single contract
		setActiveOrder(state, action: PayloadAction<any>) {
			state.activeContract.data = action.payload;
		},

		// Single contract details
		loadContractDetails(state, action: PayloadAction<string>) {
			state.activeContract = {
				data: null,
				loading: true,
				error: null,
			};
		},

		//Get total orders already loaded
		contractDetailsLoaded(state, action: PayloadAction<ContractDetails>) {
			state.activeContract = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		// Contract and customer details
		loadContractSummary(state, action: PayloadAction<{ id: string }>) {
			state.activeContractDetails = {
				data: null,
				loading: true,
				error: null,
			};
		},

		// Set in state active contract summary
		contractSummaryLoaded(state, action: PayloadAction<ContractDetails>) {
			state.activeContractDetails = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		// Select single contract
		setActiveOrderDetails(state, action: PayloadAction<any>) {
			state.activeContractDetails.data = action.payload;
		},

		// Current Modal: Create, edit or cancel, convert contract, bulkConvert
		setCurrentModal(state, action: PayloadAction<ContractsModals | null>) {
			state.currentModal = action.payload;

			if (state.currentModal === null) {
				state.activeContract.data = null;
				state.activeContract.error = null;
			}
		},

		// set Current action: View Details, Table Contracts
		setCurrentView(state, action: PayloadAction<ContractsViews | null>) {
			state.currentView = action.payload;
		},

		// Current type of contract in creation and edition (Flat price, HTA or Basis)
		setInitialValues(
			state,
			action: PayloadAction<typeof initialState.initialValues>,
		) {
			state.initialValues = action.payload;
		},

		// Create contract
		createContract(state, action: PayloadAction<any>) {
			state.activeContract = {
				data: null,
				loading: true,
				error: null,
			};

			state.success = null;
		},
		contractCreated(state) {
			state.activeContract = {
				data: null,
				loading: false,
				error: null,
			};

			state.success = true;
		},

		cancelContract(state, action: PayloadAction<object>) {
			state.contractList = {
				...state.contractList,
				loading: true,
				error: null,
			};
		},
		contractCanceled(state) {
			state.contractList = {
				...state.contractList,
				loading: false,
				error: null,
			};
			state.success = true;
		},
		cancelContractError(state, action: PayloadAction<GenericError>) {
			state.contractList = {
				...state.contractList,
				loading: false,
				error: action.payload,
			};
		},

		resetContractError(state) {
			state.contractList = {
				...state.contractList,
				loading: false,
				error: null,
			};
		},

		// Update contract
		updateOrder(state, action: PayloadAction<any>) {
			state.activeContract = {
				...state.activeContract,
				loading: true,
				error: null,
			};

			state.success = false;
		},
		contractUpdated(state) {
			state.activeContract = {
				data: null,
				loading: false,
				error: null,
			};

			state.success = true;
		},

		// convert contract
		convertOrder(state, action: PayloadAction<any>) {
			state.activeContract = {
				...state.activeContract,
				loading: true,
				error: null,
			};

			state.success = false;
		},

		contractConverted(state) {
			state.activeContract = {
				data: null,
				loading: false,
				error: null,
			};

			state.success = true;
		},

		// bulk Convert
		bulkConvertOrder(state, action: PayloadAction<any>) {
			state.contractList = {
				...state.contractList,
				loading: true,
				error: null,
			};
			state.success = false;
		},

		bulkContractConverted(state) {
			state.contractList = {
				...state.contractList,
				loading: false,
				error: null,
			};

			state.success = true;
		},

		// bulk Update
		bulkUpdateOrder(state, action: PayloadAction<any>) {
			state.contractList = {
				...state.contractList,
				loading: true,
				error: null,
			};
			state.success = false;
		},

		bulkUpdateConverted(state) {
			state.contractList = {
				...state.contractList,
				loading: false,
				error: null,
			};

			state.success = true;
		},

		/**
		 * Generic error action for all the requests related to orders
		 * in order to preserver previuous data list loaded, only set error object
		 * */
		contractListError(state, action: PayloadAction<GenericError>) {
			state.contractList = {
				...state.contractList,
				error: action.payload,
				loading: false,
			};
		},

		activeContractError(state, action: PayloadAction<GenericError>) {
			state.activeContract = {
				...state.activeContract,
				error: action.payload,
				loading: false,
			};
		},

		contractSummaryError(state, action: PayloadAction<GenericError>) {
			state.activeContractDetails = {
				...state.activeContractDetails,
				error: action.payload,
				loading: false,
			};
		},

		//Price - Roll Actions
		priceRollContract(state, action) {
			state.contractPriceRoll = {
				data: action.payload.data,
				loading: true,
				error: null,
				response: null,
			};
		},

		contractPriceRollCreated(state, action) {
			state.contractPriceRoll = {
				...state.contractPriceRoll,
				response: action.payload,
				loading: false,
				error: null,
			};
		},

		errorContractPriceRoll(state, action: PayloadAction<GenericError>) {
			state.contractPriceRoll = {
				...state.contractPriceRoll,
				error: action.payload,
				loading: false,
			};
		},

		resetContractPriceRoll(state) {
			state.contractPriceRoll = {
				data: null,
				loading: false,
				response: null,
				error: null,
			};
		},

		setCurrentEventType(state, action: PayloadAction<EventType>) {
			state.currentEventType = action.payload;
		},

		// Contract's Children
		loadContractsChildren(state, action: PayloadAction<string>) {
			state.contractsChildren = {
				data: null,
				loading: true,
				error: null,
			};
		},

		// Set in state contract's children
		contractsChildrenLoaded(state, action: PayloadAction<any>) {
			state.contractsChildren = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		contractsChildrenError(state, action: PayloadAction<GenericError>) {
			state.contractsChildren = {
				...state.contractsChildren,
				error: action.payload,
				loading: false,
			};
		},

		loadSpreadValue(state, action: PayloadAction<any>) {
			state.spreadValue = {
				data: action.payload,
				response: null,
				loading: true,
				error: null,
			};
		},

		spreadValueLoaded(state, action: PayloadAction<any>) {
			state.spreadValue = {
				data: null,
				response: action.payload,
				loading: false,
				error: null,
			};
		},

		spreadValueError(state, action: PayloadAction<GenericError>) {
			state.spreadValue = {
				data: null,
				response: null,
				error: action.payload,
				loading: false,
			};
		},

		resetSpreadValue(state) {
			state.spreadValue = {
				data: null,
				response: null,
				loading: false,
				error: null,
			};
		},

		loadApplyNameId(state, action: PayloadAction<any>) {
			state.contractApplyNameId = {
				...state.contractApplyNameId,
				loading: true,
			};
		},

		applyNameId(state, action: PayloadAction<any>) {
			state.contractApplyNameId = {
				...state.contractApplyNameId,
				loading: true,
			};
		},

		applyNameIdSuccess(state, action: PayloadAction<any>) {
			state.contractApplyNameId = {
				...state.contractApplyNameId,
				data: action.payload,
				loading: false,
			};
		},

		clearApplyNameId(state) {
			state.contractApplyNameId = {
				...state.contractApplyNameId,
				data: null,
				loading: false,
			};
		},

		applyNameIdError(state, action: PayloadAction<GenericError>) {
			state.contractApplyNameId = {
				...state.contractApplyNameId,
				loading: false,
				error: action.payload,
			};
		},

		resendToERP(state, action: PayloadAction<any>) {
			state.resendToERP = {
				...state.contractApplyNameId,
				loading: true,
			};
		},

		resendToERPError(state, action: PayloadAction<any>) {
			state.resendToERP = {
				...state.contractApplyNameId,
				error: action.payload,
				loading: false,
			};
		},

		resendToERPSuccess(state, action: PayloadAction<any>) {
			state.resendToERP = {
				...state.contractApplyNameId,
				data: action.payload,
				loading: false,
			};
		},

		// For multiple sellect contract on contract grid
		setSelectedRows(state, action: PayloadAction<any>) {
			state.selectedRows = action.payload;
		},

		// to clear out multiple sellect contract on contract grid after bulkaction has been performed
		setSelectedRowsAsEmpty(state) {
			state.selectedRows = [];
		},
		loadContractsFuturesMonthsList(
			state,
			action: PayloadAction<GenericLoadListPayload>,
		) {
			const sourceListName = action.payload.source;

			state.contractsFuturesMonths[sourceListName] = {
				data: [],
				loading: true,
				error: null,
			};
		},
		contractsFuturesMonthsListLoaded(
			state,
			action: PayloadAction<LoadFuturesMonthOptionsSuccessPayload>,
		) {
			const sourceListName = action.payload.source;

			state.contractsFuturesMonths[sourceListName] = {
				data: mapToLabelValue(
					action?.payload.data.map((item) => ({
						...item,
						highlight: item.isExpired,
					})),
				),
				loading: false,
				error: null,
			};
		},
		contractsFuturesMonthsListError(
			state,
			action: PayloadAction<GenericLoadListErrorPayload>,
		) {
			const sourceListName = action.payload.source;

			state.contractsFuturesMonths[sourceListName] = {
				...state.contractsFuturesMonths[sourceListName],
				loading: false,
				error: action.payload.error,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = contractsSlice;
