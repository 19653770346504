import './style.scss';

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';
interface Props {
	disabled?: boolean;
}

export const UnfilledQuantity = memo(function UnfilledQuantity(props: Props) {
	const { disabled } = props;
	const { t: translate } = useTranslation();
	const featureFlags = useSelector(selectFeatureFlags);
	const msg = translations.app.containers.Transactions.components.Quantity;

	return (
		featureFlags[FeatureFlag.enableOfferQuantityEdit] && (
			<GenericForm.FormItem
				label={translate(msg.unfilledQuantity)}
				name="remainingBalance"
				customClassName={['input-remainingBalance__label']}
				data-testid="remainingBalance-form-item"
				isNumericFieldSlow
			>
				<Input
					className="input-remainingBalance__input"
					key="remainingBalance"
					name="remainingBalance"
					placeholder={translate(msg.placeholder)}
					type="text"
					disabled={disabled}
				/>
			</GenericForm.FormItem>
		)
	);
});
