import { Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { selectOrderEntry } from 'app/containers/GlobalSaga/selectors';

import { actions } from '../../slice';
import { TOGGLE_FORM_ITEMS } from '../../types';
import { ORDER_ENTRIES_MAX, ORDER_ENTRY_DOMAIN } from '../../utils/helpers';

export const Toggle = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const fields = useSelector(selectOrderEntry);

	const handleSwitchChange = (checked: boolean) => {
		dispatch(
			actions.setOrderEntryRequest({
				data: { fields, hasCustomFields: checked },
			}),
		);
	};

	return (
		<GenericForm.FormItem
			colon={false}
			data-testid={`${TOGGLE_FORM_ITEMS.HAS_CUSTOM_FIELDS}-form-item`}
			label={translate(ORDER_ENTRY_DOMAIN.Views.ToggleForm.toggleLabel, {
				maxOrderEntries: ORDER_ENTRIES_MAX,
			})}
			name={TOGGLE_FORM_ITEMS.HAS_CUSTOM_FIELDS}
		>
			<Switch
				key={TOGGLE_FORM_ITEMS.HAS_CUSTOM_FIELDS}
				onChange={handleSwitchChange}
			/>
		</GenericForm.FormItem>
	);
};
