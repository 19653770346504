import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmationModal } from 'app/components/ConfirmationModal';
import { selectActiveOffer } from 'app/containers/Offers/selectors';
import { actions } from 'app/containers/Offers/slice';

import { useTranslations } from '../shared/useTranslations';

export const InformCustomerModal = () => {
	const dispatch = useDispatch();

	const translations = useTranslations();

	const data = useSelector(selectActiveOffer);

	const handleConfirmation = () => {
		dispatch(actions.completeOffer({ id: data?.id }));
	};

	const handleCancel = () => {
		dispatch(actions.setCurrentModal(null));
	};

	return (
		<ConfirmationModal
			handleCancel={handleCancel}
			handleConfirmation={handleConfirmation}
			title={translations.informCustomer.title}
			body={translations.informCustomer.body}
		/>
	);
};
