/**
 *
 * AuthenticationCallback
 *
 */

import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	selectAuthUser,
	selectSessionError,
	selectUserInfo,
} from 'app/containers/GlobalSaga/selectors';
import { actions } from 'app/containers/GlobalSaga/slice';
import { ROUTES } from 'app/routes';
import { translations } from 'locales/i18n';
import { SessionActions } from 'types/SessionActions';
import { SessionErrors } from 'types/SessionErrors';

interface Props {}

export const AuthenticationCallback = memo(function AuthenticationCallback(
	props: Props,
) {
	const history = useHistory();
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const authUser = useSelector(selectAuthUser);
	const userInfo = useSelector(selectUserInfo);
	const sessionError = useSelector(selectSessionError);

	const urlParams = new URLSearchParams(history.location.search);
	const accessToken = urlParams.get('Token');
	useEffect(() => {
		dispatch(
			actions.startManageSession({
				sessionAction: SessionActions.setSession,
				accessToken: accessToken,
			}),
		);
	}, [accessToken, dispatch]);

	authUser && userInfo && history.push(ROUTES.HOME);
	return (
		<>
			<div>
				{!sessionError &&
					translate(translations.app.containers.Login.authenticatingText)}

				{sessionError && sessionError.detail === SessionErrors.invalidToken
					? translate(translations.app.containers.Login.invalidTokenText)
					: sessionError && sessionError.detail}
			</div>
		</>
	);
});
