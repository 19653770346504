/**
 *
 * Customers
 *
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { CreateCustomer } from './Modals/CreateCustomer';
import { EditCustomer } from './Modals/EditCustomer';
import { customersSaga } from './saga';
import { selectCurrentCustomerLoading, selectCurrentModal } from './selectors';
import { actions, reducer, sliceKey } from './slice';
import { Modal } from './types';
import { List } from './View/List';

export function Customers() {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: customersSaga });

	const dispatch = useDispatch();
	const currentModal = useSelector(selectCurrentModal);
	const editCustomerLoading = useSelector(selectCurrentCustomerLoading);

	useEffect(() => {
		dispatch(actions.getCountriesList());
		dispatch(actions.getStatesList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<List />
			{currentModal === Modal.Create && <CreateCustomer />}
			{currentModal === Modal.Edit && !editCustomerLoading && <EditCustomer />}
		</>
	);
}
