import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { selectFilters } from 'app/containers/ReviewAndRelease/selectors';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { fileRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { ReviewAndReleaseFilters } from '../types';
import { getSelectedFilter } from '../utils/helpers';

const { reviewAndReleaseExport } = apiEndpoints;

export function* exportReviewAndRelease(action: PayloadAction<any>) {
	const { successMsg, errorMsg } = action.payload;
	const selectedFilters: ReviewAndReleaseFilters = yield select(selectFilters);
	const requestURL = new URL(`${apiRoutes.base}/${reviewAndReleaseExport}`);

	const selectedStatus = getSelectedFilter('status', 'value', selectedFilters);
	const selectedFuturesMonth = getSelectedFilter(
		'futuresMonth',
		'value',
		selectedFilters,
	);
	const selectedSymbol = getSelectedFilter('symbol', 'value', selectedFilters);
	const selectedCustomerId = getSelectedFilter(
		'customerId',
		'value',
		selectedFilters,
	);

	const data: any = {
		...selectedFilters,
		status: selectedStatus,
		futuresMonth: selectedFuturesMonth,
		symbol: selectedSymbol,
		customerId: selectedCustomerId,
	};

	const { responseData, responseError }: any = yield call(
		fileRequest,
		requestURL,
		httpMethod.Post,
		false,
		data,
		null,
		true,
		true,
	);
	if (!!responseData) {
		yield put(actions.exportedReviewAndRelease(responseData));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMsg + responseData.fileName,
			}),
		);
		yield put(actions.clearExportReviewAndRelease());
	} else if (!!responseError.detail) {
		yield put(actions.exportReviewAndReleaseError(responseError));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.ERROR,
				message: errorMsg,
			}),
		);
	}
}
