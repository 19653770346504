import { z } from 'zod';

import { GenericError } from 'types/GenericError';
import { GenericItem } from 'types/GenericItem';
import { GenericOption } from 'types/GenericOption';

export function isEmptyObject(obj: unknown): obj is Record<string, never> {
	if (obj !== null && typeof obj === 'object' && !Array.isArray(obj)) {
		return Object.keys(obj as object).length === 0;
	}
	return false;
}

export function isNonEmptyArray<T>(
	array: T[] | null | undefined,
): array is [T, ...T[]] {
	return (array?.length ?? 0) > 0;
}

export const genericItemSchema: z.Schema<GenericItem> = z.object({
	id: z.string(),
	name: z.string(),
});

const genericOptionSchema: z.Schema<GenericOption> = z.object({
	label: z.string(),
	value: z.string(),
	type: z.string().optional(),
	highlight: z.boolean().optional(),
	regionIds: z.array(z.string()).optional(),
});

export function isGenericOption(value: unknown): value is GenericOption {
	return genericOptionSchema.safeParse(value).success;
}

export const genericErrorSchema: z.Schema<GenericError, z.ZodTypeDef, unknown> =
	z.object({
		title: z.string().optional(),
		detail: z.string().nullable().default(null),
		type: z.string().optional(),
		status: z.number().optional(),
		data: z.any().optional(),
	});
