import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { CreateNotificationGroup } from '../../components/CreateNotificationGroup';
import { DeleteNotificationGroup } from '../../components/DeleteNotificationGroup';
import { EditNotificationGroup } from '../../components/EditNotificationGroup';
import { ListNotificationGroups } from '../../components/ListNotificationGroups';
import { useCustomerGroupCreate } from '../../hooks/useCustomerGroupCreate';
import { useCustomerGroupDelete } from '../../hooks/useCustomerGroupDelete';
import { useCustomerGroupEdit } from '../../hooks/useCustomerGroupEdit';
import { useCustomerGroupList } from '../../hooks/useCustomerGroupList';
import { selectCurrentModal, selectCurrentView } from '../../selectors';
import { actions } from '../../slice';
import { Modal, View } from '../../types';

export const CustomerGroupNotificationSettings = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;
	const customersTitle = translate(translationsScope.CustomersTitle);

	const dispatch = useDispatch();
	const currentModal = useSelector(selectCurrentModal);
	const currentView = useSelector(selectCurrentView);

	useEffect(() => {
		return () => {
			dispatch(actions.setCurrentView(View.List));
		};
	}, [dispatch]);

	return (
		<>
			{currentView === View.Create ? (
				<CreateNotificationGroup
					useGroupsHook={useCustomerGroupCreate}
					nonMembersTitle={customersTitle}
				/>
			) : currentView === View.Edit ? (
				<EditNotificationGroup
					useGroupsHook={useCustomerGroupEdit}
					nonMembersTitle={customersTitle}
				/>
			) : (
				<ListNotificationGroups useGroupsHook={useCustomerGroupList} />
			)}
			{currentModal === Modal.Delete && (
				<DeleteNotificationGroup useGroupsHook={useCustomerGroupDelete} />
			)}
		</>
	);
};
