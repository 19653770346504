import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import {
	FormButtonsProps,
	FormSchemaDefinition,
} from 'app/components/GenericForm/types';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { selectActiveOfferLoading } from 'app/containers/Offers/selectors';
import { actions } from 'app/containers/PreHedge/slice';
import { PreHedgeRequestBody } from 'app/containers/PreHedge/types';
import { SourceContext } from 'app/containers/Transactions';
import { ModalHeader } from 'app/containers/Transactions/components/ModalHeader';
import { ActionType } from 'types/ActionType';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { DATE_FORMAT, PRE_HEDGE_ORDER_TYPES } from 'utils/constants';
import { isActionSell, isMarketOrder } from 'utils/helpers';

import { useTranslations } from '../shared/useTranslations';
import { usePreHedgeSchema } from './schemas/PreHedge';

export const CreateModal = memo(function CreateModal() {
	const dispatch = useDispatch();

	const translations = useTranslations();

	const [closeAfterSubmit, setCloseAfterSubmit] = useState(true);
	const isLoading = useSelector(selectActiveOfferLoading);

	const {
		newPreHedgeTitle: newOfferTitle,
		createConfirmationMessage: preHedgeCreateConfirmationMessage,
		confirmText,
		cancelText,
	} = translations.actions;

	const preHedgeSchemas = usePreHedgeSchema();
	const defaultSchema = preHedgeSchemas[PRE_HEDGE_ORDER_TYPES.marketOrder];
	const [currentSchema, setCurrentSchema] =
		useState<FormSchemaDefinition>(defaultSchema);
	const resolver = yupResolver(currentSchema?.validationSchema);

	const formInstance = useForm({
		defaultValues: {
			...currentSchema.initialValues,
			...defaultSchema,
			action: ActionType.SELL,
		},
		resolver,
		mode: 'all',
	});

	const { watch } = formInstance;

	const currentContractType = watch('preHedgeOrderType')?.value;

	const cleanState = () => {
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	useEffect(() => {
		const { preHedgeOrderType } = formInstance.watch();
		const { isDirty } = formInstance.formState;

		if (!preHedgeOrderType?.label || !isDirty) return;

		const newSchema = preHedgeSchemas[preHedgeOrderType.label];

		cleanState();

		// 7020:
		// 1. For common fields, we're preserving the user's input across both order types.
		//    By spreading ...currentValues after ...newSchema?.initialValues, we ensure that
		//    the user's input for common fields is preserved across different order types.
		//
		// 2. For fields that are present in Limit but not Market orders (e.g., Futures Price,
		//    GTC mode, and Expiration Date), they are automatically handled by using the new
		//    schema's initial values. When switching to a Market order, these fields won't be
		//    included in the new schema, effectively 'resetting' them. The ...newSchema?.initialValues
		//    ensures fields specific to the new order type are reset appropriately.
		//
		// 3. When switching back to a Limit order, these fields will be reinitialized with the
		//    schema's default values due to ...newSchema?.initialValues.

		const currentValues = formInstance.getValues();

		formInstance.reset({
			...newSchema?.initialValues,
			...currentValues,
		});

		setCurrentSchema(newSchema);

		// Address the issue with Futures M/Y field becoming incorrectly disabled when switching order types
		// Scenario:
		// 1. User opens the modal (defaults to Market Order Sell)
		// 2. User selects a commodity and Futures M/Y (e.g., Z24)
		// 3. User changes order type from Market Order to Limit Order
		// Expected: Futures M/Y should remain enabled if a commodity is selected
		// Actual (before fix): Futures M/Y became incorrectly restricted

		// Reload futures months options if a commodity is selected
		if (currentValues.commodity?.value) {
			dispatch(
				globalActions.loadFuturesMonthOptions({
					source: CalculatedFieldsSource.Transactions,
					params: {
						commodityId: currentValues.commodity.value,
					},
				}),
			);
		}

		// Clear any existing errors to prevent fields from being marked as invalid during the switch
		formInstance.clearErrors();

		// Re-evaluate validation for all fields to ensure correct enabled/disabled states
		Object.keys(formInstance.getValues()).forEach((fieldName) => {
			formInstance.trigger(fieldName);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentContractType]);

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'order-form__new-order',
			htmlType: 'submit',
			children: translations.buttons.submitAndNewPreHedge,
			onClick: () => setCloseAfterSubmit(false),
			disabled: isLoading,
			debounceReenable: 1000,
			key: 'submitAndNewPreHedge',
		},
		{
			htmlType: 'submit',
			children: translations.buttons.submitPreHedge,
			disabled: isLoading,
			debounceReenable: 1000,
			key: 'submit',
		},
	];

	const header = (
		<ModalHeader
			title={newOfferTitle}
			content={`${preHedgeCreateConfirmationMessage} ${translations.actions.confirmation}`}
			confirmText={confirmText}
			cancelText={cancelText}
			handleConfirm={handleClose}
		/>
	);

	const handleSubmit = (values) => {
		const data: PreHedgeRequestBody = {
			isMarketOrder: isMarketOrder(values.preHedgeOrderType.value),
			isSell: isActionSell(values.action),
			commodityId: values.commodity.value,
			cropYear: values.cropYear,
			futuresMonth: values.futuresMonth?.value ?? values.futuresMonth,
			lots: values.numberOfLots ? parseFloat(values.numberOfLots) : 0,
			openPrice: values?.orderPrice ? parseFloat(values?.orderPrice) : 0,
			futuresAccountId: values?.hedgeAccount?.value,
			gtc: values.gtcMode,
			expiration: values.expirationDateGTC?.length
				? moment(values.expirationDateGTC).format(DATE_FORMAT)
				: null,
			comments: values.comments,
		};

		if (closeAfterSubmit) {
			cleanState();
		}
		dispatch(
			actions.createPreHedge({
				data,
				closeAfterSubmit: closeAfterSubmit,
				successMessage: translations.common.success,
			}),
		);
		setCloseAfterSubmit(true);
	};

	return (
		<GenericForm.ModalContainer
			key="customerModal"
			className="create-form__modal"
			title={header}
			onCancel={handleClose}
			closable={false}
			destroyOnClose={true}
			keyboard={false}
		>
			<SourceContext.Provider value={GlobalSagaSource.createEditOfferModal}>
				<GenericForm.Form
					className="order-form"
					key="customerForm"
					formInstance={formInstance}
					validationSchema={currentSchema?.validationSchema}
					onSubmit={handleSubmit}
					buttonsDefinition={btnsDefinition}
				>
					{currentSchema?.elements}
				</GenericForm.Form>
			</SourceContext.Provider>
		</GenericForm.ModalContainer>
	);
});
