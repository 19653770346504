import './styles.scss';

import ResizeObserver from 'rc-resize-observer';
import React, { memo, useCallback, useEffect, useRef } from 'react';

import { getElementSize } from 'utils/helpers';

interface Props {
	onRefChange: (height: number) => void;
	children?: JSX.Element;
	containerClassName?: string;
}

export const ResizeObserverWrapper = memo(function ResizeObserverWrapper(
	props: Props,
) {
	const { onRefChange, children = <></>, containerClassName } = props;

	const containerRef = useRef<HTMLDivElement>(null);
	const onResizeOrMutate = useCallback(() => {
		const node = containerRef.current;
		if (!children.props?.loading && node) {
			const header: HTMLElement | null =
				node.querySelector('.ant-table-header');
			const pagination: HTMLElement | null = node.querySelector(
				'.ant-table-pagination',
			);

			const containerHeight = getElementSize(node).height;
			const headerHeight = getElementSize(header).height;
			const paginationHeight = getElementSize(pagination).height;

			onRefChange(containerHeight - headerHeight - paginationHeight);
		}
	}, [children.props?.loading, onRefChange]);

	useEffect(() => {
		if (containerRef.current) {
			let timeout: ReturnType<typeof setTimeout> | null = null;
			const observer = new MutationObserver(() => {
				if (timeout) clearTimeout(timeout);
				timeout = setTimeout(() => {
					onResizeOrMutate();
				}, 100);
			});
			observer.observe(containerRef.current, {
				subtree: true,
				childList: true,
				attributes: true,
			});
			return () => {
				observer.disconnect();
				if (timeout) clearTimeout(timeout);
			};
		}
	}, [onResizeOrMutate]);

	return (
		<ResizeObserver onResize={onResizeOrMutate}>
			<div
				className={`resize-observer-target ${containerClassName}`}
				ref={containerRef}
			>
				{children}
			</div>
		</ResizeObserver>
	);
});
