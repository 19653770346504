import './styles.scss';

import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	CheckCircleFilled,
	CloseCircleOutlined,
	CloseOutlined,
	EditFilled,
	SaveOutlined,
} from '@ant-design/icons';
import {
	Button,
	DatePicker,
	InputNumber,
	Modal,
	Select,
	Space,
	Tag,
	Tooltip,
	Typography,
} from 'antd';
import moment from 'moment';
import React, { ComponentProps, useState } from 'react';
import { useAbac } from 'react-abac';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Calendar } from 'app/components/Calendar';
import { ConfirmDialog } from 'app/components/ConfirmDialog';
import { Dropdown } from 'app/components/Dropdown';
import { GenericTable } from 'app/components/GenericTable';
import { UserAvatar } from 'app/components/UserAvatar';
import {
	selectCommoditiesList,
	selectCropsList,
	selectDestinationsList,
	selectFeatureFlags,
	selectFuturesMonthOptions,
} from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import { FeatureFlag } from 'types/FeatureFlags';
import {
	dateFormat,
	dateFormatDelivery,
	mapPropertyToLabelValue,
	mapToLabelValue,
	timeFormat,
} from 'utils/helpers';

import {
	selectAllNotificationGroups,
	selectAllNotificationGroupsLoading,
	selectBidsheetListType,
	selectListData,
	selectListLoading,
	selectListTotal,
	selectPagination,
	selectValidationErrors,
} from '../../../selectors';
import { actions, actions as bidSheetActions } from '../../../slice';
import { Bidsheet, TableTypes } from '../../../types';
import { Delete } from './Delete';

const { Paragraph } = Typography;

export const Table = () => {
	const dispatch = useDispatch();
	const featureFlags = useSelector(selectFeatureFlags);

	const { t: translate } = useTranslation();
	const [editingKey, setEditingKey] = useState('');
	const translationsScope =
		translations.app.containers.Settings.sections.Bidsheet.View.List.Table;

	const listData = useSelector(selectListData);
	const listTotal = useSelector(selectListTotal);
	const listLoading = useSelector(selectListLoading);
	const pagination = useSelector(selectPagination);
	const bidsheetListType = useSelector(selectBidsheetListType);
	const validationErrors = useSelector(selectValidationErrors);

	const { userHasPermissions } = useAbac();
	const hasBidsheetUploadPermission = userHasPermissions(
		Permission.CONTRACTSERVICE_BIDSHEET_UPLOAD,
	);
	const noPermissionTooltipProps: ComponentProps<typeof Tooltip> = {
		title: translate(translations.app.commons.noPermission),
	};
	// hide the tooltip if user has permission
	if (hasBidsheetUploadPermission) {
		noPermissionTooltipProps.visible = false;
	}

	// Notifications
	const groups = useSelector(selectAllNotificationGroups);
	const groupsLoading = useSelector(selectAllNotificationGroupsLoading);

	let isModalVisible = validationErrors?.length > 0 ?? false;
	const closeModal = () => {
		dispatch(actions.clearBidValidationErrors());
		isModalVisible = false;
	};

	let commodities = mapToLabelValue(useSelector(selectCommoditiesList));
	let cropsList: any = useSelector(selectCropsList);
	cropsList = cropsList.map((year) => {
		return { label: year, value: year };
	});
	const destinations = mapToLabelValue(
		useSelector(selectDestinationsList)?.data,
	);
	const futuresMonths = mapPropertyToLabelValue(
		useSelector(selectFuturesMonthOptions(CalculatedFieldsSource.Transactions)),
		'name',
	);
	const updateFuturesMonths = (commodity) => {
		dispatch(
			globalActions.loadFuturesMonthOptions({
				params: {
					commodityId: commodity?.value,
					excludeExpired: true,
				},
				source: CalculatedFieldsSource.Transactions,
			}),
		);
	};
	const handleAddBid = (bid, rowKey) => {
		bid = { ...bid, rowKey: rowKey };
		dispatch(actions.addBidRow(bid));
	};
	const handleRemoveBidRow = (rowData) => {
		dispatch(actions.bidSheetListRemoveBid(rowData.id));
	};

	const handleDiscardEdits = () => {
		setEditingKey('');
		reset();
	};

	const handleSaveEdits = (bid, rowKey) => {
		bid = { ...bid, id: rowKey };
		dispatch(actions.updateBid(bid));
		setEditingKey('');
	};

	const rows = { rows: 2 };

	const getBidChange = ({ change, newBidAdd }) => {
		if (change === 'New' || !!newBidAdd) {
			return 'new-bid-row';
		} else if (change === 'BasisValueUp') {
			return 'table-colum--green';
		} else if (change === 'BasisValueDown') {
			return 'table-colum--red';
		} else if (change === 'FuturePriceChange') {
			return 'table-colum--yellow';
		} else if (change === 'Deleted') {
			return 'table-colum-deleted--gray';
		} else {
			return '';
		}
	};
	const isEditing = (id) => {
		return id === editingKey;
	};
	const editRow = (row: Bidsheet) => {
		setEditingKey(row.id ?? '');
		reset();
		// load initial data
		updateFuturesMonths(
			commodities.find((element) => element.label === row.commodity),
		);
	};
	const handleOnChange = (page, pageSize) => {
		dispatch(
			bidSheetActions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(bidSheetActions.loadBidsheetList({ type: bidsheetListType }));
	};
	const { handleSubmit, control, reset } = useForm();
	const columns = [
		{
			title: translate(translationsScope.created),
			className: 'column-table',
			dataIndex: 'createdOn',
			key: 'createdOn',
			fixed: true,
			render: (data) => (
				<GenericTable.Column>
					<div>
						<p className="text text--bold text--large">{dateFormat(data)}</p>
						<p className="text text--label">{timeFormat(data)}</p>
					</div>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.employee),
			className: 'column-table',
			key: 'employee',
			width: 80,
			render: (data) => {
				return (
					<GenericTable.Column>
						<UserAvatar user={data.employee} />
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.crop),
			className: 'column-table',
			dataIndex: 'cropYear',
			key: 'cropYear',
			render: (data, record) => {
				const editingRow = isEditing(record.id);
				if (!!record.newRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="CropYear"
								control={control}
								render={(field) => {
									return (
										<Dropdown
											key="crop"
											placeholder="Crop Year"
											options={cropsList}
											onChange={(crop) => {
												field.onChange(crop?.value);
											}}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else if (editingRow) {
					return (
						<GenericTable.Column>
							<Controller
								defaultValue={
									cropsList.find((element) => element.label === data)?.value
								}
								name="CropYear"
								control={control}
								render={(field) => {
									return (
										<Dropdown
											defaultValue={cropsList.find(
												(element) => element.label === data,
											)}
											key="crop"
											placeholder="Crop Year"
											options={cropsList}
											onChange={(crop) => {
												field.onChange(crop?.value);
											}}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else {
					return (
						<GenericTable.Column>
							<p className="text text--large">{data}</p>
						</GenericTable.Column>
					);
				}
			},
		},
		{
			title: translate(translationsScope.commodityTitle),
			dataIndex: 'commodity',
			key: 'commodity',
			width: 150,
			render: (data, record) => {
				const editingRow = isEditing(record.id);
				if (!!record.newRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="CommodityId"
								control={control}
								render={(field) => {
									return (
										<Dropdown
											dropdownStyle={{ width: '150px' }}
											key="commodity"
											placeholder="Commodity"
											options={commodities}
											onChange={(commodity) => {
												field.onChange(commodity?.value);
												updateFuturesMonths(commodity);
											}}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else if (editingRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="CommodityId"
								control={control}
								defaultValue={
									commodities.find((element) => element.label === data)?.value
								}
								render={(field) => {
									return (
										<Dropdown
											dropdownStyle={{ width: '150px' }}
											key="commodity"
											defaultValue={commodities.find(
												(element) => element.label === data,
											)}
											options={commodities}
											onChange={(commodity) => {
												field.onChange(commodity?.value);
												updateFuturesMonths(commodity);
											}}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else {
					return (
						<GenericTable.Column>
							<p className="text text--large">{data}</p>
						</GenericTable.Column>
					);
				}
			},
		},
		{
			title: translate(translationsScope.destination),
			className: 'column-table',
			dataIndex: 'location',
			key: 'location',
			width: 180,
			render: (data, record) => {
				const editingRow = isEditing(record.id);
				if (!!record.newRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="DeliveryLocationId"
								control={control}
								render={(field) => {
									return (
										<Dropdown
											dropdownStyle={{ width: '180px' }}
											key="deliveryLocation"
											placeholder="Delivery Location"
											options={destinations}
											onChange={(location) => {
												field.onChange(location?.value);
											}}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else if (editingRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="DeliveryLocationId"
								control={control}
								defaultValue={
									destinations.find((element) => element.label === data)?.value
								}
								render={(field) => {
									return (
										<Dropdown
											defaultValue={destinations.find(
												(element) => element.label === data,
											)}
											dropdownStyle={{ width: '180px' }}
											key="deliveryLocation"
											placeholder="Delivery Location"
											options={destinations}
											onChange={(location) => {
												field.onChange(location?.value);
											}}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else {
					return (
						<GenericTable.Column>
							<Paragraph
								className="text text--large ellipsis"
								title={data}
								ellipsis={rows}
							>
								<p className="text text--large">{data}</p>
							</Paragraph>
						</GenericTable.Column>
					);
				}
			},
		},
		{
			title: translate(translationsScope.startDate),
			className: 'column-table',
			dataIndex: 'deliveryStart',
			key: 'deliveryStart',
			width: 150,
			render: (data, record) => {
				const editingRow = isEditing(record.id);
				if (!!record.newRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="DeliveryStart"
								control={control}
								render={(field) => {
									return (
										<DatePicker
											className="calendar__input"
											format={'MM/DD/YYYY'}
											{...field}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else if (editingRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="DeliveryStart"
								control={control}
								defaultValue={moment(data)}
								render={(field) => {
									return (
										<DatePicker
											className="calendar__input"
											format={'MM/DD/YYYY'}
											{...field}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else {
					return (
						<GenericTable.Column>
							<p className="text text--large">{dateFormatDelivery(data)}</p>
						</GenericTable.Column>
					);
				}
			},
		},
		{
			title: translate(translationsScope.endDate),
			className: 'column-table',
			dataIndex: 'deliveryEnd',
			key: 'deliveryEnd',
			width: 150,
			render: (data, record) => {
				const editingRow = isEditing(record.id);
				if (!!record.newRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="DeliveryEnd"
								control={control}
								render={(field) => {
									return (
										<DatePicker
											className="calendar__input"
											disabledDate={(current) =>
												current &&
												current < moment().add(-1, 'days').endOf('day')
											}
											format={'MM/DD/YYYY'}
											{...field}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else if (editingRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="DeliveryEnd"
								control={control}
								defaultValue={moment(data)}
								render={({ value, onChange, onBlur }) => {
									return (
										<Calendar
											placeholder={'End Date'}
											format={'MM/DD/YYYY'}
											value={value}
											onChange={onChange}
											onBlur={onBlur}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else {
					return (
						<GenericTable.Column>
							<p className="text text--large">{dateFormatDelivery(data)}</p>
						</GenericTable.Column>
					);
				}
			},
		},
		{
			title: translate(translationsScope.future),
			className: 'column-table',
			dataIndex: 'oldFutureMonth',
			key: 'oldFutureMonth',
			width: 100,
			render: (data, record) => {
				const editingRow = isEditing(record.id);
				if (!!record.newRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="oldFutureMonth"
								control={control}
								render={(field) => {
									return (
										<Dropdown
											key="oldFutureMonth"
											options={futuresMonths || []}
											onChange={(futureMonth) => {
												field.onChange(futureMonth?.value);
											}}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else if (editingRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="oldFutureMonth"
								control={control}
								defaultValue={
									futuresMonths && futuresMonths.length > 0
										? futuresMonths.find((element) => element.label === data)
												?.value
										: undefined
								}
								render={(field) => {
									return (
										<Dropdown
											defaultValue={
												futuresMonths && futuresMonths.length > 0
													? futuresMonths.find(
															(element) => element.label === data,
														)
													: undefined
											}
											key="oldFutureMonth"
											options={futuresMonths || []}
											onChange={(futureMonth) => {
												field.onChange(futureMonth?.value);
											}}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else {
					return (
						<GenericTable.Column>
							<Paragraph
								className="text text--large ellipsis"
								title={data}
								ellipsis={rows}
							>
								<p className="text text--large">{data}</p>
							</Paragraph>
						</GenericTable.Column>
					);
				}
			},
		},
		{
			title: translate(translationsScope.notify),
			className: 'column-table column-notify',
			dataIndex: 'notificationGroups',
			key: 'notificationGroups',
			hidden: !featureFlags[FeatureFlag.enableNotifications],
			width: 150,
			render: (data, record) => (
				<GenericTable.Column>
					{!record.newRow && !isEditing(record.id) ? (
						data?.length ? (
							<CheckCircleFilled className="notification--icon" />
						) : (
							<CloseCircleOutlined className="notification--icon" />
						)
					) : (
						<Controller
							name="notificationGroupIds"
							control={control}
							defaultValue={data?.map((item) => item.id) || []}
							render={(field) => (
								<Select
									key="notificationGroupIds"
									mode="multiple"
									placeholder={translate(translationsScope.selectGroups)}
									loading={groupsLoading}
									maxTagCount={0}
									maxTagPlaceholder={(values) =>
										translate(translationsScope.totalSelected, {
											total: values.length,
										})
									}
									value={field.value || []}
									optionFilterProp="label"
									labelInValue={false}
									dropdownMatchSelectWidth={false}
									onChange={(selectedIds) => {
										field.onChange(selectedIds || []);
									}}
								>
									{groups.map((group) => (
										<Select.Option
											key={group.id}
											value={group.id}
											label={group.name}
										>
											<Tooltip title={group.type}>
												<Tag>{group.type.charAt(0)}</Tag>
											</Tooltip>
											{group.name}
										</Select.Option>
									))}
								</Select>
							)}
						/>
					)}
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.newFuture),
			className: 'column-table',
			dataIndex: 'futureMonth',
			key: 'futureMonth',
			width: 100,
			render: (data, record) => {
				const editingRow = isEditing(record.id);
				if (!!record.newRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="futureMonth"
								control={control}
								render={(field) => {
									return (
										<Dropdown
											key="futureMonth"
											placeholder="New Future Month"
											options={futuresMonths || []}
											onChange={(futureMonth) => {
												field.onChange(futureMonth?.value);
											}}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else if (editingRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="futureMonth"
								control={control}
								defaultValue={
									futuresMonths && futuresMonths.length > 0
										? futuresMonths.find((element) => element.label === data)
												?.value
										: undefined
								}
								render={(field) => {
									return (
										<Dropdown
											defaultValue={
												futuresMonths && futuresMonths.length > 0
													? futuresMonths.find(
															(element) => element.label === data,
														)
													: undefined
											}
											key="futureMonth"
											options={futuresMonths || []}
											onChange={(futureMonth) => {
												field.onChange(futureMonth?.value);
											}}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else {
					return (
						<GenericTable.Column>
							<Paragraph
								className="text text--large ellipsis"
								title={data}
								ellipsis={rows}
							>
								<p className="text text--large">{data}</p>
							</Paragraph>
						</GenericTable.Column>
					);
				}
			},
		},
		{
			title: translate(translationsScope.basis),
			className: 'column-table',
			dataIndex: 'oldBasis',
			key: 'oldBasis',
			width: 100,
			fixed: 'right' as 'right',
			render: (data, record) => {
				const editingRow = isEditing(record.id);
				if (!!record.newRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="oldBasis"
								control={control}
								render={(field) => {
									return (
										<InputNumber
											style={{
												width: 100,
											}}
											step={0.01}
											{...field}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else if (editingRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="oldBasis"
								control={control}
								defaultValue={data}
								render={(field) => {
									return (
										<InputNumber
											style={{
												width: 100,
											}}
											step={0.01}
											{...field}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else {
					return (
						<GenericTable.Column>
							<p className="text text--large">{data}</p>
						</GenericTable.Column>
					);
				}
			},
		},
		{
			title: translate(translationsScope.newBasis),
			className: 'column-table',
			dataIndex: 'basis',
			key: 'basis',
			width: 100,
			fixed: 'right' as 'right',
			render: (data, record) => {
				const editingRow = isEditing(record.id);
				if (!!record.newRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="basis"
								control={control}
								render={(field) => {
									return (
										<InputNumber
											style={{
												width: 100,
											}}
											step={0.01}
											{...field}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else if (editingRow) {
					return (
						<GenericTable.Column>
							<Controller
								name="basis"
								control={control}
								defaultValue={data}
								render={(field) => {
									return (
										<InputNumber
											style={{
												width: 100,
											}}
											step={0.01}
											{...field}
										/>
									);
								}}
							/>
						</GenericTable.Column>
					);
				} else {
					return (
						<GenericTable.Column>
							<p className="text text--large">{data}</p>
						</GenericTable.Column>
					);
				}
			},
		},
		{
			title: translate(translationsScope.change),
			className: 'column-table',
			dataIndex:
				bidsheetListType === TableTypes.Filter ? 'difference' : 'change',
			key: bidsheetListType === TableTypes.Filter ? 'difference' : 'change',
			width: 120,
			fixed: 'right' as 'right',
			render: (data) => (
				<GenericTable.Column>
					<div className="change-column-container">
						<p className="text text--large">{data}</p>
						{data > 0 ? (
							<span className="change-column-icon">
								<ArrowUpOutlined />
							</span>
						) : data < 0 ? (
							<span className="change-column-icon">
								<ArrowDownOutlined />
							</span>
						) : (
							''
						)}
					</div>
				</GenericTable.Column>
			),
		},
		{
			title: 'Action',
			className: 'column-table',
			width: 90,
			fixed: 'right' as 'right',
			render: (rowData, record) => {
				const editingRow = isEditing(record.id);
				if (!!record.newRow || editingRow) {
					return (
						<>
							<ConfirmDialog
								data-testid={'confirm-bid-save-dialog'}
								disabled={false}
								placement={'bottomRight'}
								message={
									editingRow
										? 'Do you want to save edits for this bid?'
										: 'Do you want to save this bid?'
								}
								handleConfirm={handleSubmit((formData) => {
									if (editingRow) {
										handleSaveEdits(formData, rowData.id);
									} else {
										handleAddBid(formData, rowData.id);
									}
								})}
								confirmText={'Yes'}
								cancelText={'No'}
								withOverlay={false}
								trigger={
									<SaveOutlined
										className="action-icon"
										data-testid={'save-bid-icon'}
									/>
								}
							/>

							<ConfirmDialog
								data-testid={'discard-bid-dialog'}
								disabled={false}
								placement={'bottomRight'}
								message={
									editingRow
										? 'Do you want to discard edits for this bid?'
										: 'Do you want to discard this bid?'
								}
								handleConfirm={handleSubmit(() => {
									if (editingRow) {
										handleDiscardEdits();
									} else {
										handleRemoveBidRow(rowData);
									}
								})}
								confirmText={'Yes'}
								cancelText={'No'}
								withOverlay={false}
								trigger={
									<CloseOutlined
										className="action-icon"
										data-testid={'discard-bid-icon'}
									/>
								}
							/>
						</>
					);
				} else if (record.change === 'Deleted') {
					return <></>;
				} else {
					return (
						<Space size="small">
							<Tooltip {...noPermissionTooltipProps}>
								<div>
									<Button
										data-testid={'edit-bid-icon'}
										type="text"
										className="action-icon"
										onClick={() => editRow(record)}
										disabled={!hasBidsheetUploadPermission}
									>
										<EditFilled data-testid={'edit-button-1'} />
									</Button>
								</div>
							</Tooltip>
							<Tooltip {...noPermissionTooltipProps} placement="topRight">
								<div>
									<Delete
										rowData={rowData}
										disabled={!hasBidsheetUploadPermission}
									/>
								</div>
							</Tooltip>
						</Space>
					);
				}
			},
		},
		{
			dataIndex: 'id',
			key: 'id',
			hidden: true,
		},
	].filter((column) => !column.hidden);

	return (
		<>
			<GenericTable.Table<any>
				otherClassName="table-container bidsheet-table"
				columns={columns}
				loading={listLoading}
				data={listData}
				rowKey={'id'}
				pagination={{
					total: listTotal,
					pageSize: pagination.limit,
					defaultPageSize: pagination.limit,
					current: pagination.start,
					onChange: handleOnChange,
				}}
				rowClassName={getBidChange}
			/>
			<Modal
				title="Validation Errors"
				visible={isModalVisible}
				onOk={closeModal}
				onCancel={closeModal}
			>
				{validationErrors?.map((error) => {
					return (
						<ul className="error-list">
							<li>
								<b>{error?.Field}:</b> {error?.Message}
							</li>
						</ul>
					);
				})}
			</Modal>
		</>
	);
};
