const getCustomHeaderLabel = (headers: Object, label: string) =>
	headers[label] || '';

export const getColumnTitle = (
	hasDynamicHeaderLabel: boolean,
	headers: Object,
	label: string,
) => (hasDynamicHeaderLabel ? getCustomHeaderLabel(headers, label) : label);

export const getCellSchemaStyles = (cellSchemaStyle: string | undefined) =>
	!!cellSchemaStyle ? JSON.parse(cellSchemaStyle) : {};
