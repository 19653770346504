import './style.scss';

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { ContractsFilters } from 'app/containers/Contracts/types';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { ReviewAndReleaseFilters } from 'app/containers/ReviewAndRelease/types';
import { OffersFilters } from 'types/Offer';
import { UpdatePersistedData } from 'utils/helpers';
import { initialFilters } from 'utils/initial-filters';

type ClearFiltersProps = {
	activeFilterCount: number;
	localStorageKey: string;
	seFilters: {
		payload: ReviewAndReleaseFilters | OffersFilters | ContractsFilters | null;
		type: string;
	};
	loadGrid: { payload: boolean | undefined; type: string };
	grid: keyof typeof initialFilters;
};

export const ClearFilters = memo<ClearFiltersProps>(
	({ activeFilterCount, localStorageKey, seFilters, loadGrid, grid }) => {
		const dispatch = useDispatch();

		const clearFilters = () => {
			if (activeFilterCount === 0) {
				dispatch(
					globalActions.addNotification({
						type: CustomNotificationTypes.WARNING,
						message: 'There are no filters to be removed!',
						showDescription: true,
					}),
				);
				return;
			}
			UpdatePersistedData(localStorageKey, initialFilters[grid]);
			dispatch(seFilters);
			dispatch(loadGrid);
		};
		return (
			<div onClick={clearFilters} className="clear-filter">
				Clear Filters ({activeFilterCount})
			</div>
		);
	},
);
