import './style.scss';

import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'app/containers/PreHedge/slice';
import { translations } from 'locales/i18n';

import { selectActivePreHedge } from '../../../../../selectors';

interface Props {
	defaultMessageWithNumber?: string;
	defaultMessage?: string;
	titleMessage?: string;
}

export const ReduceQuantityModal = (props: Props) => {
	const {
		defaultMessageWithNumber = '',
		defaultMessage = '',
		titleMessage = '',
	} = props;

	const cancelOrderText =
		translations.app.containers.OrderContainer.components.CancelOrder;

	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const activeContract = useSelector(selectActivePreHedge);

	const setContractText = () => {
		const number = activeContract?.contract?.number ?? activeContract?.number;

		if (number) {
			return (
				<p className="pre-hedge-reduce-quantity-modal__text--bold">
					{translate(defaultMessageWithNumber, { number })}
				</p>
			);
		} else {
			return <p>{translate(defaultMessage)}</p>;
		}
	};
	const handleConfirmation = () => {
		activeContract && dispatch(actions.cancelPreHedge(activeContract.id));
		dispatch(actions.setCurrentModal(null));
	};

	const handleCancel = () => {
		dispatch(actions.setCurrentModal(null));
	};

	return (
		<Modal
			maskClosable={false}
			className="pre-hedge-reduce-quantity-modal"
			title={translate(titleMessage)}
			visible={true}
			onOk={handleConfirmation}
			onCancel={handleCancel}
			footer={[
				<Button
					key="back"
					onClick={handleCancel}
					className="return-button"
					data-testid="return-button"
				>
					{translate(cancelOrderText.return)}
				</Button>,
				<Button
					data-testid="confirm-button"
					key="submit"
					type="primary"
					onClick={handleConfirmation}
					className="cancel-button"
				>
					{translate(cancelOrderText.confirm)}
				</Button>,
			]}
		>
			{activeContract && setContractText()}
		</Modal>
	);
};
