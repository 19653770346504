import React from 'react';

import { translations } from 'locales/i18n';

import { ReduceQuantityModal } from '../shared/GenericComponents/ReduceQuantityModal';

export const CancelModal = () => {
	const orderTableText =
		translations.app.containers.OrderContainer.components.OrdersTable;
	const cancelOrderText =
		translations.app.containers.OrderContainer.components.CancelOrder;
	return (
		<ReduceQuantityModal
			defaultMessageWithNumber={orderTableText.cancelMessage}
			defaultMessage={orderTableText.defaultCancelMessage}
			warningMessage={cancelOrderText.warningMessage}
			titleMessage={orderTableText.cancelContract}
			maxAmountMessage={cancelOrderText.maxAmount}
			comments={orderTableText.comments}
			showCommentsField
		/>
	);
};
