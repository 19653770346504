import './style.scss';

import { PayloadAction } from '@reduxjs/toolkit';
import { Button, Checkbox, Popover } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { translations } from 'locales/i18n';
import { FixedColumn } from 'types/FixedColumn';
import { GenericOption } from 'types/GenericOption';
import { UpdatePersistedData } from 'utils/helpers';

interface FixedColumnsProps<T extends Record<string, FixedColumn>> {
	persistedDataKey: string;
	fixedColumns: T;
	setFixedColumns: (fixedColumns: T) => PayloadAction<T>;
	columns: string[];
	mapOptions: (values: string[]) => GenericOption[];
}

export const FixedColumns = memo(
	<T extends Record<string, FixedColumn>>({
		persistedDataKey,
		fixedColumns,
		setFixedColumns,
		columns,
		mapOptions,
	}: FixedColumnsProps<T>) => {
		const [allFixedColumns, setAllFixedColumns] = useState<GenericOption[]>([]);

		useEffect(() => {
			setAllFixedColumns(mapOptions(columns));
		}, [columns, mapOptions]);

		const dispatch = useDispatch();
		const { t: translate } = useTranslation();

		const handleSetFixedColumns = (option: GenericOption) => {
			const newFixedColumns = {
				...fixedColumns,
				[option.value]: fixedColumns[option.value] === 'left' ? null : 'left',
			};
			UpdatePersistedData(persistedDataKey, fixedColumns, newFixedColumns);
			dispatch(setFixedColumns(newFixedColumns));
		};

		return (
			<Popover
				content={
					<div className="grid-menu-dropdown">
						{allFixedColumns.map((option) => (
							<Checkbox
								key={option.value}
								data-testid={`filter-multiple-${option.label}`}
								className="checkbox--no-border-radius"
								checked={fixedColumns[option.value] === 'left'}
								onChange={() => handleSetFixedColumns(option)}
							>
								{option.label}
							</Checkbox>
						))}
					</div>
				}
				trigger="click"
			>
				<Button type="link" className="column-pref">
					<span>{translate(translations.app.commons.pinnedColumnsLabel)}</span>
				</Button>
			</Popover>
		);
	},
);
