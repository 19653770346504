import './styles.scss';

import React from 'react';

import { GenericForm } from 'app/components/GenericForm';

import { useToggleFormData } from './hooks/useToggleFormData';

export const ToggleForm = () => {
	const { elements, formInstance, validationSchema } = useToggleFormData();

	return (
		<GenericForm.Form
			className="toggle-form"
			formInstance={formInstance}
			key="orderEntryEnabledForm"
			validationSchema={validationSchema}
		>
			{elements}
		</GenericForm.Form>
	);
};
