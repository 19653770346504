import { z } from 'zod';

import { cropYearSchema } from 'types/CropYear';

const _commoditySchema = z.object({
	id: z.string().uuid(),
	name: z.string(),
	lotFactor: z.number(),
	cropYears: z.array(cropYearSchema).nullable(),
});
export interface Commodity extends z.infer<typeof _commoditySchema> {}
export const commoditySchema: z.Schema<Commodity> = _commoditySchema;

export interface UploadResponse {
	data: any;
	status: any;
	statusText: any;
}
