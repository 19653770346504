/**
 *
 * VirtualScroll
 *
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface Props<T> {
	onEndOfList: (data: T[]) => void;
	list: T[];
	children?: JSX.Element;
	sliceUpdate: number;
}

// 200% of the screen scroll, 60px each row
const VIEWABLE_RECORDS_RATIO = 2 / 60;

export function VirtualScroll<T>(props: Props<T>) {
	const { list: fullList, onEndOfList, sliceUpdate, children = <></> } = props;

	const fullListRef = useRef<T[] | null>(null);
	const listRef = useRef<T[] | null>(null);
	const dataRef = useRef<T[] | null>(null);
	const tableRef = useRef<HTMLDivElement | null>(null);
	const lastRecordsRef = useRef<HTMLTableRowElement[] | null>(null);

	const viewableRecordsRef = useRef(0);
	const allLoadedRef = useRef(false);
	const observerRef = useRef<IntersectionObserver | null>(null);

	const [slice, setSlice] = useState(1);

	const ref = useCallback(
		(node) => {
			if (node) {
				if (!tableRef.current) tableRef.current = node;

				if (!viewableRecordsRef.current)
					viewableRecordsRef.current = Math.floor(
						(tableRef.current?.parentNode as HTMLElement).scrollHeight *
							VIEWABLE_RECORDS_RATIO,
					);

				if (!observerRef.current)
					observerRef.current = new IntersectionObserver(
						(records) => {
							records.forEach((record) => {
								if (record.isIntersecting) {
									setSlice((slice) => slice + 1);
									observerRef.current?.disconnect();
								}
							});
						},
						{
							root: tableRef.current?.parentNode as HTMLElement,
							rootMargin: '100px',
							threshold: 0,
						},
					);

				fullListRef.current = fullList;
				if (fullList?.length <= 20) {
					onEndOfList(fullList);
				} else if (fullList?.length > 20) {
					setSlice(1);
					allLoadedRef.current = false;
					listRef.current = fullList;
					dataRef.current = listRef.current.slice(0, 22);
					onEndOfList(dataRef.current);
				}
			} else {
				observerRef.current?.disconnect();
			}
		},
		// eslint-disable-next-line
		[fullList],
	);

	useEffect(() => {
		if (slice > 1) {
			const newLimit = viewableRecordsRef.current * slice;
			if (listRef.current && listRef.current.length >= newLimit) {
				dataRef.current = listRef.current.slice(0, newLimit);
				onEndOfList(dataRef.current);
			} else {
				dataRef.current = listRef.current;
				onEndOfList(dataRef.current ?? []);
				allLoadedRef.current = true;
			}
		}
		// eslint-disable-next-line
	}, [slice]);

	useEffect(() => {
		if (sliceUpdate > 20) {
			if (!allLoadedRef.current && tableRef.current) {
				const collection = tableRef.current.querySelectorAll('tr');
				const length: number = collection.length;
				const lastRows = [
					collection[length - 5],
					collection[length - 4],
					collection[length - 3],
					collection[length - 2],
					collection[length - 1],
				];
				lastRecordsRef.current = lastRows;
				if (lastRecordsRef.current.length) {
					lastRecordsRef.current.forEach((record) => {
						observerRef.current?.observe(record);
					});
				}
			}
		}
	}, [sliceUpdate]);

	return (
		<div className="observer-target" ref={ref}>
			{children}
		</div>
	);
}
