import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import moment from 'moment';
import React, { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import {
	FormButtonsProps,
	FormSchemaDefinition,
} from 'app/components/GenericForm/types';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import {
	selectActivePreHedge,
	selectActivePreHedgeLoading,
} from 'app/containers/PreHedge/selectors';
import { actions } from 'app/containers/PreHedge/slice';
import { EditPrehedgeRequestBody } from 'app/containers/PreHedge/types';
import { SourceContext } from 'app/containers/Transactions';
import { ModalHeader } from 'app/containers/Transactions/components/ModalHeader';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { DATE_FORMAT, PRE_HEDGE_ORDER_TYPES } from 'utils/constants';
import { isActionSell, isMarketOrder } from 'utils/helpers';

import { useTranslations } from '../shared/useTranslations';
import { usePreHedgeSchema } from './schemas/PreHedge';

export const EditModal = memo(function EditModal() {
	const dispatch = useDispatch();

	const translations = useTranslations();

	const [closeAfterSubmit, setCloseAfterSubmit] = useState(true);
	const isLoading = useSelector(selectActivePreHedgeLoading);
	const preHedgeData = useSelector(selectActivePreHedge);

	const {
		editPreHedgeTitle,
		editConfirmationMessage: preHedgeEditConfirmationMessage,
		confirmText,
		cancelText,
	} = translations.actions;

	const preHedgeSchemas = usePreHedgeSchema();
	const preHedgeType =
		preHedgeData?.type === 'Limit'
			? PRE_HEDGE_ORDER_TYPES.limitOrder
			: PRE_HEDGE_ORDER_TYPES.marketOrder;
	const [currentSchema] = useState<FormSchemaDefinition>(
		preHedgeSchemas[preHedgeType],
	);

	const resolver = yupResolver(currentSchema?.validationSchema);

	const formInstance = useForm({
		defaultValues: {
			...currentSchema.initialValues,
		},
		resolver,
		mode: 'all',
	});

	const cleanState = () => {
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'order-form__new-order',
			type: 'default',
			htmlType: 'button',
			children: translations.buttons.cancelChanges,
			onClick: handleClose,
			disabled: isLoading,
			key: 'edit',
		},
		{
			htmlType: 'submit',
			children: translations.buttons.submitPreHedge,
			disabled: isLoading,
			key: 'submit',
		},
	];

	const header = (
		<ModalHeader
			title={editPreHedgeTitle}
			content={`${preHedgeEditConfirmationMessage} ${translations.actions.confirmation}`}
			confirmText={confirmText}
			cancelText={cancelText}
			handleConfirm={handleClose}
		/>
	);

	const handleSubmit = (values) => {
		const data: EditPrehedgeRequestBody = {
			id: preHedgeData?.id,
			isMarketOrder: isMarketOrder(values.preHedgeOrderType.value),
			isSell: isActionSell(values.action),
			commodityId: values.commodity.value,
			cropYear: values.cropYear,
			futuresMonth: values.futuresMonth?.value ?? values.futuresMonth,
			lots: values.numberOfLots ? parseFloat(values.numberOfLots) : 0,
			futuresPrice: values?.orderPrice ? parseFloat(values?.orderPrice) : 0,
			futuresAccountId: values?.hedgeAccount?.id,
			gtc: values.gtcMode,
			expiration: values.expirationDateGTC?.length
				? moment(values.expirationDateGTC).format(DATE_FORMAT)
				: null,
			comments: values.comments,
			unfilledLots: values?.unfilledLots,
		};

		if (closeAfterSubmit) {
			cleanState();
		}
		dispatch(
			actions.updatePreHedge({
				data,
				successMessage: translations.common.success,
			}),
		);
		setCloseAfterSubmit(true);
	};

	return (
		<GenericForm.ModalContainer
			key="customerModal"
			className="create-form__modal"
			title={header}
			onCancel={handleClose}
			closable={false}
			destroyOnClose={true}
			keyboard={false}
		>
			<SourceContext.Provider value={GlobalSagaSource.preHedgeModal}>
				<GenericForm.Form
					className="order-form"
					key="customerForm"
					formInstance={formInstance}
					validationSchema={currentSchema?.validationSchema}
					onSubmit={handleSubmit}
					buttonsDefinition={btnsDefinition}
				>
					{currentSchema?.elements}
				</GenericForm.Form>
			</SourceContext.Provider>
		</GenericForm.ModalContainer>
	);
});
