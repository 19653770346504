import { UploadResponse } from 'types/Commodity';
import { GenericError } from 'types/GenericError';

/* --- STATE --- */
export interface HedgeMappingState {
	currentView: View;
	currentModal: HedgeMappingModals | null;
	list: {
		data: [];
		loading: boolean;
		error: GenericError | null;
	};
	hedgeMapTemplate: {
		data: any;
		uploadResponse: UploadResponse;
		loading: boolean;
		error: GenericError | null;
	};
	hedgeMapTemplateUpdate: {
		data: any;
		uploadResponse: UploadResponse;
		loading: boolean;
		error: GenericError | null;
	};
	regionInfo: RegionInfo;

	submitHedgeMapToServer: {
		data: null;
		uploadResponse: { data: null; status: null; statusText: null };
		loading: boolean;
		error: null;
	};
	downloadFilledHedgemapExcel: {
		url: any;
		loading: boolean;
		error: GenericError | null;
	};
	downloadFilledHedgemapRequest: {
		commodityId: string;
		productId?: string;
		startDate?: any;
		endDate?: any;
		months?: number;
		hasFourthCrop?: boolean;
	};
}

export enum View {
	List = 'list',
}

export enum HedgeMappingModals {
	Upload = 'Upload',
}

export interface HedgeMappingRequest {
	commodityId: string;
	regionId: string;
	errorTitle: string;
	errorDescription: string;
}

export interface RegionInfo {
	commodityId: string;
	regionId: string;
}

export type HedgeMappingSectionState = HedgeMappingState;
