import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';
import { ERPIntegrationState } from './types';

const selectDomain = (state: RootState) => state.erpIntegration || initialState;

export const selectERPIntegration = createSelector(
	[selectDomain],
	(erpIntegrationState: ERPIntegrationState) =>
		erpIntegrationState.erpIntegration.data,
);

export const selectERPIntegrationLoading = createSelector(
	[selectDomain],
	(erpIntegrationState: ERPIntegrationState) =>
		erpIntegrationState.erpIntegration.loading,
);

export const selectHedgeIntegration = createSelector(
	[selectDomain],
	(erpIntegrationState: ERPIntegrationState) =>
		erpIntegrationState.hedgeIntegration.data,
);

export const selectHedgeIntegrationLoading = createSelector(
	[selectDomain],
	(erpIntegrationState: ERPIntegrationState) =>
		erpIntegrationState.hedgeIntegration.loading,
);

export const selectERPTypes = createSelector(
	[selectDomain],
	(erpIntegrationState: ERPIntegrationState) =>
		erpIntegrationState.erpTypes.data,
);

export const selectERPTypesLoading = createSelector(
	[selectDomain],
	(erpIntegrationState: ERPIntegrationState) =>
		erpIntegrationState.erpTypes.loading,
);
