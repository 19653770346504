import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from 'locales/i18n';

import { CreateNotificationGroup } from '../../components/CreateNotificationGroup';
import { DeleteNotificationGroup } from '../../components/DeleteNotificationGroup';
import { EditNotificationGroup } from '../../components/EditNotificationGroup';
import { ListNotificationGroups } from '../../components/ListNotificationGroups';
import { useEmployeeGroupCreate } from '../../hooks/useEmployeeGroupCreate';
import { useEmployeeGroupDelete } from '../../hooks/useEmployeeGroupDelete';
import { useEmployeeGroupEdit } from '../../hooks/useEmployeeGroupEdit';
import { useEmployeeGroupList } from '../../hooks/useEmployeeGroupList';
import { selectCurrentModal, selectCurrentView } from '../../selectors';
import { actions } from '../../slice';
import { Modal, View } from '../../types';

export const EmployeeGroupNotificationSettings = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.NotificationSettings;
	const employeesTitle = translate(translationsScope.EmployeesTitle);

	const dispatch = useDispatch();
	const currentModal = useSelector(selectCurrentModal);
	const currentView = useSelector(selectCurrentView);

	useEffect(() => {
		return () => {
			dispatch(actions.setCurrentView(View.List));
		};
	}, [dispatch]);

	return (
		<>
			{currentView === View.Create ? (
				<CreateNotificationGroup
					useGroupsHook={useEmployeeGroupCreate}
					nonMembersTitle={employeesTitle}
				/>
			) : currentView === View.Edit ? (
				<EditNotificationGroup
					useGroupsHook={useEmployeeGroupEdit}
					nonMembersTitle={employeesTitle}
				/>
			) : (
				<ListNotificationGroups useGroupsHook={useEmployeeGroupList} />
			)}
			{currentModal === Modal.Delete && (
				<DeleteNotificationGroup useGroupsHook={useEmployeeGroupDelete} />
			)}
		</>
	);
};
