import React from 'react';
import { useSelector } from 'react-redux';

import { RolesLocationsEmployee } from 'app/containers/Settings/sections/UserRoles/View/CreateEditRole/components/RoleLocationsEmployee';
import { RoleSettings } from 'app/containers/Settings/types';

import { selectEmployeeLocations } from '../../../selectors';

const generateRolesLocations = (formFields) => {
	return <RolesLocationsEmployee {...formFields} />;
};

const useRolesLocationsByEmployee = () => {
	const employeeList = useSelector(selectEmployeeLocations);

	let initialValues = employeeList.reduce(
		(locations, location) => ({
			...locations,
			[location.locationId]: {
				CanBuy: location.canBuy,
				CanSell: location.canSell,
				LocationId: location.locationId,
				LocationName: location.locationName,
			},
		}),
		{},
	);

	const locationsRoles = {
		name: 'rolesLocations',
		key: 'rolesLocations',
		initialValue: initialValues,
		locationsListData: employeeList,
		section: RoleSettings.LOCATIONS,
		render: generateRolesLocations,
	};

	return {
		rolesComponent: locationsRoles,
	};
};

export { useRolesLocationsByEmployee };
