// import { PayloadAction } from '@reduxjs/toolkit';
import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { actions } from 'app/containers/GlobalSaga/slice';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { contractTypeSchema } from '../types';

const { ntcAdmin } = apiEndpoints;

export function* getNtcAdminSaga() {
	const requestURL = new URL(`${apiRoutes.base}/${ntcAdmin}`);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(contractTypeSchema),
	});

	if (!!data) {
		yield* put(actions.getNtcAdminSuccess(data));
	} else if (!!error?.detail) {
		yield* put(actions.getNtcAdminError(error));
	}
}
