/**
 *
 * Table
 *
 */
import './style.scss';

import { Card } from 'antd';
import React, { memo } from 'react';

import { ListMenu } from './ListMenu';
import { TableWrapper } from './TableWrapper';

export const List = memo(function List() {
	return (
		<Card bordered={true} className="offers-wrapper">
			<ListMenu />
			<TableWrapper />
		</Card>
	);
});
