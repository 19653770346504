import './style.scss';

import {
	ArrowsAltOutlined,
	CloseOutlined,
	ShrinkOutlined,
} from '@ant-design/icons';
import { Modal, Row } from 'antd';
import classNames from 'classnames';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import React, { memo, useState } from 'react';
import * as Yup from 'yup';

import { ConfirmDialog } from 'app/components/ConfirmDialog';

import { wizardSchemaGenerator } from './generator';
import { TabGenerator } from './TabGenerator';
import { Dialog, WizardTab } from './types';
import { WizardButtons } from './WizardButtons';

interface Props {
	wizardDefinition: WizardTab[];
	handleSubmit: (values) => void;
	handleClose: () => void;
	hookSchema?: any;
	hookParams?: any;
	hookHedgeMap?: any;
	confirmDialog?: Dialog;
	hasAdditionalCropYear?: boolean;
	isEdit?: boolean;
	handleGenerateExcelForEdit?: (values) => void;
	handleGenerateBrokerMappingForEdit?: (values, actions) => void;
}

export const ModalWizard = memo(function ModalWizard(props: Props) {
	const {
		wizardDefinition,
		handleSubmit,
		handleClose,
		hookSchema,
		hookParams,
		confirmDialog,
		hasAdditionalCropYear = false,
		isEdit = false,
		handleGenerateExcelForEdit = () => {},
		handleGenerateBrokerMappingForEdit = () => {},
	} = props;

	const [activeTabIndex, setActiveFormIndex] = useState(0);
	const [isExpanded, setIsExpanded] = useState(false);
	const isLastStepActive = activeTabIndex === wizardDefinition.length - 1;

	const { initialValues, tabsElements, tabsValidationSchemas } =
		wizardSchemaGenerator(wizardDefinition);

	const mapFormHeaders = wizardDefinition.map((data) => {
		return data.name;
	});

	const formattedValidationSchema = Yup.object().shape(
		tabsValidationSchemas[activeTabIndex],
	);

	const formElements = tabsElements[activeTabIndex];

	const handleChangeStep = (values, actions) => {
		if (isEdit) {
			if (activeTabIndex === 1) {
				handleGenerateExcelForEdit(values);
			}
			if (activeTabIndex === 2) {
				handleGenerateBrokerMappingForEdit(values, actions);
			}
		}
		if (isLastStepActive && hasAdditionalCropYear) {
			return handleSubmit(values);
		}
		if (isLastStepActive && !hasAdditionalCropYear) {
			return handleSubmit(values);
		}
		if (!isLastStepActive && hasAdditionalCropYear) {
			setActiveFormIndex(activeTabIndex + 1);
			actions.setTouched({});
			actions.setSubmitting(false);
			return;
		}
		if (!isLastStepActive && !hasAdditionalCropYear && isEdit) {
			return handleSubmit(values);
		}
		setActiveFormIndex(activeTabIndex + 1);
		actions.setTouched({});
		return actions.setSubmitting(false);
	};

	const handlePrevState = () => {
		setActiveFormIndex(activeTabIndex - 1);
	};

	const handleResizeSection = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={formattedValidationSchema}
			onSubmit={handleChangeStep}
		>
			{(formikProps) => {
				!!hookSchema && hookSchema(formikProps, hookParams);

				const { isSubmitting } = formikProps;
				return (
					<Modal
						closable={false}
						maskClosable={false}
						destroyOnClose={true}
						width={isExpanded ? '100%' : 650}
						className={classNames(
							'generic-modal-form',
							'commodity-wizard',
							`commodity-wizard__form-${activeTabIndex}`,
							{
								'commodity-wizard__form-expanded': isExpanded,
							},
						)}
						title={
							<>
								<TabGenerator
									headers={mapFormHeaders}
									activeIndex={activeTabIndex}
									disable={true}
								/>
								{confirmDialog && (
									<ConfirmDialog
										placement="bottomRight"
										message={confirmDialog.content}
										handleConfirm={handleClose}
										confirmText={confirmDialog.confirmText}
										cancelText={confirmDialog.cancelText}
										trigger={<CloseOutlined />}
									/>
								)}
							</>
						}
						visible={true}
						footer={
							<WizardButtons
								handlePrev={handlePrevState}
								activeTabIndex={activeTabIndex}
								isLastStepActive={isLastStepActive}
								disabled={isSubmitting}
								isAddingAdditionalCropYear={hasAdditionalCropYear}
								isEdit={isEdit}
							/>
						}
						onCancel={handleClose}
					>
						<Row justify="end">
							{!isExpanded ? (
								<ArrowsAltOutlined
									onClick={handleResizeSection}
									className="expand__icon"
								/>
							) : (
								<ShrinkOutlined
									onClick={handleResizeSection}
									className="expand__icon"
								/>
							)}
						</Row>

						<Form
							labelCol={{ span: 4 }}
							wrapperCol={{ span: 8 }}
							name="genericWizardForm"
						>
							{formElements}
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
});
