/**
 *
 * Details
 *
 */
import './style.scss';

import { CloseOutlined } from '@ant-design/icons';
import { Card, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	ContractsModals,
	ContractsViews,
} from 'app/containers/Contracts/types';
import { Filter } from 'app/containers/Transactions/components/Filter';
import { History } from 'app/containers/Transactions/components/History';
import { ListWrapper } from 'app/containers/Transactions/components/ListWrapper';
import { Summary } from 'app/containers/Transactions/components/Summary';
import { SummaryView } from 'app/containers/Transactions/types';
import { translations } from 'locales/i18n';

import {
	selectActiveContractDetails,
	selectContractAllLoaded,
	selectContractList,
	selectContractListLoading,
	selectCurrentView,
	selectPagination,
	selectTotalContracts,
} from '../../../selectors';
import { actions } from '../../../slice';

export const Details = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();

	const contractList = useSelector(selectContractList);
	const contractListLoading = useSelector(selectContractListLoading);
	const contractAllLoaded = useSelector(selectContractAllLoaded);
	const activeContractDetails = useSelector(selectActiveContractDetails);
	const currentView = useSelector(selectCurrentView);
	const pagination = useSelector(selectPagination);
	const total = useSelector(selectTotalContracts);
	const loading = activeContractDetails.loading;
	const emptyList = !!contractList && contractList.length === 0;

	const loadContractSummary = (item) => {
		dispatch(actions.loadContractSummary({ id: item.id }));
	};

	const loadContractList = () => {
		dispatch(actions.loadContractList());
	};

	const handleCloseDetails = () => {
		dispatch(
			actions.setSelectedFilters({
				contractOrCustomer: '',
			}),
		);
		dispatch(actions.setCurrentView(null));
	};

	const handleSearch = (searchText) => {
		dispatch(actions.setActiveOrderDetails(null));
		dispatch(
			actions.setSelectedFilters({
				contractOrCustomer: searchText,
			}),
		);
		dispatch(actions.loadContractList());
	};

	const handleCreate = () => {
		dispatch(actions.setCurrentModal(ContractsModals.Create));
	};

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadContractList());
	};

	return (
		<div className="contract-details">
			{currentView === ContractsViews.Details && (
				<Card className="left-panel" bordered={false}>
					<div className="left-panel__filter">
						<Filter
							handleSearch={handleSearch}
							handleCreate={handleCreate}
							placeholder={translate(
								translations.app.containers.Details.Filter.placeholder
									.contractCustomer,
							)}
						/>
					</div>
					<ListWrapper
						list={contractList}
						listLoading={contractListLoading}
						listLoaded={contractAllLoaded}
						activeItem={activeContractDetails}
						loadItemSummary={loadContractSummary}
						loadItemList={loadContractList}
						transactionType="contract"
						pagination={{
							showTotal: (total, range) =>
								translate(translations.app.components.GenericTable.showTotal, {
									range1: range[0],
									range2: range[1],
									total: total,
								}),
							onChange: (page, pageSize) => handleOnChange(page, pageSize),
							total: total,
							pageSize: pagination.limit,
							currentPage: pagination.start,
							defaultCurrent: pagination.start,
							showSizeChanger: false,
							showLessItems: true,
							role: 'list-pagination',
							size: 'small',
						}}
					/>
				</Card>
			)}

			<div className="right-panel">
				<Card className="right-panel__summary" bordered={false}>
					{emptyList && (
						<div className="close-icon">
							<CloseOutlined
								data-testid="details-close-icon"
								onClick={handleCloseDetails}
							/>
						</div>
					)}
					<Summary
						activeView={SummaryView.contracts}
						activeEntity={activeContractDetails}
						listLoading={contractListLoading}
						isCollapsable={true}
						handleClose={handleCloseDetails}
					/>
				</Card>
				{!emptyList && (
					<Card
						bordered={true}
						className="right-panel__children"
						data-testid="children-table"
					>
						{loading ? (
							<div className="contract-details__spinner-container">
								<Spin key="spinner" />
							</div>
						) : (
							<History
								transactionType="Contract"
								activeTransactionDetails={activeContractDetails}
							/>
						)}
					</Card>
				)}
			</div>
		</div>
	);
};
