import { useDispatch, useSelector } from 'react-redux';

import {
	selectCustomerGroupNonMembers,
	selectCustomerGroupNonMembersLoading,
} from '../selectors';
import { actions } from '../slice';
import { NotificationGroupCreateHookValue, View } from '../types';

export const useCustomerGroupCreate = (): NotificationGroupCreateHookValue => {
	const dispatch = useDispatch();
	const nonMemberList = useSelector(selectCustomerGroupNonMembers);
	const nonMemberListLoading = useSelector(
		selectCustomerGroupNonMembersLoading,
	);

	const onClose = () => dispatch(actions.clearCustomerGroupState());

	const onSubmit = ({ groupName, groupMembers }) => {
		dispatch(
			actions.createCustomerGroup({
				name: groupName,
				members: groupMembers,
			}),
		);
		dispatch(actions.setCurrentView(View.List));
		onClose();
	};

	return {
		onClose,
		onSubmit,
		nonMemberList,
		loading: nonMemberListLoading,
	};
};
