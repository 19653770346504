import { AlertTone } from 'app/containers/GlobalSaga/types';
import { TransactionsTabs } from 'app/containers/Transactions/types';
import { ROUTES } from 'app/routes';
import { GroupMemberDto } from 'types/Notifications';
import {
	AggregateType,
	UserInternalNotification,
} from 'types/UserNotification';

import { NOTIFICATION_ALERT_TONES } from './constants';

/**
 * Returns the selected alert tone object.
 * @param {AlertTone} selectedTone - The selected alert tone id
 * @returns {NotificationAlertTone} - The selected alert tone object.
 */
export const getAlertToneById = (selectedTone: AlertTone) => {
	return NOTIFICATION_ALERT_TONES.find((tone) => tone.id === selectedTone);
};

/**
 * Returns the predefined list of notification alert tones and marks the selected alert tone.
 * @param {AlertTone} selectedTone - The selected alert tone id
 * @returns {Array<NotificationAlertTone>} - The list of available notification alert tones.
 */
export const mapAlertTones = (selectedTone: AlertTone) => {
	return NOTIFICATION_ALERT_TONES.map((tone) => ({
		...tone,
		isActive: tone.id === selectedTone,
	}));
};

const AGGREGATE_TYPE_TO_LINK_GENERATOR: Record<
	AggregateType,
	(id: string) => string
> = {
	[AggregateType.Contract]: (id) =>
		id && `${ROUTES.TRANSACTIONS}/${TransactionsTabs.contracts}/${id}`,
	[AggregateType.Offer]: (id) =>
		id && `${ROUTES.TRANSACTIONS}/${TransactionsTabs.offers}/${id}`,
	[AggregateType.ReviewAndRelease]: (id) =>
		id && `${ROUTES.TRANSACTIONS}/${TransactionsTabs.reviewAndRelease}/${id}`,
	[AggregateType.Bidsheet]: () => '',
	[AggregateType.Unspecified]: () => '',
};
export const generateNotificationLink = (item: UserInternalNotification) => {
	const linkGenerator = AGGREGATE_TYPE_TO_LINK_GENERATOR[item.aggregateType];
	return linkGenerator ? linkGenerator(item.aggregateId) : '';
};

export function makeNotificationGroupMemberFromDto(dto: GroupMemberDto) {
	return {
		id: dto.id,
		name: `${dto.firstName} ${dto.lastName}`,
		email: dto.email,
	};
}
