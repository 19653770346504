import { FieldValues } from 'react-hook-form';

import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import { FIELD_TYPES_VALUES } from 'types/OrderEntry';
import { getNumberArray, mapDynamicForm } from 'utils/helpers';
import Yup from 'yupCustom';

import { ORDER_ENTRY_FORM_ITEMS } from '../types';

const getFormMultiSelectItems = (formItem: FieldValues) =>
	Object.keys(formItem)
		.filter((key: string) => key.includes('multiSelectInput'))
		.map((label: string) => formItem?.[label] || '');

export const MULTI_SELECT_MAX_OPTIONS = 160;

export const ORDER_ENTRY_DOMAIN =
	translations.app.containers.Settings.sections.OrderEntry;

export const ORDER_ENTRIES_MAX = 5;

export const getFormValues = (
	isCreatingOrderEntries: boolean,
	values: FieldValues,
) =>
	mapDynamicForm(values).map((formItem: FieldValues) => ({
		id: isCreatingOrderEntries ? null : formItem?.id,
		isActive: formItem?.isActive,
		items: getFormMultiSelectItems(formItem),
		label: formItem?.label,
		type: formItem?.type?.value,
	}));

export const getMultiSelectInputs = (
	orderEntryItems: string[] | null | undefined,
	orderEntryId: string,
	requiredMsg: string,
) =>
	getNumberArray(MULTI_SELECT_MAX_OPTIONS).reduce(
		(multiSelectInputsObject, currentValue, currentIndex) => {
			const orderEntryMultiSelectInput = `${ORDER_ENTRY_FORM_ITEMS.MULTI_SELECT_INPUT}_${currentIndex}-${orderEntryId}`;
			const orderEntryMultiSelectLength = `${ORDER_ENTRY_FORM_ITEMS.MULTI_SELECT_LENGTH}-${orderEntryId}`;
			const orderEntryStatus = `${ORDER_ENTRY_FORM_ITEMS.STATUS}-${orderEntryId}`;
			const orderEntryType = `${ORDER_ENTRY_FORM_ITEMS.TYPE}-${orderEntryId}`;

			return {
				initialValues: {
					...multiSelectInputsObject.initialValues,
					[orderEntryMultiSelectInput]: orderEntryItems?.[currentIndex],
				},
				validationSchema: {
					...multiSelectInputsObject.validationSchema,
					[orderEntryMultiSelectInput]: Yup.string().test({
						message: requiredMsg,
						name: 'multiSelectInput',
						test: function (input: string) {
							const multiSelectLength =
								this.parent[orderEntryMultiSelectLength]?.value;
							const status = this.parent[orderEntryStatus];
							const type = this.parent[orderEntryType]?.value;

							return isMultiSelectInputValid(
								currentIndex,
								input,
								multiSelectLength,
								status,
								type,
							);
						},
					}),
				},
			};
		},
		{ initialValues: {}, validationSchema: {} },
	);

export const isInputValid = (input: string, status: boolean) =>
	!!input || !!!status;

/**
 * @internal exported for automated tests
 */
export const isMultiSelectInputValid = (
	currentIndex: number,
	input: string,
	multiSelectLength: number,
	status: boolean,
	type: FIELD_TYPES_VALUES,
) => {
	if (!isMultiSelectType(type)) {
		return true;
	}

	return currentIndex >= multiSelectLength || isInputValid(input, status);
};

export const isMultiSelectLengthValid = (
	option: GenericOption,
	status: boolean,
	type: FIELD_TYPES_VALUES,
) => {
	if (!isMultiSelectType(type)) {
		return true;
	}

	return isOptionValid(option, status);
};

export const isMultiSelectType = (
	type: FIELD_TYPES_VALUES | null | undefined,
) => type === FIELD_TYPES_VALUES.MUL;

export const isMultiSelectTypeInFormValues = (values: FieldValues) =>
	Object.entries(values).some(
		([key, input]) =>
			key.startsWith(ORDER_ENTRY_FORM_ITEMS.TYPE) &&
			isMultiSelectType(input?.value),
	);

export const isOptionValid = (option: GenericOption, status: boolean) =>
	!!option?.value || !!!status;
