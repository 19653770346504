/**
 *
 * ActionsMenu
 *
 */
import {
	BlockOutlined,
	CheckSquareFilled,
	EditFilled,
	EyeFilled,
	StopOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { actions } from 'app/containers/Offers/slice';
import { OffersModals } from 'app/containers/Offers/types';
import { translations } from 'locales/i18n';

interface Props {
	data: any;
}

export const ActionsMenu = memo(function ActionsMenu(props: Props) {
	const { data } = props;
	const dispatch = useDispatch();

	const translationsScope =
		translations.app.containers.Offers.TableWrapper.actions;

	const { t: translate } = useTranslation();

	const handleViewOffer = (offerId) => {
		dispatch(actions.loadOfferSummary(offerId));
	};

	const handleCancelOffer = (offerData) => {
		dispatch(actions.setCurrentModal(OffersModals.Cancel));
		dispatch(actions.setActiveOffer(offerData));
	};

	const handleEditOffer = (offerId) => {
		dispatch(actions.loadOfferDetails(offerId));
		dispatch(actions.setCurrentModal(OffersModals.Edit));
	};

	const handleBookOffer = (offerData) => {
		dispatch(actions.setCurrentModal(OffersModals.Book));
		dispatch(actions.setActiveOffer(offerData));
	};

	const handleComplete = (data) => {
		dispatch(actions.setCurrentModal(OffersModals.InformCustomer));
		dispatch(actions.setActiveOffer(data));
	};

	return (
		<div className="actions">
			<Button
				onClick={() => handleViewOffer(data.id)}
				data-testid="view-details-button"
			>
				<EyeFilled />
				{translate(translationsScope.view)}
			</Button>

			{data.restrictions.canBeBooked && (
				<Button onClick={() => handleBookOffer(data)} data-testid="book-button">
					<BlockOutlined />
					{translate(translationsScope.book)}
				</Button>
			)}

			{data.restrictions.canBeCanceled && (
				<Button
					onClick={() => handleCancelOffer(data)}
					data-testid="cancel-button"
				>
					<StopOutlined />
					{translate(translationsScope.cancel)}
				</Button>
			)}

			{data.restrictions.canBeEdited && (
				<Button
					onClick={() => handleEditOffer(data.id)}
					data-testid="edit-button"
				>
					<EditFilled />
					{translate(translationsScope.edit)}
				</Button>
			)}
			{data.restrictions.canBeCompleted && (
				<Button
					onClick={() => handleComplete(data)}
					data-testid="complete-modal-button"
				>
					<CheckSquareFilled />
					{translate(translationsScope.informCustomer)}
				</Button>
			)}
		</div>
	);
});
