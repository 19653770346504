import { takeLatest } from 'redux-saga/effects';

import { getLocationsListForSettings } from './sagas/locations-for-settings-saga';
import { settingsActions } from './slice';

export function* settingsSaga() {
	yield takeLatest(
		settingsActions.loadLocationsListForSettings.type,
		getLocationsListForSettings,
	);
}
