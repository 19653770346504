export enum TransactionsTabs {
	contracts = 'contracts',
	offers = 'offers',
	reviewAndRelease = 'reviewAndRelease',
	preHedge = 'futuresFirst',
}

export interface TransactionTab {
	name: string;
	content: any;
	icon?: any;
	className?: any;
}

export enum SummaryView {
	contracts = 'contracts',
	offers = 'offers',
	reviewAndRelease = 'reviewAndRelease',
	preHedge = 'futuresFirst',
}

export enum TransactionEvents {
	Create = 'Create',
	Price = 'Price',
	Cancel = 'Cancel',
	Roll = 'Roll',
	RollOrphan = 'RollOrphan',
	Threshold = 'Threshold',
	Book = 'Book',
	Edit = 'Edit',
	RollBack = 'RollBack',
	CreatedFromFilledOffer = 'CreatedFromFilledOffer',
	Filled = 'Filled',
	PartiallyFilled = 'PartiallyFilled',
	ApplyNameId = 'ApplyNameId',
	Completed = 'Completed',
	Convert = 'Convert',
	Undo = 'Undo',
}
