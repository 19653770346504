import './style.scss';

import { Alert, Button, Col, Row, Steps } from 'antd';
import { useFormikContext } from 'formik';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
	selectContractTypeCatalog,
	selectEnabledRegions,
} from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import { CONTRACT_TYPES } from 'utils/constants';
import { mapToLabelValueFormatted } from 'utils/helpers';
import { useResize } from 'utils/hooks/useResize';

import { HedgeAccountContent } from '../HedgeAccountContent';

interface Props {
	options: GenericOption[];
	placeholder: string;
}

const { Step } = Steps;

export const HedgeAccount = memo(function HedgeAccount({
	options,
	placeholder,
}: Props) {
	const [currentStep, setCurrentStep] = useState(0);

	const { t: translate } = useTranslation();
	const { isMobile } = useResize();
	const regions = useSelector(selectEnabledRegions);

	let contractTypes = mapToLabelValueFormatted(
		useSelector(selectContractTypeCatalog),
	);

	//removing ntc from the list
	if (contractTypes !== null && Array.isArray(contractTypes)) {
		contractTypes = contractTypes.filter(
			(contractType) => contractType.code !== CONTRACT_TYPES.ntc,
		);
	}
	const {
		values: { cropsSection },
		errors: { cropsSection: cropsSectionErrors },
		isValid,
	} = useFormikContext<any>();

	const domain = translations.app.containers.Settings.hedgeAccount;

	const steps = (regions ?? []).map((region) => ({
		id: region.id,
		title: region.name,
		content: (
			<HedgeAccountContent
				cropsSection={region.id && cropsSection[region.id]}
				regionId={region.id || ''}
				dropdownOptions={options}
				defaultPlaceholder={placeholder}
			/>
		),
	}));
	const isCurrentStepValid =
		cropsSectionErrors && cropsSectionErrors[steps[currentStep].id || ''];

	const getStepStatus = (regionId: string = '') =>
		cropsSectionErrors && cropsSectionErrors[regionId] ? 'error' : 'finish';

	const getFirstInvalidStep = (errorIndex): number => {
		if (isValid) return -1;

		const firstInvalidStepId =
			cropsSectionErrors && Object.keys(cropsSectionErrors)[errorIndex];
		const invalidStepIndex = steps.findIndex(
			(step) => step.id === firstInvalidStepId,
		);

		if (invalidStepIndex === currentStep) {
			// If the current index is the last in the array, wrap around to the beginning.
			const nextErrorIndex = (errorIndex + 1) % steps.length;
			return getFirstInvalidStep(nextErrorIndex);
		}
		return invalidStepIndex;
	};

	const nextInvalidStep = () => {
		const nextStep = getFirstInvalidStep(0);
		setCurrentStep(nextStep);
	};
	return steps.length ? (
		<>
			<Row>
				<Col xs={12} md={4}>
					<Steps
						className="hedge-account__steps"
						current={currentStep}
						onChange={setCurrentStep}
						direction={isMobile ? 'horizontal' : 'vertical'}
					>
						{steps.map((step, index) => (
							<Step
								title={step.title}
								key={index}
								status={
									currentStep === index ? 'process' : getStepStatus(step.id)
								}
							/>
						))}
					</Steps>
				</Col>
				<Col xs={12} md={8}>
					<div className="steps-content">{steps[currentStep].content}</div>
				</Col>
			</Row>
			<div className="steps-action">
				{!isValid && (
					<Button
						type="primary"
						onClick={() => nextInvalidStep()}
						disabled={isCurrentStepValid}
					>
						{translate(domain.nextRegionButton)}
					</Button>
				)}
			</div>
		</>
	) : (
		<Alert type="error" message={translate(domain.noRegionsPlaceholder)} />
	);
});
