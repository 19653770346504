import { z } from 'zod';

const _transactionItemSchema = z.object({
	id: z.string(),
	name: z.string(),
	type: z.string(),
});
export interface TransactionItem
	extends z.infer<typeof _transactionItemSchema> {}
export const transactionItemSchema: z.Schema<TransactionItem> =
	_transactionItemSchema;
