import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.genericReport || initialState;

export const selectReportsList = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.reportsList.data,
);

export const selectCurrentReport = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.currentReport,
);

export const selectTableData = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.tableData.data,
);

export const selectTableDataReportUrl = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.tableDataReportTemplate.url,
);

export const selectTableDataTotal = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.tableData.total,
);

export const selectTableDataLoaded = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.tableData.loaded,
);

export const selectFiltersSchema = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.tableSchema.data.filters,
);

export const selectSectionsSchema = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.tableSchema.data.sections,
);

export const selectBodiesSchema = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.tableSchema.data.bodies,
);

export const selectPagination = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.pagination,
);

export const selectFiltersData = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.filtersData,
);

export const selectSelectedFilters = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.selectedFilters,
);

export const selectTableSchemaLoading = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.tableSchema.loading,
);

export const selectTableDataLoading = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.tableData.loading,
);

export const selectDynamicFilters = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.dynamicFiltersData,
);

export const selectHasDynamicHeaderLabel = createSelector(
	[selectDomain],
	(genericReportState) =>
		genericReportState.tableSchema.data.hasDynamicHeaderLabel,
);

export const selectHasPagination = createSelector(
	[selectDomain],
	(genericReportState) => genericReportState.tableSchema.data.hasPagination,
);
