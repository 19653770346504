import { jwtDecode, JwtPayload } from 'jwt-decode';

import { UserAccount } from 'types/UserAccount';
import { UserInfo } from 'types/UserInfo';

interface TokenData extends JwtPayload {
	Grant: string | string[];
	name: string;
	email: string;
	'http://schemas.microsoft.com/identity/claims/tenantid': string;
	'http://schemas.microsoft.com/identity/claims/tenantDisplayName': string;
}

export const mapUser = (
	accessToken: string | null,
): { userInfo: UserInfo | null; userAccount: UserAccount | null } => {
	const accessTokenDecoded = accessToken
		? jwtDecode<TokenData>(accessToken)
		: null;
	const expiration = accessTokenDecoded?.exp;
	const permissions =
		typeof accessTokenDecoded?.Grant === 'string'
			? [accessTokenDecoded?.Grant]
			: accessTokenDecoded?.Grant;
	const name = accessTokenDecoded?.name;
	const email = accessTokenDecoded?.email;
	const userId = accessTokenDecoded?.sub;
	const tenant =
		accessTokenDecoded?.[
			'http://schemas.microsoft.com/identity/claims/tenantid'
		];
	const tenantDisplayName =
		accessTokenDecoded?.[
			'http://schemas.microsoft.com/identity/claims/tenantDisplayName'
		];

	const userAccount: UserAccount | null =
		accessToken && expiration && permissions
			? {
					accessToken,
					expiration,
					permissions,
				}
			: null;

	const userInfo: UserInfo | null =
		accessToken && name && userId && email
			? {
					id: userId,
					name: name,
					firstName: name.split('.')[0],
					lastName: name.split('.')[1],
					email: email,
					tenant: tenant ?? '',
					tenantDisplayName: tenantDisplayName ?? '',
				}
			: null;
	return { userAccount, userInfo };
};
