import { takeLatest } from 'redux-saga/effects';

import { getNotificationsHistory } from './sagas/list-saga';
import { actions } from './slice';

export function* notificationsHistorySaga() {
	yield takeLatest(
		actions.loadNotificationsHistory.type,
		getNotificationsHistory,
	);
}
