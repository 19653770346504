import React from 'react';
import { FieldValues } from 'react-hook-form';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { translations } from 'locales/i18n';

import { useActivateDNHData } from './hooks/useActivateDNHData';
import { actions } from './slice';

export const domain = translations.app.containers.ActivateDNHModal;

export const ActivateDNHModal = () => {
	const {
		dispatch,
		elements,
		formInstance,
		isLoading,
		isModalOpen,
		translate,
		validationSchema,
	} = useActivateDNHData();

	const getTranslation = (key: string) => translate(domain[key]);

	const handleCancel = () => {
		dispatch(actions.setIsModalOpen(false));
	};

	const handleSubmit = (values: FieldValues) => {
		const data = [
			{
				activateDnh: values.doNotHedge,
				commodityId: values.commodity?.value,
				cropYear: values.cropYear,
				regionId: values.region.value,
			},
		];
		dispatch(actions.setDNHListRequest({ data }));
	};

	const buttonsDefinition: FormButtonsProps[] = [
		{
			children: getTranslation('cancelButton'),
			dataTestId: 'cancel-button',
			disabled: isLoading,
			htmlType: 'button',
			key: 'cancel',
			onClick: handleCancel,
		},
		{
			children: getTranslation('submitButton'),
			dataTestId: 'activate-dnh-submit-button',
			disabled: isLoading,
			htmlType: 'submit',
			key: 'submit',
		},
	];

	if (!isModalOpen) {
		return null;
	}

	return (
		<GenericForm.ModalContainer
			className="activate-dnh-modal"
			key="activateDNHModal"
			onCancel={handleCancel}
			title={getTranslation('title')}
		>
			<GenericForm.Form
				buttonsDefinition={buttonsDefinition}
				className="activate-dnh-form"
				key="activateDNHForm"
				formInstance={formInstance}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{elements}
			</GenericForm.Form>
		</GenericForm.ModalContainer>
	);
};
