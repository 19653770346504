import { FieldDefinition } from 'types/FieldDefinition';

import { NotificationEvent } from '../types';

interface Props {
	preferences: NotificationEvent[];
}

export const useNotificationEventTypeSchema = ({
	preferences,
}: Props): FieldDefinition[] => {
	return preferences.map((preference) => ({
		name: preference.eventType,
		initialValue: {
			id: preference.id,
			scheduleTime: preference.scheduleTime,
			isInAppEnabled: preference.isInAppEnabled,
			isEmailEnabled: preference.isEmailEnabled,
			isSmsEnabled: preference.isSmsEnabled,
			isDailySummaryEnabled: preference.isDailySummaryEnabled,
		},
	}));
};
