import './styles.scss';

import React from 'react';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { brokerMappingSaga } from './saga';
import { brokerMappingReducer, sliceKey } from './slice';
import { Filters } from './View/Filters';
import { Mapping } from './View/Mapping';

export const BrokerMapping = () => {
	useInjectReducer({ key: sliceKey, reducer: brokerMappingReducer as any });
	useInjectSaga({ key: sliceKey, saga: brokerMappingSaga });

	return (
		<div className="broker-mapping-container">
			<Filters />
			<Mapping />
		</div>
	);
};
