import { takeLatest } from 'redux-saga/effects';

import {
	getERPIntegrationSaga,
	getHedgeIntegrationSaga,
	setERPIntegrationSaga,
	setHedgeIntegrationSaga,
} from './sagas/erp-integration-saga';
import { getERPTypesSaga } from './sagas/erp-types-saga';
import { actions } from './slice';

export function* erpIntegrationSaga() {
	yield takeLatest(
		actions.getERPIntegrationRequest.type,
		getERPIntegrationSaga,
	);

	yield takeLatest(
		actions.getHedgeIntegrationRequest.type,
		getHedgeIntegrationSaga,
	);

	yield takeLatest(actions.getERPTypesRequest.type, getERPTypesSaga);

	yield takeLatest(
		actions.setERPIntegrationRequest.type,
		setERPIntegrationSaga,
	);

	yield takeLatest(
		actions.setHedgeIntegrationRequest.type,
		setHedgeIntegrationSaga,
	);
}
