import './styles.scss';

import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { translations } from 'locales/i18n';

import { CashbidsAndQuotes } from '../CashbidsAndQuotes';

export const OriginatorScreen = memo(function OriginatorScreen() {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			globalActions.setPageTitle(
				translate(translations.app.components.AppLayout.cashBidsAndQuotes),
			),
		);
	}, []);

	const pageTitle = translate(
		translations.app.containers.OriginatorScreen.pageTitle,
	);

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="description" content={pageTitle} />
			</Helmet>
			<CashbidsAndQuotes />
		</>
	);
});
