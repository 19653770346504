import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import React, { memo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import { getNumberArray } from 'utils/helpers';

import { Header } from '../../components/Header';
import { RegionsRow } from '../../components/RegionsRow';
import { useRegionSchema } from '../../schemas/useRegionSchema';
import { selectAllRegions } from '../../selectors';
import { actions } from '../../slice';
import { getFormValues, REGIONS_DOMAIN } from '../../utils/helpers';

const RegionsForm = memo(function RegionsForm() {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const regions = useSelector(selectAllRegions);

	const { initialValues, validationSchema } = useRegionSchema();
	const formInstance = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		defaultValues: {
			...initialValues,
		},
	});

	const [regionsCount, setRegionsCount] = useState(regions?.length || 1);

	const handleCancel = () => {
		formInstance.reset({
			...initialValues,
		});
		setRegionsCount(regions?.length || 1);
	};
	const handleSubmit = (values: FieldValues) => {
		const mappedValues = getFormValues(values);
		dispatch(actions.createRegionRequest(mappedValues));
	};

	const addAdditionalField = () => {
		setRegionsCount(regionsCount + 1);
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			children: translate(REGIONS_DOMAIN.cancelButton),
			dataTestId: 'cancel-button',
			htmlType: 'button',
			key: 'cancel',
			onClick: handleCancel,
		},
		{
			children: translate(REGIONS_DOMAIN.submitButton),
			dataTestId: 'submit-button',
			htmlType: 'submit',
			key: 'submit',
		},
	];
	return (
		<GenericForm.Form
			buttonsDefinition={btnsDefinition}
			className="regions-form"
			formInstance={formInstance}
			key="regionsForm"
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			<>
				<Header />
				{getNumberArray(regionsCount).map((index: number) => (
					<RegionsRow index={index} regionSetup={regions?.[index]} />
				))}
				<div className="additional-field">
					<button type="button" onClick={addAdditionalField}>
						<PlusOutlined /> {translate(REGIONS_DOMAIN.addAdditionalField)}
					</button>
				</div>
			</>
		</GenericForm.Form>
	);
});

export default RegionsForm;
