/**
 *
 * CommonTable
 *
 */
import classNames from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { activeCommoityInFuture } from 'app/containers/CashbidsAndQuotes/selectors';
import { translations } from 'locales/i18n';

export const CommonTable = memo(function CommonTable({
	commodity,
	futuresQouteData,
}: any) {
	const translationsScope =
		translations.app.containers.CashbidsAndQuotes.FuturesQoutes;
	// get active commodity from redux
	const activeOrderDetails = useSelector(activeCommoityInFuture);
	const loading = activeOrderDetails === null;

	const { t: translate } = useTranslation();

	const columns = [
		{
			title: translate(translationsScope.contract),
			key: 'contract',
			className: 'md',
			fixed: true,
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large text--centered">
						{data.symbol.replace('F.US.', '')}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.product),
			key: 'product',
			fixed: true,
			className: 'md',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large text--centered">{commodity}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.last),
			key: 'last',
			className: 'xs',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large text--centered">
						{data.price?.toFixed(4)}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.change),
			key: 'change',
			className: 'xs',
			render: (data) => (
				<GenericTable.Column>
					<p
						className={classNames('text text--large text--centered', {
							'futures-qoute--isRed': data.price - data.prevSettlement < 0,
							'futures-qoute--isGreen': data.price - data.prevSettlement > 0,
						})}
					>
						{(data.price - data.prevSettlement)?.toFixed(4)}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.high),
			className: 'xs',
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--large text--centered">
						{data.high?.toFixed(4)}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.low),
			key: 'low',
			className: 'xs',
			// fixed: 'right' as 'right',
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--large text--centered">
						{data.low?.toFixed(4)}
					</p>
				</GenericTable.Column>
			),
		},
	];

	return (
		<>
			{futuresQouteData?.length && (
				<>
					<GenericTable.Table<any>
						otherClassName="futures-qoute__history-container"
						columns={columns}
						loading={loading}
						data={futuresQouteData}
						rowKey={(record) => record.id}
						pagination={false}
						useVirtualScroll={false}
						onRow={(record) => ({
							className: classNames(
								`border-left--${record?.state?.trim().split(' ').join('-').toLowerCase()}`,
							),
						})}
					/>
				</>
			)}
		</>
	);
});
