import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericModalForm } from 'app/components/GenericModalForm';
import { useUploadBidsheetErrors } from 'app/containers/Settings/definitions/hooks/useUploadBidsheetErrors';
import { translations } from 'locales/i18n';

import { selectListLoading } from '../../selectors';
import { actions } from '../../slice';
import { TableTypes } from '../../types';
import { useUploadConfig } from '../schemas/useUploadConfig';

export const UploadBidsheet = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const bidsheetListLoading = useSelector(selectListLoading);
	const domain =
		translations.app.containers.Settings.sections.Bidsheet.UploadBidsheet;

	const uploadComponent = useUploadConfig();

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const handleSubmit = () => {
		dispatch(actions.setCurrentModal(null));
		dispatch(actions.loadBidsheetList({ type: TableTypes.Filter }));
	};

	return (
		<GenericModalForm
			title={translate(domain.modalTitle)}
			formDefinition={uploadComponent}
			handleSubmit={handleSubmit}
			handleClose={handleClose}
			footerButtons={
				<FormFooterButtons
					isLoading={bidsheetListLoading}
					cancelLabel={translate(domain.cancelUpload)}
					submitLabel={translate(domain.continueUpload)}
					closeModal={handleClose}
				/>
			}
			hookSchema={useUploadBidsheetErrors}
		/>
	);
};
