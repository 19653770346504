import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericModalForm } from 'app/components/GenericModalForm';
import { selectExternalFill } from 'app/containers/ReviewAndRelease/selectors';
import { actions } from 'app/containers/ReviewAndRelease/slice';
import { translations } from 'locales/i18n';
import { isActionSell, removeComma } from 'utils/helpers';

import { useExternalFillParams } from '../hooks/useExternalFillParams';
import { useExternalFillSchema } from '../schemas/useExternalFillSchema';

export const ExternalFill = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const externalFillState = useSelector(selectExternalFill);

	const {
		contract,
		commodity,
		cropYears,
		buyOrSell,
		quantity,
		futuresMonth,
		price,
		futuresAccount,
		comments,
		regionId,
	} = useExternalFillSchema();

	const fieldDefinitions = [
		contract,
		commodity,
		cropYears,
		buyOrSell,
		quantity,
		futuresMonth,
		price,
		futuresAccount,
		regionId,
		comments,
	];

	const domain =
		translations.app.containers.ReviewAndRelease.components.Views.List.Menu
			.Modals;

	const handleSubmit = (values) => {
		const submitData = {
			contractNumber: values.contract,
			commodityId: values.commodity.value,
			cropYear: values.cropYear,
			isSell: isActionSell(values.buyOrSale),
			quantity: parseFloat(removeComma(values.quantity)),
			futuresMonth: values.futuresMonth.value,
			futuresPrice: parseFloat(values.price),
			hedgeAccountId: values.futuresAccount.value,
			comments: values.comments,
			regionId: values.regionId.value,
		};
		dispatch(
			actions.createExternalFill({
				data: submitData,
				successMessage: translate(domain.toastSuccessMessage),
			}),
		);
	};

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	return (
		<GenericModalForm
			title={translate(domain.externalFillTitle)}
			formDefinition={fieldDefinitions}
			handleSubmit={handleSubmit}
			handleClose={handleClose}
			scrollable={true}
			labelSpan={4}
			wrapperColSpan={8}
			footerButtons={
				<FormFooterButtons
					isLoading={externalFillState.loading}
					cancelLabel={translate(domain.cancelForm)}
					submitLabel={translate(domain.submitForm)}
					closeModal={handleClose}
				/>
			}
			hookSchema={useExternalFillParams}
		/>
	);
};
