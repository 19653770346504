import { call, put, select } from 'redux-saga/effects';

import { IGenericPagination } from 'types/GenericPagination';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { selectPagination } from '../selectors';
import { actions } from '../slice';

export function* getNotificationsHistory() {
	const { notificationsHistory } = apiEndpoints;
	const pagination: IGenericPagination = yield select(selectPagination);
	const requestURL = yield new URL(`${apiRoutes.base}/${notificationsHistory}`);

	requestURL.searchParams.append('pageNumber', pagination.start);
	requestURL.searchParams.append('pageSize', pagination.limit);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		const { items: data, totalCount: total } = responseData;
		yield put(actions.notificationsHistoryLoaded({ data, total }));
	}

	if (!!responseError) {
		yield put(actions.notificationsHistoryError(responseError));
	}
}
