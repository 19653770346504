import { Card, Col } from 'antd';
import classNames from 'classnames';
import React from 'react';

interface Props {
	span: number;
	noBorder?: boolean;
	dataTestId: string;
	children: any;
}

export const Section = (props: Props) => {
	const { span, children, noBorder = false, dataTestId } = props;
	return (
		<Col span={span}>
			<Card className="contract" data-testid={dataTestId} bordered={false}>
				<div
					className={classNames('divider', {
						'no-border': noBorder,
					})}
				>
					{children}
				</div>
			</Card>
		</Col>
	);
};
