import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectActiveContract,
	setCashbidsState,
} from 'app/containers/Contracts/selectors';
import { actions as contractsActions } from 'app/containers/Contracts/slice';
import {
	selectRoundingRulesList,
	selectServiceFeeList,
} from 'app/containers/GlobalSaga/selectors';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { ActionType } from 'types/ActionType';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { RoundingType } from 'types/RoundngType';
import {
	CONSTANTS,
	CONTRACT_TYPES,
	DATE_FORMAT,
	ROUNDING_TYPES_GUIDS,
} from 'utils/constants';
import { formatPriceHelper, getActionType } from 'utils/helpers';
import { isEmptyObject } from 'utils/validators';

import { useHandleDependencies } from './useHandleDependencies';

export const useHandleBusinessRules = (sourceName: GlobalSagaSource) => {
	const {
		setValue,
		getValues,
		setError,
		formState: { isDirty },
	} = useFormContext();
	const activeContract = useSelector(selectActiveContract);
	const dispatch = useDispatch();
	const roundingRules = useSelector(selectRoundingRulesList);
	const serviceFees = useSelector(selectServiceFeeList);
	const cashbidsState = useSelector(setCashbidsState);
	const { getPushBasis } = useHandleDependencies();

	const setNetBasis = () => {
		const { postedBasis, pushBasis } = getValues();

		if (postedBasis === null && pushBasis === null) {
			setValue('netBasis', null);
		} else {
			const currentPostedBasis = postedBasis ? parseFloat(postedBasis) : 0;
			const currentPushBasis = pushBasis ? parseFloat(pushBasis) : 0;

			const netBasis = currentPostedBasis + currentPushBasis;
			setValue('netBasis', formatPriceHelper(netBasis), {
				shouldValidate: true,
			});
		}
	};

	const setTotalPricesContracts = () => {
		// TODO call this when futuresPriceInput used on price/roll modals
		const {
			futuresPriceInput,
			futuresPrice,
			flatPrice,
			netBasis,
			freight,
			fees1,
			fees2,
		} = getValues();

		if (
			futuresPriceInput === null &&
			futuresPrice === null &&
			netBasis === null &&
			freight === null &&
			fees1 === null &&
			fees2 === null
		) {
			setValue('flatPrice', null);
			setValue('netBasisPrice', null);
			setValue('netFutures', null);
		} else {
			const currentfuturesPriceInput = futuresPriceInput
				? parseFloat(futuresPriceInput)
				: 0;
			const currentFuturesPrice = futuresPrice ? parseFloat(futuresPrice) : 0;
			const currentFlatPrice = flatPrice ? parseFloat(flatPrice) : 0;
			const currentNetBasis = netBasis ? parseFloat(netBasis) : 0;
			const currentFreight = freight ? parseFloat(freight) : 0;
			const currentFees1 = fees1 ? parseFloat(fees1) : 0;
			const currentFees2 = fees2 ? parseFloat(fees2) : 0;

			const newFlatPrice =
				(currentFuturesPrice || currentfuturesPriceInput) +
				currentNetBasis +
				currentFreight +
				currentFees1 +
				currentFees2;

			// Avoid unwanted setFieldValue when value has not changed
			if (newFlatPrice !== currentFlatPrice) {
				setValue('flatPrice', formatPriceHelper(newFlatPrice), {
					shouldValidate: true,
				});
			}

			const netBasisPrice =
				currentNetBasis + currentFreight + currentFees1 + currentFees2;
			setValue('netBasisPrice', formatPriceHelper(netBasisPrice), {
				shouldValidate: true,
			});

			const netFutures =
				currentFuturesPrice + currentFreight + currentFees1 + currentFees2;
			setValue('netFutures', formatPriceHelper(netFutures), {
				shouldValidate: true,
			});
		}
	};

	const setTotalPricesOffers = () => {
		const { futuresPrice, netBasis, freight, fees1, fees2 } = getValues();
		if (
			futuresPrice === null &&
			netBasis === null &&
			freight === null &&
			fees1 === null &&
			fees2 === null
		) {
			setValue('netBasisPrice', null);
			setValue('netFutures', null);
		} else {
			const currentFuturesPrice = futuresPrice ? parseFloat(futuresPrice) : 0;
			const currentNetBasis = netBasis ? parseFloat(netBasis) : 0;
			const currentFreight = freight ? parseFloat(freight) : 0;
			const currentFees1 = fees1 ? parseFloat(fees1) : 0;
			const currentFees2 = fees2 ? parseFloat(fees2) : 0;

			const netBasisPrice =
				currentNetBasis + currentFreight + currentFees1 + currentFees2;
			setValue('netBasisPrice', formatPriceHelper(netBasisPrice), {
				shouldValidate: true,
			});

			const netFutures =
				currentFuturesPrice + currentFreight + currentFees1 + currentFees2;
			setValue('netFutures', formatPriceHelper(netFutures), {
				shouldValidate: true,
			});
		}
	};

	const manageTotalPriceCall = () => {
		if (sourceName === GlobalSagaSource.contractModal) {
			setTotalPricesContracts();
		} else {
			setTotalPricesOffers();
		}
	};

	const setFuturesPrices = () => {
		const { flatPrice, netBasis, freight, fees1, fees2 } = getValues();
		if (flatPrice === null) {
			setValue('futuresPrice', null);
		} else {
			const currentNetBasis = netBasis ? parseFloat(netBasis) : 0;
			const currentFreight = freight ? parseFloat(freight) : 0;
			const currentFees1 = fees1 ? parseFloat(fees1) : 0;
			const currentFees2 = fees2 ? parseFloat(fees2) : 0;
			const currentFlatPrice = flatPrice ? parseFloat(flatPrice) : 0;

			const autoFuturesPrice =
				currentFlatPrice -
				currentNetBasis -
				currentFreight -
				currentFees1 -
				currentFees2;

			setValue('futuresPrice', formatPriceHelper(autoFuturesPrice), {
				shouldValidate: true,
			});
		}
	};
	const manageSetFuturesPriceCall = () => {
		if (sourceName === GlobalSagaSource.createEditOfferModal) {
			setFuturesPrices();
		}
	};
	const manageSetPushBasis = () => {
		if (sourceName === GlobalSagaSource.contractModal) {
			setPushBasis();
		}
	};

	const setPushBasis = () => {
		const {
			flatPrice,
			postedBasis,
			futuresPrice,
			pushBasis,
			freight,
			fees1,
			fees2,
		} = getValues();
		// Due posted basis is calculated with a service call, this will only work when is already set
		if (flatPrice && postedBasis) {
			const currentFuturesPrice = futuresPrice ? parseFloat(futuresPrice) : 0;
			const currentPostedBasis = postedBasis ? parseFloat(postedBasis) : 0;
			const currentFlatPrice = flatPrice ? parseFloat(flatPrice) : 0;
			const currentPushBasis = pushBasis ? parseFloat(pushBasis) : 0;
			const currentFreight = freight ? parseFloat(freight) : 0;
			const currentFees1 = fees1 ? parseFloat(fees1) : 0;
			const currentFees2 = fees2 ? parseFloat(fees2) : 0;

			const originalCashPrice =
				currentFuturesPrice +
				currentPostedBasis +
				currentFreight +
				currentFees1 +
				currentFees2;

			const newPushBasis = currentFlatPrice - originalCashPrice;
			// calculation on push basis should get triggered only when the futures price and cash price is not equal to null
			// Avoid unwanted setValue when value has not changed
			if (
				newPushBasis !== currentPushBasis &&
				futuresPrice !== null &&
				currentFlatPrice !== null
			) {
				setValue('pushBasis', formatPriceHelper(newPushBasis), {
					shouldValidate: true,
				});
			}
		}
	};
	const clearStore = () => {
		dispatch(globalActions.clearDeliveryDates(sourceName));
		dispatch(globalActions.clearFuturesMonth(sourceName));
		dispatch(globalActions.clearFuturesPrices(sourceName));
		dispatch(globalActions.clearPostedBasisPrice(sourceName));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	const getServiceFee = (isRoll) => {
		const { contract, commodity, cropYear, transaction, deliveryDate, fees1 } =
			getValues();
		if (
			serviceFees &&
			serviceFees.data &&
			contract &&
			commodity &&
			cropYear &&
			transaction &&
			deliveryDate
		) {
			const serviceFeeOptions = serviceFees.data;
			let selectedItem;
			for (var i = 0; i < serviceFeeOptions.length; i++) {
				if (
					serviceFeeOptions[i]['commodityId'] === commodity.value &&
					serviceFeeOptions[i]['contractTypeId'] === contract.value &&
					serviceFeeOptions[i]['cropYear'] === cropYear &&
					serviceFeeOptions[i]['transactionTypeId'] === transaction.value
				) {
					selectedItem = serviceFeeOptions[i];
					break;
				}
			}
			if (selectedItem) {
				let targetDeliveryDate = deliveryDate[0].format('MMM YY');
				let serviceFeeMonth = selectedItem.serviceFeeMonthList.filter(
					(item) => item.deliveryMonth === targetDeliveryDate,
				);
				if (isRoll) {
					if (serviceFeeMonth?.[0]?.rollingFee > 0)
						setValue(
							'fees1',
							(fees1 === null ? 0 : fees1) + serviceFeeMonth?.[0]?.rollingFee,
						);
				} else if (serviceFeeMonth?.[0]?.writingFee > 0) {
					setValue('fees1', serviceFeeMonth?.[0]?.writingFee);
				}
			}
		}
	};

	const getRoundingRules = (calculatedFuturesPrice) => {
		// Make sure to have ContractType field on schema using roundingRules
		let { contract, action } = getValues();

		if (!action) {
			action = activeContract && getActionType(activeContract.isSell);
		}

		let endingDigits = calculatedFuturesPrice.toString().split('.')[1] ?? '';
		if (endingDigits.length < 4) endingDigits = endingDigits.padEnd(4, 0); // decimals exclude trailing zeroes so if it inded in a zero we need to padd it for comparion purposes.
		let endingDigitsForTest = endingDigits.substring(2, 4);
		let roundingItems = roundingRules.data;

		let selectedItem;
		for (let i = 0; i < roundingItems.length; i++) {
			let rule = roundingItems[i];
			let lower = rule.from.toString().split('.')[1];
			let upper = rule.to.toString().split('.')[1];
			if (lower.length < 4) lower = lower.padEnd(4, '0');
			if (upper.length < 4) upper = upper.padEnd(4, '0');
			lower = lower.substring(2, 4);
			upper = upper.substring(2, 4);
			if (
				rule.contractTypeId.toUpperCase() === contract?.value.toUpperCase() &&
				(rule.isSell === false ? ActionType.BUY : ActionType.SELL) === action &&
				!!rule.isActive &&
				lower <= endingDigitsForTest &&
				upper >= endingDigitsForTest
			) {
				selectedItem = rule;
				break;
			}
		}
		if (selectedItem) {
			let roundingType = null;
			if (selectedItem.roundingTypeId != null) {
				roundingType =
					ROUNDING_TYPES_GUIDS[selectedItem.roundingTypeId.toUpperCase()];
			}
			let decimalPlace = selectedItem.decimalPlace;

			if (decimalPlace === 0 && roundingType === RoundingType.ROUNDINGUP) {
				calculatedFuturesPrice =
					Math.floor(calculatedFuturesPrice) +
					Number('.' + endingDigits.substring(0, 2)) +
					0.01;
			} else if (
				decimalPlace === 0 &&
				roundingType === RoundingType.ROUNDINGDOWN
			) {
				calculatedFuturesPrice =
					Math.floor(calculatedFuturesPrice) +
					Number('.' + endingDigits.substring(0, 2));
			} else {
				calculatedFuturesPrice =
					Math.floor(calculatedFuturesPrice) +
					Number('.' + endingDigits.substring(0, 2)) +
					Number(decimalPlace);
			}
		}
		return calculatedFuturesPrice;
	};
	const getPostedBasis = (sourceName) => {
		//This applies only for offers
		if (sourceName !== GlobalSagaSource.createEditOfferModal || !isDirty)
			return;
		const {
			commodity,
			deliveryDate,
			cropYear,
			location,
			deliveryLocation,
			contract,
		} = getValues();
		if (
			commodity &&
			deliveryDate &&
			cropYear &&
			location &&
			deliveryLocation &&
			contract?.label !== CONTRACT_TYPES.hta
		) {
			dispatch(
				globalActions.loadPostedBasisPrice({
					params: {
						commodityId: commodity.value,
						locationId: location.value,
						deliveryLocationId: deliveryLocation.value,
						deliveryStartDate: deliveryDate[0].format(DATE_FORMAT),
						deliveryEndDate: deliveryDate[1].format(DATE_FORMAT),
						cropYear,
					},
					source: sourceName,
				}),
			);
		} else {
			dispatch(globalActions.clearPostedBasisPrice(sourceName));
		}
	};

	const getDeliveryDates = (source = sourceName as any) => {
		if (isEmptyObject(cashbidsState)) {
			setValue('deliveryDate', null);
			setValue('deliveryDateWindow', { value: null });
		}
		const { commodity, cropYear, deliveryLocation } = getValues();
		if (commodity?.value && cropYear && deliveryLocation?.value) {
			dispatch(
				globalActions.loadDeliveryDates({
					source: source,
					params: {
						commodityId: commodity.value,
						locationId:
							deliveryLocation?.defaultDestinationLocationId ||
							deliveryLocation.value,
						cropYear,
					},
				}),
			);
		} else {
			dispatch(globalActions.clearDeliveryDates(sourceName));
		}
	};

	const getSpreadValue = (msg = undefined) => {
		const { futuresMonth } = getValues();
		if (futuresMonth?.value) {
			dispatch(
				contractsActions.loadSpreadValue({
					futuresMonth: futuresMonth.value,
					id: activeContract?.id,
				} as any),
			);
		} else {
			setError('spread', { type: 'error', message: msg });
		}
	};

	const manageSetPushBasisSpread = (canUpdateBasis = false) => {
		const { pushBasis, canBeUpdated } = getPushBasis(getValues());
		if (canBeUpdated && canUpdateBasis) {
			setValue('pushBasis', pushBasis.toFixed(CONSTANTS.FIXED_DECIMALS));
			setNetBasis();
		}
	};

	const getFuturesMonths = (source = CalculatedFieldsSource.Transactions) => {
		const commodity = getValues('commodity');
		if (commodity?.value) {
			dispatch(
				globalActions.loadFuturesMonthOptions({
					params: {
						commodityId: commodity.value,
						excludeExpired: true,
					},
					source: source,
				}),
			);
		}
	};

	return {
		setNetBasis,
		manageTotalPriceCall,
		manageSetFuturesPriceCall,
		manageSetPushBasis,
		manageSetPushBasisSpread,
		getPostedBasis,
		getDeliveryDates,
		getSpreadValue,
		clearStore,
		getRoundingRules,
		getFuturesMonths,
		getServiceFee,
	};
};
