import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericModalForm } from 'app/components/GenericModalForm';
import { translations } from 'locales/i18n';

import { useLocationSchema } from '../../fields';
import { LocationFormPayload } from '../../fields/types';
import {
	selectCurrentLocationLoading,
	selectErrorsInline,
} from '../../selectors';
import { actions } from '../../slice';
import { FieldModes } from '../../types';

export const CreateLocation = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const domain = translations.app.containers.Settings.sections.Locations;
	const errorsInLine = useSelector(selectErrorsInline);
	const locationSchema = useLocationSchema();
	const formDefinition = locationSchema[FieldModes.Autocomplete];
	const locationLoading = useSelector(selectCurrentLocationLoading);

	const handleSubmit = ({
		destinationLocationNumber,
		destinationLocationName,
		contractLocationNumber,
		contractLocationName,
		regionId,
	}: LocationFormPayload) => {
		const data: any = {
			destinationLocationNumber: destinationLocationNumber.value,
			destinationLocationName: destinationLocationName.value,
			contractLocationNumber: contractLocationNumber.value,
			contractLocationName: contractLocationName.value,
			regionId: regionId?.value,
		};

		dispatch(
			actions.createLocation({
				data: data,
				successMessage: translate(domain.toastSuccessCreateMessage, {
					fullNameCreated: destinationLocationName.value,
				}),
			}),
		);
	};
	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};
	const formButtons = (
		<FormFooterButtons
			isLoading={locationLoading}
			cancelLabel={translate(domain.cancelLocationModalButton)}
			submitLabel={translate(domain.addLocationModalButton)}
			closeModal={handleClose}
		/>
	);

	return (
		<GenericModalForm
			title={translate(domain.createLocationModalTitle)}
			formDefinition={formDefinition}
			handleSubmit={handleSubmit}
			handleClose={handleClose}
			footerButtons={formButtons}
			hookParams={{ errors: errorsInLine }}
			enableReinitialize={true}
		/>
	);
};
