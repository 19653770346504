import './style.scss';

import { CloseOutlined } from '@ant-design/icons';
import { Card, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FilterEmpty } from 'app/components/FilterEmpty';
import {
	OrderSource,
	OrderStatus,
	ReviewAndReleaseViews,
} from 'app/containers/ReviewAndRelease/types';
import { Filter } from 'app/containers/Transactions/components/Filter';
import { ListWrapper } from 'app/containers/Transactions/components/ListWrapper';
import { Summary } from 'app/containers/Transactions/components/Summary';
import { SummaryView } from 'app/containers/Transactions/types';
import { translations } from 'locales/i18n';

import {
	selectActiveOrderDetails,
	selectListAllLoaded,
	selectListData,
	selectListLoading,
	selectListTotal,
	selectPagination,
} from '../../../selectors';
import { actions } from '../../../slice';
import { History } from './History';
import { SourceTable } from './SourceTable';

export const Details = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();

	const list = useSelector(selectListData);
	const totalList = useSelector(selectListTotal);
	const pagination = useSelector(selectPagination);
	const listLoading = useSelector(selectListLoading);
	const listAllLoaded = useSelector(selectListAllLoaded);
	const activeOrderDetails = useSelector(selectActiveOrderDetails);
	const detailsLoading = activeOrderDetails.loading;

	const sourceOrders = activeOrderDetails.data?.list;
	const isPreHedge = activeOrderDetails.data?.source === OrderSource.preHedge;
	const emptyList = !!list && list.length === 0;
	const isFilled =
		activeOrderDetails &&
		activeOrderDetails?.data &&
		activeOrderDetails?.data?.status === OrderStatus.Filled &&
		!activeOrderDetails?.data?.list?.length;

	const loadSummary = (item) => {
		dispatch(
			actions.loadOrderDetail({
				id: item.id,
			}),
		);
	};

	const loadList = (reset) => {
		dispatch(actions.loadReviewAndReleaseList(reset));
	};

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadReviewAndReleaseList());
	};

	const handleSearch = (searchText) => {
		dispatch(actions.setActiveOrderDetails(null));
		dispatch(actions.setStartPagination({ start: 1 }));
		dispatch(
			actions.setSelectedFilters({
				contractOrCustomer: searchText,
			}),
		);
		dispatch(actions.loadReviewAndReleaseList());
	};

	const handleCloseDetails = () => {
		dispatch(
			actions.setSelectedFilters({
				sortColumnName: null,
				number: null,
			}),
		);
		dispatch(actions.setCurrentView(ReviewAndReleaseViews.table));
	};

	return (
		<div className="review-release-details">
			<Card className="left-panel" bordered={false}>
				<div className="left-panel__filter">
					<div className="left-panel__filter">
						<Filter
							placeholder={translate(
								translations.app.containers.Details.Filter.placeholder
									.contractCustomer,
							)}
							handleSearch={handleSearch}
						/>
					</div>
				</div>
				<ListWrapper
					list={list}
					listLoading={listLoading}
					listLoaded={listAllLoaded}
					activeItem={activeOrderDetails}
					loadItemSummary={loadSummary}
					loadItemList={loadList}
					transactionType={'reviewAndRelease'}
					pagination={{
						showTotal: (total, range) =>
							translate(translations.app.components.GenericTable.showTotal, {
								range1: range[0],
								range2: range[1],
								total: total,
							}),
						onChange: (page, pageSize) => handleOnChange(page, pageSize),
						total: totalList,
						pageSize: pagination.limit,
						currentPage: pagination.start,
						defaultCurrent: pagination.start,
						showSizeChanger: false,
						showLessItems: true,
						role: 'list-pagination',
						size: 'small',
					}}
				/>
			</Card>
			<div className="right-panel">
				<Card className="right-panel__summary" bordered={false}>
					{emptyList && (
						<div className="close-icon">
							<CloseOutlined
								data-testid="details-close-icon"
								onClick={handleCloseDetails}
							/>
						</div>
					)}
					<Summary
						activeView={SummaryView.reviewAndRelease}
						activeEntity={activeOrderDetails}
						listLoading={listLoading}
						isCollapsable={false}
						handleClose={handleCloseDetails}
					/>
				</Card>
				{!emptyList && (
					<Card
						bordered={true}
						className="right-panel__children"
						data-testid="source-table"
					>
						{detailsLoading ? (
							<div
								className="review-release-details__spinner-container"
								data-testid="spinner-loading"
							>
								<Spin key="spinner" />
							</div>
						) : sourceOrders?.length && !detailsLoading && !isFilled ? (
							<>
								<SourceTable />
								<History />
							</>
						) : isFilled ? (
							<></>
						) : isPreHedge ? (
							<History />
						) : (
							<FilterEmpty
								description={translate(
									translations.app.containers.ReviewAndRelease.Details
										.noSourceOrders,
								)}
							/>
						)}
					</Card>
				)}
			</div>
		</div>
	);
};
