import './styles.scss';

import { Spin } from 'antd';
import React from 'react';

import { useOrderEntryData } from './hooks/useOrderEntryData';
import { ORDER_ENTRY_DOMAIN } from './utils/helpers';
import { OrderEntryForm } from './Views/OrderEntryForm';
import { ToggleForm } from './Views/ToggleForm';

export const OrderEntry = () => {
	const { hasCustomFields, isLoading, translate } = useOrderEntryData();

	const getTranslation = (key: string, values?: any) =>
		translate(ORDER_ENTRY_DOMAIN[key], values);

	if (isLoading) {
		return <Spin data-testid="spinner-loading" key="spinner" />;
	}

	return (
		<div className="order-entry" data-testid="order-entry-form">
			<p className="header">{getTranslation('header')}</p>
			<ToggleForm />
			{hasCustomFields && <OrderEntryForm />}
		</div>
	);
};
