import { PayloadAction } from '@reduxjs/toolkit';
import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { futureMonthDTOSchema } from 'types/FutureMonth';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';
import { LoadFuturesMontOptionsPayload } from '../types';

const { futuresMonthOptions } = apiEndpoints;

export function* getFuturesMonthOptions(
	action: PayloadAction<LoadFuturesMontOptionsPayload>,
) {
	const { commodityId, excludeExpired } = action.payload.params;
	const source = action.payload.source;
	const requestURL = new URL(`${apiRoutes.base}/${futuresMonthOptions}`);
	requestURL.searchParams.append('commodityId', commodityId);

	if (excludeExpired) {
		requestURL.searchParams.append('excludeExpired', excludeExpired.toString());
	}

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(futureMonthDTOSchema),
	});

	if (!!data) {
		yield* put(
			actions.futuresMonthOptionsLoaded({
				source,
				data,
			}),
		);
	} else if (!!error?.detail) {
		yield* put(actions.futuresMonthError({ error, source }));
	}
}
