import './style.scss';

import { Radio as AntRadios } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';
import classnames from 'classnames';
import React, { memo } from 'react';

interface Props extends Omit<RadioGroupProps, 'onChange' | 'value'> {
	disabledMode?: string;
	message?: JSX.Element;
	customClass?: string;
	optionType?: 'button' | 'default';
}

export const RadioButtons = memo(function RadioButtons(props: Props) {
	const {
		disabledMode,
		customClass,
		message,
		optionType = 'button',
		...otherProps
	} = props;

	return (
		<>
			<AntRadios.Group
				{...otherProps}
				buttonStyle="solid"
				optionType={optionType}
				className={classnames('radios-form__buttons', customClass, {
					'disabled-mode--single': !!disabledMode && disabledMode === 'single',
					'disabled-mode--multi': !!disabledMode && disabledMode === 'multi',
				})}
			/>
			{message}
		</>
	);
});
