import React from 'react';

import { translations } from 'locales/i18n';

import { ReduceQuantityModal } from '../shared/GenericComponents/ReduceQuantityModal';

export const CancelModal = () => {
	const cancelOrderText =
		translations.app.containers.OrderContainer.components.cancelModal;
	return (
		<ReduceQuantityModal
			defaultMessageWithNumber={cancelOrderText.cancelMessage}
			defaultMessage={cancelOrderText.defaultCancelMessage}
			titleMessage={cancelOrderText.cancelPreHedge}
		/>
	);
};
