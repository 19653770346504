import React, { memo } from 'react';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';

const email = 'email';

export const Email = memo(function Email() {
	const translations = UseFieldTranslations();

	return (
		<GenericForm.FormItem
			data-testid={`${email}-form-item`}
			label={translations[email].label}
			name={email}
		>
			<Input name={email} placeholder={translations[email].placeholder} />
		</GenericForm.FormItem>
	);
});
