/**
 *
 * Menu
 *
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { settingsActions } from '../../slice';
import { userRolesActions } from '../UserRoles/slice';
import { CreateEmployee } from './Modals/CreateEmployee';
import { EditEmployee } from './Modals/EditEmployee';
import { RolesLocations } from './Modals/Locations/RolesLocations';
import { employeesSaga } from './sagas';
import {
	selectActiveEditEmployee,
	selectCurrentModal,
	selectCurrentView,
} from './selectors';
import { reducer, sliceKey } from './slice';
import { Modal, View } from './types';
import { EditRoleAndPermission as EditPermissions } from './View/EditRoleAndPermission';
import { List } from './View/List';

export function Employees() {
	const activeEmployeeData = useSelector(selectActiveEditEmployee);
	const currentModal = useSelector(selectCurrentModal);
	const currentView = useSelector(selectCurrentView);
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: employeesSaga });

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(userRolesActions.loadUserRolesList());
		dispatch(settingsActions.loadLocationsListForSettings());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{currentView === View.List && <List />}
			{currentView === View.Permissions && <EditPermissions />}
			{currentModal === Modal.Create && <CreateEmployee />}
			{currentModal === Modal.Location && <RolesLocations />}
			{currentModal === Modal.Edit && activeEmployeeData && <EditEmployee />}
		</>
	);
}
