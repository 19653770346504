import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import { ConvertedToObjectType } from './types';

const translationsJson = {
	en: {
		translation: en,
	},
};

type TranslationResource = typeof en;

export const translations: ConvertedToObjectType<TranslationResource> =
	{} as any;

/*
 * Converts the static JSON file into an object where keys are identical
 * but values are strings concatenated according to syntax.
 * This is helpful when using the JSON file keys and still have the intellisense support
 * along with type-safety
 */
const convertLanguageJsonToObject = (obj: any, dict: {}, current?: string) => {
	Object.keys(obj).forEach((key) => {
		const currentLookupKey = current ? `${current}.${key}` : key;
		if (typeof obj[key] === 'object') {
			dict[key] = {};
			convertLanguageJsonToObject(obj[key], dict[key], currentLookupKey);
		} else {
			dict[key] = currentLookupKey;
		}
	});
};

/**
 * @internal exported for automated tests
 */
export const i18n = i18next
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init(
		{
			resources: translationsJson,

			fallbackLng: 'en',
			debug:
				process.env.NODE_ENV !== 'production' &&
				process.env.NODE_ENV !== 'test',

			interpolation: {
				escapeValue: false, // not needed for react as it escapes by default
				prefix: '{',
				suffix: '}',
			},
		},
		() => {
			convertLanguageJsonToObject(en, translations);
		},
	);
