import { call, put, select } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { selectNoBidMappedData, selectSelectedNoBid } from '../selectors';
import { actions } from '../slice';

const { offersOrphans, availableBids } = apiEndpoints;

export function* orphansAndAvailableList() {
	const selectedNoBid: string = yield select(selectSelectedNoBid);
	const filters: any = yield select(selectNoBidMappedData);

	const tableFilters: any = filters[selectedNoBid];
	const requestTableURL = yield new URL(`${apiRoutes.base}/${offersOrphans}`);

	const tableCall: any = yield call(
		genericRequest,
		requestTableURL,
		httpMethod.Post,
		tableFilters,
	);

	if (!!tableCall.responseData) {
		yield put(actions.setOrphansList(tableCall.responseData.list));
	} else if (!!tableCall.responseError.detail) {
		yield put(actions.setOrphansAndAvailablesError(tableCall.responseError));
	}

	const requestAvailablesURL = yield new URL(
		`${apiRoutes.base}/${availableBids}`,
	);

	requestAvailablesURL.searchParams.append(
		'commodityId',
		tableFilters.commodityId,
	);
	requestAvailablesURL.searchParams.append(
		'deliveryLocationId',
		tableFilters.deliveryLocationId,
	);

	const requestAvailablesCall: any = yield call(
		genericRequest,
		requestAvailablesURL,
		httpMethod.Get,
	);

	if (!!requestAvailablesCall.responseData) {
		yield put(
			actions.setAvailableBidList(requestAvailablesCall.responseData.list),
		);
	} else if (!!requestAvailablesCall.responseError.detail) {
		yield put(
			actions.setOrphansAndAvailablesError(requestAvailablesCall.responseError),
		);
	}
}
