import './style.scss';

import { CloseOutlined, FilterOutlined, UpOutlined } from '@ant-design/icons';
import { Popover, Radio, Tag } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import classnames from 'classnames';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { translations } from 'locales/i18n';
import { decimalSignFormat } from 'utils/helpers';

import { selectSelectedCropYear } from '../../selectors';
import { CropYearBalance } from '../../types';

interface Props {
	optionsList: CropYearBalance[];
	onSelected: (string: number | null) => void;
}

export const CropYearCards = memo(function CropYearCards({
	optionsList,
	onSelected,
}: Props) {
	const { t: translate } = useTranslation();
	const scopeIntl =
		translations.app.containers.LiveLedger.components.CropYearCards;
	const value = useSelector(selectSelectedCropYear);
	const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);

	const handleExpandablePanel: React.MouseEventHandler<HTMLDivElement> = (
		e,
	) => {
		setIsPanelCollapsed(!isPanelCollapsed);
	};

	const preventToggleOnSelectCrop: React.MouseEventHandler<HTMLSpanElement> = (
		event,
	) => {
		event.stopPropagation();
	};

	const handleChange = (event: RadioChangeEvent) => {
		onSelected(event.target.value);
	};

	const handleRadioReset: React.MouseEventHandler<HTMLInputElement> = (
		event,
	) => {
		event.stopPropagation();
		event.persist();
		if (Number(event.currentTarget.value) === value) {
			onSelected(null);
		}
	};

	return (
		<>
			<div
				className="live-ledger__panel-container"
				onClick={handleExpandablePanel}
			>
				{isPanelCollapsed && (
					<div className="live-ledger__crop-year-cards-title">
						<div className="left">
							<Radio.Group
								size="large"
								className="live-ledger__crop-pills"
								onChange={handleChange}
								name="radiogroupHeader"
								value={value}
							>
								{optionsList.slice(0, 4).map((option) => (
									<Radio.Button
										key={`pill-crop-${option.cropYear}`}
										value={option.cropYear}
										onClick={handleRadioReset}
										className="live-ledger__crop-pill"
										data-testid={`pill-crop-${option.cropYear}`}
										disabled={!option.isActive}
									>
										<Popover
											visible={option.isActive ? undefined : false}
											content={
												<div style={{ padding: '0 1rem' }}>
													{value === option.cropYear
														? translate(scopeIntl.clearFilter)
														: translate(scopeIntl.filter)}
												</div>
											}
										>
											<Tag
												onClick={preventToggleOnSelectCrop}
											>{`${option.cropYear} `}</Tag>
										</Popover>
									</Radio.Button>
								))}
							</Radio.Group>
						</div>
					</div>
				)}

				<Radio.Group
					size="large"
					onChange={handleChange}
					name="radiogroup"
					className={classnames({
						'live-ledger__crop-year-cards': true,
						'live-ledger__crop-year-cards--collapsed': isPanelCollapsed,
					})}
					value={value}
				>
					{optionsList.slice(0, 4).map((option) => (
						<Radio.Button
							key={`large-button-${option.cropYear}`}
							value={option.cropYear}
							className="live-ledger__crop-year-card"
							onClick={handleRadioReset}
							data-testid={`large-button-${option.cropYear}`}
							disabled={!option.isActive}
						>
							<div
								className="selection-wrapper"
								onClick={preventToggleOnSelectCrop}
							>
								<div
									className="live-ledger__crop-year-card__title"
									data-testid={`crop-year-${option.cropYear}-balance-title`}
								>
									{translate(scopeIntl.balance, { cropYear: option.cropYear })}
								</div>
								<div
									className="live-ledger__crop-year-card__balance"
									data-testid={`crop-year-${option.cropYear}-balance`}
								>
									{decimalSignFormat(option.totalBalance)}
								</div>
								<div
									className="live-ledger__crop-year-card__beginning"
									data-testid={`crop-year-${option.cropYear}-beginning-balance`}
								>
									{translate(scopeIntl.beginning, {
										value: decimalSignFormat(option.beginningBalance),
									})}
								</div>
								<div className="selection" />
								{option.isActive && (
									<div
										className="live-ledger__crop-year-card__filter"
										data-testid={`crop-year-${option.cropYear}-balance-filter`}
									>
										<Popover
											content={
												<div style={{ padding: '0 1rem' }}>
													{value === option.cropYear
														? translate(scopeIntl.clearFilter)
														: translate(scopeIntl.filter)}
												</div>
											}
										>
											{value === option.cropYear ? (
												<CloseOutlined />
											) : (
												<FilterOutlined />
											)}
										</Popover>
									</div>
								)}
							</div>
						</Radio.Button>
					))}
					<div className="live-ledger__crop-year-card live-ledger__crop-year-card--total">
						<div
							className="selection-wrapper"
							onClick={preventToggleOnSelectCrop}
						>
							<div
								className="live-ledger__crop-year-card__title"
								data-testid="balance-total-title"
							>
								{translate(scopeIntl.total)}
							</div>
							<div
								className="live-ledger__crop-year-card__balance"
								data-testid="balance-total-balance"
							>
								{decimalSignFormat(
									optionsList
										.slice(0, 4)
										.reduce((acc, cur) => acc + cur.totalBalance, 0),
								)}
							</div>
							<div
								className="live-ledger__crop-year-card__beginning"
								data-testid="balance-total-beginning-balance"
							>
								{translate(scopeIntl.beginning, {
									value: decimalSignFormat(
										optionsList
											.slice(0, 4)
											.reduce((acc, cur) => acc + cur.beginningBalance, 0),
									),
								})}
							</div>
							<div className="selection" />
						</div>
					</div>
				</Radio.Group>
			</div>

			<UpOutlined
				className={classnames({
					'live-ledger__collapse-icon': true,
					'live-ledger__collapse-icon--collapsed': isPanelCollapsed,
				})}
				onClick={handleExpandablePanel}
				data-testid="collapse-icon"
			/>
		</>
	);
});
