import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { commoditiesDNH, commoditiesDNHList } = apiEndpoints;

export function* getDNHListSaga() {
	const requestURL = yield new URL(`${apiRoutes.base}/${commoditiesDNHList}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
		true,
	);

	if (!!responseData) {
		yield put(actions.getDNHListSuccess(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.getDNHListError(responseError));
	}
}

export function* setDNHListSaga(action: PayloadAction<any>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${commoditiesDNH}`);

	const dnhListData = action?.payload?.data;

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		dnhListData,
		true,
	);

	if (!!responseData) {
		yield put(actions.setDNHListSuccess(responseData));
		yield put(actions.setIsModalOpen(false));
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
	} else if (!!responseError?.detail) {
		yield put(actions.setDNHListError(responseError));
	}
}
