import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { CropYearsList } from 'app/containers/Settings/sections/Commodities/components/CropsDates';
import { translations } from 'locales/i18n';
import { CROP_LIST_ITERATOR } from 'utils/constants';
import {
	generateCalendar,
	generateDropdown,
	generateMultiField,
} from 'utils/GenericFormInputs/generic-form-inputs';
import {
	calculateEndDate,
	dateFormatSplited,
	yearsToLabelValue,
} from 'utils/helpers';

import { ValidationTextViewer } from '../../components/ValidationTextViewer';
import { selectActiveCommodity } from '../../selectors';

/**
 * @internal exported for automated tests
 */
export const generateCropDatePicker = (formFields) => {
	return <CropYearsList {...formFields} />;
};

const textViewer = (formFields) => {
	return <ValidationTextViewer {...formFields} />;
};

export let cropYearsObject = {
	addCropYear0: false,
	addCropYear1: false,
	addCropYear2: false,
};

export const useCropRangePicker = (isEdit = false) => {
	const { t: translate } = useTranslation();
	const domain = translations.app.containers.Settings.CropYear;
	const activeCommodity = useSelector(selectActiveCommodity);
	const currentYear = new Date();
	const fullYear = currentYear.getFullYear();
	const yearsList = yearsToLabelValue([fullYear - 1, fullYear]);
	const [cropList, setCropList] = useState<any>([]);
	const [cropYear, setCropYear] = useState(fullYear);
	const [disabled, setDisabled] = useState(true);
	const [stateUpdate, setStateUpdate] = useState(true);
	const [validationMessage, setValidationMessage] =
		useState('Select a Crop Year');
	const generateList = (startDate?, endDate?) => {
		let cropsList: string[] = [];
		if (startDate && endDate) {
			for (let i = 0; i < CROP_LIST_ITERATOR; i++) {
				const startText = `${parseInt(startDate.year) + i} - ${startDate.month}, ${startDate.day}`;
				const endText = `${endDate.month} ${endDate.day}, ${parseInt(endDate.year) + i}`;
				cropsList.push(`${startText} - ${endText}`);
			}
			setCropList(cropsList);
		} else {
			setCropList([]);
		}
	};
	if (isEdit && activeCommodity && stateUpdate) {
		generateList(
			dateFormatSplited(activeCommodity?.cropYearStartDate),
			dateFormatSplited(activeCommodity?.cropYearEndDate),
		);
		setStateUpdate(false);
	}
	const isDateRangeSelected = cropList.length;
	const handleCropYearSelection = ({ value }) => {
		generateList();
		setCropYear(value);
	};
	const handleDateRangeSelection = (startDate) => {
		if (startDate) {
			const endDate = calculateEndDate(startDate);
			const startDateFormatted = dateFormatSplited(startDate);
			const endDateFormatted = dateFormatSplited(endDate);
			generateList(startDateFormatted, endDateFormatted);
		}
	};

	const handleChangeCheckbox = ({ target }) => {
		let { checked, name } = target;

		setDisabled(!checked);

		//update cropYearsObject with reference to name from target and value from checked
		cropYearsObject[name] = checked;

		if (!cropYearsObject.addCropYear0) {
			setValidationMessage('First Crop Year is required');
			return false;
		}
		if (!cropYearsObject.addCropYear0 && cropYearsObject.addCropYear1) {
			setValidationMessage('First Crop Year is required');
			return false;
		}
		if (
			cropYearsObject.addCropYear0 &&
			!cropYearsObject.addCropYear1 &&
			cropYearsObject.addCropYear2
		) {
			setValidationMessage('Second Crop Year is required');
			return false;
		}
		if (
			!cropYearsObject.addCropYear0 &&
			cropYearsObject.addCropYear1 &&
			cropYearsObject.addCropYear2
		) {
			setValidationMessage('First Crop Year is required');
			return false;
		}
		if (
			!cropYearsObject.addCropYear0 &&
			!cropYearsObject.addCropYear1 &&
			cropYearsObject.addCropYear2
		) {
			setValidationMessage('First and Second Crop Years are required');
			return false;
		}
		if (
			!cropYearsObject.addCropYear0 &&
			!cropYearsObject.addCropYear1 &&
			!cropYearsObject.addCropYear2
		) {
			setValidationMessage('Select a Crop Year');
			return false;
		}
		setValidationMessage('');
	};

	const handleCalendarChange = (dates) => dates === null && generateList();
	const handleCustomDisableDate = (currentDate) => {
		return currentDate && currentDate < moment().year(cropYear).startOf('year');
	};
	const cropDatePicker = {
		name: 'cropDatePicker',
		label: translate(domain.cropYearLabel),
		initialValue: '',
		spans: [4, 8],
		render: generateMultiField,
		fields: [
			{
				name: 'cropYear',
				initialValue: '',
				options: yearsList,
				defaultValue: yearsList[1], //current year must be selected by default
				onSelect: (item) => handleCropYearSelection(item),
				render: generateDropdown,
			},
			{
				name: 'cropYearCalendar',
				initialValue: null,
				validation: Yup.string()
					.required(translate(domain.requiredValidation))
					.nullable(),
				isRange: false,
				key: cropYear,
				onCalendarChange: handleCalendarChange,
				disabledDate: handleCustomDisableDate,
				onSelect: handleDateRangeSelection,
				render: generateCalendar,
			},
		],
	};
	const cropYearsList = {
		name: 'cropYearList',
		label: translate(domain.cropYearSetup),
		initialValue: '',
		cropList: cropList.filter((d, i) => i !== 3),
		checkbox: false,
		disable: false,
		isDateRangeSelected,
		render: generateCropDatePicker,
	};
	const editCropYearsList = {
		name: 'cropYearList',
		label: translate(domain.cropYearSetup),
		initialValue: '',
		cropList: cropList.filter(
			(_, i) => i <= (activeCommodity?.numberOfCropYears || 0) - 1,
		),
		checkbox: false,
		disable: false,
		isDateRangeSelected,
		render: generateCropDatePicker,
	};
	const addCropYear = {
		name: 'addCropYear',
		label: translate(domain.addCropYearLabel),
		initialValue: false,
		cropList: cropList.filter((_, i) => i === 3),
		checkbox: true,
		disable: disabled,
		isDateRangeSelected,
		handleChange: handleChangeCheckbox,
		render: generateCropDatePicker,
	};
	const conditionallyAddCropYear = cropList
		.filter((_, i) => i > (activeCommodity?.numberOfCropYears || 0) - 1)
		.map((item, i) => ({
			name: `addCropYear${i}`,
			label:
				i < (activeCommodity?.numberOfCropYears || 1)
					? translate(domain.addCropYearLabel)
					: '  ',
			initialValue: false,
			cropList: [item],
			checkbox: i < (activeCommodity?.numberOfCropYears || 1),
			colon: i < (activeCommodity?.numberOfCropYears || 1),
			isDateRangeSelected,
			handleChange: handleChangeCheckbox,
			render: generateCropDatePicker,
		}));

	const viewError = activeCommodity?.hasAdditionalCrop
		? {}
		: {
				name: 'validationText',
				label: validationMessage,
				render: textViewer,
				// validation that this sting needs to be empty otherwise it will show the error
				validation:
					validationMessage === ''
						? Yup.string()
						: Yup.string().required(translate(domain.requiredValidation)),
			};

	if (isEdit) {
		return [editCropYearsList, ...conditionallyAddCropYear, viewError];
	}
	return [cropDatePicker, cropYearsList, addCropYear];
};
