import { call, put, select } from 'redux-saga/effects';

import { genericRequest, httpMethod } from 'utils/request';

import { apiEndpoints } from '../../../../utils/api-endpoints';
import { apiRoutes } from '../../../../utils/api-routes';
import { selectSelectedFilters } from '../selectors';
import { actions } from '../slice';

const { cashbids } = apiEndpoints;

/**
 * Reducer : loadCashbids
 * @method: POST /api/bidsdata/hrvystfilter
 */
export function* getCashbidsData() {
	const selectedFilters: any = yield select(selectSelectedFilters);
	const filters = {
		commodityId:
			selectedFilters.cashbidsCommodities?.find(
				(element) => element.value !== 'all',
			)?.value || '',
		deliveryLocationId:
			selectedFilters.locations
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || [],
		cropYear:
			selectedFilters.cropYears
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || [],
	};

	if (filters.commodityId === '') return;

	const requestUrl = yield new URL(`${apiRoutes.base}/${cashbids}`);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestUrl,
		httpMethod.Post,
		filters,
	);
	//success actions
	if (!!responseData) {
		yield put(actions.CashbidListLoaded(responseData));
		return;
	}
	// error actions
	if (!!responseError) {
		yield put(actions.CashbidListError(responseError));
		return;
	}
}
