/**
 *
 * GenericForm
 *
 */

import { Form } from './Form';
import { FormButton } from './FormButton';
import { FormItem } from './FormItem';
import { ModalContainer } from './ModalContainer';

export const GenericForm = {
	Form,
	FormItem,
	ModalContainer,
	FormButton,
};
