import { CONSTANTS, EMPTY_COLUMN } from 'utils/constants';
import { currencyFormat } from 'utils/helpers';

export const getColumnValue = (value: string | undefined) =>
	value || EMPTY_COLUMN;

export const getFormattedCurrency = (value: number) =>
	!!value
		? currencyFormat(value, CONSTANTS.FIXED_DECIMALS, CONSTANTS.FIXED_DECIMALS)
		: EMPTY_COLUMN;
