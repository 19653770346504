import { PayloadAction } from '@reduxjs/toolkit';

import { Location } from 'types/Location';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState, Sections } from './types';

// The initial state of the Settings container
export const initialState: ContainerState = {
	activeSection: Sections.Customers,
	locationsForSettings: {
		data: [],
		loading: false,
		error: null,
	},
	isLocationPermissionStateUpdated: false,
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setActiveSection(state, action: PayloadAction<Sections>) {
			state.activeSection = action.payload;
		},

		changeLocationPermissionState(state, action: PayloadAction<boolean>) {
			state.isLocationPermissionStateUpdated = action.payload;
		},
		// Setting Locations List
		loadLocationsListForSettings(state) {
			state.locationsForSettings = {
				...state.locationsForSettings,
				loading: true,
			};
		},
		locationsListForSettingsLoaded(state, action: PayloadAction<Location[]>) {
			state.locationsForSettings = {
				...state.locationsForSettings,
				data: action.payload,
				loading: false,
			};
		},
		locationsListForSettingsError(state, action: PayloadAction<any>) {
			state.locationsForSettings = {
				...state.locationsForSettings,
				loading: false,
				error: action.payload,
			};
		},
	},
});

export const {
	actions: settingsActions,
	reducer: settingsReducer,
	name: settingsSliceKey,
} = settingsSlice;
