import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { FutureMonth } from 'types/FutureMonth';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { getSelectedFilter } from 'utils/helpers';
import { genericRequest, httpMethod } from 'utils/request';

import { selectPagination, selectSelectedFilters } from '../selectors';
import { actions } from '../slice';

const { futuresErp, futuresErpIntegrate, futuresMonthOptions } = apiEndpoints;

export function* getFuturesErpList() {
	const requestURL = yield new URL(`${apiRoutes.base}/${futuresErp}`);
	const pagination = yield select(selectPagination);
	const selectedFilters = yield select(selectSelectedFilters);

	const filters = {
		...pagination,
		...selectedFilters,
		commodityId: getSelectedFilter('commodityId', 'value', selectedFilters),
		erpStatus: selectedFilters.erpStatus[0]?.value || [],
		futuresMonth: getSelectedFilter('futuresMonth', 'value', selectedFilters),
	};

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		filters,
	);

	if (!!responseData) {
		const { total, list } = responseData;
		yield put(actions.futuresErpListLoaded({ data: list, total }));
	} else if (!!responseError.detail) {
		yield put(actions.futuresErpListError(responseError));
	}
}

export function* integrateToErp(action: PayloadAction<string[]>) {
	const requestURL = yield new URL(`${apiRoutes.base}/${futuresErpIntegrate}`);

	const data = action.payload.map((str) => ({ id: str }));

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	if (!!responseData) {
		yield put(actions.loadFuturesErpList());

		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
	} else if (!!responseError.detail) {
		yield put(actions.integrateToErpError(responseError));
	}
}

export function* getFuturesMonths() {
	const requestURL = yield new URL(`${apiRoutes.base}/${futuresMonthOptions}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		const data: FutureMonth[] = responseData.map((item) => ({
			id: item.name,
			...item,
		}));

		yield put(actions.getFuturesMonthsSuccess(data));
	} else if (!!responseError) {
		yield put(actions.getFuturesMonthsError(responseError));
	}
}
