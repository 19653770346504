import React from 'react';
import { useSelector } from 'react-redux';

import Yup from 'yupCustom';

import { HedgeIntegrationSwitch } from '../components/HedgeIntegrationSwitch';
import { selectHedgeIntegration } from '../selectors';
import { SWITCH_FORM_ITEMS } from '../types';

export const useHedgeIntegrationSwitchFormSchema = () => {
	const hedgeIntegration = useSelector(selectHedgeIntegration);

	return {
		initialValues: {
			[SWITCH_FORM_ITEMS.HedgeIntegration]:
				hedgeIntegration?.[SWITCH_FORM_ITEMS.HedgeIntegration],
		},
		validationSchema: Yup.object().shape({
			[SWITCH_FORM_ITEMS.HedgeIntegration]: Yup.boolean(),
		}),
		elements: [<HedgeIntegrationSwitch />],
	};
};
