/**
 *
 * Details
 *
 */
import './style.scss';

import { CloseOutlined } from '@ant-design/icons';
import { Card, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { OffersModals } from 'app/containers/Offers/types';
import { Filter } from 'app/containers/Transactions/components/Filter';
import { History } from 'app/containers/Transactions/components/History';
import { ListWrapper } from 'app/containers/Transactions/components/ListWrapper';
import { Summary } from 'app/containers/Transactions/components/Summary';
import { SummaryView } from 'app/containers/Transactions/types';
import { translations } from 'locales/i18n';

import {
	selectActiveOfferDetails,
	selectOffersList,
	selectOffersListLoading,
	selectPagination,
	selectTotalOffers,
} from '../../../selectors';
import { actions } from '../../../slice';

export const Details = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();

	const offerList = useSelector(selectOffersList);
	const offerListLoading = useSelector(selectOffersListLoading);
	const activeOfferDetails = useSelector(selectActiveOfferDetails);
	const totalList = useSelector(selectTotalOffers);
	const pagination = useSelector(selectPagination);
	const loading = activeOfferDetails.loading;

	const emptyList = !!offerList && offerList.length === 0;

	const loadOfferSummary = (item) => {
		dispatch(actions.loadOfferSummary(item.id));
	};

	const loadOfferList = (reset) => {
		dispatch(actions.loadOffersList(reset));
	};

	const handleCloseDetails = () => {
		dispatch(
			actions.setSelectedFilters({
				numberOrCustomer: '',
			}),
		);
		dispatch(actions.setCurrentView(null));
	};

	const handleSearch = (searchText) => {
		dispatch(actions.setActiveOfferDetails(null));
		dispatch(
			actions.setSelectedFilters({
				numberOrCustomer: searchText,
			}),
		);
		dispatch(actions.loadOffersList(true));
	};

	const handleCreate = () => {
		dispatch(actions.setCurrentModal(OffersModals.Create));
	};

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadOffersList(false));
	};

	return (
		<div className="offer-details">
			<Card className="left-panel" bordered={true}>
				<div className="left-panel__filter">
					<Filter
						handleSearch={handleSearch}
						handleCreate={handleCreate}
						placeholder={translate(
							translations.app.containers.Details.Filter.placeholder
								.offerCustomer,
						)}
					/>
				</div>
				<ListWrapper
					list={offerList}
					listLoading={offerListLoading}
					activeItem={activeOfferDetails}
					loadItemSummary={loadOfferSummary}
					pagination={{
						showTotal: (total, range) =>
							translate(translations.app.components.GenericTable.showTotal, {
								range1: range[0],
								range2: range[1],
								total: total,
							}),
						onChange: (page, pageSize) => handleOnChange(page, pageSize),
						total: totalList,
						pageSize: pagination.limit,
						currentPage: pagination.start,
						defaultCurrent: pagination.start,
						showSizeChanger: false,
						showLessItems: true,
						role: 'list-pagination',
						size: 'small',
					}}
					loadItemList={loadOfferList}
					transactionType="offer"
				/>
			</Card>
			<div className="right-panel">
				<Card className="right-panel__summary" bordered={false}>
					{emptyList && (
						<div className="close-icon">
							<CloseOutlined
								data-testid="details-close-icon"
								onClick={handleCloseDetails}
							/>
						</div>
					)}
					<Summary
						activeView={SummaryView.offers}
						activeEntity={activeOfferDetails}
						listLoading={offerListLoading}
						isCollapsable={true}
						handleClose={handleCloseDetails}
					/>
				</Card>
				{!emptyList && (
					<Card
						bordered={true}
						className="right-panel__children"
						data-testid="children-table"
					>
						{loading ? (
							<div className="contract-details__spinner-container">
								<Spin key="spinner" />
							</div>
						) : (
							<History
								transactionType="Offer"
								activeTransactionDetails={activeOfferDetails}
							/>
						)}
					</Card>
				)}
			</div>
		</div>
	);
};
