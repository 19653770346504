import './style.scss';

import { Button, Col, Row } from 'antd';
import { FieldArray, useFormikContext } from 'formik';
import produce from 'immer';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { FormSwitch } from 'app/components/FormSwitch';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';
import { settingsActions } from 'app/containers/Settings/slice';
import { useResize } from 'utils/hooks/useResize';

interface Location {
	id?: string;
	locationId?: string;
	name?: string;
	locationName?: string;
}

interface RolesLocation {
	CanBuy: boolean;
	CanSell: boolean;
}

interface Props {
	locationsListData: Location[];
	name: string;
}

interface FormValues {
	rolesLocations: Record<string, { CanBuy: boolean; CanSell: boolean }>;
}

export const RolesLocationsEmployee = memo(function RolesLocationsEmployee({
	locationsListData,
	name,
}: Props) {
	const translations = UseFieldTranslations();
	const dispatch = useDispatch();
	const buyTitle = translations.createEditRoleView.labels.locationOperationBuy;
	const sellTitle =
		translations.createEditRoleView.labels.locationOperationSell;
	const enableAllTitle = translations.enableAll;
	const disableAllTitle = translations.disableAll;
	const { isDesktop } = useResize();
	const canBuyType = 'CanBuy';
	const canSellType = 'CanSell';
	let {
		setFieldValue,
		values: { rolesLocations },
	} = useFormikContext<FormValues>();

	const handleChange = (locationId: string, type: keyof RolesLocation) => {
		const updatedRolesLocations = produce(rolesLocations, (draft) => {
			if (locationId in draft) {
				const currentLocation = draft[locationId];
				if (type in currentLocation) {
					currentLocation[type] = !currentLocation[type];
				}
			}
		});
		setFieldValue('rolesLocations', updatedRolesLocations);
		dispatch(settingsActions.changeLocationPermissionState(true));
	};
	const bulkEnableDisable = (enable: boolean) => {
		const updatedRolesLocations = produce(rolesLocations, (draft) => {
			locationsListData.forEach((location) => {
				const locationId = location?.id ?? location?.locationId ?? '';
				if (locationId in draft) {
					draft[locationId][canBuyType] = enable;
					draft[locationId][canSellType] = enable;
				}
			});
		});
		setFieldValue('rolesLocations', updatedRolesLocations);
		dispatch(settingsActions.changeLocationPermissionState(true));
	};

	const bulkEnable = () => bulkEnableDisable(true);

	const bulkDisable = () => bulkEnableDisable(false);

	const range = (): number[] => {
		let arr: number[] = [];
		let num = 1;
		for (let i = 0; i < 45; i++) {
			if (i === 1) {
				arr.push(1);
			} else {
				num = num + (isDesktop ? 4 : 3);
				arr.push(num);
			}
		}
		return arr;
	};

	const middleRows = range();
	const rowTitles: number[] = [0, 1, 2];
	if (isDesktop) {
		rowTitles.push(3);
	}

	return (
		<>
			<Row>
				<Button type="primary" htmlType="button" onClick={bulkEnable}>
					{enableAllTitle}
				</Button>
				<Button type="primary" htmlType="button" onClick={bulkDisable}>
					{disableAllTitle}
				</Button>
			</Row>
			<FieldArray name={name}>
				{() => (
					<Row gutter={16}>
						{locationsListData &&
							locationsListData.map((location, index) => {
								const locationId = location?.id ?? location?.locationId ?? '';
								const canBuyType = 'CanBuy';
								const canSellType = 'CanSell';
								return (
									<Col
										className="gutter-row"
										span={isDesktop ? 3 : 4}
										key={locationId}
									>
										{rowTitles.includes(index) && (
											<Row
												style={{
													backgroundColor: middleRows.includes(index)
														? '#EAEAEB'
														: '',
												}}
											>
												<Col flex={88} />
												<Col flex={6}>
													<label>{buyTitle}</label>
												</Col>
												<Col flex={5}>
													<label>{sellTitle}</label>
												</Col>
											</Row>
										)}
										<Row
											style={{
												backgroundColor: middleRows.includes(index)
													? '#EAEAEB'
													: '',
											}}
										>
											<Col flex={80} className="role-employee-location__label">
												<label>{location.name || location.locationName}</label>
											</Col>

											<div className="role-employee-location__switches">
												<Col flex={10}>
													<FormSwitch
														name={`rolesLocations.${locationId}.${canBuyType}`}
														size="small"
														handleChange={() =>
															handleChange(locationId, canBuyType)
														}
														isValueArray={{
															keyValue: 'rolesLocations',
															property: canBuyType,
															index: locationId,
														}}
													/>
												</Col>
												<Col flex={10}>
													<FormSwitch
														name={`rolesLocations.${locationId}.${canSellType}`}
														size="small"
														handleChange={() =>
															handleChange(locationId, canSellType)
														}
														isValueArray={{
															keyValue: 'rolesLocations',
															property: canSellType,
															index: locationId,
														}}
													/>
												</Col>
											</div>
										</Row>
									</Col>
								);
							})}
					</Row>
				)}
			</FieldArray>
		</>
	);
});
