import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from './slice';

const { bypassReviewTag50EmployeesList, bypassReviewTag50Settings } =
	apiEndpoints;

/**
 * @internal exported for automated test
 */
export function* getTag50Settings() {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${bypassReviewTag50Settings}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.tag50SettingsLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.tag50SettingsError(responseError));
	}
}

/**
 * @internal exported for automated test
 */
export function* saveTag50Settings(action: PayloadAction<any>) {
	const { data, successMessage } = action.payload;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${bypassReviewTag50Settings}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	// Success actions
	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);

		yield put(actions.updateTag50SettingsSuccess(null));
		yield put(actions.tag50SettingsLoaded(data));
	} else if (!!responseError.detail) {
		yield put(actions.updateTag50SettingsError(responseError));
	}
}

/**
 * @internal exported for automated test
 */
export function* getTag50EmployeesList() {
	const requestURL = yield new URL(
		`${apiRoutes.base}/${bypassReviewTag50EmployeesList}`,
	);

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.tag50EmployeesListLoaded(responseData));
	} else if (!!responseError.detail) {
		yield put(actions.tag50EmployeesListError(responseError));
	}
}

export function* bypassReviewSaga() {
	yield takeLatest(actions.loadTag50Settings.type, getTag50Settings);
	yield takeLatest(actions.loadTag50EmployeesList.type, getTag50EmployeesList);
	yield takeLatest(actions.updateTag50Settings.type, saveTag50Settings);
}
