import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { selectUserInfo } from 'app/containers/GlobalSaga/selectors';
import { UserInfo } from 'types/UserInfo';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { DEFAULT_ALERT_TONE } from 'utils/constants';
import { getAlertToneById } from 'utils/notifications';
import { genericRequest, httpMethod } from 'utils/request';

import { selectActiveAlertTone, selectUserNotifications } from '../selectors';
import { actions } from '../slice';

export function* dismissAllNotifications() {
	const { dismissAllNotifications } = apiEndpoints;
	const notificationsLoaded = yield select(selectUserNotifications);

	yield put(actions.clearNotificationsList());

	if (notificationsLoaded.data.length > 0) {
		const ids = notificationsLoaded.data.map((item) => item.id);
		const requestURL = yield new URL(
			`${apiRoutes.base}/${dismissAllNotifications}`,
		);

		yield call(genericRequest, requestURL, httpMethod.Delete, {
			notificationIds: ids,
		});
	}
}

export function* dismissNotificationItem(action: PayloadAction<string>) {
	const { dismissNotification } = apiEndpoints;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${dismissNotification}/${action.payload}`,
	);

	yield call(genericRequest, requestURL, httpMethod.Delete);
}

export function* markNotificationsAsSeen() {
	const { markNotificationsAsSeen } = apiEndpoints;
	const notificationsLoaded = yield select(selectUserNotifications);

	if (notificationsLoaded.data.length > 0) {
		const ids = notificationsLoaded.data.map((item) => item.id);
		const requestURL = yield new URL(
			`${apiRoutes.base}/${markNotificationsAsSeen}`,
		);

		yield call(genericRequest, requestURL, httpMethod.Put, {
			notificationIds: ids,
		});
	}
}

// Sets the active alert tone for the user in the store that is playing on notification events
export function* getActiveAlertTone() {
	const userInfo: UserInfo = yield select(selectUserInfo);
	const { notificationAlertTones } = apiEndpoints;
	const requestURL = yield new URL(
		`${apiRoutes.base}/${notificationAlertTones}/${userInfo.email}`,
	);
	const { responseData: alertSoundId } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);
	const selectedAlertTone = getAlertToneById(alertSoundId);

	if (selectedAlertTone) {
		yield put(actions.setActiveAlertTone(selectedAlertTone));
	} else {
		// In case the user has no alert tone selected, set the default one
		yield put(actions.setActiveAlertTone(DEFAULT_ALERT_TONE));
	}
}

export function* playAudioOnNotification() {
	const activeAudio = yield select(selectActiveAlertTone);
	const audio = new Audio(activeAudio?.src);

	try {
		yield call([audio, audio.play]);
	} catch (error) {
		console.error('Error playing audio:', error);
	}
}
