import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { CreateLocation } from './Modals/CreateLocation';
import { EditLocation } from './Modals/EditLocation';
import { locationsSaga } from './saga';
import { selectCurrentLocation, selectCurrentModal } from './selectors';
import { reducer, sliceKey } from './slice';
import { Modal } from './types';
import { List } from './View/List';

export const Locations = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: locationsSaga });

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(globalActions.loadGroupedLocationsList());
	}, [dispatch]);

	const currentModal = useSelector(selectCurrentModal);
	const currentLocationData = useSelector(selectCurrentLocation);

	return (
		<>
			<List />
			{currentModal === Modal.Create && <CreateLocation />}
			{currentModal === Modal.Edit && currentLocationData && <EditLocation />}
		</>
	);
};
