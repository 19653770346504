import { CONSTANTS } from 'utils/constants';
import { customFormat, hasMaxIntegers } from 'utils/helpers';

import { MIN_QUANTITY } from '../CreateModal';

export const validateMinMaxValue = (
	remainingBalance: number,
	value: string | undefined,
) => {
	if (value) {
		try {
			const flatNumber = parseFloat(
				customFormat(value, false, CONSTANTS.FIXED_QUANTITY_DECIMALS),
			);

			return flatNumber > 0 && flatNumber <= remainingBalance;
		} catch (error) {
			console.error(error);
			return false;
		}
	} else {
		return true;
	}
};

export const validateMinQuantity = (value: string | undefined): boolean => {
	if (value) {
		try {
			const flatNumber = parseFloat(
				customFormat(value, false, CONSTANTS.FIXED_QUANTITY_DECIMALS),
			);
			return flatNumber >= MIN_QUANTITY;
		} catch (error) {
			console.error(error);
			return false;
		}
	} else {
		return true;
	}
};

export const validatePositiveValue = (value: string) => parseFloat(value) >= 0;

export const validatePrice = (value: string | undefined) => {
	if (value) {
		try {
			const price = parseFloat(value).toFixed(CONSTANTS.FIXED_DECIMALS);

			return hasMaxIntegers(price, CONSTANTS.FIXED_FUTURES_PRICE_INTEGERS);
		} catch (error) {
			console.error(error);
			return false;
		}
	} else {
		return true;
	}
};
