import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

export const initialState: ContainerState = {
	dnhList: {
		data: [],
		error: null,
		loading: false,
	},

	dnhListUpdate: {
		data: null,
		error: null,
		loading: false,
	},

	isModalOpen: false,
};

const activateDNHModalSlice = createSlice({
	name: 'activateDNHModal',
	initialState,
	reducers: {
		getDNHListError(state, action) {
			state.dnhList = {
				...state.dnhList,
				error: action?.payload,
				loading: false,
			};
		},

		getDNHListRequest(state) {
			state.dnhList = {
				...state.dnhList,
				data: [],
				error: null,
				loading: true,
			};
		},

		getDNHListSuccess(state, action) {
			state.dnhList = {
				...state.dnhList,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		setDNHListError(state, action) {
			state.dnhListUpdate = {
				...state.dnhListUpdate,
				error: action?.payload,
				loading: false,
			};
		},

		setDNHListRequest(state, action) {
			state.dnhListUpdate = {
				...state.dnhListUpdate,
				data: null,
				error: null,
				loading: true,
			};
		},

		setDNHListSuccess(state, action) {
			state.dnhListUpdate = {
				...state.dnhListUpdate,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},

		setIsModalOpen(state, action) {
			state.isModalOpen = action.payload;
		},
	},
});

export const { actions, reducer, name: sliceKey } = activateDNHModalSlice;
