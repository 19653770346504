import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectAuthUser } from 'app/containers/GlobalSaga/selectors';

import isAuthExpired from './isAuthExpired';
import logout from './logout';

export default function useLogoutIfExpired() {
	const authUser = useSelector(selectAuthUser);
	const logoutIfExpired = useCallback(() => {
		if (authUser && isAuthExpired(authUser)) {
			logout();
		}
	}, [authUser]);
	// checkout auth validity on window focus
	useEffect(() => {
		window.addEventListener('focus', logoutIfExpired);
		return () => window.removeEventListener('focus', logoutIfExpired);
	}, [logoutIfExpired]);
	// also check on render
	useEffect(logoutIfExpired, [logoutIfExpired]);
}
