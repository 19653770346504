import './style.scss';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import {
	selectActiveContract,
	selectActiveContractLoading,
	selectSelectedRows,
} from 'app/containers/Contracts/selectors';
import { actions } from 'app/containers/Contracts/slice';
import { contract } from 'app/containers/Contracts/types';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CalculatedFieldsSource } from 'app/containers/GlobalSaga/types';
import { SourceContext } from 'app/containers/Transactions';
import { ModalHeader } from 'app/containers/Transactions/components/ModalHeader';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import {
	CONSTANTS,
	CONTRACT_TYPE_VALUES,
	CONTRACT_TYPES,
	CONTRACT_TYPES_GUIDS,
	DATE_FORMAT,
	TRANSACTION_TYPES_ENUM,
} from 'utils/constants';
import {
	customFormat,
	getFormattedExpirationDate,
	isDeliveryDateCustom,
} from 'utils/helpers';

import { useTranslations } from '../shared/useTranslations';
import { useBulkEditSchema } from './schema';

export const BulkEditModal = memo(function BulkEditModal() {
	const translations = useTranslations();
	const dispatch = useDispatch();

	const orderData = useSelector(selectActiveContract) as any;
	const orderDataList = useSelector(selectSelectedRows) as contract[];
	const isLoading = useSelector(selectActiveContractLoading);

	const currentSchema =
		useBulkEditSchema()[orderData.contractTypeName] ||
		useBulkEditSchema()['NTC'];

	const resolver = yupResolver(currentSchema.validationSchema);

	const formInstance = useForm({
		defaultValues: {
			...currentSchema.initialValues,
		},
		mode: 'all',
		resolver,
	});

	const sourceName = GlobalSagaSource.contractModal;

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
		cleanState();
	};

	const cleanState = () => {
		dispatch(globalActions.clearFuturesPrices(sourceName));
		dispatch(globalActions.clearPostedBasisPrice(sourceName));
		dispatch(globalActions.clearFuturesMonth(sourceName));
		dispatch(globalActions.clearDeliveryDates(sourceName));
		dispatch(
			globalActions.clearFuturesMonthOptions(
				CalculatedFieldsSource.Transactions,
			),
		);
	};

	const btnsDefinition: FormButtonsProps[] = [
		{
			className: 'order-form__new-order',
			type: 'default',
			htmlType: 'button',
			children: translations.buttons.cancelChanges,
			onClick: handleClose,
			disabled: isLoading,
			key: 'submit',
		},
		{
			htmlType: 'submit',
			children: translations.buttons.submitChanges,
			disabled: isLoading,
			key: 'new-order',
		},
	];

	const header = (
		<ModalHeader
			title={translations.actions.editOrderTitle}
			content={
				translations.actions.convertConfirmationMessage +
				' ' +
				translations.actions.confirmation
			}
			confirmText={translations.actions.confirmText}
			cancelText={translations.actions.cancelText}
			handleConfirm={handleClose}
		/>
	);

	const handleSubmit = (values) => {
		let data = GenerateArrayStructuredBulkAction(orderDataList, values);
		cleanState();

		dispatch(
			actions.bulkUpdateOrder({
				data,
				successMessage: translations.common.success,
			}),
		);
	};

	return (
		<GenericForm.ModalContainer
			key={'convertContractModal'}
			title={header}
			closable={false}
		>
			<SourceContext.Provider value={sourceName}>
				<GenericForm.Form
					className="order-form"
					key="convertContractForm"
					formInstance={formInstance}
					validationSchema={currentSchema?.validationSchema}
					onSubmit={handleSubmit}
					buttonsDefinition={btnsDefinition}
				>
					{currentSchema?.elements}
				</GenericForm.Form>
			</SourceContext.Provider>
		</GenericForm.ModalContainer>
	);
});

/**
 * ! How to Create a more generalised form of this frunction
 * TODO: Create a component for this finction besacuse it will be used under Contract BulkEdit
 * @description This function is used to generate array of object for bulk action
 * For Example: If we have 3 orders and we want to bulk convert them to flat price contract
 * then for bulk action we are taking 1st order as base order which is beign modified by user
 * before sending to server we are updating other 2 orders with same values as base order
 * keeping in mind some are not allowed to be modified those are kept as it is
 *
 * @param array: Array of order data
 * @param object: Object of form values or response of user
 * @returns new array of object with updated values
 */

const getExtendedContractTypeId = (contract) => {
	if (!CONTRACT_TYPES_GUIDS[contract?.value?.toUpperCase()]) {
		return contract?.value;
	} else {
		return undefined;
	}
};
function GenerateArrayStructuredBulkAction(array, object) {
	// define an array with type of object as any
	let newObject: any = {};
	let newArray = [newObject];
	newArray.pop();
	array.map((item) => {
		// item for non-editable fields : data comming from db
		// object for editable fields : objects are user input data
		let newItem: any = {
			id: item.id,
			theirContract: item.theirContract,
			number: item.contract.number,
			locationId: object.location.value,
			deliveryLocationId: object.deliveryLocation.value,
			cropYear: object.cropYear,
			isDeliveryDatesCustom: isDeliveryDateCustom(object.deliveryDatesMode),
			deliveryStartDate: object.deliveryDate?.[0]?.format(DATE_FORMAT),
			deliveryEndDate: object.deliveryDate?.[1]?.format(DATE_FORMAT),
			freightPrice: object.freight ? parseFloat(object.freight) : 0,
			fees1: object.fees1 ? parseFloat(object.fees1) : 0,
			fees2: object.fees2 ? parseFloat(object.fees2) : 0,
			quantity: parseFloat(
				customFormat(
					item.quantity.grossRemainingBalance,
					false,
					CONSTANTS.FIXED_QUANTITY_DECIMALS,
				),
			),
			employeeId: object.employee.value,
			comments: object.comments,
			futuresMonth: object.futuresMonth?.value,
			// customFields: getOrderEntriesFormValues(orderEntries, object),
			transactionTypeId: item.transactionTypeId,
			contractTypeId: object.contract.value || CONTRACT_TYPE_VALUES.ntc, //object.contract.label,
			extendedContractTypeId: getExtendedContractTypeId(object.contract),
			regionId: object?.assignedRegion?.value,
		};
		// in convert we convert ntc contract to other format so populating different fields based on contract type except NTC as it is not allowed
		const label =
			CONTRACT_TYPES_GUIDS[object.contract?.value?.toUpperCase()] || 'NTC';
		if (label === CONTRACT_TYPES.flatPrice) {
			newItem = {
				...newItem,
				futuresPrice: object.futuresPrice ? parseFloat(object.futuresPrice) : 0,
				postedBasis: object.postedBasis ? parseFloat(object.postedBasis) : 0,
				pushBasis: object.pushBasis ? parseFloat(object.pushBasis) : 0,
				netBasis: object.netBasis ? parseFloat(object.netBasis) : 0,
				price: object.flatPrice ? parseFloat(object.flatPrice) : 0,
				passFill: object.passFill,
				doNotHedge: object.doNotHedge,
			};
		}
		if (object.contract.label === CONTRACT_TYPES.ntc) {
			newItem = {
				...newItem,
				expirationDate: getFormattedExpirationDate(object),
				postedBasis: object.postedBasis ? parseFloat(object.postedBasis) : 0,
				pushBasis: object.pushBasis ? parseFloat(object.pushBasis) : 0,
				netBasis: object.netBasis ? parseFloat(object.netBasis) : 0,
				futuresPrice: object.futuresPrice ? parseFloat(object.futuresPrice) : 0,
			};
		}
		if (object.contract.label === CONTRACT_TYPES.basis) {
			newItem = {
				...newItem,
				postedBasis: object.postedBasis ? parseFloat(object.postedBasis) : 0,
				pushBasis: object.pushBasis ? parseFloat(object.pushBasis) : 0,
				netBasis: object.netBasis ? parseFloat(object.netBasis) : 0,
				expirationDate: getFormattedExpirationDate(object),
				price: object.netBasisPrice ? parseFloat(object.netBasisPrice) : 0,
			};
		}
		if (object.contract.label === CONTRACT_TYPES.hta) {
			newItem = {
				...newItem,
				futuresPrice: object.futuresPrice ? parseFloat(object.futuresPrice) : 0,
				postedBasis: object.postedBasis ? parseFloat(object.postedBasis) : 0,
				pushBasis: object.pushBasis ? parseFloat(object.pushBasis) : 0,
				netBasis: object.netBasis ? parseFloat(object.netBasis) : 0,
				price: object.flatPrice ? parseFloat(object.flatPrice) : 0,
				passFill: object.passFill,
				doNotHedge: object.doNotHedge,
				expirationDate: getFormattedExpirationDate(object),
			};
		}
		newItem = {
			...newItem,
			//these parameters can't be modified
			transactionTypeId: TRANSACTION_TYPES_ENUM[item.transactionType],
			isSell: item.isSell,
			commodityId: item.commodityId,
			customerId: item.customer.id,
		};
		return newArray.push(newItem);
	});
	return newArray;
}
