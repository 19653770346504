import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import { GenericError } from 'types/GenericError';
import { GenericResponse } from 'types/GenericResponse';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';

const { roundingRulesRoot } = apiEndpoints;

export function* createRoundingRule(action: PayloadAction<any>) {
	const { data, successMessage } = action.payload;
	const requestURL = yield new URL(`${apiRoutes.base}/${roundingRulesRoot}`);

	const {
		responseData,
		responseError,
	}: {
		responseData: GenericResponse;
		responseError: GenericError;
	} = yield call(genericRequest, requestURL, httpMethod.Post, data);

	// Success actions
	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
			}),
		);

		yield put(actions.setCurrentModal(null));
		yield put(actions.loadRoundingRulesList());
		yield put(actions.roundingRuleCreated(responseData));
		yield put(globalActions.loadRoundingRulesList());
	} else if (responseError?.status === 2) {
		yield put(actions.setErrorsInLine(responseError.data));
	}
}
