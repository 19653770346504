import { Col, Row } from 'antd';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FilterInput } from 'app/components/FilterInput';
import { FilterSingle } from 'app/components/FilterSingle';
import {
	selectCommoditiesList,
	selectFeatureFlags,
	selectHedgeAccountList,
} from 'app/containers/GlobalSaga/selectors';
import {
	selectFuturesMonthsList,
	selectSelectedFilters,
} from 'app/containers/TradeBook/selectors';
import { actions } from 'app/containers/TradeBook/slice';
import { TradeBookFilters } from 'app/containers/TradeBook/types';
import { translations } from 'locales/i18n';
import { FeatureFlag } from 'types/FeatureFlags';
import { mapToLabelValue } from 'utils/helpers';

export const Filters = memo(function Filters() {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const commoditiesList = useSelector(selectCommoditiesList);
	const selectedFilters = useSelector(selectSelectedFilters);
	const futuresMonthsOptions = useSelector(selectFuturesMonthsList);
	const hedgeAccountList = useSelector(selectHedgeAccountList);
	const featureFlags = useSelector(selectFeatureFlags);

	const translationsScope =
		translations.app.containers.ReviewAndRelease.components.Views.List.Menu
			.Filters;

	const translationsDomain =
		translations.app.containers.Contracts.ListMenu.filters;

	const commoditiesOptions = mapToLabelValue(commoditiesList);
	const hedgeAccountOptions = mapToLabelValue(hedgeAccountList || []);

	useEffect(() => {
		dispatch(actions.getFuturesMonthsRequest());
		dispatch(actions.setSelectedBuyRowsAsEmpty());
		dispatch(actions.setSelectedSellRowsAsEmpty());
	}, []);

	const handleChangeField = (
		obj: TradeBookFilters,
		shouldClearSelection: boolean = true,
	) => {
		dispatch(actions.setSelectedFilters(obj));
		dispatch(actions.loadTradeBookList());
		if (shouldClearSelection) {
			dispatch(actions.setSelectedBuyRows([]));
			dispatch(actions.setSelectedSellRows([]));
		}
	};

	const FilterTranslationsScope =
		translations.app.containers.TradeBookContainer.components.Filters;
	const handleChangeAccountName = (value) => {
		const filteredAccount =
			value.length < 1 ? [hedgeAccountOptions[0] || ''] : value;
		const updatedFilters = { ...selectedFilters };

		if (selectedFilters.commodityId.length < 1) {
			updatedFilters.commodityId = [commoditiesOptions[0]];
		}

		if (selectedFilters.futuresMonth.length < 1) {
			updatedFilters.futuresMonth = [futuresMonthsOptions[0]];
		}

		updatedFilters.accountNo = filteredAccount;

		handleChangeField(updatedFilters);
	};

	const handleChangeNumber = (value) => {
		handleChangeField({ ...selectedFilters, orderNo: value || null }, false);
	};

	const handleChangeCommodities = (selectedValues) => {
		const filteredCommodity =
			selectedValues.length < 1
				? [commoditiesOptions[0] || '']
				: selectedValues;
		const updatedFilters = { ...selectedFilters };

		if (selectedFilters.accountNo.length < 1) {
			updatedFilters.accountNo = [hedgeAccountOptions[0]];
		}

		if (selectedFilters.futuresMonth.length < 1) {
			updatedFilters.futuresMonth = [futuresMonthsOptions[0]];
		}

		updatedFilters.commodityId = filteredCommodity;

		handleChangeField(updatedFilters);
	};

	const handleChangeFuturesMonth = (value) => {
		const filteredFuturesMonth =
			value.length < 1 ? [futuresMonthsOptions[0] || ''] : value;
		const updatedFilters = { ...selectedFilters };

		if (selectedFilters.accountNo.length < 1) {
			updatedFilters.accountNo = [hedgeAccountOptions[0]];
		}

		if (selectedFilters.commodityId.length < 1) {
			updatedFilters.commodityId = [commoditiesOptions[0]];
		}

		updatedFilters.futuresMonth = filteredFuturesMonth;

		handleChangeField(updatedFilters);
	};

	const newFiltersLayout = featureFlags[FeatureFlag.enableNewFilterLayout];
	return (
		<>
			<section className="grid-main-filters">
				<Row gutter={newFiltersLayout ? [8, 8] : [16, { sm: 16, md: 0 }]}>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3 }
							: { sm: 4, md: 2 })}
					>
						<FilterSingle
							currentOption={
								selectedFilters.accountNo.length < 1
									? [hedgeAccountOptions[0] || '']
									: selectedFilters.accountNo
							}
							handleChange={handleChangeAccountName}
							options={hedgeAccountOptions}
							placeholder={translate(translationsScope.accountName)}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3 }
							: { sm: 4, md: 2 })}
					>
						<FilterSingle
							currentOption={selectedFilters.commodityId || []}
							handleChange={handleChangeCommodities}
							options={commoditiesOptions}
							placeholder={translate(translationsDomain.commodities.all)}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3 }
							: { sm: 4, md: 2 })}
					>
						<FilterSingle
							currentOption={
								selectedFilters.futuresMonth.length < 1
									? [futuresMonthsOptions[0] || '']
									: selectedFilters.futuresMonth
							}
							handleChange={handleChangeFuturesMonth}
							options={futuresMonthsOptions}
							placeholder={translate(
								translationsDomain.futuresMonth.placeHolder,
							)}
						/>
					</Col>
					<Col
						{...(newFiltersLayout
							? { xs: 12, md: 6, lg: 3, xl: 2 }
							: { span: 2 })}
					>
						<FilterInput
							onSearch={handleChangeNumber}
							placeholder={translate(FilterTranslationsScope.order)}
							currentOption={selectedFilters.orderNo || ''}
						/>
					</Col>
				</Row>
			</section>
		</>
	);
});
