import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { CustomNotificationTypes } from 'app/containers/GlobalSaga/types';
import {
	selectCurrentView,
	selectFilters,
	selectPagination,
} from 'app/containers/ReviewAndRelease/selectors';
import {
	MenuActionType,
	Modal,
	ReviewAndReleaseAction,
	ReviewAndReleaseFilters,
	ReviewAndReleaseViews,
} from 'app/containers/ReviewAndRelease/types';
import { translations } from 'locales/i18n';
import { IGenericPagination } from 'types/GenericPagination';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { actions } from '../slice';
import { getSelectedFilter } from '../utils/helpers';

const {
	reviewAndReleaseFilter,
	reviewAndReleaseApprove,
	reviewAndReleaseDeny,
	reviewAndReleaseAcknowledge,
	reviewAndReleaseDetail,
	reviewAndReleaseResend,
	reviewAndReleaseCancel,
	reviewAndReleaseExternalFill,
} = apiEndpoints;

const translationsScope = translations.app.containers.ReviewAndRelease;

export function* getReviewAndReleaseList() {
	const pagination: IGenericPagination = yield select(selectPagination);
	const selectedFilters: ReviewAndReleaseFilters = yield select(selectFilters);
	const requestURL = new URL(`${apiRoutes.base}/${reviewAndReleaseFilter}`);

	const selectedStatus = getSelectedFilter('status', 'value', selectedFilters);
	const selectedFuturesMonth = getSelectedFilter(
		'futuresMonth',
		'value',
		selectedFilters,
	);
	const selectedSymbol = getSelectedFilter('symbol', 'value', selectedFilters);
	const selectedCustomerId = getSelectedFilter(
		'customerId',
		'value',
		selectedFilters,
	);

	const data: any = {
		...pagination,
		...selectedFilters,
		status: selectedStatus,
		futuresMonth: selectedFuturesMonth,
		symbol: selectedSymbol,
		customerId: selectedCustomerId,
	};

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		data,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.reviewAndReleaseListLoaded(responseData));
		yield put(actions.setSelectedRows([]));
	} else if (!!responseError.detail) {
		yield put(actions.reviewAndReleaseListError(responseError));
	}
}

export function* reviewAndReleaseMenuAction(
	action: PayloadAction<ReviewAndReleaseAction>,
) {
	const { data, type } = action.payload;

	const ids = data.map(({ id }) => id);

	const currentView = yield select(selectCurrentView);

	let actionEndpoint;

	switch (type) {
		case MenuActionType.approve:
			actionEndpoint = reviewAndReleaseApprove;
			break;
		case MenuActionType.deny:
			actionEndpoint = reviewAndReleaseDeny;
			break;
		case MenuActionType.acknowledge:
			actionEndpoint = reviewAndReleaseAcknowledge;
			break;
		case MenuActionType.resent:
			actionEndpoint = reviewAndReleaseResend;
			break;
		default:
			break;
	}

	const requestURL = yield new URL(`${apiRoutes.base}/${actionEndpoint}`);

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		ids as any,
	);

	if (!!responseData) {
		yield put(actions.reviewAndReleaseMenuActionSuccess(responseData));
		yield put(actions.loadReviewAndReleaseList());

		const { totalProcessedTransactions, totalRequestedTransactions } =
			responseData;

		switch (type) {
			case MenuActionType.acknowledge:
				if (
					totalRequestedTransactions === 1 &&
					totalProcessedTransactions === 1
				) {
					yield put(
						globalActions.addNotification({
							type: CustomNotificationTypes.SUCCESS,
							message: {
								key: translationsScope.successSingleAcknowledge,
								data: {
									number: data[0].orderNumber ?? '',
								},
							},
						}),
					);
				} else if (
					totalRequestedTransactions > 1 &&
					totalProcessedTransactions > 0
				) {
					yield put(
						globalActions.addNotification({
							type: CustomNotificationTypes.SUCCESS,
							message: {
								key: translationsScope.successMultipleAcknowledge,
								data: {
									total: totalProcessedTransactions,
								},
							},
						}),
					);
				}
				break;
			case MenuActionType.resent:
				if (
					totalRequestedTransactions === 1 &&
					totalProcessedTransactions === 1
				) {
					yield put(
						globalActions.addNotification({
							type: CustomNotificationTypes.SUCCESS,
							title: {
								key: translationsScope.titleResentSuccess,
							},
							message: {
								key: translationsScope.successSingleResent,
								data: {
									number: data[0].orderNumber ?? '',
								},
							},
						}),
					);
				} else if (
					totalRequestedTransactions > 1 &&
					totalProcessedTransactions > 0
				) {
					yield put(
						globalActions.addNotification({
							type: CustomNotificationTypes.SUCCESS,
							title: {
								key: translationsScope.titleResentSuccess,
							},
							message: {
								key: translationsScope.successMultipleResent,
								data: {
									total: totalProcessedTransactions,
								},
							},
						}),
					);
				}
				break;
			default:
				break;
		}
		if (totalRequestedTransactions !== totalProcessedTransactions) {
			yield put(
				globalActions.addNotification({
					type: CustomNotificationTypes.ERROR,
				}),
			);
		}
		if (currentView === ReviewAndReleaseViews.details) {
			yield put(actions.loadOrderDetail({ id: ids[0] }));
		}
	} else if (!!responseError.detail) {
		yield put(actions.reviewAndReleaseMenuActionError(responseError));
	}
}

export function* getOrderDetail(action: PayloadAction<{ id: string }>) {
	const { id } = action.payload;

	const requestURL = yield new URL(
		`${apiRoutes.base}/${reviewAndReleaseDetail}/${id}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.orderDetailLoaded(responseData));
		yield put(actions.setCurrentView(ReviewAndReleaseViews.details));
	} else if (!!responseError.detail) {
		yield put(actions.orderDetailError(responseError));
	}
}

export function* getExternalFill(action: PayloadAction<{ id: string }>) {
	const { id } = action.payload;

	const requestURL = yield new URL(
		`${apiRoutes.base}/${reviewAndReleaseExternalFill}/${id}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.editExternalFillLoaded(responseData));
		yield put(actions.setCurrentModal(Modal.EditExternalFill));
	} else if (!!responseError.detail) {
		yield put(actions.editExternalFillError(responseError));
	}
}

export function* editExternalFill(action: PayloadAction<any>) {
	const { id, ...data } = action.payload;

	const requestURL = yield new URL(
		`${apiRoutes.base}/${reviewAndReleaseExternalFill}?id=${id}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
		data,
	);

	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.editExternalFillLoaded(responseData));
		yield put(actions.setCurrentModal(null));
		yield put(actions.loadReviewAndReleaseList());
	} else if (!!responseError.detail) {
		yield put(actions.editExternalFillError(responseError));
	}
}

export function* cancelExternalFill(action: PayloadAction<{ id: string }>) {
	const { id } = action.payload;

	const requestURL = yield new URL(
		`${apiRoutes.base}/${reviewAndReleaseCancel}?id=${id}`,
	);
	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Put,
	);

	if (!!responseData) {
		yield put(
			globalActions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
			}),
		);
		yield put(actions.loadReviewAndReleaseList());
	} else if (!!responseError.detail) {
		yield put(actions.cancelExternalFillError(responseError));
	}
	yield put(actions.setCurrentModal(null));
}
