import './styles.scss';

import { CloseOutlined, NotificationOutlined } from '@ant-design/icons';
import { Badge, Empty, List, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import classNames from 'classnames';
import moment from 'moment';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { translations } from 'locales/i18n';
import {
	AggregateType,
	UserInternalNotification,
} from 'types/UserNotification';

interface Props {
	data: UserInternalNotification[];
	onDismissNotification: (id: string) => void;
	onItemClick: () => void;
}

export const NotificationTab = memo(function NotificationTab({
	data,
	onDismissNotification,
	onItemClick,
}: Props) {
	const { t: translate } = useTranslation();
	const translationsScope = translations.app.components.BellNotifications;

	return data?.length ? (
		<List className="notification-list">
			{data.map((item) => (
				<List.Item
					className={classNames('notification-list__item', {
						new: !item?.dateSeen,
					})}
					key={item.id}
				>
					{!item?.dateSeen && (
						<Text className="label-new">
							{translate(translationsScope.new)}
						</Text>
					)}
					{item.path ? (
						<Link
							to={item.path}
							className="notification-list__link"
							onClick={() => onItemClick()}
						>
							<ListItemMeta item={item} />
						</Link>
					) : (
						<ListItemMeta item={item} />
					)}
					<CloseOutlined
						className="notification-list__item--dismiss"
						onClick={() => onDismissNotification(item.id)}
					/>
				</List.Item>
			))}
		</List>
	) : (
		<Empty />
	);
});

function ListItemMeta({ item }: { item: UserInternalNotification }) {
	const { t: translate } = useTranslation();
	const translationsScope = translations.app.components.BellNotifications;

	return (
		<List.Item.Meta
			className="notification-list__meta"
			avatar={
				<Badge dot={!item?.dateSeen}>
					<NotificationOutlined />
				</Badge>
			}
			title={
				<div className="notification-list__title">
					{item.subject}
					<div className="notification-list__title--extra">
						{item.aggregateType !== AggregateType.Unspecified ? (
							<Tag>{translate(translationsScope[item.aggregateType])}</Tag>
						) : (
							''
						)}
					</div>
				</div>
			}
			description={
				<>
					<div className="notification-list__description" title={item.body}>
						{item.body}
					</div>
					<div className="notification-list__date text text--right">
						{moment.utc(item?.dateDelivered || new Date()).fromNow() ?? ''}
					</div>
				</>
			}
		/>
	);
}
