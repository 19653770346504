import { call, put, select } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import {
	selectSelectedFilters,
	selectSelectedFiltersRecap,
} from '../selectors';
import { actions } from '../slice';

const { longAndShortRoot, longAndShortRootRecap } = apiEndpoints;

export function* getLongShortReport() {
	const selectedFilters = yield select(selectSelectedFilters);

	if (!selectedFilters || !selectedFilters.commodity.length) {
		return;
	}

	const requestURL = yield new URL(`${apiRoutes.base}/${longAndShortRoot}`);

	const params = new URLSearchParams();
	params.append('CommodityId', selectedFilters.commodity[0].value);
	params.append('CropYear', selectedFilters.cropYear[0].value);
	params.append('StartDate', selectedFilters.dateTime);
	requestURL.search = params.toString();

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(actions.longShortReportLoaded(responseData.longAndShortSummary));
	} else if (!!responseError) {
		yield put(actions.longShortReportError(responseError));
	}
}

export function* getLongShortReportRecap() {
	const selectedFilters = yield select(selectSelectedFiltersRecap);

	const requestURL = yield new URL(
		`${apiRoutes.base}/${longAndShortRootRecap}`,
	);

	const params = new URLSearchParams();
	params.append('StartDate', selectedFilters.dateTime);
	requestURL.search = params.toString();

	const { responseData, responseError }: any = yield call(
		genericRequest,
		requestURL,
		httpMethod.Get,
	);

	if (!!responseData) {
		yield put(
			actions.longShortReportLoadedRecap(responseData.longAndShortRecap),
		);
	} else if (!!responseError) {
		yield put(actions.longShortReportErrorRecap(responseError));
	}
}
