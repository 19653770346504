import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { BypassReviewState } from './types';

// The initial state of the ByPassReview State
export const initialState: BypassReviewState = {
	tag50Settings: {
		data: null,
		loading: false,
		error: null,
	},

	updateTag50Settings: {
		data: null,
		loading: false,
		error: null,
	},

	tag50EmployeesList: {
		data: [],
		total: 0,
		loading: false,
		error: null,
	},
};

const bypassReviewSlice = createSlice({
	name: 'bypassReview',
	initialState,
	reducers: {
		// Tag50 Settings
		loadTag50Settings(state: BypassReviewState) {
			state.tag50Settings = {
				...state.tag50Settings,
				data: {},
				loading: true,
				error: null,
			};
		},

		tag50SettingsLoaded(state: BypassReviewState, action: PayloadAction<any>) {
			state.tag50Settings = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		tag50SettingsError(
			state: BypassReviewState,
			action: PayloadAction<GenericError>,
		) {
			state.tag50Settings = {
				...state.tag50Settings,
				loading: false,
				error: action.payload,
			};
		},

		// update Tag50 Settings
		updateTag50Settings(state: BypassReviewState, action: PayloadAction<any>) {
			state.updateTag50Settings = {
				...state.updateTag50Settings,
				loading: true,
				error: null,
			};
		},

		updateTag50SettingsSuccess(
			state: BypassReviewState,
			action: PayloadAction<any>,
		) {
			state.updateTag50Settings = {
				data: action.payload,
				loading: false,
				error: null,
			};
		},

		updateTag50SettingsError(
			state: BypassReviewState,
			action: PayloadAction<GenericError>,
		) {
			state.updateTag50Settings = {
				...state.updateTag50Settings,
				loading: false,
				error: action.payload,
			};
		},

		// Tag50 Employees List
		loadTag50EmployeesList(state: BypassReviewState) {
			state.tag50EmployeesList = {
				...state.tag50EmployeesList,
				data: [],
				loading: true,
				error: null,
			};
		},

		tag50EmployeesListLoaded(
			state: BypassReviewState,
			action: PayloadAction<any>,
		) {
			state.tag50EmployeesList = {
				data: action.payload.employees,
				total: action.payload.total,
				loading: false,
				error: null,
			};
		},

		tag50EmployeesListError(
			state: BypassReviewState,
			action: PayloadAction<GenericError>,
		) {
			state.tag50EmployeesList = {
				...state.tag50EmployeesList,
				loading: false,
				error: action.payload,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = bypassReviewSlice;
