import { PayloadAction } from '@reduxjs/toolkit';
import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';
import {
	CustomNotificationTypes,
	notificationGroupDtoSchema,
	NotificationMessagePayload,
} from '../types';

export function* loadEmployeeNotificationGroupList() {
	const { employeeGroupNotificationSettings } = apiEndpoints;
	const requestURL = new URL(
		`${apiRoutes.base}/${employeeGroupNotificationSettings}`,
	);

	const { data, error } = yield* requestWithSchema(
		requestURL,
		httpMethod.Get,

		{
			schema: z.array(notificationGroupDtoSchema),
			params: {
				includeSystemGroups: false,
			},
		},
	);

	if (!!data) {
		yield* put(actions.employeeNotificationGroupListLoaded(data));
	}

	if (!!error) {
		yield* put(actions.employeeNotificationGroupListError(error));
	}
}

export function* loadCustomerNotificationGroupList() {
	const { customerGroupNotificationSettings } = apiEndpoints;
	const requestURL = new URL(
		`${apiRoutes.base}/${customerGroupNotificationSettings}`,
	);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.array(notificationGroupDtoSchema),
	});

	if (!!data) {
		yield* put(actions.customerNotificationGroupListLoaded(data));
	}

	if (!!error) {
		yield* put(actions.customerNotificationGroupListError(error));
	}
}

export function* sendCustomNotification(
	action: PayloadAction<NotificationMessagePayload>,
) {
	const { sendCustomNotification } = apiEndpoints;
	const { data: payloadData, successMessage } = action.payload;
	const requestURL = new URL(`${apiRoutes.base}/${sendCustomNotification}`);

	const { data, error } = yield* requestWithSchema(
		requestURL,
		httpMethod.Post,
		{
			schema: z.object({}),
			body: payloadData,
		},
	);

	if (!!data) {
		yield* put(
			actions.addNotification({
				type: CustomNotificationTypes.SUCCESS,
				message: successMessage,
				showDescription: true,
			}),
		);
	}

	if (!!error) {
		yield* put(
			actions.addNotification({
				type: CustomNotificationTypes.ERROR,
				message: `${error.title}: ${error.detail}`,
				showDescription: true,
			}),
		);
	}
	yield* put(actions.setCurrentModal(null));
}
