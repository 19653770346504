import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
} from '@microsoft/signalr';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { Middleware } from 'redux';

import { selectAuthUser } from 'app/containers/GlobalSaga/selectors';
import { actions } from 'app/containers/HubSaga/slice';
import { apiRoutes } from 'utils/api-routes';
import {
	HUB_NAME,
	hubSubscriptionChannels,
	hubTriggers,
} from 'utils/signalR/constants';

import { contractsHandler } from '../events/contracts';
import { futuresErpHandler } from '../events/futures-erp';
import { inAppNotificationsHandler } from '../events/internal-notifications';
import { offersHandler } from '../events/offers';
import { prehedgeHandler } from '../events/pre-hedge';
import { reviewAndReleaseHandler } from '../events/review-and-release';

let connection: HubConnection | null = null;

export const signalRMiddleware = (): Middleware => {
	let channelQueue: string[] = [];

	return (store) => (next) => (action) => {
		// Dynamic access token factory
		const accessTokenFactory = async () => {
			const state = store.getState();
			const token = selectAuthUser(state);
			return token?.accessToken || '';
		};

		const startConnection = async (dispatch: Dispatch<AnyAction>) => {
			connection = new HubConnectionBuilder()
				.withUrl(`${apiRoutes.root}/${HUB_NAME}`, {
					accessTokenFactory: accessTokenFactory,
				})
				.withAutomaticReconnect()
				.build();

			connection.onclose(() => {
				console.log('SignalR connection closed');
			});

			// Event handlers
			inAppNotificationsHandler(connection, dispatch);
			contractsHandler(connection, dispatch);
			futuresErpHandler(connection, dispatch);
			offersHandler(connection, dispatch);
			reviewAndReleaseHandler(connection, dispatch);
			prehedgeHandler(connection, dispatch);

			try {
				await connection.start();
				console.log('SignalR connection started');
				// SignalR actions
				// Subscribe to notifications
				await connection.send(hubTriggers.subscribeToNotificationMessages);

				if (channelQueue.length > 0) {
					channelQueue.forEach(async (channel) => {
						await connection?.send('Subscribe', channel);
					});
					channelQueue = [];
				}
			} catch (err) {
				console.error('SignalR connection error: ', err);
			}
		};

		const stopConnection = async () => {
			if (connection) {
				await connection.stop();
				console.log('SignalR connection stopped');
			}
		};

		const sendSubscribe = async (trigger: string) => {
			if (connection?.state === HubConnectionState.Connected) {
				await connection?.send('Subscribe', trigger);
			} else {
				channelQueue = [...channelQueue, trigger];
			}
		};

		const sendUnsubscribe = async (trigger: string) => {
			if (connection?.state === HubConnectionState.Connected) {
				await connection?.send('Unsubscribe', trigger);
			}
		};

		// Action handlers
		switch (action.type) {
			case actions.startSignalRConnection.type:
				startConnection(store.dispatch);
				break;
			case actions.stopSignalRConnection.type:
				stopConnection();
				break;
			case actions.subscribeToContractUpdates.type:
				sendSubscribe(hubSubscriptionChannels.contractUpdates);
				break;
			case actions.subscribeToFuturesErpUpdates.type:
				sendSubscribe(hubSubscriptionChannels.futuresErpUpdates);
				break;
			case actions.subscribeToOfferUpdates.type:
				sendSubscribe(hubSubscriptionChannels.offerUpdates);
				break;
			case actions.subscribeToReviewAndReleaseUpdates.type:
				sendSubscribe(hubSubscriptionChannels.reviewAndReleaseUpdates);
				break;
			case actions.subscribeToStatusTransactionUpdates.type:
				sendSubscribe(hubSubscriptionChannels.statusTransactionUpdates);
				break;
			case actions.subscribeToPreHedgeUpdates.type:
				sendSubscribe(hubSubscriptionChannels.preHedgeUpdates);
				break;

			case actions.unsubscribeFromContractUpdates.type:
				sendUnsubscribe(hubSubscriptionChannels.contractUpdates);
				break;
			case actions.unsubscribeFromFuturesErpUpdates.type:
				sendUnsubscribe(hubSubscriptionChannels.futuresErpUpdates);
				break;
			case actions.unsubscribeFromOfferUpdates.type:
				sendUnsubscribe(hubSubscriptionChannels.offerUpdates);
				break;
			case actions.unsubscribeFromReviewAndReleaseUpdates.type:
				sendUnsubscribe(hubSubscriptionChannels.reviewAndReleaseUpdates);
				break;
			case actions.unsubscribeFromPreHedgeUpdates.type:
				sendUnsubscribe(hubSubscriptionChannels.preHedgeUpdates);
				break;
			default:
				break;
		}

		return next(action);
	};
};
