import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericForm } from 'app/components/GenericForm';
import { Input } from 'app/components/Input';
import { translations } from 'locales/i18n';

import { SETUP_FORM_ITEMS } from '../../types';

const domain =
	translations.app.containers.Settings.sections.ERPIntegration.components.URL;

export const URL = memo(function URL() {
	const { t: translate } = useTranslation();

	return (
		<GenericForm.FormItem
			data-testid={`${SETUP_FORM_ITEMS.URL}-form-item`}
			label={translate(domain.label)}
			name={SETUP_FORM_ITEMS.URL}
		>
			<Input name={SETUP_FORM_ITEMS.URL} />
		</GenericForm.FormItem>
	);
});
