/**
 *
 * SwitchStateFull
 *
 */
import { Selector } from '@reduxjs/toolkit';
import { Switch } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'types';

import { ConfirmDialog } from '../ConfirmDialog';

interface Props {
	activeRecordSelector?: Selector<RootState, string | null | undefined>;
	cancelButtonText: string;
	confirmButtonText: string;
	customHandleConfirm?: Function;
	errorSelector: Selector<RootState, unknown>;
	initialStatus: boolean;
	loadingSelector?: Selector<RootState, boolean>;
	recordId: string;
	toggleOffMessage: string;
	toogleOnMessage: string;
	stateControlled?: boolean;
	toggleAction?: ({ id, isActive }: { id: string; isActive: boolean }) => void;
	disabled?: boolean;
}

export const SwitchStateFull = memo(function SwitchStateFull({
	activeRecordSelector,
	cancelButtonText,
	confirmButtonText,
	customHandleConfirm,
	errorSelector,
	initialStatus,
	loadingSelector,
	recordId,
	toggleOffMessage,
	toogleOnMessage,
	stateControlled,
	toggleAction,
	disabled,
}: Props) {
	const toggleLoading = useSelector(loadingSelector || (() => false));
	const activeRecordData = useSelector(activeRecordSelector || (() => null));

	const [active, setActive] = useState(initialStatus);

	const dispatch = useDispatch();
	const toggleError = useSelector(errorSelector);

	const handleConfirm = () => {
		if (customHandleConfirm) {
			customHandleConfirm(active);
		} else if (toggleAction) {
			dispatch(toggleAction({ id: recordId, isActive: active }));
		}
	};

	useEffect(() => {
		if (!toggleLoading && toggleError && activeRecordData === recordId) {
			setActive((value) => !value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleLoading, toggleError]);

	useEffect(() => {
		setActive(initialStatus);
	}, [initialStatus]);

	return (
		<ConfirmDialog
			disabled={disabled}
			placement="bottomRight"
			message={active ? toggleOffMessage : toogleOnMessage}
			handleConfirm={handleConfirm}
			confirmText={confirmButtonText}
			cancelText={cancelButtonText}
			trigger={
				<Switch
					disabled={disabled}
					data-testid="confirm-dialog-switch-trigger"
					checked={active}
					loading={toggleLoading}
				/>
			}
			withOverlay={false}
			stateControlled={stateControlled}
		/>
	);
});
