import { PayloadAction } from '@reduxjs/toolkit';

import { GenericError } from 'types/GenericError';
import { Region } from 'types/Regions';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { RegionsState } from './types';

export const initialState: RegionsState = {
	regionUpdate: {
		error: null,
		loading: false,
	},
	regions: {
		data: [],
		error: null,
		loading: false,
	},
};

const RegionsSlice = createSlice({
	name: 'regions',
	initialState,
	reducers: {
		createRegionError(state, action: PayloadAction<GenericError>) {
			state.regionUpdate = {
				...state.regionUpdate,
				error: action?.payload,
				loading: false,
			};
		},

		createRegionRequest(state, action: PayloadAction<Region[]>) {
			state.regionUpdate = {
				...state.regionUpdate,
				error: null,
				loading: true,
			};
		},

		createRegionSuccess(state) {
			state.regionUpdate = {
				...state.regionUpdate,
				error: null,
				loading: false,
			};
		},

		getAllRegionsError(state, action: PayloadAction<GenericError>) {
			state.regions = {
				...state.regions,
				error: action?.payload,
				loading: false,
			};
		},

		getAllRegionsRequest(state) {
			state.regions = {
				...state.regions,
				data: [],
				error: null,
				loading: true,
			};
		},

		getAllRegionsSuccess(state, action: PayloadAction<Region[]>) {
			state.regions = {
				...state.regions,
				data: action?.payload,
				error: null,
				loading: false,
			};
		},
	},
});

export const { actions, reducer, name: sliceKey } = RegionsSlice;
