import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { genericRequest, httpMethod } from 'utils/request';

import { selectPagination, selectSelectedFilters } from '../selectors';
import { actions } from '../slice';
import { TableTypes } from '../types';

const { bidsheetList, bidsheetListFilter } = apiEndpoints;

export function* getBidsheetList(action: PayloadAction<any>) {
	const { type }: any = action.payload;

	const reviewOrFilterURL =
		type === TableTypes.Filter ? bidsheetListFilter : bidsheetList;

	const requestURL = yield new URL(`${apiRoutes.base}/${reviewOrFilterURL}`);
	const pagination: any = yield select(selectPagination);
	const selectedFilters: any = yield select(selectSelectedFilters);

	const filters = {
		...pagination,
		...selectedFilters,
		commodities:
			selectedFilters.commodities
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || [],
		locations:
			selectedFilters.locations
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || [],
		cropYears:
			selectedFilters.cropYears
				?.filter((element) => element.value !== 'all')
				.map((element) => element.value) || [],
	};

	const { responseData, responseError } = yield call(
		genericRequest,
		requestURL,
		httpMethod.Post,
		filters,
	);

	// Success actions
	if (!!responseData) {
		yield put(actions.bidsheetListLoaded(responseData));
		yield put(actions.getAllNotificationGroups());
	} else if (!!responseError.detail) {
		yield put(actions.bidsheetListError(responseError));
	}
}
