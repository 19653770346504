import { Tooltip } from 'antd';
import { RenderResult, TransferItem } from 'antd/lib/transfer';
import { Transfer, TransferProps } from 'formik-antd';
import React, { memo } from 'react';

interface Props extends TransferProps<TransferItem> {
	name: string;
	description: string;
	initialValue: string[];
}

export const FormTransfer = memo(function FormTransfer(props: Props) {
	const { initialValue } = props;
	const renderItem = (item: TransferItem): RenderResult => {
		const customLabel = <Tooltip title={item.description}>{item.name}</Tooltip>;

		return {
			label: customLabel,
			value: '',
		};
	};

	return <Transfer {...props} targetKeys={initialValue} render={renderItem} />;
});
