import './style.scss';

import { Col, Row } from 'antd';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FilterMultiple } from 'app/components/FilterMultiple';
import { FilterSingle } from 'app/components/FilterSingle';
import { selectSelectedFilters } from 'app/containers/CashbidsAndQuotes/selectors';
import { actions } from 'app/containers/CashbidsAndQuotes/slice';
import {
	selectCommoditiesList,
	selectCropsList,
	selectDestinationsList,
	selectUserInfo,
} from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { FILTERS_FOR_ORIGINATOR_SCREEN } from 'utils/constants';
import {
	GetPersistedData,
	mapToLabelValue,
	UpdatePersistedData,
} from 'utils/helpers';

import { FilterKey, SelectedValue } from '../../../../types';

const allDefaultOption = {
	id: 'all',
	name: '',
};

export const Filters = memo(function Filters() {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();
	const commoditiesList = useSelector(selectCommoditiesList);
	const destinationList = useSelector(selectDestinationsList);
	const selectedFilters = useSelector(selectSelectedFilters);
	const userInfo = useSelector(selectUserInfo);
	let cropYearList: any = useSelector(selectCropsList);

	const persistedFilters = GetPersistedData<null | typeof selectedFilters>(
		`${FILTERS_FOR_ORIGINATOR_SCREEN}-${userInfo?.id}`,
		null,
	);

	// Apply persisted filters
	useEffect(() => {
		dispatch(actions.setSelectedFilters(persistedFilters));
		dispatch(actions.loadCashbids());
	}, []);

	cropYearList = cropYearList.map((year) => {
		return { name: year, id: year };
	});

	const getTranslation = (key: string, subKey: string) =>
		translate(
			translations.app.containers.CashbidsAndQuotes.Cashbids.filters[key][
				subKey
			],
		);

	const allCommoditiesOption = {
		...allDefaultOption,
		name: getTranslation('commodities', 'all'),
	};

	const allCropYearOption = {
		...allDefaultOption,
		name: getTranslation('cropYears', 'all'),
	};

	const commoditiesOptions = mapToLabelValue([
		allCommoditiesOption,
		...commoditiesList,
	]) as {
		label: string;
		value: any;
		type: string;
	}[];
	const allDestinationListOption = {
		...allDefaultOption,
		name: getTranslation('locations', 'all'),
	};
	const destinationOptions = mapToLabelValue([
		allDestinationListOption,
		...destinationList.data,
	]);

	const cropYearOptions = mapToLabelValue([allCropYearOption, ...cropYearList]);

	const updateSelectedFiltersAndLoadCashbids = (
		key: FilterKey,
		selectedValues: SelectedValue[],
	) => {
		UpdatePersistedData(
			`${FILTERS_FOR_ORIGINATOR_SCREEN}-${userInfo?.id}`,
			selectedFilters,
			{
				[key]: selectedValues,
			},
		);

		const defaultCropYears = [cropYearOptions[1]];

		switch (key) {
			case FilterKey.CashBidsCommodities:
				dispatch(
					actions.setSelectedFilters({ cashbidsCommodities: selectedValues }),
				);
				break;

			case FilterKey.CropYears:
				const filteredCropYear =
					selectedValues.length < 1 || selectedValues[0].key === 'all'
						? defaultCropYears
						: selectedValues;
				dispatch(actions.setSelectedFilters({ cropYears: filteredCropYear }));
				break;

			case FilterKey.Locations:
				dispatch(actions.setSelectedFilters({ locations: selectedValues }));
				break;

			default:
				break;
		}

		dispatch(actions.loadCashbids());
	};

	const handleChangeCommodities = (selectedValues) => {
		// force the earliest cropYear as default
		if (selectedFilters.cropYears.length < 1) {
			dispatch(
				actions.setSelectedFilters({
					cropYears: [cropYearOptions[1]],
				}),
			);
		}
		updateSelectedFiltersAndLoadCashbids(
			FilterKey.CashBidsCommodities,
			selectedValues,
		);
	};

	const handleChangeDestinations = (selectedValues) => {
		updateSelectedFiltersAndLoadCashbids(FilterKey.Locations, selectedValues);
	};

	const handleChangeCropYears = (selectedValues) => {
		updateSelectedFiltersAndLoadCashbids(FilterKey.CropYears, selectedValues);
	};

	return (
		<Row gutter={16}>
			<Col className="grid-menu-label" flex="none">
				{translate(translations.app.containers.Contracts.ListMenu.label)}
			</Col>

			<Col md={5} lg={4} xl={3} xxl={2}>
				<FilterSingle
					currentOption={selectedFilters.cashbidsCommodities}
					handleChange={handleChangeCommodities}
					options={commoditiesOptions}
					placeholder={getTranslation('commodities', 'all')}
				/>
			</Col>
			<Col md={5} lg={4} xl={3} xxl={2}>
				<FilterMultiple
					currentOptions={selectedFilters.locations}
					handleChange={handleChangeDestinations}
					options={destinationOptions}
					placeholder={getTranslation('locations', 'all')}
				/>
			</Col>
			<Col span={2}>
				<FilterMultiple
					currentOptions={
						selectedFilters.cropYears.length < 1 ||
						selectedFilters.cropYears[0]?.value === 'all'
							? [cropYearOptions[1] || '']
							: selectedFilters.cropYears
					}
					handleChange={handleChangeCropYears}
					options={cropYearOptions}
					placeholder={getTranslation('cropYears', 'all')}
				/>
			</Col>
		</Row>
	);
});
