import { PayloadAction } from '@reduxjs/toolkit';
import { put } from 'typed-redux-saga';
import { z } from 'zod';

import { ContractTypeSource } from 'types/ContractTypeSource';
import { deliveryDateSchema } from 'types/DeliveryDate';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';
import {
	LoadDeliveryDatesPayload,
	LoadFuturesMonthPayload,
	LoadPostedBasisPricePayload,
} from '../types';

const { deliveryDates, futuresMonth, futuresMonthOffers, postedBasisPrice } =
	apiEndpoints;

const bidsheetDeliveryDatesResponseSchema = z.object({
	min: z.string(),
	max: z.string(),
	deliveryDates: z.array(deliveryDateSchema).nullable(),
});

export function* getDeliveryDates(
	action: PayloadAction<LoadDeliveryDatesPayload>,
) {
	const source = action.payload.source;
	const params = action.payload.params;

	if (!params.commodityId || !params.cropYear || !params.locationId) {
		return;
	}

	let requestURL = new URL(`${apiRoutes.base}/${deliveryDates}`);

	requestURL.searchParams.append('CommodityId', params.commodityId);
	requestURL.searchParams.append('cropYear', params.cropYear.toString());
	requestURL.searchParams.append('LocationId', params.locationId);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: bidsheetDeliveryDatesResponseSchema,
	});

	if (!!data) {
		yield* put(
			actions.deliveryDatesLoaded({
				source,
				data: data.deliveryDates ?? [],
			}),
		);
	} else if (!!error) {
		yield* put(
			actions.deliveryDatesError({
				source,
				error,
			}),
		);
	}
}

const futuresMonthResponseSchema = z.object({
	futuresMonth: z.string().nullable(),
});

export function* getFuturesMonth(
	action: PayloadAction<LoadFuturesMonthPayload>,
) {
	const source = action.payload.source;
	const params = action.payload.params;
	const type = action.payload.type;

	if (
		!params.cropYear ||
		!params.deliveryStartDate ||
		!params.deliveryEndDate
	) {
		return;
	}
	if (
		type === ContractTypeSource.OFFER &&
		(!params.commodity || !params.location)
	) {
		return;
	}
	const endpoint =
		type === ContractTypeSource.CONTRACT ? futuresMonth : futuresMonthOffers;

	let requestURL = new URL(`${apiRoutes.base}/${endpoint}`);
	requestURL.searchParams.append('CropYear', params.cropYear.toString());
	requestURL.searchParams.append('DeliveryStart', params.deliveryStartDate);
	requestURL.searchParams.append('DeliveryEnd', params.deliveryEndDate);
	if (params.commodity) {
		requestURL.searchParams.append('CommodityId', params.commodity);
	}

	if (type === ContractTypeSource.OFFER && params.location) {
		requestURL.searchParams.append('DeliveryLocationId', params.location);
	}

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: futuresMonthResponseSchema,
	});

	if (!!data) {
		yield* put(
			actions.futuresMonthLoaded({
				source,
				data: data.futuresMonth,
			}),
		);
	} else if (!!error) {
		yield* put(
			actions.futuresMonthError({
				source,
				error,
			}),
		);
	}
}

export function* getPostedBasisPrice(
	action: PayloadAction<LoadPostedBasisPricePayload>,
) {
	const source = action.payload.source;
	const params = action.payload.params;

	if (
		!params.commodityId ||
		!params.deliveryStartDate ||
		!params.deliveryEndDate ||
		!params.cropYear ||
		!params.locationId ||
		!params.deliveryLocationId
	) {
		return;
	}

	let requestURL = new URL(`${apiRoutes.base}/${postedBasisPrice}`);
	requestURL.searchParams.append('CommodityId', params.commodityId);
	requestURL.searchParams.append('DeliveryStartDate', params.deliveryStartDate);
	requestURL.searchParams.append('DeliveryEndDate', params.deliveryEndDate);
	requestURL.searchParams.append('CropYear', params.cropYear.toString());
	requestURL.searchParams.append('LocationId', params.locationId);
	requestURL.searchParams.append(
		'DeliveryLocationId',
		params.deliveryLocationId,
	);

	const { data, error } = yield* requestWithSchema(requestURL, httpMethod.Get, {
		schema: z.number(),
	});

	if (!!data) {
		yield* put(
			actions.postedBasisPriceLoaded({
				source,
				data,
			}),
		);
	} else if (!!error) {
		yield* put(
			actions.postedBasisPriceError({
				source,
				error,
			}),
		);
	}
}
